import { Grid, TextField } from '@mui/material'
import React from 'react'
import { useState } from "react";
import FormInput from "../SignUpPage/FormInput.js";
import AquaPrimaryButton from '../../../../Common/AquaPrimaryButton'
import styles from "./RegisterPage.module.css"
import constants from '../../../../Common/Constants.js'
import { useHistory } from 'react-router-dom'
import axios from 'axios';
import { useLocation } from 'react-router-dom';

function RegisterPage() {

  
  const [name, setName] = useState('');
  const [dateofbirth, setDateofbirth] = useState('');
  const [height, setHeight] = useState('');
  const [weight, setWeight] = useState('');
  const [contact, setContact] = useState('');
  const [email, setEmail] = useState('');
  const [medication, setMedication] = useState('');
  const [errors, setErrors] = useState('');
  const [hrDisease, sethrDisease] = useState(false);
  const [cholesterol, setCholesterol] = useState(false);
  const [diabetes, setDiabetes] = useState(false);
  const [cancer, setCancer] = useState(false);
  const [hypertension, setHypertension] = useState(false);
  
  const [nameError, setNameError] = useState("");
  const [heightError, setHeightError] = useState("");
  const [weightError, setWeightError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [emailError, setEmailError] = useState("");
  const[isRegistered, setIsRegistered]=useState(false);
  
  const location = useLocation();
 const history =useHistory();
 const handleLoginPage = () =>{
     history.push("/")
 }      
 const [values, setValues] = useState({
     name: "",
     dateofbirth: "",
     height: "",
     weight:" ",
     contact:"",
     email:"",
     medication:"",
     
   });
 
   const inputs = [
     {
       id: 1,
       name: "name",
       type: "text",
       placeholder: "Name",
       errorMessage: "Allow only alphabets",
     //   label: "Name",
       pattern: "^[A-Za-z0-9]$",
       required: true,
     },
     {
       id: 2,
       name: "dateofbirth",
       type: "date",
       placeholder: "DOB",
       errorMessage: "Enter a valid date!",
      //  label: "DOB",
       required: true,
     },
     {
       id: 3,
       name: "height",
       type: "number",
       placeholder: "Height",
      //  label: "Height",
     },
     {
       id: 4,
       name: "weight",
       type: "number",
       placeholder: "Weight",
       errorMessage:
         "Should be 8-20 characters with at least 1 Letter, 1 number and 1 special character!",
     //   label: "Password",
       pattern: `^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$`,
       required: true,
     },
     {
       id: 5,
       name: "contact",
       type: "number",
       placeholder: "Contact",
       errorMessage: "Enter a valid number!",
     //   label: "Confirm Password",
      //  pattern:,
       required: true,
     },
        { id: 6,
          name: "email",
          type: "email",
          placeholder: "Email",
          errorMessage: "It should be a valid email address!",
          // label: "Email",
          required: true,
        
        },
        {
          id: 7,
          name: "medication",
          type: "text",
          placeholder: "Medication",
          errorMessage: "Enter details",
        //   label: "Name",
          pattern: "^[A-Za-z0-9]$",
          required: false,
        },
        {
          id:8,
          name:"Heart Diseases",
          type:"checkbox",
        },
        {
          id:9,
          name:"Hypertension",
          type:"checkbox",
        },
        {
          id:10,
          name:"Diabetes",
          type:"checkbox",
        }
   ];

   const handleCheckbox1 = () => {
    sethrDisease(!hrDisease);
  };
  const handleCheckbox2 = () => {
    setHypertension(!hypertension);
  };
  const handleCheckbox3 = () => {
    setDiabetes(!diabetes);
  };
  const handleCheckbox4 = () => {
    setCancer(!cancer);
  };
  const handleCheckbox5 = () => {
    setCholesterol(!cholesterol);
  };
  
   var formData=JSON.stringify({name:name, dob:dateofbirth, height:height,
    weight:weight, contact:contact, email:email, medication:(medication),
    heart_disease:hrDisease,cholesterol:cholesterol,diabetes:diabetes,cancer:cancer,hypertension:hypertension, caretaker: "1"});
   
  
   const username = location.state ? location.state.username : null;


   const handleSubmit = (e) => {
     e.preventDefault();

     
     

   
       axios  ({
         method: "post",
         headers: {
           "Content-Type": "application/json",
           Accept: "application/json",
         },
         url: `${constants.API_URL}/profile/group/${username}/`,
         data: formData,
       
       }).then(
         (res) => {
          setIsRegistered(true)
          if (true) {
            setTimeout(() => {
              // redirect to the next page after 5 seconds
              history.push('/')
            }, 4000);
          }
           
           

           
         }).catch((e) =>{
           setErrors(e.response.data.message)
           
         })



     // axios({
     //     method: "post",
     //     headers: {
     //       "Content-Type": "application/json",
     //       Accept: "application/json",
     //     },
     //     url: `${constants.API_URL}/sign_up/group/`,
     //     data: formData,
       
     //   }).then(
     //     (res) => {
     //       

     //       history.push('/register_page');
     //     }); 

   };
 
   const onChange = (e) => {
     setValues({ ...values, [e.target.name]: e.target.value });
   };

   const validateName = () => {
    const nameRegex = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
    if (!nameRegex.test(name)) {
      setNameError("Please enter a valid name");
    } else {
      setNameError("");
    }
  };

  const today = new Date().toISOString().substring(0, 10);

  
   const validateEmail = () => {
    const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
    if (!emailRegex.test(email)) {
      setEmailError("Please enter a valid email address");
    } else {
      setEmailError("");
    }
  };

  const validateHeight = () => {
    if (height < 5) {
      setHeightError("Height must be atleast 5cm");
    } 
    else if( height > 400){
      setHeightError("Enter a valid height (max:400cm)");
    }
    else {
      setHeightError("");
    }
  };

  const validateWeight = () => {
    if (weight < 0.5) {
      setWeightError("Weight must be atleast 0.5kg");
    } 
    else if( weight > 400){
      setWeightError("Enter a valid weight (max:700kg)");
    }
    else {
      setWeightError("");
    }
  };

  const validatePhone = () => {
   const phoneRegex=/[0-9]{10}/
   if (!phoneRegex.test(contact)) {
    setPhoneError("Please enter a valid phone number");
  } else {
    setPhoneError("");
  }
};
 
 

    return (
        <div className={styles.container}>
          <div className={styles.innerBox}>
            <div className={styles.left}>
              <div className={styles.leftBlur}>
                <div className={styles.leftHeading}>
                <span style={{ color: "#FFFFFF" }}>Care</span>
                <span style={{ color: "#5b2f8d"}}>HQ</span>
                </div>
              </div>
            </div>
            <div className={styles.right}>
              <div className={styles.headerTitle}> Register </div>
              <form>
              <input 
              type='text' 
              className={styles.field} 
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder='NAME' 
              autoComplete="off"
              fullWidth
              required
              onBlur={validateName}
              // defaultValue="DOB"
              >
              </input>
              {nameError && <p style={{ color: "red",fontSize:10, marginTop:-10,marginBottom:2 }}>{nameError}</p>}

              <input 
              type="date"
              className={styles.field} 
              placeholder="DOB"
              onChange={(e) => setDateofbirth(e.target.value)}
              required
              max={today}
              value={dateofbirth}
              > 
              </input>
              {/* {emailError && <p style={{ color: "red",fontSize:10, marginTop:-10,marginBottom:2 }}>{emailError}</p>} */}

              <input 
              type='number' 
              className={styles.field} 
              placeholder='HEIGHT (in cm)'
              required
              min="1"
              max="400"
              onBlur={validateHeight}
              title="Please enter a valid height"
              onChange={(e) => setHeight(e.target.value)}
              value={height}
              >
              </input>
              {heightError && <p style={{ color: "red",fontSize:10, marginTop:-10,marginBottom:2 }}>{heightError}</p>}


              <input
              type='number' 
              className={styles.field} 
              value={weight}
              min="1"
              max="700"
              onBlur={validateWeight}
              title="Please enter a valid weight"
              required
              onChange={(e) => setWeight(e.target.value)}
              placeholder='WEIGHT (in kg)'>
              </input>
              {weightError && <p style={{ color: "red",fontSize:10, marginTop:-10,marginBottom:2 }}>{weightError}</p>}

              <input 
              type='tel' 
              className={styles.field} 
              value={contact}
              required
              maxLength={10}
              onBlur={validatePhone}
              onChange={(e) => setContact(e.target.value)}
              placeholder='CONTACT'>
              </input>
              {phoneError && <p style={{ color: "red",fontSize:10, marginTop:-10,marginBottom:2 }}>{phoneError}</p>}

              <input 
               type='email'
               value={email} 
               required  
               onChange={(e) => setEmail(e.target.value)} 
               className={styles.field} 
               placeholder='EMAIL'
               onBlur={validateEmail}
              //  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" 
              //  title="Please enter a valid email address" 
              >
                
              </input>
              {emailError && <p style={{ color: "red",fontSize:10, marginTop:-10,marginBottom:2 }}>{emailError}</p>}

              <input type='text' value={medication} onChange={(e) => setMedication(e.target.value)} className={styles.field} placeholder='MEDICATION'></input>

              <div className={styles.checks}> 

              <p>Having any health conditions ?</p>

              
              <label className={styles.checkslabel}>Heart Diseases</label><input onChange={handleCheckbox1} type='checkbox' className={styles.checkbtn} checked={hrDisease} /> 
              
              <label className={styles.checkslabel}>Hypertension</label> <input onChange={handleCheckbox2} className={styles.checkbtn} type='checkbox' checked={hypertension}/> 
               </div>
              
               <div className={styles.checks2}> 
              
               <label className={styles.checkslabel}>Diabetes</label> <input onChange={handleCheckbox3} className={styles.checkbtn} type='checkbox'  checked={diabetes}/> 
              
               <label className={styles.checkslabel}>Cancer</label> <input onChange={handleCheckbox4} className={styles.checkbtn} type='checkbox' checked={cancer}/> 
              
               <label className={styles.checkslabel}>Cholesterol</label> <input onChange={handleCheckbox5} className={styles.checkbtn} type='checkbox' checked={cholesterol}/> 
               </div>

              <button className={styles.btnSignUp} type="submit" onClick={handleSubmit}>REGISTER</button>
              {errors && 
            <div 
            style={{
              color: "red",
              marginTop: "5px",
              textAlign: "center",
              fontSize: "12px"
            }}
          >
              {errors}</div>}
              <div><button className={styles.login12} onClick={handleLoginPage}>LOGIN</button> </div>
              </form>
            {isRegistered && (
              <div>
                <p style={{
                  color: "Green",
                  marginTop: "5px",
                  textAlign: "center",
                  fontSize: "15px"
                }}> SUCCESSFULLY REGISTERED! </p>
                {setTimeout(() => setIsRegistered(false), 4000)}
              </div>
            )}
             </div> 
            </div> 
        </div>
      )
    }

export default RegisterPage
import ReactEcharts from 'echarts-for-react';
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
} from "recharts";
import { Row, Container } from "react-bootstrap";
import constants from '../../../../../Common/Constants'
import {useSelector,useDispatch } from "react-redux"
import FormHR from './FormHR';
import PopupBG from './PopBG';
import ExpandMorePopup from '../../../../../Common/ExpandMorePopup';
import FormStep from './FormStep';
import TileRemove from '../../../../home/TileRemove';
// import { setHeartRate, setHeartRateStatus } from "../../../../../store/dashboardSlice";



function HeartrateChart1({handleClose,onClick, careTakerPatientView=false}) {
  
    const ws = useRef();
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [status, setStatus] = useState(false)
    const groupName = useSelector((state) => state.auth.groupName);
    // const heartRate = useSelector((state) => state.dashboard.heartRate);
    // const heartRateStatus = useSelector((state) => state.dashboard.heartRateStatus);
    const token = useSelector((state) => state.auth.authToken);
    const heartRateDaily = useSelector((state) => state.dashboard.heartRateDaily);
    
    const alarmStatus= heartRateDaily?.data?.y_data?.status_point
  
    
    // 
    // 

    useEffect(()=>{
      setData([])
    },[groupName,token])
   
    useEffect(() => {
      
      // if ( heartRateStatus == "fetching"){ 
      //Send request to our websocket server using the "/request" path
      ws.current = new WebSocket(`${constants.SOCKET}/${groupName}/fitbit/read/${token}/`);
      

      ws.current.onopen = (item) => {
        
       let interval = setInterval(() => {
          if (ws.current) {
            if (ws.current.readyState == 3) {
              clearInterval(interval);
              
            }
            var messagehs = {
              handshake: Date.now(),
            };
            ws.current.send(JSON.stringify(messagehs));
          }
        }, 30000);
  
        // send data to server to echo back
     /*   ws.current.send(
          JSON.stringify({
            foo: "baz"
          })
        ); */
      
       
      };

      ws.current.onmessage = (ev) => {
        // 
        const message1 = JSON.parse(ev.data);
        let message= JSON.parse(message1.message);
        // 
       // 
        // Upon receiving websocket message then add it to the list of data that we are displaying
        var epochTS = message.TS;
        var d = new Date(epochTS);
        let tsnow =d.toLocaleTimeString()
        let newDataArray = [
          ...data,
          {
            id: tsnow,
            HeartRate: message.HR,
          },
        ];
      
        setData((currentData) => limitData(currentData, message));
        
        // dispatch(setHeartRate((currentData) => limitData(currentData, message)));
        // dispatch(setHeartRateStatus("success"))
      };
      ws.current.onclose = (ev) => {
        
      };
   
      //We limit the number of reads to the last 24 reading and drop the last read
      function limitData(currentData, message) {
        if (currentData.length > 24) {
          // 
          currentData.shift();
        }
        var epochTS = message.TS;
        var d = new Date(epochTS);
        let tsnow =d.toLocaleTimeString()
        return [
          ...currentData,
          {
            id: tsnow,
            HeartRate: message.HR,
          },
        ];
      }
   
      return () => {
        
        ws.current.close();
      };
    // }
    }, [groupName,token]);
// 
    const chartData=
    React.useMemo(()=>{
      return{
        title: [
          {
            text: data?.[(data?.length-1)]?.HeartRate,
            textStyle:{
              fontSize:35,
              fontWeight:'normal'
            },
            left:"30%",
            top:"25%",
            subtext:"bpm",
            textAlign:'center',
          },
        ],
    series: [
      {
        type: 'gauge',
        center: ['50%', '60%'],
        startAngle: 200,
        endAngle: -20,
        min: 0,
        max: 60,
        splitNumber: 12,
        itemStyle: {
          color: 'black'
        },
        progress: {
          show: false,
          width: 30
        },
        pointer: {
          show: false
        },
        axisLine: {
          show: false,
          lineStyle: {
            width: 30
          }
        },
        axisTick: {
           show: false,
          distance: -45,
          splitNumber: 5,
          lineStyle: {
            width: 2,
            color: '#999'
          }
        },
        splitLine: {
           show: false,
          distance: -52,
          length: 14,
          lineStyle: {
            width: 3,
            color: '#999'
          }
        },
        axisLabel: {
          distance: -20,
          color: '#999',
          fontSize: 20, 
          show: false
        },
        anchor: {
          show: false
        },
    
        detail: {
          valueAnimation: true,
          width: '40%',
          lineHeight: 40,
          borderRadius: 8,
          offsetCenter: [-10, '-180%'],
          fontFamily:'sans-serif',
          fontSize: 20,
          fontWeight: 'bolder',
          formatter: '{value} bpm',
          color: 'black'
          
        },
       
      },
      
    ]
  };
})

const fitbitHeartData = useMemo(() => {
  return {
    title:[{
        left: '5%',
        text: 'Heart Rate',
        top: 0,
      
        textStyle: {
          fontWeight: 'normal',
          fontSize: 28,
          }
      },
      {subtext: heartRateDaily?.data?.x_data?.time_gap + ' ago', left: "right", bottom: "5%",fontSize: 12 },
      {subtext: heartRateDaily?.data?.y_data?.status , left: "left", bottom: "5%",fontSize: 12 },
      {
      
          subtext: "bpm",
          left: "center",
          top: "53%",
          // bottom: "18%",
          fontSize: 15,
        
      }
    ],
    tooltip: {
      textStyle: {
        fontFamily: "sans-serif",
      },
    },
    series: [
      {
        type: "gauge",
        center: ['50%', '50%'],
        radius:"55%",
        guageCategoryGap: "0%",
        startAngle: 360,
        endAngle: 0,
        min: false,
        max: 1,
        splitNumber: 12,
        itemStyle: {
          color: "#6D3DA3",
          shadowColor: "",
          shadowBlur: 0,
          shadowOffsetX: 0,
          shadowOffsetY: 0,
        },
        progress: {
          show: true,
          width: 9,
          
        },
        pointer:{
          show:false
        },
        axisLine: {
          show:false,
        },
        axisTick: {
          show: false
        },
        splitLine: {
          show:false,
          length: 15
        },
        axisLabel: {
          show:false,
          distance: 20,
          color: 'red',
          fontSize: 20
        },
        anchor: {
          show: false,
          showAbove: false,
          size: 20,
          itemStyle: {
            borderWidth: 10
          }
        },
        title: {
          show: false
        },
        detail: {
          valueAnimation: true,
          fontSize: 38,
          fontWeight:"normal",
          color:" #434242",
          // color:'rgb(99, 99, 99)',
          offsetCenter: [0, '5%']
        },
        data: [
          {
            name:'Heart Rate',
            value: heartRateDaily?.data?.y_data?.HR,
          
          }
        ]
      }
    ]
  
}})
  //  
    //Display the chart using rechart.js
    
    if(!(fitbitHeartData?.series[0]?.data[0]?.value))
      return null

    if(!data[0]?.HeartRate){
      return <div>
        <div className={'dis-daily-chart' +(' alarm-status-'+alarmStatus)} >
  <div className="popup-bg" style={{display:'flex', justifyContent: 'right'}}>
    <ExpandMorePopup 
       option1={<PopupBG optionName="Manual Entry" FormTitle="Heart Rate" FormType={<FormHR handleClose={handleClose} careTakerPatientView={careTakerPatientView}/>} />}
       option2={<TileRemove
        careTakerPatientView={careTakerPatientView}
        parameter="HR" />} /></div>
  <div onClick={onClick}><ReactEcharts option={fitbitHeartData}/> </div>
  </div>
  </div>

    }
   
    // if(!(chartData?.title?.text)){
    //   return  <div><ReactEcharts option={fitbitHeartData}/> </div>
    // }
    
    return (
      <div className={"dis-daily-chart"  +(' alarm-status-'+alarmStatus)}>
         <div className="popup-bg" style={{display:'flex', justifyContent: 'right'}}>
          <ExpandMorePopup 
          option1={<PopupBG optionName="Manual Entry" FormTitle="Heart Rate" FormType={<FormHR handleClose={handleClose} careTakerPatientView={careTakerPatientView} />} />}
          option2={<TileRemove
            careTakerPatientView={careTakerPatientView}
            parameter="HR" />}  /></div>
      <div className="name1" style={{ textAlign:"left",paddingLeft:20}} onClick={onClick}>Heart Rate</div>
     
      <div 
      style={{maxWidth: 500  , height: 260, display:'flex' }} >
       
        <div style={{flex:3}}>
        <ResponsiveContainer >
         
         <LineChart
           width={600}
           height={400}
           data={data}
           margin={{
             top:35,
             right:20,
             left: 0,
             bottom: 45,
           
           }}
         >
           {/* <CartesianGrid strokeDasharray="3 3" /> */}
           <XAxis  tick={{fontSize:12}} dataKey="id" 
          //  label={{ value: "Time", position: "insideBottomCenter", dy: 14, fill: '#777'}} 
           /> 
           <YAxis tick={{fontSize:14}} 
              // label={{ value: "Heart Rate", position: "insideLeftCenter", angle: -90, dx: -12, fill: '#777'}}
              />
           <Tooltip>{{fontFamily: "sans-serif"}}</Tooltip>  
          
           
           <Line
           
             type="monotone"
             dataKey="HeartRate"
             stroke="#6D3DA3"
             activeDot={{ r: 14 }}
             strokeWidth="4"
             
             
           />
           {/* <Line type="monotone" dataKey="uv" stroke="#82ca9d" /> */}
         </LineChart>
        
       </ResponsiveContainer>
        </div>
        <div style={{flex:1}}><ReactEcharts option={chartData}/> </div>
      </div>
    
  </div>
    );
    
}



export default HeartrateChart1
import React, { useState } from "react";
import styles from "./ForgotPassword.module.css";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useHistory, useLocation } from "react-router-dom";
import constants from "../../../../../../Common/Constants";
import axios from "axios";

export const ResetPassword = () => {
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [error, setError] = useState("");
  const otp_token = queryParams.get("otp_token") || "";
  const id = queryParams.get("id") || "";
  const token = queryParams.get("token") || "";
  const handleSignIn = () => {
    history.push("/");
  };

  const toggleNewPasswordVisibility = () => {
    setNewPasswordVisible(!newPasswordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
    // Check if passwords match and update the error state
    setPasswordsMatch(e.target.value === confirmPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    // Check if passwords match and update the error state
    setPasswordsMatch(e.target.value === newPassword);
  };

  const resetPassword = async () => {
    // Check if passwords match
    if (!passwordsMatch) {
      setError("Passwords do not match. Please try again.");
      return;
    }

    // Send a POST request to reset the password API with otp_token and newPassword
    try {
      const apiUrl = `${constants.API_URL}/reset_password/${id}/${token}/`;
      const response = await axios.post(apiUrl, {
        otp_token,
        password: newPassword,
      });
    
      if (response.status === 201) {
        // Handle the case where the password reset was successful
        setShowSnackbar(true);
        setSnackbarMessage("Password successfully changed.");
        
        // Automatically close the snackbar after 5 seconds
        setTimeout(() => {
          setShowSnackbar(false);
        }, 5000);
    
        // Password reset successful, you can handle it accordingly
        setTimeout(() => {
          history.push("/");
        }, 3000);
      } else if (response.status === 400 && response?.data?.message === "Old password, please enter another one.") {
        // Handle the case where the old password matches the new one
        setShowSnackbar(true);
        setSnackbarMessage("The password is the same as the old password, try a new one!");
    
        // Automatically close the snackbar after 5 seconds
        setTimeout(() => {
          setShowSnackbar(false);
        }, 5000);
      } else {
        // Handle other error cases
        setError("Password reset failed. Please try again.");
      }
    } catch (error) {
      console.error("Error resetting password:", error);
      setShowSnackbar(true);
      setSnackbarMessage("Error resetting password. Please try again.");
    
      // Automatically close the snackbar after 5 seconds
      setTimeout(() => {
        setShowSnackbar(false);
      }, 5000);
    
      setError("Error resetting password. Please try again.");
    }
    
  };

  return (
    <div className={styles.mainContainerForgot}>
      <div className={styles.mobileViewForgot}>
        <div>
          <span
            style={{
              color: "#6A4396",
              fontWeight: "700",
              fontFamily: "'Montserrat', sans-serif",
              fontSize: "1.6rem",
            }}
          >
            Care
          </span>
          <span
            style={{
              color: "#23AF72",
              fontWeight: "700",
              fontFamily: "'Montserrat', sans-serif",
              fontSize: "1.6rem",
            }}
          >
            HQ
          </span>
        </div>

        <div className={styles.resetDetail}>
          <div className={styles.forgotHeading}>Reset Password</div>

          {/* New Password */}
          <div className={styles.inputContainer}>
            <input
              type={newPasswordVisible ? "text" : "password"}
              placeholder="New Password"
              className={styles.passwordField}
              value={newPassword}
              onChange={handleNewPasswordChange}
            />
            <span
              className={styles.passwordToggle}
              onClick={toggleNewPasswordVisibility}
            >
              {newPasswordVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </span>
          </div>

          {/* Confirm Password */}
          <div className={styles.inputContainer}>
            <input
              type={confirmPasswordVisible ? "text" : "password"}
              placeholder="Confirm Password"
              className={styles.passwordField}
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
            />
            <span
              className={styles.passwordToggle}
              onClick={toggleConfirmPasswordVisibility}
            >
              {confirmPasswordVisible ? (
                <VisibilityOffIcon />
              ) : (
                <VisibilityIcon />
              )}
            </span>
          </div>

          {/* Error Message */}
          {!passwordsMatch && (
            <div className={styles.errorMessage}>
              Passwords do not match. Please try again.
            </div>
          )}
        </div>

        <div className={styles.resetBtn} onClick={resetPassword}>
          Reset
        </div>
        {showSnackbar && (
          <div
            style={{
              position: "fixed",
              bottom: "50px",
              left: "50%",
              transform: "translateX(-50%)",
              backgroundColor: "green",
              color: "white",
              padding: "12px",
              borderRadius: "25px",
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
              zIndex: 9999,
              fontFamily: "Montserrat, sans-serif",
              fontSize: "small",
            }}
          >
            {snackbarMessage}
          </div>
        )}

        <div className={styles.backBtn} onClick={handleSignIn}>
          Back to Login
        </div>
      </div>
    </div>
  );
};

import React, { useState, useEffect } from "react";
import styles from "./BMIChartPopup.module.css";
import axios from "axios";
import constants from "../../../../Common/Constants";
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import { endOfDay, format } from "date-fns";
import { BMIContainer } from "./BMIContainer";
import { MoreHoriz } from "@material-ui/icons";
import { Snackbar } from "@mui/material";

const BMIChartPopup = () => {
  const [tabValue, setTabValue] = useState("week");
  const [refreshId,setId]= useState(0);
  const [monthData, setMonthData] = useState({
    xData: [],
    yData: [],
    bmiRes: [],
  });
  const [weekData, setWeekData] = useState({ xData: [], yData: [], bmiRes: [] });
  const [threeMonthData, setThreeMonthData] = useState({
    xData: [],
    yData: [],
    bmiRes: [],
  });
  const groupName = useSelector((state) => state.auth.groupName);
  const token = useSelector((state) => state.auth.authToken);
  const careTakerToken = useSelector((state) => state.auth.careTakerToken);
  const currentUserIndex = useSelector((state) => state.auth.currentUserIndex);
  const dateHR = format(endOfDay(new Date()), "ddMMyyy");
  const userData = useSelector((state) => state.auth.userData);
  const location = useLocation();
  
  const urlStateData = location?.state;
  const { id, patient_group_id } = useParams();
  
  const patientView = location?.state;

  

  const viewOnly = location?.state;
  const {pat_id} = useParams();



  const handleChange = (value) => {
    setTabValue(value);
  };
  const [showPopup, setShowPopup] = useState(false);

  const openPopup = () => {
    if (viewOnly?.viewOnly === true) {
      setShowPopup(false)
    } else {
      setShowPopup(true);
    }
  };
  
  const closePopup = (event) => {
    event.stopPropagation();
    setShowPopup(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [response, setResponse] = useState(null);
  const [open, setOpen] = React.useState(false);

  let x = [];
  let y = [];
  let bmiResult = [];

  useEffect(() => {
    if (tabValue === "week" && !weekData?.xData?.length) {
      let url = `${constants.API_URL}/week_data/BMI/${
        viewOnly?.viewOnly? pat_id : urlStateData?.patientView ? patient_group_id : groupName
      }/`;

      url += `${dateHR}/${token}/`;
      axios.get(url).then((res) => {
        res.data.week_data?.forEach((data) => {
          var epochTS = data.x_data;
          var d = new Date(parseInt(epochTS));

          let dsnow = `${d.toLocaleDateString()}\n${d.toLocaleTimeString()}`;

          x.push(dsnow);
          y.push(data.y_data.BMI.toFixed(1));
          bmiResult.push(data);
        });

        setWeekData({ xData: x, yData: y, bmiRes: bmiResult });
      });
    } else if (tabValue === "month" && !monthData?.xData?.length) {
      let url = `${constants.API_URL}/month_data/BMI/${
        viewOnly?.viewOnly? pat_id : urlStateData?.patientView ? patient_group_id : groupName
      }/`;

      url += `${dateHR}/${token}/`;
      axios.get(url).then((res) => {
        res.data.month_data?.forEach((data) => {
          var epochTS = data.x_data;
          var d = new Date(parseInt(epochTS));

          let tsnow = d.toLocaleTimeString();

          let dsnow = `${d.toLocaleDateString()}\n${d.toLocaleTimeString()}`;

          x.push(dsnow);
          y.push(data.y_data.BMI.toFixed(1));
          bmiResult.push(data);
        });

        setMonthData({ xData: x, yData: y, bmiRes: bmiResult });
      });
    } else if (tabValue === "threeMonth" && !threeMonthData?.xData?.length) {
      let url = `${constants.API_URL}/three_month_data/BMI/${
        viewOnly?.viewOnly? pat_id : urlStateData?.patientView ? patient_group_id : groupName
      }/`;
      let activeUserDeviceList = userData?.[currentUserIndex]?.["device_list"];

      url += `${dateHR}/${token}/`;
      axios.get(url).then((res) => {
        res.data.three_month_data?.forEach((data) => {
          var epochTS = data.x_data;
          var d = new Date(parseInt(epochTS));

          let tsnow = d.toLocaleTimeString();

          let dsnow = `${d.toLocaleDateString()}\n${d.toLocaleTimeString()}`;

          x.push(dsnow);
          y.push(data.y_data.BMI.toFixed(1));
          bmiResult.push(data);
        });

        setThreeMonthData({ xData: x, yData: y, bmiRes: bmiResult });
      });
    }
  }, [tabValue]);

  const deleteLastEntry = () => {
    axios
      .get(
        `${constants.API_URL}/delete_latest_manual_entry/${ urlStateData?.patientView? patient_group_id : groupName}/BMI/${token}/`
      )
      .then((res) => {
        if (res.data.message === "success") {
          setResponse("success");
          setOpen(true);
          // Update the weekData state to reflect the changes
          const updatedWeekData = { ...weekData };
          updatedWeekData.xData.pop(); // Remove the last entry from xData
          updatedWeekData.yData.pop(); // Remove the last entry from yData
          updatedWeekData.bmiRes.pop(); // Remove the last entry from bmiResult
          setWeekData(updatedWeekData);
           // Update the monthData state to reflect the changes
           const updatedMonthData = { ...monthData };
           updatedMonthData.xData.pop(); // Remove the last entry from xData
           updatedMonthData.yData.pop(); // Remove the last entry from yData
           updatedMonthData.bmiRes.pop(); // Remove the last entry from bmiResult
           setMonthData(updatedMonthData);
            // Update the threeMonthData state to reflect the changes
          const updatedThreeMonthData = { ...threeMonthData };
          updatedThreeMonthData.xData.pop(); // Remove the last entry from xData
          updatedThreeMonthData.yData.pop(); // Remove the last entry from yData
          updatedThreeMonthData.bmiRes.pop(); // Remove the last entry from bmiResult
          setThreeMonthData(updatedThreeMonthData);

          setId(Math.random()*600);
          
        } else if (res.data.message === "No data found") {
          setResponse("noData");
          setOpen(true);
        } else {
          setResponse("error");
          setOpen(true);
        }
      })
      .catch((error) => {
        setResponse("error");

        setOpen(true);
  
      });
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.heading}>
        <div>Body Mass Index</div>
        <div style={{ color: "grey", cursor: "pointer" }}>
          <MoreHoriz onClick={openPopup} />
        </div>
      </div>
      <div className={styles.contentDiv}>
        <div className={styles.tabContainer}>
          <div
            className={`${styles.tab} ${
              tabValue === "week" && styles.activeTab
            }`}
            onClick={() => handleChange("week")}
          >
            WEEK
          </div>
          <div
            className={`${styles.tab} ${
              tabValue === "month" && styles.activeTab
            }`}
            onClick={() => handleChange("month")}
          >
            MONTH
          </div>
          <div
            className={`${styles.tab} ${
              tabValue === "threeMonth" && styles.activeTab
            }`}
            onClick={() => handleChange("threeMonth")}
          >
            3 MONTHS
          </div>
        </div>
        <div className={styles.tabContent} key={refreshId}>
        
          {tabValue === "week" && (
            <BMIContainer
              title="Weekly Report"
              bmiRes={weekData?.bmiRes}
              xData={weekData?.xData}
              yData={weekData?.yData}
              type="week"
            />
          )}
          {tabValue === "month" && (
            <BMIContainer
              title="Monthly Report"
              bmiRes={monthData?.bmiRes}
              xData={monthData?.xData}
              yData={monthData?.yData}
              type="month"
            />
          )}
          {tabValue === "threeMonth" && (
            <BMIContainer
              title="3 Months Report"
              bmiRes={threeMonthData?.bmiRes}
              xData={threeMonthData?.xData}
              yData={threeMonthData?.yData}
              type="threeMonth"
            />
          )}
        </div>
      </div>
      {showPopup && (
        <div className={styles.popup}>
          <div className={styles.popupOption} onClick={deleteLastEntry}>
            Delete Last Entry
          </div>
          <div
            className={styles.popupOption}
            onClick={(event) => closePopup(event)}
          >
            Cancel
          </div>
        </div>
      )}
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        message={
          response === "success"
            ? "Successfully deleted the last entry!"
            : "No recent data found!"
        }
      />
    </div>
  );
};

export default BMIChartPopup;

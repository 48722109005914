import React from 'react'
import './FitbitDashboard.css' ;
import {Topbar} from './FitCharts/Topbar'
import FNewDash from './FitCharts/FNewDash';




const FitbitDashboard = () => {
  
  return (
    
  <div> 
    
     <div className='back'>
     
  
     <div className='container-dash'>
     
      <FNewDash/>
      
     </div>
     </div>
     <Topbar/>
    </div>
    
  )
}

export default FitbitDashboard
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CardTopHeader from "./CardTopHeader";
import GraphContainer from "./GraphContainer";
import Grid from "@material-ui/core/Grid";
import GraphStatsColumn from "./GraphStatsColumn";
import { setBedDeviceView } from "../../store/authSlice";
import {
  setSelectedBed,
  setSelectedDeviceId,
  setSelectedDeviceType,
} from "../../store/settingsSlice";
import "./Home.css";

function GraphDashboard({ singleData }) {
  const dispatch = useDispatch();

  const bedDeviceList = useSelector((state) => state.home.bedDeviceList);
  const deviceTypeList = useSelector((state) => state.home.deviceTypeList);
  const [alertBorder, setAlertBorder] = useState("");
  const [bedId, setBedId] = useState(null);

  useEffect(() => {
    bedDeviceList.map((device) => {
      if (device.deviceId === singleData.name) {
        setBedId(device.bedId);
      }
    });
  }, [singleData, JSON.stringify(singleData)]);

  const handleDeviceSelection = (deviceName) => {
    if (bedId !== undefined) {
      let bedElementId = "bedCardPanel" + bedId;
      var bedElement = document.getElementById(bedElementId);
      bedElement.scrollIntoView();
    }
    dispatch(setSelectedBed(bedId));
    dispatch(setBedDeviceView(true));
    deviceTypeList.map((device) => {
      if (device.deviceId === deviceName) {
        let selectedDeviceType = device.deviceType;
        dispatch(setSelectedDeviceType(selectedDeviceType));
        dispatch(setSelectedDeviceId(device.deviceId));
      }
    });
  };

  return (
    <Grid item xs={12} sm={12} md={6} lg={4} key={singleData.name}>
      <div
        className="gridDashboard_GridCell"
        style={{ border: alertBorder }}
        key={singleData.name}
      >
        <CardTopHeader
          deviceName={singleData.name}
          alarmCount={singleData.alarmCount}
          priorityAlarm={singleData.priorityAlarm}
          setAlertBorder={setAlertBorder}
          isActive={singleData.active}
          cycleNum={singleData.cycleNo}
          bedId={bedId}
        />
        {singleData.active && (
          <>
            {bedId === undefined ? (
              <Link
                className="link"
                // to={`/device/${singleData.name}`}
                // onClick={() => handleDeviceSelection(singleData.name)}
              >
                {singleData.deviceType !== "vitalcam" ? (
                  <GraphContainer data={singleData} />
                ) : (
                  <img
                    alt=" "
                    src={`data:image/jpeg;base64,${singleData.graphData[0]}`}
                    style={{
                      height: "100%",
                      width: "70%",
                      objectFit: "cover",
                      maxHeight: "22vh",
                    }}
                  />
                )}
                <GraphStatsColumn data={singleData} />
              </Link>
            ) : (
              <Link
                className="link"
                to={`/bed/${bedId}`}
                onClick={() => handleDeviceSelection(singleData.name)}
              >
                {singleData.deviceType !== "vitalcam" ? (
                  <GraphContainer data={singleData} />
                ) : (
                  <img
                    alt=" "
                    src={`data:image/jpeg;base64,${singleData.graphData[0]}`}
                    style={{
                      height: "100%",
                      width: "70%",
                      objectFit: "cover",
                      maxHeight: "22vh",
                    }}
                  />
                )}
                <GraphStatsColumn data={singleData} />
              </Link>
            )}
          </>
        )}
        {!singleData.active && (
          <div className="gridDashboard_DeviceOffline">
            <p>Device Offline</p>
          </div>
        )}
      </div>
    </Grid>
  );
}

export default React.memo(GraphDashboard);

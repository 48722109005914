import React from "react";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { Badge, IconButton, makeStyles, styled } from "@material-ui/core";

const Item = styled("div")(({ theme }) => ({
  padding: "8px 12px",
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  color: "white",
}));

const useStyles = makeStyles((theme) => ({
  stack: {
    ["@media (max-width:900px)"]: {
      flexWrap: "wrap",
    },
  },
}));

function VitalCamHeader({ deviceName, cycleNo, alarmCount, alertsList }) {
  const classes = useStyles();

  return (
    <div className="flexitems-between ">
      <div>
        <h3>VitalCam - {deviceName} </h3>
      </div>
      <div className="flexitems-between flex-wrap" style={{ marginLeft: "0" }}>
        <div direction="row" spacing={1}>
          <Item>
            <IconButton style={{ color: "white", padding: "0" }}>
              <Badge badgeContent={alarmCount} color="primary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
          </Item>
        </div>

        <div style={{ paddingLeft: "15px" }}>
          <div className="flexitems-baseline">
            <p style={{ margin: "0" }}>Cycle No: </p>
            &nbsp;
            <p>{cycleNo}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VitalCamHeader;

import React from "react";
import { useState, useEffect } from "react";
import ReactEcharts from "echarts-for-react";
import "./FNewDash.css";

import { useSelector, useDispatch } from "react-redux";
import {
  format,
  endOfDay,
} from "date-fns";
import { useMemo } from "react";
import DistanceDefault from "./DefaultCharts/DistanceDefault";

function DisChartHourly() {
  const [data, setData] = useState([]);
  const groupName = useSelector((state) => state.auth.groupName);
  const token = useSelector((state) => state.auth.authToken);
  const dateHR = format(endOfDay(new Date()), "ddMMyyy");

  const dispatch = useDispatch();
  const distanceDaily = useSelector((state) => state.dashboard.distanceDaily);
 

  const distanceDailyUpdated = useMemo(() => {
    let xData = [];
    let yData = [];

    distanceDaily?.data?.forEach((fitbitData) => {
      xData.push(fitbitData.x_data);
      yData.push(fitbitData.y_data);
    });
    return { x: xData, y: yData };
  }, [distanceDaily]);

  const fitbitDistanceData = useMemo(() => {
    return {
      title: {
        left: "center",
        text: "Distance",
        subtext:
          "TotalDistance: " + (distanceDaily?.total / 1000).toFixed(1) + "km",
        top: 5,
        textStyle: {
          fontFamily: "sans-serif",
        },
      },
      tooltip: {
        trigger: "axis",
        textStyle: {
          fontFamily: "sans-serif",
        },
        axisPointer: {
          type: "shadow",
        },
      },

      

      grid: {
        left: "14%",
        right: "5%",
        bottom: "20%",
      },
      xAxis: {
        type: "category",
        data: distanceDailyUpdated?.x,
        axisLabel: {
          fontFamily: "sans-serif",
        },
      },
      yAxis: {
        type: "value",
        axisLabel: {
          fontFamily: "sans-serif",
        },
      },

      series: [
        {
          name: "Distance in meters",
          barWidth: "93%",
          type: "bar",
          color: "#27A9E3",
          symbol: "none",
          data: distanceDailyUpdated?.y,
        },
      ],
    };
  }, [distanceDailyUpdated]);

 

  return (
    <div>
      {distanceDailyUpdated?.x?.length ? (
        <div className="dis-daily-chart">
          <ReactEcharts option={fitbitDistanceData} />
        </div>
      ) : (
        <DistanceDefault/>
      )}
    </div>
  );
}

export default DisChartHourly;

import { display } from '@mui/system';
import axios from 'axios';
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import constants from '../../../../Common/Constants';
import { useLocation, useParams } from 'react-router-dom/cjs/react-router-dom';

export default function UserData({careTakerPatientView=false,profileID,profileName}) {
    
    const [userProfileData, setUserProfileData]= useState([])
    const headerGroupName = useSelector((state) => state.auth.headerGroupName);
    const groupName = useSelector((state) => state.auth.groupName);
    const token = useSelector((state) => state.auth.authToken);

    const careTakerToken=useSelector((state) => state.auth.careTakerToken);

      
    const location = useLocation();
    const urlStateData = location?.state
    console.log("URLSTATE",urlStateData)
  
    const { id,patient_group_id } = useParams();

    React.useEffect(() => {
        axios.get(`${constants.API_URL}/view_profile/${profileID}/${token}`).then((res) => {
          console.log("proData:",res)
          setUserProfileData(res?.data?.profile_data)
        });
      }, [groupName]);

      console.log(userProfileData)

      const userName = userProfileData?.name
      const userDob = userProfileData?.dob
      const userHeight = userProfileData?.height
      const userWeight = userProfileData?.weight
      const usereEmail = userProfileData?.email
      const userContact = userProfileData?.contact
 

  return (
    <>
        <div className='' style={{ justifyContent: "left", textAlign: "left", fontSize: "1.2rem", fontFamily: "'Montserrat', sans-serif",color:"#594000", fontWeight: "500" }}>
      {/* {urlStateData?patient_group_id:headerGroupName} */}
      {profileName}
  </div>
  <div  style={{justifyContent: "left", textAlign: "left", fontSize: "1rem", fontFamily: "'Montserrat', sans-serif",color:"#7F55AE", fontWeight: "500", margin:"5px", marginLeft:"2px"}}>Personal Details</div>
  <ul style={{listStyleType:"disc", paddingInlineStart:"0px", padding:"2px", display:"flex",flexDirection:"column", gap:"2px"}}>
    <li style={{display:"flex", alignItems:"center", gap:"10px"}}>
      <span style={{fontSize: "0.8rem", fontFamily: "'Montserrat', sans-serif", fontWeight: "400"}}>DOB:</span>
      <span style={{fontSize: "0.8rem", fontFamily: "'Montserrat', sans-serif", fontWeight: "500"}}>{userDob ? userDob : "-"}</span>
    </li>
    <li style={{display:"flex", alignItems:"center", gap:"10px"}}>
      <span style={{fontSize: "0.8rem", fontFamily: "'Montserrat', sans-serif", fontWeight: "400"}}>HEIGHT:</span>
      <span style={{fontSize: "0.8rem", fontFamily: "'Montserrat', sans-serif", fontWeight: "500"}}>{userHeight ? userHeight+"cm" : "-"}</span>
    </li>
    <li style={{display:"flex", gap:"10px"}}>
      <span style={{fontSize: "0.8rem", fontFamily: "'Montserrat', sans-serif", fontWeight: "400"}}>EMAIL:</span>
      <span style={{overflowWrap:"anywhere", textAlign:"left",fontSize: "0.8rem", fontFamily: "'Montserrat', sans-serif", fontWeight: "500"}}>{usereEmail ? usereEmail : "-"}</span>
    </li>
    <li style={{display:"flex", gap:"10px"}}>
      <span style={{fontSize: "0.8rem", fontFamily: "'Montserrat', sans-serif", fontWeight: "400"}}>CONTACT:</span>
      <span style={{overflowWrap:"anywhere", textAlign:"left",fontSize: "0.8rem", fontFamily: "'Montserrat', sans-serif", fontWeight: "500"}}>{userContact ? userContact : "-"}</span>
    </li>
  </ul>
    </>
  )
}

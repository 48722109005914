import React, { useState, useEffect } from "react";
import StarIcon from "@material-ui/icons/Star";
import SingleBedIcon from "@material-ui/icons/SingleBed";
import TimelapseIcon from "@material-ui/icons/Timelapse";
import { IconButton } from "@material-ui/core";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import CardTopAlarm from "./CardTopAlarm";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import {
  addGroupDevices,
  addPageDevices,
  addPageDeviceFavorite,
  addDeviceList,
} from "../../store/authSlice";
import { addStatsPageDevices } from "../../store/statsSlice";
import constants from "../../Common/Constants";

function CardTopHeader({
  deviceName,
  alarmCount,
  priorityAlarm,
  cycleNum,
  isActive,
  setAlertBorder,
  bedId,
}) {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.authToken);
  const favoritelist = useSelector((state) => state.auth.pageDeviceFavorite);
  const deviceList = useSelector((state) => state.auth.deviceList);
  const deviceTypeList = useSelector((state) => state.home.deviceTypeList);
  const presentPage = useSelector((state) => state.auth.presentPage);
  const [deviceType, setDeviceType] = useState(null);

  useEffect(() => {
    let alertBorderColor = "";
    if (isActive) {
      if (priorityAlarm > 3) alertBorderColor = "4px solid red";
      else {
        switch (priorityAlarm) {
          case 0:
            alertBorderColor = "4px solid #0C5FE6";
            break;
          case 1:
            alertBorderColor = "4px solid yellow";
            break;
          case 2:
            alertBorderColor = "4px solid red";
            break;
          default:
            alertBorderColor = "";
        }
      }
      setAlertBorder(alertBorderColor);
    }
  }, [priorityAlarm]);

  useEffect(() => {
    deviceTypeList.map((device) => {
      if (device.deviceId == deviceName){
        let thisDeviceType = "";
        switch (device.deviceType) {
          case "iremon":
            thisDeviceType = "Ventilator";
            break;
          case "vitalcam":
            thisDeviceType = "VitalCam";
            break;
          case "isave":
            thisDeviceType = "iSave";
            break;
          case "capnometer":
            thisDeviceType = "Capnometer";
            break;
          case "patientmon":
            thisDeviceType = "PatientMon";
            break;
          case "ventilator":
            thisDeviceType = "Ventilator";
            break;
          case "rhemos":
            thisDeviceType = "Rhemos";
            break;
          default:
            thisDeviceType = "";
        }
        setDeviceType(thisDeviceType);
      }
    });
  }, [deviceName]);

  function setDeviceFavorite(id, status) {
    let data1 = { device_id: id, status: status };
    axios({
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      url: `${constants.API_URL}/bookmark_device/${token}/`,
      data: data1,
    }).then(
      (response) => {
        const { data = {} } = response;
        if (data.success) {
          // arr = arr.filter(item => item !== value)
          let tempDeviceList = deviceList.filter((item) => item.device_type !== "fitbit" && item.device_type !== "blehub")
          let newDeviceList = tempDeviceList.filter((item) => item.device_id != id);
          // 
          // 
          
          if (status) {
            newDeviceList.push({ is_favorite: true, device_id: id });
            dispatch(addPageDeviceFavorite([...favoritelist, id]));
          } else {
            newDeviceList.push({ is_favorite: false, device_id: id });
            let newFavoriteList = favoritelist.filter((item) => item != id);
            dispatch(addPageDeviceFavorite(newFavoriteList));
          }
          newDeviceList.sort((a, b) => (a.device_id > b.device_id ? 1 : -1));
          newDeviceList.sort((a, b) => b.is_favorite - a.is_favorite);
          let groupDevice = [],
            pageDevice = [],
            count = 1,
            i = 0;
          newDeviceList.map((device) => {
            groupDevice.push(device.device_id);
          });
          if (presentPage != 1) {
            i = (presentPage - 1) * 9;
          }
          for (; i < groupDevice.length; i++) {
            if (count <= 9) {
              pageDevice.push(groupDevice[i]);
              count++;
            } else {
              i = groupDevice.length;
            }
          }
          let graphPageDevice = [],
            count1 = 1,
            j = 0;
          for (; j < groupDevice.length; j++) {
            if (count1 <= 12) {
              graphPageDevice.push(groupDevice[j]);
              count1++;
            } else {
              j = groupDevice.length;
            }
          }
          dispatch(addStatsPageDevices(graphPageDevice));
          dispatch(addGroupDevices(groupDevice));
          dispatch(addPageDevices(pageDevice));
          dispatch(addDeviceList(newDeviceList));
        }
      },
      (error) => {
        
      }
    );
  }

  return (
    <>
      <div className="gridCell_Header gridCell_Header-Medium">
        <div className="gridCell_DeviceTitle">
          <p>
            {deviceType}: {deviceName}
          </p>
        </div>
        <div className="gridCell_AlertDiv">
          {alarmCount > 0 && <CardTopAlarm alarmCount={alarmCount} />}
        </div>

        <div className="flexitems-start">
          <div className="gridCell_BedDiv gridCell_CycleDiv">
            <IconButton>
              <TimelapseIcon />
            </IconButton>
            <p>Cycle No: {cycleNum}</p>
          </div>

          <div className="gridCell_BedDiv">
            <IconButton>
              <SingleBedIcon />
            </IconButton>
            {bedId === undefined ? <p>Bed: -- </p> : <p>Bed: #{bedId}</p>}
          </div>
          <div className="gridCell_FavoriteDiv">
            {favoritelist.indexOf(deviceName) != -1 ? (
              <div className="gridCell_FavoritedDiv">
                <StarIcon
                  fontSize="inherit"
                  onClick={() => setDeviceFavorite(deviceName, false)}
                />
              </div>
            ) : (
              <div className="gridCell_NotFavoritedDiv">
                <StarBorderIcon
                  fontSize="inherit"
                  onClick={() => setDeviceFavorite(deviceName, true)}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="gridCell_Header gridCell_Header-Small">
        <div className="w-100 flexitems-between">
          <div className="gridCell_DeviceTitle">
            <p>
              {deviceType}: {deviceName}
            </p>
          </div>
          <div className="gridCell_AlertDiv">
            {alarmCount > 0 && <CardTopAlarm alarmCount={alarmCount} />}
          </div>

          <div className="gridCell_FavoriteDiv">
            {favoritelist.indexOf(deviceName) != -1 ? (
              <div className="gridCell_FavoritedDiv">
                <StarIcon
                  fontSize="inherit"
                  onClick={() => setDeviceFavorite(deviceName, false)}
                />
              </div>
            ) : (
              <div className="gridCell_NotFavoritedDiv">
                <StarBorderIcon
                  fontSize="inherit"
                  onClick={() => setDeviceFavorite(deviceName, true)}
                />
              </div>
            )}
          </div>
        </div>
        <div className="flexitems-start" style={{ paddingTop: "6px" }}>
          <div className="gridCell_BedDiv gridCell_CycleDiv">
            <IconButton>
              <TimelapseIcon />
            </IconButton>
            <p>Cycle No: {cycleNum}</p>
          </div>

          <div className="gridCell_BedDiv">
            <IconButton>
              <SingleBedIcon />
            </IconButton>
            {bedId === undefined ? <p>Bed: -- </p> : <p>Bed: #{bedId}</p>}
          </div>
        </div>
      </div>
    </>
  );
}
export default React.memo(CardTopHeader);

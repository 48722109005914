import React from 'react'
import IconButton from '@mui/material/IconButton';
import { MoreHoriz } from "@material-ui/icons";
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import PopupBG from '../components/Member Details/Comps/FitbitFolder/FitCharts/PopBG';


export default function ExpandMorePopup({option1,option2}) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
  return (
   <React.Fragment>
     <IconButton aria-describedby={id} onClick={handleClick}>
        <MoreHoriz />
     </IconButton>
     <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {/* <Typography sx={{ p: 2 }}>The content of the Popover.</Typography> */}
        <div className='cardDropdown'>
        <div>{option1}</div>
        <div>{option2}</div>
        {/* <div></div>
        <div></div> */}
        </div>
      </Popover>
   </React.Fragment>
  )
}

import React, { useEffect, useState } from "react";
import styles from "./Registerv2.module.css";
import * as Yup from "yup";
import { Formik, ErrorMessage, Form } from "formik";
import axios from "axios";
import constants from "../../../../../Common/Constants";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Button, IconButton, makeStyles } from "@material-ui/core";
// import BreadcrumbsTop from "../BreadcrumbsTop";
// import { PopProfileSave } from "../../../";
// import Header from "../../../../../Header/Header";
import Headerv2 from "../../../../home/V2Components/Headerv2";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import {
  addAuthToken,
  addGroupName,
  addHeaderGroupName,
  setIsDoctor,
  setIsPatient,
} from "../../../../../store/authSlice";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import { Switch } from "@mui/material";
import { Alert } from '@material-ui/lab';
import { Snackbar } from '@material-ui/core';
import PhoneInput from "react-phone-input-2";



const useStyles = makeStyles((theme) => ({
  root: {
    alignSelf: "center",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  container: {
    position: "relative",
    display: "inline-block",
  },
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  cameraIcon: {
    position: "absolute",
    bottom: "10%",
    right: "15%",
    transform: "translate(50%, 50%)",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: "50%",
    padding: theme.spacing(1),
    cursor: "pointer",
    display: "flex",
    height: "20px",
    width: "20px",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const VALIDATION_SCHEMA = Yup.object().shape({
  name: Yup.string().required(),
  dob: Yup.string().nullable(),
  height: Yup.number().nullable().min(3, "Height must be at least 30 cm")
  .max(300, "Height cannot exceed 300 cm"),
  weight: Yup.number().nullable().min(3, "Weight must be at least 3 kg")
  .max(300, "Weight cannot exceed 300 kg"),
  contact: Yup.string().nullable(),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  caretaker: Yup.string().nullable(),
  heart_disease: Yup.string().nullable(),
  cholesterol: Yup.boolean().nullable(),
  diabetes: Yup.boolean().nullable(),
  cancer: Yup.boolean().nullable(),
  hypertension: Yup.boolean().nullable(),
  medication: Yup.string().nullable(),
});

const FIELDS = {
  name: {
    label: "Name",
    type: "text",
  },
  dob: {
    label: "Date of Birth",
    type: "date",
  },
  contact: {
    label: "Contact No.",
    type: "text",
  },
  email: {
    label: "Email",
    type: "text",
  },
  caretaker: {
    label: "Caretaker",
    type: "text",
  },
  height: {
    label: "Height",
    type: "number",
    unit: "cm",
  },
  weight: {
    label: "Weight",
    unit: "kg",
    type: "number",
  },
  heart_disease: {
    label: "Heart Disease",
    type: "radio",
    options: [
      {
        label: "Yes",
        value: "True",
      },
      {
        label: "No",
        value: "False",
      },
    ],
  },
  cholesterol: {
    label: "Cholestrol",
    type: "radio",
    options: [
      {
        label: "Yes",
        value: "True",
      },
      {
        label: "No",
        value: "False",
      },
    ],
  },
  diabetes: {
    label: "Diabetes",
    type: "radio",
    options: [
      {
        label: "Yes",
        value: "True",
      },
      {
        label: "No",
        value: "False",
      },
    ],
  },
  cancer: {
    label: "Cancer",
    type: "radio",
    options: [
      {
        label: "Yes",
        value: "True",
      },
      {
        label: "No",
        value: "False",
      },
    ],
  },
  hypertension: {
    label: "Hypertension",
    type: "radio",
    options: [
      {
        label: "Yes",
        value: "True",
      },
      {
        label: "No",
        value: "False",
      },
    ],
  },
  medication: {
    label: "Add Medications",
    type: "text",
  },
};

function RegisterV2() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [showPopup, setShowPopup] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [countryCode, setCountryCode] = useState("");
  const [name, setName] = useState('');
  const [dateofbirth, setDateofbirth] = useState('');
  const [height, setHeight] = useState('');
  const [weight, setWeight] = useState('');
  const [contact, setContact] = useState('');
  const [emailID, setEmailID] = useState('');
  const [medication, setMedication] = useState('');
  const [errors, setErrors] = useState('');
  const [hrDisease, sethrDisease] = useState(false);
  const [cholesterol, setCholesterol] = useState(false);
  const [diabetes, setDiabetes] = useState(false);
  const [cancer, setCancer] = useState(false);
  const [hypertension, setHypertension] = useState(false);
  const [selectedDoctor, setSelectedDoctor] = useState('');
  const [isDoctorBoolean, setIsDoctorBoolean] = useState(false);
  const [isPatientBoolean, setIsPatientBoolean] = useState(false);
  const [specialization, setSpecialization] = useState('');
  const [hospitalName, setHospitalName] = useState('');
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarColor, setSnackbarColor] = useState('');

  const [values, setValues] = useState({
    name: "",
    dateofbirth: "",
    height: "",
    weight:" ",
    contact:"",
    email:"",
    medication:"",
    
  });

  const [doctorsList, setDoctorsList] = useState([]);
  const {newID, newToken} = location.state;
  // const { newUserID, newUserName, newUserToken } = location.state;
  // const newUserID1 = location.state ? location.state.newUserID : null;
  // const newUserName = location.state ? location.state.newUserName : null;
  // const newUserToken = location.state ? location.state.newUserToken : null;
  // const userID = useSelector((state) => state.auth.groupName)
  console.log("newUserID",newID)
  // console.log("newUserName", newUserName)
  console.log("newUserToken", newToken)

const homePage = () =>{
  history.push("/home");
}

// Effect to fetch the list of doctors

useEffect(() =>{
const fetchDoctors = async () => {
  try {
    const response = await axios.get(`${constants.API_URL}/doctor_list`);
    // const data = await response.json();
    setDoctorsList(response?.data?.doctor_list);
    if (response?.data?.doctor_list?.length > 0) {
      setSelectedDoctor(response?.data?.doctor_list[0]?.doctor_id);
    }
  } catch (error) {
    console.error('Error fetching doctors:', error);
  }
};
fetchDoctors();
}, []);

const handleCheckbox1 = () => {
  sethrDisease(!hrDisease);
};
const handleCheckbox2 = () => {
  setHypertension(!hypertension);
};
const handleCheckbox3 = () => {
  setDiabetes(!diabetes);
};
const handleCheckbox4 = () => {
  setCancer(!cancer);
};
const handleCheckbox5 = () => {
  setCholesterol(!cholesterol);
};

const handleDoctorToggle = (event) => {
  setIsDoctorBoolean(!isDoctorBoolean);
  const value = event.target.checked;
  if (value == false) {
    setSpecialization('');
    setHospitalName('');
  }
};
const handlePatientToggle = (event) => {
  setIsPatientBoolean(!isPatientBoolean);
};

const handleSpecialization = (event) => {
  setSpecialization(event.target.value);
};

const handleHospitalName = (event) => {
  setHospitalName(event.target.value);
};

 var formData=JSON.stringify({
  name:name, 
  dob:dateofbirth, 
  height:height,
  weight:weight, 
  contact:contact, 
  email:email, 
  medication:(medication),
  doctor:selectedDoctor,
  heart_disease:hrDisease,
  cholesterol:cholesterol,
  diabetes:diabetes,
  cancer:cancer,
  hypertension:hypertension,
  is_doctor:isDoctorBoolean,
  is_patient:isPatientBoolean,
  specialization:specialization,
  hospital:hospitalName,
});
 

  const handleRegisterApi = () => {
   console.log(formData);
   axios  ({
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    url: `${constants.API_URL}/update_profile/${newID}/${newToken}/`,
    data: formData,
  
  }).then(
    (res) => {
      console.log(res)
      let check1 = res?.data?.is_doctor
     if (res?.status === 200) {
      dispatch(addGroupName(newID));
      dispatch(addAuthToken(newToken));
      dispatch(setIsDoctor(res?.data?.is_doctor));
      dispatch(addHeaderGroupName(res?.data?.name));
      dispatch(setIsPatient(res?.data.is_patient));
      setSnackbarOpen(true);
      setSnackbarColor('success');
      setSnackbarMessage('Successfully Registered');

      setTimeout(() => {
         // redirect to the next page after 5 seconds
         history.push('/home')
       }, 2500);
     }
    }).catch((e) =>{
      setErrors(e?.response?.data?.message)
      console.error(e?.response?.status)
      if(e?.response?.status === 500){
        setSnackbarOpen(true);
        setSnackbarColor('error');
        setSnackbarMessage('Something went wrong');
      }
      else if(e?.response?.status === 400){
        setSnackbarOpen(true);
        setSnackbarColor('error');
        setSnackbarMessage(e?.response?.data?.message);
      }
      else{
        setSnackbarOpen(true);
        setSnackbarColor('error');
        setSnackbarMessage(e?.response?.data?.message);
      }
    })

  
  }

  function handleEmailChange(event) {
    const { value } = event.target;
    setEmail(value);

    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(value)) {
      setEmailError("Invalid Email Format");
    } else {
      setEmailError("");
    }
  }
  const groupName = useSelector((state) => state.auth.groupName);
  const [profile, setProfile] = React.useState();
  const [userInitials, setUserInitials] = useState("");
  const headerGroupName = useSelector((state) => state.auth.headerGroupName);

  function getInitials(headerName) {
    let initials = headerName
      .split(" ")
      .map((word) => word[0])
      .join("")
      .toUpperCase()
      .slice(0, 2);
    return initials;
  }
  useEffect(() => {
    let initials = getInitials(headerGroupName);
    setUserInitials(initials);
  }, [headerGroupName]);

  const initial_values = React.useMemo(() => {
    var val = {};
    Object.keys(FIELDS).map((key) => {
      val[key] = (profile && profile[key]) || "";
    });
    val = Object.assign({}, val, profile ? profile : {});
    Object.keys(val).forEach((key) => {
      if (typeof val[key] === "boolean") {
        val[key] = val[key] ? "true" : "false";
      }
    });
    return val;
  }, [profile]);

  // React.useEffect(() => {
  //   axios.get(`${constants.API_URL}/get_profile/${groupName}`).then((res) => {
  //     setProfile(res?.data?.profile_data);
  //   });
  // }, [groupName]);
  const onSubmit = (values, props) => {
    const form = new FormData();
    console.log(FormData)
    for(let key in values){

      if( key === "image"){
        form.append('image',selectedFile);
        continue;
      }
      form.append(key, values[key]) 
    }

    if(selectedImage === null){
      form.delete('image')
    }
   
  };

  return (
    <div className={styles.backH} key={profile}>

      <div className={styles.containerDashH}>

        <Formik
          initialValues={initial_values}
          validationSchema={VALIDATION_SCHEMA}
          onSubmit={handleRegisterApi}
        >
          {({
            handleChange,
            handleBlur,
            values,
            handleSubmit,
            errors,
            setFieldValue,
          }) => {
            return (
              
              <form onSubmit={handleSubmit}>
                
                <div className={styles.profileHeading}>
          
                  <div style={{fontFamily: "Montserrat,  san-serif", fontSize:"15px"}}>REGISTER YOUR DETAILS</div>
                                    <div style={{fontFamily: "Montserrat,  san-serif", fontSize:"15px"}}>{newID}</div>
                
                  </div>
                <div className={styles.contentDiv}>
                  <div className={styles.imageDiv}>
                    <div className={classes.container}>
                      <Avatar
                        src={
                          selectedImage ||
                          "https://www.w3schools.com/howto/img_avatar.png"
                        }
                        style={{ width: '95px', height: '95px' }}
                      />
                      <label
                        htmlFor="icon-button-file"
                        className={classes.cameraIcon}
                      >
                        <span style={{ fontSize: "15px" }}>
                          <PhotoCamera fontSize="inherit" />
                        </span>
                      </label>
                      <input
                        accept="image/*"
                        className={classes.input}
                        id="icon-button-file"
                        type="file"
                        onChange={(event) => {
                          const file = event.target.files[0];
                          setSelectedFile(file);
                          setSelectedImage(URL.createObjectURL(file));
                        }}
                      />
                    </div>
                  </div>

                  <div className={styles.detailsDiv}>
                    <div className="row" style={{width:"100%"}}>
                      <div className="col" style={{width:"100%"}}>
                        <div className={styles.labels}>*Name</div>{" "}
                        <input
                          type="text"
                          onChange={(e) => setName(e.target.value)}
                          onBlur={handleBlur}
                          id="name"
                          required
                          name="name"
                          value={name}
                          className={styles.fields}
                          maxLength={30}
                        />
                      </div>
                      <div className="col">
                        <div className={styles.labels}>*Date of Birth</div>
                        <input
                          type="date"
                          onChange={(e) => setDateofbirth(e.target.value)}
                          onBlur={handleBlur}
                          id="dob"
                          required
                          name="dob"
                          value={dateofbirth}
                          className={styles.fields}
                        />
                      </div>
                    </div>
                    <div className="row" style={{width:"100%"}}>
                      <div className="col">
                        <div className={styles.labels}> Contact No.</div>
                        <PhoneInput
                        id="phoneNumber"
                        country={countryCode}
                        value={contact}
                        onChange={(event) => setContact(event)}
                        inputStyle={{width:"100%",alignItems:"center",fontSize:"small", justifyContent:"center",backgroundColor:"#f5f5f5",border:"none",color:"grey",fontFamily:"Montserrat,  san-serif"}}
                      />
                      </div>
                      <div className="col">
                        <div className={styles.labels}>Email</div>
                        <input
                          type="email"
                          onChange={(e) => setEmail(e.target.value)}
                          onBlur={handleBlur}
                          id="email"
                          name="email"
                          value={email}
                          className={styles.fields}
                        />
                      </div>
                    </div>
                    <div className="row"  style={{width:"100%"}}>
                      <div className="col">
                        <div className={styles.labels}>Doctor List</div>
                        <div style={{alignItems:"left", textAlign:"left"}}>
                          
                        
                          <select
                            value={selectedDoctor}
                            onChange={(event) => setSelectedDoctor(event.target.value)}
                            className={styles.dropdown}
                          >
                            <option value="">Select a doctor</option>
                            {doctorsList?.map((doctor) => (
                              <option key={doctor?.doctor_id} value={doctor?.doctor_id}>
                                {doctor?.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col">
                        <div className={styles.labels}>*Height(cm)</div>
                        <input
                          type="number"
                          onChange={(e) => setHeight(e.target.value)}
                          onBlur={handleBlur}
                          id="height"
                          required
                          name="height"
                          value={height}
                          className={styles.fields}
                        />
                      </div>
                      <div className="col">
                        <div className={styles.labels}>*Weight(cm)</div>
                        <input
                          type="number"
                          onChange={(e) => setWeight(e.target.value)}
                          onBlur={handleBlur}
                          id="weight"
                          name="weight"
                          required
                          value={weight}
                          className={styles.fields}
                        />
                      </div>
                    </div>
                   <div className={styles.labels}>Having any of the below health issue ?</div> 
                    
                    <div className={styles.infoDivDis}>
                    <div className={styles.infosDisease}>
                      <label className={styles.labels}>Cancer</label>
                      <div className={styles.checkboxContainer}>
                        <div className={styles.checkboxContainer}>
                          <input
                            className={styles.checkbox}
                            type="radio"
                            id="cancer-yes"
                            value="yes"
                            checked={cancer} 
                            onChange={(event) =>
                              setCancer(event.target.checked)
                            }
                          />
                          <label htmlFor="cancer-yes">Yes</label>
                        </div>
                        <div className={styles.checkboxContainer}>
                          <input
                            className={styles.checkbox}
                            type="radio"
                            id="cancer-no"
                            value="no"
                            checked={!cancer} 
                            onChange={(event) =>
                              setCancer(!event.target.checked)
                            }
                          />
                          <label htmlFor="cancer-no">No</label>
                        </div>
                      </div>
                    </div>
                    <div className={styles.infosDisease}>
                      <label className={styles.labels}>Heart_Diseases</label>
                      <div className={styles.checkboxContainer}>
                        <div className={styles.checkboxContainer}>
                          <input
                            className={styles.checkbox}
                            type="radio"
                            id="HeartDiseases-yes"
                            checked={hrDisease} 
                            onChange={(event) =>
                              sethrDisease(event.target.checked)
                            }
                          />
                          <label htmlFor="HeartDiseases-yes">Yes</label>
                        </div>
                        <div className={styles.checkboxContainer}>
                          <input
                            className={styles.checkbox}
                            type="radio"
                            id="HeartDiseases-no"
                            checked={!hrDisease} 
                            onChange={(event) =>
                              sethrDisease(!event.target.checked)
                            }
                          />
                          <label htmlFor="HeartDiseases-no">No</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.infoDivDis}>
                    <div className={styles.infosDisease}>
                      <label className={styles.labels}>Cholestrol</label>
                      <div className={styles.checkboxContainer}>
                        <div className={styles.checkboxContainer}>
                          <input
                            className={styles.checkbox}
                            type="radio"
                            id="Cholestrol-yes"
                            checked={cholesterol} 
                            onChange={(event) =>
                              setCholesterol(event.target.checked)
                            }
                          />
                          <label htmlFor="Cholestrol-yes">Yes</label>
                        </div>
                        <div className={styles.checkboxContainer}>
                          <input
                            className={styles.checkbox}
                            type="radio"
                            id="Cholestrol-no"
                            checked={!cholesterol} 
                            onChange={(event) =>
                              setCholesterol(!event.target.checked)
                            }
                          />
                          <label htmlFor="Cholestrol-no">No</label>
                        </div>
                      </div>
                    </div>
                    <div className={styles.infosDisease}>
                      <label className={styles.labels}>Diabetics</label>
                      <div className={styles.checkboxContainer}>
                        <div className={styles.checkboxContainer}>
                          <input
                            className={styles.checkbox}
                            type="radio"
                            id="Diabetics-yes"
                            checked={diabetes} 
                            onChange={(event) =>
                              setDiabetes(event.target.checked)
                            }
                          />
                          <label htmlFor="Diabetics-yes">Yes</label>
                        </div>
                        <div className={styles.checkboxContainer}>
                          <input
                            className={styles.checkbox}
                            type="radio"
                            id="Diabetics-no"
                            checked={!diabetes} 
                            onChange={(event) =>
                              setDiabetes(!event.target.checked)
                            }
                          />
                          <label htmlFor="Diabetics-no">No</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.infoDivDis}>
                    <div className={styles.infosDisease}>
                      <label className={styles.labels}>Hypertension</label>
                      <div className={styles.checkboxContainer}>
                        <div className={styles.checkboxContainer}>
                          <input
                            className={styles.checkbox}
                            type="radio"
                            id="Hypertension-yes"
                            checked={hypertension} 
                            onChange={(event) =>
                              setHypertension(event.target.checked)
                            }
                          />
                          <label htmlFor="Hypertension-yes">Yes</label>
                        </div>
                        <div className={styles.checkboxContainer}>
                          <input
                            className={styles.checkbox}
                            type="radio"
                            id="Hypertension-no"
                            checked={!hypertension} 
                            onChange={(event) =>
                              setHypertension(!event.target.checked)
                            }
                          />
                          <label htmlFor="Hypertension-no">No</label>
                        </div>
                      </div>
                    </div>
                    <div className={styles.infosDisease} />
                  </div>

                    <div className="row"  style={{width:"100%"}}>
                      <div className="col">
                        <div className={styles.labels}> Add Medications </div>
                        <input
                          type="text"
                          onChange={(e) => setMedication(e.target.value)}
                          onBlur={handleBlur}
                          id="medication"
                          name="medication"
                          value={medication}
                          className={styles.docfields}
                        />
                      </div>
                    </div>

                    <div className="col">
                    <div className={styles.patientSwitch}>
                      <div className={styles.labels}> Are you a Patient ? </div>
                     
                      <Switch
                        classes={{ checked: classes.muiSwitch }}
                        color="secondary"
                        checked={isPatientBoolean}
                        onChange={handlePatientToggle}
                      />
                      </div>
                      <div className={styles.docSwitch}>
                      <div className={styles.labels}> Are you a Doctor ? </div>
                     
                      <Switch
                        classes={{ checked: classes.muiSwitch }}
                        color="secondary"
                        checked={isDoctorBoolean}
                        onChange={handleDoctorToggle}
                      />
                      </div>
                      
                    </div>
                    {isDoctorBoolean && (
                      <div style={{width:"100%", display:"flex", flexDirection:"row", paddingRight:"20px"}}>
                        <div style={{marginRight:"5px", textAlign:"left", fontFamily:"Montserrat, san-serif", width:"100%"}}>
                        *Specialization
                          <input type="text" required className={styles.docfields} value={specialization} onChange={handleSpecialization} />
                        </div>

                        <div style={{marginLeft:"5px", textAlign:"left", fontFamily:"Montserrat, san-serif", width:"100%"}}>
                        *Hospital
                          <input type="text" required className={styles.docfields} value={hospitalName} onChange={handleHospitalName} />
                        </div>
                      </div>
                    )}

                    <div style={{ marginTop: "5%" }}>
                      <Button
                        type="submit"
                        className={styles.btnStyle}
                        variant="contained"
                        onClick={handleRegisterApi}
                      >
                        REGISTER
                      </Button>
                    </div>
                  </div>
                  <div></div>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
      <Snackbar

     open={snackbarOpen}
     autoHideDuration={3000}
     onClose={() => setSnackbarOpen(false)}
     message={snackbarMessage}
     ContentProps={{
      className: styles.snackbarContent,
    }}
  
   >
     <Alert severity={snackbarColor} sx={{ width: '100%' }}>
       {snackbarMessage}
     </Alert>
   </Snackbar>
    </div>
  );
}

export default RegisterV2;
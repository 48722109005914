import React, { useState, useEffect } from "react";
import styles from "./MedicationPage.module.css";
import MedCurrentTime from "../MedCurrentTime/index.js";
import MedAlarmTile from "../MedAlarmTile";
import axios from "axios";
import constants from "../../../../../../Common/Constants";
import { useSelector } from "react-redux";
// import ArrowBackwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import BreadcrumbsPill from "../BreadrumbsPill";
import MedicationFloatingButton from "./MedicationFloatingButton";
import { useHistory, useLocation } from "react-router-dom";
import { ArrowBackIos } from "@material-ui/icons";
import Medication2FloatingButton from "./Schedules/Medication2FloatingButton";


const breadCrumbData = [
  {
    displayName: "Back",
    link: "/home",
  },
];

export const MedicationPage = () => {
  const token = useSelector((state) => state.auth.authToken);
  const groupName = useSelector((state) => state.auth.groupName);
  const [notification, setNotification] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const viewablityConstant = location?.state;
  // console.log(viewablityConstant?.patientToken, viewablityConstant?.viewMode);

  

  const fetchData = async () => {
    const response = await fetch(
      `${constants.API_URL}/get_pd_alarms/${viewablityConstant?.viewMode? viewablityConstant?.patientToken : viewablityConstant?.urlStateData?.patientView? viewablityConstant?.patientToken:groupName}/${token}`
    );
    const data = await response.json();

    setNotification(data?.result);
  };
  useEffect(() => {
    
    fetchData();
  }, []);

  const handleDelete = async (id) => {
    try {
      await axios.get(
        `${constants.API_URL}/delete_pd_alarms/${id}/${viewablityConstant?.viewMode? viewablityConstant?.patientToken : viewablityConstant?.urlStateData?.patientView? viewablityConstant?.patientToken:groupName}/${token}`
      );
      // Remove the deleted alarm from the notification list
      setNotification((prevNotification) =>
        prevNotification.filter((alarm) => alarm.id !== id)
      );
    } catch (error) {
      console.error("Error deleting alarm:", error);
    }
  };

  const handleBackCondition = () =>{
    if( viewablityConstant?.viewMode == true){
      history.goBack();
    }
    else if (viewablityConstant?.urlStateData?.patientView == true){
      history.goBack();
    }
    else{
      history.push("/home")
    }
  }
  console.log("viewablityConstant", viewablityConstant)

  const enabledNotifications = notification?.filter((item) => item?.enabled);

  return (
    <div className={styles.medMain}>
      <div className={styles.medSide1}>
        <div style={{ position: "relative", width: "100%", height: "100%" }}>
          <img
            alt=" "
            src={"./pill_dispenser.png"}
            style={{
              width: "100%",
              height: "100%",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
        </div>
      </div>
      <div className={styles.medSide2}>
        <div
          className={styles.breadcrump}
          style={{ display: "flex", alignItems: "center" }}
          onClick={handleBackCondition} 
        >
          <ArrowBackIos
            style={{
              fontSize: "small",
              color: "blue",
              cursor:"pointer"
            }}
          />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div onClick={handleBackCondition} style={{ cursor:"pointer"}}> Back </div>
          </div>
        </div>
        <div className={styles.medSide2Inner}>
          <MedCurrentTime />
          {enabledNotifications?.map((item) => (
            <MedAlarmTile
              key={item.id}
              id={item.id}
              hour={item.hour}
              minute={item.minute}
              message={item.med_name}
              status={item?.status}
              foodStatus={
                item?.fd_status === "AF" ? "After Food" :
                item?.fd_status === "BF" ? "Before Food" : " "
              }
              partOfDay={
                item?.part_of_day === "morning" ? "Morning" :
                item?.part_of_day === "noon" ? "Afternoon" :
                item?.part_of_day === "night" ? "Night" : " "
            }
              onDelete={() => handleDelete(item?.id)}
            />
          ))}
          {/* <MedicationFloatingButton fetchData={fetchData}/> */}
          <Medication2FloatingButton />
        </div>
      </div>
    </div>
  );
};

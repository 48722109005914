import React from 'react'
import EditIcon from '@material-ui/icons/Edit';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import FormBP from './FormBP';
import { CloseButton } from 'react-bootstrap';
import { MoreHoriz } from "@material-ui/icons";

export default function PopUpBP({optionTitle, careTakerPatientView=false}) {
    const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
     <Button style={{height:15}} color='primary'
      onClick={handleClickOpen}>
        {optionTitle}
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <div style={{display:'flex', justifyContent: 'right'}}>
            <CloseButton onClick={handleClose} />
            </div>
            
        
        <DialogTitle id="customized-dialog-title" onClose={handleClose} textAlign="center" >
          Blood Pressure Data
        </DialogTitle>
        <DialogContent dividers>
          <FormBP careTakerPatientView={careTakerPatientView} onClose={handleClose}/>
        </DialogContent>
        {/* <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Save changes
          </Button>
        </DialogActions> */}
      </Dialog>
    </div>
  )
}

import React from "react";
import moment from "moment";
import { useState } from "react";
import axios from "axios";
import constants from "../../../../Common/Constants";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { SPOBarChart } from "./SPOBarChart";
import styles from './SPOPopup.module.css'

const apiName = {
    week: "spo2",
    month: "week_data",
    threeMonth: "three_month_data",
    year: "month_data",
  };

export default function SPOContainer({ title, xData, yData }) {
    const groupName = useSelector((state) => state.auth.groupName);
    const [clickedChart, setClickedChart] = useState(null);
    const [subChartName, setChartName] = useState("");
    const token = useSelector((state) => state.auth.authToken);
  
    const [chart_data, setData] = useState({});
  
    const careTakerToken = useSelector((state) => state.auth.careTakerToken);
  
    const { id, patient_group_id } = useParams();
  
    const location = useLocation();
    const urlStateData = location?.state;
    const viewOnly = location?.state;
    const {pat_id} = useParams();
  
    const handleClick = (param, echarts) => {
      const dateMonth = param?.name?.split(" ");
      let month = "01";
      let date = "01";
      if (dateMonth?.length === 1) {
        month = moment().month(dateMonth[0]).format("MM");
      } else {
        month = moment().month(dateMonth[1]).format("MM");
        date = dateMonth[0]?.length === 1 ? `0${dateMonth[0]}` : dateMonth[0];
      }
      const year = (param?.name.split(" "))[2];
  
      const clickedDate = `${date}${month}${year}`;
      setClickedChart(clickedDate);
      setChartName(param?.name);
  
      if (!chart_data?.[clickedDate]) {
        //api call
  
        axios
          .get(
            `${constants.API_URL}/day_data/spo2/${
              viewOnly?.viewOnly? pat_id : urlStateData?.patientView? patient_group_id : groupName
            }/${clickedDate}/${token}/`
          )
          .then((res) => {
            let data = res?.data?.day_data[0].y_data;
  
            let stateCopy = { ...chart_data };
  
            stateCopy[clickedDate] = { yData: data };
  
            setData(stateCopy);
          });
      }
    };
    return (
      <div className={styles.containerMain}>
        <div>
          <SPOBarChart
            title={title}
            xData={xData}
            yData={yData}
            handleClick={handleClick}
          />
        </div>
      </div>
    );
  }
  

import React, { memo, useMemo } from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactEcharts from "echarts-for-react";
import "./FNewDash.css";

import ExpandMorePopup from "../../../../../Common/ExpandMorePopup";
import FormSpO2 from "./FormSpO2";
import PopupBG from "./PopBG";
import TileRemove from "../../../../home/TileRemove";
// import { setSpo2DailyAvg,setSpo2DailyAvgStatus,setSpo2DailyMin,setSpo2DailyMinStatus,setSpo2DailyMax,setSpo2DailyMaxStatus} from "../../../../../store/dashboardSlice";

function Spo2ChartDaily({handleClose,onClick,careTakerPatientView=false}) {
  const groupName = useSelector((state) => state.auth.groupName);
  const token = useSelector((state) => state.auth.authToken);
  // const dateStep = format(subDays(endOfDay(new Date()),6), 'ddMMyyy')
 
  const [xAxisData, setXData] = useState([]);
  
  const spo2DailyMin = useSelector((state) => state.dashboard.spo2DailyMin);
  const spo2DailyMax = useSelector((state) => state.dashboard.spo2DailyMax);
  const spo2DailyAvg = useSelector((state) => state.dashboard.spo2DailyAvg);

  const dispatch = useDispatch();
  const alarmStatus= spo2DailyAvg?.data?.y_data?.status_point

  
 

  const chartData = React.useMemo(() => {
    return {
      title: [
        {
          text: "SpO2",

          left: "5%",
          top: 0,
          textStyle: {
            fontWeight: "normal",
            fontSize: 28,
          },
        },
        {
          text: spo2DailyMin?.data?.y_data?.spo2_min,
          subtext: "MIN",
          subtextStyle: {
            fontSize: 15,
          },
          textAlign: "center",
          left: "12%",
          top: "53%",
          textStyle: {
            fontSize: 25,
            fontWeight: "normal",
          },
        },
        {
          text: spo2DailyMax?.data?.y_data?.spo2_max,
          subtext: "MAX",
          subtextStyle: {
            fontSize: 15,
          },
          textAlign: "center",
          left: "85%",
          top: "53%",
          textStyle: {
            fontSize: 25,
            fontWeight: "normal",
          },
        },

        { subtext: spo2DailyMax?.data?.x_data+" ago", left: "right", bottom: "5%",fontSize: 12 },
        { subtext: spo2DailyMax?.data?.y_data?.status, left: "left", bottom: "5%", fontSize: 12},

      ],
      tooltip: {
        textStyle: {
          fontFamily: "sans-serif",
        },
      },
      legend: {
        left: 272,
        top: 40,
        selectedMode: false,
      },

      series: [
        {
          color: "green",
          type: "gauge",
          center: ["50%", "50%"],
          radius: "55%",
          guageCategoryGap: "0%",
          startAngle: 360,
          endAngle: 0,
          min: false,
          max: 1,
          splitNumber: 12,
          itemStyle: {
            color: "#00B5C6",
            shadowColor: "blue",
            shadowBlur: 0,
            shadowOffsetX: 0,
            shadowOffsetY: 0,
          },
          progress: {
            show: true,
            width: 9,
            
          },
          pointer:{
            show:false
          },
          axisLine: {
            show:false,
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show:false,
            length: 15
          },
          axisLabel: {
            show:false,
            distance: 20,
            color: 'red',
            fontSize: 20
          },
          title: {
            show: false,
          },
          detail: {
            lineHeight: 0,
            height: 0,
            borderRadius: 8,
            offsetCenter: [0, "5%"],
            valueAnimation: true,
            formatter: function (value) {
              return "{value|" + value.toFixed(0) +"}{unit|%}";
            },
            rich: {
              value: {
                fontSize: 40,
            
                color:" #434242",
                fontWeight:"normal",
       
              },

              unit: {
                fontSize: 30,
                fontWeight:"normal",
                color:" #434242",
                padding: [0, 0, 0, 0],
              },
            },
          },
          data: [
            {
              value: spo2DailyAvg?.data?.y_data?.spo2_avg,
            },
          ],
        },
      ],
    };
  }, [xAxisData, spo2DailyAvg, spo2DailyMin, spo2DailyMax]);

  
if(!(chartData?.series[0]?.data[0]?.value)){
  return null
}
  return (
        <div>
         
          <div className={"dis-daily-chart" +(' alarm-status-'+alarmStatus)}>
          <div className="popup-bg" style={{display:'flex', justifyContent: 'right'}}>
            <ExpandMorePopup 
            option1={<PopupBG optionName="Manual Entry" FormTitle="SpO2" FormType={<FormSpO2 handleClose={handleClose} careTakerPatientView={careTakerPatientView}/>} />}
            option2={<TileRemove
              careTakerPatientView={careTakerPatientView}
              parameter="SPO" />}  /></div>
          <div className="BG-value" style={{ fontFamily: "sans-serif" }} onClick={onClick}>
          <ReactEcharts option={chartData} />{" "}
          </div>
          </div>
         
        </div>
    
  );
}

export default memo(Spo2ChartDaily);

import React from 'react'
import SideCardCommon from '../SideCardCommon'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faFire} from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import FormCalorie from '../../../Member Details/Comps/FitbitFolder/FitCharts/FormCalorie';
import TileRemove from '../Featuresv2/TileRemove';
import { useState } from 'react';
import { useEffect } from 'react';

function Caloriev2({onClick}) {

  const [isExpired, setIsExpired] = useState(false);

  const calorieDaily = useSelector((state) => state.dashboard.calorieDaily);
  const CALDaily = calorieDaily?.total
  // console.log(calorieDaily)
  
  const timeGapIndicationCAL = calorieDaily?.time_gap
  // console.log(timeGapIndicationCAL)

  useEffect(() => {
    if (timeGapIndicationCAL?.includes("day") || timeGapIndicationCAL?.includes("days")) {
      setIsExpired(true);
    }
    else{
      setIsExpired(false);
    }
  }, [timeGapIndicationCAL, calorieDaily]);

  if (!CALDaily){
    return null;
  }
  
  return (
    <SideCardCommon 
    onClick={onClick}
    title="Calorie" 
    tileBgColor={isExpired? "#F9F9F9" : "white"}
    iconpic={<FontAwesomeIcon icon={faFire} color="#7F55AE"/>}
    parameterValue={CALDaily? CALDaily:""} 
    parameterUnit="cal"
    alarmStatus={"- "}
    timeGap={calorieDaily?.time_gap}
    FormTitle={"Calorie"}
    FormType={<FormCalorie/>}
    Option2={<TileRemove option2title={"REMOVE TILE"} parameter={"CAL"}/>}
    >
    </SideCardCommon>
  )
}

export default Caloriev2
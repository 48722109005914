import React, { memo } from "react";
import ReactEcharts from "echarts-for-react";
import "../FNewDash.css";
import moment from "moment";

function SleepChartPage2({ title, xData, yData, handleClick }) {
  let totalSleep = yData?.deep + yData?.light + yData?.rem + yData?.wake;
  let totalSleepHrs = parseInt(totalSleep / 60);
  let totalSleepMin = totalSleep % 60;


  const newYData = [];

  newYData.push({
    value: ((yData?.["rem"] / totalSleep) * 100).toFixed(1),
    itemStyle: { color: "#FFB848" },
  });
  newYData.push({
    value: ((yData?.["light"] / totalSleep) * 100).toFixed(1),
    itemStyle: { color: "#27A9E3" },
  });
  newYData.push({
    value: ((yData?.["deep"] / totalSleep) * 100).toFixed(1),
    itemStyle: { color: "#28B779" },
  });
  newYData.push({
    value: ((yData?.["wake"] / totalSleep) * 100).toFixed(1),
    itemStyle: { color: "#6D3DA3" },
  });

  const chartData = React.useMemo(() => {
    return {
      title: {
        left: "center",
        top: 16,
        text: "Sleep",
        subtext:
          "TotalSleep: " + totalSleepHrs + " hrs " + totalSleepMin + " mins",
        textStyle: {
          fontFamily: "sans-serif",
        },
      },
      tooltip: {
        textStyle: {
          fontFamily: "sans-serif",
        },
      },

      grid: {
        left: "3%",
        right: "10%",
        bottom: "11%",
        containLabel: true,
      },

      yAxis: {
        type: "category",
        boundaryGap: [0, 0.01],
        data: ["REM", "Light Sleep", "Deep Sleep", "Awake"],
        axisLabel: {
          fontFamily: "sans-serif",
        },
      },
      xAxis: {
        type: "value",
        axisLabel: {
          fontFamily: "sans-serif",
        },
      },

      series: [
        {
          name: "%",
          type: "bar",
          areaStyle: {},

          symbol: "none",

          data: newYData,
        },
      ],
    };
  }, [xData, yData]);

  const chartData3 = React.useMemo(() => {
    return{
       series: [
         {
           type: 'gauge',
           startAngle: 180,
           endAngle: 0,
           min:0,
           // splitNumber: 12,
           itemStyle: {
             color: '#FFB848',
             shadowColor: 'rgba(0,138,255,0.45)',
             shadowBlur: 3,
             shadowOffsetX: 1,
             shadowOffsetY: 1
           },
           progress: {
             show: true,
             roundCap: true,
             width: 22
           },
           pointer: {
            show:false
           },
           axisLine: {
             roundCap: true,
           },
           axisTick: {
            show:false
           },
           splitLine: {
           show:false
           },
           axisLabel: {
             show:false
           },
           title: {
             show: false
           },
           detail: {
             backgroundColor: '#fff',
             borderColor: '#999',
             borderWidth: 2,
             valueAnimation: true,
            show:false
           },
           data: [
             {
               value: 100
             }
           ]
         }
       ]
     }
   })

  function calculateDuration(ST, ET) {
    const startTime = moment(ST);
    const endTime = moment(ET);
    const duration = moment.duration(endTime.diff(startTime));
    const hours = duration.hours();
    const minutes = duration.minutes();
    return `${hours} hours  ${minutes} minutes`;
  }

  const chartData2 = () => {
    // {alert("qwertyu")}
   
    return (
      <>
        {calculateDuration(
          yData?.sleep[0]?.sleep_start,
          yData?.sleep[0]?.sleep_end
        )}{" "}
      </>
    );
  };
  // 

  if (chartData && chartData.series && chartData.series.length && chartData.series[0].data && chartData.series[0].data.length && isNaN(chartData?.series[0]?.data[0].value)) {
    if(!yData?.sleep[0]?.sleep_start || !yData?.sleep[0]?.sleep_end ){
      return null ;
   }
    return <div className="dis-daily-chart1" style={{ width: '600px', height: '400px', margin: '0 auto' }}>
        <div onClick={handleClick}>
        <div className="sleepHeadInner">Sleep</div>
        <div>
        <div className="sleepDetails">{chartData2()}
        <div className="spanDiv">
          <span className="spanDiv1">Sleep Start Time</span>
          <span className="spanDiv2">Sleep End Time</span>{" "}
        </div>
        <div className="spanDiv">
          <span className="spanTime">{moment(yData?.sleep[0]?.sleep_start).format("h:mm a")}</span>
          <span className="spanTime1">{moment(yData?.sleep[0]?.sleep_end).format("h:mm a")}</span>
        </div>
        </div>
        </div>
      </div>
    </div>;
  } 
  else {
    return (
      <div className="dis-daily-chart1" style={{ width: '600px', height: '400px', margin: '0 auto' }}>
        <ReactEcharts
          option={chartData}
          onEvents={{
            click: handleClick,
          }}
        />
      </div>
    );
  }
}

export default memo(SleepChartPage2);

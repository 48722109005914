import React, { useState } from "react";
import BMILineChart from "./BMILineChart";
import styles from "./BMIChartPopup.module.css";

export const BMIContainer = ({ title, xData, yData, type , bmiRes }) => {
  const [bmiValue, setBmiValue] = useState({ bmi: null, date: null });
  const handleClick = (data1) => {
    if (data1) {
      var [a1, b1] = data1.value;
      setBmiValue({
        title: a1,
        bmi: a1,
        date: a1,
        x1: a1,
        x2: b1,
      });
    }
  };

  return (
    <div className={styles.containerMain}>
      <div>
        <BMILineChart
          title={title}
          xData={xData}
          yData={yData}
          bmiRes={bmiRes}
          handleClick={handleClick}
        />
      </div>
    </div>
  );
};

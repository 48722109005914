import React, { useState } from "react";
import "../FNewDash.css";
import { Row, Col } from "reactstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import constants from "../../../../../../Common/Constants";
import { format, formatRelative, subDays, endOfDay } from "date-fns";
import BPChartContainer from "./BPChartContainer";
import BreadcrumbsTop from "../BreadcrumbsTop";
import { useLocation, useParams } from "react-router-dom";
import { Popover, Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { Typography } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

const breadCrumbData = [
  {
    displayName: "Home",
    link: "/home",
  },
  {
    displayName: "BloodPressurePage",
    link: "/bppage",
  },
];
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const BPMainPage = () => {
  window.scrollTo(0, 0);
  const [tabValue, setTabValue] = useState("week");
  const [monthData, setMonthData] = useState({
    xData: [],
    yData1: [],
    yData2: [],
    bpRes: []
  });
  const [weekData, setWeekData] = useState({
    xData: [],
    yData1: [],
    yData2: [],
    bpRes: [],
  });
  const [threeMonthData, setThreeMonthData] = useState({
    xData: [],
    yData1: [],
    yData2: [],
    bpRes: []
  });
  const [yearData, setYearData] = useState({
    xData: [],
    yData1: [],
    yData2: [],
    bpRes: []
  });

  const groupName = useSelector((state) => state.auth.groupName);
  const token = useSelector((state) => state.auth.authToken);
  const currentUserIndex = useSelector((state) => state.auth.currentUserIndex);
  const userData = useSelector((state) => state.auth.userData);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const careTakerToken = useSelector((state) => state.auth.careTakerToken);

  const dateHR = format(endOfDay(new Date()), "ddMMyyy");
  const [open, setOpen] = React.useState(false);

  const { id, patient_group_id } = useParams();

  const location = useLocation();
  const urlStateData = location?.state;

  let x = [];
  let y1 = [];
  let y2 = [];
  let bpResult = [];

  useEffect(() => {
    if (tabValue === "week" && !weekData?.xData?.length) {
      let url = `${constants.API_URL}/week_data/BP/${
        urlStateData ? patient_group_id : groupName
      }/`;
      let activeUserDeviceList = userData?.[currentUserIndex]?.["device_list"];

      url += `${dateHR}/${urlStateData ? careTakerToken : token}/`;
      axios.get(url).then((res) => {
        res.data.week_data?.forEach((data) => {
          var epochTS = data.x_data;

          var d = new Date(parseInt(epochTS));

          let tsnow = `${d.toLocaleDateString()}\n${d.toLocaleTimeString()}`;

          x.push(tsnow);
          y1.push(data.y_data.BPS);
          y2.push(data.y_data.BPD);
          bpResult.push(data);
        });

        setWeekData({ xData: x, yData1: y1, yData2: y2, bpRes: bpResult });
      });
     

    } else if (tabValue === "month" && !monthData?.xData?.length) {
      let url = `${constants.API_URL}/month_data/BP/${
        urlStateData ? patient_group_id : groupName
      }/`;
      let activeUserDeviceList = userData?.[currentUserIndex]?.["device_list"];

      url += `${dateHR}/${urlStateData ? careTakerToken : token}/`;
      axios.get(url).then((res) => {
        res.data.month_data?.forEach((data) => {
          var epochTS = data.x_data;
          var d = new Date(parseInt(epochTS));
          let tsnow = `${d.toLocaleDateString()}\n${d.toLocaleTimeString()}`;
          x.push(tsnow);
          y1.push(data.y_data.BPS);
          y2.push(data.y_data.BPD);
          bpResult.push(data);
        });
       
        setMonthData({ xData: x, yData1: y1, yData2: y2, bpRes: bpResult });
      });
    } else if (tabValue === "threeMonth" && !threeMonthData?.xData?.length) {
      let url = `${constants.API_URL}/three_month_data/BP/${
        urlStateData ? patient_group_id : groupName
      }/`;
      let activeUserDeviceList = userData?.[currentUserIndex]?.["device_list"];

      url += `${dateHR}/${urlStateData ? careTakerToken : token}/`;
      axios.get(url).then((res) => {
        res.data.three_month_data?.forEach((data) => {
          var epochTS = data.x_data;
          var d = new Date(parseInt(epochTS));
          let tsnow = `${d.toLocaleDateString()}\n${d.toLocaleTimeString()}`;
          x.push(tsnow);
          y1.push(data.y_data.BPS);
          y2.push(data.y_data.BPD);
          bpResult.push(data);
        });

        setThreeMonthData({
          xData: x,
          yData1: y1,
          yData2: y2,
          bpRes: bpResult,
        });
      });
    } else if (tabValue === "year" && !yearData?.xData?.length) {
      let url = `${constants.API_URL}/three_month_data/BP/${
        urlStateData ? patient_group_id : groupName
      }/`;
      let activeUserDeviceList = userData?.[currentUserIndex]?.["device_list"];

      url += `${dateHR}/${urlStateData ? careTakerToken : token}/`;
      axios.get(url).then((res) => {
        res.data.year_data?.forEach((data) => {
          var epochTS = data.x_data;
          var d = new Date(parseInt(epochTS));
          let tsnow = `${d.toLocaleDateString()}\n${d.toLocaleTimeString()}`;
          x.push(tsnow);
          y1.push(data.y_data.BPS);
          y2.push(data.y_data.BPD);
          bpResult.push(data);
        });

        setYearData({ xData: x, yData1: y1, yData2: y2, bgRes: bpResult });
      });
    }
  }, [tabValue]);

  const handleChange = (value) => {
    setTabValue(value);
  };
  const BPManualEntryDel = () => {  
    axios.get(`${constants.API_URL}/delete_latest_manual_entry/${groupName}/BP/${token}/`).then(
      (res) => {
        setOpen(true)
      }
    )
    
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAnchorClose = () => {
    setAnchorEl(null);
  };

  const openPopOver = Boolean(anchorEl);
  const popOverId = openPopOver ? 'simple-popover' : undefined;


  return (
    <div className="backH">
      <div className="container-dashH">
      <div className="DelButton">
      <BreadcrumbsTop data={breadCrumbData}/>
      <IconButton  onClick={handleClick}><MoreHorizIcon/></IconButton> 
      <Popover
        id={popOverId}
        open={openPopOver}
        anchorEl={anchorEl}
        onClose={handleAnchorClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Typography style={{padding:"2.5px", cursor: "pointer",touchAction: "manipulation",transition: "box-shadow .2s ease-in-out", outline: "transparent"}} onClick={BPManualEntryDel}>Delete Last Entry</Typography>
      </Popover>
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Last Manual Entry Successfully Deleted!
        </Alert>
      </Snackbar>
      </div>
        <Tabs
          defaultActiveKey="week"
          activeKey={tabValue}
          transition={false}
          id="noanim-tab-example"
          className="mb-3"
          onSelect={handleChange}
        >
          <Tab className="h-100" eventKey="week" title="WEEK">
            <Row className="h-100">
              <Col>
                <BPChartContainer
                  title="Weekly Report"
                  bpRes={weekData?.bpRes}
                  xData={weekData?.xData}
                  yData1={weekData?.yData1}
                  yData2={weekData?.yData2}
                  type="week"
                />
              </Col>
            </Row>
          </Tab>
          <Tab className="h-100" eventKey="month" title="MONTH">
            <Row className="h-100">
              <Col>
                <BPChartContainer
                  title="Monthly Report"
                  bpRes={monthData?.bpRes}
                  xData={monthData?.xData}
                  yData1={monthData?.yData1}
                  yData2={monthData?.yData2}
                  type="month"
                />
              </Col>
            </Row>
          </Tab>
          <Tab className="h-100" eventKey="threeMonth" title="3MONTHS">
            <Row className="h-100">
              <Col>
                <BPChartContainer
                  title="3 Months Report"
                  bpRes={threeMonthData?.bpRes}
                  xData={threeMonthData?.xData}
                  yData1={threeMonthData?.yData1}
                  yData2={threeMonthData?.yData2}
                  type="threeMonth"
                />
              </Col>
            </Row>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};
export default BPMainPage;

import React from 'react'
import "../FNewDash.css";
import { Col, Row } from "reactstrap";
import moment from "moment";
import { useState } from "react";
import constants from "../../../../../../Common/Constants";
import axios from "axios";
import { useSelector } from "react-redux";
import BPChartPage1 from './BPChartPage1';
import BPChartPage2 from './BPChartPage2';


const apiName = {
  week: "day_data",
  month: "week_data",
  threeMonth: "month_data",
  year: "month_data",
};
export default function BPChartContainer({ title, xData, yData1, yData2,bpRes, type}) {
    const groupName = useSelector((state) => state.auth.groupName);
    const [clickedChart, setClickedChart] = useState(null);
    const [subChartName,setChartName]= useState('')
    const [sysDia,setSysDia] = useState({sys:null,dia:null,date:null})
    const token = useSelector((state) => state.auth.authToken);
    const api_type = apiName[type];

   const [ __test , setTest ] = useState({});
    const handleClick = ( data1 , data2  ) => {
      if( data1 && data2 ){
          var [a1 , a2 ] = data1.value ;
          var [b1 , b2 ] = data2.value ;
          setSysDia({
            title : a1 ,
            sys : a1 , 
            dia : a1 , 
            date : a1 , 
            x1 : b2,
            x2 : a2
          })
   

      }

    }
   
  
    return (
      <div className="h-100">
        <Row className="h-100">
          <Col className="first-box h-100">
            <div className="first-card h-100">
              <BPChartPage1
                title={title}
                xData={xData}
                yData1={yData1}
                yData2={yData2}
                bpRes={bpRes}
                handleClick={handleClick}
              />
            </div>
          </Col>
          <Col className="second-box">
            {sysDia?.sys && sysDia?.dia && sysDia?.date &&
            <div className="second-card">
              
              <BPChartPage2 
                title={sysDia?.title}
                x= {sysDia?.title}
                y1={sysDia?.x1}
                y2={sysDia?.x2}
              />
            </div>
  }
          </Col>
        </Row>
      </div>
    );
  
  }
import React from 'react'
import './FNewDash.css'

export const Topbar = () => {
  return (
    <div className='Topbar-header'> <div className='fitlogo'>DemoUser
   
    </div>
    
     </div>
  )
}

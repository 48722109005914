import React from "react";
import "../FNewDash.css";
import { Col, Row } from "reactstrap";
import DisChartPage1 from "./DisChartPage1";
import moment from "moment";
import { useState } from "react";
import constants from "../../../../../../Common/Constants";
import axios from "axios";
import { useSelector } from "react-redux";

const apiName = {
  week: "day_data",
  month: "week_data",
  threeMonth: "month_data",
  year: "month_data",
};

export default function DisChartContainer({ title, xData, yData, type }) {
  const groupName = useSelector((state) => state.auth.groupName);
  const [clickedChart, setClickedChart] = useState(null);
  const [subChartName,setChartName]= useState('')
  const token = useSelector((state) => state.auth.authToken);
  const api_type = apiName[type];
  const [chart_data, setData] = useState({});
  const handleClick = (param, echarts) => {
    const dateMonth = param?.name?.split(" ");
    let month = "01"
    let date = "01"
    if (dateMonth?.length === 1){
      month = moment().month(dateMonth[0]).format("MM");
    }
    else{
      month = moment().month(dateMonth[1]).format("MM");
      date = dateMonth[0]?.length === 1 ? `0${dateMonth[0]}` : dateMonth[0];
    }
    const year = new Date().getFullYear();
    const clickedDate = `${date}${month}${year}`;
    setClickedChart(clickedDate)
    setChartName(param?.name)
    if (!chart_data?.[clickedDate]) {
      //api call
      axios
        .get(
          `${constants.API_URL}/${api_type}/DIS/${groupName}/${clickedDate}/${token}/`
        )
        .then((res) => {
         
          let x = [];
          let y = [];
          res?.data?.[api_type].forEach((data) => {
            x.push(data.x_data);
            y.push(data.y_data);
          });
          let stateCopy = {...chart_data};
         
          stateCopy[clickedDate] = {xData:x,yData:y}
        
          setData(stateCopy);
         
        });
    }
  };

  

  return (
    <div>
      <Row>
        <Col className="first-box">
          <div className="first-card">
            <DisChartPage1
              title={title}
              xData={xData}
              yData={yData}
              handleClick={handleClick}
            />
          </div>
        </Col>
        <Col className="second-box">
          {clickedChart&&
          <div className="second-card">
            <DisChartPage1 
              title={subChartName}
              xData={chart_data?.[clickedChart]?.xData}
              yData={chart_data?.[clickedChart]?.yData}
            />
          </div>
}
        </Col>
      </Row>
    </div>
  );

}


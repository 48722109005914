
import React, { useState, useEffect } from 'react';
import styles from './Form.module.css'
import { Alert, Snackbar, Switch } from '@mui/material';
import constants from '../../../../../Common/Constants';
import { setDashboardData } from '../../../../../store/dashboardSlice';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Divider } from 'antd';

const SleepForm = ({ handleClose, careTakerPatientView = false }) => {
    const dispatch = useDispatch();
    const token = useSelector((state) => state.auth.authToken);
    const groupName = useSelector((state) => state.auth.groupName);

    const { id, patient_group_id } = useParams();

    const location = useLocation();
    const urlStateData = location?.state

    const [showPopup, setShowPopup] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarColor, setSnackbarColor] = useState('');


    const [startDate, setStartDate] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endDate, setEndDate] = useState('');
    const [endTime, setEndTime] = useState('');
    const [advancedMode, setAdvancedMode] = useState(false);
    const [deepSleepPercentage, setDeepSleepPercentage] = useState(0);
    const [awakePercentage, setAwakePercentage] = useState(0);
    const [lightSleepPercentage, setLightSleepPercentage] = useState(0);
    const [remPercentage, setRemPercentage] = useState(0);
    const [errorMessageTime, setErrorMessageTime] = useState('');
    const [errorMessageAdvanced, setErrorMessageAdvanced] = useState('');
    const [sleepStartTime, setSleepStartTime] = useState('');
    const [sleepEndTime, setSleepEndTime] = useState('');
    const [totalMinutes, setTotalMinutes] = useState(0);
    const [totalHourMinute, setTotalHourMinute] = useState(0);
    const [totalPercentageAdvanced, setTotalPercentageAdvanced] = useState(0);


    const handleAdvancedMode = () => {

        setAdvancedMode(!advancedMode);
        if (advancedMode) {
            setErrorMessageAdvanced('');
        }
    }

    const fetchDataApi = async () => {
        let url = `${constants.API_URL}/dashboard_values/${urlStateData ? patient_group_id : groupName}/${token}/`;

        // url += `${token}/`;


        const result = await axios.get(url);

        dispatch(setDashboardData(result.data.data));
    };

    const getCurrentDate = () => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
        const day = String(currentDate.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const getCurrentTime = () => {
        const currentTime = new Date();
        const hours = String(currentTime.getHours()).padStart(2, '0');
        const minutes = String(currentTime.getMinutes()).padStart(2, '0');
        return `${hours}:${minutes}`;
    };

    const [maxTime, setMaxTime] = useState(getCurrentTime());


    // Custom validation function to prevent future times
    const validateTimeInput = (event) => {
        const inputTime = event.target.value;
        const currentTime = getCurrentTime();

        if (maxTime && inputTime > maxTime) {
            event.target.value = maxTime; // Reset the input value to the maxTime
        }
    };



    useEffect(() => {
        handleTotalMinute();

        if (advancedMode) {
            const deep = parseFloat(deepSleepPercentage) || 0 ;
            const wake = parseFloat(awakePercentage) || 0 ;
            const light = parseFloat(lightSleepPercentage) || 0;
            const rem = parseFloat(remPercentage) || 0 ;
            const totalPercentage = deep + wake + light + rem;
            setTotalPercentageAdvanced(totalPercentage);
            console.log(totalPercentageAdvanced)
            console.log(deepSleepPercentage)
            console.log(awakePercentage)
            console.log(lightSleepPercentage)
            console.log(remPercentage)
            if (totalPercentage !== 100) {
                setErrorMessageAdvanced('Total percentage must be equal to 100%.' + ` Remaining ${(100 - totalPercentage).toFixed(2).length > 5 ? (100 - totalPercentage).toFixed(2).slice(0,6) + ".." : (100 - totalPercentage).toFixed(2)}%`);
            } else {
                setErrorMessageAdvanced('');
            }
        }

        if (startTime && startDate && endDate && endTime) {
            let timeErrors = '';
            if (totalMinutes < 30) {
                timeErrors += 'Duration must be at least 30 minutes. \n';
            }
            if (totalMinutes > 1440) {
                timeErrors += 'Duration cannot exceed 24 hours. \n';
            }
            if (new Date(`${startDate}T${startTime}`) >= new Date(`${endDate}T${endTime}`)) {
                timeErrors += 'Start time must be before end time. \n';
            }
            setErrorMessageTime(timeErrors);
        } else {
            setErrorMessageTime('');
        }
    }, [startDate, startTime, endDate, endTime, errorMessageAdvanced, errorMessageTime, advancedMode, deepSleepPercentage, awakePercentage, lightSleepPercentage, remPercentage]);

    const handleTotalMinute = () => {
        if (startDate && startTime && endDate && endTime) {
            const startTimestamp = new Date(`${startDate}T${startTime}`).getTime();
            const endTimestamp = new Date(`${endDate}T${endTime}`).getTime();
            setSleepStartTime(startTimestamp);
            setSleepEndTime(endTimestamp);
            const durationInMilliseconds = endTimestamp - startTimestamp;
            const calculatedTotalMinutes = Math.floor(durationInMilliseconds / (1000 * 60)); // Convert milliseconds to minutes
            setTotalMinutes(calculatedTotalMinutes);

            const hours = Math.floor(totalMinutes / 60);
            const remainingMinutes = totalMinutes % 60;

            const formattedDuration = `${hours > 0 ? hours + ' hours' : ''} ${remainingMinutes > 0 ? remainingMinutes + ' minutes' : ''
                }`;
            setTotalHourMinute(formattedDuration);

        } else {
            setTotalMinutes(0);
        }
    };

    const onSubmit = () => {

        if (sleepStartTime && sleepEndTime !== '') {
            let dataToSend = ''
            if (advancedMode) {
                dataToSend = {
                    sleep_start: sleepStartTime,
                    sleep_end: sleepEndTime,
                    // advancedMode: advancedMode,
                    deep: parseFloat(((deepSleepPercentage / 100) * totalMinutes).toFixed(1)),
                    wake: parseFloat(((awakePercentage / 100) * totalMinutes).toFixed(1)),
                    light:  parseFloat(((lightSleepPercentage / 100) * totalMinutes).toFixed(1)),
                    rem:  parseFloat(((remPercentage / 100) * totalMinutes).toFixed(1)),
                };
            } else {
                dataToSend = {
                    sleep_start: sleepStartTime,
                    sleep_end: sleepEndTime,
                };
            }
            console.log(dataToSend);

            axios({
                method: "post",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                url: `${constants.API_URL}/manual_entry/${urlStateData ? patient_group_id : groupName}/${token}/`,
                data: dataToSend,
            }).then((res) => {
                if (res?.status === 200)
                    setShowPopup(true);
                setSnackbarOpen(true);
                setSnackbarColor('success');
                setSnackbarMessage('Manual Entry Successful');

                setTimeout(() => {
                    // show pop up message
                    setShowPopup(false);
                    handleClose();

                }, 2000);
                clearTimeout();


                setTimeout(() => {
                    // show pop up message
                    fetchDataApi();
                    console.log("api refreshing for manual entry")
                }, 4000);
                clearTimeout();
            }).catch((error) => {
                console.log(error?.response?.status);
                if (error?.response?.status === 400){
                    setSnackbarOpen(true);
                    setSnackbarColor('error');
                    setSnackbarMessage('ERROR: Please select proper Date and Time');
                }
                else if (error?.response?.status === 404){
                    setSnackbarOpen(true);
                    setSnackbarColor('error');
                    setSnackbarMessage('Unauthorized');
                }
                else{
                    setSnackbarOpen(true);
                    setSnackbarColor('error');
                    setSnackbarMessage('ERROR');
                }
                // start_time should be less than endtime and endtime should beless than current time
            })
            ;
        }
        // Send the data to the backend here
    };

    const vertical = "bottom";
    const horizontal = "center";

    console.log(lightSleepPercentage);

    return (
        <div className={styles.sleepMainContainer}>
            <div>
                <div className={styles.startTimeContainer}>
                    <label className={styles.labelContainer}>
                        Start Date: {""}
                        <input type="date" max={getCurrentDate()} value={startDate} 
                        // onChange={(e) => setStartDate(e.target.value)} 
                        onChange={(e) => {
                            setStartDate(e.target.value);
                            const selectedDate = new Date(e.target.value);
                            const currentDate = new Date();
                            setStartTime('');
                            if (selectedDate.toDateString() === currentDate.toDateString()) {
                                // If selected date is today, set max time to current time
                                setMaxTime(getCurrentTime());
                            } else {
                                // If selected date is not today, remove max time restriction
                                setMaxTime(''); // Set to empty string to remove max attribute
                            }
                        }}
                        />
                    </label>
                    <label className={styles.labelContainer}>
                        Start Time: {""}
                        <input type="time" max={maxTime} 
                        // onBlur={handleTotalMinute}
                         value={startTime} onChange={(e) => {
                            validateTimeInput(e);
                            setStartTime(e.target.value);
                        }} />
                    </label>
                </div>
                <div className={styles.endTimeContainer}>
                    <label className={styles.labelContainer}>
                        End Date: {""}
                        <input type="date" max={getCurrentDate()} value={endDate}
                        //  onChange={(e) => setEndDate(e.target.value)} 
                         onChange={(e) => {
                            setEndDate(e.target.value);
                            const selectedDate = new Date(e.target.value);
                            const currentDate = new Date();
                            setEndTime('');
                            if (selectedDate.toDateString() === currentDate.toDateString()) {
                                // If selected date is today, set max time to current time
                                setMaxTime(getCurrentTime());
                            } else {
                                // If selected date is not today, remove max time restriction
                                setMaxTime(''); // Set to empty string to remove max attribute
                            }
                        }}
                         />
                    </label>
                    <label className={styles.labelContainer}>
                        End Time: {""}
                        <input type="time" onBlur={handleTotalMinute} max={maxTime} value={endTime} onChange={(e) => {
                            validateTimeInput(e);
                            setEndTime(e.target.value);
                        }} />
                    </label>
                </div>
            </div>

            <div className={styles.totalMinutesLabel}>Total Time : {totalHourMinute !== 0 ? totalHourMinute : '--'}</div>

            <div className={styles.errorMessageTimeStyles}>{errorMessageTime}</div>

            <div>
                {startTime && startDate && endDate && endTime && errorMessageTime == '' && (
                    <label className={styles.totalMinutesLabel}>
                        Advanced Settings:
                        <Switch
                            // type="checkbox"
                            color="secondary"
                            id="advancedMode"
                            name="advancedMode"
                            checked={advancedMode}
                            onChange={handleAdvancedMode}
                        />
                    </label>
                )}
            </div>

            {advancedMode && totalMinutes > 0 && (
                <div className=''>
                    <div>
                        <label className={styles.labelContainer} htmlFor="deepSleepPercentage">Deep Sleep Percentage % :</label>
                        <input
                            type="number"
                            min={0}
                            max={100}
                            className={styles.inputFieldStyles}
                            id="deepSleepPercentage"
                            name="deepSleepPercentage"
                            value={(deepSleepPercentage)}
                            onChange={(e) => setDeepSleepPercentage(e.target.value)}
                        />
                        <span className={styles.minuteValueStyles}> {((deepSleepPercentage / 100) * totalMinutes).toFixed(1).length > 6 ? ((deepSleepPercentage / 100) * totalMinutes).toFixed(1).slice(0, 6) + ".." : ((deepSleepPercentage / 100) * totalMinutes).toFixed(1) } mins</span>
                    </div>
                    <Divider className={styles.dividerStyles}/>
                    <div>
                        <label className={styles.labelContainer} htmlFor="awakePercentage">Awake Percentage % :</label>
                        <input
                            type="number"
                            min={0}
                            max={100}
                            className={styles.inputFieldStyles}
                            id="awakePercentage"
                            name="awakePercentage"
                            value={(awakePercentage)}
                            onChange={(e) => setAwakePercentage(e.target.value)}
                        />
                        <span className={styles.minuteValueStyles}>{((awakePercentage / 100) * totalMinutes).toFixed(1).length > 6 ? ((awakePercentage / 100) * totalMinutes).toFixed(1).slice(0,6) + ".." : ((awakePercentage / 100) * totalMinutes).toFixed(1)} mins </span>
                    </div>
                    <Divider className={styles.dividerStyles}/>
                    <div>
                        <label className={styles.labelContainer} htmlFor="lightSleepPercentage">Light Sleep Percentage % :</label>
                        <input
                            type="number"
                            min={0}
                            max={100}
                            className={styles.inputFieldStyles}
                            id="lightSleepPercentage"
                            name="lightSleepPercentage"
                            value={(lightSleepPercentage)}
                            onChange={(e) => setLightSleepPercentage(e.target.value)}
                        />
                        <span className={styles.minuteValueStyles}>{((lightSleepPercentage / 100) * totalMinutes).toFixed(1).length > 6 ? ((lightSleepPercentage / 100) * totalMinutes).toFixed(1).slice(0,6) + ".." : ((lightSleepPercentage / 100) * totalMinutes).toFixed(1)} mins</span>
                    </div>
                    <Divider className={styles.dividerStyles}/>
                    <div>
                        <label className={styles.labelContainer} htmlFor="remPercentage">REM Percentage % :</label>
                        <input
                            type="number"
                            min={0}
                            max={100}
                            className={styles.inputFieldStyles}
                            id="remPercentage"
                            name="remPercentage"
                            value={(remPercentage)}
                            onChange={(e) => setRemPercentage(e.target.value)}
                        />
                        <span className={styles.minuteValueStyles}>{((remPercentage / 100) * totalMinutes).toFixed(1).length > 6 ? ((remPercentage / 100) * totalMinutes).toFixed(1).slice(0,6) + ".." : ((remPercentage / 100) * totalMinutes).toFixed(1)} mins</span>
                    </div>
                    <Divider className={styles.dividerStyles}/>
                    <div className={styles.totalMinutesLabel}>  {totalPercentageAdvanced} %</div>
                    <div className={styles.errorMessageTimeStyles}>{errorMessageAdvanced}</div>
                </div>
            )}

            <button className={styles.submitButtonSleep}
                type="submit"
                onClick={onSubmit}
                disabled={
                    errorMessageTime !== '' ||
                    errorMessageAdvanced !== '' ||
                    totalMinutes === 0 ||
                    (advancedMode &&
                        parseFloat(deepSleepPercentage) + parseFloat(awakePercentage) + parseFloat(lightSleepPercentage) + parseFloat(remPercentage) !== 100)
                }
            >
                SUBMIT
            </button>

            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)}
                message={snackbarMessage}
                key={vertical + horizontal}
            >
                <Alert severity={snackbarColor} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default SleepForm;

import React, { useState } from 'react'
import { Grid, Paper, Button, Typography } from '@material-ui/core'
import { TextField } from '@material-ui/core'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { useRef } from 'react'
import {useSelector,useDispatch } from "react-redux"
import constants from '../../../../../Common/Constants'
import { setPassword } from "../../../../../store/authSlice";
import axios from "axios";
import { useParams, useHistory,useLocation } from "react-router-dom";
import {
  setDashboardData,
  setWebsocketStatus,
} from "../../../../../store/dashboardSlice";
import { PopMsg } from "./PopMsg";
import { Alert } from '@material-ui/lab';
import { Snackbar } from '@material-ui/core';

  

function FormStep({handleClose,careTakerPatientView=false,refreshChartData}) {

    const dispatch = useDispatch();
    const [showPopup, setShowPopup] = useState(false);
    const careTakerToken=useSelector((state) => state.auth.careTakerToken);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarColor, setSnackbarColor] = useState('');

    const token = useSelector((state) => state.auth.authToken);
    const stepsDaily = useSelector((state) => state.dashboard.stepsDaily)
    const currentUser = useSelector((state) => state.dashboard.currentUserIndex)
    const groupName = useSelector((state) => state.auth.groupName);
    const paperStyle = { padding: '0 5px 50px 5px', width: 270, }
    const btnStyle = { marginTop: 10, fontFamily:"'Montserrat', sans-serif"  }
    const SysDiaValue=/^[0-9]{2,3}/
    const initialValues = {
        STP: '',
    }
    const userData = useSelector((state) => state.auth.userData)

    const { id,patient_group_id } = useParams();

    const location = useLocation();
    const urlStateData = location?.state

    const validationSchema = Yup.object().shape({
       
        STP: Yup.number().min(50).max(70000).required("Required"),

       
    })

    const fetchDataApi = async () => {
      let url = `${constants.API_URL}/dashboard_values/${urlStateData?patient_group_id:groupName}/${token}/`;
 
      const result = await axios.get(url);

      dispatch(setDashboardData(result.data.data));
      };

    const onSubmit = (values, props) => {

        
        props.resetForm()

        var dataSTP=parseInt(values.STP)
        // var curStep=stepsDaily?.total
        // var addedStep= curStep? dataSTP + curStep :dataSTP
        

        var currTimestamp= Date.now()
       
      
        const dateValue=values.Date 
     
        const timeValue=values.Time
    
  
        var timestampData   =  dateValue +" "+ timeValue  ;
        var epochTime = Date.parse(timestampData);
   
        var utcTime = epochTime

        var formData=JSON.stringify({STP:parseInt(dataSTP), TS:currTimestamp});


    
        axios({
            method: "post",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            url: `${constants.API_URL}/manual_entry/${urlStateData?patient_group_id:groupName}/${token}/`,
            data: formData,
            
          
          }).then(
            (res) => {
              if (res.status===200) 
           
                  setShowPopup(true);
                  setSnackbarOpen(true);
                  setSnackbarColor('success');
                  setSnackbarMessage('Manual Entry Successful');
    
                  setTimeout(() => {
                     // show pop up message
                     setShowPopup(false);
                     handleClose() ;
                     refreshChartData();
            
                   }, 2000);
                   clearTimeout();
                   
            
                   setTimeout(() => {
                     // show pop up message
                    fetchDataApi();
                 console.log("api refreshing for manual entry")
                   }, 3000);
                   clearTimeout();

            }).catch((error) => {
              console.log(error?.response);
              if(error?.response?.status == 400){
               setShowPopup(true);
               setSnackbarOpen(true);
               setSnackbarColor('error');
               setSnackbarMessage(error?.response?.data?.message);
              }
              else{
               setShowPopup(true);
               setSnackbarOpen(true);
               setSnackbarColor('error');
               setSnackbarMessage("Something went wrong");
              }
           });
            
    }
    const vertical = "bottom"; 
    const horizontal = "center"; 
   

  return (
    <div>
    {showPopup &&(
      <PopMsg/>
    )}

    <Grid>
    <Paper elevation={0} style={paperStyle}>
        <Grid align='center'>
            <Typography variant='caption'>Enter incremental steps</Typography>
        </Grid>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {(props) => (
                <Form noValidate>
                    

                    <Field as={TextField} name='STP' label='Step Count' fullWidth
                        error={props.errors.STP && props.touched.STP}
                        helperText={<ErrorMessage name='STP' />} required />


                    <Button type='submit' style={btnStyle} variant='contained'
                        color='primary'>Submit Data</Button>
                </Form>
            )}
        </Formik>
    </Paper>
</Grid>
<Snackbar
        anchorOrigin={{ vertical, horizontal }}
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={() => setSnackbarOpen(false)}
          message={snackbarMessage}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={snackbarColor} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
</div>
  )
}

export default FormStep
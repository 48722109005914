import React from "react";
import { useState, useEffect } from "react";
import ReactEcharts from "echarts-for-react";
import "../FNewDash.css";

import { useSelector, useDispatch } from "react-redux";

import { useMemo } from "react";

function DistanceDefault() {

  
 

  
  const fitbitDistanceData = useMemo(() => {
    return {
      title: {
        left: "center",
        text: "Distance",
        top: 5,
        textStyle: {
          fontFamily: "sans-serif",
        },
      },
      graphic: {
        type: 'text',
        left: 'center',
        top: 'middle',
        style: { text: "No data available", fontSize:25},
        },
        
      tooltip: {
        trigger: "axis",
        textStyle: {
          fontFamily: "sans-serif",
        },
        axisPointer: {
          type: "shadow",
        },
      },

      grid: {
        left: "14%",
        right: "5%",
        bottom: "20%",
      },
      xAxis: {
        type: "category",
        axisLine:{
          show:false
        },
        axisLabel: {
          fontFamily: "sans-serif",
        },
      },
      yAxis: {
        type: "value",
        axisLabel: {
          fontFamily: "sans-serif",
        },
      },

    
    };
  }, []);


  return (
      
        <div className="dis-daily-chart">
          <ReactEcharts option={fitbitDistanceData} />
        </div>
  );
}
   

export default DistanceDefault
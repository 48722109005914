import React, { useRef, useState } from 'react';
import  styles from './ChatCss.module.css'
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMessage, faRobot, faUser } from '@fortawesome/free-solid-svg-icons';
import { Send } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { Button, Menu } from 'antd';
import { Divider, MenuItem, Popover } from '@mui/material';

const ChatContainer = () => {
    
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const messageContainerRef = useRef(null);
  const cureGptToken = useSelector((state) => state.auth.cureGptToken);
  const headerGroupName = useSelector((state) => state.auth.headerGroupName);
  // const cureGptToken = useSelector((state) => state.auth.cureGptToken);
  console.log(cureGptToken);
  const userData = useSelector((state) => state.auth.userData);
  console.log(userData);

  const [showPatientsList, setShowPatientsList] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);

  const patients = userData?.[0]?.group_list? userData?.[0]?.group_list : "No Patients" ;
  
  const loggedInUser = {
    cure_id: cureGptToken,
    group_name: headerGroupName,
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const togglePatientsList = (event) => {
    setAnchorEl(event.currentTarget);
  }; 
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const loadPatientInIframe = (patient) => {
    setSelectedPatient(patient);
  };

  const loadUserInIframe = (loggedInUser) => {
    setSelectedPatient(loggedInUser)
  }

  return (
    <div className={styles.chatInterface}>
       <div className={styles.iFrameContainer} >
      <iframe
        src={
          selectedPatient 
            ? `https://curegpt.simelabs.in/iFrame/${selectedPatient.cure_id}|${selectedPatient.group_name}`
            : `https://curegpt.simelabs.in/iFrame/${loggedInUser?.cure_id}|${loggedInUser?.group_name}`
        }
        title="Child Application"
        style={{
          borderRadius: '15px',
          width: '100%',
          height: '100%',
          // transform: 'scale(0.95)',
          transformOrigin: 'top left',
        }}
      />
    </div>
      <div>
        <Button style={{cursor:"pointer",color:"#7F55AE"}} onClick={togglePatientsList}>User</Button>

       <Popover
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        > 
        <div style={{padding:"5px"}}>
        <div className={styles.headingFontStyles}>Logged In</div>
        <div>
          <MenuItem 
              key={loggedInUser.id}
              onClick={() => loadUserInIframe(loggedInUser)}
              style={{ cursor: 'pointer' }}
              className={styles.listFontStyles}
            >
              {loggedInUser.group_name}
            
          </MenuItem>
          <Divider/>
        <div className={styles.headingFontStyles}>Patients</div>
              {patients !== "No Patients" ? (
                <>
                  {patients?.map((patient, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => loadPatientInIframe(patient)}
                      className={styles.listFontStyles}
                    >
                      {patient.group_name}
                    </MenuItem>
                  ))}
                </>
              ) : (
                <div className={styles.headingFontStyles}>-- No Patients --</div>
              )}
        </div>
        </div>
        </Popover>
      </div>
    </div>
  );
};

export default ChatContainer;

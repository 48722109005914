import React from 'react'
import ReactEcharts from 'echarts-for-react';

export default function BRChartPage1({title,xData,yData,handleClick}) {
    const chartData =React.useMemo(()=>{
      return {
        title: {
          left: 'center',
          text: title,
          textStyle: {
            fontFamily: "sans-serif"
          },
        },
        
        tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            },
            textStyle: {
              fontFamily: "sans-serif"
            }
          },
          // legend: {
          //   textStyle: {
          //     fontFamily: "sans-serif"
          //   },
          //   left:490,
          //   top:35,
          //   selectedMode: false,
          // },
        xAxis: {
          type: 'category',
        //   boundaryGap: false,
          data: xData,
          axisLabel: {
              
            fontFamily:'sans-serif',
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
              
            fontFamily:'sans-serif',
          }
        //   boundaryGap: [0, '100%']
        },
        
        series: [
          {
            name: 'breaths/min',
            type: 'bar',
            areaStyle: {},
                
            symbol: 'none',
            
            // itemStyle: {
            color: '#6D3DA3',
            // },
            
            data: yData
          }
        ]}
    },[xData,yData,title])
  
  
  
  // const [status, setStatus] = React.useState(false)
  // const tokenStr = "eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIyMzhKVDMiLCJzdWIiOiJCNEpWS1kiLCJpc3MiOiJGaXRiaXQiLCJ0eXAiOiJhY2Nlc3NfdG9rZW4iLCJzY29wZXMiOiJ3aHIgd251dCB3cHJvIHdzbGUgd3NvYyB3YWN0IHdveHkgd3RlbSB3d2VpIHdzZXQgd2xvYyB3cmVzIiwiZXhwIjoxNjYzNzQ0MDcxLCJpYXQiOjE2NjMxMzkyNzF9.7Pz6O94kCPNXSq_ZenSoqM1yqrsxeXHJiBdh1PaSYHY"
  
  return (
    <div>
      {chartData?.xAxis?.data?.length?
    <div className='left-chart'>
    <ReactEcharts 
    option={chartData} 
    onEvents={{
      'click': handleClick,
    }}
    />
    <div className='area-name'></div>
    </div>
     : <p style={{fontSize:"20px", fontWeight:"bold"}}>NO DATA AVAILABLE </p>
     }
    </div>
  )
  }
  
import React from 'react'
import ChatContainer from './ChatContainer'

const CureGPTIntegration = () => {
  return (
    <div style={{margin:"20px" ,width:"100%", height:"100vh"}}>
    <ChatContainer/>
    </div>
  )
}

export default CureGPTIntegration
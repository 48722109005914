import React, { useEffect, useState } from "react";
import styles from "./MedCurrentTime.module.css";
import { Avatar, Button, IconButton } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const MedCurrentTime = () => {
  const [userInitials, setUserInitials] = useState("");
  const groupName = useSelector((state) => state.auth.groupName);
  const [liveTime, setLiveTime] = useState(new Date().toLocaleTimeString());
  const headerGroupName = useSelector((state) => state.auth.headerGroupName);
  const [headerPatientID, setHeaderPatientID] = useState('');

  const location = useLocation();
  const viewablityConstant = location?.state;
  console.log(viewablityConstant);

  useEffect(()=> {
    if (viewablityConstant?.viewMode == true){
      setHeaderPatientID(viewablityConstant?.patientToken)
    } 
    else if (viewablityConstant?.urlStateData?.patientView == true){
      setHeaderPatientID(viewablityConstant?.patientToken)
    }
    else {
      setHeaderPatientID(viewablityConstant?.patientToken)
    }
  },[]);
  
 
  useEffect(() => {
    const intervalId = setInterval(() => {
      setLiveTime(new Date().toLocaleTimeString([],{
        hour: "2-digit",
        minute: "2-digit"
      }));
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  function getInitials(headerName) {
    let initials = headerName
      .split(" ")
      .map((word) => word[0])
      .join("")
      .toUpperCase()
      .slice(0, 2);
    return initials;
  }
  useEffect(() => {
    let initials = getInitials(headerGroupName);
    setUserInitials(initials);
  }, [headerGroupName]);

  return (
    <div className={styles.MedCurrentTime}>
      <div style={{ width: "100%", flex: 1 }}>
        <div style={{ color: "#AEAEAE", display: "flex", fontSize: "18px" }}>
          Current Time
        </div>
        <div
          style={{
            display: "flex",
            fontFamily: "montserrat",
            fontSize: "22px",
          }}
        >
          {liveTime}
        </div>
      </div>
      <p style={{ fontSize: "25px", display: "flex", alignItems: "center", fontFamily: "montserrat" }}></p>
     
        {viewablityConstant?.urlStateData?.patientView == true || viewablityConstant?.viewMode ==  true ? (

          <span className={styles.headerUserIDStyles}> USER ID - {headerPatientID}</span>

        ) : (

          <IconButton>
          <Avatar className={styles.userAvatar}>{userInitials}</Avatar>
          </IconButton>

        )}
      

    </div>
  );
};
export default MedCurrentTime;

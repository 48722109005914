import React from 'react'
import styles from './UserAlarm.module.css'
import { AccessAlarmRounded } from '@mui/icons-material'
import { Menu } from '@material-ui/core';
import { useState } from 'react';
import { faBell, faCircle, faCircleExclamation, faCircleInfo, faClockRotateLeft, faMinus, faUserClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Divider } from '@mui/material';
import AlarmTile from './AlarmTile';
import { useSelector } from 'react-redux';

export default function UserAlarm() {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleShowNotificationList = (event) => {
        setAnchorEl(event.currentTarget)
    }; 
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const glucoseDaily = useSelector((state) => state.dashboard.glucoseDaily);
    const bpDailySys = useSelector((state) => state.dashboard.bpDailySys);
    const breathDaily = useSelector((state) => state.dashboard.breathDaily);
    const sleepDaily = useSelector((state) => state.dashboard.sleepDaily);
    const spo2DailyMin = useSelector((state) => state.dashboard.spo2DailyMin);
    const stepsDaily = useSelector((state) => state.dashboard.stepsDaily);
    const tempDaily = useSelector((state) => state.dashboard.tempDaily);
    const heartRateDaily = useSelector((state) => state.dashboard.heartRateDaily);
    console.log("glucoseDaily",glucoseDaily);
  
    const glucose = glucoseDaily?.data?.y_data?.status;
    const glucoseStatus = glucoseDaily?.data?.y_data?.status_point;
    const glucoseTimeGap = glucoseDaily?.data?.x_data?.time_gap;
    const bp = bpDailySys?.data?.y_data?.status;
    const bpStatus = bpDailySys?.data?.y_data?.status_point;
    const bpTimeGap = bpDailySys?.data?.x_data?.time_gap;
    const br = breathDaily?.data?.y_data?.status;
    const brStatus = breathDaily?.data?.y_data?.status_point;
    const brTimeGap = breathDaily?.data?.x_data
    const sleep = sleepDaily?.data?.y_data?.status;
    const sleepStatus = sleepDaily?.data?.y_data?.status_point;
    const sleepTimeGap = sleepDaily?.data?.x_data?.time_gap;
    const spo = spo2DailyMin?.data?.y_data?.status;
    const spoStatus = spo2DailyMin?.data?.y_data?.status_point;
    const spoTimeGap = spo2DailyMin?.data?.x_data ;
    const steps = stepsDaily?.data?.y_data?.status;
    const stepsStatus = stepsDaily?.data?.y_data?.status_point;
    const temp = tempDaily?.data?.y_data?.status;
    const tempStatus = tempDaily?.data?.y_data?.status_point;
    const tempTimeGap = tempDaily?.data?.x_data?.time_gap;
    const heartRate = heartRateDaily?.data?.y_data?.status;
    const heartRateStatus = heartRateDaily?.data?.y_data?.status_point
    const heartRateTimeGap = heartRateDaily?.data?.x_data?.time_gap;

    const alarmData = [
        glucoseStatus,
        bpStatus,
        brStatus,
        sleepStatus,
        spoStatus,
        stepsStatus,
        tempStatus,
        heartRateStatus,
    ];

    const alarmCount  = alarmData?.filter((data) => data !== 0 && data).length;
    console.log(alarmCount);

    const getStatusText = (status_point) => {
        switch (status_point) {
          case 3:
            return "High";
          case 2:
            return "Medium";
          case 1:
            return "Low";
          default:
            return "Normal"; // You can provide a default value for other cases
        }
      };
    
      const getStatusColorClass = (status_point) => {
        switch (status_point) {
          case 3:
            return "redBackground";
          case 2:
            return "yellowBackground";
          case 1:
            return "blueBackground";
          default:
            return "defaultBackground"; // You can provide a default background color class
        }
      };

      const getPriorityColorClass = (priorityColor) => {
        switch (priorityColor) {
          case 3:
            return "redPriority";
          case 2:
            return "yellowPriority";
          case 1:
            return "bluePriority";
          default:
            return "default-priority"; // You can provide a default color class
        }
      };    

  return (
      <div className="">
          <Badge badgeContent={alarmCount} color="primary" style={{ fontSize: "2px" }}>
              <div style={{ fontSize: "1.2rem", cursor: "pointer" }}>
                  <FontAwesomeIcon size='lg' icon={faBell} style={{fontSize:"1.2rem", cursor: "pointer" }} color="#7F55AE" onClick={handleShowNotificationList} />
              </div>
          </Badge>

          <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleMenuClose}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          >
              <div className={styles.mainContainer}>
                  <div className={styles.headingContainer}>
                      <div className={styles.labelHeader}>Alarm History</div>
                      <div><FontAwesomeIcon size='sl' icon={faClockRotateLeft} /></div>
                  </div>
                  <Divider/>
                  <div>
                    {alarmCount === 0 ? (
                        <div className={styles.labelHeader}> No Alarms. </div>
                    ) : (
                        <>
                        
                     {glucoseStatus !== 0 && glucoseStatus && ( <AlarmTile alarmUserData={glucoseDaily} 
                                                          statusText={getStatusText(glucoseStatus)}
                                                          backgroundColorClass={getStatusColorClass(glucoseStatus)} 
                                                          timeGapStatus={glucoseTimeGap} 
                                                          priorityColor={getPriorityColorClass(glucoseStatus)} 
                                                          
                                                          /> )}
                                                          

                     {bpStatus !== 0 && bpStatus && ( <AlarmTile alarmUserData={bpDailySys} 
                                                     statusText={getStatusText(bpStatus)}
                                                     backgroundColorClass={getStatusColorClass(bpStatus)}
                                                     timeGapStatus={bpTimeGap} 
                                                     priorityColor={getPriorityColorClass(bpStatus)}
                                                     />  )}

                     {brStatus !== 0 && brStatus && ( <AlarmTile alarmUserData={breathDaily} 
                                                     statusText={getStatusText(brStatus)} 
                                                     backgroundColorClass={getStatusColorClass(brStatus)}
                                                     timeGapStatus={brTimeGap} 
                                                     priorityColor={getPriorityColorClass(brStatus)}
                                                     /> )}

                     {spoStatus !== 0 && spoStatus && ( <AlarmTile alarmUserData={spo2DailyMin} 
                                                      statusText={getStatusText(spoStatus)} 
                                                      backgroundColorClass={getStatusColorClass(spoStatus)}
                                                      timeGapStatus={spoTimeGap} 
                                                      priorityColor={getPriorityColorClass(spoStatus)}
                                                      /> )}

                     {tempStatus !== 0 && tempStatus && (  <AlarmTile alarmUserData={tempDaily} 
                                                        statusText={getStatusText(tempStatus)}
                                                        backgroundColorClass={getStatusColorClass(tempStatus)}
                                                        timeGapStatus={tempTimeGap}
                                                        priorityColor={getPriorityColorClass(tempStatus)}
                                                         /> )}

                     {heartRateStatus !== 0 && heartRateStatus && (  <AlarmTile alarmUserData={heartRateDaily} 
                                                             statusText={getStatusText(heartRateStatus)} 
                                                             backgroundColorClass={getStatusColorClass(heartRateStatus)}
                                                             timeGapStatus={heartRateTimeGap}
                                                             priorityColor={getPriorityColorClass(heartRateStatus)}
                                                             /> )}

                     {sleepStatus !== 0 && sleepStatus && (  <AlarmTile alarmUserData={sleepDaily} 
                                                         statusText={getStatusText(sleepStatus)} 
                                                         backgroundColorClass={getStatusColorClass(sleepStatus)}
                                                         timeGapStatus={sleepTimeGap}
                                                         priorityColor={getPriorityColorClass(sleepStatus)} 
                                                         /> )}
                                                         
                     {/* {stepsStatus !== 0 && (  <AlarmTile alarmUserData={stepsDaily} /> )} */}
                     </>
                    )}
                  </div>
                  <div className={styles.minusIconStyles}> <FontAwesomeIcon icon={faMinus} color='rgb(179, 179, 179)' style={{marginTop:"-5px", marginBottom:"-10px"}}/></div>
              </div>
          </Menu>
      </div>
  )
}

import React, { useState } from "react";
import HeartRateLineChart from "./HeartRateLineChart";
import styles from "./HeartRatePopup.module.css";
import moment from "moment";
import axios from 'axios'
import { useLocation, useParams } from "react-router-dom";
import constants from "../../../../Common/Constants";
import { useSelector } from "react-redux";

const apiName = {
  week: "day_data",
  month: "week_data",
  threeMonth: "month_data",
  year: "month_data",
};

export const HeartRateContainer = ({  title, xData, yData,type }) => {

  const [clickedChart, setClickedChart] = useState(null);
  const [subChartName, setChartName] = useState("");
  const groupName = useSelector((state) => state.auth.groupName);
  const token = useSelector((state) => state.auth.authToken);
  const careTakerToken = useSelector((state) => state.auth.careTakerToken);
  const [chart_data, setData] = useState({});
  const api_type = apiName[type];
  const location = useLocation();
  const urlStateData = location?.state 
  const { id,patient_group_id } = useParams();

  const viewOnly = location?.state;
  const {pat_id} = useParams();

  const handleClick = (param, echarts) => {
    const dateMonth = param?.name?.split(" ");
    let month = "01";
    let date = "01";

    if (dateMonth?.length === 1) {
      month = moment().month(dateMonth[0]).format("MM");
    } else {
      month = moment().month(dateMonth[1]).format("MM");
      date = dateMonth[0]?.length === 1 ? `0${dateMonth[0]}` : dateMonth[0];
    }
    const year = (param?.name.split(" "))[2];

    const clickedDate = `${date}${month}${year}`;
    setClickedChart(clickedDate);
    setChartName(param?.name);
    if (!chart_data?.[clickedDate]) {
      //api call
      axios
        .get(
          `${constants.API_URL}/${api_type}/HR/${viewOnly?.viewOnly? pat_id : urlStateData?.patientView? patient_group_id:groupName}/${clickedDate}/${token}/`
        )
        .then((res) => {
          let x = [];
          let y = [];
          res?.data?.[api_type].forEach((data) => {
            x.push(data.x_data);
            y.push(data.y_data);
          });
          let stateCopy = { ...chart_data };

          stateCopy[clickedDate] = { xData: x, yData: y };

          setData(stateCopy);
        });
    }
  };

  return (
    <div className={styles.containerMain}>
      <div>
        <HeartRateLineChart
          title={title}
          xData={xData}
          yData={yData}
          handleClick={handleClick}
        />
      </div>
    </div>
  );
};

import React, { useState } from "react";
import "../FNewDash.css";
import { Row, Col } from "reactstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import { useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import constants from "../../../../../../Common/Constants";
import BreadcrumbsTop from "../BreadcrumbsTop";
import BGChartContainer from "./BGChartContainer";
import { format, formatRelative, subDays, endOfDay } from "date-fns";
import { useLocation, useParams } from "react-router-dom";
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { IconButton } from "@material-ui/core";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';

const breadCrumbData = [
  {
    displayName: "Home",
    link: "/home",
  },
  {
    displayName: "BloodGlucosePage",
    link: "/bgpage",
  },
];
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const BGMainPage = ({ careTakerPatientView = false }) => {
  window.scrollTo(0, 0);
  const [tabValue, setTabValue] = useState("week");
  const [monthData, setMonthData] = useState({ xData: [], yData: [], bgRes:[] });
  const [weekData, setWeekData] = useState({ xData: [], yData: [],bgRes:[] });
  const [threeMonthData, setThreeMonthData] = useState({xData: [], yData: [],bgRes:[]});
  const [yearData, setYearData] = useState({ xData: [], yData: [] ,bgRes:[]});
  const groupName = useSelector((state) => state.auth.groupName);
  const token = useSelector((state) => state.auth.authToken);
  const currentUserIndex = useSelector((state) => state.auth.currentUserIndex);
  const userData = useSelector((state) => state.auth.userData);
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const careTakerToken = useSelector((state) => state.auth.careTakerToken);

  const dateHR = format(endOfDay(new Date()), "ddMMyyy");

  const { id, patient_group_id } = useParams();

  const location = useLocation();
  const urlStateData = location?.state;

  let x = [];
  let y = [];
  let bgResult = [];

  useEffect(() => {
    if (tabValue === "week" && !weekData?.xData?.length) {
      let url = `${constants.API_URL}/week_data/BG/${
        urlStateData ? patient_group_id : groupName
      }/`;

      url += `${dateHR}/${urlStateData ? careTakerToken : token}/`;
      axios.get(url).then((res) => {
        res.data.week_data?.forEach((data) => {
          var epochTS = data.x_data;
          var d = new Date(parseInt(epochTS));

          let dsnow = `${d.toLocaleDateString()}\n${d.toLocaleTimeString()}`;

          x.push(dsnow);
          y.push(data.y_data.BG.toFixed(1));
          bgResult.push(data);
        });

        setWeekData({ xData: x, yData: y, bgRes: bgResult });
      });
    } else if (tabValue === "month" && !monthData?.xData?.length) {
      let url = `${constants.API_URL}/month_data/BG/${
        urlStateData ? patient_group_id : groupName
      }/`;

      url += `${dateHR}/${urlStateData ? careTakerToken : token}/`;
      axios.get(url).then((res) => {
        res.data.month_data?.forEach((data) => {
          var epochTS = data.x_data;
          var d = new Date(parseInt(epochTS));

          let tsnow = d.toLocaleTimeString();

          let dsnow = `${d.toLocaleDateString()}\n${d.toLocaleTimeString()}`;

          x.push(dsnow);
          y.push(data.y_data.BG.toFixed(1));
          bgResult.push(data);
        });

        setMonthData({ xData: x, yData: y, bgRes: bgResult });
      });
    } else if (tabValue === "threeMonth" && !threeMonthData?.xData?.length) {
      let url = `${constants.API_URL}/three_month_data/BG/${
        urlStateData ? patient_group_id : groupName
      }/`;
      let activeUserDeviceList = userData?.[currentUserIndex]?.["device_list"];

      url += `${dateHR}/${urlStateData ? careTakerToken : token}/`;
      axios.get(url).then((res) => {
        res.data.three_month_data?.forEach((data) => {
          var epochTS = data.x_data;
          var d = new Date(parseInt(epochTS));

          let tsnow = d.toLocaleTimeString();

          let dsnow = `${d.toLocaleDateString()}\n${d.toLocaleTimeString()}`;

          x.push(dsnow);
          y.push(data.y_data.BG.toFixed(1));
          bgResult.push(data);
        });

        setThreeMonthData({ xData: x, yData: y, bgRes: bgResult });
      });
    } else if (tabValue === "year" && !yearData?.xData?.length) {
      let url = `${constants.API_URL}/year_data/BG/${groupName}/`;

      url += `${dateHR}/${token}/`;
      axios.get(url).then((res) => {
        let x = [];
        let y = [];
        res.data.year_data?.forEach((data) => {
          var epochTS = data.x_data;
          var d = new Date(parseInt(epochTS));

          let dsnow = `${d.toLocaleDateString()}\n${d.toLocaleTimeString()}`;

          x.push(dsnow);
          y.push(data.y_data.BG.toFixed(1));
          bgResult.push(data);
        });

        setYearData({ xData: x, yData: y, bgRes: bgResult });
      });
    }
  }, [tabValue]);

  const handleChange = (value) => {
    setTabValue(value);
  };

  const BGManualEntryDel = () => {  
    axios.get(`${constants.API_URL}/delete_latest_manual_entry/${groupName}/BG/${token}/`).then(
      (res) => {
        setOpen(true)
      }
    )
    
  }

  const handleClose = () => {
    setOpen(false);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAnchorClose = () => {
    setAnchorEl(null);
  };

  const openPopOver = Boolean(anchorEl);
  const popOverId = openPopOver ? 'simple-popover' : undefined;

  return (
    <div className="backH" key={tabValue}>
      <div className="container-dashH">
      <div className="DelButton">
      <BreadcrumbsTop data={breadCrumbData}/>
      <IconButton  onClick={handleClick}><MoreHorizIcon/></IconButton> 
      <Popover
        id={popOverId}
        open={openPopOver}
        anchorEl={anchorEl}
        onClose={handleAnchorClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Typography style={{padding:"2.5px", cursor: "pointer",touchAction: "manipulation",transition: "box-shadow .2s ease-in-out", outline: "transparent"}} onClick={BGManualEntryDel}>Delete Last Entry</Typography>
      </Popover>
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Last Manual Entry Successfully Deleted!
        </Alert>
      </Snackbar>
      </div>
        <Tabs
          defaultActiveKey="week"
          activeKey={tabValue}
          transition={false}
          id="noanim-tab-example"
          className="mb-3"
          onSelect={handleChange}
        >
          <Tab className="h-100" eventKey="week" title="WEEK">
            <Row className="h-100">
              <Col>
                <BGChartContainer
                  title="Weekly Report"
                  bgRes={weekData?.bgRes}
                  xData={weekData?.xData}
                  yData={weekData?.yData}
                  type="week"
                />
              </Col>
            </Row>
          </Tab>
          <Tab className="h-100" eventKey="month" title="MONTH">
            <Row className="h-100">
              <Col>
                <BGChartContainer
                  title="Monthly Report"
                  bgRes={monthData?.bgRes}
                  xData={monthData?.xData}
                  yData={monthData?.yData}
                  type="month"
                />
              </Col>
            </Row>
          </Tab>
          <Tab className="h-100" eventKey="threeMonth" title="3MONTHS">
            <Row className="h-100">
              <Col>

                <BGChartContainer
                  title="3 Months Report"
                  bgRes={threeMonthData?.bgRes}
                  xData={threeMonthData?.xData}
                  yData={threeMonthData?.yData}
                  type="threeMonth"
                />
              </Col>
            </Row>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default BGMainPage;

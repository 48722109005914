import React, { useEffect, useState } from "react";
import styles from "./MedAlarmTile.module.css";
import DeleteIcon from '@mui/icons-material/Delete';
import axios from "axios";
import constants from "../../../../../../Common/Constants";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan } from '@fortawesome/free-solid-svg-icons';

const MedAlarmTile = ({ id, time,hour,minute, message, status, onDelete, foodStatus, partOfDay }) => {
  const token = useSelector((state) => state.auth.authToken);
  const groupName = useSelector((state) => state.auth.groupName);
  const [liveTime, setLiveTime] = useState(new Date().toLocaleTimeString());

  const location = useLocation();
  const viewablityConstant = location?.state;

  const date = new Date(time);
  const formattedTime = date.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });

  function getStatusIcon(status) {
    switch (status) {
      case 0:
        return <img src="./Clock2.svg" style={{height:"30px"}} />;
      case 1:
        return <img src="./Ring2.svg"  style={{height:"30px"}}  />;
      case 2:
        return <img src="./Cross2.svg"  style={{height:"30px"}}  />;
      case 3:
        return <img src="./Tick2.svg"  style={{height:"30px"}}  />;
      case 4:
        return <img src="./Tick2.svg" style={{height:"30px"}}  />;
        case 5:
          return <FontAwesomeIcon icon={faBan} style={{height:"30px",color:"#dee2e6"}} />
      default:
        return null;
    }
  }
  const handleDelete = () => {
    onDelete(id);
  };
  const handleNone = () =>{
    
  }

  return (
    <div className={styles.MedAlarmTile}>
      <div className={styles.MedAlarmMainTile}>
        <div>{hour}:{minute}</div>
        <div className={styles.messageStyle}>
      {/* Join the messages with commas */}
      {Array.isArray(message) ? message.join(', ') : message}
    </div>
      </div>
      <div style={{ position: "absolute", right: 10, top: 15 }}>
        {getStatusIcon(status)}
      </div>
      <div className={styles.MedAlarmSubTile}>
        <div>{foodStatus}</div>
        <div style={{cursor:"pointer"}}>
          {/* <DeleteIcon  onClick={viewablityConstant?.viewMode == true ? handleNone : handleDelete}/> */}
          <div>{partOfDay}</div>
        </div>
      </div>
    </div>
  );
};

export default MedAlarmTile;

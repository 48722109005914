import React from 'react'
import './LoginMobileView.css'
import LoginMVImg from './BannerImg..png'
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Button, Divider } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import PermIdentityOutlinedIcon from "@material-ui/icons/PermIdentityOutlined";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { Grid, Typography } from "@mui/material";
import GLogin from '../../SSO/GLogin';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faMobile, faMobileAndroid, faMobilePhone} from '@fortawesome/free-solid-svg-icons';
import { FcGoogle } from 'react-icons/fc';

import resetReduxState from "../../../../../Common/Constants";
import constants from "../../../../../Common/Constants";
import {
    addAuthToken,
    addDeviceList,
    addGroupName,
    addGroupDevices,
    addHeaderGroupName,
    addPageDevices,
    addPageDeviceFavorite,
    addNoOfPages,
    addPresentPage,
    setCareTaker,
    setUserData,
    setIsDoctor,
    setIsPatient,
    setPatientList,
    setCareTakerToken,
    addCureGptToken,
  } from "../../../../../store/authSlice";
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
    paper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      // backgroundColor:"rgba(255, 255, 255, 0.1)",
      padding:"5px 20px 5px 20px",
      borderRadius:"15px",
      // backdropFilter: "blur(30px)"
      // width:"fit-content"
  
    },
  
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
  
    submit: {
      margin: "12px",
    },
    container: {
      width: "100%",
      height: "100vh",
      // backgroundColor: "white",
      // background: `url(${BackgroundImage})`,
      background: "linear-gradient(to right, #49ACA9, #ffff)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      backgroundSize: "cover",
      // backgroundPosition: "center",
      position: "relative",
    },
    blurBackground: {
      position: "absolute",
      width: "100%",
      height: "100%",
      backdropFilter: "blur(6px)",
    },
  
    textField: {
    //   borderBottom: "1px solid black",
      color: "rgb(96, 96, 96) !important",
      borderRadiusTop: "10px",
      borderTopLeftRadius: '10px',
      borderTopRightRadius: '10px',
      margin: "20px 0",
      backgroundColor:"rgb(248, 248, 248)",
      padding:"6px",
      fontFamily:"'Montserrat', sans-serif",
      fontWeight:"500"
    },
  
    formLabel: {
      marginLeft: "0",
      fontWeight:"500",
      marginRight: "12px",
    },
  
    loginbtn: {
      backgroundColor: "#49ACA9",
      borderRadius: "20px",
      height: "20px",
      color: "white",
      fontSize: "12px",
      alignItems: "center",
    },
  
    // signInbtn: {
    //   backgroundColor: "#6A4396",
    //   borderRadius: "10px",
    //   height: "100%",
    //   padding:"5px",
    //   marginTop:"4%",
    //   marginBottom:"5%",
    //   fontSize:"1rem" ,
    //   width: "100%",
    //   color: "white",
    //   alignItems: "center",
    // },
    signInbtn:{
        width: "100%",
        height: "100%",
        background: "#6A4396 !important",
        marginTop:"4%",
        marginBottom:"5%",  
        borderRadius: "10px",
        color: "white",
        fontSize: "1rem",
        fontWeight: "500",
        fontFamily: "'Montserrat', sans-serif",
        alignItems: "center",
        textTransform: "uppercase",
        // padding: "8px 10px 5px 10px",
        border: "transparent",
        boxShadow: "0px 4px 8px 0px rgba(0,0,0,0.3)",
        transition: "all 0.2s ease-out",
    },
    labelHeading: {
        fontSize: "1.2rem !important",
        fontWeight: "600 !important",
        color:"rgb(116, 116, 116);",
        fontFamily: "'Montserrat', sans-serif !important",
        marginTop: "-14px !important",
    },
  
    version: {
      color: "black",
      clear: "both",
      position: "relative",
    },
  }));

export default function LoginMobileView({
    setConnectFlag,
    setConnectReplayFlag,
    closeCreatedSockets,
}) {

    const dispatch = useDispatch();
    const classes = useStyles();
    const history = useHistory();
    const [groupName, setGroupName] = useState("101");
    const [password, setPassword] = useState("passme123");
    const [path, setPath] = useState("home");
    const [errorMessage, setErrorMessage] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);

    const handleSignUpPage = () =>{
        history.push("/patient_signup_page");
    }
    const handleMobileClick =() =>{
      history.push("/mobile_login_page")
    }
    
    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
      };
    
      const handleSignIn = () => {
        history.push("/patient_signup_page");
      };
      const handleLoginSubmit = (e) => {
        e.preventDefault();
        let data = { id: groupName, password: password };
        axios({
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          url: `${constants.API_URL}/authenticate_user/`,
          data: data,
        }).then(
          (response) => {
            // 
            console.log("SignIn Response",response);
        setErrorMessage(false);
        const userData = [];

          if(response?.status === 200){
            userData.push(response.data);
            console.log(userData);
            dispatch(addGroupName(userData?.[0]?.id));
            // setConnectFlag(false);
            // setConnectReplayFlag(false);
            dispatch(setUserData(userData));
            dispatch(addAuthToken(userData?.[0]?.token));
            dispatch(addHeaderGroupName(userData?.[0]?.group_name));
            dispatch(setIsDoctor(userData?.[0]?.is_doctor));
            dispatch(setIsPatient(userData?.[0]?.is_patient));
            dispatch(addCureGptToken(userData?.[0]?.cure_id));

            const patientList = userData?.[0]?.group_list?.map(patient => ({
              group: patient.group,
              group_name: patient.group_name,
            }));
            dispatch(setPatientList(patientList));
            
            history.push("/" + path);
          }
          },
          (error) => {
            setErrorMessage(true);
            
          }
        );
      };
  return (
    <>
    <div className="mobile-view">
      <div className='imgMobile' alt="Mobile View" />
      <div className="form-containerMV">
        <div className="heading">
            <span style={{ color: "#6A4396", fontWeight: "700", fontFamily: "'Montserrat', sans-serif", fontSize: "2rem" }}>Care</span>
            <span style={{ color: "#23AF72",fontWeight:"700", fontFamily: "'Montserrat', sans-serif",fontSize:"2rem"  }}>HQ</span>
        </div>
        <div className='heading2'>Login</div>
        <div className='heading3'>Sign in to access your account</div>
        {/* <input type="text" className="input-field" placeholder="Enter username" />
        <input type="password" className="input-field" placeholder="Enter password" />
        <button className="submit-button">Submit</button> */}
        <div>
                      <form
                          className={classes.form}
                          noValidate
                          onSubmit={handleLoginSubmit}
                      >
                          <TextField
                              variant="standard"
                              id="outlined-basic"
                              label={<Typography className={classes.labelHeading}>Group ID</Typography>}
                              placeholder="Group Name"
                              autoComplete="off"
                              fullWidth
                              color="#6A4396"
                              defaultValue="101"
                              onChange={(e) => setGroupName(e.target.value)}
                              InputProps={{
                                  startAdornment: (
                                      <InputAdornment position="start">
                                          <LockOutlinedIcon style={{ color: "#545459" }} />
                                      </InputAdornment>
                                  ),
                                  className: classes.textField,
                              }}
                          />

                          <div style={{ float: "left", width: "100%", position: "relative" }}>
                              <TextField

                                  variant="standard"
                                  required
                                  fullWidth
                                  label={<Typography className={classes.labelHeading}>Password</Typography>}
                                  name="password"
                                  placeholder="Password"
                                  type={passwordVisible ? "text" : "password"}
                                  id="password"
                                  defaultValue="passme123"
                                  autoComplete="current-password"
                                  onChange={(e) => setPassword(e.target.value)}
                                  InputProps={{
                                      startAdornment: (
                                          <InputAdornment position="start">
                                              <PermIdentityOutlinedIcon
                                                  style={{ color: "#545459" }}
                                              />
                                          </InputAdornment>
                                      ),
                                      className: classes.textField,
                                  }}
                              />
                              <span
                                  style={{
                                      position: "absolute",
                                      top: 18,
                                      right: 0,
                                      cursor: "pointer"
                                  }}
                              >
                                  {" "}
                                  <button
                                      type="button"

                                      style={{
                                          background: "none",
                                          color: "#545459",
                                          border: "none",
                                          cursor: "pointer",
                                          outline: "none",
                                          marginLeft: "-30px"
                                      }}
                                      onClick={togglePasswordVisibility}
                                  >
                                      {passwordVisible ? <VisibilityIcon style={{ height: "20px", width: "20px" }} /> : <VisibilityOffIcon style={{ height: "20px", width: "20px" }} />}
                                  </button>
                              </span>
                          </div>      
                          {errorMessage && (
                              <p
                                  style={{
                                      color: "red",
                                      marginTop: "0",
                                      textAlign: "center",
                                      fontFamily: "'Montserrat', sans-serif"
                                  }}
                              >
                                  Login Failed! Invalid ID or Password
                              </p>
                          )}

                          <Button
                              className={classes.signInbtn}
                            //   color="white"
                              type="submit"
                            //   buttonlabel="Sign In"
                          >
                              LOGIN
                          </Button>

                          <div style={{display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center",fontFamily: "'Montserrat', sans-serif", fontSize: "0.8rem", height:"100%" }}>
                            Don't have an account? <div onClick={handleSignUpPage} style={{color:"#6A4396", fontWeight:"700", marginLeft:"10px",fontFamily: "'Montserrat', sans-serif",cursor:"pointer", fontSize: "0.9rem" }}> SignUp</div> </div>

                          <Grid container alignItems="center" style={{ marginTop: "10px" }}>
                              <Grid item xs>
                                  <Divider style={{ height: "1px", backgroundColor: "rgb(163, 163, 163)" }} />
                              </Grid>
                              <Grid item>
                                  <Typography variant="caption" style={{ margin: "0 10px",color: "rgb(163, 163, 163)" }}>OR CONTINUE WITH</Typography>
                              </Grid>
                              <Grid item xs>
                                  <Divider style={{ height: "1px", backgroundColor: "rgb(201, 198, 198)" }} />
                              </Grid>
                          </Grid>

                          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", padding: "10px 10px 30px 10px", margin: "5px 10px 10px 10px" }}>

                              <div>
                                <GLogin/>  
                              </div>
                          <div>
                  <Button
                    label='Login with Mobile'
                   onClick={handleMobileClick}
                    // disabled={renderProps.disabled}
                    style={{
                      width: "fit-content",
                      height: "fit-content",
                      borderRadius: "20px",
                      margin:"2px",
                      background: "#fff",
                      color: "#6D3DA3",
                      fontSize: "11.5px",
                      fontWeight: "500",
                      fontFamily: "'Montserrat', sans-serif",
                      alignItems: "center",
                      textTransform: "uppercase",
                      padding: "5px 10px 5px 10px",
                      border: "transparent",
                      transition: "all 0.2s ease-out",
                      boxShadow: "0px 4px 8px 0px rgba(0,0,0,0.3)",

                    }}
                  >
                    <FontAwesomeIcon icon={faMobilePhone} size="xl" style={{ marginRight: "5px" }} />
                    MOBILE
                  </Button>
                  </div>
                  </div>

                      </form>
        </div>
      </div>
    </div>
    </>
  )
}

import React, { useState, useEffect } from "react";
import GraphStatsCard from "./GraphStatsCard";
import "./StatsCard.css";

const GraphStatsColumn = ({ data }) => {
  const [statsTypes, setStatsTypes] = useState([]);
  const [statsValues, setStatsValues] = useState([]);

  useEffect(() => {
    if (data.emptyStatsSettings) {
      setStatsTypes(["---", "---", "---", "---"]);
      setStatsValues(["...", "...", "...", "..."]);
    } else {
      setStatsTypes(Object.keys(data.statsData[0]));
      setStatsValues(Object.values(data.statsData[0]));
    }
  }, [data]);

  return (
    <div className="graphDashboard_GridCard">
      {statsTypes[0] && (
        <GraphStatsCard
          statusType={statsTypes[0]}
          statusValue={statsValues[0]}
          statusColor="status_green"
        />
      )}
      {statsTypes[1] && (
        <GraphStatsCard
          statusType={statsTypes[1]}
          statusValue={statsValues[1]}
          statusColor="status_aqua"
        />
      )}
      {statsTypes[2] && (
        <GraphStatsCard
          statusType={statsTypes[2]}
          statusValue={statsValues[2]}
          statusColor="status_white"
        />
      )}
      {statsTypes[3] && (
        <GraphStatsCard
          statusType={statsTypes[3]}
          statusValue={statsValues[3]}
          statusColor="status_yellow"
        />
      )}
    </div>
  );
};
export default GraphStatsColumn;

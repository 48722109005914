import React, { useState } from "react";
import styles from "./Schedule.module.css";
import { useFormikContext } from "formik";
import MedicationForm2 from "./MedicationForm2";

export const MedicationTimeSel = () => {
  const { values, handleChange } = useFormikContext();
  // console.log("form values : ", values);

  const timeFieldControl = () => {
    let time = {
        morningTime : [] ,
        noonTime : [] ,
        nightTime : []
    };

    (values?.medicines || []).forEach((item) => {

      if (item.hasOwnProperty("morningTime") && item["morningTime"]) {
        time["morningTime"]?.push(item["morning"]);
      }
      if (item.hasOwnProperty("noonTime") && item["noonTime"]) {
        time["noonTime"]?.push(item["noon"]);
      }
      if (item.hasOwnProperty("nightTime") && item["nightTime"]) {
        time["nightTime"]?.push(item["night"]);
      }
    });
    // console.log( time ,'TIMEEEEE');
    return time ;
  };

  const time = timeFieldControl()
  // console.log("tttt:",disabledTime)
  return (
    <div className={styles.MedSchedBody}>
      {/* {JSON.stringify(values, null, 5)} */}
      <span className={styles.selHeading}>Select your medication time</span>
      <div className={styles.TimeBody}>
        <p className={styles.timeHead}>Morning</p>
        <div className={styles.Scheds}>
          <div className={styles.Before}>
            <p>Before Food</p>
            <input
              type="time"
              name="beforemorningtime"
              className={styles.timeField}
              value={values?.beforemorningtime}
              onChange={handleChange}
              disabled={!(time.morningTime.includes('BF'))}
            />
          </div>
          <div className={styles.Before}>
            <p>After Food</p>
            <input
              type="time"
              name="aftermorningtime"
              className={styles.timeField}
              value={values?.aftermorningtime}
              onChange={handleChange}
              disabled={!(time.morningTime.includes('AF'))}
            />
          </div>
        </div>

        <p className={styles.timeHead}>Noon</p>
        <div className={styles.Scheds}>
          <div className={styles.Before}>
            <p>Before Food</p>
            <input
              type="time"
              name="beforeNoontime"
              className={styles.timeField}
              value={values?.beforeNoontime}
              onChange={handleChange}
              disabled={!(time.noonTime.includes('BF'))}
            />
          </div>
          <div className={styles.Before}>
            <p>After Food</p>
            <input
              type="time"
              name="afterNoontime"
              className={styles.timeField}
              value={values?.afterNoontime}
              onChange={handleChange}
              disabled={!(time.noonTime.includes('AF'))}
            />
          </div>
        </div>

        <p className={styles.timeHead}>Night</p>
        <div className={styles.Scheds}>
          <div className={styles.Before}>
            <p>Before Food</p>
            <input
              type="time"
              name="beforeNighttime"
              value={values?.beforeNighttime}
              className={styles.timeField}
              onChange={handleChange}
              disabled={!(time.nightTime.includes('BF'))}
            />
          </div>
          <div className={styles.Before}>
            <p>After Food</p>
            <input
              type="time"
              name="afterNighttime"
              className={styles.timeField}
              value={values?.afterNighttime}
              onChange={handleChange}
              disabled={!(time.nightTime.includes('AF'))}
            />
          </div>
        </div>
      </div>
      <div className={styles.setBtnDiv}>
        <button className={styles.setBtn} type="submit">
          SAVE CHANGES
        </button>
      </div>
    </div>
  );
};

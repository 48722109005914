import React, { useState } from 'react'
import { Grid, Paper, Button, Typography } from '@material-ui/core'
import { TextField } from '@material-ui/core'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { useRef } from 'react'
import { useSelector, useDispatch } from "react-redux"
import constants from '../../../../../Common/Constants'
import { setPassword } from "../../../../../store/authSlice";
import axios from "axios";
import { useParams, useHistory, useLocation } from "react-router-dom";
import {
  setDashboardData,
  setWebsocketStatus,
} from "../../../../../store/dashboardSlice";
import { PopMsg } from "./PopMsg";
import { Alert } from '@material-ui/lab';
import { Snackbar } from '@material-ui/core';

function FormHR({ handleClose, careTakerPatientView = false, refreshChartData }) {

  const dispatch = useDispatch();
  const [showPopup, setShowPopup] = useState(false);
  const careTakerToken = useSelector((state) => state.auth.careTakerToken);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarColor, setSnackbarColor] = useState('');

  const token = useSelector((state) => state.auth.authToken);
  const groupName = useSelector((state) => state.auth.groupName);
  const paperStyle = { padding: '0 5px 50px 5px', width: 270, }
  const btnStyle = { marginTop: 10, fontFamily: "'Montserrat', sans-serif" }
  const SysDiaValue = /^[0-9]{2,3}/
  const initialValues = {
    PUL: '',
    // Date: '',
    // Time:'',
    datetime: "",
  }
  const userData = useSelector((state) => state.auth.userData)

  const { id, patient_group_id } = useParams();

  const location = useLocation();
  const urlStateData = location?.state
  console.log(urlStateData)

  const isTimeValid = (value) => {
    const selectedTime = new Date(`1970-01-01T${value}:00`);
    const now = new Date();
    return selectedTime <= now;
  };


  const validationSchema = Yup.object().shape({


    PUL: Yup.number().min(0).max(300).required("Required"),


    //   Date: Yup.date()
    //   .max(new Date(), "Future Date cannot be entered")
    //  .required("Required"),

    //  Time: Yup.string()
    //  .max(new Date(), "Future Time cannot be entered")
    //  .required("Required"),
    // Time: Yup.mixed().test("is-time-valid", "Future Time cannot be entered", isTimeValid),

    datetime: Yup.date()
      .max(new Date(), "Date and time must be in the past")
      .required("Please enter a date and time"),

    //  Time: Yup.string().test("is-time-valid", "Time must be in the past", isTimeValid),
    // Time: Yup.date()
    // .max(new Date(), "Time must be in the past")
    // .transform((value, originalValue) => {
    //   const date = new Date();
    //   const time = new Date(originalValue);
    //   date.setHours(time.getHours(), time.getMinutes());
    //   return date;
    // }),

  })

  const fetchDataApi = async () => {
    let url = `${constants.API_URL}/dashboard_values/${urlStateData ? patient_group_id : groupName}/${token}/`;

    // url += `${token}/`;
    console.log(careTakerPatientView)

    const result = await axios.get(url);
    console.log(result)
    dispatch(setDashboardData(result.data.data));
  };

  const onSubmit = (values, props) => {


    props.resetForm()

    var dataPUL = (values.PUL)

    // const dateValue=values.Date 

    // const timeValue=values.Time

    const datetimevalue = values.datetime

    console.log(datetimevalue)

    // var timestampData   =  dateValue +" "+ timeValue  ;
    var epochTime = Date.parse(datetimevalue);
    console.log(epochTime)

    // var utcTime = epochTime

    var formData = JSON.stringify({ PUL: parseInt(dataPUL), TS: epochTime });
    console.log(formData)



    console.log("formData", formData)

    axios({
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      url: `${constants.API_URL}/manual_entry/${urlStateData ? patient_group_id : groupName}/${token}/`,
      data: formData,


    }).then(
      (res) => {
        console.log(res)
        if (res.status === 200) {

          setShowPopup(true);
          setSnackbarOpen(true);
          setSnackbarColor('success');
          setSnackbarMessage('Manual Entry Successful');
          refreshChartData();

          setTimeout(() => {
            // show pop up message
            setShowPopup(false);
            handleClose();

          }, 2000);
          clearTimeout();


          setTimeout(() => {
            // show pop up message
            fetchDataApi();
            console.log("api refreshing for manual entry")
          }, 3000);
          clearTimeout();
        }
      }).catch((error) => {
         console.log(error?.response);
         if(error?.response?.status == 400){
          setShowPopup(true);
          setSnackbarOpen(true);
          setSnackbarColor('error');
          setSnackbarMessage(error?.response?.data?.message);
         }
         else if (error?.response?.status == 500){
          setShowPopup(true);
          setSnackbarOpen(true);
          setSnackbarColor('error');
          setSnackbarMessage("Something went wrong");
         }
      });

  };
  const vertical = "bottom";
  const horizontal = "center";


  return (
    <div>
      {showPopup && (
        <PopMsg />
      )}

      <Grid>
        <Paper elevation={0} style={paperStyle}>

          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {(props) => (
              <Form noValidate>

                {/* {JSON.stringify(props.errors)} <br />
{JSON.stringify(props.values)} */}
                <div style={{ marginBottom: "10px" }}>
                  <Field as={TextField} name='PUL' label='Heart Rate' fullWidth
                    error={props.errors.PUL && props.touched.PUL} autoFocus
                    required />
                  <div style={{ color: "Red" }}>
                    <ErrorMessage name='PUL' />
                  </div>
                </div>

                <div style={{ marginBottom: "10px" }}>
                  <Field type="datetime-local" name="datetime" label="datetime" fullWidth
                    error={props.errors.datetime && props.touched.datetime}
                    // helperText={props.errors.Date && props.touched.Date ? props.errors.Date : null}
                    required />
                  <div style={{ color: "Red" }}>
                    <ErrorMessage name='datetime' />
                  </div>
                </div>
                {/* 
                <div style={{marginBottom:"10px"}}>
                    <Field type="time" name="Time" label="Time" fullWidth
                        error={props.errors.Time && props.touched.Time}
                        // helperText={<ErrorMessage name='Time' />}  
                        required />
                    <div style={{color:"Red"}}>
                        <ErrorMessage name='Time' />
                        </div>
                </div> */}

                <Button type='submit' style={btnStyle} variant='contained'
                  color='primary'>Submit Data</Button>
              </Form>
            )}
          </Formik>
        </Paper>
      </Grid>

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        key={vertical + horizontal}
      >
        <Alert onClose={handleClose} severity={snackbarColor} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>

  )
}

export default FormHR
import React, { useEffect, useState, useRef } from "react";
import styles from "./ForgotPassword.module.css";
import { useHistory, useLocation } from "react-router-dom";
import { Timer } from "./Timer";
import constants from "../../../../../../Common/Constants";
import axios from "axios";

export const EmailVerification = () => {
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [firstVal, setFirstVal] = useState();
  const [secondVal, setSecondVal] = useState();
  const [thirdVal, setThirdVal] = useState();
  const [fifthVal, setFifthVal] = useState();
  const [fourthVal, setFourthVal] = useState();
  const [sixthVal, setSixthVal] = useState();
  const [isResendActive, setIsResendActive] = useState(false);
  const [timer, setTimer] = useState(30);
  const [error, setError] = useState("");
  const countDownTimer = useRef(null);
  const email = queryParams.get("email") || "";
  const contactNumber = queryParams.get("contactNumber") || "";
  const firstInputRef = useRef(null);
  const secondInputRef = useRef(null);
  const thirdInputRef = useRef(null);
  const fourthInputRef = useRef(null);
  const fifthInputRef = useRef(null);
  const sixthInputRef = useRef(null);

  const handleSignIn = () => {
    history.push("/");
  };
 

  const verifyOTP = async () => {
    const apiUrl = `${constants.API_URL}/password_reset_verify_otp/`;

    // Prepare the payload with the OTP and contact details
    const otp = `${firstVal}${secondVal}${thirdVal}${fourthVal}${fifthVal}${sixthVal}`;
    const otpNum = parseInt(otp, 10);
    const payload = {
      code: otpNum,
      detail: email || `+${contactNumber}`,
    };

    try {
      const response = await axios.post(apiUrl, payload);

      if (response.status === 200) {
        
        const otp_token = response?.data?.otp_token;
        const id = response?.data?.id;
        const token = response?.data?.token;
        // Verification successful, you can handle it accordingly
        history.push(
          `/reset_password?otp_token=${otp_token}&id=${id}&token=${token}`
        ); // Redirect to the reset password page or another suitable action
        // history.push("/reset_password")
      } else {
        setError("Invalid OTP"); // Handle the case when OTP is invalid
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      setError("Error verifying OTP"); // Handle API request error
    }
  };
  
  const resendOTP = () => {
    const apiUrl = `${constants.API_URL}/password_reset_otp_generator/`;
    const payload = {
      detail: email || `+${contactNumber}`,
    };
    
    try {
      const response = fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Add any other headers you need here
        },
        body: JSON.stringify(payload),
      });
    }
    catch (error) {
      console.error("Error sending verification code:", error);
    }
  }

  return (
    <div className={styles.mainContainerForgot}>
      <div className={styles.mobileViewForgot}>
        <div>
          <span
            style={{
              color: "#6A4396",
              fontWeight: "700",
              fontFamily: "'Montserrat', sans-serif",
              fontSize: "1.6rem",
            }}
          >
            Care
          </span>
          <span
            style={{
              color: "#23AF72",
              fontWeight: "700",
              fontFamily: "'Montserrat', sans-serif",
              fontSize: "1.6rem",
            }}
          >
            HQ
          </span>
        </div>

        <div className={styles.detailForgot}>
          <div className={styles.forgotHeading}>Enter Verification Code</div>
          <div className={styles.verifyDiv}>
            <input
              type="text"
              placeholder="0"
              className={styles.verifyDigit}
              value={firstVal}
              onChange={(e) => {
                setFirstVal(e.target.value);
                if (e.target.value.length === 1) {
                  secondInputRef.current.focus();
                }
              }}
              maxLength={1}
              ref={firstInputRef}
            />

            <input
              type="text"
              placeholder="0"
              className={styles.verifyDigit}
              value={secondVal}
              onChange={(e) => {
                setSecondVal(e.target.value);
                if (e.target.value.length === 1) {
                  thirdInputRef.current.focus();
                }
              }}
              maxLength={1}
              ref={secondInputRef}
            />

            <input
              type="text"
              placeholder="0"
              className={styles.verifyDigit}
              value={thirdVal}
              onChange={(e) => {
                setThirdVal(e.target.value);
                if (e.target.value.length === 1) {
                  fourthInputRef.current.focus();
                }
              }}
              maxLength={1}
              ref={thirdInputRef}
            />

            <input
              type="text"
              placeholder="0"
              className={styles.verifyDigit}
              value={fourthVal}
              onChange={(e) => {
                setFourthVal(e.target.value);
                if (e.target.value.length === 1) {
                  fifthInputRef.current.focus();
                }
              }}
              maxLength={1}
              ref={fourthInputRef}
            />

            <input
              type="text"
              placeholder="0"
              className={styles.verifyDigit}
              value={fifthVal}
              onChange={(e) => {
                setFifthVal(e.target.value);
                if (e.target.value.length === 1) {
                  sixthInputRef.current.focus();
                }
              }}
              maxLength={1}
              ref={fifthInputRef}
            />

            <input
              type="text"
              placeholder="0"
              className={styles.verifyDigit}
              value={sixthVal}
              onChange={(e) => {
                setSixthVal(e.target.value);
                if (e.target.value.length === 1) {
                  sixthInputRef.current.focus();
                }
              }}
              maxLength={1}
              ref={sixthInputRef}
            />
          </div>
          <div className={styles.errorMessage}>{error}</div>
          <Timer ref={countDownTimer} onComplete={resendOTP} delay={30}/>
          <div className={styles.verify} onClick={verifyOTP}>
            Verify
          </div>
          <div className={styles.backBtn} onClick={handleSignIn}>
            Back to Login
          </div>
        </div>
      </div>
    </div>
  );
};

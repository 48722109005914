import React from 'react'
import {Card , CardActionArea} from '@mui/material'
import './FNewDash.css'
import {Row, Col} from 'reactstrap' 
import HeartrateChart1 from './HeartrateChart1'
import {useHistory} from 'react-router-dom'
import StepChartHour from './StepChartHour'
import DisChartHourly from './DisChartHourly'
import BreathChart from './BreathChart'
import SleepChartdaily from './SleepChartdaily'
import Spo2ChartDaily from './Spo2ChartDaily'
import BPChartDaily from './BPChartDaily'
import GlucoseChartDaily from './GlucoseChartDaily'

const FNewDash = () => {
  
  const history = useHistory() ;
const HeartPage =() =>{
history.push('/heartratepage');
}
const StepsPage = () =>{
  history.push("/stepspage");
}
const DisPage = () =>{
  history.push("/distancepage");
}
const SlpPage = () =>{
  history.push("/sleeppage")
}
const Spo2Page = () =>{
  history.push("/spo2page")
}
const BrPage = () =>{
  history.push("/brpage")
}
const BpPage = () =>{
  history.push("/bppage")
}
const BgPage = () =>{
  history.push("/bgpage")
}

  return (
    <div className='grid'>
    
        <Row>
            <Col>
        <div className='card1'> <Card onClick={HeartPage}>
            
            <CardActionArea >
                
            <HeartrateChart1/>
            </CardActionArea>
          </Card></div>
          </Col>
          
          <Col>
          <div className='card2'> 
          <Card onClick={StepsPage}>
            <CardActionArea>
            <StepChartHour/>
            </CardActionArea>
          </Card></div>
          </Col>

          <Col>
        <div className='card3'> <Card onClick={BrPage}>
            
            <CardActionArea>
            <BreathChart/>
            </CardActionArea>
          </Card></div>
          </Col>
          </Row>
          <Row>
          <Col>
          <div className='card5'> 
            <Card onClick={DisPage}>
            <CardActionArea>
            <DisChartHourly/>
            </CardActionArea>
          </Card></div>
          </Col>

          <Col>
          <div className='card6'> 
            <Card onClick={SlpPage}>
            <CardActionArea>
         <SleepChartdaily/>
            </CardActionArea>
          </Card></div>
          </Col>
          <Col>
          <div className='card7'> 
            <Card onClick={BgPage}>
            <CardActionArea>
             <GlucoseChartDaily/>
            </CardActionArea>
          </Card></div>
          </Col>
          </Row>
          <Row>
           <Col>
          <div className='card8'> 
            <Card onClick={BpPage}>
            <CardActionArea>
          <BPChartDaily/>
            </CardActionArea>
          </Card></div>
          </Col>
          <Col>
          <div className='card9'> 
            <Card onClick={Spo2Page}>
            <CardActionArea>
            <Spo2ChartDaily/>
            </CardActionArea>
          </Card></div>
          </Col>
          </Row>

          
    </div>
   
  
  )
}

export default FNewDash
import React, { memo } from "react";
import ReactEcharts from "echarts-for-react";
import "../../FitCharts/FNewDash.css";

function BPChartPage1({ title, xData, yData1, yData2, bpRes, handleClick }) {

  const colorBP1 = bpRes
    ?.map((point) => {
      var epochTS = new Date(parseInt(point.x_data));
      var dsnow = `${epochTS.toLocaleDateString()} \n ${epochTS.toLocaleTimeString()}`;

      return {
        value: [dsnow, (point?.y_data?.BPS).toFixed(1)],

        itemStyle: {
          color: point.y_data.is_manual ? "#808080" : "#27A9E3",
        },
      };
    })
    .sort((a, b) => {
      // Convert the x-axis values to Date objects for comparison
      const dateA = new Date(`01/01/2021 ${a.value[0]}`);
      const dateB = new Date(`01/01/2021 ${b.value[0]}`);

      // Compare the dates and return the sort order
      if (dateA < dateB) {
        return -1;
      }
      if (dateA > dateB) {
        return 1;
      }
      return 0;
    });


  const colorBP2 = bpRes
    ?.map((point) => {
      var epochTS = new Date(parseInt(point.x_data));
      var dsnow = `${epochTS.toLocaleDateString()} \n ${epochTS.toLocaleTimeString()}`;

      return {
        value: [dsnow, (point?.y_data?.BPD).toFixed(1)],
        itemStyle: {
          color: point.y_data.is_manual ? "#808080" : "#852B99",
        },
      };
    })
    .sort((a, b) => {
      // Convert the x-axis values to Date objects for comparison
      const dateA = new Date(`01/01/2021 ${a.value[0]}`);
      const dateB = new Date(`01/01/2021 ${b.value[0]}`);

      // Compare the dates and return the sort order
      if (dateA < dateB) {
        return -1;
      }
      if (dateA > dateB) {
        return 1;
      }
      return 0;
    });



  const chartData = React.useMemo(() => {
    return {
      title: {
        left: "center",
        text: title,
        textStyle: {
          fontFamily: "sans-serif",
        },
      },

      tooltip: {
        textStyle: {
          fontFamily: "sans-serif",
        },

        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },

      xAxis: {
        type: "category",
        // data: xData
      },
      yAxis: [
        {
          type: "value",
        },
        {
          type: "value",
        },
      ],

      series: [
        {
          name: "Sys ",
          type: "line",
          color: "#00B5C6",
          data: colorBP1,
        },
        {
          name: "Dia ",
          type: "line",
          textStyle: {
            fontFamily: "sans-serif",
          },
          color: "#6D3DA3",
          data: colorBP2,
        },
      ],
    };
  }, [xData, yData1, yData2, title]);
  const _handleClick = (params, data) => {
    let index = params.dataIndex;
    handleClick &&
      "function" === typeof handleClick &&
      handleClick(colorBP1[index],colorBP2[index]);
  };
  
  return (
    <div>
      {chartData?.series?.[0]?.data?.length ? (
        <div className="left-chart">
          <ReactEcharts
            option={chartData}
            onEvents={{
              click: _handleClick,
            }}
          />
        </div>
      ) : (
        <p style={{ fontSize: "20px", fontWeight: "bold" }}>
          NO DATA AVAILABLE{" "}
        </p>
      )}
    </div>
  );
}
export default memo(BPChartPage1);

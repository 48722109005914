import React, { useState, useEffect } from "react";
import styles from "./TMPPopup.module.css";
import { Tabs } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import axios from "axios";
import constants from "../../../../Common/Constants";
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import { endOfDay, format } from "date-fns";
import CancelIcon from '@mui/icons-material/Cancel';
import { MoreHoriz } from "@material-ui/icons";
import { Snackbar } from "@mui/material";
import { TMPContainer } from "./TMPContainer";
import PopUpManualEntry from "../OtherComponents/PopUpManualEntry";
import FormTemp from "../../../Member Details/Comps/FitbitFolder/FitCharts/FormTemp";

const TMPPopup= () => {
    const [tabValue, setTabValue] = useState("week");
    const [dayData, setDayData] = useState({
      xData: [],
      yData: [],
      bgRes: [],
    });
    const [monthData, setMonthData] = useState({
      xData: [],
      yData: [],
      bgRes: [],
    });
    const [weekData, setWeekData] = useState({ xData: [], yData: [], bgRes: [] });
    const [threeMonthData, setThreeMonthData] = useState({
      xData: [],
      yData: [],
      bgRes: [],
    });
    const groupName = useSelector((state) => state.auth.groupName);
    const token = useSelector((state) => state.auth.authToken);
    const careTakerToken = useSelector((state) => state.auth.careTakerToken);
    const currentUserIndex = useSelector((state) => state.auth.currentUserIndex);
    const dateHR = format(endOfDay(new Date()), "ddMMyyy");
    const userData = useSelector((state) => state.auth.userData);
    const location = useLocation();
    const urlStateData = location?.state;
    const { id, patient_group_id } = useParams();

    const viewOnly = location?.state;
    const {pat_id} = useParams();

    const handleChange = (value) => {
      setTabValue(value);
    };
    const [showPopup, setShowPopup] = useState(false);

    const openPopup = () => {
      if (viewOnly?.viewOnly == true) {
        setShowPopup(false)
      } else {
        setShowPopup(true);
      }
    };
    
    const closePopup = (event) => {
      event.stopPropagation();
      setShowPopup(false);
    };
    const handleClose = () => {
      setOpen(false);
    };
    const [response, setResponse] = useState(null); 
    const [open, setOpen] = React.useState(false);
  
    let x = [];
    let y = [];
    let bgResult = [];

    const refreshChartData = () => {

      console.log("Refreshing Chart Data",tabValue);
  
      // setDayData({ xData: [], yData: [],  bgRes: [] });
      // setMonthData({ xData: [], yData: [],  bgRes: [] });
      // setThreeMonthData({ xData: [], yData: [], bgRes: [] });
      // setWeekData({ xData: [], yData: [], bgRes: [] });
  
      if (tabValue === "day" ) {
        setTimeout(() => {
        let url = `${constants.API_URL}/day_data/TMP/${
        viewOnly?.viewOnly? pat_id : urlStateData?.patientView? patient_group_id : groupName
        }/`;
  
        url += `${dateHR}/${token}/`;
        axios.get(url).then((res) => {
          res.data.day_data?.forEach((data) => {
            var epochTS = data.x_data;
            var d = new Date(parseInt(epochTS));
  
            let dsnow = `${d.toLocaleDateString()}\n${d.toLocaleTimeString()}`;
  
            x.push(dsnow);
            y.push(data.y_data.TMP.toFixed(1));
            bgResult.push(data);
          });
  
          setDayData({ xData: x, yData: y, bgRes: bgResult });
        });
      },3000)
      } 
      else if (tabValue === "week" ) {
        setTimeout(() => {
        let url = `${constants.API_URL}/week_data/TMP/${
        viewOnly?.viewOnly? pat_id : urlStateData?.patientView? patient_group_id : groupName
        }/`;
  
        url += `${dateHR}/${token}/`;
        axios.get(url).then((res) => {
          res.data.week_data?.forEach((data) => {
            var epochTS = data.x_data;
            var d = new Date(parseInt(epochTS));
  
            let dsnow = `${d.toLocaleDateString()}\n${d.toLocaleTimeString()}`;
  
            x.push(dsnow);
            y.push(data.y_data.TMP.toFixed(1));
            bgResult.push(data);
          });
  
          setWeekData({ xData: x, yData: y, bgRes: bgResult });
        });
      },3000)
      } else if (tabValue === "month" ) {
        setTimeout(() => {
        let url = `${constants.API_URL}/month_data/TMP/${
          viewOnly?.viewOnly? pat_id : urlStateData?.patientView? patient_group_id : groupName
        }/`;
  
        url += `${dateHR}/${token}/`;
        axios.get(url).then((res) => {
          res.data.month_data?.forEach((data) => {
            var epochTS = data.x_data;
            var d = new Date(parseInt(epochTS));
  
            let tsnow = d.toLocaleTimeString();
  
            let dsnow = `${d.toLocaleDateString()}\n${d.toLocaleTimeString()}`;
  
            x.push(dsnow);
            y.push(data.y_data.TMP.toFixed(1));
            bgResult.push(data);
          });
  
          setMonthData({ xData: x, yData: y, bgRes: bgResult });
        });
      },3000)
      } else if (tabValue === "threeMonth" ) {
        setTimeout(() => {
        let url = `${constants.API_URL}/three_month_data/TMP/${
          viewOnly?.viewOnly? pat_id : urlStateData?.patientView? patient_group_id : groupName
        }/`;
        let activeUserDeviceList = userData?.[currentUserIndex]?.["device_list"];
  
        url += `${dateHR}/${token}/`;
        axios.get(url).then((res) => {
          res.data.three_month_data?.forEach((data) => {
            var epochTS = data.x_data;
            var d = new Date(parseInt(epochTS));
  
            let tsnow = d.toLocaleTimeString();
  
            let dsnow = `${d.toLocaleDateString()}\n${d.toLocaleTimeString()}`;
  
            x.push(dsnow);
            y.push(data.y_data.TMP.toFixed(1));
            bgResult.push(data);
          });
  
          setThreeMonthData({ xData: x, yData: y, bgRes: bgResult });
        });
      },3000)
      } 
  
    }
  
    useEffect(() => {
      if (tabValue === "day" && !dayData?.xData?.length) {
        let url = `${constants.API_URL}/day_data/TMP/${
        viewOnly?.viewOnly? pat_id : urlStateData?.patientView? patient_group_id : groupName
        }/`;
  
        url += `${dateHR}/${token}/`;
        axios.get(url).then((res) => {
          res.data.day_data?.forEach((data) => {
            var epochTS = data.x_data;
            var d = new Date(parseInt(epochTS));
  
            let dsnow = `${d.toLocaleDateString()}\n${d.toLocaleTimeString()}`;
  
            x.push(dsnow);
            y.push(data.y_data.TMP.toFixed(1));
            bgResult.push(data);
          });
  
          setDayData({ xData: x, yData: y, bgRes: bgResult });
        });
      } 
      else if (tabValue === "week" && !weekData?.xData?.length) {
        let url = `${constants.API_URL}/week_data/TMP/${
        viewOnly?.viewOnly? pat_id : urlStateData?.patientView? patient_group_id : groupName
        }/`;
  
        url += `${dateHR}/${token}/`;
        axios.get(url).then((res) => {
          res.data.week_data?.forEach((data) => {
            var epochTS = data.x_data;
            var d = new Date(parseInt(epochTS));
  
            let dsnow = `${d.toLocaleDateString()}\n${d.toLocaleTimeString()}`;
  
            x.push(dsnow);
            y.push(data.y_data.TMP.toFixed(1));
            bgResult.push(data);
          });
  
          setWeekData({ xData: x, yData: y, bgRes: bgResult });
        });
      } else if (tabValue === "month" && !monthData?.xData?.length) {
        let url = `${constants.API_URL}/month_data/TMP/${
          viewOnly?.viewOnly? pat_id : urlStateData?.patientView? patient_group_id : groupName
        }/`;
  
        url += `${dateHR}/${token}/`;
        axios.get(url).then((res) => {
          res.data.month_data?.forEach((data) => {
            var epochTS = data.x_data;
            var d = new Date(parseInt(epochTS));
  
            let tsnow = d.toLocaleTimeString();
  
            let dsnow = `${d.toLocaleDateString()}\n${d.toLocaleTimeString()}`;
  
            x.push(dsnow);
            y.push(data.y_data.TMP.toFixed(1));
            bgResult.push(data);
          });
  
          setMonthData({ xData: x, yData: y, bgRes: bgResult });
        });
      } else if (tabValue === "threeMonth" && !threeMonthData?.xData?.length) {
        let url = `${constants.API_URL}/three_month_data/TMP/${
          viewOnly?.viewOnly? pat_id : urlStateData?.patientView? patient_group_id : groupName
        }/`;
        let activeUserDeviceList = userData?.[currentUserIndex]?.["device_list"];
  
        url += `${dateHR}/${token}/`;
        axios.get(url).then((res) => {
          res.data.three_month_data?.forEach((data) => {
            var epochTS = data.x_data;
            var d = new Date(parseInt(epochTS));
  
            let tsnow = d.toLocaleTimeString();
  
            let dsnow = `${d.toLocaleDateString()}\n${d.toLocaleTimeString()}`;
  
            x.push(dsnow);
            y.push(data.y_data.TMP.toFixed(1));
            bgResult.push(data);
          });
  
          setThreeMonthData({ xData: x, yData: y, bgRes: bgResult });
        });
      } 
    }, [tabValue]);
    
    const deleteLastEntry = () => {
      axios.get(`${constants.API_URL}/delete_latest_manual_entry/${viewOnly?.viewOnly? pat_id : urlStateData?.patientView? patient_group_id:groupName}/TMP/${token}/`).then((res) => {
        if (res.data.message === "success") {
          setResponse('success');
          setOpen(true);
          // Update the dayData state to reflect the changes
          const updatedDayData = { ...dayData };
          updatedDayData.xData.pop(); // Remove the last entry from xData
          updatedDayData.yData.pop(); // Remove the last entry from yData
          updatedDayData.bgRes.pop();
          setDayData(updatedDayData);

          // Update the weekData state to reflect the changes
          const updatedWeekData = { ...weekData };
          updatedWeekData.xData.pop(); // Remove the last entry from xData
          updatedWeekData.yData.pop(); // Remove the last entry from yData
          updatedWeekData.bgRes.pop();
          setWeekData(updatedWeekData);

             // Update the weekData state to reflect the changes
             const updatedMonthData = { ...monthData };
             updatedMonthData.xData.pop(); // Remove the last entry from xData
             updatedMonthData.yData.pop(); // Remove the last entry from yData
             updatedMonthData.bgRes.pop();
             setMonthData(updatedMonthData);

               // Update the weekData state to reflect the changes
           const updatedThreeMonthData = { ...threeMonthData };
           updatedThreeMonthData.xData.pop(); // Remove the last entry from xData
           updatedThreeMonthData.yData.pop(); // Remove the last entry from yData
           updatedThreeMonthData.bgRes.pop();
           setThreeMonthData(updatedThreeMonthData);

        } else if (res.data.message === "No data found") {
          setResponse('noData');
          setOpen(true);
        } else {
          setResponse('error');
          setOpen(true);
        }
    
      })
      .catch((error) => {
        setResponse('error');
        setOpen(true);
  
      });
    };
  
    return (
      <div className={styles.mainContainer}>
        <div className={styles.heading}>
          <div>TEMPERATURE</div>
          <div style={{ color: "grey", cursor: "pointer" }} >
            <MoreHoriz onClick={openPopup}/>
          </div>
        </div>
        <div className={styles.contentDiv}>
          <div className={styles.tabContainer}>
          <div
              className={`${styles.tab} ${tabValue === "day" && styles.activeTab}`}
              onClick={() => handleChange("day")}
            >
              DAY
            </div>
            <div
              className={`${styles.tab} ${tabValue === "week" && styles.activeTab}`}
              onClick={() => handleChange("week")}
            >
              WEEK
            </div>
            <div
              className={`${styles.tab} ${tabValue === "month" && styles.activeTab}`}
              onClick={() => handleChange("month")}
            >
              MONTH
            </div>
            <div
              className={`${styles.tab} ${tabValue === "threeMonth" && styles.activeTab}`}
              onClick={() => handleChange("threeMonth")}
            >
              3 MONTHS
            </div>
          </div>
          <div className={styles.tabContent}>
            {tabValue === "day" && (
              <TMPContainer
                title="Daily Report"
                bgRes={dayData?.bgRes}
                xData={dayData?.xData}
                yData={dayData?.yData}
                type="day"
              />
            )}
            {tabValue === "week" && (
              <TMPContainer
                title="Weekly Report"
                bgRes={weekData?.bgRes}
                xData={weekData?.xData}
                yData={weekData?.yData}
                type="week"
              />
            )}
            {tabValue === "month" && (
              <TMPContainer
                title="Monthly Report"
                bgRes={monthData?.bgRes}
                xData={monthData?.xData}
                yData={monthData?.yData}
                type="month"
              />
            )}
            {tabValue === "threeMonth" && (
              <TMPContainer
                title="3 Months Report"
                bgRes={threeMonthData?.bgRes}
                xData={threeMonthData?.xData}
                yData={threeMonthData?.yData}
                type="threeMonth"
              />
            )}
          </div>
        </div>
        {showPopup && (
          <div className={styles.popup}>
            <div className={styles.popupOption1}>
              <PopUpManualEntry
                optionName={"Manual Entry"}
                FormTitle={"Temperature"}
                FormType={<FormTemp refreshChartData={refreshChartData} style={{ zIndex: "9999" }} />}
              />
            </div>
            <div className={styles.popupOption} onClick={deleteLastEntry}>
              Delete Last Entry
            </div>
            <div className={styles.popupOption} onClick={(event) => closePopup(event)}>
              Cancel
            </div>
          </div>
        )}
       <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        message={
          response === "success" ? "Successfully deleted the last entry!" : "No recent data found!"
        }
      />
      </div>
    );
  };
  
export default TMPPopup
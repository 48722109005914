import React from 'react'
import styles from "./MainCards.module.css"

function MainCards({title,status,time}) {
  return (
   <div className={styles.container}>
    <div className={styles.headContainer}>{title}</div>
    <div className={styles.bodyContainer}></div>
    <div className={styles.footerContainer}>
        <div className={styles.status}>{status}</div>
        <div className={styles.time}>{time} ago</div>
    </div>
   </div>
  )
}

export default MainCards

import React from "react";
import { useState, useEffect } from "react";
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer } from "recharts";
import "./LineGraph.css";

const SingleGraph = (props) => {
  const [oneGraphCSSClass, setOneGraphCSSClass] = useState("");

  useEffect(() => {
    if (props.deviceType === "capnometer") {
      setOneGraphCSSClass("gridDashboard_SingleChart");
    } else {
      setOneGraphCSSClass("");
    }
  }, [props.deviceType]);

  return (
    <div className={`gridDashboard_ChartContainer ${oneGraphCSSClass}`}>
      <div className="gridDashboard_ChartPanel">
        <div className="ChartPanel_LeftLabelDiv">
          <span className="ChartPanel_LeftLabel">{props.graphLabels[0]}</span>
        </div>
        <div className="ChartPanel_ChartDiv">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              data={[...props.data.graphData]}
              syncId="anyId"
              margin={{
                top: 5,
                right: 5,
                left: -30,
                bottom: 5,
              }}
            >
              <XAxis
                dataKey="name"
                scale="time"
                type="number"
                ticks={props.data.seriesData}
                tick={({ fill: "white" }, { fontSize: "0.75rem" })}
                hide={true}
              />
              <YAxis tick={{ fontSize: "0.6rem" }} />

              <Line
                type="monotone"
                fontSize="xx-small"
                isAnimationActive={false}
                dot={false}
                dataKey="uv"
                strokeWidth="2"
                stroke="#FFCF1D"
                fill="#FFCF1D"
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>

      {props.graphLabels.length > 1 && (
        <div className="gridDashboard_ChartPanel">
          <div className="ChartPanel_LeftLabelDiv">
            <span className="ChartPanel_LeftLabel">{props.graphLabels[1]}</span>
          </div>
          <div className="ChartPanel_ChartDiv">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart
                data={[...props.data.graphDataTwo]}
                syncId="anyId"
                margin={{
                  top: 5,
                  right: 5,
                  left: -30,
                  bottom: 5,
                }}
              >
                <XAxis
                  dataKey="name"
                  scale="time"
                  type="number"
                  ticks={props.data.seriesData}
                  tick={({ fill: "white" }, { fontSize: "0.75rem" })}
                  hide={true}
                />
                <YAxis tick={{ fontSize: "0.6rem" }} />

                <Line
                  type="monotone"
                  fontSize="xx-small"
                  isAnimationActive={false}
                  dot={false}
                  dataKey="uv"
                  strokeWidth="2"
                  stroke="#48D342"
                  fill="#48D342"
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      )}

      {props.graphLabels.length > 2 && (
        <div className="gridDashboard_ChartPanel">
          <div className="ChartPanel_LeftLabelDiv">
            <span className="ChartPanel_LeftLabel">{props.graphLabels[2]}</span>
          </div>
          <div className="ChartPanel_ChartDiv">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart
                data={[...props.data.graphDataThree]}
                syncId="anyId"
                margin={{
                  top: 5,
                  right: 5,
                  left: -30,
                  bottom: 5,
                }}
              >
                <XAxis
                  dataKey="name"
                  scale="time"
                  type="number"
                  ticks={props.data.seriesData}
                  tick={({ fill: "white" }, { fontSize: "0.75rem" })}
                  hide={true}
                />
                <YAxis tick={{ fontSize: "0.6rem" }} />

                <Line
                  type="monotone"
                  fontSize="xx-small"
                  isAnimationActive={false}
                  dot={false}
                  dataKey="uv"
                  strokeWidth="2"
                  stroke="#40BEBB"
                  fill="#40BEBB"
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      )}
    </div>
  );
};
export default React.memo(SingleGraph);

import React from "react";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";

export default function CardTopAlarm({ alarmCount }) {
  return (
    <div className="alertSpan">
      <div className="notifyicon" style={{ color: "red" }}>
        <NotificationsActiveIcon fontSize="inherit" />
      </div>
    </div>
  );
}

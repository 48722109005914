import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import {
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  ListItem,
  ListItemText,
  Popover,
  List,
  MenuItem,
  Menu,
  Divider,
  Snackbar,
  Alert,
  Badge,
  Typography,
  Tabs,
  Tab,
} from '@mui/material';
import Pagination from '@mui/material/Pagination';
import SnoozeIcon from '@mui/icons-material/Snooze';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import HistoryIcon from '@mui/icons-material/History';
import { Backdrop, CircularProgress } from '@mui/material';
import { ArrowCircleDown, ArrowCircleUp, ArrowCircleUpRounded, ArrowDropDownCircle, ArrowUpwardOutlined, ArrowUpwardRounded, ArrowUpwardSharp, ArrowUpwardTwoTone, Done } from '@mui/icons-material';
import styles from './AlarmTest.module.css'
import { makeStyles } from '@material-ui/core';
import constants from "../../../../Common/Constants";


// const useStyles = makeStyles({
//   customBadge: {
//     backgroundColor: 'blue', // Change to your desired background color
//   },
// });

const useStyles = makeStyles({
  blinkingRow: {
    animation: '$blink 1s infinite',
  },
  latestRow: {
    backgroundColor: '#ffecec', // Change to your desired color
  },
  '@keyframes blink': {
    '0%, 100%': {
      backgroundColor: 'transparent',
    },
    '50%': {
      backgroundColor: '#ffcdcd', // Change to your desired color
    },
  },
  noDataMessage: {
    textAlign: 'center',
    padding: '1rem',
  },
});

const AlarmTest = () => {

  const classes = useStyles();

  const groupName = useSelector((state) => state.auth.groupName);
  const token = useSelector((state) => state.auth.authToken);
  const patientList = useSelector((state) => state.auth.patientList);

  const [alarmData, setAlarmData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  console.log("currentPage", currentPage);
  console.log("totalPages", totalPages);
  const [selectedTimeRange, setSelectedTimeRange] = useState(null);
  const [timeRangeAnchorEl, setTimeRangeAnchorEl] = useState(null);

  // Filtering States
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [alarmTypeFilterAnchorEl, setAlarmTypeFilterAnchorEl] = useState(null);
  const [priorityTypeFilterAnchorEl, setPriorityTypeFilterAnchorEl] = useState(null);

  const [timeOrder, setTimeOrder] = useState('desc');

  const [isFetchingData, setIsFetchingData] = useState(false);

  const [selectedPatientName, setSelectedPatientName] = useState(null);
  const [selectedPatientId, setSelectedPatientId] = useState(null);
  const [selectedAlarmName, setSelectedAlarmName] = useState(null);
  const [selectedAlarmType, setSelectedAlarmType] = useState(null);
  const [selectedPriorityName, setSelectedPriorityName] = useState(null);
  const [selectedPriorityType, setSelectedPriorityType] = useState(null);

  const [snoozeAnchorEl, setSnoozeAnchorEl] = useState(null);
  const [selectedSnoozeDuration, setSelectedSnoozeDuration] = useState(null);

  const [selectedSnoozeVital, setSelectedSnoozeVital] = useState(null);
  const [selectedSnoozeUserId, setSelectedSnoozeUserId] = useState(null);
  const [selectedSnoozeAlarm, setSelectedSnoozeAlarm] = useState(null);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarColor, setSnackbarColor] = useState('');

  const [activeTab, setActiveTab] = useState('all'); // 'all' or 'snoozed'
  const [snoozedAlarms, setSnoozedAlarms] = useState([]);

  const [escalatedAlarms, setEscalatedAlarms] = useState([]);


  const [resetItems, setResetItems] = useState([]);

  const [dynamicRequestBody, setDynamicRequestBody] = useState({
    page: currentPage,
    order: timeOrder,
    time_range: selectedTimeRange,
  });


  const vitalList = [
    { value: null, label: 'All Vitals' },
    { value: 'BG', label: 'Blood Glucose' },
    { value: 'HR', label: 'Heart Rate' },
    { value: 'BPD', label: 'BP-Diastolic ' },
    { value: 'BPS', label: 'BP-Systolic' },
    { value: 'SPO', label: 'SpO2' },
    { value: 'SLP', label: 'Sleep' },
    { value: 'BR', label: 'Breath Rate' },
    { value: 'TMP', label: 'Temperature' },
    { value: 'CAL', label: 'Calorie' },
    { value: 'STP', label: 'Step' },
  ];

  const priorityList = [
    { value: null, label: "All Priority" },
    { value: "High", label: "High" },
    { value: "Medium", label: "Medium" },
    { value: "Low", label: "Low" },
  ];

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
    console.log("newPage", newPage)
  };

  const handleTimeRangeClick = (event) => {
    setTimeRangeAnchorEl(event.currentTarget);
  };

  const handleTimeRangeClose = () => {
    setTimeRangeAnchorEl(null);
  };

  const handleAlarmTypeFilterClick = (event) => {
    setAlarmTypeFilterAnchorEl(event.currentTarget);
  };

  const handleAlarmTypeFilterClose = () => {
    setAlarmTypeFilterAnchorEl(null);
  };

  const handlePriorityTypeFilterClick = (event) => {
    setPriorityTypeFilterAnchorEl(event.currentTarget);
  };

  const handlePriorityTypeFilterClose = () => {
    setPriorityTypeFilterAnchorEl(null);
  };

  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  const handleSnoozeMenuOpen = (event, key) => {
    event.preventDefault();
    setSnoozeAnchorEl(event.currentTarget);
  };

  const handleSnoozeMenuClose = (event) => {
    setSnoozeAnchorEl(null);
  };

  const fetchAlarmData = () => {

    const requestBody = {
      page: currentPage,
      order: timeOrder,
      // time_range: selectedTimeRange,
    };

    if (selectedTimeRange !== null) {
      requestBody.time_range = selectedTimeRange;
    }

    // Add selectedPatientId to the request body if it's not null
    if (selectedPatientId !== null) {
      requestBody.id = selectedPatientId;
    }

    // Add selectedAlarmType to the request body if it's not null
    if (selectedAlarmType !== null) {
      requestBody.vital = selectedAlarmType;
    }

    // Add selectedPriorityType to the request body if it's not null
    if (selectedPriorityType !== null) {
      requestBody.priority = selectedPriorityType;
    }

    setIsFetchingData(true);
    axios.post(`${constants.API_URL}/get_alarm_history/${groupName}/${token}`, requestBody)
      .then(response => {
        setAlarmData(response.data.data);
        setTotalPages(response.data.total_pages);
      })
      .catch(error => {
        console.error('Error fetching alarm data:', error);
      })
      .finally(() => {
        setIsFetchingData(false);
      })
  };

  //INITIAL API CALL
  useEffect(() => {
    fetchAlarmData();
  }, [selectedAlarmType, selectedPatientId, selectedPriorityType, selectedTimeRange ,timeOrder, currentPage]);

  // Filter snoozed alarms and update state
  useEffect(() => {
    const snoozed = alarmData?.filter((alarm) => alarm?.snooze_at !== null);
    setSnoozedAlarms(snoozed);

    const escalated = alarmData?.filter((alarm) => alarm?.escalate === true);
    setEscalatedAlarms(escalated);

  }, [alarmData]);

  //handleSnooze Action
  const handleSnoozeClick = (event, item) => {
    setSelectedSnoozeAlarm(item);
    setSelectedSnoozeVital(item.vital);
    setSelectedSnoozeUserId(item.user_id);
    event.preventDefault();
    setSnoozeAnchorEl(event.currentTarget);
  };

  const handleSnoozeDurationSelect = (duration) => {
    setSnoozeAnchorEl(null); // Close the popover

    if (selectedSnoozeAlarm) {
      setIsFetchingData(true);

      let snoozeTimeInMilliseconds = 0;

      switch (duration) {
        case '30mins':
          snoozeTimeInMilliseconds = 30 * 60 * 1000;
          break;
        case '1hour':
          snoozeTimeInMilliseconds = 60 * 60 * 1000;
          break;
        case '2hrs':
          snoozeTimeInMilliseconds = 2 * 60 * 60 * 1000;
          break;
        // case '12hrs':
        //   snoozeTimeInMilliseconds = 12 * 60 * 60 * 1000;
        //   break;
        // case '24hrs':
        //   snoozeTimeInMilliseconds = 24 * 60 * 60 * 1000;
        //   break;
        default:
          break;
      }

      const currentTimestamp = new Date().getTime();
      const futureTimestamp = currentTimestamp + snoozeTimeInMilliseconds;

      // API for Snooze 
      const apiUrl = `${constants.API_URL}/alarm_snooze_reset/${selectedSnoozeUserId}/${selectedSnoozeVital}/${token}`;
      const requestBodysnooze = {
        snooze_at: futureTimestamp,
      };

      axios.post(apiUrl, requestBodysnooze)
        .then((response) => {
          // Handle success
          console.log('Snooze request sent successfully', response);
          if (response?.status === 200) {

            setSnackbarOpen(true);
            setSnackbarMessage("Alarm Snoozed for " + duration);
            setSnackbarColor("success")

            const requestBody = {
              page: currentPage,
              order: timeOrder,
              time_range: selectedTimeRange
            }

            setTimeout(() => {
              fetchAlarmData();
            }, 1500);
          }
        })
        .catch((error) => {
          console.error('Error sending snooze request:', error);
          setSnackbarOpen(true);
          setSnackbarMessage("Error");
          setSnackbarColor("error")
        })
        .finally(() => {
          setIsFetchingData(false);
        })
    }
  };

  // handle Escalate Action
  const handleEscalateClick = async (item) => {

    if (item) {
      setIsFetchingData(true);
      const apiUrl = `${constants.API_URL}/alarm_snooze_reset/${item?.user_id}/${item?.vital}/${token}`;
      const requestBodyEs = {
        escalate: true,
      };

      axios.post(apiUrl, requestBodyEs)
        .then((response) => {
          // Handle success
          console.log('Escalate request sent successfully', response);
          if (response?.status === 200) {

            setSnackbarOpen(true);
            setSnackbarMessage("Alarm Escalated");
            setSnackbarColor("success")

            const requestBody = {
              page: currentPage,
              order: timeOrder,
              time_range: selectedTimeRange
            }

            setTimeout(() => {
              fetchAlarmData();
            }, 1500);
          }
        })
        .catch((error) => {
          console.error('Error sending escalate request:', error);
          console.error('Error sending reset request:', error);
          setSnackbarOpen(true);
          setSnackbarMessage("Error");
          setSnackbarColor("error")
        })
        .finally(() => {
          setIsFetchingData(false);
        })
    }
  };

  // Handle reset action
  const handleResetClick = async (event, item) => {

    if (item) {
      setIsFetchingData(true);

      const apiUrl = `${constants.API_URL}/alarm_snooze_reset/${item?.user_id}/${item?.vital}/${token}`;
      const requestBodyReset = {
        reset: true,
      };

      axios.post(apiUrl, requestBodyReset)
        .then((response) => {
          // Handle success
          console.log('Reset request sent successfully', response);
          if (response?.status === 200) {

            setResetItems([...resetItems, item.id]); 

            setSnackbarOpen(true);
            setSnackbarMessage("Alarm Reset Successfull");
            setSnackbarColor("success")

            const requestBody = {
              page: currentPage,
              order: timeOrder,
              time_range: selectedTimeRange
            }

            setTimeout(() => {
              fetchAlarmData();
            }, 1500);
          }
        })
        .catch((error) => {
          console.error('Error sending reset request:', error);
          setSnackbarOpen(true);
          setSnackbarMessage("Error");
          setSnackbarColor("error")
        })
        .finally(() => {
          setIsFetchingData(false);
        })
    }
  };

  const handleTimeRangeSelect = (timeRange) => {
    // setSelectedTimeRange((prevTimeRange) => {
    //   console.log(`Previous Time Range: ${prevTimeRange}`);
    //   console.log(`New Time Range: ${timeRange}`);
  
    //   // You can use timeRange here or perform other actions based on it
  
    //   return timeRange; // Update the state with the new value
    // });
    setSelectedTimeRange(timeRange);
    console.log(selectedTimeRange);
    console.log(timeRange);
    setCurrentPage(1);
    handleTimeRangeClose();

    // Update the API call here
    // You'll need to modify your existing API call code to include the selectedTimeRange parameter
    // ...
    fetchAlarmData();
  };

  // Filtering based on PATIENT NAME
  const handlePatientClick = (patientId, patientGroupName) => {
    setCurrentPage(1);
    setSelectedPatientName(patientGroupName);
    setSelectedPatientId(patientId);
    setFilterAnchorEl(null);
  };

  // Filtering based on ALARM TYPE or VITAL NAME
  const handleAlarmTypeClick = (alarmType, alarmName) => {
    setSelectedAlarmType(alarmType);
    setSelectedAlarmName(alarmName)
    setCurrentPage(1);
    setAlarmTypeFilterAnchorEl(null);
  };

  const handlePriorityTypeClick = (priorityType, priorityName) => {

    setCurrentPage(1);
    setSelectedPriorityType(priorityType);
    setSelectedPriorityName(priorityName);
    setPriorityTypeFilterAnchorEl(null);

    //   if(priorityType == null){
    //       // Fetch alarms for all patients
    //       axios.post(`${constants.API_URL}/get_alarm_history/${groupName}/${token}`, {
    //         page: 1,
    //         order: timeOrder,
    //         time_range: selectedTimeRange,
    //       })
    //       .then(response => {
    //         setAlarmData(response.data.data);
    //         setTotalPages(response.data.total_pages);
    //       })
    //       .catch(error => {
    //         console.error('Error fetching alarm data:', error);
    //       });
    //   }
    //   else{axios.post(`${constants.API_URL}/get_alarm_history/${groupName}/${token}`, {
    //     page: 1,
    //     order: timeOrder,
    //     priority: priorityType,
    //     time_range: selectedTimeRange,
    //   })
    //   .then(response => {
    //     setAlarmData(response.data.data);
    //     setTotalPages(response.data.total_pages);
    //   })
    //   .catch(error => {
    //     console.error('Error fetching alarm data:', error);
    //   });
    // }

  };

  //Filtering based on TIME - ASCENDING/DESCENDING
  const handleTimeFilterClick = () => {
    const newTimeOrder = timeOrder === 'asc' ? 'desc' : 'asc';
    setTimeOrder(newTimeOrder);
    setCurrentPage(1);

    const requestBodytime = {
      page: 1,
      order: newTimeOrder,
    }
    if ( selectedTimeRange !== null){
      requestBodytime.time_range = selectedTimeRange
    }
    axios.post(`${constants.API_URL}/get_alarm_history/${groupName}/${token}`, requestBodytime)
      .then(response => {
        setAlarmData(response?.data?.data);
        setTotalPages(response?.data?.total_pages);
      })
      .catch(error => {
        console.error('Error fetching alarm data:', error);
      });
  };

  const vertical = "bottom";
  const horizontal = "right";

  const handleClearAllFilters = () => {
    setSelectedPatientId(null);
    setSelectedAlarmType(null);
    setSelectedPriorityType(null);
    setSelectedPatientName(null);
    setSelectedAlarmName(null);
    setSelectedPriorityName(null);
    setTimeOrder('desc');
  }

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  console.log("snoozedAlarms", snoozedAlarms)
  console.log("AllAlarms", alarmData)


  return (
    <div className={styles.mainTableContainer}>
      <div>
        <div className={styles.tableContainer}>
          <div className={styles.tabswitchingContainer}>
            <div>
              <Tabs value={activeTab} onChange={handleTabChange}>
                <Tab value="all" className={styles.tabLabel} label="All Alarms"/>
                <Tab value="snoozed" className={styles.tabLabel} label="Snoozed Alarms"/>
                <Tab value="escalated" className={styles.tabLabel} label="Escalated Alarms" />
              </Tabs>
            </div>
            <div className={styles.timeRangeButtonContainer}>
              <Button className={styles.clearFilterButton}
                variant="filled"
                onClick={handleClearAllFilters}>Clear Filters</Button>

              <Button className={styles.timeRangeButton}
                variant="standard"
                onClick={handleTimeRangeClick}
              >
                <HistoryIcon /> {selectedTimeRange == null ? ("All") : ( selectedTimeRange + "hrs")  } 
              </Button>
              <Menu
                anchorEl={timeRangeAnchorEl}
                open={Boolean(timeRangeAnchorEl)}
                onClose={handleTimeRangeClose}
              > <MenuItem onClick={() => handleTimeRangeSelect(null)}>All</MenuItem>
                <MenuItem onClick={() => handleTimeRangeSelect(24)}>24 hrs</MenuItem>
                <MenuItem onClick={() => handleTimeRangeSelect(48)}>48 hrs</MenuItem>
              </Menu>
            </div>
          </div>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <div className={styles.tableCellContainer}>
                    <div className={styles.tableHeaderLabel}>PATIENT NAME</div> {''}
                    <IconButton onClick={handleFilterClick}>
                      {selectedPatientId !== null && (
                        // <Badge badgeContent={"Active"} color="success" variant="dot">
                          <div>
                            <ArrowDropDownCircle />
                            <div className={styles.selectedNameStyles}>{selectedPatientName.length > 8 ? selectedPatientName.substr(0, 9) + "..." : selectedPatientName}</div>
                          </div>
                      )}
                      {selectedPatientId == null && (
                        <ArrowDropDownCircle />
                      )}
                    </IconButton>
                    </div>
                  </TableCell>
                  <TableCell>
                  <div className={styles.tableCellContainer}>
                  <div className={styles.tableHeaderLabel}>ALARM TYPE </div>
                    <IconButton onClick={handleAlarmTypeFilterClick}>
                      {selectedAlarmType !== null && (
                        // <Badge badgeContent={"Active"} color="success" variant="dot">
                          <div>
                            <ArrowDropDownCircle />
                            <div className={styles.selectedNameStyles}>{selectedAlarmName.length > 8 ? selectedAlarmName.substr(0, 9) + "..." : selectedAlarmName}</div>
                          </div>
                        // </Badge>
                      )}
                      {selectedAlarmType == null && (
                        <ArrowDropDownCircle />
                      )}
                    </IconButton>
                    </div>
                  </TableCell>
                  <TableCell> 
                    <div className={styles.tableCellContainer}>
                      <div className={styles.tableHeaderLabel}>VALUE</div>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className={styles.tableCellContainer}>
                    <div className={styles.tableHeaderLabel}> PRIORITY </div>
                    <IconButton onClick={handlePriorityTypeFilterClick}>
                      {selectedPriorityType !== null && (
                        // <Badge badgeContent={selectedPatientId !== null ? selectedPatientId.toString() : ""} style={{ color: "green !important", backgroundColor: "blue !important" }} variant="dot">
                          <div>
                            <ArrowDropDownCircle />
                            <div className={styles.selectedNameStyles}>{selectedPriorityName.length > 8 ? selectedPriorityName.substr(0, 9) + "..." : selectedPriorityName}</div>
                            </div>
                        // </Badge>
                      )}
                      {selectedPriorityType == null && (
                        <ArrowDropDownCircle />
                      )}
                    </IconButton>
                    </div>
                  </TableCell>
                  <TableCell>
                  <div className={styles.tableCellContainer}>
                  <div className={styles.tableHeaderLabel}>TIME</div>
                    <IconButton onClick={handleTimeFilterClick}>
                      {timeOrder === 'asc' ? <ArrowCircleUp /> : <ArrowCircleDown />}
                    </IconButton>
                    </div>
                  </TableCell>
                  <TableCell> 
                    <div className={styles.tableCellContainer}>
                     <div className={styles.tableHeaderLabel}>COUNT </div>
                    </div>
                  </TableCell>
                  <TableCell>
                   <div className={styles.tableCellContainer}>
                    <div className={styles.tableHeaderLabel}>ACTIONS</div>
                   </div>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {activeTab === 'all'? ( 
                alarmData?.map((item, index) => (
                    <TableRow
                      className={`${item?.latest && item?.reset == false ? classes.blinkingRow : ''
                        // alarm.priority === 'High' ? classes.blinkingRow : ''
                        }`} key={index}
                    >
                      <TableCell className={styles.patientNameStyles}>{item?.name}</TableCell>
                      <TableCell className={styles.detailsTextStyles}>{item?.status}</TableCell>
                      <TableCell className={styles.detailsTextStyles}>{item?.value}</TableCell>
                      <TableCell className={styles.detailsTextStyles}>{item?.priority}</TableCell>
                      <TableCell className={styles.detailsTextStyles}>
                        {new Date(item?.triggered_at).toLocaleTimeString('en-US', {
                          hour12: false,
                          hour: '2-digit',
                          minute: '2-digit',
                          second: '2-digit',
                        })}
                      </TableCell>
                      <TableCell className={styles.detailsTextStyles}>{item?.count}</TableCell>
                      <TableCell>
                        {item?.latest && (
                          <>
                          {item?.snooze === true ? (
                             <Button variant="standard" style={{ height: "fit-content", color:"grey" }} disabled>
                             <SnoozeIcon style={{ color: "grey" }} />
                             <div style={{ fontSize: "0.55rem" }}>Snoozed</div>
                             <Done style={{ height:"15px", width:"15px", color: "green" }}/>
                           </Button>

                          ):(
                            <Button variant="standard" style={{ height: "fit-content" }} onClick={(event) => handleSnoozeClick(event, item)}>
                            <SnoozeIcon style={{ color: "rgb(74, 72, 151)" }} />
                            <div style={{ fontSize: "0.55rem" }}>Snooze</div>
                          </Button>

                          )}
                           
                            {/* <Button variant="standard" style={{ height: "fit-content" }} onClick={(event) => handleResetClick(event, item)}>
                              <RotateLeftIcon style={{ color: "rgb(74, 72, 151)" }} />
                              <div style={{ fontSize: "0.5rem" }}>Reset</div>
                            </Button> */}
                            {item?.reset === true ? (
                              <Button variant="standard" style={{ height: "fit-content", color: 'grey' }} disabled>
                                <RotateLeftIcon style={{ color: "grey" }} />
                                <div style={{ fontSize: "0.55rem" }}>Reset</div>
                                <Done style={{ height:"15px", width:"15px", color: "green" }}/>
                              </Button>
                            ) : (
                              <Button variant="standard" style={{ height: "fit-content" }} onClick={(event) => handleResetClick(event, item)}>
                                <RotateLeftIcon style={{ color: "rgb(74, 72, 151)" }} />
                                <div style={{ fontSize: "0.55rem" }}>Reset</div>
                              </Button>
                            )}
                            <Button variant="standard" style={{ height: "fit-content" }} onClick={() => handleEscalateClick(item)}>
                              <ArrowCircleUpRounded style={{ color: "rgb(209, 0, 0)" }} />
                              <div style={{ fontSize: "0.55rem" }}>Escalate</div>
                            </Button>
                          </>
                        )}
                      </TableCell>
                    </TableRow>

                  ))
                )
                  : activeTab === 'snoozed' ? ( snoozedAlarms?.map((item, index) => (
                    <TableRow className={`${item?.latest && item?.reset == false ? classes.blinkingRow : ''}`}  key={index}>
                      <TableCell>{item?.name}</TableCell>
                      <TableCell>{item?.status}</TableCell>
                      <TableCell>{item?.value}</TableCell>
                      <TableCell>{item?.priority}</TableCell>
                      <TableCell>
                        {new Date(item?.triggered_at).toLocaleTimeString('en-US', {
                          hour12: false,
                          hour: '2-digit',
                          minute: '2-digit',
                          second: '2-digit',
                        })}
                      </TableCell>
                      <TableCell>{item?.count}</TableCell>
                      <TableCell>
                        <Typography style={{fontSize:"0.6rem", fontWeight:"500"}}><SnoozeIcon style={{ color: "rgb(74, 72, 151)" }} />SNOOZED TILL {new Date(item?.snooze_at).toLocaleTimeString('en-US', {
                          hour12: false,
                          hour: '2-digit',
                          minute: '2-digit',
                          second: '2-digit',
                        })}</Typography>
                        {item?.latest && (
                          <>
                            {/* <Button variant="standard" style={{ height: "fit-content" }} onClick={(event) => handleSnoozeClick(event, item)}>
                              <SnoozeIcon style={{ color: "rgb(74, 72, 151)" }} />
                              <div style={{ fontSize: "0.5rem" }}>Snooze</div>
                            </Button>
                            <Button variant="standard" style={{ height: "fit-content" }} onClick={(event) => handleResetClick(event, item)}>
                              <RotateLeftIcon style={{ color: "rgb(74, 72, 151)" }} />
                              <div style={{ fontSize: "0.5rem" }}>Reset</div>
                            </Button> */}
                            <Button variant="standard" style={{ height: "fit-content" }} onClick={() => handleEscalateClick(item)}>
                              <ArrowCircleUpRounded style={{ color: "rgb(209, 0, 0)" }} />
                              <div style={{ fontSize: "0.5rem" }}>Escalate</div>
                            </Button>
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  )) 
                  ) : ( 
                    escalatedAlarms?.map((item, index) => (
                      <TableRow className={`${item?.latest && item?.reset == false ? classes.blinkingRow : ''}`}  key={index}>
                        <TableCell>{item?.name}</TableCell>
                        <TableCell>{item?.status}</TableCell>
                        <TableCell>{item?.value}</TableCell>
                        <TableCell>{item?.priority}</TableCell>
                        <TableCell>
                          {new Date(item?.triggered_at).toLocaleTimeString('en-US', {
                            hour12: false,
                            hour: '2-digit',
                            minute: '2-digit',
                            second: '2-digit',
                          })}
                        </TableCell>
                        <TableCell>{item?.count}</TableCell>
                        <TableCell>
                          {item?.latest && (
                            <>
                              <Button variant="standard" style={{ height: "fit-content" }} onClick={() => handleEscalateClick(item)}>
                                <ArrowCircleUpRounded style={{ color: "rgb(209, 0, 0)" }} />
                                <div style={{ fontSize: "0.5rem" }}>Escalate</div>
                              </Button>
                            </>
                          )}
                        </TableCell>
                      </TableRow>
                    ) )
                  )
                  }
                {activeTab === 'snoozed' && snoozedAlarms?.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={5}>
                      No snoozed alarms available.
                    </TableCell>
                  </TableRow>
                )}
                 {activeTab === 'escalated' && escalatedAlarms?.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={5}>
                      No escalated alarms available.
                    </TableCell>
                  </TableRow>
                )}
                {activeTab === 'all' && alarmData?.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={5}>
                      No alarms available.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>

        {/* Snooze PopUp */}
        <Popover
          anchorEl={snoozeAnchorEl}
          open={Boolean(snoozeAnchorEl)}
          onClose={handleSnoozeMenuClose}
        > <ListItemText className={styles.listItemTextCss} style={{ fontSize: "0.8rem" }}> Snooze Duration </ListItemText>
          <Divider />
          <MenuItem onClick={() => handleSnoozeDurationSelect('30mins')}>30 mins</MenuItem>
          <MenuItem onClick={() => handleSnoozeDurationSelect('1hour')}>1 hour</MenuItem>
          <MenuItem onClick={() => handleSnoozeDurationSelect('2hrs')}>2 hrs</MenuItem>
        </Popover>

        {/* PAGINATION */}
        <div style={{ marginTop: '20px', marginBottom: "20px", display: 'flex', justifyContent: 'center' }}>
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
          />
        </div>


        {/* Patient Name Filter */}
        <Popover
          open={Boolean(filterAnchorEl)}
          anchorEl={filterAnchorEl}
          onClose={handleFilterClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <List>
            <ListItem button onClick={() => handlePatientClick(null)}> {/* Pass null for All Patients */}
              <ListItemText primary="All Patients" />
            </ListItem>
            {patientList?.map((patient) => (
              <ListItem key={patient?.group} button onClick={() => handlePatientClick(patient?.group, patient?.group_name)}>
                <ListItemText primary={patient?.group_name} />
              </ListItem>
            ))}
          </List>

        </Popover>

        {/* Alarm Type filter */}
        <Popover
          open={Boolean(alarmTypeFilterAnchorEl)}
          anchorEl={alarmTypeFilterAnchorEl}
          onClose={handleAlarmTypeFilterClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <List>
            {vitalList?.map((vital) => (
              <ListItem key={vital?.value} button onClick={() => handleAlarmTypeClick(vital?.value, vital?.label)}>
                <ListItemText primary={vital?.label} />
              </ListItem>
            ))}
          </List>
        </Popover>

        <Popover
          open={Boolean(priorityTypeFilterAnchorEl)}
          anchorEl={priorityTypeFilterAnchorEl}
          onClose={handlePriorityTypeFilterClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <List>
            {priorityList?.map((priority) => (
              <ListItem key={priority?.value} button onClick={() => handlePriorityTypeClick(priority?.value, priority?.label)}>
                <ListItemText primary={priority?.label} />
              </ListItem>
            ))}
          </List>
        </Popover>
      </div>
      <Backdrop open={isFetchingData} style={{ zIndex: 1, color: '#fff' }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        key={vertical + horizontal}
      >
        <Alert severity={snackbarColor} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default AlarmTest;

import React from 'react';
import styles from './Reminder.module.css'

const MedicationReminderModal = ({ isOpen, onClose,medicineName }) => {
    if (!isOpen) return null;
  
  return (
    <div className={styles.modalOverlay}>
      <div className={styles.contentModal}>
      <p className={styles.closeButton} onClick={onClose}>X</p>
        <h3>Medication Reminder!</h3>
        <div className={styles.medicationName}>It's time to take your Medication!</div>
        <div className={styles.medicationName}>Remark : {medicineName}</div>
        <div className={styles.modalButton}>
        <button className={styles.modalOkButton} onClick={onClose}>OK</button>
        </div>
       
      </div>
    </div>
  );
};

export default MedicationReminderModal;

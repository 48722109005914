import { Avatar, Badge, Button } from '@material-ui/core';
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import './Commonv2.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faBell, faUser} from '@fortawesome/free-solid-svg-icons';
import { AddCircle, FiberManualRecord, NotificationsActiveOutlined, RemoveCircle } from '@material-ui/icons';
import { CircularProgress } from '@mui/material';
import RightTopIconHeader from './RightTopIconHeader';
import NotificationRequest from './ProfileSwitching/FamilyMemeber/NotificationRequest';
import UserAlarm from './OtherComponents/UserAlarm/UserAlarm';

function Headerv2({careTakerPatientView=false}) {

  const headerGroupName = useSelector((state) => state.auth.headerGroupName);
  
  const [notificationOpen, setNotificationOpen] = useState(false);
  const notificationRef = useRef(null);

  const handleNotificationClick = () => {
    setNotificationOpen(!notificationOpen )
  }

  const glucoseDaily = useSelector((state) => state.dashboard.glucoseDaily);
  const bpDailySys = useSelector((state) => state.dashboard.bpDailySys);
  const breathDaily = useSelector((state) => state.dashboard.breathDaily);
  const sleepDaily = useSelector((state) => state.dashboard.sleepDaily);
  const spo2DailyMin = useSelector((state) => state.dashboard.spo2DailyMin);
  const stepsDaily = useSelector((state) => state.dashboard.stepsDaily);
  const tempDaily = useSelector((state) => state.dashboard.tempDaily);
  const heartRateDaily = useSelector((state) => state.dashboard.heartRateDaily);
  console.log("glucoseDaily",glucoseDaily);

  const glucose = glucoseDaily?.data?.y_data?.status;
  const glucoseStatus = glucoseDaily?.data?.y_data?.status_point;
  const bp = bpDailySys?.data?.y_data?.status;
  const bpStatus = bpDailySys?.data?.y_data?.status_point;
  const br = breathDaily?.data?.y_data?.status;
  const brStatus = breathDaily?.data?.y_data?.status_point;
  const sleep = sleepDaily?.data?.y_data?.status;
  const sleepStatus = sleepDaily?.data?.y_data?.status_point;
  const spo = spo2DailyMin?.data?.y_data?.status;
  const spoStatus = spo2DailyMin?.data?.y_data?.status_point;
  const steps = stepsDaily?.data?.y_data?.status;
  const stepsStatus = stepsDaily?.data?.y_data?.status_point;
  const temp = tempDaily?.data?.y_data?.status;
  const tempStatus = tempDaily?.data?.y_data?.status_point;
  const heartRate = heartRateDaily?.data?.y_data?.status;
  const heartRateStatus = heartRateDaily?.data?.y_data?.status_point
  

  const Heart = useMemo(() => {
    if (heartRateStatus === 3) {
      return <div style={{ backgroundColor: "#eb9d9d" ,fontFamily:"'Montserrat', sans-serif",borderRadius:"7px",margin:"5px",padding:"2px 10px 2px 10px", boxShadow:"0 0px 7px 0 rgb(0 0 0 / 20%), 0px 0px 1px 0px rgb(0 0 0 / 10%)"}}>
              {heartRate}
             </div>;
    } 
     if (heartRateStatus === 2) {
      return <div style={{ backgroundColor: "#FFD43B",fontFamily:"'Montserrat', sans-serif",borderRadius:"7px",margin:"5px",padding:"2px 10px 2px 10px", boxShadow:"0 0px 7px 0 rgb(0 0 0 / 20%), 0px 0px 1px 0px rgb(0 0 0 / 10%)" }}>
              {heartRate}
             </div>;
    }
    if (heartRateStatus === 1) {
      return <div style={{ backgroundColor: "#c7a8ea",fontFamily:"'Montserrat', sans-serif",borderRadius:"7px",margin:"5px",padding:"2px 10px 2px 10px", boxShadow:"0 0px 7px 0 rgb(0 0 0 / 20%), 0px 0px 1px 0px rgb(0 0 0 / 10%)"}}> 
              {heartRate}
             </div>;
    }
    else {
      return null;
    }
  }, [heartRateStatus]);

  const Diabetes = useMemo(() => {
    if (glucoseStatus === 3) {
      return <div style={{ backgroundColor: "#eb9d9d",fontFamily:"'Montserrat', sans-serif" ,borderRadius:"7px",margin:"5px",padding:"2px 10px 2px 10px", boxShadow:"0 0px 7px 0 rgb(0 0 0 / 20%), 0px 0px 1px 0px rgb(0 0 0 / 10%)"}}>
              {glucose}
             </div>;
    } 
     if (glucoseStatus === 2) {
      return <div style={{ backgroundColor: "#FFD43B",fontFamily:"'Montserrat', sans-serif", borderRadius:"7px",margin:"5px",padding:"2px 10px 2px 10px", boxShadow:"0 0px 7px 0 rgb(0 0 0 / 20%), 0px 0px 1px 0px rgb(0 0 0 / 10%)" }}>
              {glucose}
             </div>;
    }
    if (glucoseStatus === 1) {
      return <div style={{ backgroundColor: "#c7a8ea",fontFamily:"'Montserrat', sans-serif",borderRadius:"7px",margin:"5px",padding:"2px 10px 2px 10px", boxShadow:"0 0px 7px 0 rgb(0 0 0 / 20%), 0px 0px 1px 0px rgb(0 0 0 / 10%)" }}>
              {glucose}
             </div>;
    }
     else {
      return null;
    }
  }, [glucoseStatus]);

  const Pressure = useMemo(() => {
    if (bpStatus === 3) {
      return <div style={{ backgroundColor: "#eb9d9d",fontFamily:"'Montserrat', sans-serif",borderRadius:"7px",margin:"5px",padding:"2px 10px 2px 10px", boxShadow:"0 0px 7px 0 rgb(0 0 0 / 20%), 0px 0px 1px 0px rgb(0 0 0 / 10%)" }}>
              {bp}
             </div>;
    } 
     if (bpStatus === 2) {
      return <div style={{ backgroundColor: "#FFD43B",fontFamily:"'Montserrat', sans-serif",borderRadius:"7px" ,margin:"5px",padding:"2px 10px 2px 10px",boxShadow:"0 0px 7px 0 rgb(0 0 0 / 20%), 0px 0px 1px 0px rgb(0 0 0 / 10%)"  }}>
              {bp}
             </div>;
    }
    if (bpStatus === 1) {
      return <div style={{ backgroundColor: "#c7a8ea",fontFamily:"'Montserrat', sans-serif",borderRadius:"7px",margin:"5px",padding:"2px 10px 2px 10px", boxShadow:"0 0px 7px 0 rgb(0 0 0 / 20%), 0px 0px 1px 0px rgb(0 0 0 / 10%)"  }}>
              {bp}
             </div>;
    }
     else {
      return null;
    }
  }, [bpStatus]);

  const Breath = useMemo(() => {
    if (brStatus === 3) {
      return <div style={{ backgroundColor: "#eb9d9d" , fontFamily:"'Montserrat', sans-serif",borderRadius:"7px",margin:"5px",padding:"2px 10px 2px 10px", boxShadow:"0 0px 7px 0 rgb(0 0 0 / 20%), 0px 0px 1px 0px rgb(0 0 0 / 10%)" }}>
              {br}
             </div>;
    } 
     if (brStatus === 2) {
      return <div style={{ backgroundColor: "#FFD43B", fontFamily:"'Montserrat', sans-serif",borderRadius:"7px",margin:"5px",padding:"2px 10px 2px 10px", boxShadow:"0 0px 7px 0 rgb(0 0 0 / 20%), 0px 0px 1px 0px rgb(0 0 0 / 10%)" }}>
              {br}
             </div>;
    }
    if (brStatus === 1) {
      return <div style={{ backgroundColor: "#c7a8ea",fontFamily:"'Montserrat', sans-serif",borderRadius:"7px",margin:"5px",padding:"2px 10px 2px 10px", boxShadow:"0 0px 7px 0 rgb(0 0 0 / 20%), 0px 0px 1px 0px rgb(0 0 0 / 10%)"  }}>
              {br}
             </div>;
    }
    else {
      return null;
    }
  }, [brStatus]);

  const Sleeps = useMemo(() => {
    if (sleepStatus === 3) {
      return <div style={{ backgroundColor: "#eb9d9d",fontFamily:"'Montserrat', sans-serif",borderRadius:"7px",margin:"5px",padding:"2px 10px 2px 10px", boxShadow:"0 0px 7px 0 rgb(0 0 0 / 20%), 0px 0px 1px 0px rgb(0 0 0 / 10%)"}}>
              {sleep}
             </div>;
    } 
     if (sleepStatus === 2) {
      return <div style={{ backgroundColor: "#FFD43B",fontFamily:"'Montserrat', sans-serif" ,borderRadius:"7px",margin:"5px",padding:"2px 10px 2px 10px", boxShadow:"0 0px 7px 0 rgb(0 0 0 / 20%), 0px 0px 1px 0px rgb(0 0 0 / 10%)"  }}>
              {sleep}
             </div>;
    }
    if (sleepStatus === 1) {
      return <div style={{ backgroundColor: "#c7a8ea",fontFamily:"'Montserrat', sans-serif",borderRadius:"7px",margin:"5px",padding:"2px 10px 2px 10px", boxShadow:"0 0px 7px 0 rgb(0 0 0 / 20%), 0px 0px 1px 0px rgb(0 0 0 / 10%)"  }}>
              {sleep}
             </div>;
    }
     else {
      return null;
    }
  }, [sleepStatus]);

  const Spo2 = useMemo(() => {
    if (spoStatus === 3) {
      return <div style={{ backgroundColor: "#eb9d9d",fontFamily:"'Montserrat', sans-serif",borderRadius:"7px",margin:"5px",padding:"2px 10px 2px 10px", boxShadow:"0 0px 7px 0 rgb(0 0 0 / 20%), 0px 0px 1px 0px rgb(0 0 0 / 10%)"   }}>
              {/* <FiberManualRecord style={{fontSize:"10px", color:"red"}}/> */}
              {spo}
             </div>;
    } 
     if (spoStatus === 2) {
      return <div style={{ backgroundColor: "#FFD43B",fontFamily:"'Montserrat', sans-serif",borderRadius:"7px",margin:"5px",padding:"2px 10px 2px 10px", boxShadow:"0 0px 7px 0 rgb(0 0 0 / 20%), 0px 0px 1px 0px rgb(0 0 0 / 10%)"   }}>
              {/* <FiberManualRecord style={{fontSize:"10px", color:"yellow"}}/> */}
              {spo}
             </div>;
    }
    if (spoStatus === 1) {
      return <div style={{ backgroundColor: "#c7a8ea",fontFamily:"'Montserrat', sans-serif",borderRadius:"7px",margin:"5px",padding:"2px 10px 2px 10px", boxShadow:"0 0px 7px 0 rgb(0 0 0 / 20%), 0px 0px 1px 0px rgb(0 0 0 / 10%)"   }}>
              {spo}
             </div>;
    }
     else {
      return null;
    }
  }, [spoStatus]);

  const Steps = useMemo(() => {
    if (stepsStatus === 3) {
      return <div style={{ backgroundColor: "#eb9d9d",fontFamily:"'Montserrat', sans-serif",borderRadius:"7px",margin:"5px",padding:"2px 10px 2px 10px", boxShadow:"0 0px 7px 0 rgb(0 0 0 / 20%), 0px 0px 1px 0px rgb(0 0 0 / 10%)"   }}>
              <FiberManualRecord style={{fontSize:"10px", color:"red"}}/>
              {steps}
             </div>;
    } 
     if (stepsStatus === 2) {
      return <div style={{ backgroundColor: "#FFD43B",fontFamily:"'Montserrat', sans-serif",borderRadius:"7px",margin:"5px",padding:"2px 10px 2px 10px", boxShadow:"0 0px 7px 0 rgb(0 0 0 / 20%), 0px 0px 1px 0px rgb(0 0 0 / 10%)"   }}>
              <FiberManualRecord style={{fontSize:"10px", color:"yellow"}}/>
              {steps}
             </div>;
    }
    if (stepsStatus === 1) {
      return <div style={{ backgroundColor: "#c7a8ea",fontFamily:"'Montserrat', sans-serif",borderRadius:"7px",margin:"5px",padding:"2px 10px 2px 10px", boxShadow:"0 0px 7px 0 rgb(0 0 0 / 20%), 0px 0px 1px 0px rgb(0 0 0 / 10%)"   }}>
              <FiberManualRecord style={{fontSize:"10px", color:"blue"}}/>
              {steps}
             </div>;
    }
    else {
      return null;
    }
  }, [stepsStatus]);

  const Temperature = useMemo(() => {
    if (tempStatus === 3) {
      return <div style={{ backgroundColor: "#eb9d9d",fontFamily:"'Montserrat', sans-serif",borderRadius:"7px",margin:"5px",padding:"2px 10px 2px 10px", boxShadow:"0 0px 7px 0 rgb(0 0 0 / 20%), 0px 0px 1px 0px rgb(0 0 0 / 10%)"   }}>
              {temp}
             </div>;
    } 
     if (tempStatus === 2) {
      return <div style={{ backgroundColor: "#FFD43B",fontFamily:"'Montserrat', sans-serif",borderRadius:"7px",margin:"5px",padding:"2px 10px 2px 10px", boxShadow:"0 0px 7px 0 rgb(0 0 0 / 20%), 0px 0px 1px 0px rgb(0 0 0 / 10%)"   }}>
              {temp}
             </div>;
    }
    if (tempStatus === 1) {
      return <div style={{ backgroundColor: "#c7a8ea",fontFamily:"'Montserrat', sans-serif",borderRadius:"7px",margin:"5px",padding:"2px 10px 2px 10px", boxShadow:"0 0px 7px 0 rgb(0 0 0 / 20%), 0px 0px 1px 0px rgb(0 0 0 / 10%)"   }}>
              {temp}
             </div>;
    }
     else {
      return null;
    }
  }, [tempStatus]);

  useEffect(() => {
    const handleDocumentClick = (event) => {
      // Check if the click occurred outside the notification component
      if (notificationRef.current && !notificationRef.current.contains(event.target)) {
        setNotificationOpen(false); // Close the notification
      }
    };

    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  return (
    <div className='main-HeaderV2'>

      <div style={{ marginLeft: "20px", marginRight: "10px" }}>
        <span style={{ color: "#6A4396", fontWeight: "Bold", fontSize: "1.7rem", fontFamily: "'Montserrat', sans-serif" }}>Care</span>
        <span style={{ color: "#23AF72", fontWeight: "Bold", fontSize: "1.7rem", }}>HQ </span>
      </div>
      <div style={{ marginLeft: "0px", display: "flex", alignItems: "center" }}>
        <div><NotificationRequest /></div>
        <div className='headerv2-notification' style={{ margin: "10px 15px 10px 10px", alignItems: "center", justifyContent: "center" }}>
        <div><UserAlarm /></div>
        </div>
        <div style={{ fontSize: "1.1rem", fontWeight: "500", color: "#010000", fontFamily: "Montserrat, sans-serif", marginRight: "20px", marginLeft: "10px" }}>
          {/* {headerGroupName} */}
          <RightTopIconHeader />

        </div>
      </div>

    </div>
  )
}

export default Headerv2

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CareTakeCard from '../../CareGiverPage/CareTakerCard';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

import LoaderCG from '../../CareGiverPage/LoaderCG';
import constants from '../../../../Common/Constants';
import { Button } from '@material-ui/core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faRotateRight} from '@fortawesome/free-solid-svg-icons';
import {Avatar, Divider, ListItemText, Menu, MenuItem, Popover } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import DevicesIcon from "@material-ui/icons/Devices";
import FitbitIcon from "@mui/icons-material/Fitbit";
import BloodtypeIcon from "@mui/icons-material/Bloodtype";
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import { BubbleChart } from '@material-ui/icons';
import { BluetoothAudio, Sync } from '@mui/icons-material';

let socket = null;
const MAX_RETRIES = 10;
const RETRY_DELAY = 2000;

const MyPatientComponent = () => {


    const [isLoading, setIsLoading] = useState(true);
    const groupName = useSelector((state) => state.auth.groupName);
    const token = useSelector((state) => state.auth.authToken);
    const isDoctor = useSelector((state) => state.auth.isDoctor);
    const careTaker = useSelector((state) => state.auth.careTaker);
    const [careData, setCareData] = useState([]);
    const [socket, setSocket] = useState(null);
    const history = useHistory();
    const [retryCount, setRetryCount] = useState(0);
    const userData = useSelector((state) => state.auth.userData);
    const currentUserIndex = useSelector((state) => state.auth.currentUserIndex);
    const [devicesAnchorEl, setDevicesAnchorEl] = useState(null);

    const patientList = useSelector((state) => state.auth.patientList);
    console.log("patientList",patientList);

    const [patientData, setPatientData] = useState([]);

    function getDeviceIcon(deviceType) {
      switch (deviceType) {
        case "linktop":
          return <MonitorHeartIcon fontSize="small" style={{ color: "#6c757d" }} />;
        case "AND":
          return <BloodtypeIcon fontSize="small" style={{ color: "#6c757d" }} />;
        case "pill_dispenser":
          return <VaccinesIcon fontSize="small" style={{ color: "#6c757d" }} />;
        case "accucheck":
          return <TouchAppIcon fontSize="small" style={{ color: "#6c757d" }} />;
        case "one_touch":
          return <TouchAppIcon fontSize="small" style={{ color: "#6c757d" }} />;
        case "omron":
          return <BloodtypeIcon fontSize="small" style={{ color: "#6c757d" }} />;
        case "green_ocean":
          return <BubbleChart fontSize="small" style={{ color: "#6c757d" }}/>
        default:
          return <BluetoothAudio fontSize="small" style={{ color: "#6c757d" }}/> ;
      }
    }
  
    const connectWebSocket = () => {
      const newSocket = new WebSocket(
        `${constants.SOCKET1}/doctor_dashboard/${groupName}/${token}/`
      );
  
      newSocket.onopen = () => {
        console.log("WebSocket connection opened");
      };
  
      newSocket.onmessage = (evt) => {
        const data = JSON.parse(evt?.data);
        const receivedData = data?.message;
  
        if (receivedData.length > 0) {
          setIsLoading(false);
          setCareData(receivedData);
        } else {
          setIsLoading(false);
        }
      };
  
      newSocket.onerror = (error) => {
        setIsLoading(false);
        console.error(`WebSocket error: ${error}`);
      };
  
      return newSocket;
    };
  
    const retryWebSocket = () => {
      if (retryCount < MAX_RETRIES) {
        setRetryCount(retryCount + 1);
        console.log(`WebSocket connection attempt ${retryCount + 1}`);
        setSocket(connectWebSocket());
        setIsLoading(true);
      }
    };
  
    useEffect(() => {
      let currentSocket = null;
  
      if (isDoctor) {
        currentSocket = connectWebSocket();
        setSocket(currentSocket);
      }
  
      return () => {
        if (currentSocket) {
          currentSocket.close();
          console.log("Websocket closed")
        }
      };
    }, [isDoctor]);
  
    useEffect(() => {
      let intervalIdWS;
  
      if (isDoctor && socket) {
        intervalIdWS = setInterval(() => {
          socket.send(JSON.stringify({ foo: 'baz' }));
        }, 500000);
      }
  
      return () => {
        clearInterval(intervalIdWS);
      };
    }, [socket, isDoctor]);
  
    useEffect(() => {
      const handleBeforeUnload = () => {
        if (socket) {
          socket.close();
          console.log("Websocket closed1")
        }
      };
  
      window.addEventListener('beforeunload', handleBeforeUnload);
  
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }, [socket]);

    const handleFilterClose = () => {
      setDevicesAnchorEl(null);
    }
    const handleSyncedDevices = (event) =>{
      setDevicesAnchorEl(event.currentTarget);
    }

    useEffect(() => {

      const fetchPatientData = async (id) => {
        try {
          const response = await axios.get(`${constants.API_URL}/ble_devices/${id}/${token}`);
          // console.log(response)
          setPatientData(prevData => [...prevData, response.data]);
        } catch (error) {
          console.error('Error fetching patient data:', error);
        }
      };
  
      patientList?.forEach(patient => {
        fetchPatientData(patient.group);
      });
    }, [patientList]);

  
    const CardOnClick = (token, group_id, group_name) => {
     const patientView = true;
      history.push(`/patient/${group_id}`, { patient_token: token, group_name , patientView});
    };
  
    const renderCareTakerView = (
      <div>
        {isLoading ? (
          <div style={{ marginTop: "-15%" }}>
            <LoaderCG />
          </div>
        ) : socket && socket.readyState === WebSocket.OPEN ? (
          careData && Array.isArray(careData) && careData?.length > 0 ? (
            <div>
              <div style={{ textAlign: "right", margin: "2px" }}> <Button onClick={handleSyncedDevices} style={{ backgroundColor: "#7F55AE", color: "white", fontSize: "0.7rem" }}> <Sync /> Synced devices</Button></div>
              <div className="wrapper1">
                {careData?.map((curElem) => (
                  <div key={curElem?.group_id}>
                    <CareTakeCard
                      title={curElem?.group_name}
                      token={curElem?.token}
                      group_id={curElem?.group_id}
                      RTlabel={curElem.key0}
                      RTunit={curElem.unit0}
                      RTvalue={curElem.data0}
                      alarmPoint0={curElem.alarm_point0}
                      LTlabel={curElem.key1}
                      LTunit={curElem.unit1}
                      LTvalue={curElem.data1}
                      alarmPoint1={curElem.alarm_point1}
                      RBlabel={curElem.key2}
                      RBunit={curElem.unit2}
                      RBvalue={curElem.data2}
                      alarmPoint2={curElem.alarm_point2}
                      LBlabel={curElem.key3}
                      LBunit={curElem.unit3}
                      LBvalue={curElem.data3}
                      alarmPoint3={curElem.alarm_point3}
                      handleCardClick={CardOnClick}
                    />
                  </div>

                ))}
              </div>
    </div>
  )  : (
            <div style={{fontFamily:"'Montserrat', sans-serif", fontSize:"1rem", fontWeight:"600"}}>No patients assigned to you</div>
          )
        ) : retryCount < MAX_RETRIES ? (
          <div>
            <div style={{ fontFamily:"'Montserrat',san-serif", fontSize:"0.9rem", fontWeight:'500'}}>Connection Error</div>
            <Button style={{fontFamily:"'Montserrat',san-serif", fontSize:"0.7rem",backgroundColor:"#d3d3d3"}} onClick={retryWebSocket}>
              <FontAwesomeIcon style={{marginRight:"3px"}} icon={faRotateRight}/>Try Again</Button>
          </div>
          
        ) : (
          <div>
           <div style={{ fontFamily:"'Montserrat',san-serif", fontSize:"0.9rem", fontWeight:'500'}}>Connection Error</div>
            <Button style={{fontFamily:"'Montserrat',san-serif", fontSize:"0.7rem",backgroundColor:"#d3d3d3"}} onClick={retryWebSocket}>
              <FontAwesomeIcon style={{marginRight:"3px"}} icon={faRotateRight}/>Try Again</Button>
          </div>
        )}
      </div>
    );
  
    return (
      <div>
        {isDoctor ? (
          renderCareTakerView
        ) : (
          <div>No Patient Details</div>
        )}
        <Popover
          open={Boolean(devicesAnchorEl)}
          anchorEl={devicesAnchorEl}
          onClose={handleFilterClose}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left'}}
          transformOrigin={{ vertical: 'top',horizontal: 'left'}}
        >
          <div>
            <div style={{margin:"7px",fontFamily:"'Montserrat',san-serif", fontSize:"0.95rem", fontWeight:'600', color: "#6c757d" }}> Patient Device Info </div>
            {patientList?.map((patient, index) => (
              <Accordion style={{fontFamily:"'Montserrat',san-serif", fontSize:"0.9rem", fontWeight:'500'}} key={index}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                 <div style={{display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center",fontFamily:"'Montserrat',san-serif", fontSize:"0.85rem", fontWeight:'500'}}> <Avatar style={{height:"20px", width:"20px", margin: " 0px 5px 0px 5px"}} /> {patient.group_name} - {patient.group} </div>
                </AccordionSummary>
                <AccordionDetails style={{marginTop:"-10px"}}>
                  <div style={{fontFamily:"'Montserrat',san-serif", fontSize:"0.75rem", fontWeight:'600',color: "#6c757d", marginBottom:"3px"}}>REGISTERED DEVICES</div>
                  <div style={{minWidth:"250px", maxWidth:"100%", backgroundColor:"aliceblue", boxShadow:"0px 3px 5px rgba(67, 67, 67, 0.5)", borderRadius:"5px", padding:"10px"}}>
                    {patientData[index]?.device_list?.map((device, deviceIndex) => (
                      <div key={deviceIndex}>
                     <div style={{width:"100%",display:"flex", flexDirection:"column", alignItems:"left", justifyContent:"left", margin:"5px 0px 5px 0px"}}>
                       <div style={{fontFamily:"'Montserrat',san-serif", fontSize:"0.85rem", fontWeight:'550'}}> {getDeviceIcon(device.device_type)} {device.device_type} </div>
                        <div style={{fontFamily:"'Montserrat',san-serif", fontSize:"0.7rem", fontWeight:'500'}}> mac ID - {device.mac_address}  </div>
                     </div>
                     <Divider/>
                      </div>
                    ))}
                  </div>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </Popover>
      </div>
    );
  };

export default MyPatientComponent;
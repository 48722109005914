
import React from 'react'
import ReactEcharts from 'echarts-for-react';
import '../../FitCharts/FNewDash.css'


export default function HRChartPage1({title,xData,yData,handleClick}) {
  

  const chartData =React.useMemo(()=>{
    return {
      title: {
        textStyle: {
          fontFamily: "sans-serif"
        },
        left: 'center',
        text: title
      },
      
      tooltip: {
        textStyle: {
          fontFamily: "sans-serif"
        },
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        // legend: {
        //   textStyle: {
        //     fontFamily: "sans-serif"
        //   },
        //   left:490,
        //   top:35,
        //   selectedMode: false,
        // },
      xAxis: {
        type: 'category',
      //   boundaryGap: false,
        data: xData,
        axisLabel: {
              
          fontFamily:'sans-serif',
        }
      },
      yAxis: {
        splitLine: {
          show: false
        },
        type: 'value',
        axisLabel: {
              
          fontFamily:'sans-serif',
        }
      //   boundaryGap: [0, '100%']
      },
      
      series: [
        {
          name: 'beats/min',
          type: 'bar',
          areaStyle: {},
              
          symbol: 'none',
          
          // itemStyle: {
          color: '#6D3DA3',
          // },
          
          data: yData
        }
      ]}
  },[xData,yData,title])



// const [status, setStatus] = React.useState(false)
// const tokenStr = "eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIyMzhKVDMiLCJzdWIiOiJCNEpWS1kiLCJpc3MiOiJGaXRiaXQiLCJ0eXAiOiJhY2Nlc3NfdG9rZW4iLCJzY29wZXMiOiJ3aHIgd251dCB3cHJvIHdzbGUgd3NvYyB3YWN0IHdveHkgd3RlbSB3d2VpIHdzZXQgd2xvYyB3cmVzIiwiZXhwIjoxNjYzNzQ0MDcxLCJpYXQiOjE2NjMxMzkyNzF9.7Pz6O94kCPNXSq_ZenSoqM1yqrsxeXHJiBdh1PaSYHY"

return (
  <div>
    {chartData?.xAxis?.data?.length?
  <div className='hr-week-chart'>
  <ReactEcharts 
  option={chartData} 
  onEvents={{
    'click': handleClick,
  }}
  />
  <div className='area-name'></div>
  </div>
   : <p style={{fontSize:"20px", fontWeight:"bold"}}>NO DATA AVAILABLE </p>
   }
  </div>
)
}

import React from "react";
import { useState } from "react";

import "../FNewDash.css";
import { Row, Col } from "reactstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import constants from "../../../../../../Common/Constants";
import { format, endOfDay } from "date-fns";
import BRChartContainer from "./BRChartContainer";
import BreadcrumbsTop from "../BreadcrumbsTop";
import { useLocation, useParams } from "react-router-dom";
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { Popover, Typography } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

const breadCrumbData = [
  {
    displayName: "Home",
    link: "/home",
  },
  {
    displayName: "BreathPage",
    link: "/brpage",
  },
];
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const BRMainPage = () => {
  window.scrollTo(0, 0);
  const [tabValue, setTabValue] = useState("week");
  const [monthData, setMonthData] = useState({ xData: [], yData: [] });
  const [weekData, setWeekData] = useState({ xData: [], yData: [] });
  const [threeMonthData, setThreeMonthData] = useState({
    xData: [],
    yData: [],
  });
  const [yearData, setYearData] = useState({ xData: [], yData: [] });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const groupName = useSelector((state) => state.auth.groupName);
  const token = useSelector((state) => state.auth.authToken);

  const careTakerToken = useSelector((state) => state.auth.careTakerToken);

  const dateHR = format(endOfDay(new Date()), "ddMMyyy");

  const { id, patient_group_id } = useParams();
  const [open, setOpen] = React.useState(false);

  const location = useLocation();
  const urlStateData = location?.state;

  useEffect(() => {
    if (tabValue === "week" && !weekData?.xData?.length) {
      axios
        .get(
          `${constants.API_URL}/week_data/br/${
            urlStateData ? patient_group_id : groupName
          }/${dateHR}/${urlStateData ? careTakerToken : token}/`
        )
        .then((res) => {
          let x = [];
          let y = [];
          res.data.week_data?.forEach((data) => {
            x.push(data.x_data);
            y.push(data.y_data);
          });

          setWeekData({ xData: x, yData: y });
        });
    } else if (tabValue === "month" && !monthData?.xData?.length) {
      axios
        .get(
          `${constants.API_URL}/month_data/br/${
            urlStateData ? patient_group_id : groupName
          }/${dateHR}/${urlStateData ? careTakerToken : token}/`
        )
        .then((res) => {
          let x = [];
          let y = [];
          res.data.month_data?.forEach((data) => {
            x.push(data.x_data);
            y.push(data.y_data);
          });

          setMonthData({ xData: x, yData: y });
        });
    } else if (tabValue === "3month" && !threeMonthData?.xData?.length) {
      axios
        .get(
          `${constants.API_URL}/three_month_data/br/${
            urlStateData ? patient_group_id : groupName
          }/${dateHR}/${urlStateData ? careTakerToken : token}/`
        )
        .then((res) => {
          let x = [];
          let y = [];
          res.data.three_month_data?.forEach((data) => {
            x.push(data.x_data);
            y.push(data.y_data);
          });
          setThreeMonthData({ xData: x, yData: y });
        });
    } else if (tabValue === "year" && !yearData?.xData?.length) {
      axios
        .get(
          `${constants.API_URL}/year_data/br/${
            urlStateData ? patient_group_id : groupName
          }/${dateHR}/${urlStateData ? careTakerToken : token}/`
        )
        .then((res) => {
          let x = [];
          let y = [];
          res.data.year_data?.forEach((data) => {
            x.push(data.x_data);
            y.push(data.y_data);
          });

          setYearData({ xData: x, yData: y });
        });
    }
  }, [tabValue]);

  const handleChange = (value) => {
    setTabValue(value);
  };
  const BRManualEntryDel = () => {
    axios
      .get(
        `${constants.API_URL}/delete_latest_manual_entry/${groupName}/BP/${token}/`
      )
      .then((res) => {
        setOpen(true);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAnchorClose = () => {
    setAnchorEl(null);
  };

  const openPopOver = Boolean(anchorEl);
  const popOverId = openPopOver ? "simple-popover" : undefined;

  return (
    <div className="backH">
      <div className="container-dashH">
        <div className="DelButton">
          <BreadcrumbsTop data={breadCrumbData} />
          <IconButton onClick={handleClick}>
            <MoreHorizIcon />
          </IconButton>
          <Popover
            id={popOverId}
            open={openPopOver}
            anchorEl={anchorEl}
            onClose={handleAnchorClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Typography
              style={{
                padding: "2.5px",
                cursor: "pointer",
                touchAction: "manipulation",
                transition: "box-shadow .2s ease-in-out",
                outline: "transparent",
              }}
              onClick={BRManualEntryDel}
            >
              Delete Last Entry
            </Typography>
          </Popover>
          <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
            <Alert
              onClose={handleClose}
              severity="success"
              sx={{ width: "100%" }}
            >
              Last Manual Entry Successfully Deleted!
            </Alert>
          </Snackbar>
        </div>
        <Tabs
          defaultActiveKey="week"
          activeKey={tabValue}
          transition={false}
          id="noanim-tab-example"
          className="mb-3"
          onSelect={handleChange}
        >
          <Tab className="h-100" eventKey="week" title="WEEK">
            <Row className="h-100">
              <Col>
                <BRChartContainer
                  title="Weekly Report"
                  xData={weekData?.xData}
                  yData={weekData?.yData}
                />
              </Col>
            </Row>
          </Tab>
          <Tab className="h-100" eventKey="month" title="MONTH">
            <Row className="h-100">
              <Col>
                <BRChartContainer
                  title="Monthly Report"
                  xData={monthData?.xData}
                  yData={monthData?.yData}
                />
              </Col>
            </Row>
          </Tab>
          <Tab className="h-100" eventKey="3month" title="3MONTHS">
            <Row className="h-100">
              <Col>
                <BRChartContainer
                  title="Three Month Report"
                  type="threeMonth"
                  xData={threeMonthData?.xData}
                  yData={threeMonthData?.yData}
                />
              </Col>
            </Row>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default BRMainPage;

import {
  addNoOfStatsPages,
  addStatsPageDevices,
  addStatsPresentPage,
} from "../store/statsSlice";
import {
  addBedDeviceList,
  addBedList,
  addDeviceSettings,
  addDeviceStreamTokens,
  addDeviceTypeList,
} from "../store/homeSlice";
import {
  setDeviceNames,
  setDeviceTypeTabs,
  setEndReplayTime,
  setReplayBedDevices,
  setReplayPage,
  setSelectedBed,
  setSelectedDeviceId,
  setSelectedDeviceType,
  setStartReplayTime,
  showReplayModal,
} from "../store/settingsSlice";
import {
  addAuthToken,
  addDeviceList,
  addGroupDevices,
  addGroupName,
  addHeaderGroupName,
  addNoOfPages,
  addPageDeviceFavorite,
  addPageDevices,
  addPresentPage,
  setCareTaker,
  setCurrentUserIndex,
  setCareTakerToken,
  setUserData,
} from "../store/authSlice";
import { clearDashboardSlice } from "../store/dashboardSlice";

export default function resetReduxState(dispatch) {
  //AuthSlice Store
  dispatch(addAuthToken(""));
  dispatch(addGroupName(""));
  dispatch(addHeaderGroupName(""));
  dispatch(addNoOfPages(1));
  dispatch(addPresentPage(1));
  dispatch(addGroupDevices([]));
  dispatch(addPageDevices([]));
  dispatch(addPageDeviceFavorite([]));
  dispatch(addDeviceList({}));
  dispatch(setCareTaker(false));
  dispatch(setCurrentUserIndex(0));
  dispatch(setCareTakerToken([]));
  dispatch(setUserData([]));

  //StatsSlice Store
  dispatch(addStatsPageDevices([]));
  dispatch(addStatsPresentPage(1));
  dispatch(addNoOfStatsPages(1));

  //HomeSlice Store
  dispatch(addBedList([]));
  dispatch(addBedDeviceList([]));
  dispatch(addDeviceList([]));
  dispatch(addDeviceTypeList([]));
  dispatch(addDeviceSettings([]));
  dispatch(addDeviceStreamTokens([]));

  //SettingSlice Store
  dispatch(setSelectedBed("AllDevices"));
  dispatch(setSelectedDeviceId(null));
  dispatch(setSelectedDeviceType(null));
  dispatch(setDeviceTypeTabs([]));
  dispatch(setDeviceNames([]));
  dispatch(showReplayModal(false));
  dispatch(setReplayPage(false));
  dispatch(setStartReplayTime(null));
  dispatch(setEndReplayTime(null));
  dispatch(setReplayBedDevices([]));

  //dashboard slice

  dispatch(clearDashboardSlice())

  
}
import React, { useEffect, useState } from "react";
import common from "../../../Common/Common";
import { Card, CardContent, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  card: {
    padding: "12px",
    "&:last-child": {
      paddingBottom: "12px",
    },
  },
}));

function VitalCamStatus({ statusValue, statusType, index }) {
  const classes = useStyles();

  const [maxValue, setMaxValue] = useState(null);
  const [minValue, setMinValue] = useState(null);
  const [avgValue, setAvgValue] = useState(0);
  const [statusUnit, setStatusUnit] = useState("");

  useEffect(() => {
    setMaxValue(null);
    setMinValue(-1);
    setAvgValue(0);
    setStatusUnit("");

    Object.keys(common.STATUS_UNITS).forEach((key, index) => {
      if (key === statusType) setStatusUnit(common.STATUS_UNITS[key]);
    });

    Object.keys(common.STATUS_MAXVALUE).forEach((key, index) => {
      if (key === statusType) setMaxValue(common.STATUS_MAXVALUE[key]);
    });

    Object.keys(common.STATUS_MINVALUE).forEach((key, index) => {
      if (key === statusType) setMinValue(common.STATUS_MINVALUE[key]);
    });

    Object.keys(common.STATUS_AVGVALUE).forEach((key, index) => {
      if (key === statusType) setAvgValue(common.STATUS_AVGVALUE[key]);
    });
  }, [statusValue, statusType]);

  return (
    <Card
      style={{
        color: common.STATUS_DISPLAY_COLORS[index],
        backgroundColor: "#1b1927",
        marginBottom: "20px",
      }}
    >
      <CardContent className={classes.card}>
        <div className="flexitems-between">
          <div>
            <Typography className="text-overflow-ellipsis smallText">
              {statusType !== undefined ? statusType : "--"}
            </Typography>
          </div>
          {maxValue && (
            <div style={{ width: "50%" }}>
              <Typography className="text-overflow-ellipsis smallText text-right">
                <span style={{ color: "grey" }}> max: </span> {maxValue}
              </Typography>
            </div>
          )}
        </div>
        <Typography className="extraLargeText statsCenterValue text-overflow-ellipsis">
          {statusValue}
        </Typography>
        <Typography className="smallText statsCenterValue text-overflow-ellipsis">
          {statusUnit}
        </Typography>
        <div className="flexitems-between flex-row-reverse">
          {minValue > -1 && (
            <div style={{ width: "50%" }}>
              <Typography className="smallText text-overflow-ellipsis text-right">
                <span style={{ color: "grey" }}> min: </span>
                {minValue}
              </Typography>
            </div>
          )}

          <div
            className="bordered-stats"
            style={{ visibility: avgValue ? "visible" : "hidden" }}
          >
            <Typography className="smallText">{avgValue}</Typography>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

export default VitalCamStatus;

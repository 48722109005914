import React from 'react'
import CircleCommonCard from './CircleCommonCharts/CircleCommonCard'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faShoePrints} from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import FormStep from '../../../Member Details/Comps/FitbitFolder/FitCharts/FormStep';
import FormSpO2 from '../../../Member Details/Comps/FitbitFolder/FitCharts/FormSpO2';
import { useHistory } from 'react-router-dom';
import TileRemove from '../Featuresv2/TileRemove';
import { SPOPopup } from '../SPOv2/SPOPopup';
import { useState } from 'react';
import { useEffect } from 'react';



function StepSpo2v2({onSTPPopupClick,onSPOPopupClick}) {

  const history = useHistory();

  const stepsDaily = useSelector((state) => state.dashboard.stepsDaily)
  const STPDaily = stepsDaily?.total
  // console.log(stepsDaily);

  const spo2DailyMin = useSelector((state) => state.dashboard.spo2DailyMin);
  const spo2DailyMax = useSelector((state) => state.dashboard.spo2DailyMax);
  const spo2DailyAvg = useSelector((state) => state.dashboard.spo2DailyAvg);


  const [isExpiredSPO, setIsExpiredSPO] = useState(false);
  const [isExpiredSTP, setIsExpiredSTP] = useState(false);

  const alarmStatusSPO= spo2DailyMin?.data?.y_data?.status_point
  const timeGapIndicationSPO = spo2DailyMin?.data?.x_data

  const timeGapIndicationSTP = stepsDaily?.time_gap

  useEffect(() => {
    if (timeGapIndicationSPO?.includes("day") || timeGapIndicationSPO?.includes("days")) {
      setIsExpiredSPO(true);
    }
    else{
      setIsExpiredSPO(false);
    }
  }, [timeGapIndicationSPO, spo2DailyMin]);

  useEffect(() => {
    if (timeGapIndicationSTP?.includes("day") || timeGapIndicationSTP?.includes("days")) {
      setIsExpiredSTP(true);
    }
    else{
      setIsExpiredSTP(false);
    }
  }, [timeGapIndicationSTP, stepsDaily]);

  const SPOAvg = spo2DailyAvg?.data?.y_data?.spo2_avg
  const SPOMin = spo2DailyMin?.data?.y_data.spo2_min
  const SPOMax = spo2DailyMax?.data?.y_data.spo2_max

  // console.log(spo2DailyMin);
  // console.log(SPOMin);
  // console.log(SPOMax);

  const StepsPage = () => {
    history.push("/stepspage");
  };
  const Spo2Page = () => {
    history.push("/spo2page");
  };


  if ( !SPOAvg || !STPDaily){
    return null;
  }

  return (

    
    <div style={{height:"100%", display:"flex",gap:"5px", flexDirection:"column", justifyContent:"space-between"}}>
   {SPOAvg && <div>
        <CircleCommonCard 
     onClick={onSPOPopupClick}
     tileBgColor={isExpiredSPO? "transparent" : "white"}
     alarmStatusBorder={alarmStatusSPO}
     cardTitle={"SpO2"}
     cardValue={SPOAvg? SPOAvg:""}
     cardUnit={"%"}
     cardValue1={SPOMin? SPOMin :""}
     cardValue3={SPOMax? SPOMax:""}
     cardAlarmStatus={(spo2DailyMax?.data?.y_data?.status).length > 15 ? spo2DailyMax?.data?.y_data?.status.substr(0, 15) + "..." : spo2DailyMax?.data?.y_data?.status }
     cardTimeGap={spo2DailyMax?.data?.x_data? spo2DailyMax?.data?.x_data +" ago":"-"}
     circleColor="#41D581"
     fontValueSize={"1.4rem"}
     parameter1={"MIN"}
     parameter3={"MAX"}
     TitleForm="SpO2"
     FormFile={<FormSpO2/>}
     option2={<TileRemove option2title={"REMOVE TILE"} parameter={"SPO"}/>}
     >
    </CircleCommonCard>

    </div>
}
{STPDaily && 
    <div>
        <CircleCommonCard
        onClick={onSTPPopupClick}
        cardTitle={"Steps"}
        tileBgColor={isExpiredSTP? "transparent" : "white"}
        cardValue={STPDaily? STPDaily:""}
        cardUnit={"Steps"}
        cardValue1={""}
        cardValue3={""}
        cardAlarmStatus={""}
        cardTimeGap={stepsDaily?.time_gap+" ago"}
        circleColor="#F64D60"
        cardIcon={<FontAwesomeIcon icon={faShoePrints} color="#75757C" />}
        TitleForm="Step Count"
        FormFile={<FormStep/>}
        option2={<TileRemove option2title={"REMOVE TILE"} parameter={"STP"}/>}
        >
        
        </CircleCommonCard>
    </div>
}
    </div>
  )
}

export default StepSpo2v2
import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import GraphDashboard from "./GraphDashboard.js";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory,useLocation } from "react-router-dom";
import update from "immutability-helper";
import StatsDashboard from "./StatsDashboard.js";
import BedDashboard from "../SinglePage/BedDashboard.js";
import common from "./../../Common/Common.js";
import CareCam from "../../Common/CommonCard";
import DeviceDashboard from "../SinglePage/DeviceDashboard.js";

import {
  setReplayPage,
  setSelectedBed,
  setSelectedDeviceId,
  setSelectedDeviceType,
  showReplayModal,
} from "../../store/settingsSlice.js";
import resetReduxState from "../../Common/Logout.js";
import constants from "../../Common/Constants.js";
import { addRenderInterval, setCareTaker } from "../../store/authSlice.js";
import {
  setDashboardData,
  setWebsocketStatus,
} from "../../store/dashboardSlice";
import ReplayModal from "../ReplayModal/ReplayModal.js";

import HeartrateChart1 from "../Member Details/Comps/FitbitFolder/FitCharts/HeartrateChart1.js";
import BPChartDaily from "../Member Details/Comps/FitbitFolder/FitCharts/BPChartDaily.js";
import GlucoseChartDaily from "../Member Details/Comps/FitbitFolder/FitCharts/GlucoseChartDaily.js";
import SleepChartdaily from "../Member Details/Comps/FitbitFolder/FitCharts/SleepChartdaily.js";
import DisChartHourly from "../Member Details/Comps/FitbitFolder/FitCharts/DisChartHourly.js";
import BreathChart from "../Member Details/Comps/FitbitFolder/FitCharts/BreathChart.js";
import StepChartHour from "../Member Details/Comps/FitbitFolder/FitCharts/StepChartHour.js";
import Spo2ChartDaily from "../Member Details/Comps/FitbitFolder/FitCharts/Spo2ChartDaily.js";
import { Col, Row } from "reactstrap";
import { Card, CardActionArea, Paper } from "@material-ui/core";
import PopUpBP from "../Member Details/Comps/FitbitFolder/FitCharts/PopUpBP.js";
import PopUpBG from "../Member Details/Comps/FitbitFolder/FitCharts/PopBG.js";
import ExpandMorePopup from "../../Common/ExpandMorePopup.js";

import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { Button } from "@mui/material";
import { MoreHoriz } from "@material-ui/icons";
import FormBP from "../Member Details/Comps/FitbitFolder/FitCharts/FormBP.js";
import TempChartDaily from "../Member Details/Comps/FitbitFolder/FitCharts/TempChartDaily.js";
import CalorieChartDaily from "../Member Details/Comps/FitbitFolder/FitCharts/CalorieChartDaily.js";
import CommonCard from "../../Common/CommonCard";
import InfoCard from "../../Common/InfoCard/index.js";

import Ventilator from "./Ventilator";
import PatientMon from "./PatientMonitor";
import CareCamCard from "./CareCamCard/index.js";
import { get } from "jquery";
import WeightDaily from "../Member Details/Comps/FitbitFolder/FitCharts/WeightDaily.js";
import CareTaker1 from "./CareGiverPage/CareTaker1.js";
import CareGiverPage from "../home/CareGiverPage/index.js"
import CareTakeCard from "./CareGiverPage/CareTakerCard.js";
import Header from "../Header/Header.js";
import FloatingAddbtn from "./FloatingAddbtn.js";
import PopupBG from "../Member Details/Comps/FitbitFolder/FitCharts/PopBG.js";
import FormBG from "../Member Details/Comps/FitbitFolder/FitCharts/FormBG.js";
import FormSpO2 from "../Member Details/Comps/FitbitFolder/FitCharts/FormSpO2.js";
import FormHR from "../Member Details/Comps/FitbitFolder/FitCharts/FormHR.js";
import FormBR from "../Member Details/Comps/FitbitFolder/FitCharts/FormBR.js";
import FormTemp from "../Member Details/Comps/FitbitFolder/FitCharts/FormTemp.js";
import FormWT from "../Member Details/Comps/FitbitFolder/FitCharts/FormWT.js";
import FormStep from "../Member Details/Comps/FitbitFolder/FitCharts/FormStep.js";
import FormCalorie from "../Member Details/Comps/FitbitFolder/FitCharts/FormCalorie.js";
import PopUpSleep from "../Member Details/Comps/FitbitFolder/FitCharts/PopUpSleep.js";

let IremonSocket = null;
let ISaveSocket = null;
let VitalCamSocket = null;
let CapnometerSocket = null;
let PatientMonSocket = null;
let VentilatorSocket = null;
let socket = null;

let deviceReplaySocket = null; //For Replay Mode
let bedReplaySocket = null; //For Replay Mode

let socketArray = [];

function HomeDashboard2({
  graphMode,
  bedDeviceView,
  pageType,
  connectFlag,
  setConnectFlag,
  getCreatedSocket,
  storeCreatedSockets,
  closeCreatedSockets,
  connectReplayFlag,
  setConnectReplayFlag,
  
}) {
  const HeartPage = () => {
    history.push(`/heartratepage/${patient_group_id}`,careTakerPatientView);
  };
  const StepsPage = () => {
    history.push(`/stepspage/${patient_group_id}`,careTakerPatientView);
  };
  
  const SlpPage = () => {
    history.push(`/sleeppage/${patient_group_id}`,careTakerPatientView);
  };
  const Spo2Page = () => {
    history.push(`/spo2page/${patient_group_id}`,careTakerPatientView);
  };
  const BrPage = () => {
    history.push(`/brpage/${patient_group_id}`,careTakerPatientView);
  };
  const BpPage = () => {
    history.push(`/bppage/${patient_group_id}`,careTakerPatientView);
  };
  const BgPage = () => {
    history.push(`/bgpage/${patient_group_id}`,careTakerPatientView);
  };

  const PatMon = () => {
    history.push("/patientmonitor");
  };
  const Vent = () => {
    history.push("/ventilator");
  };
  var [gridData, setGridData] = useState([]);
  var [bedData, setBedData] = useState([]);
  var [gridBufferedData, setGridBufferedData] = useState([]);
  var [bedBufferedData, setBedBufferedData] = useState([]);
  const [ test , setTest ] = useState(1);
  const [careData , setCareData] =useState([]);
  const getLocalStoirageData = ()=>{
    let d = localStorage.getItem("test");
    if(!test){
       return false ;
    }
    try{
      d = JSON.parse( d );
      return d.token ? d : false ;
    }catch(er){
      return false ;
    }
    return !1; 
  }
  var [viewedBed, setViewedBed] = useState(null);

  var [renderingsPerSecond, setRenderingsPerSecond] = useState(10);

  const count = useSelector((state) => state.auth.renderInterval);
  const groupName = useSelector((state) => state.auth.groupName);
  const token = useSelector((state) => state.auth.authToken);
  const deviceTypeList = useSelector((state) => state.home.deviceTypeList);

  const careTakerToken = useSelector((state) => {
    let local = getLocalStoirageData();
    if( local ){ 
        return local.token ;   
    }
    return state.auth.careTakerToken
  });
  
  const userData = useSelector((state) => state.auth.userData);
  const currentUserIndex = useSelector((state) => state.auth.currentUserIndex);
  
  const careTaker = useSelector((state) => {
    let local = getLocalStoirageData();
    if( local && local.token ){
        return false ;
    }
    return state.auth.careTaker ;
  });
  let deviceTypeArray = deviceTypeList.map((device) => {
    return device.deviceType;
  });
  const pageDevices = useSelector((state) => {
    if (state.auth.graphMode) {
      return state.auth.pageDevices;
    } else {
      return state.stats.statsPageDevices;
    }
  });

  const replayModal = useSelector((state) => state.settings.replayModal);
  const replayPage = useSelector((state) => state.settings.replayPage);

  const selectedBed = useSelector((state) => state.settings.selectedBed);
  const selectedDeviceId = useSelector(
    (state) => state.settings.selectedDeviceId
  );
  const selectedDeviceType = useSelector(
    (state) => state.settings.selectedDeviceType
  );
  const deviceStreamTokens = useSelector(
    (state) => state.home.deviceStreamTokens
  );
  const deviceTypeTabs = useSelector((state) => state.settings.deviceTypeTabs);
  const deviceNames = useSelector((state) => state.settings.deviceNames);
  
  const deviceSettings = useSelector((state) => state.home.deviceSettings);
  const bedList = useSelector((state) => state.home.bedList);

  const websocketStatus = useSelector(
    (state) => state.dashboard.websocketStatus
  );

  const { id,patient_group_id } = useParams();
  const location = useLocation();
  const urlStateData = location?.state

  const careTakerPatientView = !!(careTaker && patient_group_id)
  const history = useHistory();
  const dispatch = useDispatch();
  //Handshake Intervals for websockets
  var interval;
  var intervalRefresh;
  var CapnometerInterval;
  var PatientMonInterval;
  var VentilatorInterval;

  let newdata;
  let newBedData;

  //For replay Mode
  var replayInterval;

  let bedIdArray = bedList.map((bed) => {
    return bed.bedId;
  });

  let deviceIdArray = deviceTypeList.map((device) => {
    return device.deviceId;
  });
  const startReplayTime = useSelector(
    (state) => state.settings.startReplayTime
  );
  const endReplayTime = useSelector((state) => state.settings.endReplayTime);
  const dashboardApiStatus = useSelector((state) => state.dashboard.apiStatus);

  //Replaces the websocket if already created
  function removeCreatedSocket(deviceType) {
    let errorMessage = `Error creating Websocket for ${deviceType}`;
    
    let socketIndex = socketArray.findIndex(
      (socket) => socket.deviceType === deviceType
    );
    socketArray.splice(socketIndex, 1);
    if (socketArray.length === 0) {
      resetReduxState(dispatch);
      clearInterval(intervalRefresh);
      history.push("/");
      
    }
    return socketArray;
  }
  
  useEffect(() => {
    
    // if (dashboardApiStatus == "fetch") {
      const fetchData = async () => {
      let url = `${constants.API_URL}/dashboard_values/${careTakerPatientView?patient_group_id:groupName}/${careTakerPatientView?urlStateData?.patient_token:token}/`;
      
      let activeUserDeviceList = userData?.[currentUserIndex]?.["device_list"];

      // url += `${token}/`;
      
      
      const result = await axios.get(url);
      
      dispatch(setDashboardData(result.data.data));
      };
      {(!careTaker || careTakerPatientView) &&(
      fetchData()
      )}
      const intervalId = setInterval(fetchData, 610000);
      return () => clearInterval(intervalId)
    // }
  }, [dashboardApiStatus, groupName, token, currentUserIndex, userData,test ]);

  
 

  const CardOnClick = ( token, group_id ) => {
    
        history.push(`/patient/${group_id}`,{ patient_token: token })
  };
  
  


  return (
    <>
    <div className="containerPatientViewMain2">
      <div className="header-patientview">
        <Header careTakerPatientView/>

      </div>
       
       <div className="dem-carehr-container main-container">
        

      
        <div class="wrapper">
          <HeartrateChart1 onClick={HeartPage} careTakerPatientView={careTakerPatientView} />

          <Spo2ChartDaily onClick={Spo2Page} careTakerPatientView={careTakerPatientView} />

          <GlucoseChartDaily onClick={BgPage} careTakerPatientView={careTakerPatientView} />

          <BPChartDaily onClick={BpPage} careTakerPatientView={careTakerPatientView} />

          <SleepChartdaily onClick={SlpPage} careTakerPatientView={careTakerPatientView} />

          <StepChartHour onClick={StepsPage} careTakerPatientView={careTakerPatientView} />

          {/* <CareCamCard /> */}
          {/* <Ventilator /> */}
          {/* <PatientMon/> */}
        </div>

        <div className="fixed-container">
          <BreathChart onClick={BrPage} careTakerPatientView={careTakerPatientView} />

          <TempChartDaily careTakerPatientView={careTakerPatientView} />

          <WeightDaily careTakerPatientView={careTakerPatientView} />

          <CalorieChartDaily careTakerPatientView={careTakerPatientView} />
        </div>
        
        <FloatingAddbtn
        option1={<PopUpBP optionTitle="Blood Pressure" careTakerPatientView={careTakerPatientView}/>} 

        option2={<PopupBG
          optionName="Blood Glucose" 
          FormTitle="Blood Glucose" 
          FormType={<FormBG careTakerPatientView={careTakerPatientView} />}
          />}

        option9={<PopupBG 
              optionName="SpO2 " 
              FormTitle="SpO2 " FormType={<FormSpO2 careTakerPatientView/>}
              />} 
              
        option3={<PopupBG 
                optionName="Heart Rate" 
                FormTitle="Heart Rate" FormType={<FormHR careTakerPatientView={careTakerPatientView}/>}
                />}        

        option4={<PopupBG 
                  optionName="Breath Rate" 
                  FormTitle="Breath Rate" 
                  FormType={<FormBR careTakerPatientView/>}
                  />}        
        
        option5={<PopupBG 
                    optionName="Temperature" 
                    FormTitle="Temperature" 
                    FormType={<FormTemp careTakerPatientView={careTakerPatientView}/>}
                    />}
        
        option6={<PopupBG 
                      optionName="Weight" 
                      FormTitle="Weight" 
                      FormType={<FormWT careTakerPatientView/>}
                      />}
        
        option8={<PopupBG 
                        optionName="Step" 
                        FormTitle="Step" 
                        FormType={<FormStep careTakerPatientView />}
                        />}
                        
        option7={<PopupBG
                          optionName="Calorie" 
                          FormTitle="Calorie" 
                          FormType={<FormCalorie careTakerPatientView/>}
                          />}    
                          
        option10={<PopUpSleep optionTitle="Sleep" careTakerPatientView={careTakerPatientView}/>}                   
                      
                      

        />
      </div>

      
      

      {graphMode && !bedDeviceView ? (
        <Grid container className="gridDashboard_Grid">
          {pageDevices.map(
            (device) =>
              gridBufferedData[device] && (
                // <div>
                <GraphDashboard
                  singleData={gridBufferedData[device]}
                  key={gridBufferedData[device].name}
                />
                // </div>
              )
          )}
        </Grid>
      ) : null}

     

</div>
    </>
  );
}
export default React.memo(HomeDashboard2);

import React from "react";

import "../FNewDash.css";
import { Col, Row } from "reactstrap";
import StepChartpage1 from "./StepChartpage1";
import moment from "moment";
import { useState } from "react";
import constants from "../../../../../../Common/Constants";
import axios from "axios";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

const apiName = {
  week: "day_data",
  month: "week_data",
  threeMonth: "month_data",
  year: "month_data",
};

export default function StepChartContainer({ title, xData, yData, type }) {
  const groupName = useSelector((state) => state.auth.groupName);
  const [clickedChart, setClickedChart] = useState(null);
  const [subChartName, setChartName] = useState("");
  const token = useSelector((state) => state.auth.authToken);
  const api_type = apiName[type];
  const [chart_data, setData] = useState({});

  const careTakerToken = useSelector((state) => state.auth.careTakerToken);

  const { id,patient_group_id } = useParams();

  
  
   // 

  const location = useLocation();
  const urlStateData = location?.state 

  

  const handleClick = (param, echarts) => {
    const dateMonth = param?.name?.split(" ");
    let month = "01";
    let date = "01";
    if (dateMonth?.length === 1) {
      month = moment().month(dateMonth[0]).format("MM");
    } else {
      month = moment().month(dateMonth[1]).format("MM");
      date = dateMonth[0]?.length === 1 ? `0${dateMonth[0]}` : dateMonth[0];
    }
    const year = (param?.name.split(" "))[2];
    const clickedDate = `${date}${month}${year}`;
    setClickedChart(clickedDate);
    setChartName(param?.name);
    if (!chart_data?.[clickedDate]) {
      //api call
      axios
        .get(
          `${constants.API_URL}/${api_type}/STP/${urlStateData?patient_group_id:groupName}/${clickedDate}/${urlStateData?careTakerToken:token}/`
        )
        .then((res) => {
          let x = [];
          let y = [];
          res?.data?.[api_type].forEach((data) => {
            x.push(data.x_data);
            y.push(data.y_data);
          });
          let stateCopy = { ...chart_data };

          stateCopy[clickedDate] = { xData: x, yData: y };

          setData(stateCopy);
        });
    }
  };

  return (
    <div className="h-100">
      <Row className="h-100">
        <Col className="first-box h-100">
          <div className="first-card h-100">
            <StepChartpage1
              title={title}
              xData={xData}
              yData={yData}
              handleClick={handleClick}
            />
          </div>
        </Col>
        <Col className="second-box">
          {clickedChart && (
            <div className="second-card">
              <StepChartpage1
                title={subChartName}
                xData={chart_data?.[clickedChart]?.xData}
                yData={chart_data?.[clickedChart]?.yData}
              />
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
}

import React, { memo, useMemo, useRef } from "react";
import { useState, useEffect } from "react";
import ReactEcharts from "echarts-for-react";
import "../Commonv2.css";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { format, endOfDay } from "date-fns";
import moment from "moment";
import MainCommonCard from './MainCommonCard';
import PopUpManualEntry from "../OtherComponents/PopUpManualEntry";
import PopUpSleep from "../../../Member Details/Comps/FitbitFolder/FitCharts/PopUpSleep";
import FormSleep from "../../../Member Details/Comps/FitbitFolder/FitCharts/FormSleep";
import { height } from "@mui/system";
import TileRemove from "../Featuresv2/TileRemove";
import FormSleepAdv from "../../../Member Details/Comps/FitbitFolder/FitCharts/FormSleepAdv";

function SleepQuick({ onClick,careTakerPatientView=false }) {
  const groupName = useSelector((state) => state.auth.groupName);
  const token = useSelector((state) => state.auth.authToken);

  const [isExpired, setIsExpired] = useState(false);
  const dateHR = format(endOfDay(new Date()), "ddMMyyyy");
  const [xAxisData, setXData] = useState([]);
  const [yAxisData, setYData] = useState([]);

  const dispatch = useDispatch();
  const sleepDaily = useSelector(
    (state) => state.dashboard.sleepDaily || false
  );
  let totalSleep =
    sleepDaily?.data?.y_data["deep"] +
    sleepDaily?.data?.y_data["rem"] +
    sleepDaily?.data?.y_data["light"] +
    sleepDaily?.data?.y_data["wake"];
  let totalSleepHrs = parseInt(totalSleep / 60);
  let totalSleepMin = totalSleep % 60;

  const alarmStatus = sleepDaily?.data?.y_data?.status_point;

  const timeGapIndication = sleepDaily?.data?.x_data

  useEffect(() => {
    if (timeGapIndication?.includes("day") || timeGapIndication?.includes("days")) {
      setIsExpired(true);
    }
    else{
      setIsExpired(false);
    }
  }, [timeGapIndication, sleepDaily]);

  const chartData = React.useMemo(() => {
    return {
      title: [
        {
          text: "TotalSleep: " + totalSleepHrs + " hrs " + totalSleepMin + " mins ",
          left: "center",
          top: 0,
          textStyle: {
            fontWeight: "normal",
            fontSize: 13,
            fontFamily: "'Montserrat', sans-serif",
          },
        },
        
      ],
      tooltip: {
        textStyle: {
          fontFamily: "sans-serif",
        },
      },

      grid: {
        top:"16%",
        left: "8%",
        right: "10%",
        bottom: "5%",
        containLabel: true,
      },

      yAxis: {
        axisLabel: {
          fontFamily: "sans-serif",
        },
        type: "category",
        boundaryGap: [0, 0.01],

        data: ["REM", "Light Sleep", "Deep Sleep", "Awake"],
      },
      xAxis: {
        type: "value",
        show:false,
        axisLabel: {
          fontFamily: "sans-serif",
        },
        splitLine: {
          show: false
        },
      },
      series: [
        {
          name: "%",
          type: "bar",
          areaStyle: {},
          colors: [["#4dcd32", "#4dcd32", "#dd0453", "#4dcd32"]],
          symbol: "none",

          itemStyle: {
            colors: [["#4dcd32", "#4dcd32", "#dd0453", "#4dcd32"]],
          },

          data: [
            {
              value: (
                (sleepDaily?.data?.y_data?.rem / totalSleep) *
                100
              ).toFixed(1),
              itemStyle: { color: "#7F55AE" },
            },
            {
              value: (
                (sleepDaily?.data?.y_data?.light / totalSleep) *
                100
              ).toFixed(1),
              itemStyle: { color: "#41D581" },
            },
            {
              value: (
                (sleepDaily?.data?.y_data?.deep / totalSleep) *
                100
              ).toFixed(1),
              itemStyle: { color: "#00B5C6" },
            },
            {
              value: (
                (sleepDaily?.data?.y_data?.wake / totalSleep) *
                100
              ).toFixed(1),
              itemStyle: { color: "#F64D60" },
            },
          ],
        },
      ],
    };
  }, [xAxisData, sleepDaily]);

  function calculateDuration(ST, ET) {
    const startTime = moment(ST);
    const endTime = moment(ET);
    const duration = moment.duration(endTime.diff(startTime))
    const hours = duration.hours();
    const minutes = duration.minutes();
    return `${hours} hours  ${minutes} minutes`
  }

  const chartData2 = () => {
    // {alert("qwertyu")}
    return (
      <>
        {calculateDuration(
          sleepDaily?.data?.y_data?.sleep[0]?.sleep_start,
          sleepDaily?.data?.y_data?.sleep[0]?.sleep_end
        )}{" "}
      </>
    );
  };
  

  if( !sleepDaily || !Object.keys(sleepDaily).length ) {
    return null
  }
  else{
    if(isNaN(chartData.series[0]?.data[0]?.value)){
      return (
<>
        <MainCommonCard
        tileTitle={"Sleep"}
        tileColor={isExpired? "transparent" : "white"}
        Option1={
            <PopUpManualEntry
              optionName="MANUAL ENTRY"
              FormTitle="Sleep"
              FormType={<FormSleepAdv />} />
          }
          Option2={<TileRemove option2title={"REMOVE TILE"} parameter={"SLEEP"}/> }
          onClick={onClick}
          alarmLabel={ sleepDaily?.data?.y_data?.status? sleepDaily?.data?.y_data?.status:""}
          timeGap={sleepDaily?.data?.x_data?  sleepDaily?.data?.x_data + " ago":""}
          renderComponent2={
            <>
            <div style={{margin:"40px 20px 20px 20px", alignItems:"center", justifyContent:"center"}} onClick={onClick}>

                      <div className="" style={{
                          backgroundColor: "#FFB848", borderRadius: "10px", width: "100%", display: "flex", color: "#434242",
                          fontWeight: "600", height: "fit-content", padding:"5px 10px 5px 10px", 
                          alignItems: "center", justifyContent: "center", fontFamily: "'Montserrat', sans-serif",cursor:"pointer"
                      }} >{chartData2()}</div>
                      <div className="" style={{ display: "flex", justifyContent: "space-between", 
                      fontSize: "0.8rem", fontWeight: "600" , fontFamily: "'Montserrat', sans-serif"}}>
                          <span>START TIME</span>
                          <span>END TIME</span>{" "}
                      </div>

                      <div className="" style={{display: "flex", justifyContent: "space-between", 
                      fontSize: "1rem", fontWeight: "600" , fontFamily: "'Montserrat', sans-serif"}}>
                          <span>{moment(sleepDaily?.data?.y_data?.sleep[0]?.sleep_start).format("h:mm a")}</span>
                          <span>{moment(sleepDaily?.data?.y_data?.sleep[0]?.sleep_end).format("h:mm a")}</span>

                      </div>
                  </div>
                
            </>
          }>

        </MainCommonCard>
</>
      )}

  if(chartData.series[0]?.data[0]?.value){
    return (
        <MainCommonCard
        tileTitle={"Sleep"}
        tileColor={isExpired? "transparent" : "white"}
        Option1={
            <PopUpManualEntry
              optionName="MANUAL ENTRY"
              FormTitle="Sleep"
              FormType={<FormSleepAdv />} />
          }
          Option2={<TileRemove option2title={"REMOVE TILE"} parameter={"SLEEP"}/> }
          onClick={onClick}
          alarmLabel={ sleepDaily?.data?.y_data?.status? sleepDaily?.data?.y_data?.status:""}
          timeGap={sleepDaily?.data?.x_data?  sleepDaily?.data?.x_data + " ago":""}
          renderComponent2={
            <>
            <div onClick={onClick} style={{cursor:"pointer"}}>
            <ReactEcharts option={chartData} style={{height:"170px",cursor:"pointer"}}/>
            </div>
    
            </>
          }>

        </MainCommonCard>
    );
  }

  }
  return null ;
} 


export default memo(SleepQuick);
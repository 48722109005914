import React from 'react'
import Headerv2 from './Headerv2'
import BRv2 from './VitalCharts/BRv2'
import Caloriev2 from './VitalCharts/Caloriev2'
import Tempv2 from './VitalCharts/Tempv2'
import Weightv2 from './VitalCharts/Weightv2'
import './HomeTest.css'

function HomeTest() {
  return (
      <>
      <div className='dashboard-main-container-v2'>
        <Headerv2/>
        <div>
            
        </div>

      </div>
      </>
  )
}

export default HomeTest
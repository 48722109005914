import React, { useState, useEffect, useRef } from 'react';
import './Commonv2.css'
import { Close } from '@mui/icons-material';
import CancelIcon from '@mui/icons-material/Cancel';
import { pointer } from 'd3';


const PopupModal = ({ onClose, children }) => {
  const popupRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };

    const handleEscapeKey = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    document.addEventListener('keydown', handleEscapeKey);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [onClose]);

  return (
    <div>
         <div className="overlay visible" >
          <CancelIcon style={{ marginTop:"20px",marginBottom:"-20px",cursor:"pointer", color:"white"}} onClick={onClose}/>
          </div>
        <div className="popup visible">
          
          {/* Your popup content goes here */}

          {children}
        </div>

    </div>
  );
};

export default PopupModal;

import React from "react";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { useHistory } from "react-router-dom";

const BreadcrumbsTop = ({data}) => {
	const history = useHistory()
	const handleClick = (link) => () =>{
		history.push(link)
	}
return (
	<div
	style={{
		marginLeft:"25px",
		marginTop:"10px",
		marginBottom:"0px",
		display: "table",
		cursor:"pointer"
	}}
	>
{/* 	
	<Breadcrumbs aria-label="breadcrumb">
		<Link
		color="inherit"
		href="/"
		onClick={(event) => {
			event.preventDefault();
			
		}}
		>
		Fitbit 
		</Link>
		<Link
		color="inherit"
		href="/heartratepage"
		onClick={(event) => {
			//event.preventDefault();
			
		}}
		>
		heartratepage 
		</Link>
	</Breadcrumbs> */}
	{data.map((item,index)=>{
		if (data?.length === index+1 )
			return(
			<span key={index} onClick={handleClick(item?.link)}>{item.displayName}</span>
			)
		else
			return(
				<React.Fragment>
					<span key={index} onClick={handleClick(item?.link)}>{item.displayName}</span>	
					<ArrowForwardIosIcon style={{
						fontSize:"small",
						color:"blue"
					}}/>
				</React.Fragment>
			)
	})}
	</div>
);
};

export default BreadcrumbsTop;

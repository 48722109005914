import React from 'react'
import { Button } from 'react-bootstrap'
import AquaPrimaryButton from '../../../Common/AquaPrimaryButton'
import styles from "./PatientRegisterPage.module.css"
import { useRef, useState, useEffect } from "react";
import { Route, useHistory } from 'react-router';
import axios from 'axios';
import constants from '../../../Common/Constants.js'
// import axios from './api/axios';


const USER_REGEX = /^[A-z][A-z0-9-_]{3,10}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{6,12}$/;


const REGISTER_URL = `${constants.API_URL}/sign_up/group/`



function Sgindex() {

  const userRef = useRef();
    const errRef = useRef();

    const [user, setUser] = useState('');
    const [validName, setValidName] = useState(false);
    const [userFocus, setUserFocus] = useState(false);

    const [pwd, setPwd] = useState('');
    const [validPwd, setValidPwd] = useState(false);
    const [pwdFocus, setPwdFocus] = useState(false);

    const [matchPwd, setMatchPwd] = useState('');
    const [validMatch, setValidMatch] = useState(false);
    const [matchFocus, setMatchFocus] = useState(false);

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    const history = useHistory() ;

const LoginPage =() =>{
history.push('/home');
}

    useEffect(() => {
      userRef.current.focus();
  }, [])

  useEffect(() => {
      setValidName(USER_REGEX.test(user));
  }, [user])

  useEffect(() => {
      setValidPwd(PWD_REGEX.test(pwd));
      setValidMatch(pwd === matchPwd);
  }, [pwd, matchPwd])

  useEffect(() => {
      setErrMsg('');
  }, [user, pwd, matchPwd])

  var formData=JSON.stringify({id:(user), password:(pwd)});
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if button enabled with JS hack
    const v1 = USER_REGEX.test(user);
    const v2 = PWD_REGEX.test(pwd);


    // if (!v1 || !v2) {
    //     setErrMsg("Invalid Entry");
    //     return;
    // }
    // try {
      // url: `${constants.API_URL}/manual_entry/${activeUserDeviceList}/${token}/`,
      axios({
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        url: `${constants.API_URL}/sign_up/group/`,
        data: formData,
      
      }).then(
        (res) => {
          
        }); 
        // const response = await axios.post(REGISTER_URL,
        //     JSON.stringify({ user, pwd }),
        //     {
        //         headers: { 'Content-Type': 'application/json' },
        //         withCredentials: true
        //     }
        // );
        // 
        // 
        // 
        // setSuccess(true);
        // //clear state and controlled inputs
        // //need value attrib on inputs for this
        // setUser('');
        // setPwd('');
        // setMatchPwd('');
    // } catch (err) {
    //     if (!err?.response) {
    //         setErrMsg('No Server Response');
    //     } else if (err.response?.status === 409) {
    //         setErrMsg('Username Taken');
    //     } else {
    //         setErrMsg('Registration Failed')
    //     }
    //     errRef.current.focus();
    // }
}


  return (
    <div className={styles.container}>
      <div className={styles.innerBox}>
        <div className={styles.left}>
          
          <div className={styles.leftBlur} >
            <div className={styles.leftHeading}>Care HQ<faCheck/></div>
          </div>
        </div>
        <div className={styles.right}>
          <h2> Sign Up</h2>
          
                    <form onSubmit={handleSubmit}>
                        
                        <input
                            type="text"
                            label="USERNAME"
                            placeholder='USERNAME'
                            id="username"
                            ref={userRef}
                            className={styles.field}
                            autoComplete="off"
                            onChange={(e) => setUser(e.target.value)}
                            value={user}
                            required
                            aria-invalid={validName ? "false" : "true"}
                            aria-describedby="uidnote"
                            onFocus={() => setUserFocus(true)}
                            onBlur={() => setUserFocus(false)}
                        />
{/* 
                        <label htmlFor="password">Password:</label> */}
                        <input
                            type="password"
                            id="password"
                            label="PASSWORD"
                            placeholder='PASSWORD'
                            className={styles.field}
                            onChange={(e) => setPwd(e.target.value)}
                            value={pwd}
                            required
                        />

                        
                        <input
                            type="password"
                            id="confirm_pwd"
                            placeholder='CONFIRM PASSWORD'
                            onChange={(e) => setMatchPwd(e.target.value)}
                            value={matchPwd}
                            className={styles.field}
                            required
                            aria-invalid={validMatch ? "false" : "true"}
                            aria-describedby="confirmnote"
                            onFocus={() => setMatchFocus(true)}
                            onBlur={() => setMatchFocus(false)}
                        />
                         <Button type='submit' >Sign Up</Button>
                    </form>
                    <p>
                        Already having an account?<br />
                        <span className="line">
                        
                            <a href="" color='Blue'>LOGIN</a>
                        </span>
                    </p>
          
          {/* <input type='text' className={styles.field} placeholder='Your Phone'></input> */}
        </div>
      </div>
    </div>
  )
}
export default Sgindex
import React,{memo} from 'react'

import ReactEcharts from 'echarts-for-react'
import '../FNewDash.css'

function BRChartPage2({title,xData,yData,handleClick}) {
    
    if(!handleClick)
    {
       
    }
 const chartData=
 React.useMemo(()=>{
    return{
   title:{
    textStyle: {
      fontFamily: "sans-serif"
    },
   },
       
        tooltip:{
          textStyle: {
          fontFamily: "sans-serif"
        },
      },
        
        
        series: [
          {
            name: 'Breathing Rate',
            type: 'gauge',
            min: 0,
            max: 40,
            color:"#6D3DA3",
          
            progress: {
              show: true,
              roundCap: true,
              width: 13
            },
            pointer: {
              show: false
            },
            axisTick: {
              show:false
              },
             
              axisLabel:{
                show:false,
              } ,

              axisLine:{
                roundCap:true
              },
              splitLine:{
                show:false
              },
              
              detail: {
                
                fontSize: 35,
                fontWeight: 'bolder',
                color: '#777',
                lineHeight: 40,
                offsetCenter: [0, '0%'],
                valueAnimation: true,  
                textStyle: {
                  fontFamily: "sans-serif"
                },
                
              },
            itemStyle: {
              
              shadowColor: 'rgba(0,138,255,0.45)',
              shadowBlur: 15,
              shadowOffsetX: 2,
              shadowOffsetY: 2
            },
           
            data: [
                {
                    value: [yData]
                }
            ]
          },
        ]
    }
},[xData,yData])

    
    return (
      <div>
    {chartData.series[0].data.length?
  <div className='br-daily-chart1'><div className='nameBP' style={{fontFamily: "sans-serif"}}>{title}</div>
  <ReactEcharts option={chartData} onEvents={{
      'click': handleClick,
    }}/>
  
  </div>
  : <p style={{fontSize:"20px", fontWeight:"bold"}}>NO DATA AVAILABLE </p>}
  </div>
    )
    }

export default memo(BRChartPage2)
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Pagination from "@material-ui/lab/Pagination";
import HomeDashboard from "./HomeDashboard";
import Header from "../Header/Header";
import axios from "axios";
import constants from "../../Common/Constants";
import { setDashboardApiStatus, setDashboardData } from '../../store/dashboardSlice';
import {
  setGraphMode,
  addPageDevices,
  addPresentPage,
  addRenderInterval,
  setBedDeviceView,
  addDeviceList,
  addGroupDevices,
  addPageDeviceFavorite,
  addNoOfPages,
  addGroupName,addAuthToken,addHeaderGroupName
} from "../../store/authSlice";
import "./Home.css";
import {
  addStatsPageDevices,
  addStatsPresentPage,
  addNoOfStatsPages,
} from "../../store/statsSlice";
import SideDrawer from "../SideDrawer/SideDrawer";
import { useParams } from "react-router-dom";
import {
  setEndReplayTime,
  setSelectedBed,
  setSelectedDeviceId,
  setStartReplayTime,
  setDeviceTypeTabs,
  setSelectedDeviceType,
  setDeviceNames,
} from "../../store/settingsSlice";

import {
  addBedDeviceList,
  addBedList,
  addDeviceSettings,
  addDeviceStreamTokens,
  addDeviceTypeList,
} from "../../store/homeSlice";
import { act } from "@testing-library/react";
import FloatingAddbtn from "./FloatingAddbtn";
import PopUpBP from "../Member Details/Comps/FitbitFolder/FitCharts/PopUpBP";
import PopUpSleep from "../Member Details/Comps/FitbitFolder/FitCharts/PopUpSleep";
import PopupBG from "../Member Details/Comps/FitbitFolder/FitCharts/PopBG";
import FormBG from "../Member Details/Comps/FitbitFolder/FitCharts/FormBG";
import FormHR from "../Member Details/Comps/FitbitFolder/FitCharts/FormHR";
import FormSpO2 from "../Member Details/Comps/FitbitFolder/FitCharts/FormSpO2";
import FormBR from "../Member Details/Comps/FitbitFolder/FitCharts/FormBR";
import FormTemp from "../Member Details/Comps/FitbitFolder/FitCharts/FormTemp";
import FormWT from "../Member Details/Comps/FitbitFolder/FitCharts/FormWT";
import FormStep from "../Member Details/Comps/FitbitFolder/FitCharts/FormStep";
import FormCAL from "../Member Details/Comps/FitbitFolder/FitCharts/FormCAL";
import HeaderCG from "./CareGiverPage/HeaderCG";

function HomePage({
  pageType,
  connectFlag,
  setConnectFlag,
  storeCreatedSockets,
  getCreatedSocket,
  closeCreatedSockets,
  connectReplayFlag,
  setConnectReplayFlag,
}) {
  const dispatch = useDispatch();

  const { id } = useParams();
  const groupName = useSelector((state) => state.auth.groupName);
  const token = useSelector((state) => state.auth.authToken);
  const graphMode = useSelector((state) => state.auth.graphMode);
  const bedDeviceView = useSelector((state) => state.auth.bedDeviceView);
  const dashboardApiStatus =useSelector((state) => state.dashboard.apiStatus)
  const currentUserIndex= useSelector((state) => state.auth.currentUserIndex)
  const userData = useSelector((state) => state.auth.userData)
  const careTaker = useSelector((state) =>state.auth.careTaker) ;

  useEffect(()=>{
        dispatch(addGroupName(userData?.[currentUserIndex]?.group));
        dispatch(addAuthToken(userData?.[currentUserIndex]?.token));
        dispatch(addHeaderGroupName(userData?.[currentUserIndex]?.group_name));
        setConnectFlag(false)
        let deviceList = [],
          pageList = [],
          favoriteList = [],
          statsPageList = [],
          deviceTypeList = [],
          deviceSettingsList = [],
          bedDeviceList = [],
          deviceStreamTokens = [];
        let list = userData?.[currentUserIndex]?.device_list;
        list=[...list].sort((a, b) => (a.device_id > b.device_id ? 1 : -1));
        list=[...list].sort((a, b) => b.is_favorite - a.is_favorite);
        list.map((device) => {
          deviceList.push(device.device_id);
          if (pageList.length < 9 && device.device_type != "fitbit" && device.device_type != "blehub" && device.device_type != "cgm") {
            pageList.push(device.device_id);
          }
          if (statsPageList.length < 12) {
            statsPageList.push(device.device_id);
          }
          if (device.is_favorite) {
            favoriteList.push(device.device_id);
          }
          let deviceDetail = {
            deviceId: device.device_id,
            deviceType: device.device_type,
          };
          deviceTypeList.push(deviceDetail);
          let bedDetail = {
            bedId: device.bed_id,
            deviceId: device.device_id,
            deviceType: device.device_type,
          };
          bedDeviceList.push(bedDetail);
          let deviceSetting = {
            deviceId: device.device_id,
            deviceSetting: device.settings,
          };
          deviceSettingsList.push(deviceSetting);
          if (device.device_type === "vitalcam") {
            let deviceTokens = {
              deviceId: device.device_id,
              deviceToken: device.streaming_token,
            };
            deviceStreamTokens.push(deviceTokens);
          }
        });
        dispatch(addGroupDevices(deviceList));
        dispatch(addPageDevices(pageList));
        dispatch(addStatsPageDevices(statsPageList));
        dispatch(addPageDeviceFavorite(favoriteList));
        dispatch(addDeviceTypeList(deviceTypeList));
        dispatch(addBedDeviceList(bedDeviceList));
        dispatch(addDeviceList(userData?.[currentUserIndex]?.device_list));
        dispatch(addNoOfPages(Math.ceil(deviceList.length / 9)));
        dispatch(addNoOfStatsPages(Math.ceil(deviceList.length / 12)));
        dispatch(addPresentPage(1));
        dispatch(addStatsPresentPage(1));
        dispatch(setDeviceTypeTabs([]));
        dispatch(setSelectedBed("AllDevices"));
        dispatch(setSelectedDeviceType(null));
        dispatch(setDeviceNames([]));
        dispatch(addDeviceSettings(deviceSettingsList));
        dispatch(addDeviceStreamTokens(deviceStreamTokens));
        dispatch(setDashboardApiStatus('fetch'))

        // let bedDetailList = data.beds;
        // let bedFavList = [];
        // bedDetailList.sort((a, b) => (a.bed_id > b.bed_id ? 1 : -1));
        // bedDetailList.sort((a, b) => b.is_favorite - a.is_favorite);
        // bedDetailList.map((bed, key) => {
        //   let bedFavObject = {
        //     bedId: bed.bed_id,
        //     is_favorite: bed.is_favorite,
        //   };
        //   bedFavList.push(bedFavObject);
        // });
        // dispatch(addBedList(bedFavList));
  },[currentUserIndex,userData])

  function findQueryParams(query) {
    let queryParamName;
    let queryParamValue;
    let locationArray = window.location.href.split("/");
    const queryString = locationArray.find((element) => {
      if (element.includes("startTime") && element.includes("endTime")) {
        return true;
      }
    });
    let queryParams = queryString.split("&");
    queryParams.find((queryParam) => {
      if (queryParam.includes("=")) {
        queryParamName = queryParam.split("=")[0];
        if (queryParamName === query) {
          queryParamValue = queryParam.split("=")[1];
        }
      }
    });
    return queryParamValue;
  }
  

  useEffect(() => {

    if (pageType === "stats") {
      
      dispatch(setGraphMode(false));
      dispatch(setBedDeviceView(false));
    }
    if (pageType === "grid") {
      
      dispatch(setGraphMode(true));
      dispatch(setBedDeviceView(false));
    }
    if (pageType === "bed") {
      
      if (id) dispatch(setBedDeviceView(true));
    }
    if (pageType === "device") {
      
      if (id) {
        dispatch(setBedDeviceView(true));
        dispatch(setSelectedDeviceId(id));
      }
    }
    if (pageType === "replayDevice") {
      
      let startTime = findQueryParams("startTime");
      let endTime = findQueryParams("endTime");
      if (id && startTime && endTime) {
        dispatch(setBedDeviceView(true));
        dispatch(setSelectedDeviceId(id));
        dispatch(setStartReplayTime(parseInt(startTime)));
        dispatch(setEndReplayTime(parseInt(endTime)));
      }
    }
    if (pageType === "replayBed") {
      
      let startTime = findQueryParams("startTime");
      let endTime = findQueryParams("endTime");
      if (id && startTime && endTime) {
        dispatch(setBedDeviceView(true));
        dispatch(setSelectedBed(id));
        dispatch(setStartReplayTime(parseInt(startTime)));
        dispatch(setEndReplayTime(parseInt(endTime)));
      }
    }
  }, [pageType]);

  

  //3*3 Graph Dashboard
  const presentGraphPage = useSelector((state) => state.auth.presentPage);
  const noOfGraphPages = useSelector((state) => state.auth.noOfPages);
  const totalDevices = useSelector((state) => state.auth.groupDevices);
  const [count, setCount] = useState(
    useSelector((state) => state.auth.renderInterval)
  );

  // function changeRenderInterval(data) {
  //   setCount(data);
  //   dispatch(addRenderInterval(data));
  // }

  const changeGraphPage = (event, value) => {
    let graphDevicesArray = [],
      count = 1,
      i = 0;
    if (value != 1) {
      i = (value - 1) * 9;
    }
    for (; i < totalDevices.length; i++) {
      if (count <= 9) {
        graphDevicesArray.push(totalDevices[i]);
        count++;
      } else {
        i = totalDevices.length;
      }
    }
    dispatch(addPageDevices(graphDevicesArray));
    dispatch(addPresentPage(value));
  };

  //4*3 Stats Dashboard
  const presentStatsPage = useSelector((state) => state.stats.statsPresentPage);
  const noOfStatsPages = useSelector((state) => state.stats.noOfStatsPages);

  const changeStatsPage = (event, value) => {
    let statsDevicesArray = [],
      count = 1,
      i = 0;
    if (value != 1) {
      i = (value - 1) * 12;
    }
    for (; i < totalDevices.length; i++) {
      if (count <= 12) {
        statsDevicesArray.push(totalDevices[i]);
        count++;
      } else {
        i = totalDevices.length;
      }
    }
    dispatch(addStatsPageDevices(statsDevicesArray));
    dispatch(addStatsPresentPage(value));
  };

  return (
    // <div style={{ display: "flex", backgroundColor: "#4d4e63" }}>
    //   <div style={{ backgroundColor: "#4d4e63" }}>
    //     <SideDrawer />
    //   </div>
    <>
    <div>

      {bedDeviceView ? (
        <HomeDashboard
          bedDeviceView={bedDeviceView}
          pageType={pageType}
          connectFlag={connectFlag}
          setConnectFlag={setConnectFlag}
          getCreatedSocket={getCreatedSocket}
          storeCreatedSockets={storeCreatedSockets}
          closeCreatedSockets={closeCreatedSockets}
          connectReplayFlag={connectReplayFlag}
          setConnectReplayFlag={setConnectReplayFlag}
        />
      ) : null}

      {graphMode && !bedDeviceView ? (
        <div className={!careTaker ? "gridDashboard_container w-100":""}>
          <div className={!careTaker ? "gridDashboard_TopContainer":" "}>
            {!careTaker && (
              <Header data="Stats" closeCreatedSockets={closeCreatedSockets} />
            )}
            {
              careTaker&&(
                null
              )
            }
            
            <div className="gridDashboard_GraphGrid">
              <HomeDashboard
                pageType={pageType}
                graphMode={graphMode}
                connectFlag={connectFlag}
                setConnectFlag={setConnectFlag}
                getCreatedSocket={getCreatedSocket}
                storeCreatedSockets={storeCreatedSockets}
                closeCreatedSockets={closeCreatedSockets}
                setConnectReplayFlag={setConnectReplayFlag}
              />
            </div>
          </div>
          {/* <div className="gridDashboard_GraphPagination">
            <Pagination
              id="pagination"
              count={noOfGraphPages}
              page={presentGraphPage}
              onChange={changeGraphPage}
              size="small"
            />
          </div> */}
        </div>
      ) : null}

      {!graphMode && !bedDeviceView ? (
        <div className="statsDashboard_BodyPanel w-100">
          <Header data="Home" closeCreatedSockets={closeCreatedSockets} />
          <div className="statsDashboard_BodyDiv">
            <HomeDashboard
              pageType={pageType}
              graphMode={graphMode}
              connectFlag={connectFlag}
              setConnectFlag={setConnectFlag}
              getCreatedSocket={getCreatedSocket}
              storeCreatedSockets={storeCreatedSockets}
              closeCreatedSockets={closeCreatedSockets}
              setConnectReplayFlag={setConnectReplayFlag}
            />
          </div>
          {/* <div className="statsDashboard_Pagination">
            <Pagination
              count={noOfStatsPages}
              page={presentStatsPage}
              onChange={changeStatsPage}
              size="small"
            />
          </div> */}
        </div>
      ) : null}
    </div>
    </>
  );
}
export default React.memo(HomePage);
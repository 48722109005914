import React, { useEffect } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import InputAdornment from "@material-ui/core/InputAdornment";
import PermIdentityOutlinedIcon from "@material-ui/icons/PermIdentityOutlined";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  addAuthToken,
  addDeviceList,
  addGroupName,
  addGroupDevices,
  addHeaderGroupName,
  addPageDevices,
  addPageDeviceFavorite,
  addNoOfPages,
  addPresentPage,
  setCareTaker,
  setUserData,
  setCareTakerToken,
} from "../../store/authSlice";
import {
  addNoOfStatsPages,
  addStatsPresentPage,
  addStatsPageDevices,
} from "../../store/statsSlice";
import {
  addBedDeviceList,
  addBedList,
  addDeviceSettings,
  addDeviceStreamTokens,
  addDeviceTypeList,
} from "../../store/homeSlice";
import { withStyles } from "@material-ui/core/styles";
import AquaPrimaryButton from "../../Common/AquaPrimaryButton";
import constants from "../../Common/Constants";
import {
  setDeviceNames,
  setDeviceTypeTabs,
  setSelectedBed,
  setSelectedDeviceType,
} from "../../store/settingsSlice";
import resetReduxState from "../../Common/Logout";
import { Button, Divider } from "@material-ui/core";
import { after } from "lodash";
import BackgroundImage from "./homebg.jpg";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import "./SignIn.css";

import {gapi} from 'gapi-script';
import GLogin from "../home/V2Components/SSO/GLogin";
import { Grid, Typography } from "@mui/material";
import ErrorModal from "../home/V2Components/SSO/Components/ErrorModal";

const client_id = "1042626051183-efk02e24ggt4v8rlu981e4v24i1hpss5.apps.googleusercontent.com"


const WhiteRadioButton = withStyles({
  root: {
    color: "white",
    "&$checked": {
      color: "white",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor:"rgba(255, 255, 255, 0.1)",
    padding:"30px 30px 30px 30px",
    borderRadius:"15px",
    backdropFilter: "blur(30px)"

  },

  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },

  submit: {
    margin: "12px",
  },
  container: {
    width: "100%",
    height: "100vh",
    // backgroundColor: "white",
    // background: `url(${BackgroundImage})`,
    background: "linear-gradient(to right, #49ACA9, #ffff)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    backgroundSize: "cover",
    // backgroundPosition: "center",
    position: "relative",
  },
  blurBackground: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backdropFilter: "blur(6px)",
  },

  textField: {
    // borderBottom: "1px solid black",
    color: "black !important",
    borderRadius: "0px",
    margin: "12px 0",
  },

  formLabel: {
    marginLeft: "0",
    marginRight: "12px",
  },

  loginbtn: {
    backgroundColor: "rgb(14, 68, 244)",
    borderRadius: "20px",
    height: "24px",
    color: "white",
    fontSize: "12px",
    alignItems: "center",
  },

  signInbtn: {
    backgroundColor: "rgb(14, 68, 244)",
    borderRadius: "20px",
    height: "30px",
    width: "100%",
    color: "white",
    fontSize: "12px",
    alignItems: "center",
  },

  version: {
    color: "black",
    clear: "both",
    position: "relative",
  },
}));

export default function SignIn({
  setConnectFlag,
  setConnectReplayFlag,
  closeCreatedSockets,
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const [groupName, setGroupName] = useState("101");
  const [password, setPassword] = useState("passme123");
  const [path, setPath] = useState("home");
  const [errorMessage, setErrorMessage] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleSignIn = () => {
    history.push("/patient_signup_page");
  };

  const handleLoginSubmit = (e) => {
    e.preventDefault();
    let data = { id: groupName, password: password };
    axios({
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      url: `${constants.API_URL}/authenticate_group/`,
      data: data,
    }).then(
      (response) => {
        // 
        setErrorMessage(false);
        const { data = {} } = response;
        let userData = [];

        let careTakerToken = [];
        if (data?.group_list) {
          dispatch(setCareTaker(true));

          careTakerToken = data?.token;
          dispatch(setCareTakerToken(careTakerToken));
          // 

          userData = data?.group_list;
          dispatch(addAuthToken(userData));
        } else {
          let n_data = { ...data };
          n_data["group"] = groupName;
          userData.push(n_data);
        }
       

        dispatch(addGroupName(userData?.[0]?.group));
        setConnectFlag(false);
        setConnectReplayFlag(false);
        dispatch(setUserData(userData));
        dispatch(addAuthToken(userData?.[0]?.token));
        dispatch(addHeaderGroupName(userData?.[0]?.group_name));
        history.push("/" + path);
        console.log("USerData",userData)
      },
      (error) => {
        setErrorMessage(true);
        
      }
    );
  };

  //Removes all the Redux Stored Values and Websockets related to the CareHQ
  useEffect(() => {
    
    resetReduxState(dispatch);
    closeCreatedSockets();
  }, []);

  // const SCOPES = ['https://www.googleapis.com/auth/userinfo.email']

  // useEffect(() => {
  //   function start(){
  //     gapi.client.init({
  //       client_id: client_id,
  //       scope:SCOPES,
  //       cookie_policy: 'single_host_origin',
  //     })
  //   };
  //   gapi.load('client:auth2', start);
  // });

  // function onSignIn(googleUser) {
  //   var profile = googleUser.getBasicProfile();
  //   console.log('ID: ' + profile.getId()); // Do not send to your backend! Use an ID token instead.
  //   console.log('Name: ' + profile.getName());
  //   console.log('Image URL: ' + profile.getImageUrl());
  //   console.log('Email: ' + profile.getEmail()); // This is null if the 'email' scope is not present.
  // }

  return (
    <div>
      <div className={classes.container}>
        <div className={classes.blurBackground}></div>
        <div className="flexitems-center flex-1">
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
              <h1 style={{ fontSize: "32px" }}>
                <span style={{ color: "white" }}>Care</span>
                <span style={{ color: "green" }}>HQ</span>
              </h1>

              <form
                className={classes.form}
                noValidate
                onSubmit={handleLoginSubmit}
              >
                <TextField
                  variant="standard"
                  id="standard-start-adornment"
                  placeholder="Group Name"
                  autoComplete="off"
                  fullWidth
                  defaultValue="101"
                  onChange={(e) => setGroupName(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockOutlinedIcon style={{ color: "#545459" }} />
                      </InputAdornment>
                    ),
                    className: classes.textField,
                  }}
                />

                <div style={{float:"left",width:"100%",position:"relative"}}>
                  <TextField
                   
                    variant="standard"
                    required
                    fullWidth
                    name="password"
                    placeholder="Password"
                    type={passwordVisible ? "text" : "password"}
                    id="password"
                    defaultValue="passme123"
                    autoComplete="current-password"
                    onChange={(e) => setPassword(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PermIdentityOutlinedIcon
                            style={{ color: "#545459" }}
                          />
                        </InputAdornment>
                      ),
                      className: classes.textField,
                    }}
                  />
                  <span
                    style={{
                      position: "absolute",
                      top:18,
                     right:0,
                     cursor:"pointer"
                    }}
                  >
                    {" "}
                    <button
                    type="button"

                      style={{  background: "none",
                      color:"#545459",
                        border: "none",
                        cursor: "pointer",
                        outline: "none",
                        marginLeft: "-30px" }}
                      onClick={togglePasswordVisibility}
                    >
                      {passwordVisible ? <VisibilityIcon style={{height:"20px", width:"20px"}}/> : <VisibilityOffIcon style={{height:"20px", width:"20px"}}/>}
                    </button>
                  </span>
                </div>

                <FormControl
                  style={{
                    color: "white",
                    width: "100%",
                    marginBottom: "12px",
                  }}
                  component="fieldset"
                >
                  <RadioGroup
                    onChange={(e) => setPath(e.target.value)}
                    row
                    aria-label="position"
                    name="position"
                    defaultValue="home"
                    className="flexitems-center"
                  >
                    {/* <FormControlLabel
                      value="home"
                      control={<WhiteRadioButton />}
                      label="Graph"
                      labelPlacement="start"
                      className={classes.formLabel}
                    /> */}

                    {/* <FormControlLabel
                      value="stats"
                      control={<WhiteRadioButton />}
                      label="Stats"
                      labelPlacement="start"
                      className={classes.formLabel}
                    /> */}
                  </RadioGroup>
                </FormControl>

                {errorMessage && (
                  <p
                    style={{
                      color: "red",
                      marginTop: "0",
                      textAlign: "center",
                    }}
                  >
                    Login Failed! Invalid ID or Password 
                  </p>
                )}

                <Button
                  className={classes.signInbtn}
                  color="white"
                  type="submit"
                  buttonlabel="Sign In"
                >
                  SIGN IN
                </Button>

                <Grid container alignItems="center" style={{ marginTop: "10px" }}>
  <Grid item xs>
    <Divider style={{ height: "1px", backgroundColor: "black" }} />
  </Grid>
  <Grid item>
    <Typography variant="caption" style={{ margin: "0 10px" }}>OR</Typography>
  </Grid>
  <Grid item xs>
    <Divider style={{ height: "1px", backgroundColor: "black" }} />
  </Grid>
</Grid>

                <div style={{ marginTop:"10px"}}>
                  <GLogin/>
                </div>
                
              </form>
              
              <div>
                {" "}
                <p
                  style={{
                    color: "black",
                    marginTop: "10px",
                    textAlign: "center",
                  }}
                >
                  Don't have an account ?{" "}
                  <Button className={classes.loginbtn} onClick={handleSignIn}>
                    Sign Up
                  </Button>
                </p>
              </div>
            </div>
          </Container>
        </div>
        <div className={classes.version}>
          <p className="smallText">
            Version: 1.0
            {/* Version: CI-179 &nbsp;&nbsp;&nbsp; Date: 01/12/2021 */}
          </p>
        </div>
      </div>
    </div>
  );
}

import * as React from "react";
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import SettingsIcon from "@material-ui/icons/Settings";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import constants from "../../../Common/Constants.js";
import "../../Header/TopToolBar.css";
import resetReduxState from "../../../Common/Logout";
import { PlayCircleOutlineRounded } from "@material-ui/icons";
import { showReplayModal } from "../../../store/settingsSlice";
import PeopleIcon from "@material-ui/icons/People";
import DevicesIcon from "@material-ui/icons/Devices";
import FitbitIcon from "@mui/icons-material/Fitbit";
import FormFitbit from "../../Member Details/Comps/FitbitFolder/FitCharts/FormFitbit";
import BloodtypeIcon from "@mui/icons-material/Bloodtype";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import VaccinesIcon from "@mui/icons-material/Vaccines";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import PanToolAltIcon from "@mui/icons-material/PanToolAlt";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

function RightTopIconHeader(props, careTakerPatientView = false) {
  const dispatch = useDispatch();
  const history = useHistory();

  const { id, patient_group_id } = useParams();

  const token = useSelector((state) => state.auth.authToken);
  const headerGroupName = useSelector((state) => state.auth.headerGroupName);
  const careTaker = useSelector((state) => state.auth.careTaker);
  const groupName = useSelector((state) => state.auth.groupName);
  const [deviceTypes, setDeviceTypes] = useState([]);
  const [userAvatar, setUserAvatar] = useState(null); // New state variable

  //Find the initials to be displayed in the Header
  const [userInitials, setUserInitials] = useState("");
  function getInitials(headerName) {
    let firstLetters = headerName
      ?.split(" ")
      .map((word) => word[0])
      .join("");
    let initials = firstLetters?.toUpperCase().slice(0, 2);
    return initials;
  }

  const fetchProfile = async () => {
    const response = await fetch(
      `${constants.API_URL}/view_profile/${groupName}/${token}`
    );
    const data = await response.json();
    return data;
  };

  useEffect(() => {
    axios
      .get(`${constants.API_URL}/ble_devices/${groupName}/${token}/`)
      .then((res) => {
        setDeviceTypes(
          res.data.device_list.map((device) => device.device_type)
        );
        console.log("blee: ", res.data.device_list);
      });
  }, [groupName, token]);

  function getDeviceIcon(deviceType) {
    switch (deviceType) {
      case "linktop":
        return (
          <MonitorHeartIcon fontSize="small" style={{ color: "#6c757d" }} />
        );
      case "AND":
        return <BloodtypeIcon fontSize="small" style={{ color: "#6c757d" }} />;
      case "pill_dispenser":
        return <VaccinesIcon fontSize="small" style={{ color: "#6c757d" }} />;
      case "accucheck":
        return <TouchAppIcon fontSize="small" style={{ color: "#6c757d" }} />;
      case "one_touch":
        return <TouchAppIcon fontSize="small" style={{ color: "#6c757d" }} />;
      case "omron":
        return <BloodtypeIcon fontSize="small" style={{ color: "#6c757d" }} />;
      case "green_ocean":
        return <PanToolAltIcon fontSize="small" style={{ color: "#6c757d" }} />;
      case "withings":
        return <FavoriteBorderIcon fontSize="small" style={{ color: "#6c757d" }} />;
      default:
        return null;
    }
  }

  useEffect(() => {
    fetchProfile()
      .then((data) => {
        console.log("prodata", data);
        if (data?.profile_data?.image != null) {
          setUserAvatar(data?.profile_data?.image); // Set the user's avatar image if available
        } else {
          let initials = getInitials(headerGroupName);
          setUserInitials(initials);
        }
      })
      .catch((error) => {
        console.error("Error fetching profile data:", error);
      });
  }, [headerGroupName]);

  let data;
  const [errorMessage, setErrorMessage] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [popperAnchorEl, setPopperAnchorEl] = React.useState(null);
  const popperOpen = Boolean(popperAnchorEl);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handlePopperClose = () => {
    setPopperAnchorEl(null);
  };
  const [showPopup, setShowPopup] = useState(false);

  function togglePopup() {
    setShowPopup(!showPopup);
  }

  const Withings = () => {
    window.location.href = "https://account.withings.com/oauth2_user/authorize2?response_type=code&client_id=306f1169f65ae3dcec176402e2769ce49f4e2aeeeb789273e3fefa3eeea5eabb&scope=user.info,user.metrics,user.activity&redirect_uri=https://carehq.aimed.care/authenticate_withings/&state=b3000fef8a19ab56ed8f671d97427a21264f583242f48c5b837fa4f56a921109";
  }

  const handleLogout = (event) => {
    setAnchorEl(null);
    axios({
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      url: `${constants.API_URL}/logout_user/${token}/`,
      data: data,
    }).then(
      (response) => {
        history.push("/");
        console.log("Logout successful");
        setAnchorEl(anchorEl ? null : event.currentTarget);
        resetReduxState(dispatch);
        // props.closeCreatedSockets();
        console.log("Logout successful");
      },
      (error) => {
        history.push("/");
        setErrorMessage(true);
        resetReduxState(dispatch);
        // props.closeCreatedSockets();
        console.log("Logout not proper");
      }
    );
  };

  //Navigate Back to Care taker screen
  const handleBackToCareTaker = (event) => {
    setAnchorEl(null);
    setAnchorEl(anchorEl ? null : event.currentTarget);
    history.push("/home");
  };

  //Navigate to My Profile Page
  const handleMyProfile = (event) => {
    setAnchorEl(null);
    setAnchorEl(anchorEl ? null : event.currentTarget);
    history.push("/myprofile");
  };
  //Navigate to Settings Page
  const handleSettings = (event) => {
    setAnchorEl(null);

    setAnchorEl(anchorEl ? null : event.currentTarget);
    history.push("/settings");
  };

  //Opens Replay Modal
  const openReplayModal = (event) => {
    setAnchorEl(null);
    setAnchorEl(anchorEl ? null : event.currentTarget);
    dispatch(showReplayModal(true));
  };

  const handleRegisterClick = (event) => {
    setPopperAnchorEl(event.currentTarget);
  };



  return (
    <div style={{ cursor: "pointer" }}>
      <Box
        onClick={handleMenuClick}
        sx={{ display: "flex", alignItems: "center", textAlign: "center" }}
      >
        <IconButton className="userAvatar" size="small" sx={{ ml: 2 }}>
          {careTakerPatientView && careTaker && <Avatar />}
          {!careTaker && userAvatar ? ( // Check if userAvatar exists
            <Avatar src={`https://carehq-backend.aimed.care${userAvatar}`} /> // Display the user's avatar image
          ) : (
            <Avatar style={{ fontFamily: "Montserrat,  san-serif" }}>
              {userInitials}
            </Avatar>
          )}
        </IconButton>

        {careTakerPatientView && careTaker && (
          <Typography style={{ fontFamily: "Montserrat,  san-serif" }}>
            {patient_group_id}
          </Typography>
        )}

        {!careTaker && (
          <Typography style={{ fontFamily: "Montserrat,  san-serif" }}>
            {headerGroupName}
          </Typography>
        )}

        <IconButton className="userDropDown" size="small" sx={{ ml: 2 }}>
          <ArrowDropDownIcon style={{ color: "#6c757d", marginLeft: 0 }} />
        </IconButton>
      </Box>

      <Menu
        id="LoggedUser"
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <MenuItem
          className="normalText minHeight-md-0"
          onClick={handleMyProfile}
        >
          <ListItemIcon>
            <AccountCircleIcon fontSize="small" style={{ color: "#6c757d" }} />
          </ListItemIcon>
          My Profile
        </MenuItem>
        <Divider />

        <MenuItem
          className="normalText minHeight-md-0"
          onClick={handleRegisterClick}
        >
          <ListItemIcon>
            <DevicesIcon fontSize="small" style={{ color: "#6c757d" }} />
          </ListItemIcon>
          Devices
        </MenuItem>

        <Divider />

        {!careTaker && (
          <MenuItem
            className="normalText minHeight-md-0"
            onClick={(e) => handleLogout(e)}
          >
            <ListItemIcon>
              <ExitToAppIcon fontSize="small" style={{ color: "#6c757d" }} />
            </ListItemIcon>
            Logout
          </MenuItem>
        )}

        {careTakerPatientView && careTaker && (
          <MenuItem
            className="normalText minHeight-md-0"
            onClick={(e) => handleBackToCareTaker(e)}
          >
            <ListItemIcon>
              <ExitToAppIcon fontSize="small" style={{ color: "#6c757d" }} />
            </ListItemIcon>
            Back to CareTaker
          </MenuItem>
        )}
      </Menu>
      <Menu
        id="LoggedUser"
        anchorEl={popperAnchorEl}
        open={popperOpen}
        onClose={handlePopperClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem className="normalText minHeight-md-0" onClick={togglePopup}>
          <ListItemIcon>
            <FitbitIcon fontSize="small" style={{ color: "#6c757d" }} />
          </ListItemIcon>
          Fitbit
        </MenuItem>
        <MenuItem className="normalText minHeight-md-0" onClick={Withings} >
          <ListItemIcon>
            <FavoriteBorderIcon fontSize="small" style={{ color: "#6c757d" }} />
          </ListItemIcon>
          Withings
        </MenuItem>
        {deviceTypes.map((deviceType, index) => (
          <div key={index}>
            <Divider />
            <MenuItem className="normalText minHeight-md-0">
              <ListItemIcon>{getDeviceIcon(deviceType)}</ListItemIcon>
              {deviceType}
            </MenuItem>
          </div>
        ))}

        {showPopup ? (
          <div
            style={{
              position: "absolute",
              top: "40%",
              right: "-30%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              padding: "10px",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
              borderRadius: "5px",
            }}
          >
            <> {<FormFitbit onClose={() => setShowPopup(false)} />} </>
          </div>
        ) : null}
        <Divider />
      </Menu>
    </div>
  );
}

export default RightTopIconHeader;

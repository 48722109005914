import React from 'react'
import styles from "./HRLowHigh.module.css"
import FavoriteOutlinedIcon from "@material-ui/icons/FavoriteOutlined";

export default function HRLowHigh({heartRate,type="High"}) { 
    let text = `${type} Heart Rate Count : ${heartRate}`
  return (
    <span className={styles.container}>
        <FavoriteOutlinedIcon style={{ color: "#FF407B" }}/>
        <div>{text}</div>
    </span>
  )
}

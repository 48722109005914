import React,{useState} from "react";
import "../FNewDash.css";
import { Row, Col } from "reactstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import { useEffect } from "react";
import axios from 'axios';
import {useSelector } from "react-redux"
import constants from '../../../../../../Common/Constants';

import DisChartContainer from './DisChartContainer';
import { format, formatRelative, subDays, endOfDay} from 'date-fns'
import BreadcrumbsTop from '../BreadcrumbsTop'

const breadCrumbData = [
  {
    displayName:"Home",
    link:"/home"
  },
  {
    displayName:"DistancePage",
    link:"/distancepage"
  }
]


const DisMainPage = () => {
  window.scrollTo(0, 0);
  const [tabValue,setTabValue] = useState("week")
  const [monthData,setMonthData] = useState({xData:[],yData:[]})
  const [weekData,setWeekData] = useState({xData:[],yData:[]})
  const [threeMonthData,setThreeMonthData] = useState({xData:[],yData:[]})
  const [yearData,setYearData] = useState({xData:[],yData:[]})

  const groupName = useSelector((state) => state.auth.groupName);
  const token = useSelector((state) => state.auth.authToken);
  
  const dateHR=format(endOfDay(new Date()), 'ddMMyyy')
  


  useEffect(() =>{
    if(tabValue === "week" &&!(weekData?.xData?.length)){
      axios
        .get(`${constants.API_URL}/week_data/DIS/${groupName}/${dateHR}/${token}/`)
        .then(res => {
         
          let x= []
          let y = []
          res.data.week_data?.forEach(data => {
            x.push(data.x_data)
            y.push(data.y_data)
          });
          
          
          setWeekData({xData:x,yData:y})
        
        })

    }
    else if(tabValue === "month" &&!(monthData?.xData?.length)){
      axios
      .get(`${constants.API_URL}/month_data/DIS/${groupName}/${dateHR}/${token}/`)
      .then(res => {
      
          let x= []
          let y = []
          res.data.month_data?.forEach(data => {
            x.push(data.x_data)
            y.push(data.y_data)
          });
         

          setMonthData({xData:x,yData:y})
      
      })
    }
    else if(tabValue === "threeMonth" &&!(threeMonthData?.xData?.length)){
        axios
      .get(`${constants.API_URL}/three_month_data/DIS/${groupName}/${dateHR}/${token}/`)
      .then(res => {
       
        let x= []
        let y = []
        res.data.three_month_data?.forEach(data => {
          x.push(data.x_data)
          y.push(data.y_data)
        });
       

        setThreeMonthData({xData:x,yData:y})
      
      })

    }
    else if(tabValue === "year" &&!(yearData?.xData?.length)){
        axios
      .get(`${constants.API_URL}/year_data/DIS/${groupName}/${dateHR}/${token}/`)
      .then(res => {
       
       
        let x= []
        let y = []
        res.data.year_data?.forEach(data => {
          x.push(data.x_data)
          y.push(data.y_data)
        });
       

        setYearData({xData:x,yData:y})
      
      })

    }

  },[tabValue])

  const handleChange = (value) => {
   
    setTabValue(value)
  };
  return (
    <div className="backH">
      <div className="container-dashH">
      <BreadcrumbsTop data={breadCrumbData}/>
        <Tabs
          defaultActiveKey="week"
          activeKey={tabValue}
          transition={false}
          id="noanim-tab-example"
          className="mb-3"
          onSelect={handleChange}
        >
          <Tab eventKey="week" title="WEEK">
            <Row>
              <Col>
              <DisChartContainer title="Weekly Report" xData={weekData?.xData} yData={weekData?.yData} type="week" />
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="month" title="MONTH">
          <Row>
              <Col>
              <DisChartContainer title="Monthly Report" xData={monthData?.xData} yData={monthData?.yData} type="week" />
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="threeMonth" title="3MONTHS">
            <Row>
              <Col>
              <DisChartContainer title="3 Months Report" xData={threeMonthData?.xData} yData={threeMonthData?.yData} type="week"/>
              </Col>
            </Row>
          </Tab>
          
        </Tabs>
      </div>
    </div>
  );
};





export default DisMainPage
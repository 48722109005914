import React, { useState } from 'react'
import {GoogleLogin} from 'react-google-login'
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import axios from 'axios';
import { FaGooglePlus } from 'react-icons/fa';
import constants from '../../../../Common/Constants';
import { useDispatch } from "react-redux";
import {
  addAuthToken,
  addDeviceList,
  addGroupName,
  addGroupDevices,
  addHeaderGroupName,
  addPageDevices,
  addPageDeviceFavorite,
  addNoOfPages,
  addPresentPage,
  setCareTaker,
  setUserData,
  setIsDoctor,
  setCareTakerToken,
} from "../../../../store/authSlice";
import { useHistory } from 'react-router-dom';
import GoogleButton from 'react-google-button';
import { Alert, Button } from '@mui/material';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faGoogle} from '@fortawesome/free-solid-svg-icons';
import { FcGoogle } from 'react-icons/fc';
import { error } from 'jquery';
import ErrorModal from './Components/ErrorModal';
import { Snackbar } from '@material-ui/core';

const clientID = "1042626051183-efk02e24ggt4v8rlu981e4v24i1hpss5.apps.googleusercontent.com";

const useStyles = makeStyles((theme) => ({
  googlebtn: {
    margin:"10px 10px 10px 10px !important",
    // borderRadius:"20px !important",
    "& .MuiButton-startIcon": {
      borderRadius: "20px !important",
      height:"15px !important",
      "& > *": {
        borderRadius: "20px !important",
      },
    },
    "&.MuiButton-contained": {
      borderRadius: "20px !important",
      height:"15px !important",
    },
    "&.MuiButton-containedPrimary": {
      borderRadius: "20px !important",
      height:"15px !important",
    },
    "& button.MuiButton-contained.MuiButton-containedPrimary": {
      borderRadius: "20px !important",
      height:"15px !important",
    },
  },
})
)

function GLogin({}) {
 
  const classes = useStyles();

  const dispatch = useDispatch();
  const history = useHistory();
  const [groupName, setGroupName] = useState([]);
  const [path, setPath] = useState("home");
  const [openDialog,setOpenDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarColor, setSnackbarColor] = useState('');
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

const responseGoogleSuccess = (response) => {
  const { tokenId, profileObj } = response;
    console.log("Successfull",response);
    // Send user data to the backend
    const data = {
      idToken: tokenId,
      email: profileObj.email,
      name: profileObj.name,
      // dob: profileObj.birthday,
      image: profileObj.imageUrl,
    };
    console.log("dataSSO",data);

    console.log("email", profileObj.email)
    console.log("tokenID", tokenId)
    
    var ssoData=JSON.stringify({email:(profileObj.email), sso_token:(tokenId)});
    console.log(ssoData)

    
    axios({
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      url: `${constants.API_URL}/sso_login/`,
      data: ssoData,
      
    }).then((response) =>{
              console.log("SignIn Response",response);
        setErrorMessage(false);
        const userData = []; 

      if (response?.status === 200) {
        userData.push(response.data);
        console.log(userData);
        dispatch(addGroupName(userData?.[0]?.id));
        dispatch(setUserData(userData));
        dispatch(addAuthToken(userData?.[0]?.token));
        dispatch(addHeaderGroupName(userData?.[0]?.group_name));
        dispatch(setIsDoctor(userData?.[0]?.is_doctor));
        history.push("/" + path);
      }else{
        setErrorMessage(true);
        setSnackbarOpen(true);   
        setSnackbarMessage("Something went wrong");
        setSnackbarColor("error")
       }

      }).catch(error =>{
      if(error.response.status === 400){
        setErrorMessage(true);
        setSnackbarOpen(true);   
        setSnackbarMessage(error.response.data.message);
        setSnackbarColor("error")
      }
      setErrorMessage(true);
      setSnackbarOpen(true);   
      setSnackbarMessage(error.response.data.message);
      setSnackbarColor("error")
      console.log("error")
      console.log(error.response.status)

      console.log(error.response.data);
      // show error message to user
      // for example:
      // alert("Login failed: " + error.response.data.message);
    })
      

  
    // fetch('/api/auth/google', {
    //   method: 'POST',
    //   headers: { 'Content-Type': 'application/json' },
    //   body: JSON.stringify(data),
    // })
    //   .then((res) => res.json())
    //   .then((data) => console.log(data))
    //   .catch((err) => console.error(err));
  };

  const responseGoogleFailure = (response) => {
    console.log("data 2",response);
    // alert("Login Failed");
  }

  // googleLoginFunction = (
    
  // )

  const vertical = "bottom"; // Specify the desired vertical position (e.g., "top", "bottom")
  const horizontal = "center"; 

  return (
   
   <> 
    <GoogleLogin
      clientId={clientID}
      onSuccess={responseGoogleSuccess}

      render={renderProps => (
        <Button
          label='Login with Google'
          onClick={renderProps.onClick}
          disabled={renderProps.disabled}
          style={{
            width: "fit-content",
            height: "fit-content",
            borderRadius: "20px",
            margin:"2px",
            background: "white",
            color: "#4285F4",
            fontSize: "11px",
            // fontWeight:"500px",
            fontFamily:"'Montserrat', sans-serif",
            alignItems: "center",
            transition: "all 0.2s ease-out",
            textTransform: "uppercase",
            padding: "5px 10px 5px 10px",
            border: "transparent",
            transition: "all 0.2s ease-out",
            boxShadow: "0px 4px 8px 0px rgba(0,0,0,0.3)",
            
          }}
          disableElevation
        >
          <FcGoogle size={"20px"} style={{ marginRight: "5px" }} />
           GOOGLE
        </Button>
      )}
      
      onFailure={responseGoogleFailure}
      buttonText={"Sign in with Google"}
      cookiePolicy={'single_host_origin'}
      prompt="select_account"
      isSignedIn={false}
    // className={classes.googlebtn}
    />
    {/* {errorMessage && (
                  <p
                    style={{
                      color: "red",
                      marginTop: "5px",
                      textAlign: "center",
                      fontFamily:"'Montserrat', san-serif",
                      fontSize:"10px",
                      fontWeight:"500"
                    }}
                  >
                    This Email ID is not registered
                  </p>
                )} */}

{ errorMessage&& <Snackbar
        anchorOrigin={{ vertical, horizontal }}
          open={snackbarOpen}
          autoHideDuration={4000}
          onClose={() => setSnackbarOpen(false)}
          message={snackbarMessage}
          key={vertical + horizontal}
        >
          <Alert severity={snackbarColor} sx={{ width: '100%' }}>
              {snackbarMessage}
          </Alert>
        </Snackbar>
}
</>
  )
}

export default GLogin


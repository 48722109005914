import { createSlice } from '@reduxjs/toolkit';

const statsSlice = createSlice({
  name: 'auth',
  initialState: {
    noOfStatsPages:'',
    statsPresentPage:'',
    statsPageDevices: [],
  },
  reducers: {
    addNoOfStatsPages(state, action){
      state.noOfStatsPages = action.payload;
    },
    addStatsPresentPage(state, action){
      state.statsPresentPage = action.payload;
    },
    addStatsPageDevices(state, action){
      state.statsPageDevices = action.payload;
    },
  },
});

export const {
  addNoOfStatsPages,
  addStatsPresentPage,
  addStatsPageDevices,
} = statsSlice.actions;

export default statsSlice.reducer;

import React,{memo} from 'react'
import ReactEcharts from 'echarts-for-react';
import '../../FitCharts/FNewDash.css'


function DisChartPage1({title,xData,yData,handleClick}) {
  
    if(!handleClick)
    {
    
    }

  const chartData =React.useMemo(()=>{
    return {
      title: {
        left: 'center',
        text: title,
        textStyle: {
          fontFamily: "sans-serif"
        }
      },
      
      tooltip: {
          trigger: 'axis',
          textStyle: {
            fontFamily: "sans-serif"
          },
          axisPointer: {
            type: 'shadow'
          }
        },
    
      xAxis: {
        type: 'category',
      
        data: xData,
        axisLabel: {
              
          fontFamily:'sans-serif',
        }
      },
      yAxis: {
        type: 'value',
        axisLabel: {
              
          fontFamily:'sans-serif',
        }
     
      },
      
      series: [
        {
          name: 'Distance in meter ',
          type: 'bar',
          areaStyle: {},
              
          symbol: 'none',
          
         
          color: '#27A9E3',
       
          
          data: yData
        }
      ]}
  },[xData,yData,title])





return ( 
  <div>
    {chartData?.xAxis?.data?.length?
  <div className='left-chart'>
  <ReactEcharts 
    option={chartData} 
    onEvents={{
      'click': handleClick,
    }}
  />
  <div className='area-name'></div>
  </div>
   :<p>...</p>}
  </div>
)
}


export default memo(DisChartPage1)

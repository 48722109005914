import React, { memo } from "react";
import { useState, useEffect } from "react";
import ReactEcharts from "echarts-for-react";
import "./FNewDash.css";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { format, formatRelative, subDays, endOfDay } from "date-fns";
import BreathDefault from "./DefaultCharts/BreathDefault";
import DistanceDefault from "./DefaultCharts/DistanceDefault";
import ExpandMorePopup from "../../../../../Common/ExpandMorePopup";
import PopupBG from "./PopBG";

import FormTemp from "./FormTemp";

import FormCAL from "./FormCAL";
import FormCalorie from "./FormCalorie";
import FormBG from "./FormBG";
import TileRemove from "../../../../home/TileRemove";


function CalorieChartDaily({ handleClose,onClick,careTakerPatientView=false }) {
  const groupName = useSelector((state) => state.auth.groupName);
  const token = useSelector((state) => state.auth.authToken);

  const dateHR = format(endOfDay(new Date()), "ddMMyyy");
  const [xAxisData, setXData] = useState([]);

  const calorieDaily = useSelector((state) => state.dashboard.calorieDaily);

  const dispatch = useDispatch();

  const chartData = React.useMemo(() => {
    return {
      title: [
        {
          text: "Calorie",

          left: "2%",
          top: "9%",
          textStyle: {
            fontWeight: "normal",
            fontSize: 23,
            color: "black",
          },
        },
        {
          subtext: calorieDaily?.time_gap + " ago",
          left: "right",
          top: "26%",
          fontSize: 12,
        },
        {
        subtext: "CAL",
        left: "71%",
        top: "15%",
        fontSize: 14,
        }
      ],
      tooltip: {
        textStyle: {
          fontFamily: "sans-serif",
        },
      },

      series: [
        {
          type: "gauge",
          radius: "30%",
          center: ["75%", "15%"],
          guageCategoryGap: "20%",
          startAngle: 360,
          endAngle: 0,

          min: false,
          max: 1,
          color: " red",
          splitNumber: 12,
          itemStyle: {
            color: "#FFC750",

            shadowColor: "blue",
            shadowBlur: 0,
            shadowOffsetX: 0,
            shadowOffsetY: 0,
          },
          progress: {
            show: true,
            width: 8,
          },
          pointer: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },

          title: {
            show: false,
          },
          detail: {
            lineHeight: 0,
            height: 0,
            fontSize: 20,
            fontWeight: "normal",
            color: " #434242",

            borderRadius: 8,
            offsetCenter: [0, "5%"],
            valueAnimation: true,
          },
          data: [
            {
              value: calorieDaily?.total,
            },
          ],
        },
      ],
    };
  }, [calorieDaily]);

  if (
    !chartData.series[0].data[0].value ||
    chartData.series[0].data[0].value === "NaN"
  )
    return null;

  return (
    
    <div>
        <div className="side-chart1" >
        
        <div className="popup-bg" style={{display:'flex', justifyContent: 'right'}}>
          <ExpandMorePopup 
          option1={<PopupBG optionName="Manual Entry" FormTitle="Calorie" FormType={<FormCalorie handleClose={handleClose} careTakerPatientView={careTakerPatientView}/>} />}
          option2={<TileRemove
            careTakerPatientView={careTakerPatientView}
            parameter="CAL" />}  /></div>

          <ReactEcharts option={chartData} />{" "}
      </div>
    </div>
  );
}

export default memo(CalorieChartDaily);


import React, { useEffect, useState } from 'react'
import ExpandMorePopup from '../../../../Common/ExpandMorePopup'
import { Divider } from '@mui/material';
import { useSelector } from 'react-redux';
import OptionPopUp from '../OtherComponents/OptionPopUp';
import PopUpManualEntry from '../OtherComponents/PopUpManualEntry';
import FormBP from '../../../Member Details/Comps/FitbitFolder/FitCharts/FormBP';
import TileRemove from '../Featuresv2/TileRemove';

function BPChartsv2({onClick}) {

  const [isExpired, setIsExpired] = useState(false);

  const bpDailySys = useSelector((state) => state.dashboard.bpDailySys);
  const BPsysDaily = bpDailySys?.data?.y_data?.BPS
  console.log("BPS", BPsysDaily)

  const bpDailyDia = useSelector((state) => state.dashboard.bpDailyDia);
  const BPdiaDaily = bpDailyDia?.data?.y_data?.BPD
  console.log("BPD", BPdiaDaily)

  const alarmStatus= bpDailySys?.data?.y_data?.status_point

  const timeGapIndication = bpDailyDia?.data?.x_data?.time_gap
  // console.log(timeGapIndication)

  useEffect(() => {
    // Check if the time gap contains the words "day" or "days"
    if (timeGapIndication?.includes("day") || timeGapIndication?.includes("days")) {
      setIsExpired(true);
    }
    else{
      setIsExpired(false);
    }
  }, [timeGapIndication,bpDailySys]);
  console.log(isExpired)

  const stylesFont = {
    background: '-webkit-linear-gradient(#fa9191,#a0c3f8)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontSize: '3rem',
    fontWeight:'500',
    borderRadius: '10px',
  };

  if (!BPdiaDaily){
    return null;
  }
  

  return (
    <div className={"BPv2-main"+(' alarm-status-'+alarmStatus)} style={{ backgroundColor:isExpired ? "transparent" : "white", minHeight: "200px" , display:"flex"}}>
      <div style={{ display: "flex", flexDirection: "column" , width:"100%"}}>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginLeft: "10px", marginRight: "2px"}}>
          <div style={{ fontSize: "1.2rem", fontFamily: "'Montserrat', sans-serif", fontWeight: "500" }}>Blood Pressure</div>
          <div> <OptionPopUp option1={<PopUpManualEntry
          
          optionName="MANUAL ENTRY" 
          FormTitle="Blood Pressure" 
          FormType={<FormBP/>}/>} 
          option2={<TileRemove option2title={"REMOVE TILE"} parameter={"BP"}/> }/></div>
        </div>
        <div onClick={onClick} style={{cursor:"pointer"}}>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", fontFamily: "'Montserrat', sans-serif", margin:"10% 10% 10% 10%",minHeight:"90px"}}>
          <div style={{margin:"10px 10px 10px 10px"}}>
            <div style={{ fontFamily: "'Montserrat', sans-serif", fontSize: "2rem", fontWeight: "550" }}>{BPsysDaily? BPsysDaily:""}</div>
            <div style={{ fontFamily: "'Montserrat', sans-serif", fontSize: "0.8rem", fontWeight: "600" }}>SYS</div>
            <div><Divider style={{height:"5px", borderRadius:"20px", color:"#fa9191"}}/></div>
          </div>

          <div style={stylesFont}>|</div>
          
          <div style={{margin:"10px 10px 10px 10px"}}>
          <div style={{ fontFamily: "'Montserrat', sans-serif", fontSize: "2rem", fontWeight: "550" }}>{BPdiaDaily? BPdiaDaily:""}</div>
            <div style={{ fontFamily: "'Montserrat', sans-serif", fontSize: "0.8rem", fontWeight: "600" }}>DIA</div>
            <div><Divider style={{height:"5px", borderRadius:"20px", color:"#a0c3f8"}}/></div>
          </div>
        </div>
        </div>

        <div style={{ width: "100%", height: "100%" }}>

        </div>
        

        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "self-end", marginLeft: "7px", marginRight: "7px", marginTop: "0px", fontSize: "0.75rem", fontFamily: "'Montserrat', sans-serif" }}>
          <div style={{ fontFamily: "'Montserrat', sans-serif" }}>
            {bpDailyDia?.data?.y_data?.status? bpDailyDia?.data?.y_data?.status:""}
          </div>
          <div style={{ fontFamily: "'Montserrat', sans-serif" }}>
            {bpDailyDia?.data?.x_data?.time_gap? bpDailyDia?.data?.x_data?.time_gap + " ago" :""}
          </div>

        </div>
      </div>
    </div>
  )
}

export default BPChartsv2
import React, { useEffect, useState } from "react";
import { Modal } from "@material-ui/core";
import MedicationForm from "./MedicationForm";
import styles from "./Schedule.module.css";
import { Formik, Field, Form, FieldArray } from "formik";
import { MedicationTimeSel } from "./MedicationTimeSel";
import MedicationForm2 from "./MedicationForm2";
import constants from "../../../../../../../Common/Constants";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import LoaderCG from "../../../../../../home/CareGiverPage/LoaderCG";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import moment from "moment/moment";

export default function AddScheduleModal({ open, onClose }) {
  const [showMedicationTimeSel, setShowMedicationTimeSel] = useState(false); // Add state for showing MedicationTimeSel
  const [medicationData, setMedicationData] = useState(null);
  const groupName = useSelector((state) => state.auth.groupName);
  const token = useSelector((state) => state.auth.authToken);
  const [isMorningTimeChecked, setIsMorningTimeChecked] = useState(false);
  const [isNoonTimeChecked, setIsNoonTimeChecked] = useState(false);
  const [isNightTimeChecked, setIsNightTimeChecked] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const location = useLocation();
  const viewablityConstant = location?.state;
  console.log(viewablityConstant)

  const isTimeValid = (time, relatedTime,type) => {
    if(type ==="AF" && relatedTime && time)
      return moment(time, 'HH:mm').isAfter(moment(relatedTime, 'HH:mm'));
    else 
      return true
    }

  const validationSchema = Yup.object().shape({
    medicines: Yup.array().of(
      Yup.object().shape({
        medicineName: Yup.string().required("*Medicine name is required"),
        category: Yup.string()
          .oneOf(["total_days", "total_medicines", "date_range"])
          .required("*Category is required"),
        numDays: Yup.number().when("category", {
          is: "total_days",
          then: Yup.number()
            .required("*Enter the number of days")
            .positive("*Number of days must be positive"),
          otherwise: Yup.number(),
        }),
        numMed: Yup.number().when("category", {
          is: "total_medicines",
          then: Yup.number()
            .required("*Enter the number of medicines")
            .positive("*Number of medicines must be positive"),
          otherwise: Yup.number(),
        }),
        from_date: Yup.date().when("category", {
          is: "date_range",
          then: Yup.date().required("*From date is required"),
          otherwise: Yup.date(),
        }),
        end_date: Yup.date().when("category", {
          is: "date_range",
          then: Yup.date()
            .required("*To date is required")
            .min(Yup.ref("from_date"), "*To date must be after from date"), 
          otherwise: Yup.date(),
        }),
        night: Yup.string().when(['nightTime'], {
          is: true,
          then: Yup.string().required('*Please select atleast one option for night'),
        }),
        morning: Yup.string().when(['morningTime'], {
          is: true,
          then: Yup.string().required('*Please select atleast one option for morning'),
        }),
        noon: Yup.string().when(['noonTime'], {
          is: true,
          then: Yup.string().required('*Please select atleast one option for noon'),
        }),
       
      })
    ),
    // beforemorningtime: Yup.string()
    // .test(
    //   "start_time_test",
    //   "Start time must be before end time",
    //   function(value) {
    //     const { aftermorningtime } = this.parent;
    //     return isTimeValid(value, aftermorningtime,"BF");
    //   }
    // ),
    // aftermorningtime: Yup.string()
    
    
  });

 
  const fetchMedicationDetails = async () => {
    try {
      const response = await fetch(
        `${constants.API_URL}/get_medication_details/${viewablityConstant?.urlStateData?.patientView? viewablityConstant?.patientToken : groupName}/${token}/`
      );
      if (response.ok) {
        setIsLoading(false);
        const data = await response.json();
        // Extract medication details and schedules from the API response
        const extractedData = data.result;
        // console.log("extData:", extractedData);
        setMedicationData(extractedData);
      } else {
        setIsLoading(false);
        // console.error("Failed to fetch medication details");
      }
    } catch (error) {
      setIsLoading(false);
      // console.error("Error fetching medication details:", error);
    }
  };

  useEffect(() => {
    if (open) {
      fetchMedicationDetails();
    }
  }, [open]);

  // console.log("medicationData: ", medicationData);

  const getInitialValues = (data) => {
    let defaultInitialValue = { medicines: {} };
    // console.log("obj:", data);

    if (data) {
      let initialValues = {};
      let medicines = [];
      let medicineData = data?.medication;
      Object.keys(medicineData).forEach((medicine) => {
        let medicineObj = medicineData;

        medicines.push({
          medicineName: medicine,
          category: medicineObj[medicine]?.total_days
            ? "total_days"
            : medicineObj[medicine].total_medicines
            ? "total_medicines"
            : "date_range",
          numDays: medicineObj[medicine]?.total_days || 0,
          numMed: medicineObj[medicine]?.total_medicines || 0,
          from_date: medicineObj[medicine]?.date_range
            ? medicineObj[medicine].date_range.from_date
            : "",
          end_date: medicineObj[medicine]?.date_range
            ? medicineObj[medicine].date_range.end_date
            : "",
          morningTime: medicineObj[medicine]?.part_of_day?.morning
            ? true
            : false,
          noonTime: medicineObj[medicine]?.part_of_day?.noon ? true : false,
          nightTime: medicineObj[medicine]?.part_of_day?.night ? true : false,
          morning: medicineObj[medicine]?.part_of_day?.morning || "",
          noon: medicineObj[medicine]?.part_of_day?.noon || "",
          night: medicineObj[medicine]?.part_of_day?.night || "",
        });
      });
      initialValues.beforemorningtime = `${data?.time_range?.morning?.BF?.hour}:${data?.time_range?.morning?.BF?.minute}`;
      initialValues.aftermorningtime = `${data?.time_range?.morning?.AF?.hour}:${data?.time_range?.morning?.AF?.minute}`;
      initialValues.beforeNoontime = `${data?.time_range?.noon?.BF?.hour}:${data?.time_range?.noon?.BF?.minute}`;
      initialValues.afterNoontime = `${data?.time_range?.noon?.AF?.hour}:${data?.time_range?.noon?.AF?.minute}`;
      initialValues.beforeNighttime = `${data?.time_range?.night?.BF?.hour}:${data?.time_range?.night?.BF?.minute}`;
      initialValues.afterNighttime = `${data?.time_range?.night?.AF?.hour}:${data?.time_range?.night?.AF?.minute}`;
      initialValues.medicines = medicines;

      return initialValues;
    }
    else {
      return defaultInitialValue;
     }
    
  };

  const handleNextButtonClick = () => {
    // Toggle the visibility of MedicationTimeSel when "Next" is clicked
    setShowMedicationTimeSel(true);
  };
  const handleGoBackButtonClick = () => {
    // Toggle the visibility of MedicationTimeSel when "Go Back" is clicked
    setShowMedicationTimeSel(false);
  };
  function convertTime(time) {
    if (time)
      return {
        hour: parseInt(time.split(":")[0]),
        minute: parseInt(time.split(":")[1]),
      };
  }

  const payloadConverter = (values) => {
    let medicines = { time_range: {}, medication: {} };
    let noon = {};
    let morning = {};
    let night = {};

    values?.medicines.forEach((medicine) => {

      let obj = {};
      let part_of_day = {};
      if (medicine?.morningTime) {
        part_of_day.morning = medicine?.morning;
        medicines.time_range.morning = {};
        if (medicine?.morning === "AF")
          morning.AF = convertTime(values?.aftermorningtime);
        if (medicine?.morning === "BF")
          morning.BF = convertTime(values?.beforemorningtime);
      }
      if (medicine?.noonTime) {
        part_of_day.noon = medicine?.noon;
        medicines.time_range.noon = {};
        if (medicine?.noon === "AF")
          noon.AF = convertTime(values?.afterNoontime);
        if (medicine?.noon === "BF")
          noon.BF = convertTime(values?.beforeNoontime);
      }
      if (medicine?.nightTime) {
        part_of_day.night = medicine?.night;
        medicines.time_range.night = {};
        // console.log('values?.beforeNighttime', values?.beforeNighttime)
        if (medicine?.night === "AF")
          night.AF = convertTime(values?.afterNighttime);
        if (medicine?.night === "BF")
          night.BF = convertTime(values?.beforeNighttime);
      }
      if (medicine?.category === "total_days")
        obj.total_days = parseInt(medicine.numDays);
      if (medicine?.category === "total_medicines")
        obj.total_medicines = parseInt(medicine.numMed);

      if (medicine?.category === "date_range") {
        let date_range = {};
        date_range.from_date = medicine.from_date;
        date_range.end_date = medicine.end_date;
        obj.date_range = date_range;
      }

      obj.part_of_day = part_of_day;
      medicines["medication"][medicine?.medicineName] = obj;
    });
    if (medicines?.time_range?.morning) {
      medicines.time_range.morning = morning;
    }
    if (medicines?.time_range?.noon) {
      medicines.time_range.noon = noon;
    }
    if (medicines?.time_range?.night) {
      medicines.time_range.night = night;
    }

    // return {time_range: {},medication:{}}
    return medicines;
  };

  // const onSubmit = async (values) => {
  //   let payload = payloadConverter(values);

  //   const requestBody = {
  //     method: "PUT",
  //     headers: { "Content-Type": "application/json" },
  //     body: JSON.stringify(payload),
  //   };
  //   fetch(
  //     `${constants.API_URL}/store_pd_schedules/${groupName}/${token}/`,
  //     requestBody
  //   ).then((response) => {
  //     let res = response.json();
  //     console.log("resp:", res);
  //   });
  // };

  const onSubmit = async (values, { setSubmitting }) => {
    let payload = payloadConverter(values);

    const requestBody = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    };

    try {
      const response = await fetch(
        `${constants.API_URL}/store_pd_schedules/${viewablityConstant?.urlStateData?.patientView? viewablityConstant?.patientToken : groupName}/${token}/`,
        requestBody
      );

      if (response.ok) {
        // Submission was successful, close the modal
        onClose();
      } else {
        // Handle the case where the submission failed, e.g., show an error message
        console.error("Failed to submit the form.");
      }
    } catch (error) {
      // Handle any network or unexpected errors
      console.error("Error submitting the form:", error);
    } finally {
      // Ensure to set submitting to false to allow future submissions
      setSubmitting(false);
    }
  };


  return (
    <Modal
      open={open}
      onClose={onClose}
      style={{
        minHeight: "400px",
        maxHeight: "700px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          minHeight: "400px",
          minWidth: "700px",
          maxHeight: "800px",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "white",
          padding: "10px",
          margin: "10px",
          borderRadius: "10px",
        }}
      >
        <div className={styles.modalHead}>
          <div className={styles.heading}>Medication Schedules</div>
          <div className={styles.medCloseBtn} onClick={onClose}>
            X
          </div>
        </div>

      
        {/* Conditionally render MedicationForm or MedicationTimeSel */}
        {!isLoading && medicationData !== null ? (
          
          <Formik
            initialValues={getInitialValues(medicationData)}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {({ handleSubmit, values, errors, touched,isValid }) => {
              // console.log("err:", errors);
              
              return (
                <>
                <div className={styles.headBtn}>
                {/* Conditionally render buttons based on showMedicationTimeSel */}
                {showMedicationTimeSel ? (
                  <button
                    className={styles.addButton}
                    onClick={handleGoBackButtonClick}
                  >
                    GO BACK
                  </button>
                ) : (
                  <>
                    {/* <button className={styles.addButton} onClick={addMedicineForm}>
                      ADD MEDICINE
                    </button> */}
                    <button
                      className={styles.addButton}
                      onClick={handleNextButtonClick}
                      disabled={!isValid}
                    >
                      NEXT
                    </button>
                  </>
                )}
              </div>
                <form onSubmit={handleSubmit}>
                  {/* {JSON.stringify(errors)} */}
                  <div
                    className={styles.medForm}
                    hidden={!showMedicationTimeSel}
                  >
                    <MedicationTimeSel
                      isMorningTimeChecked={isMorningTimeChecked}
                      setIsMorningTimeChecked={setIsMorningTimeChecked}
                      isNoonTimeChecked={isNoonTimeChecked}
                      setIsNoonTimeChecked={setIsNoonTimeChecked}
                      isNightTimeChecked={isNightTimeChecked}
                      setIsNightTimeChecked={setIsNightTimeChecked}
                    />
                  </div>
                  {!showMedicationTimeSel && (
                    <FieldArray name="medicines">
                      {({ insert, remove, push }) => (
                        <div style={{ overflowY: "scroll", height: "400px" }}>
                          {values.medicines.map((medicine, index) => (
                            <div className={styles.medForm} key={index}>
                              <MedicationForm2
                                medicine={medicine}
                                index={index}
                                name={"medicines"}
                              />

                              {values.medicines.length > 0 && (
                                <button
                                  className={styles.removeButton}
                                  onClick={() => remove(index)}
                                >
                                  Remove
                                </button>
                              )}
                            </div>
                          ))}
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "30px"
                          
                            }}
                          >
                            <button
                              className={styles.addButton}
                              type="button"
                              onClick={() => push({})}
                            >
                              ADD MEDICINE
                            </button>
                          </div>
                        </div>
                      )}
                    </FieldArray>
                  )}
                </form>
                </>
              );
            }}
          </Formik>
        ) : (
          <div>
            <LoaderCG />
          </div>
        )}
      </div>
    </Modal>
  );
}

import React from 'react'
import styles from './UserAlarm.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faCircleExclamation, faCircleInfo } from '@fortawesome/free-solid-svg-icons'
import { Divider } from '@mui/material'
import { useEffect } from 'react'

export default function AlarmTile({alarmUserData, statusText, backgroundColorClass, timeGapStatus,priorityColor}) {
  console.log(backgroundColorClass);

  const alarmListContainerClass = `${styles.alarmListContainer} ${styles[backgroundColorClass]}`;
  const iconColorClass = `${styles.exclamationIcon} ${styles[priorityColor]}`
  

  return (
    <>
          <div className={alarmListContainerClass}>
              <div className={styles.boxStyling}>
                  <div className={styles.timeHeadingContainer}>
                      <div className={styles.firstContainerBox}>
                        <FontAwesomeIcon className={iconColorClass} icon={faCircleExclamation} />
                          {timeGapStatus} ago 
                          <FontAwesomeIcon style={{ alignItems: "center", height: "4px", width: "4px", margin: "0px 5px 0px 5px" }} icon={faCircle} />
                          Priority - {statusText} {" "}
                      </div>
                      {/* <div><FontAwesomeIcon size='sl' icon={faCircleInfo} color='#b5b5b5' /></div> */}
                  </div>
                  <div><Divider /></div>
                  <div className={styles.alarmMessageContainer}> {alarmUserData?.data?.y_data?.status}</div>
              </div>
          </div>

    </>
  )
}

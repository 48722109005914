import React from "react";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { Badge, IconButton, MenuItem, Select, styled } from "@material-ui/core";
import AlertPopper from "../AlertPopper";
import Slideshow from "../../Slideshow/Slideshow";

const Item = styled("div")(({ theme }) => ({
  padding: "8px 12px",
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  color: "white",
}));

function PatientMonHeader({
  deviceName,
  cycleNo,
  alarmCount,
  alertsList,
  oneColumnStats,
  selectedEcg,
  changeEcgOption,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handlePopperClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleSelectEcg = (event) => {
    changeEcgOption(event.target.value);
  };

  return (
    <>
      <AlertPopper
        open={open}
        anchorEl={anchorEl}
        alertSeries={alertsList}
        alarmCount={alarmCount}
      />

      <div className="flexitems-between ">
        <div className="bedDashboard_HeaderLeftPanel">
          <div className="d-flex align-items-center">
            <h3>PatientMon - {deviceName}</h3>
            <Select
              value={selectedEcg}
              onChange={(e) => handleSelectEcg(e)}
              className="white-select"
            >
              <MenuItem value="ECGA">ECG A</MenuItem>
              <MenuItem value="ECGB">ECG B</MenuItem>
              <MenuItem value="ECGC">ECG C</MenuItem>
            </Select>
          </div>
          <Slideshow alertSeries={alertsList} />
        </div>

        <div className={`bedDashboard_HeaderRightPanel ${oneColumnStats}`}>
          <div>
            <div onClick={handlePopperClick}>
              <Item>
                <IconButton style={{ color: "white", padding: "0" }}>
                  <Badge badgeContent={alarmCount}>
                    <NotificationsIcon />
                  </Badge>
                </IconButton>
              </Item>
            </div>
          </div>
          <div style={{ paddingLeft: "15px" }}>
            <div className="flexitems-baseline">
              <p style={{ margin: "0" }}>Cycle No: </p>
              &nbsp;
              <p>{cycleNo}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default React.memo(PatientMonHeader);

import React, { useState } from "react";
import { Grid, Paper, Button, Typography } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import constants from "../../../../../Common/Constants";
import axios from "axios";
import { PopProfileSave } from "./PopProfileSave";

function FormFitbit({ onClose }) {
  const [showPopup, setShowPopup] = useState(false);
  const [popperAnchorEl, setPopperAnchorEl] = React.useState(null);

  //Navigate to Fitbit Page
  const handleFitbit = (event) => {
    event.preventDefault();

    setPopperAnchorEl(null);
  };

  const paperStyle = { padding: "0 5px 10px 5px", width: 230 };
  const btnStyle = { marginTop: 10 };
  const initialValues = {
    DeviceID: "",
  };

  const validationSchema = Yup.object().shape({
    DeviceID: Yup.string().required("Enter a valid fitbit ID"),
  });

  const onSubmit = (values, props) => {
    props.resetForm();

    var device_id = values.DeviceID;
    window.location.href =
      "https://www.fitbit.com/oauth2/authorize?response_type=token&client_id=238JT3&redirect_uri=https%3A%2F%2Fdemo.aimedcare.co%2Fauthenticate_fitbit%2F&scope=activity%20heartrate%20location%20nutrition%20profile%20settings%20sleep%20social%20weight%20oxygen_saturation%20respiratory_rate%20temperature&expires_in=31557600";

    sessionStorage.setItem("device_id", device_id);
  };

  return (
    <div>
      {showPopup && <PopProfileSave />}
      <Grid>
        <Paper elevation={0} style={paperStyle}>
          <Grid align="center">
            <Typography
              variant="caption"
              style={{
                fontFamily: "Montserrat,san-serif",
                fontWeight: 600,
                fontSize: "18px",
                color: "#808080",
              }}
            >
              {" "}
              Fitbit{" "}
            </Typography>
          </Grid>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {(props) => (
              <Form noValidate>
                <Field
                  // style={{marginTop:"1%"}}
                  as={TextField}
                  name="DeviceID"
                  label="Enter Your Fitbit Device ID"
                  fullWidth
                  error={props.errors.DeviceID && props.touched.DeviceID}
                  helperText={<ErrorMessage name="DeviceID" />}
                  required
                />
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <Button
                    type="submit"
                    style={{
                      btnStyle,
                      color: "white",
                      backgroundColor: "#23AF72",
                      marginLeft: "5%",
                      marginTop: "5%",
                      fontFamily: "Montserrat,san-serif",
                    }}
                    variant="contained"
                    // backgroundColor="#23AF72"
                  >
                    Save
                  </Button>
                  <Button
                    onClick={onClose}
                    style={{
                      btnStyle,
                      color: "#23AF72",
                      backgroundColor: "white",
                      marginLeft: "25%",
                      marginTop: "5%",
                      fontFamily: "Montserrat,san-serif",
                    }}
                    variant="contained"
                  >
                    Close
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Paper>
      </Grid>
    </div>
  );
}

export default FormFitbit;

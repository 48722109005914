import React from "react";
import { Grid, Paper, Button, Typography, TextField } from "@material-ui/core";

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import constants from "../../../../../Common/Constants";
import axios from "axios";
import { useState } from "react";
import { useParams, useHistory,useLocation } from "react-router-dom";
import {
  setDashboardData,
  setWebsocketStatus,
} from "../../../../../store/dashboardSlice";
import { PopMsg } from "./PopMsg";


function FormSleep({ onClose,careTakerPatientView=false}) {

  const dispatch = useDispatch();
  const [showPopup, setShowPopup] = useState(false);
  const careTakerToken=useSelector((state) => state.auth.careTakerToken);

  const token = useSelector((state) => state.auth.authToken);
  const paperStyle = { padding: "0 5px 50px 5px", width: 270 };
  const btnStyle = { marginTop: 10 };
  const userData = useSelector((state) => state.auth.userData);
  const groupName = useSelector((state) => state.auth.groupName);

  const { id,patient_group_id } = useParams();

    const location = useLocation();
    const urlStateData = location?.state

  const initialValues = {
    Date: "",
    StartTime: "",
    EndTime: "",
    REM:"",
    Awake:"",
    Deep:"",
    Light:""
  };
  const validationSchema = Yup.object().shape({
    Date: Yup.date().required("Required"),
    StartTime: Yup.string().required("Required"),
    EndTime: Yup.string().required("Required"),
    REM: Yup.number().min(1).max(1000).required("Required"),
    Awake: Yup.number().min(1).max(1000).required("Required"),
    Deep: Yup.number().min(1).max(1000).required("Required"),
    Light: Yup.number().min(1).max(1000).required("Required")
  });

  const generateValidationSchema = (isChecked) => {
    let validationSchema = Yup.object({
      Date: Yup.date().required("Required"),
      StartTime: Yup.string().required("Required"),
      EndTime: Yup.string().required("Required"),
      REM: Yup.number().min(1).max(1000)[isChecked ? 'required' : 'optional']("REM value Required"),
      Awake: Yup.number().min(1).max(1000)[isChecked ? 'required' : 'optional']("Awake value Required"),
      Deep: Yup.number().min(1).max(1000)[isChecked ? 'required' : 'optional']("Deep value Required"),
      Light: Yup.number().min(1).max(1000)[isChecked ? 'required' : 'optional']("Light value Required"),
    });
  
    return validationSchema;
  };

  const fetchDataApi = async () => {
    let url = `${constants.API_URL}/dashboard_values/${urlStateData?patient_group_id:groupName}/${token}/`;

    // url += `${token}/`;


    const result = await axios.get(url);
   
    dispatch(setDashboardData(result.data.data));
    };

  const [isChecked, setIsChecked] = useState(false);

  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };

  const onSubmit = (values, props) => {
    props.resetForm();

    const dateValue = values.Date;
    const startTimeValue = values.StartTime;
    const endTimeValue = values.EndTime;
    const REM = values.REM;
    const wake = values.Awake;
    const deep = values.Deep;
    const light = values.Light
  

    const StartTimeCheck1 = startTimeValue.charAt(0);
    const StartTimeCheck2 = startTimeValue.charAt(1);
    const StartTimeCheck = StartTimeCheck1 + StartTimeCheck2;
   

    const EndTimeCheck1 = endTimeValue.charAt(0);
    const EndTimeCheck2 = endTimeValue.charAt(1);
    const EndTimeCheck = EndTimeCheck1 + EndTimeCheck2;
  

    if (StartTimeCheck > EndTimeCheck) {
      var temp = new Date(dateValue);
      temp.setDate(temp.getDate() - 1);
      var sleepStartTimestamp =
        temp.toLocaleDateString() + " " + startTimeValue;
      var epochStartTime = Date.parse(sleepStartTimestamp);
      

      var sleepEndTimestamp = dateValue + " " + endTimeValue;
      var epochEndTime = Date.parse(sleepEndTimestamp);
    } else {
      var sleepStartTimestamp = dateValue + " " + startTimeValue;
      var epochStartTime = Date.parse(sleepStartTimestamp);

      var sleepEndTimestamp = dateValue + " " + endTimeValue;
      var epochEndTime = Date.parse(sleepEndTimestamp);
    }


    let formData = " "
if (!isChecked){

  formData = JSON.stringify({
    sleep_start: epochStartTime,
    sleep_end: epochEndTime,
    TS: Date.now(),
  });
}
else{
  formData = JSON.stringify({
    sleep_start: epochStartTime,
    sleep_end: epochEndTime,
    TS: Date.now(),
    rem:REM,
    wake:wake,
    deep:deep,
    light:light
  })
}
   

    axios({
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      url: `${constants.API_URL}/manual_entry/${urlStateData?patient_group_id:groupName}/${token}/`,
      data: formData,
    }).then((res) => {
      if (res?.status === 200) 
      setShowPopup(true);
    
      setTimeout(() => {
         // show pop up message
         setShowPopup(false);
         onClose() ;

       }, 2000);
       clearTimeout();
       

       setTimeout(() => {
         // show pop up message
        fetchDataApi();
     console.log("api refreshing for manual entry")
       }, 4000);
       clearTimeout();
    });
  };

  const max = ()=>{
    var dtToday = new Date();
       var month = dtToday.getMonth() + 1;
       var day = dtToday.getDate();
       var year = dtToday.getFullYear();
       if(month < 10)
           month = '0' + month.toString();
       if(day < 10)
           day = '0' + day.toString();
       var maxDate = year + '-' + month + '-' + day;
       return maxDate ;
   }

  return (
    <div>
    {showPopup &&(
      <PopMsg/>
    )}

    <Grid>
      <Paper elevation={0} style={paperStyle}>
        <Grid align="center">
          <Typography variant="caption">Enter the accurate values</Typography>
        </Grid>
        <Formik
          initialValues={initialValues}
          validationSchema={generateValidationSchema(isChecked)}
          onSubmit={onSubmit}
        >
          {({
            handleSubmit,
            touched,
            handleBlur,
            handleChange,
            values,
            errors,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              Date{" "}
              <Field
                type="date"
                name="Date"
                label="date"
                fullWidth
                error={errors.Date && touched.Date}
                helperText={<ErrorMessage name="date" />}
                required
                max={max()}
              />
              <br />
              {console.log("Error : ",errors)}
              <br />
              Sleep StartTime{" "}
              <Field
                type="time"
                name="StartTime"
                label="SleepStartTime"
                fullWidth
                error={errors.StartTime && touched.StartTime}
                helperText={<ErrorMessage name="StartTime" />}
                required 
              />
              <br />
              <br /> Sleep EndTime
              <Field
                type="time"
                name="EndTime"
                label="SleepEndTime"
                fullWidth
                error={errors.EndTime && touched.EndTime}
                helperText={<ErrorMessage name="SleepEndTime" />}
                required
                
              />{" "}
              <br />
              <br />
              <div>
                <input
                  type="checkbox"
                  id="topping"
                  name="topping"
                  value="Advanced"
                  checked={isChecked}
                  onChange={handleOnChange}
                />{" "}
                <b>Advanced Sleep Data</b>
              </div>
              <br />
              { isChecked && 
            <> 
              
              REM
              <Field
                as={TextField}
                name="REM"
                label="REM"
                fullWidth
                error={errors.REM && touched.REM}
                // helperText={<ErrorMessage name="REM" />}
                required
              />{" "}
              Awake
              <Field
                as={TextField}
                name="Awake"
                label="Awake"
                fullWidth
                error={errors.Awake && touched.Awake}
                // helperText={<ErrorMessage name="Awake" />}
                required
              />{" "}
              Deep Sleep
              <Field
                as={TextField}
                name="Deep"
                label="Deep"
                fullWidth
                error={errors.Deep && touched.Deep}
                // helperText={<ErrorMessage name="Deep" />}
                required
              />{" "}
              Light Sleep
              <Field
                as={TextField}
                name="Light"
                label="Light"
                fullWidth
                error={errors.Light && touched.Light}
                // helperText={<ErrorMessage name="Light" />}
                required
              />{" "}
              </>
}
              <center>
                <Button
                  type="submit"
                  style={btnStyle}
                  variant="contained"
                  color="primary"
                >
                  <center>Submit Data</center>
                </Button>
              </center>
            </form>
          )}
        </Formik>
      </Paper>
    </Grid>
    </div>
  );
}

export default FormSleep;
import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    authToken: "",
    groupName: "",
    headerGroupName: "",
    updateHeaderGroupName:"" ,
    noOfPages: "",
    presentPage: "",
    groupDevices: [],
    pageDevices: [],
    pageDeviceFavorite: [],
    deviceList: {},
    renderInterval: 100,
    graphMode: false,
    bedDeviceView: false,
    careTaker:false,
    isDoctor:false,
    isPatient:false,
    careTakerToken:[],
    cureGptToken: "",
    currentUserIndex:0,
    userData:[],
    vitalList:[],
    patientList:[]
  },
  reducers: {
    addAuthToken(state, action) {
      state.authToken = action.payload;
    },
    addCureGptToken(state, action) {
      state.cureGptToken = action.payload;
    },
    addGroupName(state, action) {
      state.groupName = action.payload;
    },
    addHeaderGroupName(state, action) {
      state.headerGroupName = action.payload;
    },
    addGroupDevices(state, action) {
      state.groupDevices = action.payload;
    },
    addPageDevices(state, action) {
      state.pageDevices = action.payload;
    },
    addPageDeviceFavorite(state, action) {
      state.pageDeviceFavorite = action.payload;
    },
    addDeviceList(state, action) {
      state.deviceList = action.payload;
    },
    addNoOfPages(state, action) {
      state.noOfPages = action.payload;
    },
    addPresentPage(state, action) {
      state.presentPage = action.payload;
    },
    addRenderInterval(state, action) {
      state.renderInterval = action.payload;
    },
    setGraphMode(state, action) {
      state.graphMode = action.payload;
    },
    setBedDeviceView(state, action) {
      state.bedDeviceView = action.payload;
    },
    setCareTaker(state,action){
      state.careTaker = action.payload
    },
    setIsDoctor(state,action){
      state.isDoctor = action.payload
    },
    setIsPatient(state,action){
      state.isPatient = action.payload
    },
    setCurrentUserIndex(state,action){
      state.currentUserIndex = action.payload
    },
    setUserData(state,action){
      state.userData = action.payload
    },
    setVitalList(state,action){
      state.vitalList = action.payload
    },
    setPatientList(state,action){
      state.patientList = action.payload
    },
    setCareTakerToken(state,action){
      state.careTakerToken = action.payload
    },
    updateHeaderGroupName(state, action) {
      state.headerGroupName = action.payload;
    },
  },
});

export const {
  addAuthToken,
  addCureGptToken,
  addGroupName,
  addHeaderGroupName,
  addGroupDevices,
  addPageDevices,
  addPageDeviceFavorite,
  addDeviceList,
  addNoOfPages,
  addPresentPage,
  addRenderInterval,
  setGraphMode,
  setBedDeviceView,
  setCareTaker,
  setIsDoctor,
  setIsPatient,
  setCurrentUserIndex,
  setUserData,
  setVitalList,
  setPatientList,
  setCareTakerToken,
  updateHeaderGroupName,
} = authSlice.actions;

export default authSlice.reducer;










import { createSlice } from "@reduxjs/toolkit";

const settingsSlice = createSlice({
  name: "settings",
  initialState: {
    replayModal: true,
    replayPage: false,
    startReplayTime: null,
    endReplayTime: null,
    replayBedDevices: [],
    selectedBed: "AllDevices",
    selectedDeviceId: null,
    selectedDeviceType: null,
    deviceTypeTabs: [],
    deviceNames: [],
  },

  reducers: {
    showReplayModal(state, action) {
      state.replayModal = action.payload;
    },
    setReplayPage(state, action) {
      state.replayPage = action.payload;
    },
    setStartReplayTime(state, action) {
      state.startReplayTime = action.payload;
    },
    setEndReplayTime(state, action) {
      state.endReplayTime = action.payload;
    },
    setReplayBedDevices(state, action) {
      state.replayBedDevices = action.payload;
    },
    setSelectedBed(state, action) {
      state.selectedBed = action.payload;
    },
    setSelectedDeviceId(state, action) {
      state.selectedDeviceId = action.payload;
    },
    setSelectedDeviceType(state, action) {
      state.selectedDeviceType = action.payload;
    },
    setDeviceTypeTabs(state, action) {
      state.deviceTypeTabs = action.payload;
    },
    setDeviceNames(state, action) {
      state.deviceNames = action.payload;
    },
  },
});

export const {
  setSelectedBed,
  setSelectedDeviceId,
  setSelectedDeviceType,
  setDeviceTypeTabs,
  setDeviceNames,
  showReplayModal,
  setReplayPage,
  setStartReplayTime,
  setEndReplayTime,
  setReplayBedDevices,
} = settingsSlice.actions;

export default settingsSlice.reducer;

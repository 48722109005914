import * as React from "react";
import PropTypes from "prop-types";
import { Box, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  box: {
    backgroundColor: "black",
    color: "white",
    margin: "0 1rem",
    border: "4px solid green",
    borderRadius: "0 6px 6px 6px",
    padding: "0 16px 24px 24px",
    height: "85vh",

    ["@media (max-width:1025px)"]: {
      height: "80vh",
    },

    ["@media (max-width:900px)"]: {
      margin: "0",
      padding: "14px",
      height: "80vh",
    },
  },
}));

function TabPanel(props) {
  const classes = useStyles();

  const { children, selectedTabvalue, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={selectedTabvalue !== index}
      id={`single-bed-tabpanel-${index}`}
      aria-labelledby={`single-bed-tab-${index}`}
      {...other}
    >
      {selectedTabvalue === index && (
        <Box className={classes.box}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  selectedTabvalue: PropTypes.number.isRequired,
};

export default TabPanel;

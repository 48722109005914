import React from 'react'
import { useSelector } from "react-redux";
import constants from '../../../../Common/Constants';
import {  useLocation, useParams } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { format, endOfDay} from 'date-fns'
import BRBarChart from './BRBarChart'
import styles from './BRPopup.module.css'

const apiName = {
  threeMonth: "month_data",
  year: "month_data",
};

export const BRContainer = ({ title, xData, yData, type}) => {
  const dateHR=format(endOfDay(new Date()), 'ddMMyyy')
   const groupName = useSelector((state) => state.auth.groupName);
     const [clickedChart, setClickedChart] = useState(null);
     const [brValue,setBrValue]=useState({br:null,date:null})
    const [subChartName,setChartName]= useState('')
     const token = useSelector((state) => state.auth.authToken);
     const api_type = apiName[type];
    const [chart_data, setData] = useState({});
    const careTakerToken = useSelector((state) => state.auth.careTakerToken);
    const { id,patient_group_id } = useParams();
  
    const location = useLocation();
    const urlStateData = location?.state 

    const viewOnly = location?.state;
    const {pat_id} = useParams();

      const handleClick = (param, echarts) => {
        let location = (param.dataIndex)
            const tempState = {...brValue}
            tempState["date"] = xData[location]
            tempState["br"] = yData[location]
            setBrValue(tempState)  
        }
        const handle3MonthClick = (param,echarts) => {
          const clickedDate = param?.name
            setClickedChart(clickedDate)
            setChartName(param?.name)
          
      
            axios
            .get(`${constants.API_URL}/${api_type}/br/${viewOnly?.viewOnly? pat_id : urlStateData?.patientView? patient_group_id:groupName}/fitbit001/${dateHR}/${token}/`)
            .then(res => { 
               
               
                let x= []
                let y = []
                res?.data?.month_data.forEach((data) => {
                  x.push(data.x_data)
                  y.push(data.y_data)
                  let stateCopy = {...chart_data};
                  
                  stateCopy[clickedDate] = {xData:x,yData:y}
                  
                  setData(stateCopy);
                });
          
        })
        }
  return (
    <div className={styles.containerMain}>
      <div>
        <BRBarChart
           title={title}
           xData={xData}
           yData={yData}
           handleClick={handleClick}
        />
      </div>
    </div>
  )
}

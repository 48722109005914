import React from 'react'
import ExpandMorePopup from '../../../../Common/ExpandMorePopup'
import { Divider } from '@mui/material';
import { useSelector } from 'react-redux';
import OptionPopUp from '../OtherComponents/OptionPopUp';

function MainCommonCard({alarmLabel,alarmStatusBorder, timeGap,tileTitle,renderComponent,Option1,renderComponent2,tileColor,Option2,vitalTile, dotIcon, onClick}) {

  return (
    // <div className='BPv2-main' style={{ backgroundColor: "white", minHeight: "200px" , display:"flex"}}>
    //   <div style={{ display: "flex", flexDirection: "column" , width:"100%"}}>
    //     <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginLeft: "10px", marginRight: "2px"}}>
    //       <div style={{ fontSize: "1.2rem", fontFamily: "'Montserrat', sans-serif", fontWeight: "500" }}>{"vitalTile"}</div>
    //       <div>{"dotIcon"}</div>
    //     </div>

    //     <div style={{ display: "flex", justifyContent: "space-between", alignItems: "self-end", marginLeft: "7px", marginRight: "7px", marginTop: "0px", fontSize: "0.75rem", fontFamily: "'Montserrat', sans-serif" }}>
    //       <div style={{ fontFamily: "'Montserrat', sans-serif" }}>
    //         {"alarmStatus"}
    //       </div>
    //       <div style={{ fontFamily: "'Montserrat', sans-serif" }}>
    //         {"timeGap"}
    //       </div>

    //     </div>
    //   </div>
    // </div>
    <div className={"BPv2-main"+(' alarm-status-'+alarmStatusBorder)} style={{ backgroundColor: tileColor || "white", minHeight: "220px" , display:"flex"}}>
    <div style={{ display: "flex", flexDirection: "column" , width:"100%"}}>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginLeft: "10px", marginRight: "2px"}}>
        <div style={{ fontSize: "1.2rem", fontFamily: "'Montserrat', sans-serif", fontWeight: "500" }}>{tileTitle}</div>
        <div> <OptionPopUp option1={Option1} option2={Option2}/></div>
      </div>

      {renderComponent2}

      <div onClick={onClick}  style={{ cursor:"pointer",width: "100%", height: "100%",display:"flex", alignItems:"center", justifyContent:"center" }}>
        {renderComponent}
      </div>

      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "self-end", marginLeft: "7px", marginRight: "7px", marginTop: "0px", fontSize: "0.75rem", fontFamily: "'Montserrat', sans-serif" }}>
        <div style={{ fontFamily: "'Montserrat', sans-serif" }}>
          {alarmLabel}
        </div>
        <div style={{ fontFamily: "'Montserrat', sans-serif" }}>
          {timeGap}
        </div>

      </div>
    </div>
  </div>
  )
}

export default MainCommonCard;
import React,{memo} from 'react'
import ReactEcharts from 'echarts-for-react';
import '../../FitCharts/FNewDash.css'

function BGChartPage1({title,xData,yData,handleClick , bgRes }) {

  const colorBG = bgRes?.map((point) => {

    var epochTS = new Date(parseInt(point.x_data));
    var dsnow = `${epochTS.toLocaleDateString()} \n ${epochTS.toLocaleTimeString()}`
   
    return {
    
      value: [dsnow, (point?.y_data?.BG).toFixed(1).toString()],
      
      itemStyle: {
        color: point.y_data.is_manual ? "#808080" : "#6D3DA3",
      },
    }
  })  .sort((a, b) => {
    // Convert the x-axis values to Date objects for comparison
    const dateA = new Date(`01/01/2021 ${a.value[0]}`);
    const dateB = new Date(`01/01/2021 ${b.value[0]}`);

    // Compare the dates and return the sort order
    if (dateA < dateB) {
      return -1;
    }
    if (dateA > dateB) {
      return 1;
    }
    return 0;
  });

  
   
  const chartData =React.useMemo(()=>{
      return {
        title: {
          left: 'center',
          text: title,
          textStyle: {
            fontFamily: "sans-serif"
          }
        },
        
        tooltip: {
            trigger: 'axis',
            textStyle: {
              fontFamily: "sans-serif"
            },
            axisPointer: {
              type: 'shadow'
            }
          },
          
        xAxis: {
          type: 'category',
        //   boundaryGap: false,
          axisLabel: {
                
            fontFamily:'sans-serif',
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
                
            fontFamily:'sans-serif',
          }
     
        },
        
        series: [
          {
            name: 'mg/dL ',
            type: 'line',
            barWidth:"50%",
            
            color: '#6D3DA3',
     
            
            data:colorBG,
             
          }
        ]}
  },[xData,yData,title ]);
  const _handleClick = (params, data) => {

    let index = params.dataIndex;
    handleClick &&
      "function" === typeof handleClick &&
      handleClick(colorBG[index]);
  };
  

return ( 
  <div>
    {chartData?.series?.[0]?.data?.length?
  <div className='left-chart' >
  <ReactEcharts 
    option={chartData} 
    onEvents={{
      'click': handleClick,
    }}
  />
  </div>
   : <p style={{fontSize:"20px", fontWeight:"bold"}}>NO DATA AVAILABLE </p>
   }
  </div>
)
}


export default memo(BGChartPage1)

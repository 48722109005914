import React, { useState } from "react";
import styles from "./TMPPopup.module.css";
import TMPBarChart from "./TMPBarChart";

export const TMPContainer = ({ title, xData, yData, type , bgRes }) => {
    const [bgValue, setBgValue] = useState({ bg: null, date: null });
    const handleClick = (data1) => {
      if (data1) {
        var [a1, b1] = data1.value;
        setBgValue({
          title: a1,
          bg: a1,
          date: a1,
          x1: a1,
          x2: b1,
        });
      }
    };
  
    return (
      <div className={styles.containerMain}>
        <div>
          <TMPBarChart
            title={title}
            xData={xData}
            yData={yData}
            bgRes={bgRes}
            handleClick={handleClick}
          />
        </div>
      </div>
    );
  };
  
import React from "react";

import "../../FNewDash.css";
import HRLowHigh from "../HRLowHigh";
import HRChartPage1 from "../HRChartPage1";
import moment from "moment";
import { useState } from "react";
import constants from "../../../../../../../Common/Constants";
import axios from "axios";
import { useSelector } from "react-redux";
import styles from "./HRChartContainer.module.css";
import HRAnalytics from "../HRAnalytics";
import HRPieChart from "../HRPieChart";
import HRCardio from "../HRCardio";
import RestingHR from "../RestingHR";
import { useLocation, useParams } from "react-router-dom";

const apiName = {
  week: "day_data",
  month: "week_data",
  threeMonth: "month_data",
  year: "month_data",
};

export default function HRChartContainer({ title, xData, yData, type }) {
  const groupName = useSelector((state) => state.auth.groupName);
  const [clickedChart, setClickedChart] = useState(null);
  const [subChartName, setChartName] = useState("");
  const token = useSelector((state) => state.auth.authToken);
  const res = useSelector((state) => state.dashboard.analyticsRes);
  const api_type = apiName[type];
  const [chart_data, setData] = useState({});

  const careTakerToken = useSelector((state) => state.auth.careTakerToken);
  
  const { id,patient_group_id } = useParams();

  const location = useLocation();
  const urlStateData = location?.state 

  

  const handleClick = (param, echarts) => {
    const dateMonth = param?.name?.split(" ");
    let month = "01";
    let date = "01";

    if (dateMonth?.length === 1) {
      month = moment().month(dateMonth[0]).format("MM");
    } else {
      month = moment().month(dateMonth[1]).format("MM");
      date = dateMonth[0]?.length === 1 ? `0${dateMonth[0]}` : dateMonth[0];
    }
    const year = (param?.name.split(" "))[2];

    const clickedDate = `${date}${month}${year}`;
    setClickedChart(clickedDate);
    setChartName(param?.name);
    if (!chart_data?.[clickedDate]) {
      //api call
      axios
        .get(
          `${constants.API_URL}/${api_type}/HR/${urlStateData?patient_group_id:groupName}/${clickedDate}/${urlStateData?careTakerToken:token}/`
        )
        .then((res) => {
          let x = [];
          let y = [];
          res?.data?.[api_type].forEach((data) => {
            x.push(data.x_data);
            y.push(data.y_data);
          });
          let stateCopy = { ...chart_data };

          stateCopy[clickedDate] = { xData: x, yData: y };

          setData(stateCopy);
        });
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.container1}>
          <div className={styles.subContainer}>
            <HRChartPage1
              title={title}
              xData={xData}
              yData={yData}
              handleClick={handleClick}
            />
          </div>
          <div className={styles.subContainer}>
            {clickedChart && (
              <HRChartPage1
                title={subChartName}
                xData={chart_data?.[clickedChart]?.xData}
                yData={chart_data?.[clickedChart]?.yData}
              />
            )}
          </div>
        </div>
        <div className={styles.container1}>
          <div className={styles.subContainer}>
            <HRAnalytics />
          </div>
          <div className={styles.subContainer}>
            <HRPieChart />
          </div>
        </div>
        <div className={styles.container1}>
          <div className={styles.subContainer}>
            <HRCardio />
          </div>
          <div className={styles.subContainer}>
            <RestingHR />
          </div>
          <div style={{ display: "flex", alignItems: "center", padding: 20 }}>
            {res?.data?.high_heart_rates && (
              <HRLowHigh heartRate={res?.data?.high_heart_rates} />
            )}
            

            <div className={styles.subContainer}>
              {res?.data?.low_heart_rates && (
                <HRLowHigh heartRate={res?.data?.low_heart_rates} type="Low" />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

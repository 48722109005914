import * as echarts from 'echarts';
import {useSelector,useDispatch } from "react-redux"
import React, { useState, useEffect, useRef } from 'react';
import ReactEcharts from 'echarts-for-react';
import ExpandMorePopup from '../../../../Common/ExpandMorePopup';
import MainCommonCard from './MainCommonCard';
import OptionPopUp from '../OtherComponents/OptionPopUp';
import PopUpManualEntry from '../OtherComponents/PopUpManualEntry';
import FormHR from '../../../Member Details/Comps/FitbitFolder/FitCharts/FormHR';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faHeartPulse} from '@fortawesome/free-solid-svg-icons';
import TileRemove from '../Featuresv2/TileRemove';
import constants from '../../../../Common/Constants';


const HeartRatev2 = ({onClick,userID}) => {


  const chartRef = useRef(null);

  const ws = useRef();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [status, setStatus] = useState(false)

  const [isLive, setIsLive] = useState(false);
  const [lastUpdateTime, setLastUpdateTime] = useState();

  const [isConnected, setIsconnected] = useState(false)
  const [isExpired, setIsExpired] = useState(false);

  const groupName = useSelector((state) => state.auth.groupName);
 
  const token = useSelector((state) => state.auth.authToken);

  const [checkHRData, setCheckHRData] = useState(false);

  const heartRateDaily = useSelector((state) => state.dashboard.heartRateDaily);

  const HRDaily = heartRateDaily?.data?.y_data?.HR

  const alarmStatusHR= heartRateDaily?.data?.y_data?.status_point

  const timeGapIndication =  heartRateDaily?.data?.x_data?.time_gap? heartRateDaily?.data?.x_data?.time_gap:"" 

  useEffect(() => {
    // Check if the time gap contains the words "day" or "days"
    if (timeGapIndication?.includes("day") || timeGapIndication?.includes("days")) {
      setIsExpired(true);
    }
    else{
      setIsExpired(false);
    }
    // else setIsExpired(false)
  }, [timeGapIndication]);

  


  useEffect(() => {

    ws.current = new WebSocket(`${constants.SOCKET}/${userID}/fitbit/read/${token}/`);
 

    ws.current.onopen = (item) => {
      setIsconnected(true);
     let interval = setInterval(() => {
        if (ws.current) {
          if (ws.current.readyState == 3) {
            clearInterval(interval);
          }
          var messagehs = {
            handshake: Date.now(),
          };
          ws.current.send(JSON.stringify(messagehs));
        }
      }, 30000);
    };

    ws.current.onmessage = (ev) => {

      const message1 = JSON.parse(ev?.data);
      let message= JSON.parse(message1?.message);
      let checkHR = message?.HR

      if (checkHR){
         setCheckHRData(true);
      }
      else{
        setCheckHRData(false);
      }

      // Upon receiving websocket message then add it to the list of data that we are displaying
      var epochTS = message?.TS;
      var d = new Date(epochTS);
      let tsnow =d.toLocaleTimeString()
      let newDataArray = [
        ...data,
        {
          id: tsnow,
          HeartRate: message?.HR,
        },
      ];
      setData((currentData) => limitData(currentData, message));  
      setLastUpdateTime(Date.now());
      setIsLive(true);
      
      // dispatch(setHeartRate((currentData) => limitData(currentData, message)));
      // dispatch(setHeartRateStatus("success"))
    };
    ws.current.onclose = (ev) => {
   
      setIsLive(false);
      setData([]);
      setLastUpdateTime();
    };
 
    //We limit the number of reads to the last 24 reading and drop the last read
    function limitData(currentData, message) {
      if (currentData?.length > 24) {
        currentData?.shift();
      }
      var epochTS = message?.TS;
      var d = new Date(epochTS);
      let tsnow =d.toLocaleTimeString()
      return [
        ...currentData,
        {
          id: tsnow,
          HeartRate: message?.HR,
        },
      ];
    }
 
    return () => {
      console.log("Cleaning up! ");
      ws.current.close();
      setIsconnected(false)
    };
  }, [groupName,token]);

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = Date.now();
      if (currentTime - lastUpdateTime > 10000) {
        console.log('No data received for more than 10 seconds');
        setIsLive(false);
        setLastUpdateTime();
        setData([]);
      }
    }, 2000);

    return () => {
      clearInterval(interval);
    };
  }, [isLive, lastUpdateTime]);

  useEffect(() => {
    if(data[0]?.HeartRate){

    const chart = echarts.init(chartRef.current);
  
    const option = {
      color:['#F64D60'],
      animation:false,
         responsive: true,
         tooltip: {
          color:"#F64D60",
          trigger: 'axis',
          color:"red",
          textStyle: {
            fontFamily: 'sans-serif' // set tooltip font family to Arial
          }
        },
      grid: {
          left: '5%',
          right: '8%',
          bottom: '0%',
          top:"5%",
          containLabel: true
        },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        show:false,
        splitLine: {
            show: false
          },
        data: data.map(d => d.id),
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          fontSize: 10,
          fontFamily: "'Montserrat', sans-serif",
         fontWeight:500
      },
        splitLine: {
            show: false
          },
      },
      series: [
        {
        type: 'line',
        name:"HR",
        stack: 'Total',
            smooth:true,
            lineStyle: {
              width: 1.75,
              color: '#F64D60',
            },
             showSymbol: false,
            areaStyle: {
              opacity:0.3 ,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 1,
                  color: 'white'
                },
                {
                  offset: 0,
                  color: '#F64D60'
                }
              ])
            },
            emphasis: {
              focus: 'series'
            },
        data: data.map(d => d.HeartRate),
        
      }],
    };
  
    chart.setOption(option);
    
    if(!(data[0]?.HeartRate)){
      chart.dispose();
      setCheckHRData(false)
    }

    return () => {
      chart.dispose();
    };
  
  }
  }, [data]);




const HeartRateSingleValueChart = (

  <MainCommonCard
  tileTitle={"Heart Rate"}
  tileColor={isExpired? "transparent" : "white"}
  alarmStatusBorder={alarmStatusHR}
  alarmLabel={heartRateDaily?.data?.y_data?.status? heartRateDaily?.data?.y_data?.status: ""}
  timeGap={heartRateDaily?.data?.x_data?.time_gap? heartRateDaily?.data?.x_data?.time_gap + " ago" : ""}
  onClick={onClick}
    Option1={
      <PopUpManualEntry
        optionName="MANUAL ENTRY"
        FormTitle="Heart Rate"
        FormType={<FormHR />} />
    }
    Option2={<TileRemove option2title={"REMOVE TILE"} parameter={"HR"}/> }
    
    renderComponent={
     
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
          <div><div style={{height:"100%", width:"100%", backgroundColor:"rgb(225, 239, 250)",display:"flex",alignItems:"center",justifyContent:"center", padding:"18px", borderRadius:"50%"}}>
            <FontAwesomeIcon icon={faHeartPulse} style={{height:"40px",width:"40px" ,color: "#7F55AE",}} /></div>
            {/* <img src={""} alt="icon" style={{ height: "50px", width: "50px", filter: "hue-rotate(270deg)" }} /> */}
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", fontFamily: "'Montserrat', sans-serif" }}>
              <div style={{ fontFamily: "'Montserrat', sans-serif", fontSize: "1.8rem", fontWeight: "600" }}>{HRDaily? HRDaily:""}</div>
              <div style={{ fontFamily: "'Montserrat', sans-serif", fontSize: "0.8rem", fontWeight: "500", marginTop: "-5px" }}>bpm</div>
            </div>
          </div>
        </div>
      </div>
    }
  >

  </MainCommonCard>
)

 if(!HRDaily)
 return null

 if(!(data[0]?.HeartRate)){
  // (data?.[(data?.length-1)]?.HeartRate)
 return HeartRateSingleValueChart
}

  return (
    <div className='BPv2-main' style={{backgroundColor:"white", minHeight:"200px", maxWidth:"100%", display:"flex",cursor:"pointer"}}>
    <div style={{display:"flex", flexDirection:"column", width:"100%"}}>
       <div style={{display:"flex",alignItems:"center", justifyContent:"space-between",marginLeft:"10px", marginRight:"2px"}}>
           <div style={{fontSize:"1.2rem", fontFamily:"'Montserrat', sans-serif", fontWeight:"500"}}>Heart Rate</div>
           <div> <OptionPopUp option1={<PopUpManualEntry
          
          optionName="MANUAL ENTRY" 
          FormTitle="Heart Rate" 
          FormType={<FormHR/>}/>} 
          option2={<TileRemove option2title={"REMOVE TILE"} parameter={"HR"}/> }/></div>
       </div>

       <div onClick={onClick}>
       <div style={{ display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center",fontFamily:"'Montserrat', sans-serif"}}>
           <div style={{fontFamily:"'Montserrat', sans-serif",fontSize:"1.8rem",fontWeight:"600" }}>{data?.[(data?.length-1)]?.HeartRate? data?.[(data?.length-1)]?.HeartRate : "-" }</div>
           <div style={{fontFamily:"'Montserrat', sans-serif",fontSize:"0.8rem",fontWeight:"500", marginTop:"-5px", color:"#75757C"}}>bpm</div>
       </div>

       <div style={{width:"100%", height:"100%"}}>

       
       <div ref={chartRef} style={{ height: '120px' }}></div>

       </div>
       </div>
       <div style={{display:"flex", justifyContent:"space-between",alignItems:"self-end", marginLeft:"7px",marginRight:"7px",marginTop:"0px", fontSize:"0.75rem", fontFamily:"'Montserrat', sans-serif"}}>
       <div style={{fontFamily:"'Montserrat', sans-serif"}}>
         Normal
       </div>
       <div style={{fontFamily:"'Montserrat', sans-serif"}}>
         Live
       </div>

      </div>
    </div>
   </div>
  
  );
};

export default HeartRatev2;

const common = {
  STATS_PER_PAGE: 12,
  GRAPH_PER_PAGE: 9,

  STATUS_DISPLAY_COLORS: [
    "rgb(72, 211, 66)",
    "aqua",
    "white",
    "yellow",
    "rgb(72, 211, 66)",
    "aqua",
    "white",
    "yellow",
    "rgb(72, 211, 66)",
    "aqua",
    "white",
    "yellow",
    "rgb(72, 211, 66)",
    "aqua",
    "white",
    "yellow",
  ],

  STATUS_UNITS: {
    BPM: "BPM",
    CD: "mL/cmH20",
    CP: "mL",
    DIA: "IBP",
    ET: "mL",
    ETC: "mmHg",
    FiO2: "%O2",
    Flow: "L/m",
    Fo: "cmH2O",
    HR: "BPM",
    IE: " ",
    MV: "L/m",
    PE: "cmH2O",
    PEEP: "cmH2O",
    pPeak: "cmH2O",
    pPlateau: "cmH2O",
    PF: "BPM",
    PI: "cmH2O",
    PIP: "cmH2O",
    PL: "mL",
    PP: "ms",
    PR: "BPM",
    PX: "%",
    Pressure: "cmH2O",
    RR: "BPM",
    RRC: "BPM",
    RRE: "ECG",
    SP: "%O2",
    SPO: "%O2",
    SYS: "IBP",
    TH: "ms",
    THold: "ms",
    TP: "mL",
    TVe: "mL",
    TVi: "mL",
    VE: "mL",
    VI: "mL",
    VR: "cmH2O",
  },

  STATUS_DESCRIPTION: {
    PI: "PIP",
    PE: "PEEP",
    PX: "PI",
    PR: "PR",
    VI: "TVi",
    VE: "TVe",
    RR: "RR",
    CD: "Cd",
    IE: "I:E",
    SP: "SPO2",
  },

  STATUS_MAXVALUE: {
    PIP: 40,
    pPeak: 40,
    pPlateau: 30,
    Flow: 60,
    FiO2: 698500,
  },
  STATUS_MINVALUE: {
    PIP: 0,
    pPlateau: 5,
    Flow: 5,
    FiO2: 210,
  },
  STATUS_AVGVALUE: {
    Flow: 28,
    FiO2: 520,
  },

  ALARM_DESCRIPTION: {
    HPI: "High Ppeak",
    LPI: "Low Ppeak",
    LPE: "Low PEEP",
    HPE: "High PEEP",
    LVI: "Low TVi",
    HVI: "High TVi",
    IRF: "Reverse Flow in Insp.",
    ERF: "Reverse Flow in Exp.",
    LPA: "Low PAP",
    HPA: "High PAP",
    HMV: "High MV",
    LMV: "Low MV",
    HP: "High Pressure",
    LP: "Low Pressure",
    HF: "High Flow",
    LF: "Low Flow",
    BLW: "Battery Low",
    PWF: "Power Failure",
    LIC: "Leak in Circuit",
    DIS: "Disconnection",
    ICE: "Internal Code Error",
    APN: "Apnea",
    MFI: "Max Flow Insp.",
    FEI: "Flow in Exp. during Insp.",
    EPO: "Exp. Port Open",
  },

  ALARM_BACKGROUNDCOLOR: {
    0: "alarm_background_blue",
    1: "alarm_background_yellow",
    2: "alarm_background_red",
  },

  NO_OF_DEVICETYPE_GRAPHS: {
    iremon: 3,
    isave: 3,
    capnometer: 1,
    patientmon: 3,
    ventilator: 3,
  },

  SINGLEPOINT_GRAPH: {
    iremon: true,
    isave: true,
  },
};

export default common;

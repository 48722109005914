import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { createTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SignIn from "./components/SignIn/SignIn";
import HomePage from "./components/home/HomePage";
import NotFound from "./components/NotFound/NotFound";
import VitalSettings from "./components/Settings/VitalSettings";
import "./Common/Common.css";
import FitbitDashboard from "./components/Member Details/Comps/FitbitFolder/FitbitDashboard";
import HRMainPage from "./components/Member Details/Comps/FitbitFolder/FitCharts/HRPage/HRMainPage";
import DisMainPage from "./components/Member Details/Comps/FitbitFolder/FitCharts/DistancePage/DisMainPage";
import StepMainPage from "./components/Member Details/Comps/FitbitFolder/FitCharts/StepPage/StepMainpage";
import SleepMainPage from "./components/Member Details/Comps/FitbitFolder/FitCharts/SleepPage/SleepMainPage";
import SPO2MainPage from "./components/Member Details/Comps/FitbitFolder/FitCharts/SPO2Page/SPO2MainPage";
import BRMainPage from "./components/Member Details/Comps/FitbitFolder/FitCharts/BRPage/BRMainPage";
import BPMainPage from "./components/Member Details/Comps/FitbitFolder/FitCharts/BPPage/BPMainPage";
import BGMainPage from "./components/Member Details/Comps/FitbitFolder/FitCharts/BGPage/BGMainPage";
import { FitbitLoginPage } from "./components/Member Details/Comps/FitbitFolder/FitCharts/FitbitLoginPage";
import PatientMonInner from "./components/home/PatientMonInner";
import VentilatorInner from "./components/home/VentilatorInner";
import PatientRegisterPage from './components/home/PatientRegisterPage/index.js'

import SignUpPage from "./components/home/PatientRegisterPage/SignUpPage/SignUpPage";
import RegisterPage from "./components/home/PatientRegisterPage/RegisterPage/RegisterPage";

import MyProfile from "./components/Member Details/Comps/FitbitFolder/FitCharts/MyProfile";
import HomeDashboard2 from "./components/home/HomeDashboard2";
import HomeDashboardv2 from "./components/home/V2Components/HomeDashboardv2";
import HomeTest from "./components/home/V2Components/HomeTest";
import { MedicationPage } from "./components/Member Details/Comps/FitbitFolder/FitCharts/MedicationPage";
import SignUpv2 from "./components/home/V2Components/SignUpAndRegisterv2/SignUp/SignUpv2";
import HomeDashboardPatientView from "./components/home/V2Components/HomeDashboardPatientView";

import TwilioLogin from "./components/home/V2Components/Twilio/TwilioLogin";
import Loginv2 from "./components/home/V2Components/SignUpAndRegisterv2/LoginScreen/Loginv2";
import LoginMobileView from "./components/home/V2Components/SignUpAndRegisterv2/LoginScreen/LoginMobileView";
import RegisterV2 from "./components/home/V2Components/SignUpAndRegisterv2/Register";
import DashboardMain from "./components/home/V2Components/ProfileSwitching/DashboardMain";
import IndividualPatientScreen from "./components/home/V2Components/ProfileSwitching/IndividualPatientScreen";
import TwilioSignUp from "./components/home/V2Components/Twilio/TwilioSignUp";
import { ProfilePage } from "./components/Member Details/Comps/FitbitFolder/FitCharts/MyProfile/index1";
import AlarmSettings from "./components/home/V2Components/AlarmManage/AlarmSettings";
import AlarmNewPage from "./components/home/V2Components/AlarmManage/AlarmNewPage";
import MyFamilyInnerScreen from "./components/home/V2Components/ProfileSwitching/MyHealthComponent/MyFamilyInnerScreen";
import { ForgotPassword } from "./components/Member Details/Comps/FitbitFolder/FitCharts/ForgotPassword";
import { EmailVerification } from "./components/Member Details/Comps/FitbitFolder/FitCharts/ForgotPassword/EmailVerification";
import { ResetPassword } from "./components/Member Details/Comps/FitbitFolder/FitCharts/ForgotPassword/ResetPassword";
import { WithingsLoginPage } from "./components/Member Details/Comps/FitbitFolder/FitCharts/WithingsLoginPage";

function App() {
  //For Normal Mode
  const [connectingFlag, setConnectingFlag] = useState(false);
  function setConnectFlag(isConnect) {
    setConnectingFlag(isConnect);
  }
  useEffect(() => {
    
  }, [connectingFlag]);

  var [socketList, setSocketList] = useState([]);
  function storeCreatedSockets(socketArray) {
    setSocketList([...socketArray]);
  }
  function closeCreatedSockets() {
    let socketArray = [...socketList];
    setSocketList([]);
    socketArray.map((socket) => {
      let webSocket = socket.webSocket;
      webSocket.close();
    });
    
  }
  function getCreatedSocket(deviceType) {
    let isSocketCreated = socketList.find(
      (socket) => socket.deviceType === deviceType
    );
    return isSocketCreated;
  }

  //For Replay Mode
  const [replayConnectFlag, setReplayConnectFlag] = useState(false);
  function setConnectReplayFlag(isReplay) {
    setReplayConnectFlag(isReplay);
  }

  //Checks if the user is already logged in
  const token = useSelector((state) => state.auth.authToken);
  const [loggedIn, setLoggedIn] = useState(!!token);
  useEffect(() => {
    if (token) setLoggedIn(true);
    else setLoggedIn(false);
  }, [token]);

  const screenWidth = window.innerWidth;

  return (
    <Router>
      <div className="App">
        <div className="content">
          <Switch>
            <Route exact path="/">

              {screenWidth >= 901 ? 
              <Loginv2
              setConnectFlag={setConnectFlag}
                setConnectReplayFlag={setConnectReplayFlag}
                closeCreatedSockets={closeCreatedSockets}/> 
                 : 
               <LoginMobileView
                setConnectFlag={setConnectFlag}
                setConnectReplayFlag={setConnectReplayFlag}
                closeCreatedSockets={closeCreatedSockets}
              />  
             } 
            </Route>

            {loggedIn && (
              <Route exact path="/home">
                <DashboardMain/>
                {/* <HomePage
                  pageType="grid"
                  connectFlag={connectingFlag}
                  setConnectFlag={setConnectFlag}
                  storeCreatedSockets={storeCreatedSockets}
                  getCreatedSocket={getCreatedSocket}
                  closeCreatedSockets={closeCreatedSockets}
                  connectReplayFlag={replayConnectFlag}
                  setConnectReplayFlag={setConnectReplayFlag}
                /> */}
              </Route>
            )}

            {/* {loggedIn && (
              <Route exact path="/home">
                <HomePage
                  pageType="stats"
                  connectFlag={connectingFlag}
                  setConnectFlag={setConnectFlag}
                  storeCreatedSockets={storeCreatedSockets}
                  getCreatedSocket={getCreatedSocket}
                  closeCreatedSockets={closeCreatedSockets}
                  connectReplayFlag={replayConnectFlag}
                  setConnectReplayFlag={setConnectReplayFlag}
                />
              </Route>
            )} */}
            {loggedIn && (
              <Route exact path="/patient/:patient_group_id">
                <IndividualPatientScreen
                />
              </Route>
            )}
              
            {loggedIn && (
              <Route exact path="/patient/:patient_group_id">
                <IndividualPatientScreen
                />
              </Route>
            )}
              <Route path="/familydashboard/:pat_id" 
              render={({ match }) => <MyFamilyInnerScreen pat_id={match.params.pat_id} />} />

            {/* {loggedIn && (
              <Route path="/bed/:id">
                <HomePage
                  pageType="bed"
                  connectFlag={connectingFlag}
                  setConnectFlag={setConnectFlag}
                  storeCreatedSockets={storeCreatedSockets}
                  getCreatedSocket={getCreatedSocket}
                  closeCreatedSockets={closeCreatedSockets}
                  connectReplayFlag={replayConnectFlag}
                  setConnectReplayFlag={setConnectReplayFlag}
                />
              </Route>
            )} */}

            {/* {loggedIn && (
              <Route path="/device/:id">
                <HomePage
                  pageType="device"
                  connectFlag={connectingFlag}
                  setConnectFlag={setConnectFlag}
                  storeCreatedSockets={storeCreatedSockets}
                  getCreatedSocket={getCreatedSocket}
                  closeCreatedSockets={closeCreatedSockets}
                  connectReplayFlag={replayConnectFlag}
                  setConnectReplayFlag={setConnectReplayFlag}
                />
              </Route>
            )} */}

            {/* {loggedIn && (
              <Route path="/replay/device/:id/">
                <HomePage
                  pageType="replayDevice"
                  connectReplayFlag={replayConnectFlag}
                  setConnectReplayFlag={setConnectReplayFlag}
                  connectFlag={connectingFlag}
                  setConnectFlag={setConnectFlag}
                  storeCreatedSockets={storeCreatedSockets}
                  getCreatedSocket={getCreatedSocket}
                  closeCreatedSockets={closeCreatedSockets}
                />
              </Route>
            )} */}

            {/* {loggedIn && (
              <Route path="/replay/bed/:id/">
                <HomePage
                  pageType="replayBed"
                  connectReplayFlag={replayConnectFlag}
                  setConnectReplayFlag={setConnectReplayFlag}
                  connectFlag={connectingFlag}
                  setConnectFlag={setConnectFlag}
                  storeCreatedSockets={storeCreatedSockets}
                  getCreatedSocket={getCreatedSocket}
                  closeCreatedSockets={closeCreatedSockets}  
                />
              </Route>
            )} */}

            

            {/* {loggedIn && (
              <Route path="/settings">
                <VitalSettings
                  contentType="settings"
                  closeCreatedSockets={closeCreatedSockets}
                />
              </Route>
            )} */}
             <Route  path="/demo-user"> 
             <FitbitDashboard/>
             </Route>

             <Route  path="/heartratepage/:patient_group_id"> 
             <HRMainPage/>
             </Route>

             <Route path="/alarm_management/:patient_group_id">
              <AlarmNewPage/>
             </Route>


             <Route  path="/heartratepage"> 
             <HRMainPage/>
             </Route>

             <Route  path="/stepspage/:patient_group_id"> 
             <StepMainPage/>
             </Route>

             <Route  path="/stepspage"> 
             <StepMainPage/>
             </Route>

             <Route  path="/distancepage"> 
             <DisMainPage/>
             </Route>

             <Route  path="/sleeppage/:patient_group_id"> 
             <SleepMainPage/>
             </Route>

             <Route  path="/sleeppage"> 
             <SleepMainPage/>
             </Route>

             <Route  path="/spo2page/:patient_group_id"> 
             <SPO2MainPage/>
             </Route>

             <Route  path="/spo2page"> 
             <SPO2MainPage/>
             </Route>

             <Route  path="/brpage/:patient_group_id"> 
             <BRMainPage/>
             </Route>

             <Route  path="/brpage"> 
             <BRMainPage/>
             </Route>

             <Route  path="/bppage/:patient_group_id"> 
             <BPMainPage/>
             </Route>

             <Route  path="/bppage"> 
             <BPMainPage/>
             </Route>

             <Route  path="/bgpage/:patient_group_id"> 
             <BGMainPage/>
             </Route>

             <Route  path="/bgpage"> 
             <BGMainPage/>
             </Route>

             {/* <Route  path="/vitcam"> 
             <BGMainPage/>
             </Route> */}

             <Route  path="/patientmonitor"> 
             <PatientMonInner/>
             </Route>

             <Route  path="/ventilator"> 
             <VentilatorInner/>
             </Route>

             <Route path="/authenticate_fitbit">
              <FitbitLoginPage/>
             </Route>

             {/* <Route path='/authenticate_withings' >
              <WithingsLoginPage/>
             </Route> */}

             <Route path="/authenticate_withings" component={WithingsLoginPage} />

             <Route path="/patient_signup_page">
              <SignUpv2/>
             </Route>

             <Route path="/register_page">
              <RegisterV2/>
              </Route>
              
              <Route path="/v1.2">
              <HomeDashboardv2/>
              </Route>

              <Route path="/homedashboardct">
              <HomeDashboard2/>
              </Route>

             <Route path="/myprofile">
             <ProfilePage/>
             </Route>

             <Route path="/medication_page">
             <MedicationPage/>
             </Route>

             <Route path="/mobile_login_page">
             <TwilioLogin/>
             </Route>

             <Route path="/mobile_signup_page">
             <TwilioSignUp/>
             </Route>

             <Route path="/alarm_management">
             <AlarmNewPage/>
             </Route>

             <Route path="/sample_v2">
             <DashboardMain/>
             </Route>

             <Route path="/forgot_password">
             <ForgotPassword/>
             </Route>

             <Route path="/email_verify">
             <EmailVerification/>
             </Route>

             <Route path="/reset_password">
             <ResetPassword/>
             </Route>
             

            <Route path="*">
              <NotFound />
            </Route>

            
          </Switch>
        </div>
      </div>
    </Router>
  );
}

export default App;

import React, { memo, useMemo } from "react";
import { useState, useEffect } from "react";
import ReactEcharts from "echarts-for-react";
import "./FNewDash.css";
import axios from "axios";
import { useSelector,useDispatch } from "react-redux";
import { format, formatRelative, subDays, endOfDay } from "date-fns";
import BreathDefault from "./DefaultCharts/BreathDefault";
import DistanceDefault from "./DefaultCharts/DistanceDefault";
import ExpandMorePopup from "../../../../../Common/ExpandMorePopup";
import FormBR from "./FormBR";
import PopupBG from "./PopBG";
import TileRemove from "../../../../home/TileRemove";



function BreathtChart({handleClose, onClick, careTakerPatientView=false}) {
  const groupName = useSelector((state) => state.auth.groupName);
  const token = useSelector((state) => state.auth.authToken);
 
  const dateHR = format(endOfDay(new Date()), "ddMMyyy");
  const [xAxisData, setXData] = useState([]);
  
  const breathDaily = useSelector((state) => state.dashboard.breathDaily);
  
  const dispatch = useDispatch();

  const alarmStatus= breathDaily?.data?.y_data?.status_point
 
  
  
  
 

  const y1Padding = React.useMemo(()=>{
    if( breathDaily?.data?.y_data?.br && breathDaily?.data?.y_data?.br.toString().length===2){
    return [0, 0, -60, -56]
    }
 else
    return [0, 0, -65, -69]
    
 },[])
  const chartData = React.useMemo(() => {
    return {
      
      title: [{
      
        text: "Breath Rate",
       
        
        left: "2%",
        top: "7%",
        textStyle: {
          fontWeight: "normal",
          fontSize: 23,
          color: "black",
        },
      },
      { subtext:breathDaily?.data?.x_data + " ago",
      left: "right",
      top:"25%",
      fontSize:12,
    },
    {
        subtext: "RR",
        left: "72%",
        top: "15%",
      
        fontSize: 14,
    },
    { subtext: breathDaily?.data?.y_data?.status , 
      left: "left", top: "25%", fontSize: "12" },
    ],
      tooltip:{ textStyle: {
        fontFamily: "sans-serif"
      },},
     
      
      series: [
       
        {
          
          type: 'gauge',
          radius: '30%',
          center: ['75%', '15%'],
          guageCategoryGap: "20%",
          startAngle: 360,
          endAngle: 0,

          min: false,
          max: 1,
          color:" #27A9E3",
          splitNumber: 12,
          itemStyle: {
            color: '#6D3DA3',
            
            shadowColor: 'blue',
            shadowBlur: 0,
            shadowOffsetX: 0,
            shadowOffsetY: 0
          },
          progress: {
            show: true,
            width: 8
          },
          pointer: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: false
          },
          axisLabel: {
            show: false
          },
         
          title: {
            show: false
          },
          detail: {
            // backgroundColor: '#fff',
            lineHeight: 0,
            height: 0,
            fontSize:20,
            fontWeight:"normal",
            color:" #434242",
            borderRadius: 8,
            offsetCenter: [0, '5%'],
            valueAnimation: true,
            
         
          },
          data: [
            {
               value: breathDaily?.data?.y_data?.br,
              
            }
          ]
        },
      ],
     
    };
  }, [breathDaily]);
  

 

  if(!(chartData.series[0].data[0].value)){
    return null
  }
  return (
    <div>
     
        <div className={"side-chart1" +(' alarm-status-'+alarmStatus) }>
        <div className="popup-bg" style={{display:'flex', justifyContent: 'right'}}>
          <ExpandMorePopup 
              option1={<PopupBG optionName="Manual Entry" FormTitle="Breath Rate" FormType={<FormBR careTakerPatientView={careTakerPatientView}/>} />}
              option2={<TileRemove
                careTakerPatientView={careTakerPatientView}
                parameter="BR" />}  /></div>
          
          <div onClick={onClick}>
          <ReactEcharts option={chartData} />{" "}
            </div>
        </div>
   
    </div>
  );
}

export default memo(BreathtChart);
import React from 'react'
import { useEffect } from 'react';
import constants from '../../../../../Common/Constants';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { Divider, Menu, MenuItem, Snackbar, Typography } from '@material-ui/core';
import styles from './MyFamily.module.css';
import PersonIcon from '@mui/icons-material/Person';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useHistory } from 'react-router-dom';
import LoaderCG from '../../../CareGiverPage/LoaderCG';
import { Cancel, Close } from '@mui/icons-material';
import { Alert } from '@material-ui/lab';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '@mui/material';

const MyFamilyComponent = () => {

  const history = useHistory();
  const [familyList, setFamilyList] = useState([]);
  const token = useSelector((state) => state.auth.authToken);
  const groupName = useSelector((state) => state.auth.groupName);
  const [isLoading, setIsLoading] = useState(true);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarColor, setSnackbarColor] = useState('');

  const [selectedPatientName, setsSelectedPatientName] = useState("");
  const [selectedPatientID, setsSelectedPatientID] = useState("");

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };


  const handleClickOpen = (patID, patName) => {
    setsSelectedPatientID(patID);
    setsSelectedPatientName(patName);
    setOpen(true);
  };


  const fetchFamiltyMemebers = () => {
    axios({
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      url: `${constants.API_URL}/list_patients/${groupName}/${token}/`,
    }).then(
      (res) => {
        console.log(res)
        setFamilyList(res?.data?.Requests);
        setIsLoading(false);
      });
  }

  useEffect(() => {
    fetchFamiltyMemebers();
  }, []);

  const handleDeleteFamilyMember = () => {

    const requestBody = JSON.stringify({ relation: "patient", rel_id: selectedPatientID })
    console.log(requestBody);

    axios({
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      url: `${constants.API_URL}/remove_family/${groupName}/${token}/`,
      data: requestBody,
    }).then(
      (res) => {
        console.log(res)
        setIsLoading(false);
        if (res?.status === 200) {
          setTimeout(() => {
            fetchFamiltyMemebers();
          }, 2000);
          setSnackbarMessage("Member Deleted");
          setSnackbarOpen(true);
          setSnackbarColor("success")
          handleClose();
        }
        else {
          setSnackbarMessage("Error");
          setSnackbarOpen(true);
          setSnackbarColor("error")
        }
      }).catch(
        (e) => {
          setSnackbarMessage("Something when wrong");
          setSnackbarOpen(true);
          setSnackbarColor("error")
        });
  }

  const handleViewFamilyDashboard = (pat_id) => {
    const buttonDisable = true;
    const viewOnly = true;
    history.push(`/familydashboard/${pat_id}`, { buttonDisable, viewOnly });
  };

  if (isLoading) {
    return <div style={{ marginTop: "-15%" }}><LoaderCG /></div>;
  }

  const vertical = "auto";
  const horizontal = "center";


  return (
    <div>
      <div className={styles.familyMainContainer}>
        <Typography style={{ fontFamily: "'Montserrat', sans-serif", fontSize: "0.9rem", fontWeight: "600", textAlign: "left" }}>List of Family Members {""}</Typography>
        <Divider style={{ backgroundColor: "black", marginBottom: "5px" }} />
        <div className={styles.listContainer1}>
          {familyList?.length === 0 ? (
            <div style={{ fontFamily: "'Montserrat', sans-serif", fontSize: "0.84rem", fontWeight: "500", textAlign: "left" }}>No family member</div>
          ) : (
            familyList?.map((family) => (
              <div key={family.pat_id}>
                <div className={styles.nameMainContainer}>
                  <div className={styles.nameContainer}>
                    <div className={styles.iconStyle}>  <PersonIcon /> </div>
                    <div className={styles.nameTypography}>{family.name} </div>
                    <div className={styles.iconStyle}><KeyboardArrowRightIcon /></div>
                    <div className={styles.nameTypography}> ID - {family.pat_id}</div>
                    <div className={styles.iconStyle}><KeyboardArrowRightIcon /></div>
                    <button className={styles.viewButton} onClick={() => handleViewFamilyDashboard(family.pat_id)} > View Dashboard</button>
                  </div>
                  <button className={styles.deleteButtonStyle}
                    // onClick={() => handleDeleteFamilyMember(family.pat_id)}
                    onClick={() => handleClickOpen(family.pat_id, family.name)}
                  >
                    <Cancel style={{ height: "15px", width: "15px" }} className={styles.closeIconsStyle} />
                    DELETE</button>
                </div>
                <Divider />

              </div>
            ))
          )
          }
        </div>
        <Dialog onClose={handleClose} open={open} >
          <div style={{ display: 'flex', justifyContent: 'right' }}>
            <Cancel className={styles.dialogCloseIconStyles} onClick={handleClose} />
          </div>

          <DialogTitle id="customized-dialog-title" onClose={handleClose} textAlign="center" >
            <div className={styles.dialogMainContainer}>
              <div className={styles.dialogTextStyle} >  Are you sure you want to DELETE this family member ? </div>
              <div className={styles.nameContainer}>
                <div className={styles.iconStyle}>  <PersonIcon /> </div>
                <div className={styles.nameTypography}>{selectedPatientName} </div>
                <div className={styles.iconStyle}><KeyboardArrowRightIcon /></div>
                <div className={styles.nameTypography}> ID - {selectedPatientID}</div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                <Button onClick={handleDeleteFamilyMember} className={styles.buttonStyleYes}>YES</Button>
                <Button className={styles.buttonStyleNo} onClick={handleClose}>NO</Button>
              </div>
            </div>
          </DialogTitle>

        </Dialog>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={() => setSnackbarOpen(false)}
          message={snackbarMessage}
          key={vertical + horizontal}
        >
          <Alert severity={snackbarColor} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </div>
    </div>
  )
}

export default MyFamilyComponent
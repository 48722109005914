import React from "react";
import ReactEcharts from "echarts-for-react";
import "./SleepPopup.module.css";
import CircularProgress from "@mui/material/CircularProgress";
import styles from './SleepPopup.module.css';
export const SleepBarChart = ({ title, xData, yData, handleClick }) => {
  const chartData = React.useMemo(() => {
    return {
      tooltip: {
        trigger: "axis",
        textStyle: {
          fontFamily: "sans-serif",
        },
        formatter: function (params) {
          // Customize the tooltip text
          let time = params?.[0]?.data;
          const hours = Math.floor(time / 60);
          const remainingMinutes = (time % 60).toFixed(0);
          return `${hours} Hours ${remainingMinutes} Minutes`;
        },
      },
      dataZoom: [
        {
          type: 'inside',
          start: 0,
          end: 100
        },
        {
          start: 10,
          end: 0
        }
      ],
      grid: { // Add grid property to set the margin
        top: "8%", // Adjust the bottom margin as needed
        containLabel: true, // Include axis labels and title inside the grid
      },
      xAxis: {
        type: "category",
   
        data: xData,
       
      },
      yAxis: {
        type: "value",
        axisLabel: {
          formatter: function (value) {
            // Convert the value to hours and minutes format
            const hours = Math.floor(value / 60);
            const minutes = value % 60;
            return `${hours}:${minutes < 10 ? "0" : ""}${minutes}`;
          },
        },
      },

      series: [
        {
          name: "Minutes",
          type: "bar",
          areaStyle: {},

          symbol: "none",

         
          color: "#6D3DA3",


          data: yData,
        },
      ],
    };
  }, [xData, yData, title]);

  return (
    <div style={{ height: "100%" }}>
      {chartData?.series?.[0]?.data?.length ? (
        <div className="left-chart">
          <ReactEcharts
            option={chartData}
            onEvents={{
              click: handleClick,
            }}
            style={{ width: "fitContent" }}
          />
        </div>
      ) : (
        <p className={styles.noDataStyles}>
         {/* <CircularProgress /> */}
         No Data
        </p>
      )}
    </div>
  );
};

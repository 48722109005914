import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './TwilioCommon.css'
import image1 from './image1.png'
import { Button, Divider, Snackbar } from '@material-ui/core';
import axios from 'axios';
import constants from '../../../../Common/Constants'
import { Alert } from '@material-ui/lab';
import MobileImage from './mypasswordamico.png'
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useDispatch } from "react-redux";
import {
  addAuthToken,
  addDeviceList,
  addGroupName,
  addGroupDevices,
  addHeaderGroupName,
  addPageDevices,
  addPageDeviceFavorite,
  addNoOfPages,
  addPresentPage,
  setCareTaker,
  setUserData,
  setIsDoctor,
  setCareTakerToken,
} from "../../../../store/authSlice";

function TwilioLogin() {

    const [otp, setOtp] = useState(['', '', '', '', '','']);
    const [countryCode, setCountryCode] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [otpView, setOtpView] = useState(false);
    const [mobileNumberView, setMobileNumberView] = useState(true);
    const [open, setOpen] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarColor, setSnackbarColor] = useState('');
    const [path, setPath] = useState("home");

    const history =useHistory();
    const dispatch = useDispatch();

    const handleClose = () => {
      setOpen(false);
    };

    const handleChange = (index, value) => {
      const updatedOtp = [...otp];
      updatedOtp[index] = value;
      setOtp(updatedOtp);
    };
  
    const handleKeyUp = (event, index) => {
      if (event.key === 'Backspace' || event.key === 'Delete') {
        const previousIndex = index - 1;
        if (previousIndex >= 0) {
          event.target.form.elements[previousIndex].focus();
        }
      } else {
        const nextIndex = index + 1;
        if (nextIndex < otp.length) {
          event.target.form.elements[nextIndex].focus();
        }
      }
    };
  
    const handlePhoneNumberChange = (value) => {
      setPhoneNumber(value);

    };
  
    const handleCountryCodeChange = (value) => {
      setCountryCode(value);
    };

    const handleTryAgain =() =>{
      setOtp(['', '', '', '', '','']);
      setCountryCode('');
      setPhoneNumber('');
      setMobileNumberView(true);
      setOtpView(false);
    }

    var userPhoneNumber = JSON.stringify({mobile:"+" + phoneNumber});
    // console.log("khuhuk",userPhoneNumber)
    const handleSubmit = (e) => {
      e.preventDefault();

      //the phone number and country code
      console.log('Country Code:', countryCode);
      console.log('Phone Number:', phoneNumber);
      console.log("userPhoneNumber",userPhoneNumber)
      // setOpen(true);
      // setOtpView(true);
      // console.log(otpView);
      // setMobileNumberView(false);
      
      axios({
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        url: `${constants.API_URL}/gen_otp_login/`,
        data: userPhoneNumber,
      
      }).then(
        (res) => { 
                 console.log(res)
                 if (res.status===202) {
                 console.log(res)
                 setSnackbarMessage('OTP sent successfully!');
                 setSnackbarColor("success")
                 setTimeout(() => {
                  setOtpView(true);
                  setMobileNumberView(false);
                    }, 2000);
                    clearTimeout();
                 } 
                 else{
                   setSnackbarMessage('Error occurred while sending OTP.');
                   setSnackbarOpen(true);
                   console.log(snackbarMessage)
                 }
                 setSnackbarOpen(true);         
        }).catch((e) =>{
          if (e.response.status == 500)
          {
            setSnackbarMessage("Something went wrong");
            setSnackbarOpen(true);
            setSnackbarColor("error")
          }
          else if (e.response.status == 400){
            setSnackbarOpen(true);
            setSnackbarColor("error")
            setSnackbarMessage(e.response.data.message);
          }
          else{
            setSnackbarOpen(true);
            setSnackbarColor("error")
            setSnackbarMessage("Something went wrong");
          }
        })
    };
    
    var userOtp =  JSON.stringify({mobile:"+" + phoneNumber, code:otp.join('')});
    
    const handleSubmitOTP = (event) => {
      event.preventDefault();
      console.log(otp)
      console.log(userOtp)
      console.log(otp.join('')); // Combine OTP digits into a single string
      axios({
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        url: `${constants.API_URL}/verify_otp/`,
        data: userOtp,
      
      }).then((response) => {
        console.log("SignIn Response", response);
        // setErrorMessage(false);
        const userData = [];

        if (response?.status === 200) {
          userData.push(response.data);
          console.log(userData);
          dispatch(addGroupName(userData?.[0]?.id));
          dispatch(setUserData(userData));
          dispatch(addAuthToken(userData?.[0]?.token));
          dispatch(addHeaderGroupName(userData?.[0]?.group_name));
          dispatch(setIsDoctor(userData?.[0]?.is_doctor));
          history.push("/" + path);
        } else {
          // setErrorMessage(true);
          setSnackbarOpen(true);
          setSnackbarMessage("Something went wrong");
          setSnackbarColor("error")
        }

      }).catch(error => {
        if (error.response.status === 400) {
          // setErrorMessage(true);
          setSnackbarOpen(true);
          setSnackbarMessage(error.response.data.message);
          setSnackbarColor("error")
        }
        // setErrorMessage(true);
        setSnackbarOpen(true);
        setSnackbarMessage(error.response.data.message);
        setSnackbarColor("error")
        console.log("error")
        console.log(error.response.status)

        console.log(error.response.data);
        // show error message to user
        // for example:
        // alert("Login failed: " + error.response.data.message);
      })
    };


     const handleBackToRegister = ()=>{
      history.push("/")
     }

    const action = (
      <Button color="inherit" size="small" onClick={handleClose}>
        Close
      </Button>
    );

    const vertical = "bottom"; // Specify the desired vertical position (e.g., "top", "bottom")
    const horizontal = "center"; 
  
    return (
        <div className='main-container-otp'>
      <div className='mobile-image'>
        <div>
            <span style={{ color: "#6A4396",fontWeight:"700", fontFamily: "'Montserrat', sans-serif", fontSize:"1.6rem" }}>Care</span>
            <span style={{ color: "#23AF72",fontWeight:"700", fontFamily: "'Montserrat', sans-serif",fontSize:"1.6rem"  }}>HQ</span>
              </div>
        <div className='heading-mobileotp'>
        <img src={MobileImage} style={{width:"80%", height:"100%"}} alt="img" />
        </div>
        
    
        <div className='otp-verfication-heading'>OTP Verification</div>
        <Divider style={{width:"100%", color:"white", height:"2px", backgroundColor:"black", margin:"5px 2px 5px 2px"}}></Divider>
        
        <div className='form-wrap-otp'>
            { mobileNumberView &&(
              
        <form onSubmit={handleSubmit} className='form-container'>
          <div style={{fontFamily:"'Montserrat', sans-serif", fontWeight:"400", fontSize:"0.8rem"}}>Enter the Mobile Number</div>
        {/* <div>
          <label htmlFor="countryCode">Country Code:</label>
          <PhoneInput
            id="countryCode"
            country={'in'}
            value={countryCode}
            onChange={handleCountryCodeChange}
          />
        </div>  */}
        
         <div style={{alignItems:"center", justifyContent:"center"}}>
          <div className="number-field-mobileotp">
          <PhoneInput 
            id="phoneNumber"
            country={countryCode}
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            inputStyle={{width:"100%",alignItems:"center", justifyContent:"center"}}
          />
          </div>
        </div>
        <Button type="submit" className='send-otp-div' >Send OTP</Button>
        <div style={{ fontFamily:"'Montserrat', sans-serif",fontSize:"0.7rem", marginTop:"20px", cursor:"pointer"}} onClick={handleBackToRegister}><ArrowBackIosIcon style={{height:"12px", width:"10px"}}/>Back</div>
      </form>
)}
 {otpView &&(

<form onSubmit={handleSubmitOTP}>
<div style={{fontFamily:"'Montserrat', sans-serif", fontWeight:"400", fontSize:"0.8rem"}}>Enter the OTP and Verify</div>
        <div className="otpContainer">
          {otp.map((digit, index) => (
            <input
              key={index}
              name={`otp${index + 1}`}
              type="text"
              autoComplete="off"
              className="otpInput"
              value={digit}
              maxLength="1"
              onChange={(e) => handleChange(index, e.target.value)}
              onKeyUp={(e) => handleKeyUp(e, index)}
            />
          ))}
        </div>
        <Button className="send-otp-div" type="submit" onClick={handleSubmitOTP}>
          Verify
        </Button>
        <div style={{ fontFamily:"'Montserrat', sans-serif",fontSize:"0.6rem", marginTop:"10px", cursor:"pointer"}} onClick={handleTryAgain}>Try Again</div>
      </form>
)}
       </div>
        </div>
      
      {/* <div dangerouslySetInnerHTML={{ __html: htmlContent }} /> */}
        <Snackbar
        anchorOrigin={{ vertical, horizontal }}
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={() => setSnackbarOpen(false)}
          message={snackbarMessage}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={snackbarColor} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </div>
    );
  };
  

export default TwilioLogin;
import React, { useState, useEffect } from "react";
import styles from "./BPChartPopup.module.css";
import { Tabs } from "react-bootstrap";
import { BPContainer } from "./BPContainer";
import Tab from "react-bootstrap/Tab";
import axios from "axios";
import constants from "../../../../Common/Constants";
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import { endOfDay, format } from "date-fns";
import { MoreHoriz } from "@material-ui/icons";
import { Snackbar } from "@mui/material";
import PopUpManualEntry from "../OtherComponents/PopUpManualEntry";
import FormBP from "../../../Member Details/Comps/FitbitFolder/FitCharts/FormBP";

const BPChartPopup = () => {
  const [tabValue, setTabValue] = useState("week");
  const groupName = useSelector((state) => state.auth.groupName);
  const token = useSelector((state) => state.auth.authToken);
  const careTakerToken = useSelector((state) => state.auth.careTakerToken);
  const currentUserIndex = useSelector((state) => state.auth.currentUserIndex);
  const dateHR = format(endOfDay(new Date()), "ddMMyyy");
  const userData = useSelector((state) => state.auth.userData);
  const location = useLocation();
  const urlStateData = location?.state;
  const { id, patient_group_id } = useParams();
  const [refreshId,setId]= useState(0);

  const viewOnly = location?.state;
  const {pat_id} = useParams();

  const handleChange = (value) => {
    setTabValue(value);
  };
  const [showPopup, setShowPopup] = useState(false);

  const openPopup = () => {
    if (viewOnly?.viewOnly == true) {
      setShowPopup(false)
    } else {
      setShowPopup(true);
    }
  };
  
  const closePopup = (event) => {
    event.stopPropagation();
    setShowPopup(false);
  };
  const [response, setResponse] = useState(null); 
  const [open, setOpen] = React.useState(false);
  const [dayData, setDayData] = useState({
    xData: [],
    yData1: [],
    yData2: [],
    bpRes: [],
  });
  const [monthData, setMonthData] = useState({
    xData: [],
    yData1: [],
    yData2: [],
    bpRes: [],
  });
  const [weekData, setWeekData] = useState({
    xData: [],
    yData1: [],
    yData2: [],
    bpRes: [],
  });
  const [threeMonthData, setThreeMonthData] = useState({
    xData: [],
    yData1: [],
    yData2: [],
    bpRes: [],
  });

  let x = [];
  let y1 = [];
  let y2 = [];
  let bpResult = [];

  const deleteLastEntry = () => {
    axios.get(`${constants.API_URL}/delete_latest_manual_entry/${ urlStateData?.patientView? patient_group_id:groupName}/BP/${token}/`).then((res) => {
      if (res.data.message === "success") {
        setResponse('success');
        setOpen(true);
           // Update the dayData state to reflect the changes
           const updatedDayData = { ...dayData };
           updatedDayData.xData.pop(); // Remove the last entry from xData
           updatedDayData.yData1.pop(); // Remove the last entry from yData1
           updatedDayData.yData2.pop(); // Remove the last entry from yData2
           updatedDayData.bpRes.pop(); // Remove the last entry from bpRes
           setId(Math.random()*600);
           setWeekData(updatedWeekData);
         // Update the weekData state to reflect the changes
         const updatedWeekData = { ...weekData };
         updatedWeekData.xData.pop(); // Remove the last entry from xData
         updatedWeekData.yData1.pop(); // Remove the last entry from yData1
         updatedWeekData.yData2.pop(); // Remove the last entry from yData2
         updatedWeekData.bpRes.pop(); // Remove the last entry from bpRes
         setId(Math.random()*600);
         setWeekData(updatedWeekData);

         const updatedMonthData = { ...monthData };
         updatedMonthData.xData.pop(); // Remove the last entry from xData
         updatedMonthData.yData1.pop(); // Remove the last entry from yData1
         updatedMonthData.yData2.pop(); // Remove the last entry from yData2
         updatedMonthData.bpRes.pop(); // Remove the last entry from bpRes
         setMonthData(updatedMonthData);

         const updatedThreeMonthData = { ...threeMonthData };
         updatedThreeMonthData.xData.pop(); // Remove the last entry from xData
         updatedThreeMonthData.yData1.pop(); // Remove the last entry from yData1
         updatedThreeMonthData.yData2.pop(); // Remove the last entry from yData2
         updatedThreeMonthData.bpRes.pop(); // Remove the last entry from bpRes
         setThreeMonthData(updatedThreeMonthData);

      } else if (res.data.message === "No data found") {
        setResponse('noData');
        setOpen(true);
      } else {
        setResponse('error');
        setOpen(true);
      }
   
    })
    .catch((error) => {
      setResponse('error');
      setOpen(true);
  
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  
  const refreshChartData = () => {

    console.log("Refreshing Chart Data",tabValue);

    // let x = [];
    // let y1 = [];
    // let y2 = [];
    // let bpResult = [];

    // setDayData({ xData: [], yData1: [], yData2: [], bpRes: [] });
    // setMonthData({ xData: [], yData1: [], yData2: [], bpRes: [] });
    // setThreeMonthData({ xData: [], yData1: [], yData2: [], bpRes: [] });
    // setWeekData({ xData: [], yData1: [], yData2: [], bpRes: [] });

    if (tabValue === "day" ) {
      setTimeout(() => {
      let url = `${constants.API_URL}/day_data/BP/${
        viewOnly?.viewOnly? pat_id : urlStateData?.patientView? patient_group_id : groupName
      }/`;
      url += `${dateHR}/${token}/`;
      axios.get(url).then((res) => {
        res.data.day_data?.forEach((data) => {
          var epochTS = data.x_data;

          var d = new Date(parseInt(epochTS));

          let tsnow = `${d.toLocaleDateString()}\n${d.toLocaleTimeString()}`;

          x.push(tsnow);
          y1.push(data.y_data.BPS);
          y2.push(data.y_data.BPD);
          bpResult.push(data);
        });

        setDayData({ xData: x, yData1: y1, yData2: y2, bpRes: bpResult });
      });
    },4500)
    } 
    else if (tabValue === "week" ) {
      setTimeout(() => {
      let url = `${constants.API_URL}/week_data/BP/${
        viewOnly?.viewOnly? pat_id : urlStateData?.patientView? patient_group_id : groupName
      }/`;
      url += `${dateHR}/${token}/`;
      axios.get(url).then((res) => {
        res.data.week_data?.forEach((data) => {
          var epochTS = data.x_data;

          var d = new Date(parseInt(epochTS));

          let tsnow = `${d.toLocaleDateString()}\n${d.toLocaleTimeString()}`;

          x.push(tsnow);
          y1.push(data.y_data.BPS);
          y2.push(data.y_data.BPD);
          bpResult.push(data);
        });

        setWeekData({ xData: x, yData1: y1, yData2: y2, bpRes: bpResult });
      });
    },4500)
    } else if (tabValue === "month") {
      setTimeout(() => {
      let url = `${constants.API_URL}/month_data/BP/${
        viewOnly?.viewOnly? pat_id : urlStateData?.patientView ? patient_group_id : groupName
      }/`;

      url += `${dateHR}/${token}/`;
      axios.get(url).then((res) => {
        res.data.month_data?.forEach((data) => {
          var epochTS = data.x_data;
          var d = new Date(parseInt(epochTS));
          let tsnow = `${d.toLocaleDateString()}\n${d.toLocaleTimeString()}`;
          x.push(tsnow);
          y1.push(data.y_data.BPS);
          y2.push(data.y_data.BPD);
          bpResult.push(data);
        });

        setMonthData({ xData: x, yData1: y1, yData2: y2, bpRes: bpResult });
      });
    },4500)
    } else if (tabValue === "threeMonth") {
      setTimeout(() => {
      let url = `${constants.API_URL}/three_month_data/BP/${
        viewOnly?.viewOnly? pat_id : urlStateData?.patientView ? patient_group_id : groupName
      }/`;

      url += `${dateHR}/${token}/`;
      axios.get(url).then((res) => {
        res.data.three_month_data?.forEach((data) => {
          var epochTS = data.x_data;
          var d = new Date(parseInt(epochTS));
          let tsnow = `${d.toLocaleDateString()}\n${d.toLocaleTimeString()}`;
          x.push(tsnow);
          y1.push(data.y_data.BPS);
          y2.push(data.y_data.BPD);
          bpResult.push(data);
        });

        setThreeMonthData({
          xData: x,
          yData1: y1,
          yData2: y2,
          bpRes: bpResult,
        });
      });
    },4500)
    }

  }


  useEffect(() => {
    if (tabValue === "day" && !dayData?.xData?.length) {
      let url = `${constants.API_URL}/day_data/BP/${
        viewOnly?.viewOnly? pat_id : urlStateData?.patientView? patient_group_id : groupName
      }/`;
      url += `${dateHR}/${token}/`;
      axios.get(url).then((res) => {
        res.data.day_data?.forEach((data) => {
          var epochTS = data.x_data;

          var d = new Date(parseInt(epochTS));

          let tsnow = `${d.toLocaleDateString()}\n${d.toLocaleTimeString()}`;

          x.push(tsnow);
          y1.push(data.y_data.BPS);
          y2.push(data.y_data.BPD);
          bpResult.push(data);
        });

        setDayData({ xData: x, yData1: y1, yData2: y2, bpRes: bpResult });
      });
    } 
    else if (tabValue === "week" && !weekData?.xData?.length) {
      let url = `${constants.API_URL}/week_data/BP/${
        viewOnly?.viewOnly? pat_id : urlStateData?.patientView? patient_group_id : groupName
      }/`;
      url += `${dateHR}/${token}/`;
      axios.get(url).then((res) => {
        res.data.week_data?.forEach((data) => {
          var epochTS = data.x_data;

          var d = new Date(parseInt(epochTS));

          let tsnow = `${d.toLocaleDateString()}\n${d.toLocaleTimeString()}`;

          x.push(tsnow);
          y1.push(data.y_data.BPS);
          y2.push(data.y_data.BPD);
          bpResult.push(data);
        });

        setWeekData({ xData: x, yData1: y1, yData2: y2, bpRes: bpResult });
      });
    } else if (tabValue === "month" && !monthData?.xData?.length) {
      let url = `${constants.API_URL}/month_data/BP/${
        viewOnly?.viewOnly? pat_id : urlStateData?.patientView ? patient_group_id : groupName
      }/`;

      url += `${dateHR}/${token}/`;
      axios.get(url).then((res) => {
        res.data.month_data?.forEach((data) => {
          var epochTS = data.x_data;
          var d = new Date(parseInt(epochTS));
          let tsnow = `${d.toLocaleDateString()}\n${d.toLocaleTimeString()}`;
          x.push(tsnow);
          y1.push(data.y_data.BPS);
          y2.push(data.y_data.BPD);
          bpResult.push(data);
        });

        setMonthData({ xData: x, yData1: y1, yData2: y2, bpRes: bpResult });
      });
    } else if (tabValue === "threeMonth" && !threeMonthData?.xData?.length) {
      let url = `${constants.API_URL}/three_month_data/BP/${
        viewOnly?.viewOnly? pat_id : urlStateData?.patientView ? patient_group_id : groupName
      }/`;

      url += `${dateHR}/${token}/`;
      axios.get(url).then((res) => {
        res.data.three_month_data?.forEach((data) => {
          var epochTS = data.x_data;
          var d = new Date(parseInt(epochTS));
          let tsnow = `${d.toLocaleDateString()}\n${d.toLocaleTimeString()}`;
          x.push(tsnow);
          y1.push(data.y_data.BPS);
          y2.push(data.y_data.BPD);
          bpResult.push(data);
        });

        setThreeMonthData({
          xData: x,
          yData1: y1,
          yData2: y2,
          bpRes: bpResult,
        });
      });
    }
  }, [tabValue,refreshChartData]);

  return (
    <div className={styles.mainContainer}>
      <div className={styles.heading}>
        <div>Blood Pressure</div>
        <div style={{ color: "grey", cursor: "pointer" }} >
          <MoreHoriz onClick={openPopup}/>
        </div>
      </div>
      <div className={styles.contentDiv}>
        <div className={styles.tabContainer}>
        <div
            className={`${styles.tab} ${
              tabValue === "day" && styles.activeTab
            }`}
            onClick={() => handleChange("day")}
          >
            DAY
          </div>
          <div
            className={`${styles.tab} ${
              tabValue === "week" && styles.activeTab
            }`}
            onClick={() => handleChange("week")}
          >
            WEEK
          </div>
          <div
            className={`${styles.tab} ${
              tabValue === "month" && styles.activeTab
            }`}
            onClick={() => handleChange("month")}
          >
            MONTH
          </div>
          <div
            className={`${styles.tab} ${
              tabValue === "threeMonth" && styles.activeTab
            }`}
            onClick={() => handleChange("threeMonth")}
          >
            3 MONTHS
          </div>
        </div>
        <div className={styles.tabContent}  key={refreshId}>
        {tabValue === "day" && (
            <BPContainer
              title="Daily Report"
              bpRes={dayData?.bpRes}
              xData={dayData?.xData}
              yData1={dayData?.yData1}
              yData2={dayData?.yData2}
              type="day"
            />
          )}
          {tabValue === "week" && (
            <BPContainer
              title="Weekly Report"
              bpRes={weekData?.bpRes}
              xData={weekData?.xData}
              yData1={weekData?.yData1}
              yData2={weekData?.yData2}
              type="week"
            />
          )}
          {tabValue === "month" && (
            <BPContainer
              title="Monthly Report"
              bpRes={monthData?.bpRes}
              xData={monthData?.xData}
              yData1={monthData?.yData1}
              yData2={monthData?.yData2}
              type="month"
            />
          )}
          {tabValue === "threeMonth" && (
            <BPContainer
              title="3 Months Report"
              bpRes={threeMonthData?.bpRes}
              xData={threeMonthData?.xData}
              yData1={threeMonthData?.yData1}
              yData2={threeMonthData?.yData2}
              type="threeMonth"
            />
          )}
        </div>
      </div>
      {showPopup && (
        <div className={styles.popup}>
          <div className={styles.popupOption1}>
            <PopUpManualEntry
              optionName={"Manual Entry"}
              FormTitle={"Blood Pressure"}
              FormType={<FormBP refreshChartData={refreshChartData} style={{ zIndex: "9999" }} />}
            />
          </div>
          <div className={styles.popupOption} onClick={deleteLastEntry}>
            Delete Last Entry
          </div>
          <div className={styles.popupOption} onClick={(event) => closePopup(event)}>
            Cancel
          </div>
        </div>
      )}
     <Snackbar
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
      message={
        response === "success" ? "Successfully deleted the last entry!" : "No recent data found!"
      }
    />
    </div>
  );
};

export default BPChartPopup;

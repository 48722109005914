import React, { useState } from 'react'
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import CloseButton from 'react-bootstrap/CloseButton';
import axios from 'axios';
import {useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import constants from '../../Common/Constants';
import "./Home.css";
import {
    setDashboardData,
    setWebsocketStatus,
  } from "../../store/dashboardSlice";
import { CheckCircle } from '@material-ui/icons';

function TileRemove({careTakerPatientView=false,parameter}) {

    const [showPopup, setShowPopup] = useState(false);

    const dispatch = useDispatch();

    const [open, setOpen] = React.useState(false);

    const token = useSelector((state) => state.auth.authToken);
    const groupName = useSelector((state) => state.auth.groupName);

    const careTakerToken = useSelector((state)=>state.auth.careTakerToken);

    const {id, patient_group_id} =useParams();

    const location = useLocation();
    const urlStateData = location?.state



    const handleClickOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };
    let data;

    const fetchDataApi = async () => {
        let url = `${constants.API_URL}/dashboard_values/${careTakerPatientView?patient_group_id:groupName}/${careTakerPatientView?careTakerToken:token}/`;

        // url += `${token}/`;
  

        const result = await axios.get(url);
        dispatch(setDashboardData(result.data.data));
        };



    const handleDeleteApi = () =>{
        axios({
            method: "get",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            url: `${constants.API_URL}/user_priority/${parameter}/${careTakerPatientView?patient_group_id:groupName}/${careTakerPatientView?careTakerToken:token}/`

          }).then(
            (res) => {
                if (res.status===200) 
                     handleClose() ;
             
                     setShowPopup(true);

                     setTimeout(() => {
                        // show pop up message
                        setShowPopup(false);

                      }, 3000);
                      clearTimeout();

                      setTimeout(() => {
                        // show pop up message
                       fetchDataApi();
              
                      }, 6000);
                      clearTimeout();
            }); 
    }

   
  
     const handleAnimationEnd = () => {
        setShowPopup(false);
        // call the onClose function when the animation ends
      };

    return (
      <div>
         {showPopup && (
  <div className="popup" >
    <p>Tile successfully removed!<CheckCircle/> </p> 

  </div>
)}
        <Button style={{height:15}} color='primary'
        onClick={handleClickOpen}>
          REMOVE TILE
        </Button>
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <div style={{display:'flex', justifyContent: 'right'}}>
              <CloseButton onClick={handleClose} />
              </div>

          <DialogTitle id="customized-dialog-title" onClose={handleClose} textAlign="center" >
           <div className='removeTile' style={{ fontSize:"1rem", color:"rgb(14, 68, 244)", marginBottom:"15px"}}>  Are you sure you want to remove the tile ? </div>
           <div style={{display:"flex",justifyContent:"center", alignItems:"center"}}>

              <div> <Button className="remove-yes-btnn" style={{color:"white", backgroundColor:"rgb(14, 68, 244)", borderRadius:"20px", marginRight:"5px", fontSize:"0.6rem"}} onClick={handleDeleteApi}> Yes </Button> </div>

              <div> <Button className="remove-no-btnn" style={{color:"white", backgroundColor:"rgb(14, 68, 244)", borderRadius:"20px", marginLeft:"5px", fontSize:"0.6rem"}} onClick={handleClose}> No </Button> </div>

           </div>


          </DialogTitle>

        </Dialog>
      </div>

    ) }

export default TileRemove
import React from "react";
import { Fab } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./Schedule.module.css";
import { useHistory, useLocation } from "react-router-dom";
import AddScheduleModal from "./AddScheduleModal";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  floatingButton: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(5),
    zIndex: 999,
    backgroundColor: "white",
    border:"none"
  },
}));

function Medication2FloatingButton() {
  const classes = useStyles();
  const [modalOpen, setModalOpen] = useState(false);

  const location = useLocation();
  const viewablityConstant = location?.state;

  const handleButtonClick = () => {
    if (viewablityConstant?.viewMode == true) {
      setModalOpen(false);
    } else {
      setModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <div>
      {/* <Fab className={classes.floatingButton}
        onClick={handleButtonClick}>
         <AddIcon className={styles.addIcon}/>
        </Fab> */}
      <button onClick={handleButtonClick} className={styles.floatBtn}>
        ADD/EDIT
      </button>
      <AddScheduleModal open={modalOpen} onClose={handleCloseModal} />
    </div>
  );
}

export default Medication2FloatingButton;

import React, { useState } from 'react';
import './DashboardCommon.css'; // Import the CSS file
import MyHealthComponent from './MyHealthComponent';
import MyPatientComponent from './MyPatientComponent';
import Headerv2 from '../Headerv2';
import { useSelector } from 'react-redux';
import { Add } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserPlus, faPaperPlane, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import { Avatar, Divider, Menu, MenuItem, Typography } from '@material-ui/core';
import Person2Icon from '@mui/icons-material/Person2';
import { useEffect } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import axios from 'axios';
import constants from '../../../../Common/Constants';
import { Alert } from '@material-ui/lab';
import { Snackbar } from '@material-ui/core';
import MyFamilyComponent from './MyHealthComponent/MyFamilyComponent';
import HomeIcon from '@mui/icons-material/Home';
import MedicationIcon from '@mui/icons-material/Medication';
import AlarmTest from '../AlarmView/AlarmTest';
import CureGPTIntegration from './CureGPT/CureGPTIntegration';

const DashboardMain = () => {

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const history = useHistory();

  const handleShowMemberList = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const PATHS = {
    home : '/home',
    profile:'/myprofile',
    alarm:'/alarm_management'
}
const isActive = ( path )=>{
  return path === location.pathname ;
}

  const profileClick = () => {
    history.push(PATHS.profile)
  }
 
  const HomePage = () => {
    history.push(PATHS.home)
  }
  const handleToAlarmSettings = () => {
    history.push(PATHS.alarm)
  }
 
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarColor, setSnackbarColor] = useState('');

  const token = useSelector((state) => state.auth.authToken);
  const groupName = useSelector((state) => state.auth.groupName);

  const isDoctor = useSelector((state) => state.auth.isDoctor);
  const isPatient = useSelector((state) => state.auth.isPatient);

  const [activeTab, setActiveTab] = useState( () => {
    if (isPatient && isDoctor) {
      return 'My Patients';
    } else if (isPatient && !isDoctor) {
      return 'My Health';
    } else if (!isPatient && isDoctor) {
      return 'My Patients';
    } else {
      // You can set a default tab here if needed
      return 'My Health';
    }
  });

  const [searchQuery, setSearchQuery] = useState('');

  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const activeTabParam = searchParams.get('activeTab');
    if (activeTabParam) {
      setActiveTab(activeTabParam);
    }
  }, [location.search]);

 

  const [memberList, setMemberList] = useState([]);

  useEffect(() => {
    axios({
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      url: `${constants.API_URL}/users_list`,
    }).then(
      (res) => {
        setMemberList(res?.data?.users)
      });

  }, []);

  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearchQuery(value);
  };


  const handleSendRequest = (id) => {
    const reqData = { id, "relation": "family" }
    console.log(reqData)

    axios({
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      url: `${constants.API_URL}/sent_request/${groupName}/${token}/`,
      data: reqData
    }).then(
      (res) => {
        console.log(res)
        if (res?.status === 200) {
          setSnackbarOpen(true);
          setSnackbarColor('success');
          setSnackbarMessage('Request Sent');
        }
      }).catch(error => {
        console.log(error?.response?.data?.message)
        if (error?.response?.status === 400) {
          setSnackbarOpen(true);
          setSnackbarColor('error');
          setSnackbarMessage(error?.response?.data?.message);
        } else if (error?.response?.status === 404) {
          setSnackbarOpen(true);
          setSnackbarColor('error');
          setSnackbarMessage('Something went wrong');
        } else {
          setSnackbarOpen(true);
          setSnackbarColor('error');
          setSnackbarMessage('Something went wrong');
        }
      });
    // const updatedMembers = memberList.map((member) =>
    //   member.id === id ? { ...member, isRequestSent: true } : member
    // );
    // setMemberList(updatedMembers);
  };


  const filteredMembers = memberList?.filter((member) =>
    member?.name?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  if (location.pathname === '/home?activeTab=My%20Patients' && activeTab === 'My Patients') {
    history.push('/home');
  }
  if(location.pathname === '/home?activeTab=Family%20Members' && activeTab === 'Family Members') {
    history.push('/home');
  }


  return (
    <div className='dashboardMainView'>
      <Headerv2 />
      <div className='patientSwitchingTab'>
        <div className='sidebarDashboard'>
          
        <div className={['sidebarIcon' , isActive(PATHS.home)?'active':''].join(" ")} onClick={HomePage}>
            <HomeIcon style={{height:"20px"}}/>
            <p style={{fontSize:"small",fontFamily: "Montserrat, sans-serif"}}>Home</p>
            </div>
          
            <div className='sidebarIcon' onClick={profileClick}>
            <Person2Icon style={{height:"20px"}}/>
            <p style={{fontSize:"small",fontFamily: "Montserrat, sans-serif"}}>My Profile</p>
            </div>
            <div className='sidebarIcon' onClick={handleToAlarmSettings}>
            <AccessAlarmsIcon style={{height:"20px"}}/>
            <p style={{fontSize:"small",fontFamily: "Montserrat, sans-serif"}}>Alarm Settings</p>
            </div>
            
        </div>
        <div className='mainVital'>
        <div className="tabs">
          <div className='tabsContainer'>
            <button
              className={activeTab === 'My Health' ? 'active' : ''}
              onClick={() => handleTabClick('My Health')}
            >
              My Health
            </button>
            {isDoctor && (
              <button
                className={activeTab === 'My Patients' ? 'active' : ''}
                onClick={() => handleTabClick('My Patients')}
              >
                My Patients
              </button>
            )}
            <button
              className={activeTab === 'Family Members' ? 'active' : ''}
              onClick={() => handleTabClick('Family Members')}
            >
              Family
            </button>
              {isDoctor && (
                <button
                  className={activeTab === 'Alarms' ? 'active' : ''}
                  onClick={() => handleTabClick('Alarms')}
                >
                  Alarms
                </button>
              )}
            <button
              className={activeTab === 'CureGPT' ? 'active' : ''}
              onClick={() => handleTabClick('CureGPT')}
            >
              CureGPT
            </button>
          </div>
          <div className='addFamilyBtn'>
            <FontAwesomeIcon size='lg' icon={faUserPlus} color="#7F55AE" onClick={handleShowMemberList} />
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleMenuClose}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            >
              <div className='searchMemberList'>
                <Typography style={{ fontFamily: "'Montserrat', sans-serif", fontWeight: "500" }}>Members</Typography>
                <input
                  type="text"
                  placeholder="Search"
                  value={searchQuery}
                  className='searchNameBtn'
                  onChange={handleSearchChange}
                />
                {filteredMembers?.length > 0 ? (
                  <div className="memberList">
                    {filteredMembers?.map((member) => (
                      <div className='nameAndReq' key={member?.id}><div style={{ marginRight: "10px", display: "flex", flexDirection: "row", alignItems: "center" }}><Avatar style={{ height: "20px", width: "20px", marginRight: "5px" }} />{member?.name ? member?.name : member?.id}</div>
                        <div className='sendReqbutton' onClick={() => handleSendRequest(member?.id)}> Send Request {""}
                          <FontAwesomeIcon icon={faArrowUp} iconStyle="light" color="#7F55AE"
                            style={{ color: member?.isRequestSent ? 'gray' : '#7F55AE' }}
                            disabled={member?.isRequestSent}
                          // onClick={() => handleSendRequest(member?.id)}
                          />

                        </div>
                      </div>


                    ))}
                  </div>

                ) : (
                  <div className='noMatchingResult'>No matching results</div>
                )}
              </div>
            </Menu>
          </div>
        </div>
        <div className="main-content">
          {activeTab === 'My Health' && <MyHealthComponent />}
          {isDoctor && (activeTab === 'My Patients' && <MyPatientComponent />)}
          {activeTab === 'Family Members' && < MyFamilyComponent />}
          {/* {activeTab === 'Alarms' && <AlarmTest/>} */}
          {isDoctor && (activeTab === 'Alarms' && <AlarmTest/>)}
          {activeTab === 'CureGPT' && <CureGPTIntegration/> }
        </div>
      </div>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        key={"top" + "right"}
      >
        <Alert severity={snackbarColor} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default DashboardMain;
import React, { memo } from "react";
import { useState, useEffect } from "react";
import ReactEcharts from "echarts-for-react";
import "./FNewDash.css";
import { useMemo } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { format, formatRelative, subDays, endOfDay } from "date-fns";

import ExpandMorePopup from "../../../../../Common/ExpandMorePopup";
import FormTemp from "./FormTemp";
import PopupBG from "./PopBG";
import TileRemove from "../../../../home/TileRemove";


function TempChartDaily({handleClose,onClick, careTakerPatientView=false}) {
  const groupName = useSelector((state) => state.auth.groupName);
  const token = useSelector((state) => state.auth.authToken);

  const dateHR = format(endOfDay(new Date()), "ddMMyyy");
  const [xAxisData, setXData] = useState([]);

  const tempDaily = useSelector((state) => state.dashboard.tempDaily);

  const dispatch = useDispatch();


  const alarmStatus= tempDaily?.data?.y_data?.status_point

  
 


  var tempFar = ((tempDaily?.data?.y_data?.TMP) * (9/5) )+ 32

  const chartData = React.useMemo(() => {
    return {
      title: [
        {
          text: "Temperature",
          

          left: "2%",
          top: "7%",
          textStyle: {
            fontWeight: "normal",
            fontSize: 23,
            color: "black",
          },
        },
        {
          subtext: tempDaily?.data?.x_data?.time_gap + " ago",
          left: "right",
          top: "26%",
          fontSize: 12,
        },
        {
          subtext: "F",
          left: "73%",
          top: "16%",
       
          fontSize: 15,
        },
          { subtext:tempDaily?.data?.y_data?.status ,
          left: "left",
          top:"26%",
          fontSize:12,
         
        },
      ],
      tooltip: {
        textStyle: {
          fontFamily: "sans-serif",
        },
      },

      series: [
        {
          type: "gauge",
          radius: "30%",
          center: ["75%", "15%"],
          guageCategoryGap: "20%",
          startAngle: 360,
          endAngle: 0,

          min: false,
          max: 1,
          color: " red",
          splitNumber: 12,
          itemStyle: {
            color: "#00B5C6",

            shadowColor: "blue",
            shadowBlur: 0,
            shadowOffsetX: 0,
            shadowOffsetY: 0,
          },
          progress: {
            show: true,
            width: 8
          },
          pointer: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: false
          },
          axisLabel: {
            show: false
          },

          title: {
            show: false,
          },
          detail: {
           
            lineHeight: 0,
            height: 0,
            fontSize: 20,
            fontWeight:"normal",
            color:" #434242",
          

            borderRadius: 8,
            offsetCenter: [0, "5%"],
            valueAnimation: true,
          },
          data: [
            {

              value: (((tempDaily?.data?.y_data?.TMP) * (9/5) )+ 32).toFixed(1),

           
            },
          ],
        },
      ],
     
    };
  }, [tempDaily]);

  if (!(chartData.series[0].data[0].value) ||(chartData.series[0].data[0].value==="NaN") )
    return null;

  return (
    <div>
        <div className={"side-chart1 "+(' alarm-status-'+alarmStatus)} >
        <div className="popup-bg" style={{display:'flex', justifyContent: 'right'}}>
          <ExpandMorePopup 
          option1={<PopupBG optionName="Manual Entry" FormTitle="Temperature" FormType={<FormTemp handleClose={handleClose} careTakerPatientView={careTakerPatientView} />} />}
          option2={<TileRemove
            careTakerPatientView={careTakerPatientView}
            parameter="TMP" />}  /></div>
          <ReactEcharts option={chartData} />{" "}
        </div>
    </div>
  );
}

export default memo(TempChartDaily);

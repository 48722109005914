import React, { memo } from "react";
import { useState, useEffect } from "react";
import ReactEcharts from "echarts-for-react";
import "./FNewDash.css";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import constants from "../../../../../Common/Constants";
import { format, formatRelative, subDays, endOfDay } from "date-fns";
import { Col, Row } from "reactstrap";
import BPDefault from "./DefaultCharts/BPDefault";
import ExpandMorePopup from "../../../../../Common/ExpandMorePopup";
import PopUpBP from "./PopUpBP";
import { useMemo } from 'react';
import TileRemove from "../../../../home/TileRemove";
// import { setBpDailyDia, setBpDailySys, setBpDailyDiaStatus, setBpDailySysStatus } from '../../../../../store/dashboardSlice';

function BPChartDaily({ onClick,careTakerPatientView=false }) {
  const groupName = useSelector((state) => state.auth.groupName);
  const token = useSelector((state) => state.auth.authToken);
  
  const dateHR = format(endOfDay(new Date()), "ddMMyyy");
  const [xAxisData, setXData] = useState([]);
 
  const bpDailySys = useSelector((state) => state.dashboard.bpDailySys);
  
  const bpDailyDia = useSelector((state) => state.dashboard.bpDailyDia);
  
  const dispatch = useDispatch();
   const alarmStatus= bpDailySys?.data?.y_data?.status_point
  
  
  const chartData = React.useMemo(() => {
    return {
      title: [
        {
          left: "5%",
          text: "Blood Pressure",
          top: 0,
          textStyle: {
            fontWeight: "normal",
            fontSize: 26

          },
        },
       
        {
          subtext: bpDailyDia?.data?.x_data?.time_gap + " ago",
          left: "right",
          bottom: "5%",
          fontSize: 12,
        },
        {
          subtext: bpDailyDia?.data?.y_data?.status,
          left: "left",
          bottom: "5%",
          fontSize: 12,
        },
      ],
      series: [
        {
          type: "gauge",
          center: ["39%", "50%"],

          startAngle: 200,
          endAngle: -20,
          min: 0,
          max: 60,
          itemStyle: {
            color: "Black",
          },
          progress: {
            show: false,
            width: 9,
          },
          pointer: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          detail: {
            valueAnimation: true,
            width: "60%",
            lineHeight: 40,
            borderRadius: 8,
            offsetCenter: [0, "0%"],
            fontSize: "25",
            fontWeight: "normal",
            color: " #434242",
          },
          data: [
            {
              value: bpDailySys?.data?.y_data?.BPS,
              name: "SYS",
            
            },
          ],
        },
        {
          type: "gauge",
          center: ["62%", "50%"],
          startAngle: 200,
          endAngle: -20,
          min: 0,
          max: 60,
          itemStyle: {
            color: "Black",
          },
          progress: {
            show: false,
            width: 8,
          },
          pointer: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          detail: {
            valueAnimation: true,
            width: "60%",
            lineHeight: 40,
            borderRadius: 8,
            offsetCenter: [0, "0%"],
            fontSize: 25,
            fontWeight: "normal",
            color: " #434242",
          },
          data: [
            {
              value: bpDailyDia?.data?.y_data?.BPD,
              name: "DIA",
            
            },
          ],
        },
        {
         
          color: "#00B5C6",
          type: "gauge",
          center: ["50%", "50%"],
          radius: "62%",
          guageCategoryGap: "0%",
          startAngle: 360,
          endAngle: 0,
          min: false,
          max: 1,
          splitNumber: 12,
          itemStyle: {
            color: "#00B5C6",
            shadowColor: "blue",
            shadowBlur: 0,
            shadowOffsetX: 0,
            shadowOffsetY: 0,
          },
          progress: {
            show: true,
            width: 8,
          },
          pointer: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          detail: {
            valueAnimation: true,
            width: "60%",
            lineHeight: 40,
            borderRadius: 8,
            offsetCenter: [0, "0%"],
            fontSize: 40,
            show: false,
          },
          data: [
            {
              value: 1,
             
            },
          ],
        },
      ],
    };
  }, [xAxisData, bpDailySys, bpDailyDia]);

 
  if (!chartData?.series[0]?.data[0]?.value) {
    return null;
  }
  return (
    <div>

    
    <div className={'dis-daily-chart'+(' alarm-status-'+alarmStatus)} > 
    <div className="popup-bg" 
    style={{display:'flex', justifyContent: 'right'}}>
      <ExpandMorePopup 
        option1={<PopUpBP careTakerPatientView={careTakerPatientView} optionTitle="Manual Entry"/>}
        option2={<TileRemove
          careTakerPatientView={careTakerPatientView}
          parameter="BP" />}/></div>
   
    <div className="BG-value" style={{ fontFamily: "sans-serif" }} onClick={onClick}>
        
    <ReactEcharts option={chartData}/>{" "}
      </div>
    </div>
    </div>
  );
}

export default memo(BPChartDaily);

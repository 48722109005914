import React, { memo, useMemo, useRef } from "react";
import { useState, useEffect } from "react";
import ReactEcharts from "echarts-for-react";
import "./FNewDash.css";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import constants from "../../../../../Common/Constants";
import { format, endOfDay } from "date-fns";
import SleepDefault from "./DefaultCharts/SleepDefault";
import ExpandMorePopup from "../../../../../Common/ExpandMorePopup";
import PopUpSleep from "./PopUpSleep";
import FormSleep from "./FormSleep";
import moment from "moment";
import TileRemove from "../../../../home/TileRemove";

function SleepChartdaily({ onClick,careTakerPatientView=false }) {
  const groupName = useSelector((state) => state.auth.groupName);
  const token = useSelector((state) => state.auth.authToken);

  const dateHR = format(endOfDay(new Date()), "ddMMyyyy");
  const [xAxisData, setXData] = useState([]);
  const [yAxisData, setYData] = useState([]);

  const dispatch = useDispatch();
  const sleepDaily = useSelector(
    (state) => state.dashboard.sleepDaily || false
  );
  let totalSleep =
    sleepDaily?.data?.y_data["deep"] +
    sleepDaily?.data?.y_data["rem"] +
    sleepDaily?.data?.y_data["light"] +
    sleepDaily?.data?.y_data["wake"];
  let totalSleepHrs = parseInt(totalSleep / 60);
  let totalSleepMin = totalSleep % 60;

  const alarmStatus = sleepDaily?.data?.y_data?.status_point;

  const chartData = React.useMemo(() => {
    return {
      title: [
        {
          text: "Sleep",
          left: "5%",
          top: 0,
          textStyle: {
            fontWeight: "normal",
            fontSize: 28,
          },

          subtext:
            "TotalSleep: " + totalSleepHrs + " hrs " + totalSleepMin + " mins ",
        },
        {
          subtext: sleepDaily?.data?.x_data + " ago",
          left: "right",
          bottom: "5%",
          fontSize: 12,
        },
        {
          subtext: sleepDaily?.data?.y_data?.status,
          left: "left",
          bottom: "5%",
          fontSize: 12,
        },
      ],
      tooltip: {
        textStyle: {
          fontFamily: "sans-serif",
        },
      },

      grid: {
        left: "3%",
        right: "10%",
        bottom: "18%",
        containLabel: true,
      },

      yAxis: {
        axisLabel: {
          fontFamily: "sans-serif",
        },
        type: "category",
        boundaryGap: [0, 0.01],

        data: ["REM", "Light Sleep", "Deep Sleep", "Awake"],
      },
      xAxis: {
        type: "value",
        axisLabel: {
          fontFamily: "sans-serif",
        },
      },
      series: [
        {
          name: "%",
          type: "bar",
          areaStyle: {},
          colors: [["#4dcd32", "#4dcd32", "#dd0453", "#4dcd32"]],
          symbol: "none",

          itemStyle: {
            colors: [["#4dcd32", "#4dcd32", "#dd0453", "#4dcd32"]],
          },

          data: [
            {
              value: (
                (sleepDaily?.data?.y_data?.rem / totalSleep) *
                100
              ).toFixed(1),
              itemStyle: { color: "#FFB848" },
            },
            {
              value: (
                (sleepDaily?.data?.y_data?.light / totalSleep) *
                100
              ).toFixed(1),
              itemStyle: { color: "#27A9E3" },
            },
            {
              value: (
                (sleepDaily?.data?.y_data?.deep / totalSleep) *
                100
              ).toFixed(1),
              itemStyle: { color: "#28B779" },
            },
            {
              value: (
                (sleepDaily?.data?.y_data?.wake / totalSleep) *
                100
              ).toFixed(1),
              itemStyle: { color: "#6D3DA3" },
            },
          ],
        },
      ],
    };
  }, [xAxisData, sleepDaily]);

  function calculateDuration(ST, ET) {
    const startTime = moment(ST);
    const endTime = moment(ET);
    const duration = moment.duration(endTime.diff(startTime))
    const hours = duration.hours();
    const minutes = duration.minutes();
    return `${hours} hours  ${minutes} minutes`
  }

  const chartData2 = () => {
    // {alert("qwertyu")}
    return (
      <>
        {calculateDuration(
          sleepDaily?.data?.y_data?.sleep[0]?.sleep_start,
          sleepDaily?.data?.y_data?.sleep[0]?.sleep_end
        )}{" "}
      </>
    );
  };



  if( !sleepDaily || !Object.keys(sleepDaily).length ) {
    return null
  }
  else{
    if(isNaN(chartData.series[0]?.data[0]?.value)){

      return (
        <div
          className={"dis-daily-chart" + (" alarm-status-" + alarmStatus)}
          style={{ fontFamily: "sans-serif" }}
        >
          <div
            className="popup-bg"
            style={{ display: "flex", justifyContent: "right" }}
          >
            <ExpandMorePopup
              option1={<PopUpSleep careTakerPatientView={careTakerPatientView} optionTitle="Manual Entry" />}
              option2={<TileRemove
                careTakerPatientView={careTakerPatientView}
                parameter="SLEEP" />}
            />
          </div>
          <div onClick={onClick}>

        <div className="sleepHead">Sleep</div>
        <div className="sleepDetails">{chartData2()}</div>
        <div className="spanDiv">
          <span>Start Time</span>
          <span>End Time</span>{" "}
        </div>
        
        <div className="spanDiv">
          <span>{moment(sleepDaily?.data?.y_data?.sleep[0]?.sleep_start).format("h:mm a")}</span>
          <span>{moment(sleepDaily?.data?.y_data?.sleep[0]?.sleep_end).format("h:mm a")}</span>

        </div>
      </div>
    </div>
  );
  }
  if(chartData.series[0]?.data[0]?.value){
    return (
      <div
        className={"dis-daily-chart" + (" alarm-status-" + alarmStatus)}
        style={{ fontFamily: "sans-serif" }}
      >
        <div
          className="popup-bg"
          style={{ display: "flex", justifyContent: "right" }}
        >
       
            <ExpandMorePopup
              option1={<PopUpSleep careTakerPatientView={careTakerPatientView} optionTitle="Manual Entry"/>}
              option2={<TileRemove
                careTakerPatientView={careTakerPatientView}
                parameter="SLEEP" />}/></div>
          <div onClick={onClick}>
            <ReactEcharts option={chartData} />
          </div>
      </div>
    );
  }

  }
  return null ;
}

export default memo(SleepChartdaily);

import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faGauge} from '@fortawesome/free-solid-svg-icons';
import SideCardCommon from '../SideCardCommon';
import { useSelector } from 'react-redux';
import TileRemove from '../Featuresv2/TileRemove';

function BMI({onClick}) {

  const BMI = useSelector((state) => state.dashboard.BMI);

  const BMIValue = BMI?.BMI
  console.log("bmi: ",BMI);

  if (!BMIValue){
    return null;
  }
  
  return (
    <SideCardCommon title="BMI" 
    onClick={onClick}
    iconpic={<FontAwesomeIcon icon={faGauge} color="#7F55AE"/>}
    parameterValue={BMIValue? BMIValue:""}
    parameterUnit=""
    alarmStatus={"-"}
    timeGap={"--"}
    FormTitle={"BMI"}
    FormType={""}
    Option1={""}
    Option2={<TileRemove option2title={"REMOVE TILE"} parameter={"BMI"}/>}
    >
        
    </SideCardCommon>
  )
}

export default BMI
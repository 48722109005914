import React from 'react'
import ReactEcharts from 'echarts-for-react';
import '../../FitCharts/FNewDash.css'
import moment from "moment";



export default function SleepChartpage1({title,xData,yData,handleClick}) {

  
    const chartData =React.useMemo(()=>{
      return {
        title: {
          left: 'center',
          text: title,
          textStyle: {
            fontFamily: "sans-serif"
          },
        },
        
        tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            },
            textStyle: {
              fontFamily: "sans-serif"
            },
            formatter: function (params) {
              // Customize the tooltip text
              let time = params?.[0]?.data
              const hours = Math.floor(time / 60);
              const remainingMinutes = (time % 60).toFixed(0);
              return `${hours} Hours ${remainingMinutes} Minutes`;
            },
          },
          // legend: {
          //   left:490,
          //   top:35,
          //   selectedMode: false,
          // },
        xAxis: {
          type: 'category',
        //   boundaryGap: false,
          data: xData
          // data: `${hours} hours  ${minutes} minutes`
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              // Convert the value to hours and minutes format
              const hours = Math.floor(value / 60);
              const minutes = value % 60;
              return `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
            },
          },
        },
        
        series: [
          {
            name: 'Minutes',
            type: 'bar',
            areaStyle: {},
                
            symbol: 'none',
            
            // itemStyle: {
            color: '#6D3DA3',
            // },
            
            data: yData
  
          }
        ]}
    },[xData,yData,title])
  
  
  
  
  return (
    <div>
      {chartData?.xAxis?.data?.length?
    <div className='left-chart'>
    <ReactEcharts 
    option={chartData} 
    onEvents={{
      'click': handleClick,
    }}
    />
    <div className='area-name'></div>
    </div>
     : <p style={{fontSize:"20px", fontWeight:"bold"}}>NO DATA AVAILABLE </p>}
    </div>
  )
  }

  
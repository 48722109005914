import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit"

import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from "redux-persist"
import rootReducer from "./root-reducer"

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
    },
    immutableCheck: false
  }),
  devTools: true
})

export default store



// import { configureStore } from '@reduxjs/toolkit';
// import authReducer from './authSlice';
// import settingsReducer from './settingsSlice';




// const store = configureStore({
//   reducer: { 
//     auth: authReducer,
//     settings: settingsReducer,
//   },
// });

// export default store;
import { faPills } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'

export default function MedicationPopUp({medication, medicneTime, fdStatus, tileColor}) {

    const styles = {
        root: {
            display: 'grid',
            gridTemplateColumns: '1fr auto 1fr', // Three columns: Left (labels), middle (stepper), right (content)
            alignItems: 'center', // Align labels to the top
            justifyContents: "center",
            marginLeft: "15px",
        },
        stepLabel: {
            gridColumn: '1', // Left column
            fontSize: "0.8rem",
            //   marginLEft:"5px",
            alignSelf: 'center', // Align labels to the top
            fontFamily:"'Montserrat',sans-serif",
            fontWeight:"500"
        },
        stepContent1: {
            gridColumn: '3', // Right column
            backgroundColor: "rgb(253, 205, 205)",
            boxShadow:"0px 2px 4px rgba(67, 67, 67, 0.5)",
            borderRadius: "5px",
            fontSize: "0.8rem",
            // marginLeft: "-15px",
            color: "black",
            display: "flex",
            flexDirection: "row",
            // animation: `${blinkAnimation} 1s ease infinite !important`,
        },
        stepContent2: {
            gridColumn: '3', // Right column
            backgroundColor: tileColor,
            boxShadow:"0px 2px 4px rgba(67, 67, 67, 0.5)",
            borderRadius: "5px",
            fontSize: "0.8rem",
            margin:"1px 2px 7px 2px",
            color: "black",
            display: "flex",
            flexDirection: "row",
        },
        stepContent3: {
            gridColumn: '3', // Right column
            backgroundColor: "#f8f8f8",
            boxShadow:"0px 2px 4px rgba(67, 67, 67, 0.5)",
            borderRadius: "5px",
            fontSize: "0.8rem",
            // marginLeft: "-15px",
            color: "black",
            display: "flex",
            flexDirection: "row",
        },
        pillContainer: {
            padding: "3px 0px 3px 5px",
            margin: "3px",
            display: "flex",
            alignItems: "center",
            justifyContents: "center"
        },
        labelContainer: {
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
            justifyContents: "left",
            textAlign: "left",
            padding: "3px 5px 3px 5px",
            fontFamily:"'Montserrat',sans-serif",
        },
        beforeAfterTimeContainer: {
            display: "flex",
            flexDirection: "row",
            fontSize: "0.6rem",
            fontFamily:"'Montserrat',sans-serif",
            color:"rgb(100,100,100)",
        },
        medicineNameLabel: {
            display: "flex",
            alignItems: "left",
            justifyContent:"space-between",
            textAlign: "left",
            width:"100%",
            fontWeight: "600",
            fontSize: "0.7rem",
            flexDirection: "row",
            fontFamily:"'Montserrat',sans-serif",
        },
        medNameCountStyle:{
            fontWeight: "600",
            fontSize: "0.7rem",
            fontFamily:"'Montserrat',sans-serif",
        },
        numOfMedicinesStyle:{
            fontWeight: "600",
            fontSize: "0.65rem",
            fontFamily:"'Montserrat',sans-serif",
            backgroundColor:"white",
            padding:"0px 2px 0px 2px",
            height:"fit-content",
            borderRadius:"5px",
            boxShadow:"0px 2px 2px rgba(67, 67, 67, 0.5)",
        },
        labelBeforeAfterFood: {
            marginRight: "3px",
            fontFamily:"'Montserrat',sans-serif",
            fontWeight:"500",
        },
        labelTime: {
            marginLeft: "3px",
            fontFamily:"'Montserrat',sans-serif",
            fontWeight:"500",
        },
        stepTimelabel:{
            fontFamily:"'Montserrat',sans-serif",
            fontWeight:"600",
            fontSize:"0.8rem",
            marginRight:"3px",
        },
        popUpMainContainer:{
            display:"inline-flex",
            flexDirection:"column",position:"absolute",
            backgroundColor:"#e6daf3", color:"black",transform: "translateY(-50%)",
            border:"2px solid #7F55AE",padding:"10px",
            zIndex:"1",
            borderRadius:"7px",fontFamily:"'Montserrat', sans-serif", 
            whiteSpace:"nowrap"
        },
        popUpgrid:{
            display:"flex",
            flexWrap:"wrap",
            padding:"10px",
        }

    };

  return (
      <div>
         {medication?.med_name.map((medicinesName, index) => (
          <div style={styles.stepContent2}>
              <div style={styles.pillContainer}><FontAwesomeIcon color='#4B5966' icon={faPills} /></div>
              <div style={styles.labelContainer}>
                  <div style={styles.medicineNameLabel}>
                          <div style={styles.medNameCountStyle}>{medicinesName.length > 17 ? medicinesName.substr(0, 17) + "..." : medicinesName}</div> 
                  </div>
                  <div style={styles.beforeAfterTimeContainer}>
                      <div style={styles.labelBeforeAfterFood}>{fdStatus} </div>
                      <div>|</div>
                      <div style={styles.labelTime}>{medicneTime}</div>
                  </div>
              </div>
          </div>
          ))}
      </div>
  )
}

import React,{memo} from 'react'
import { useState, useEffect } from 'react';
import ReactEcharts from 'echarts-for-react';
import '../FNewDash.css'
import { Col, Row } from 'reactstrap';

function BPChartPage2({title,x,y1,y2,handleClick}) {
   

  
  if(!handleClick)
  {
    
   
  }

  const y1Padding = React.useMemo(()=>{
    if(y1?.[0]?.length===2)
       return [0, 0, -65, -63]
    else
       return [0, 0, -65, -69]
    },[y1])

    const y2Padding = React.useMemo(()=>{
      if(y2?.[0]?.length===2)
         return [0, 0, -65, -75]
      else
         return [0, 0, -65, -55]
      },[y2])

  const chartData=
  React.useMemo(()=>{
      return {
        title: {
          left: 'center',
          top:28,
          text: 'SYS',
          textStyle: {
            fontFamily: "sans-serif"
          },
        },
        tooltip:{textStyle: {
          fontFamily: "sans-serif"
        },},
      
       
        
        series: [
         
          {
            color:"#00B5C6",
            type: 'gauge',
            guageCategoryGap: "20%",
            startAngle: 360,
            endAngle: 0,
            min: false,
            max: 1,
            splitNumber: 12,
            itemStyle: {
              color: '',
              shadowColor: 'blue',
              shadowBlur: 0,
              shadowOffsetX: 0,
              shadowOffsetY: 0
            },
            progress: {
              show: true,
              roundCap: true,
              width: 10,
              
            },
            pointer: {
              icon: false,
              length: '0%',
              width: 0,
              offsetCenter: [0, '0%']
            },
            axisLine: {
              roundCap: false,
              show:false,
              lineStyle: {
                width: -10,
              }
            },
            axisTick: {
              splitNumber: false,
              color: 'white',
            
            },
            axisLabel: {
              distance: 30,
              color: 'white',
              fontSize: 0
            },
            title: {
              show: false
            },
            detail: {
              backgroundColor: '#fff',
              lineHeight: 0,
              height: 0,
              borderRadius: 8,
              offsetCenter: [-6, '0%'],
              valueAnimation: true,
              formatter: function (value) {
                return '{value|' + value.toFixed(0) + '}{unit|mmHg}';
              },
              rich: {
                value: {
                  fontSize: 30,
                  fontWeight: 'bolder',
                  color: '#777',
                  fontFamily: "sans-serif"
                },
                unit: {
                  fontSize: 15,
                  color: '#999',
                  padding: y1Padding,
                  fontFamily: "sans-serif"
                }
              }
            },
            data: [
              {
                value: y1
              }
            ]
          },
          
          
        ],
        
      }
    },[x,y1])

    

  return (
    <div>
      {chartData.series[0].data.length?
    
    <div class="BP-main-container">
    <div class="BP-title">{title}</div>
    <div class="BP-chart-container">
      <div class="BP-chart">
        <div style={{fontWeight:"bold"}}>SYS</div>
        <div className="SYS-chart-circle">
          <div style={{marginTop:"32px", fontWeight:"bold", fontSize:"30px"}}>{y2}</div>
          <div>mmHg</div>
        </div>
      </div>
      <div class="BP-chart BP-right-chart">
        <div style={{fontWeight:"bold"}}>DIA</div>
        <div className='DIA-chart-circle'>
          <div style={{marginTop:"32px", fontWeight:"bold", fontSize:"30px"}}>{y1}</div>
          <div>mmHg</div>
        </div>
      </div>
    </div>
  </div>
     : <p style={{fontSize:"20px", fontWeight:"bold"}}>NO DATA AVAILABLE </p>}
    </div>
  )
}

export default memo(BPChartPage2)
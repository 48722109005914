import React from 'react'
import { useState } from "react";
import styles from "./FormSignUp.module.css"

const FormSignUp = (props) => {
  const [focused, setFocused] = useState(false);
  const { label, errorMessage, onChange, id, ...inputProps } = props;

  const handleFocus = (e) => {
    setFocused(true);
  };

  return (
    <div className={styles.formInput}>
      <label className={styles.label1}>{label}</label>
      <input className={styles.input1}
        {...inputProps}
        onChange={onChange}
        defaultValue=' '
        autoComplete="off"
        onBlur={handleFocus}
        onFocus={() =>
          inputProps.name === "confirmPassword" && setFocused(true)
        }
        focused={focused.toString()}
      />
      <span className={styles.errormsg}>{errorMessage}</span>
    </div>
  );
};

export default FormSignUp
import React, { useMemo, useState } from "react";
import "./Header.css";
import graphIcon from "./GraphIcon.svg";
import statsIcon from "./StatIcon.svg";
import Button from "@material-ui/core/Button";
import { Link, useHistory, useParams } from "react-router-dom";
import RightTopbar from "./RightTopBar";
import { setBedDeviceView, setGraphMode } from "../../store/authSlice";
import AiMed from "../../../src/AiMed.svg";
import {
  setReplayPage,
  setSelectedBed,
  setSelectedDeviceId,
  setSelectedDeviceType,
} from "../../store/settingsSlice";
import { setCurrentUserIndex } from "../../store/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { setWebsocketStatus } from "../../store/dashboardSlice";
import {
  GroupAdd,
  HighQualityRounded,
  NotificationImportantOutlined,
  NotificationsActiveOutlined,
  TapAndPlay,
} from "@material-ui/icons";
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import Badge from '@material-ui/core/Badge';

const Header = ({ data, closeCreatedSockets,careTakerPatientView=false}) => {
  const dispatch = useDispatch();
  function navigateToStatsPage(isStatsPage) {
    if (isStatsPage) {
      setGraphMode(false);
    } else {
      setGraphMode(true);
    }
    dispatch(setBedDeviceView(false));
    dispatch(setSelectedBed("AllDevices"));
    dispatch(setSelectedDeviceId(null));
    dispatch(setSelectedDeviceType(null));
    dispatch(setReplayPage(false));
  }
  

  const [notificationOpen, setNotificationOpen] = useState(false);
  const handleNotificationClick = () => {
    setNotificationOpen(!notificationOpen )
  }

   
  const history = useHistory() ;

const PatientSignUpPage =() =>{
history.push('/patient_signup_page');
}

const RegisterPage =() =>{
  history.push('/register_page');
  }  
  const handleV2Page =() =>{
    history.push('/v2');
    }

const handlesampleV2=() =>{
history.push('/sample_v2')
}
const medicationPage = () => {
  history.push("/medication_page");
};

  const userData = useSelector((state) => state.auth.userData);
  const group = useSelector((state) => state.auth.groupList);
  const currentUserIndex = useSelector((state) => state.auth.currentUserIndex);
  const careTaker = useSelector((state) => state.auth.careTaker);
  const glucoseDaily = useSelector((state) => state.dashboard.glucoseDaily);
  const bpDailySys = useSelector((state) => state.dashboard.bpDailySys);
  const breathDaily = useSelector((state) => state.dashboard.breathDaily);
  const sleepDaily = useSelector((state) => state.dashboard.sleepDaily);
  const spo2DailyMin = useSelector((state) => state.dashboard.spo2DailyMin);
  const stepsDaily = useSelector((state) => state.dashboard.stepsDaily);
  const tempDaily = useSelector((state) => state.dashboard.tempDaily);
  const heartRateDaily = useSelector((state) => state.dashboard.heartRateDaily);
 

  const glucose = glucoseDaily?.data?.y_data?.status;
  const glucoseStatus = glucoseDaily?.data?.y_data?.status_point;
  const bp = bpDailySys?.data?.y_data?.status;
  const bpStatus = bpDailySys?.data?.y_data?.status_point;
  const br = breathDaily?.data?.y_data?.status;
  const brStatus = breathDaily?.data?.y_data?.status_point;
  const sleep = sleepDaily?.data?.y_data?.status;
  const sleepStatus = sleepDaily?.data?.y_data?.status_point;
  const spo = spo2DailyMin?.data?.y_data?.status;
  const spoStatus = spo2DailyMin?.data?.y_data?.status_point;
  const steps = stepsDaily?.data?.y_data?.status;
  const stepsStatus = stepsDaily?.data?.y_data?.status_point;
  const temp = tempDaily?.data?.y_data?.status;
  const tempStatus = tempDaily?.data?.y_data?.status_point;
  const heartRate = heartRateDaily?.data?.y_data?.status;
  const heartRateStatus = heartRateDaily?.data?.y_data?.status_point

 

  const handleChange = (e) => {
    dispatch(setCurrentUserIndex(e.target.value));
    dispatch(setWebsocketStatus("fetch"));
  };

  const Heart = useMemo(() => {
    if (heartRateStatus === 3) {
      return <div style={{ backgroundColor: "#FF0000" }}>{heartRate}</div>;
    }
    if (heartRateStatus === 2) {
      return <div style={{ backgroundColor: "#FFC750" }}>{heartRate}</div>;
    }
    if (heartRateStatus === 1) {
      return <div style={{ backgroundColor: "#203864" }}>{heartRate}</div>;
    } else {
      return null;
    }
  }, [heartRateStatus]);

  const Diabetes = useMemo(() => {
    if (glucoseStatus === 3) {
      return <div style={{ backgroundColor: "#FF0000" }}>{glucose}</div>;
    }
    if (glucoseStatus === 2) {
      return <div style={{ backgroundColor: "#FFC750" }}>{glucose}</div>;
    }
    if (glucoseStatus === 1) {
      return <div style={{ backgroundColor: "#203864" }}>{glucose}</div>;
    } else {
      return null;
    }
  }, [glucoseStatus]);

  const Pressure = useMemo(() => {
    if (bpStatus === 3) {
      return <div style={{ backgroundColor: "#FF0000" }}>{bp}</div>;
    }
    if (bpStatus === 2) {
      return <div style={{ backgroundColor: "#FFC750" }}>{bp}</div>;
    }
    if (bpStatus === 1) {
      return <div style={{ backgroundColor: "#203864" }}>{bp}</div>;
    } else {
      return null;
    }
  }, [bpStatus]);

  const Breath = useMemo(() => {
    if (brStatus === 3) {
      return <div style={{ backgroundColor: "#FF0000" }}>{br}</div>;
    }
    if (brStatus === 2) {
      return <div style={{ backgroundColor: "#FFC750" }}>{br}</div>;
    }
    if (brStatus === 1) {
      return <div style={{ backgroundColor: "#203864" }}>{br}</div>;
    } else {
      return null;
    }
  }, [brStatus]);

  const Sleeps = useMemo(() => {
    if (sleepStatus === 3) {
      return <div style={{ backgroundColor: "#FF0000" }}>{sleep}</div>;
    }
    if (sleepStatus === 2) {
      return <div style={{ backgroundColor: "#FFC750" }}>{sleep}</div>;
    }
    if (sleepStatus === 1) {
      return <div style={{ backgroundColor: "#203864" }}>{sleep}</div>;
    } else {
      return null;
    }
  }, [sleepStatus]);

  const Spo2 = useMemo(() => {
    if (spoStatus === 3) {
      return <div style={{ backgroundColor: "#FF0000" }}>{spo}</div>;
    }
    if (spoStatus === 2) {
      return <div style={{ backgroundColor: "#FFC750" }}>{spo}</div>;
    }
    if (spoStatus === 1) {
      return <div style={{ backgroundColor: "#203864" }}>{spo}</div>;
    } else {
      return null;
    }
  }, [spoStatus]);

  const Steps = useMemo(() => {
    if (stepsStatus === 3) {
      return <div style={{ backgroundColor: "#FF0000" }}>{steps}</div>;
    }
    if (stepsStatus === 2) {
      return <div style={{ backgroundColor: "#FFC750" }}>{steps}</div>;
    }
    if (stepsStatus === 1) {
      return <div style={{ backgroundColor: "#203864" }}>{steps}</div>;
    } else {
      return null;
    }
  }, [stepsStatus]);

  const Temperature = useMemo(() => {
    if (tempStatus === 3) {
      return <div style={{ backgroundColor: "#FF0000" }}>{temp}</div>;
    }
    if (tempStatus === 2) {
      return <div style={{ backgroundColor: "#FFC750" }}>{temp}</div>;
    }
    if (tempStatus === 1) {
      return <div style={{ backgroundColor: "#203864" }}>{temp}</div>;
    } else {
      return null;
    }
  }, [tempStatus]);

  

  return (
    <>
    
    <div className="headerDiv">
      <div className="headerRowDiv">
        <div className="nameDiv">
          <h1 style={{ fontSize: "32px", margin: "0" }}>
            <Link
              className="header_link"
              to="/home"
              onClick={() => navigateToStatsPage(true)}
            >
           
              <span style={{ color: "#6A4396", fontWeight: "Bold"}}>Care</span>
              <span style={{ color: "#23AF72", fontWeight: "Bold" }}>HQ </span>
             
            </Link>
          </h1>
        </div>

        <div className="menuDiv">
          {data === "Home" ? (
            <Link className="header_link" to="/home">
              <div className="buttonPosition">
                <Button
                  variant="contained"
                  color="secondary"
                  className="header_button"
                  startIcon={<img src={graphIcon} className="image" alt="" />}
                  onClick={() => navigateToStatsPage(false)}
                >
                  <label className="labelDiv cursor-pointer">Graph</label>
                </Button>
              </div>
            </Link>
          ) : (
            <Link className="header_link" to="/home">
              <div className="buttonPosition">
              </div>
            </Link>
          )}
        </div>

        <div style={{cursor:"pointer"}}>
        <MonitorHeartIcon onClick={medicationPage}/>
        </div>
        <div className="notificationDiv">
       <Badge badgeContent={([Diabetes,Pressure,Breath,Sleeps,Spo2,Steps,Temperature,Heart].filter(( item )=>item!==null)).length} color="primary">
          <NotificationsActiveOutlined onClick={handleNotificationClick}/>
          </Badge>
          {notificationOpen &&  
           <div className="notificationMenu">
             {Diabetes}
            {Pressure}
            {Breath}
            {Sleeps}
            {Spo2}
            {Steps}
            {Temperature}
            {Heart}
          
          </div>}
        
        </div>

        <div className="userDiv">
          <RightTopbar careTakerPatientView closeCreatedSockets={closeCreatedSockets} />
        </div>
      </div>
    </div>
    </>
  );
};

export default Header;

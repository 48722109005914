import React, { useState, useEffect } from "react";
import LineGraph from "../ECharts/LineGraph";
import SingleGraph from "../ECharts/SingleGraph";

function GraphContainer({ data }) {
  const [deviceType, setDeviceType] = useState(data.deviceType);
  const [graphLabels, setGraphLabels] = useState([]);
  const [syncedGraph, setSyncedGraph] = useState(true);

  useEffect(() => {
    if (deviceType === "iremon") {
      setSyncedGraph(false);
      setGraphLabels(["Pressure", "Flow", "Volume"]);
    }
    if (deviceType === "isave") {
      setSyncedGraph(false);
      setGraphLabels(["Pressure", "Flow", "Volume"]);
    }
    if (deviceType === "capnometer") {
      setSyncedGraph(false);
      setGraphLabels(["C02"]);
    }
    if (deviceType === "patientmon") {
      setSyncedGraph(false);
      setGraphLabels(["ECGA", "ECGB", "ECGC"]);
    }
    if (deviceType === "ventilator") {
      setSyncedGraph(false);
      setGraphLabels(["Pressure", "Flow", "Volume"]);
    }
  }, [deviceType]);

  return syncedGraph ? (
    <LineGraph data={[...data.graphData]} graphLabels={graphLabels} />
  ) : (
    <SingleGraph
      deviceType={deviceType}
      graphLabels={graphLabels}
      data={data}
    />
  );
}
export default React.memo(GraphContainer);

import React from 'react'
import MainCommonCard from './MainCommonCard'
import { Stepper, Step, StepLabel, StepIcon, Box, Typography, StepContent, Popover } from '@mui/material';
import AlarmIcon from '@mui/icons-material/Alarm';
import ScheduleIcon from '@mui/icons-material/Schedule';
import Stack from '@mui/material/Stack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faCircleMinus, faClockRotateLeft, faPills } from '@fortawesome/free-solid-svg-icons';
import cssStyles from './MedicationNewTile.module.css'
import PropTypes from 'prop-types';
import { keyframes, styled } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { Alarm, CheckCircle, NotificationsActive, Watch } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import MedicationPopUp from './MedicationPopUp';
import { TbPillOff } from 'react-icons/tb';


export default function MedicationNewTile({ onClick }) {

    const medicationList = useSelector((state) => state.dashboard.medicationDaily)
    const [showPopupPastInfo, setShowPopupPastInfo] = useState(false);
    const [showPopupCurrentInfo, setShowPopupCurrentInfo] = useState(false);
    const [showPopupNextInfo, setShowPopupNextInfo] = useState(false);

    console.log("medicationListDataaa", medicationList)
    const pastMed = medicationList?.past
    const pastMedName = pastMed?.med_name[0] ? pastMed?.med_name[0] : "No Medicine"
    const pastMedTimeHours = pastMed?.hour ? pastMed?.hour : "-"
    const pastMedTimeMinutes = pastMed?.minute ? pastMed?.minute : "-";
    const beforAfterStatusPast = pastMed?.fd_status ? pastMed?.fd_status : "-";
    const remainingPastMed = pastMed?.med_name ? pastMed?.med_name?.length - 1 : 0

    let pastMedTimeFormat = "";
    if (pastMedTimeHours >= 12) {
        pastMedTimeFormat = "PM";
    } else if (pastMedTimeHours < 12) {
        pastMedTimeFormat = "AM";
    }
    else {
        pastMedTimeFormat = "";
    }


    const nextMed = medicationList?.next
    const nextMedName = nextMed?.med_name[0] ? nextMed?.med_name[0] : "No Medicine"
    const nextMedTimeHours = nextMed?.hour ? nextMed?.hour : "-"
    const nextMedTimeMinutes = nextMed?.minute ? nextMed?.minute : "-";
    const beforAfterStatusNext = nextMed?.fd_status ? nextMed?.fd_status : "-";
    const remainingNextMed = nextMed?.med_name ? nextMed?.med_name?.length - 1 : 0

    let nextMedTimeFormat = "";
    if (nextMedTimeHours >= 12) {
        nextMedTimeFormat = "PM";
    } else if (nextMedTimeHours < 12) {
        nextMedTimeFormat = "AM";
    }
    else {
        nextMedTimeFormat = "";
    }

    const currentMed = medicationList?.present
    const currentMedName = currentMed?.med_name[0] ? currentMed?.med_name[0] : "No Medicine";
    const currentMedTimeHours = currentMed?.hour ? currentMed?.hour : "-"
    const currentMedTimeMinutes = currentMed?.minute ? currentMed?.minute : "-"
    const beforAfterStatusCurrent = currentMed?.fd_status ? currentMed?.fd_status : "-";
    const remainingCurrentMed = currentMed?.med_name ? currentMed?.med_name?.length - 1 : 0

    let currentMedTimeFormat = "";
    if (currentMedTimeHours >= 12) {
        currentMedTimeFormat = "PM";
    } else if (currentMedTimeHours < 12) {
        currentMedTimeFormat = "AM";
    }
    else {
        currentMedTimeFormat = "";
    }

    const pastTime = `${pastMedTimeHours}:${pastMedTimeMinutes} ${pastMedTimeFormat}`
    const nextTime = `${nextMedTimeHours}:${nextMedTimeMinutes} ${nextMedTimeFormat}`
    const currentTime = `${currentMedTimeHours}:${currentMedTimeMinutes} ${currentMedTimeFormat}`

    const data = {
        past: [
            { time: "8:00 AM", medication: "Medicine A", beforeOrAfterFood: "Before Food" },
            //   { time: "1:00 PM", medication: "Medicine B", beforeOrAfterFood: "After Food" },
        ],
        present: [
            { time: "3:00 PM", medication: "Medicine C", beforeOrAfterFood: "Before Food" },
            //   { time: "8:00 PM", medication: "Medicine D", beforeOrAfterFood: "After Food" },
        ],
        next: [
            { time: "7:00 AM", medication: "Medicine E", beforeOrAfterFood: "Before Food" },
            //   { time: "12:00 PM", medication: "Medicine F", beforeOrAfterFood: "After Food" },
        ],
    };

    const blinkAnimation = keyframes`
        0%, 100% { transform: scale(1); }
        50% { transform: scale(1.3); }`;





    const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
            top: 10,
            left: 'calc(50% + 300px)',
            right: 'calc(50% + 30px)',
        },
        [`&.${stepConnectorClasses.active}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                borderColor: '#784af4',
            },
        },
        [`&.${stepConnectorClasses.completed}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                borderColor: '#784af4',
            },
        },
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#ccc',
            borderTopWidth: 8,
            borderRadius: 1,
        },
    }));

    const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 25,
        height: 25,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        ...(ownerState.active && {
            backgroundImage:
                'linear-gradient( 136deg, #17b0a3 50%, #38ef7d  100%)',
            boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
            ...(currentMed && {
                animation: `${blinkAnimation} 1s ease infinite`,
            })




        }),
        ...(ownerState.completed && {
            // ...(!pastMed && {
            //     backgroundImage:
            //     '#ccc'
            // }),
            // ...(pastMed && {
                backgroundImage:
                'linear-gradient( 136deg, rgb(246, 125, 141) 50%, rgb(138,35,135) 100%)'
            // })
        }),
    }));

    function ColorlibStepIcon(props) {
        const { active, completed, className } = props;

        const icons = {
            1: !pastMed ? (
                <FontAwesomeIcon icon={faCircleMinus} color="white" style={{height:"15px",color:"white", width:"15px"}} />
              ) : pastMed?.status === 5 ? (
                <FontAwesomeIcon icon={faBan} style={{ height: "15px", width: "15px" }} />
              ) : pastMed.status === 4 ? (
                <FontAwesomeIcon icon={faClockRotateLeft} style={{height:"15px", width:"15px"}} />
              ) : pastMed?.status === 3 ? (
                <CheckCircle style={{ height: "18px", width: "18px" }} />
              ) : pastMed?.status === 2 ? (
                <TbPillOff style={{ height: "16px", width: "16px" }} />
              ) : null,     
            2: <NotificationsActive style={{ height: "18px", width: "18px" }} />,
            3: <Alarm style={{ height: "18px", width: "18px", color: "white" }} />,
        };

        return (
            <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
                {icons[String(props.icon)]}
            </ColorlibStepIconRoot>
        );
    }

    ColorlibStepIcon.propTypes = {
        /**
         * Whether this step is active.
         * @default false
         */
        active: PropTypes.bool,
        className: PropTypes.string,
        /**
         * Mark the step as completed. Is passed to child components.
         * @default false
         */
        completed: PropTypes.bool,
        /**
         * The label displayed in the step icon.
         */
        icon: PropTypes.node,
    };

    const styles = {
        root: {
            display: 'grid',
            gridTemplateColumns: '1fr auto 1fr', // Three columns: Left (labels), middle (stepper), right (content)
            alignItems: 'center', // Align labels to the top
            justifyContents: "center",
            marginLeft: "15px",
        },
        stepLabel: {
            gridColumn: '1', // Left column
            fontSize: "0.8rem",
            //   marginLEft:"5px",
            alignSelf: 'center', // Align labels to the top
            fontFamily: "'Montserrat',sans-serif",
            fontWeight: "500"
        },
        stepContent1: {
            gridColumn: '3', // Right column
            backgroundColor: "rgb(253, 205, 205)",
            boxShadow: "0px 2px 4px rgba(67, 67, 67, 0.5)",
            borderRadius: "5px",
            fontSize: "0.8rem",
            marginLeft: "-15px",
            color: "black",
            display: "flex",
            flexDirection: "row",
            // animation: `${blinkAnimation} 1s ease infinite !important`,
        },
        stepContent2: {
            gridColumn: '3', // Right column
            backgroundColor: "#c2ebd2",
            boxShadow: "0px 2px 4px rgba(67, 67, 67, 0.5)",
            borderRadius: "5px",
            fontSize: "0.8rem",
            marginLeft: "-15px",
            color: "black",
            display: "flex",
            flexDirection: "row",
        },
        stepContent3: {
            gridColumn: '3', // Right column
            backgroundColor: "#f8f8f8",
            boxShadow: "0px 2px 4px rgba(67, 67, 67, 0.5)",
            borderRadius: "5px",
            fontSize: "0.8rem",
            marginLeft: "-15px",
            color: "black",
            display: "flex",
            flexDirection: "row",
        },
        pillContainer: {
            padding: "3px 0px 3px 5px",
            margin: "3px",
            display: "flex",
            alignItems: "center",
            justifyContents: "center"
        },
        labelContainer: {
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
            justifyContents: "left",
            textAlign: "left",
            padding: "3px 5px 3px 5px",
            fontFamily: "'Montserrat',sans-serif",
        },
        beforeAfterTimeContainer: {
            display: "flex",
            flexDirection: "row",
            fontSize: "0.6rem",
            fontFamily: "'Montserrat',sans-serif",
            color: "rgb(100,100,100)",
        },
        medicineNameLabel: {
            display: "flex",
            alignItems: "left",
            justifyContent: "space-between",
            textAlign: "left",
            width: "100%",
            fontWeight: "600",
            fontSize: "0.7rem",
            flexDirection: "row",
            fontFamily: "'Montserrat',sans-serif",
        },
        medNameCountStyle: {
            fontWeight: "600",
            fontSize: "0.7rem",
            fontFamily: "'Montserrat',sans-serif",
        },
        numOfMedicinesStyle: {
            fontWeight: "600",
            fontSize: "0.65rem",
            fontFamily: "'Montserrat',sans-serif",
            backgroundColor: "white",
            padding: "0px 2px 0px 2px",
            height: "fit-content",
            borderRadius: "5px",
            boxShadow: "0px 2px 2px rgba(67, 67, 67, 0.5)",
        },
        labelBeforeAfterFood: {
            marginRight: "3px",
            fontFamily: "'Montserrat',sans-serif",
            fontWeight: "500",
        },
        labelTime: {
            marginLeft: "3px",
            fontFamily: "'Montserrat',sans-serif",
            fontWeight: "500",
        },
        stepTimelabel: {
            fontFamily: "'Montserrat',sans-serif",
            fontWeight: "600",
            fontSize: "0.8rem",
            marginRight: "3px",
        },
        popUpMainContainer: {
            display: "inline-flex",
            flexDirection: "column", position: "absolute",
            backgroundColor: "white", color: "black", transform: "translateY(-100%)",
            // border:"1px solid grey",
            boxShadow: "0px 2px 7px rgba(67, 67, 67, 0.5)",
            padding: "10px",
            zIndex: "1",
            borderRadius: "10px",
            fontFamily: "'Montserrat', sans-serif",
            whiteSpace: "nowrap"
        },
        popUpHeading: {
            fontSize: "0.7rem",
            fontWeight: "600",
            marginBottom: "3px",
            fontFamily: "'Montserrat', sans-serif",
        },
        popUpgrid: {
            display: "flex",
            flexWrap: "wrap",
            padding: "10px",
        }

    };
    console.log("data", data)

    if (
        medicationList?.hasOwnProperty("past") ||
        medicationList?.hasOwnProperty("present") ||
        medicationList?.hasOwnProperty("next")
    ) {
        return (
            <MainCommonCard
                onClick={onClick}
                tileTitle={"Medication"}
                renderComponent={
                    <div className="mainContainer" style={{ display: "flex", padding: "10px", alignItems: "center", justifyContents: "center" }}>
                        <div className="subContainer" style={{ alignItems: "center", justifyContents: "center" }}>
                            <Stepper activeStep={1} orientation='vertical' connector={<ColorlibConnector />}>
                                <Step >
                                    <div style={styles.root}>
                                        <div style={styles.stepLabel}>
                                            <StepLabel StepIconComponent={ColorlibStepIcon}><div style={styles.stepTimelabel}>{pastTime}</div></StepLabel>
                                        </div>
                                        <div style={styles.stepContent1}>
                                            <div style={styles.pillContainer}><FontAwesomeIcon color='#4B5966' icon={faPills} /></div>
                                            <div style={styles.labelContainer} >
                                                <div style={styles.medicineNameLabel}>
                                                    <div style={styles.medNameCountStyle}>{pastMedName.length > 11 ? pastMedName.substr(0, 11) + "..." : pastMedName} </div>
                                                    <div style={styles.numOfMedicinesStyle} onMouseEnter={() => { setShowPopupPastInfo(true) }} onMouseLeave={() => { setShowPopupPastInfo(false) }}>{remainingPastMed > 0 ? ` +${remainingPastMed}` : ""}
                                                    </div>
                                                    {showPopupPastInfo &&
                                                        <div
                                                            style={styles.popUpMainContainer}>
                                                            <div style={styles.popUpHeading}>Past Medicines <CheckCircle style={{height:"17px", width:"17px",color:"white",borderRadius:"50%",padding:"2px", backgroundImage:"linear-gradient( 136deg, rgb(246, 125, 141) 50%, rgb(138,35,135) 100%)"}}/></div>
                                                            <MedicationPopUp
                                                                medication={pastMed}
                                                                medicneTime={pastTime}
                                                                fdStatus={beforAfterStatusPast == "AF" ? ("After Food") : beforAfterStatusPast == "BF" ? ("Before Food") : "-"}
                                                                tileColor={"rgb(253, 205, 205)"}
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                                <div style={styles.beforeAfterTimeContainer}>
                                                    <div style={styles.labelBeforeAfterFood}>{beforAfterStatusPast == "AF" ? ("After Food") : beforAfterStatusPast == "BF" ? ("Before Food") : "-"}</div>
                                                    <div>|</div>
                                                    <div style={styles.labelTime}>{pastTime}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Step>
                                <Step>
                                    <div style={styles.root}>
                                        <div style={styles.stepLabel}>
                                            <StepLabel StepIconComponent={ColorlibStepIcon}><div style={styles.stepTimelabel}>{currentTime}</div></StepLabel>
                                        </div>
                                        <div style={styles.stepContent2}>
                                            <div style={styles.pillContainer}><FontAwesomeIcon color='#4B5966' icon={faPills} /></div>
                                            <div style={styles.labelContainer} >
                                                <div style={styles.medicineNameLabel}>
                                                    <div style={styles.medNameCountStyle}> {currentMedName.length > 11 ? currentMedName.substr(0, 11) + "..." : currentMedName} </div>
                                                    <div style={styles.numOfMedicinesStyle} onMouseEnter={() => { setShowPopupCurrentInfo(true) }} onMouseLeave={() => { setShowPopupCurrentInfo(false) }}>{remainingCurrentMed > 0 ? ` +${remainingCurrentMed}` : ""}
                                                    </div>
                                                    {showPopupCurrentInfo &&
                                                        <div
                                                            style={styles.popUpMainContainer}>
                                                            <div style={styles.popUpHeading}>Current Medicines <NotificationsActive style={{height:"17px", width:"17px",color:"white",borderRadius:"50%",padding:"2px", backgroundImage:"linear-gradient( 136deg, #17b0a3 50%, #38ef7d  100%)"}}/></div>
                                                            <MedicationPopUp
                                                                medication={currentMed}
                                                                medicneTime={currentTime}
                                                                fdStatus={beforAfterStatusCurrent == "AF" ? ("After Food") : beforAfterStatusCurrent == "BF" ? ("Before Food") : "-"}
                                                                tileColor={"#c2ebd2"}
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                                <div style={styles.beforeAfterTimeContainer}>
                                                    <div style={styles.labelBeforeAfterFood}>{beforAfterStatusCurrent == "AF" ? ("After Food") : beforAfterStatusCurrent == "BF" ? ("Before Food") : "-"} </div>
                                                    <div>|</div>
                                                    <div style={styles.labelTime}>{currentTime}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Step>
                                <Step>
                                    <div style={styles.root}>
                                        <div style={styles.stepLabel}>
                                            <StepLabel StepIconComponent={ColorlibStepIcon}><div style={styles.stepTimelabel}>{nextTime}</div></StepLabel>
                                        </div>
                                        <div style={styles.stepContent3}>
                                            <div style={styles.pillContainer}><FontAwesomeIcon color='#4B5966' icon={faPills} /></div>
                                            <div style={styles.labelContainer} >
                                                <div style={styles.medicineNameLabel}>
                                                    <div style={styles.medNameCountStyle}>{nextMedName.length > 11 ? nextMedName.substr(0, 11) + "..." : nextMedName}</div>
                                                    <div style={styles.numOfMedicinesStyle} onMouseEnter={() => { setShowPopupNextInfo(true) }} onMouseLeave={() => { setShowPopupNextInfo(false) }}>{remainingNextMed > 0 ? ` +${remainingNextMed}` : ""}</div>
                                                    {showPopupNextInfo &&
                                                        <div
                                                            style={styles.popUpMainContainer}>
                                                            <div style={styles.popUpHeading}>Next Medicines <Alarm style={{height:"17px", width:"17px",color:"black",borderRadius:"50%",padding:"2px", backgroundColor:"#f18f18f18",boxShadow: "0px 1px 4px rgba(67, 67, 67, 0.5)",}}/></div>
                                                            <MedicationPopUp
                                                                medication={nextMed}
                                                                medicneTime={nextTime}
                                                                fdStatus={beforAfterStatusNext == "AF" ? ("After Food") : beforAfterStatusNext == "BF" ? ("Before Food") : "-"}
                                                                tileColor={"#f8f8f8"}
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                                <div style={styles.beforeAfterTimeContainer}>
                                                    <div style={styles.labelBeforeAfterFood}>{beforAfterStatusNext == "AF" ? ("After Food") : beforAfterStatusNext == "BF" ? ("Before Food") : "-"}</div>
                                                    <div>|</div>
                                                    <div style={styles.labelTime}>{nextTime}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Step>
                            </Stepper>
                        </div>
                    </div>
                }
            >

            </MainCommonCard>
        )
    } else {
        return null;
    }
}

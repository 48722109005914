import React from "react";
import "../FNewDash.css";
import { Col, Row } from "reactstrap";
import BGChartPage1 from "./BGChartPage1";
import moment from "moment";
import { useState } from "react";
import constants from "../../../../../../Common/Constants";
import axios from "axios";
import { useSelector } from "react-redux";
import BGChartPage2 from "./BGChartPage2";
import { ContactSupport } from "@material-ui/icons";

const apiName = {
  week: "day_data",
  month: "week_data",
  threeMonth: "month_data",
  year: "month_data",
};

export default function BGChartContainer({ title, xData, yData, type , bgRes }) {
  const groupName = useSelector((state) => state.auth.groupName);
  const [clickedChart, setClickedChart] = useState(null);
  const [subChartName,setChartName]= useState('');
  const [bgValue,setBgValue]=useState({bg:null,date:null})
  const token = useSelector((state) => state.auth.authToken);
  const api_type = apiName[type];

  const [ __test , setTest ] = useState({});
  const handleClick = ( data1   ) => {
 
    if( data1 ){
        var [a1 ,b1] = data1.value ;
        setBgValue({
          title : a1 ,
          bg:a1,
          date : a1 , 
          x1 : a1,
          x2 : b1
       
        })

    }


   
    
  }
 
   

  return (
    <div className="h-100" key={type}>
      <Row className="h-100">
        <Col className="first-box h-100" >
          <div className="first-card h-100">
            <BGChartPage1
              title={title}
              xData={xData}
              yData={yData}
              bgRes={bgRes}
              handleClick={handleClick}
            />
          </div>
        </Col>
        <Col className="second-box">
          {bgValue?.bg && bgValue?.date &&
          <div className="second-card">
            <BGChartPage2 
              title={bgValue?.x1}
              yData={bgValue?.x2}
            />
          </div>
}
        </Col>
      </Row>
    </div>
  );

}
import { MoreHoriz } from '@material-ui/icons';
import { Divider, IconButton, Menu, MenuItem, Popover } from '@mui/material';
import React, { useState } from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEllipsis} from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';

export default function SideOptionPopup({ option1, option2, FormName }) {
    const [showPopup, setShowPopup] = useState(false);

    const location = useLocation();
    const { buttonDisable } = location.state || {};

    const handleIconClick = () => {
        setShowPopup(true);
    };

    const handleOptionClick = (option) => {
        // Handle the selected option
        console.log(`Selected option: ${option}`);

        // Hide the popup
        setShowPopup(false);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setShowPopup(true);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <React.Fragment>
            <div aria-describedby={id} onClick={buttonDisable == true? "" : handleClick}

                style={{
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "20px",
                    height: "20px",
                    borderRadius: "50%",
                    transition: "background-color 0.2s ease-in-out",
                    cursor: "pointer"
                }}
                onMouseOver={e => e.currentTarget.style.backgroundColor = "rgba(0, 0, 0, 0.1)"}
                onMouseOut={e => e.currentTarget.style.backgroundColor = "transparent"}
            >
            <div style={{heigth:"10px", width:"10px"}}><FontAwesomeIcon style={{heigth:"10px", width:"10px"}} icon={faEllipsis} /></div>
            </div>

            {showPopup && (
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'auto',
                    }}
                    PaperProps={{
                        className: "popover-content",
                        style: {
                            border: " 2px solid #7F55AE",
                            boxShadow: "none",
                            color: "black", margin: "0px"
                        },
                    }}
                >
                    <div className='cardDropdown'>

                        <div style={{
                            fontSize: "0.7rem",
                            fontWeight: "600",
                            fontFamily: "'Montserrat', sans-serif",
                            margin: "0px",
                        }} >{option1} </div>
                        <Divider />
                        <div style={{
                            fontSize: "0.7rem",
                            fontWeight: "600",
                            fontFamily: "'Montserrat', sans-serif",
                            margin: "0px",
                        }}>{option2}</div>
            
                    </div>
                </Popover>
            )}
        </React.Fragment>

    )
}





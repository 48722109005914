import "./SideDrawer.css";
import BedCardGrid from "./BedCardGrid";
import DrawerMenuButton from "./DrawerMenuButton";
import { Drawer } from "@material-ui/core";

function SideDrawer() {
  return (
    <Drawer
      sx={{
        "& .MuiDrawer-paper": {
          boxSizing: "border-box",
          color: "white",
        },
      }}
      variant="permanent"
      anchor="left"
      id="BedDrawer"
    >
      <DrawerMenuButton />
      <BedCardGrid overflowDrawer="auto" />
    </Drawer>
  );
}

export default SideDrawer;

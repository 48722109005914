export const BASE_URL = `${window.location.hostname}` === 'localhost'? 'demo.aimedcare.co': `${window.location.hostname}`;

export const PROTOCOL_HTTP = `${window.location.protocol}` === 'https:' || `${window.location.hostname}` === 'localhost'? 'https' : 'http';
export const PROTOCOL_WS = `${window.location.protocol}` === 'https:' || `${window.location.hostname}` === 'localhost'? 'wss' : 'ws';

const constants = {
  SOCKET: `${PROTOCOL_WS}://${BASE_URL}/ws/group`,
  REPLAY_SOCKET: `${PROTOCOL_WS}://${BASE_URL}/ws/session`,
  SOCKET1: `${PROTOCOL_WS}://${BASE_URL}/ws`,
  API_URL: `${PROTOCOL_HTTP}://${BASE_URL}/api`,
  IntervalInitialValue: "10",
  Version: "CI-179",
  Date: "01/12/2021",
};

export default constants;

import React, { memo } from "react";
import ReactEcharts from "echarts-for-react";
import CircularProgress from '@mui/material/CircularProgress';
import * as echarts from 'echarts';
import styles from './BPChartPopup.module.css';

function BPLineChart({ title, xData, yData1, yData2, bpRes, handleClick }) {

  const colorBP1 = bpRes
    ?.map((point) => {
      var epochTS = new Date(parseInt(point.x_data));
      var dsnow = `${epochTS.toLocaleDateString()} \n ${epochTS.toLocaleTimeString()}`;

      return {
        value: [dsnow, (point?.y_data?.BPS).toFixed(1)],

        itemStyle: {
          color: point.y_data.is_manual ? "#808080" : "#27A9E3",
        },
      };
    })
    .sort((a, b) => {
      // Convert the x-axis values to Date objects for comparison
      const dateA = new Date(`01/01/2021 ${a.value[0]}`);
      const dateB = new Date(`01/01/2021 ${b.value[0]}`);

      // Compare the dates and return the sort order
      if (dateA < dateB) {
        return -1;
      }
      if (dateA > dateB) {
        return 1;
      }
      return 0;
    });


  const colorBP2 = bpRes
    ?.map((point) => {
      var epochTS = new Date(parseInt(point.x_data));
      var dsnow = `${epochTS.toLocaleDateString()} \n ${epochTS.toLocaleTimeString()}`;

      return {
        value: [dsnow, (point?.y_data?.BPD).toFixed(1)],
        itemStyle: {
          color: point.y_data.is_manual ? "#808080" : "#852B99",
        },
      };
    })
    .sort((a, b) => {
      // Convert the x-axis values to Date objects for comparison
      const dateA = new Date(`01/01/2021 ${a.value[0]}`);
      const dateB = new Date(`01/01/2021 ${b.value[0]}`);

      // Compare the dates and return the sort order
      if (dateA < dateB) {
        return -1;
      }
      if (dateA > dateB) {
        return 1;
      }
      return 0;
    });



  const chartData = React.useMemo(() => {
    return {

      tooltip: {
        textStyle: {
          fontFamily: "sans-serif",
        },

        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      
      dataZoom: [
        {
          type: 'inside',
          start: 0,
          end: 100
        },
        {
          start: 10,
          end: 0
        }
      ],
      grid: { // Add grid property to set the margin
        top: "8%", // Adjust the bottom margin as needed
        containLabel: true, // Include axis labels and title inside the grid
      },

      xAxis: {
        type: "category",
        // show:false,
        axisLabel: {
          fontFamily: "sans-serif",
        },
      },
      yAxis: [
        {
          type: "value",
          axisLabel: {
            fontSize: 10,
            fontFamily: "'Montserrat', sans-serif",
           fontWeight:500
        },
        splitLine: {
            show: false
          },
          // show:false
        },
        {
          type: "value",
          axisLabel: {
            fontSize: 10,
            fontFamily: "'Montserrat', sans-serif",
           fontWeight:500
        },
        splitLine: {
            show: false
          },
          // show:false
        },
        
      ],

      series: [
        {
          name: "Sys ",
          type: "line",
          color: "#00B5C6",
          smooth: true,
          data: colorBP1,
          areaStyle: {
            opacity:0.3 ,
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 1,
                color: 'white'
              },
              {
                offset: 0,
                color: '#00B5C6'
              }
            ])
          },
        },
        {
          name: "Dia ",
          type: "line",
          smooth: true,
          textStyle: {
            fontFamily: "sans-serif",
          },
          color: "#6D3DA3",
          data: colorBP2,
          areaStyle: {
            opacity:0.3 ,
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 1,
                color: 'white'
              },
              {
                offset: 0,
                color: '#6D3DA3'
              }
            ])
          },
        },
       
      ],
    };
  }, [xData, yData1, yData2, title]);
  
  const _handleClick = (params, data) => {
    let index = params.dataIndex;
    handleClick &&
      "function" === typeof handleClick &&
      handleClick(colorBP1[index],colorBP2[index]);
  };
  
  return (
    <div style={{height:"100%"}}>
      {chartData?.series?.[0]?.data?.length ? (
        <div className="left-chart">
          <ReactEcharts
            option={chartData}
            onEvents={{
              click: _handleClick,
            }}
            style={{ width: "fitContent"}}
          />
        </div>
      ) : (
        <p className={styles.noDataStyles}>
         {/* <CircularProgress /> */}
         No Data
        </p>
      )}
    </div>
  );
}
export default memo(BPLineChart);

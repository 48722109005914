import React from 'react'
import IconButton from '@mui/material/IconButton';
import { Add, Close, MoreHoriz } from "@material-ui/icons";
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import { Fab } from '@mui/material';

export default function FloatingAddbtn({option1,option2,option3,option4,option5,option6,option7,option8, option9,option10}) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

  return (
   <React.Fragment>
     <Fab color="primary" aria-label="add" 
          aria-describedby={id} onClick={handleClick} style={{
                position: "fixed",
                bottom: 20,
                right: 20,
              }}>
        <Add />
     </Fab>
     <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >

        
        <div className='cardDropdown-addbtn'>
        <IconButton onClick={handleClose} style={{ top: 0,left:85}}>
            <Close />
          </IconButton>
          <div className='addbtn-heading'> Select a parameter </div>
          <div className='addcard-options'>{option1}</div>
          <div className='addcard-options'>{option2}</div>
          <div className='addcard-options'>{option3}</div>
          <div className='addcard-options'>{option4}</div>
          <div className='addcard-options'>{option5}</div>
          <div className='addcard-options'>{option6}</div>
          <div className='addcard-options'>{option7}</div>
          <div className='addcard-options'>{option8}</div>
          <div className='addcard-options'>{option9}</div>
          <div className='addcard-options'>{option10}</div>

        </div>
      </Popover>
   </React.Fragment>
  )
}

import React from "react";
import ReactEcharts from "echarts-for-react";
import "../../FitCharts/FNewDash.css";

export default function SPO2Chartpage1({ title, xData, yData, handleClick }) {
  const chartData = React.useMemo(() => {
    return {
      title: {
        left: "center",
        top:-5,
        text: title,
        textStyle: {
          fontFamily: "sans-serif",
        },
      },

      tooltip: {
        trigger: "axis",
        textStyle: {
          fontFamily: "sans-serif",
        },
        axisPointer: {
          type: "shadow",
        },
      },

      xAxis: {
        type: "category",
        axisLabel: {
          fontFamily: "sans-serif",
        },

        data: xData,
      },
      yAxis: {
        type: "value",
        axisLabel: {
          fontFamily: "sans-serif",
        },
      },

      series: [
        {
          textStyle: {
            fontFamily: "sans-serif",
          },
          name: "SpO2 % ",
          type: "bar",
          areaStyle: {},

          symbol: "none",

          color: "#00B5C6",

          data: yData,
        },
      ],
    };
  }, [xData, yData, title]);

  return (
    <div>
      {chartData?.xAxis?.data?.length ? (
        <div className="left-chart">
          <ReactEcharts
            option={chartData}
            onEvents={{
              click: handleClick,
            }}
          />
        </div>
      ) : (
        <p style={{fontSize:"20px", fontWeight:"bold"}}>NO DATA AVAILABLE </p>
      )}
    </div>
  );
}

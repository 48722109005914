import React, { useState, useEffect } from "react";
import "./StatsCard.css";
import common from "../../Common/Common";

const GraphStatsCard = ({ statusType, statusValue, statusColor }) => {
  const [maxValue, setMaxValue] = useState(null);
  const [minValue, setMinValue] = useState(null);
  const [avgValue, setAvgValue] = useState(0);
  const [statusUnit, setStatusUnit] = useState("");
  const [statusDescription, setStatusDescription] = useState("");

  useEffect(() => {
    setMaxValue(10);
    setMinValue(5);
    setAvgValue(10);
    setStatusUnit("");

    Object.keys(common.STATUS_UNITS).forEach((key, index) => {
      if (key === statusType) setStatusUnit(common.STATUS_UNITS[key]);
    });

    Object.keys(common.STATUS_MAXVALUE).forEach((key, index) => {
      if (key === statusType) setMaxValue(common.STATUS_MAXVALUE[key]);
    });

    Object.keys(common.STATUS_MINVALUE).forEach((key, index) => {
      if (key === statusType) setMinValue(common.STATUS_MINVALUE[key]);
    });

    Object.keys(common.STATUS_AVGVALUE).forEach((key, index) => {
      if (key === statusType) setAvgValue(common.STATUS_AVGVALUE[key]);
    });

    Object.keys(common.STATUS_DESCRIPTION).forEach((key, index) => {
      if (key === statusType)
        setStatusDescription(common.STATUS_DESCRIPTION[key]);
    });
  }, []);


  return (
    statusType && (
      <div className="graphDashboard_GridCardItem">
        <div className="graphDashboard_CellFirstCol">
          <div className={`graphDashboard_StatusType ${statusColor}`}>
            {statusDescription ? (
              <p>{statusDescription}</p>
            ) : (
              <p>{statusType}</p>
            )}
          </div>
          <div className={`graphDashboard_StatusUnit ${statusColor}`}>
            <p>{statusUnit}</p>
          </div>
        </div>
        <div className="graphDashboard_CellSecondCol">
          <div className={`graphDashboard_StatusValue ${statusColor}`}>
            <p>{statusValue}</p>
          </div>
        </div>
      </div>
    )
  );
};
export default GraphStatsCard;

import { CheckCircle } from '@material-ui/icons'
import React from 'react'
import './FNewDash.css';

export const PopMsg = () => {
  return (
    <div>
        <div className="popup">
          <p>Manual Entry successful!<CheckCircle/> </p> 
        </div>
    </div>
  )
}

import React, { useEffect } from 'react'
import { useState } from "react";
import styles from "./SignUpv2.module.css"
import FormSignUp from './FormSignUp';
import constants from '../../../../../Common/Constants.js'
import axios from 'axios';
import { Button } from 'react-bootstrap';
import {
  addAuthToken,
  addGroupName,
} from "../../../../../store/authSlice.js";
import { ButtonBase } from '@material-ui/core';
import { FcGoogle } from 'react-icons/fc';
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import BGImage from './SignUPBackgroundImage-removebg-preview.png'
import { Divider, Grid, Typography } from '@mui/material';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faMobile, faMobileAndroid, faMobilePhone} from '@fortawesome/free-solid-svg-icons';
import GSignUp from '../../SSO/GSignUp';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { dispatch } from 'd3';
import { data } from 'jquery';

const SignUpv2 = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  
  const [newUserID, setNewUserID] = useState("");
  const [newUserName, setNewUserName] = useState("");
  const [newUserToken, setNewUserToken] = useState("");

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const isMobileView = window.innerWidth <= 768; 

    const history =useHistory();
    const handleLoginPage = () =>{
        history.push("/")
    }
    const [errors, setErrors] = useState('');
    const [values, setValues] = useState({
        username: '',
        password: '',
        confirmPassword: '',

      });
    
      const inputs = [
        {
          id: 1,
          name: "username",
          type: "text",
          placeholder: "Username",
          errorMessage:
            "Should inclue 3-16 characters with letters or numbers",
        //   label: "Username",
          pattern: "^[A-Za-z0-9]{3,16}$",
          required: true,
          defaultValue:'',
        },
        {
          id: 4,
          name: "password",
          type: "password",
          placeholder: "Password",
          errorMessage:
            "Should be 8-20 characters with at least 1 Letter, 1 number and 1 special character!",
        //   label: "Password",
          pattern: `^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$`,
          required: true,
          defaultValue:"",
        },
    {
          id: 5,
          name: "confirmPassword",
          type: "password",
          placeholder: "Confirm Password",
          errorMessage: "Passwords don't match!",
        //   label: "Confirm Password",
          pattern: values.password,
          required: true,
          defaultValue:"",
        },
      ];
     
      var formData=JSON.stringify({id:(values.username), password:(values.password)});
      // 

      const handleSubmit = (e) => {
        e.preventDefault();
 
      
          axios({
            method: "post",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            url: `${constants.API_URL}/sign_up/`,
            data: formData,
          
          }).then(
            (res) => {
              console.log("Result", res);
              // setNewUserID(res?.data?.id);
              // setNewUserName(res?.data?.group_name);
              // setNewUserToken(res?.data?.token);
              // dispatch(addGroupName(res?.data?.id));
              // dispatch(addAuthToken(res?.data?.token));
              
              // if(res?.status === 200){
              //   history.push('/register_page')
              // }
              const newID = res?.data?.id
              // const newName = res?.data?.group_name
              const newToken = res?.data?.token
              
              setTimeout(() => {
                history.push({
                  pathname: '/register_page',
                  state: { newID:newID,newToken:newToken}
                });
              }, 1000);
              clearTimeout();

            }).catch((e) =>{
              setErrors(e.res?.data?.message)
              // 
            })

          }
    
      const onChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
      };

      const handleMobileSignUpClick =() =>{
        history.push("/mobile_signup_page")
      }

    return (
        <div className={styles.container}>
        <div className={styles.innerBox}>
          <div className={styles.left}>
            
            <div className={styles.leftBlur} >
              <div className={styles.leftHeading}>
              <span style={{ color: "#FFFFFF", fontFamily:"'Montserrat', sans-serif"}}> Welcome to CareHQ</span>
              {/* <span style={{ color: "#FFFFFF"}}> </span> */}
              </div>
              <div className={styles.imageBG}>
              <img src={BGImage} style={{width:"250px"}} alt="Your Image" />
              </div>
              <div style={{marginBottom:"5px"}}>   <span style={{ color: "#FFFFFF", fontFamily:"'Montserrat', sans-serif", fontSize:"0.7rem"}}> Powered by CareHQ Team </span></div>
            </div>
          </div>
            <div className={styles.right}>
              <div className={styles.headerTitle}>SIGN UP</div>
            <form onSubmit={handleSubmit}>
            {inputs.map((input) => (
             <div  style={{display:"flex",position:"relative"}}> <FormSignUp className={styles.field}
             key={input.id}
             {...input}
             value={values[input.name]}
             onChange={onChange}
             defaultValue=''
             {...( input.type === "password"? { type : passwordVisible ? "text" : "password"} : {})}
             autoComplete="off"
           />
           { input.type === "password"  && <span style={{
                      position: "absolute",
                      top:20,
                     right:10,
                     cursor:"pointer"
                    }}>  <div
                      style={{ color: "#808080" }}
                      onClick={togglePasswordVisibility}
                    >
                      {passwordVisible ? <VisibilityIcon/> : <VisibilityOffIcon/>}
                    </div></span>}
              </div>
            ))}

           
            <button className={styles.submit1} type="submit">SUBMIT</button>
            {errors && 
            <div 
            style={{
              color: "red",
              marginTop: "0",
              textAlign: "center",
              fontSize: "12px"
            }}
          >
              {errors}</div>}

              <Grid container alignItems="center" style={{ marginTop: "0px" }}>
                <Grid item xs>
                  <Divider style={{ height: "1px", backgroundColor: "black" }} />
                </Grid>
                <Grid item>
                  <Typography variant="caption" style={{ margin: "0 10px" }}>OR</Typography>
                </Grid>
                <Grid item xs>
                  <Divider style={{ height: "1px", backgroundColor: "black" }} />
                </Grid>
              </Grid>

              <div style={{display:"flex", flexDirection:"row", justifyContent:"space-evenly", marginTop:"5px"}}> 
                <div> 
                  <GSignUp/>
                </div>
                <div>
                  <Button
                    label='Login with Mobile'
                   onClick={handleMobileSignUpClick}
                    // disabled={renderProps.disabled}
                    style={{
                      width: "fit-content",
                      height: "100%",
                      borderRadius: "20px",
                      background: "#fff",
                      color: "#6D3DA3",
                      fontSize: "11.5px",
                      fontWeight: "500",
                      fontFamily: "'Montserrat', sans-serif",
                      alignItems: "center",
                      textTransform: "uppercase",
                      padding: "8px 10px 5px 10px",
                      border: "transparent",
                      transition: "all 0.2s ease-out",
                      boxShadow: "0px 4px 8px 0px rgba(0,0,0,0.3)",

                    }}
                  >
                    <FontAwesomeIcon icon={faMobilePhone} size="xl" style={{ marginRight: "5px" }} />
                    MOBILE
                  </Button>
                </div>
              </div>

              <div> <div style={{ 
                marginBottom:"5px",
                fontFamily:"'Montserrat', sans-serif", 
                fontSize:"11px", fontWeight:"500"}}>Already having an account ? <button className={styles.login1} onClick={handleLoginPage}>LOGIN</button></div> </div>
            
          </form>
                        
              
        
            </div>
          </div>
        </div>
      )
    }
    export default SignUpv2

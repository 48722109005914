import React, { useEffect, useState } from 'react'
import constants from '../../../../../Common/Constants';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faCircleCheck, faMessage, faMinus} from '@fortawesome/free-solid-svg-icons';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { Divider, Menu, MenuItem, Typography } from '@material-ui/core';
import axios from 'axios';
import  styles from'./NotifyCSS.module.css'
import { Alert } from '@material-ui/lab';
import { Snackbar } from '@material-ui/core';

const NotificationRequest = () => {

    const groupName = useSelector((state) => state.auth.groupName);
    const token = useSelector((state) => state.auth.authToken);
    const [requests, setRequests] = useState([]);
    const [notifyList, setNotifyList] = useState([]);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarColor, setSnackbarColor] = useState('');

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleShowNotificationList = (event) => {
      setAnchorEl(event.currentTarget);
      fetchRequests();
      fetchNotify();
    }; 
    const handleMenuClose = () => {
      setAnchorEl(null);
    };

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const trigger = useRef(null);
    const dropdown = useRef(null);
  
    // close on click outside
    useEffect(() => {
      const clickHandler = ({ target }) => {
        if (!dropdown.current) return;
        if (!dropdownOpen || dropdown.current.contains(target) || trigger.current.contains(target)) return;
        setDropdownOpen(false);
      };
      document.addEventListener('click', clickHandler);
      return () => document.removeEventListener('click', clickHandler);
    });
  
    // close if the esc key is pressed
    useEffect(() => {
      const keyHandler = ({ keyCode }) => {
        if (!dropdownOpen || keyCode !== 27) return;
        setDropdownOpen(false);
      };
      document.addEventListener('keydown', keyHandler);
      return () => document.removeEventListener('keydown', keyHandler);
    });

    const fetchRequests = async () => {
      try {
          const response = await axios.get(`${constants.API_URL}/list_requests/${groupName}/${token}/`);
          setRequests(response?.data?.Requests);
          console.log(response)
      } catch (error) {
          // Handle error
      }
    };
    
        useEffect(() => {    
            fetchRequests();
        }, []);

    const fetchNotify = async () => {
      try {
          const res = await axios.get(`${constants.API_URL}/notifications/${groupName}/${token}/`);
          console.log(res)
          setNotifyList(res?.data?.data)
      } catch (error) {
          // Handle error
      }
    };
    
      useEffect(() => {    
          fetchNotify();
        }, []);    
        
  const acceptRequest = async (requestId) => {

      const acceptReqData = {"rel_id":requestId,"relation":"family", "accept":true }
    try {
     const res = await axios.post(`${constants.API_URL}/manage_request/${groupName}/${token}/`, 
    acceptReqData);
    console.log(res);
    if (res?.status === 200){

      setSnackbarOpen(true);
      setSnackbarColor('success');
      setSnackbarMessage('Request Accepted');
      setTimeout(() =>{
        fetchRequests();
      },1500)
    }

    } catch (error) {
      if(error?.response?.status === 400){
        setSnackbarOpen(true);
        setSnackbarColor('error');
        setSnackbarMessage('Request already accepted');
        fetchRequests();
       }
       else if (error?.response?.status === 404) {
        setSnackbarOpen(true);
        setSnackbarColor('error');
        setSnackbarMessage('Something went wrong');
        fetchRequests();
       }
       else{
        setSnackbarOpen(true);
        setSnackbarColor('error');
        setSnackbarMessage('Something went wrong');
        fetchRequests();
       }
    }
  };

  const declineRequest = async (requestId) => {

    const declineReqData = {"rel_id":requestId,"relation":"family", "accept":false }
  try {
   const res = await axios.post(`${constants.API_URL}/manage_request/${groupName}/${token}/`, 
   declineReqData);
  console.log(res);
  if (res?.status === 200){
    setSnackbarOpen(true);
    setSnackbarColor('warning');
    setSnackbarMessage('Request Declined');
    fetchRequests();
  }

  } catch (error) {
 
   if(error?.response?.status === 400){
    setSnackbarOpen(true);
    setSnackbarColor('error');
    setSnackbarMessage('Request already declined');
    fetchRequests();
   }
   else if (error?.response?.status === 404) {
    setSnackbarOpen(true);
    setSnackbarColor('error');
    setSnackbarMessage('Something went wrong');
    fetchRequests();
   }
   else{
    setSnackbarOpen(true);
    setSnackbarColor('error');
    setSnackbarMessage('Something went wrong');
    fetchRequests();
   }
  }
};

console.log("request", requests);
console.log("notification", notifyList)

  return (
    
    <div className={styles.NotificationBtn}>
      <FontAwesomeIcon size='lg' icon={faMessage} style={{cursor:"pointer"}} color="#7F55AE"  onClick={handleShowNotificationList}/>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <div className={styles.listContainer}>
           <Typography style={{fontFamily:"'Montserrat', sans-serif", fontSize:"0.9rem", fontWeight:"600"}}>Notifications {""}</Typography>
           <Divider style={{backgroundColor:"black", marginBottom:"5px"}}/>
           <div className={styles.scrollContainer}>
           { requests?.length === 0 && notifyList?.length === 0 ? (
                <div className={styles.reqTypography}>No Notification</div>
            ) : (
              <>
           {requests?.map((request) => (
              <div key={request.rel_id}>
                <div className={styles.nameDetailsContainer} style={{margin:"0px 5px 0px 5px"}}>
                <div className={styles.reqTypography}>{request.name} sent you a request</div>
               <div style={{display:"flex", flexDirection:"row"}}>
                <button className={styles.acceptBtn} onClick={() => acceptRequest(request.rel_id)}>ACCEPT</button>
                <button className={styles.declineBtn} onClick={() => declineRequest(request.rel_id)}>DECLINE</button>
                </div>
                </div>
                <Divider/>
              </div>
            ))}

            {notifyList.map((notification) => (
                  <div key={notification.pat_id}>
                    <div className={styles.nameDetailsContainer} style={{margin:"5px"}}>
                      <div className={styles.reqTypography}>
                        {notification.name} has accepted your request <FontAwesomeIcon icon={faCircleCheck} style={{ color: "#19c22d", height:"16px", width:"16px"}}/>
                      </div>
                      {/* Add additional rendering logic for the second API notifications */}
                    </div>
                    <Divider />
                  </div>
                ))}
              </>
            )}
             <FontAwesomeIcon icon={faMinus} color='rgb(179, 179, 179)' style={{marginTop:"-10px"}}/>
           </div>
        </div>
        <div className={styles.snackBarContainer} style={{zIndex:"1000"}}>
      <Snackbar
   anchorOrigin={{ vertical:"top", horizontal:"right" }}
     open={snackbarOpen}
     autoHideDuration={3000}
     onClose={() => setSnackbarOpen(false)}
     message={snackbarMessage}
     key={"top" + "right"}
   >
     <Alert severity={snackbarColor} sx={{ width: '100%' }}>
       {snackbarMessage}
     </Alert>
   </Snackbar>
   </div>
      </Menu>
       </div>
  )
}

export default NotificationRequest

import React, { useEffect, useRef, useState } from "react";
import PatientMonCard from "../PatientMonCard";
import PatientMonitor from "../PatientMonitor";
import Ventilator from "../Ventilator";
import VentilatorCard from "../VentilatorCard";
import "./Commonv2.css";
import Headerv2 from "./Headerv2";
import BRv2 from "./VitalCharts/BRv2";
import Caloriev2 from "./VitalCharts/Caloriev2";
import Tempv2 from "./VitalCharts/Tempv2";
import Weightv2 from "./VitalCharts/Weightv2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileContract,
  faFire,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import CircleCommonCard from "./VitalCharts/CircleCommonCharts/CircleCommonCard";
import StepSpo2v2 from "./VitalCharts/StepSpo2v2";
import BPv2 from "./VitalCharts/BPv2";
import HeartRatev2 from "./VitalCharts/HeartRatev2";
import BPChartsv2 from "./VitalCharts/BPChartsv2";
import SleepChartdaily from "../../Member Details/Comps/FitbitFolder/FitCharts/SleepChartdaily";
import SleepChartv2 from "./VitalCharts/SleepChartv2";
import { useDispatch, useSelector } from "react-redux";
import Ventilatorv2 from "./VitalCharts/Ventilatorv2";
import CareCamv2 from "./VitalCharts/CareCamv2";
import MainCommonCard from "./VitalCharts/MainCommonCard";
import UserData from "./OtherComponents/UserData";
import PillDisv2 from "./VitalCharts/PillDisv2";
import SleepQuick from "./VitalCharts/SleepQuick";
import { useHistory } from "react-router-dom";
import FloatingAddButton from "./Featuresv2/FloatingAddButton";
import PopUpManualEntry from "./OtherComponents/PopUpManualEntry";
import FormBP from "../../Member Details/Comps/FitbitFolder/FitCharts/FormBP";
import FormBG from "../../Member Details/Comps/FitbitFolder/FitCharts/FormBG";
import FormSpO2 from "../../Member Details/Comps/FitbitFolder/FitCharts/FormSpO2";
import FormHR from "../../Member Details/Comps/FitbitFolder/FitCharts/FormHR";
import FormBR from "../../Member Details/Comps/FitbitFolder/FitCharts/FormBR";
import FormTemp from "../../Member Details/Comps/FitbitFolder/FitCharts/FormTemp";
import FormWT from "../../Member Details/Comps/FitbitFolder/FitCharts/FormWT";
import FormStep from "../../Member Details/Comps/FitbitFolder/FitCharts/FormStep";
import FormCalorie from "../../Member Details/Comps/FitbitFolder/FitCharts/FormCalorie";
import FormSleep from "../../Member Details/Comps/FitbitFolder/FitCharts/FormSleep";
import { useLocation } from "react-router-dom";

import {
  setDashboardData,
  setWebsocketStatus,
} from "../../../store/dashboardSlice.js";
import constants from "../../../Common/Constants.js";
import axios from "axios";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import CareTakeCard from "../CareGiverPage/CareTakerCard";
import {
  Avatar,
  Box,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { ArrowDropDownCircleOutlined } from "@material-ui/icons";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import resetReduxState from "../../../Common/Logout.js";

import LoaderCG from "../CareGiverPage/LoaderCG";
import { Button } from "@material-ui/core";
import PopupModal from "./PopupModal";
import BPChartPopup from "./BPv2/BPChartPopup";
import BGChartPopup from "./BGv2/BGChartPopup";
import HeartRatePopup from "./HRv2/HeartRatePopup";
import { SleepPopup } from "./Sleepv2/SleepPopup";
import BRPopup from './BRv2/BRPopup'
import BMI from "./VitalCharts/BMI";


let socket = null;
const MAX_RETRIES = 3;
const RETRY_DELAY = 6000;

function HomeDashboardv2({ closeCreatedSockets }) {

  const [showPopup, setShowPopup] = useState(false);
  const [popupComponent, setPopupComponent] = useState(null);

  const openPopup = (component) => {
    setShowPopup(true);
    setPopupComponent(component);
  };

  const closePopup = () => {
    setShowPopup(false);
    setPopupComponent(null);
  };
  
 
  
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const HeartPage = () => {
    history.push("/heartratepage");
  };
  const StepsPage = () => {
    history.push("/stepspage");
  };
  const SlpPage = () => {
    history.push("/sleeppage");
  };
  const Spo2Page = () => {
    history.push("/spo2page");
  };
  const BrPage = () => {
    history.push("/brpage");
  };
  const BpPage = () => {
    history.push("/bppage");
  };
  const BgPage = () => {
    history.push("/bgpage");
  };
  const MedicationPage = () => {
    history.push("/medication_page");
  };

  const { id, patient_group_id } = useParams();
  const headerGroupName = useSelector((state) => state.auth.headerGroupName);
  const dashboardApiStatus = useSelector((state) => state.dashboard.apiStatus);
  const groupName = useSelector((state) => state.auth.groupName);
  const token = useSelector((state) => state.auth.authToken);
  const deviceTypeList = useSelector((state) => state.home.deviceTypeList);

  const [careData, setCareData] = useState([]);
  const [isCGLoading, setIsCGLoading] = useState(false);
  const [retryCount, setRetryCount] = useState(0);

  const [test, setTest] = useState(1);
  const getLocalStoirageData = () => {
    let d = localStorage.getItem("test");
    if (!test) {
      return false;
    }
    try {
      d = JSON.parse(d);
      return d.token ? d : false;
    } catch (er) {
      return false;
    }
    return !1;
  };

  const careTakerToken = useSelector((state) => {
    let local = getLocalStoirageData();
    if (local) {
      return local.token;
    }
    return state.auth.careTakerToken;
  });

  const userData = useSelector((state) => state.auth.userData);
  const currentUserIndex = useSelector((state) => state.auth.currentUserIndex);

  const careTaker = useSelector((state) => {
    let local = getLocalStoirageData();
    if (local && local.token) {
      return false;
    }
    return state.auth.careTaker;
  });

  const urlStateData = location?.state;
  const careTakerPatientView = !!(careTaker && patient_group_id);

  // User details PopOver Button

  const isMobileViewButton = window.innerWidth <= 530;

  const [isDetailsPopoverOpen, setDetailsPopoverOpen] = useState(false);

  const popoverRef = useRef(null);

  const togglePopover = () => {
    setDetailsPopoverOpen(!isDetailsPopoverOpen);
  };

  const closePopover = () => {
    setDetailsPopoverOpen(false);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        closePopover();
      }
    };

    const handleNavigate = () => {
      closePopover();
    };

    document.addEventListener("mousedown", handleOutsideClick);
    window.addEventListener("beforeunload", handleNavigate);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
      window.removeEventListener("beforeunload", handleNavigate);
    };
  }, []);

  useEffect(() => {
    // if (dashboardApiStatus == "fetch") {

      const fetchData = async () => {

      let url = `${constants.API_URL}/dashboard_values/${careTakerPatientView?patient_group_id:groupName}/${careTakerPatientView?urlStateData?.careTakerToken:token}/`;
            
      const result = await axios.get(url);

      dispatch(setDashboardData(result.data.data));
      };

      {(!careTaker || careTakerPatientView) &&(
      fetchData()
      )}
      
      const intervalId = setInterval(fetchData, 610000);
      return () => clearInterval(intervalId)
    // }
  }, [dashboardApiStatus, groupName, token, currentUserIndex, userData,test ]);

  let data;
  const [errorMessage, setErrorMessage] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = (event) => {
    setAnchorEl(null);
    axios({
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      url: `${constants.API_URL}/logout/caretaker/${careTakerToken}/`,
      data: data,
    }).then(
      (response) => {
        history.push("/");
        setAnchorEl(anchorEl ? null : event.currentTarget);
        resetReduxState(dispatch);
        // closeCreatedSockets();
        setCareData([]);
      },
      (error) => {
        history.push("/");
        setErrorMessage(true);

        resetReduxState(dispatch);
        setCareData([]);
        // closeCreatedSockets();
      }
    );
  };

  let retries = 0;

  const getUsers = async () => {
    setIsCGLoading(true);

    socket = new WebSocket(

        `${constants.SOCKET1}/caretaker_dashboard/${careTakerToken}/`
      );
      socket.onopen = (evt) => {
        
        setRetryCount(0);
        // send data to server to echo back
        //   socket.send(
        //     JSON.stringify({
        //       foo: "baz"
        //     })
        //   );
      };
  
      // list for data from server
      socket.onmessage = (evt) => {
        let data=JSON.parse(evt?.data)
        
        // let careTakerData=data.message
        setCareData(data?.message)
        setIsCGLoading(false)
        // 
      };

      socket.onerror=(error)=>{
        if(retries < MAX_RETRIES){
          retries++;
          setTimeout(()=>{
            
            getUsers();
          },RETRY_DELAY);
          // setTimeout(connectWebSocketCareTaker,RETRY_TIMEOUT);
          // setRetryCount(retryCount + 1);
        }
        else {
          console.error(`WebSocket connection failed after ${MAX_RETRIES} attempts`);
          history.push('/')
        }
      };
      // 
      // 

 
  
      return () => {
        if(socket){
          socket.close();
        }
      };
  }
  
  let intervalIdWS ;
    useEffect(() =>{
      {careTaker && !careTakerPatientView &&
        ( getUsers()
         )}
         {careTaker && !careTakerPatientView &&
          ( intervalIdWS = setInterval(getUsers, 500000)
          )}
         return () => clearInterval(intervalIdWS)
     
          
        
  },[ test ])


  const CardOnClick = async( token, group_id ) => {

    history.push(`/patient/${group_id}`,{ patient_token: token})
    
};

  const renderScreen = (
    <div>
      <div className="containerPatientView">
        <div className="header-caretakerMain">
          <div
            className="header-Caretkr-left"
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "15px",
              fontSize: 30,
            }}
          >
            <span style={{ color: "#6A4396", fontWeight: "Bold" }}>Care</span>
            <span style={{ color: "#23AF72", fontWeight: "Bold" }}>HQ </span>
          </div>
          <div
            className="header-Caretkr-right"
            style={{ display: "flex", alignItems: "center" }}
          >
            <Box
              onClick={handleMenuClick}
              sx={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <IconButton className="userAvatar" size="small" sx={{ ml: 2 }}>
                <Avatar></Avatar>
              </IconButton>
              <Typography
                style={{
                  color: "#747474",
                  fontSize: "18px",
                  fontWeight: "normal",
                }}
              >
                CareTaker
              </Typography>
              <IconButton className="userDropDown" size="small" sx={{ ml: 2 }}>
                <ArrowDropDownCircleOutlined
                  style={{ color: "black", marginLeft: 0 }}
                />
              </IconButton>
            </Box>
            <Menu
              id="LoggedUser"
              anchorEl={anchorEl}
              open={open}
              onClose={handleMenuClose}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
              <MenuItem
                className="normalText minHeight-md-0"
                onClick={(e) => handleLogout(e)}
              >
                <ListItemIcon>
                  <ExitToAppIcon fontSize="small" style={{ color: "white" }} />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
          </div>
        </div>

        <div className="dem-carehr-container main-container">
          <div class="wrapper1">
            {careData?.map((curElem) => {
              return (
                <div>
                  <CareTakeCard
                    title={curElem?.group_name}
                    token={curElem?.token}
                    group_id={curElem?.group_id}
                    RTlabel={curElem.key0}
                    RTunit={curElem.unit0}
                    RTvalue={curElem.data0}
                    alarmPoint0={curElem.alarm_point0}
                    LTlabel={curElem.key1}
                    LTunit={curElem.unit1}
                    LTvalue={curElem.data1}
                    alarmPoint1={curElem.alarm_point1}
                    RBlabel={curElem.key2}
                    RBunit={curElem.unit2}
                    RBvalue={curElem.data2}
                    alarmPoint2={curElem.alarm_point2}
                    LBlabel={curElem.key3}
                    LBunit={curElem.unit3}
                    LBvalue={curElem.data3}
                    alarmPoint3={curElem.alarm_point3}
                    handleCardClick={CardOnClick}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );


  return (
    <>

    { (!careTaker || careTakerPatientView)&&(
    <div className='dashMainv2'>
     <div classname="headerV2"> 
     <Headerv2/> 
     </div>
          <div className="dashboardv2MainContainer-v2">
            <div className="left-panel-v2">
              <div className="week-month-tab"> </div>
              <div className="left-card-main-grid">
                <HeartRatev2 onClick={() => openPopup(<HeartRatePopup/>)} />
                {showPopup && (
                  <PopupModal onClose={closePopup}>
                    {popupComponent}
                  </PopupModal>
                )}
                <BPChartsv2 onClick={() => openPopup(<BPChartPopup/>)} />
                {showPopup && (
                  <PopupModal onClose={closePopup}>
                    {popupComponent}
                  </PopupModal>
                )}
                <BPv2 onClick={() => openPopup(<BGChartPopup/>)} />
                {showPopup && (
                  <PopupModal onClose={closePopup}>
                    {popupComponent}
                  </PopupModal>
                )}
                <SleepQuick onClick={() => openPopup(<SleepPopup/>)} />
                {showPopup && (
                  <PopupModal onClose={closePopup}>
                    {popupComponent}
                  </PopupModal>
                )}
                <StepSpo2v2 />
                <PillDisv2 onClick={MedicationPage} />

              </div>
            </div>
            <div className="right-panel-v2">
              <div className="side-container-v2">
                <div
                  className="details-css"
                  style={{
                    margin: "0px 5px 5px 5px",
                    padding: "5px 5px 5px 5px",
                    fontFamily: "'Montserrat', sans-serif",
                    fontSize: "1rem",
                    color: "#75757C",
                    fontWeight: "500",
                  }}
                >
                  DETAILS{" "}
                  <FontAwesomeIcon
                    icon={faPen}
                    style={{ fontSize: "0.7rem", justifyContent: "center" }}
                    color="#7F55AE"
                  />
                </div>
                {/* <div
                  className="container-right-patient-details-v2"
                  style={{ minHeight: "240px" }}
                >
                  <UserData />
                </div> */}
                <div
                  className="patient-details-button-v2"
                  style={{ width: "100%" }}
                >
                  <div className="user-popover-container" ref={popoverRef}>
                    <Button className="user-button" onClick={togglePopover}>
                      DETAILS
                    </Button>
                    {isDetailsPopoverOpen && (
                      <div className="popover-content">
                        <UserData />
                      </div>
                    )}
                    {isDetailsPopoverOpen && (
                      <div
                        className="popover-overlay"
                        onClick={closePopover}
                      ></div>
                    )}
                  </div>
                </div>
                <div className="conatainer-right-vitals-v2">
                  <Tempv2 />
                  <BRv2 onClick={() => openPopup(<BRPopup/>)} />
                  {showPopup && (
                  <PopupModal onClose={closePopup}>
                    {popupComponent}
                  </PopupModal>
                )}
                  <Weightv2 />
                  <Caloriev2 />
                  <BMI/>
                </div>
              </div>
            </div>
          </div>
          <FloatingAddButton
            option1={
              <PopUpManualEntry
                optionName="Blood Pressure"
                FormTitle="Blood Pressure"
                FormType={<FormBP />}
                careTakerPatientView={careTakerPatientView}
              />
            }
            option2={
              <PopUpManualEntry
                optionName="Blood Glucose"
                FormTitle="Blood Glucose"
                FormType={
                  <FormBG careTakerPatientView={careTakerPatientView} />
                }
              />
            }
            option9={
              <PopUpManualEntry
                optionName="SpO2 "
                FormTitle="SpO2 "
                FormType={
                  <FormSpO2 careTakerPatientView={careTakerPatientView} />
                }
              />
            }
            option3={
              <PopUpManualEntry
                optionName="Heart Rate"
                FormTitle="Heart Rate"
                FormType={
                  <FormHR careTakerPatientView={careTakerPatientView} />
                }
              />
            }
            option4={
              <PopUpManualEntry
                optionName="Breath Rate"
                FormTitle="Breath Rate"
                FormType={
                  <FormBR careTakerPatientView={careTakerPatientView} />
                }
              />
            }
            option5={
              <PopUpManualEntry
                optionName="Temperature"
                FormTitle="Temperature"
                FormType={
                  <FormTemp careTakerPatientView={careTakerPatientView} />
                }
              />
            }
            option6={
              <PopUpManualEntry
                optionName="Weight"
                FormTitle="Weight"
                FormType={
                  <FormWT careTakerPatientView={careTakerPatientView} />
                }
              />
            }
            option8={
              <PopUpManualEntry
                optionName="StepCount"
                FormTitle="Step"
                FormType={
                  <FormStep careTakerPatientView={careTakerPatientView} />
                }
              />
            }
            option7={
              <PopUpManualEntry
                optionName="Calorie"
                FormTitle="Calorie"
                FormType={
                  <FormCalorie careTakerPatientView={careTakerPatientView} />
                }
              />
            }
            option10={
              <PopUpManualEntry
                optionName="Sleep"
                FormTitle="Sleep"
                FormType={<FormSleep />}
                careTakerPatientView={careTakerPatientView}
              />
            }
          />
        </div>
      )}

      {careTaker && !careTakerPatientView && (
        <div>
          <div>{isCGLoading ? <LoaderCG /> : renderScreen}</div>
        </div>
      )}
    </>
  );
}

export default HomeDashboardv2;

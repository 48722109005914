import React from 'react'
import SideCardCommon from '../SideCardCommon'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faTemperatureFull} from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import FormTemp from '../../../Member Details/Comps/FitbitFolder/FitCharts/FormTemp';
import TileRemove from '../Featuresv2/TileRemove';
import { useState } from 'react';
import { useEffect } from 'react';

function Tempv2({onClick}) {

  const [isExpired, setIsExpired] = useState(false);

  const tempDaily = useSelector((state) => state.dashboard.tempDaily);
  const TMPDaily =(((tempDaily?.data?.y_data?.TMP) * (9/5) )+ 32).toFixed(1);
  // console.log(tempDaily)

  const alarmStatus= tempDaily?.data?.y_data?.status_point
  
  const timeGapIndicationTMP = tempDaily?.data?.x_data?.time_gap
  // console.log(timeGapIndicationTMP)

  useEffect(() => {
    if (timeGapIndicationTMP?.includes("day") || timeGapIndicationTMP?.includes("days")) {
      setIsExpired(true);
    }
    else{
      setIsExpired(false);
    }
  }, [timeGapIndicationTMP, tempDaily]);
  // if(!TMPDaily)
  // return null;
  
  if (!TMPDaily || TMPDaily === "NaN"){
    return null;
  }
  
  return (
    <SideCardCommon title="Temp" 
    onClick={onClick}
    tileBgColor={isExpired? "#F9F9F9" : "white"}
    iconpic={<FontAwesomeIcon icon={faTemperatureFull} color="#7F55AE"/>}
    parameterValue={TMPDaily? TMPDaily:"-"} 
    parameterUnit={TMPDaily? "F":"-"}
    alarmStatus={tempDaily?.data?.y_data?.status}
    timeGap={tempDaily?.data?.x_data?.time_gap}
    FormTitle={"Temperature"}
    FormType={<FormTemp/>}
    alarmStatusBorder={alarmStatus}
    Option2={<TileRemove option2title={"REMOVE TILE"} parameter={"TMP"}/>}
    >
        
    </SideCardCommon>
  )
}

export default Tempv2
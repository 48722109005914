import React from "react";
import { useHistory } from "react-router";
import CareGiverPage from "./index.js";
import "./CareTakerCard.css";
import { Avatar } from "@material-ui/core";

export default function CareTakeCard({title,imgUser,alarmPoint0, alarmPoint1, alarmPoint2,alarmPoint3, token, group_id , handleCardClick,RTlabel="--",LTlabel="--",RBlabel="--",LBlabel="--",RTunit="--",LTunit="--",RBunit="--",LBunit="--",RTvalue="--",RBvalue="--",LTvalue="--",LBvalue="--"}) {
// 
const onClickHandler = ()=>{
  "function" === typeof handleCardClick && handleCardClick( token, group_id )
}
  
  return (
    <CareGiverPage title={title} imgUser={<Avatar/>} handleCardClick={onClickHandler}  >
      <div className="container2">
        {/* <div className={classnames(styles.items, styles.alarmStatus)}> */}
        {/* <div className={'items'+('alarmStat2'+alarmPoint0)}>  */}
        <div className= {'items '+ ('alarmStat')+alarmPoint0 }>
        
          <div className="innerItems">
            <div>
                <div className="headerUnit">{RTlabel}</div>
                <div className="cardUnit">{RTunit}</div>
            </div>
            <div className="valueCard">{RTvalue}</div>
          </div>
        </div>
        <div className= {'items '+ ('alarmStat')+alarmPoint1 }>
        <div className="innerItems">
        <div >
                <div className="headerUnit">{LTlabel}</div>
                <div className="cardUnit">{LTunit}</div>
            </div>
            <div  className="valueCard">{LTvalue}</div>
          </div>
        </div>
        <div className= {'items '+ ('alarmStat')+alarmPoint2 }>
        <div className="innerItems">
        <div >
                <div className="headerUnit" >{RBlabel}</div>
                <div className="cardUnit">{RBunit}</div>
            </div>
            <div  className="valueCard">{RBvalue}</div>
          </div>
        </div>
        <div className= {'items '+ ('alarmStat')+alarmPoint3}>
        <div className="innerItems">
             <div >
                <div className="headerUnit">{LBlabel}</div>
                <div className="cardUnit">{LBunit}</div>
            </div>
            <div  className="valueCard">{LBvalue}</div>
          </div>
        </div>
      </div>
    </CareGiverPage>
  );
}
import React, { useState } from 'react'
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import CloseButton from 'react-bootstrap/CloseButton';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import constants from '../../../../Common/Constants.js';
// import './Components.css'
import {
    setDashboardData,
    setWebsocketStatus,
} from "../../../../store/dashboardSlice.js";
import { CheckCircle } from '@material-ui/icons';
import { MenuItem } from '@mui/material';
import { Alert } from '@material-ui/lab';
import { Snackbar } from '@material-ui/core';

function TileRemove({ careTakerPatientView = false, parameter, option2title }) {

    const [showPopup, setShowPopup] = useState(false);

    const dispatch = useDispatch();

    const [open, setOpen] = React.useState(false);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarColor, setSnackbarColor] = useState('');

    const token = useSelector((state) => state.auth.authToken);
    const groupName = useSelector((state) => state.auth.groupName);

    const careTakerToken = useSelector((state) => state.auth.careTakerToken);

    const { id, patient_group_id } = useParams();

    const location = useLocation();
    const urlStateData = location?.state
    console.log(urlStateData)


    const handleClickOpen = () => {
        if (urlStateData?.patientView == true){
            setOpen(false);
        }
        else{
            setOpen(true);
        }
        
    };
    const handleClose = () => {
        setOpen(false);
    };
    let data;

    const fetchDataApi = async () => {
        let url = `${constants.API_URL}/dashboard_values/${urlStateData ? patient_group_id : groupName}/${token}/`;

        // url += `${token}/`;


        const result = await axios.get(url);
        dispatch(setDashboardData(result.data.data));
    };



    const handleDeleteApi = () => {
        axios({
            method: "get",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            url: `${constants.API_URL}/user_priority/${parameter}/${urlStateData ? patient_group_id : groupName}/${token}/`

        }).then(
            (res) => {
                if (res.status === 200)
                    handleClose();
                    setSnackbarOpen(true);
                    setSnackbarColor('success');
                    setSnackbarMessage('Tile Remove Successful');

                setShowPopup(true);

                setTimeout(() => {
                    // show pop up message
                    setShowPopup(false);

                }, 3000);
                clearTimeout();

                setTimeout(() => {
                    // show pop up message
                    fetchDataApi();

                }, 3000);
                clearTimeout();
            });
    }

    const vertical = "bottom"; 
    const horizontal = "center"; 
   

    const handleAnimationEnd = () => {
        setShowPopup(false);
        // call the onClose function when the animation ends
    };

    return (
        <div>
            {showPopup && (
                <div className="popup" >
                    <p>Tile successfully removed!<CheckCircle /> </p>

                </div>
            )}
            <MenuItem style={{
                fontSize: "0.7rem",
                fontWeight: "600",
                fontFamily: "'Montserrat', sans-serif",
            }}
                onClick={handleClickOpen}>
                {option2title}
            </MenuItem>
            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <div style={{ display: 'flex', justifyContent: 'right' }}>
                    <CloseButton onClick={handleClose} />
                </div>

                <DialogTitle id="customized-dialog-title" onClose={handleClose} textAlign="center" >
                    <div className='removeTile'>  Are you sure you want to remove the tile ? </div>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

                        <div> <Button className="remove-no-btn" onClick={handleDeleteApi} > Yes </Button> </div>

                        <div> <Button className='remove-no-btn' onClick={handleClose}> No </Button> </div>

                    </div>


                </DialogTitle>

            </Dialog>

            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)}
                message={snackbarMessage}
                key={vertical + horizontal}
            >
                <Alert onClose={handleClose} severity={snackbarColor} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </div>

    )
}

export default TileRemove
import { faCaretRight, faChevronLeft, faCircleDot, faCircleInfo, faColonSign, faDotCircle, faEllipsis, faListDots } from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import React, { useState } from 'react';
import './Commonv2.css';
import { makeStyles } from "@material-ui/core/styles";
import { faColon} from '@fortawesome/free-solid-svg-icons';
import OptionPopUp from './OtherComponents/OptionPopUp';
import PopUpManualEntry from './OtherComponents/PopUpManualEntry';
import { circularProgressClasses } from '@mui/material';
import SideOptionPopup from './OtherComponents/SideOptionPopup,';
import TileRemove from '../TileRemove';

function SideCardCommon({title, iconpic,parameterValue, parameterUnit, alarmStatus,alarmStatusBorder, timeGap,FormTitle,tileBgColor,FormType,Option2, onClick}) {

   const [showPopupInfo, setShowPopupInfo] = useState(false);

  const handleMouseEnter = () => {
    setShowPopupInfo(true);
  };

  const handleMouseLeave = () => {
    setShowPopupInfo(false);
  };

  const useStyles = makeStyles(() => ({
    paper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  }));

    return (
      <div>
        <div className={"containerSquare-v2"+(' alarm-status-'+alarmStatusBorder)} style={{backgroundColor: tileBgColor || "white"}} >

          <div className="title-sideCard-v2">
            <div style={{ whiteSpace: "nowrap"}}>{title}</div>
            {/* <div><FontAwesomeIcon icon={faEllipsis} /></div> */}
            <div> <SideOptionPopup 
          option1={<PopUpManualEntry
          optionName="MANUAL ENTRY" 
          FormTitle={FormTitle} 
          FormType={FormType}/>} 
          option2={Option2}
          /></div>
          </div>
          <div onClick={onClick} style={{cursor:"pointer"}}>
          <div className="container2-v2" >
            <div className="iconRoundBackground-v2">
              <div className="iconePicture-v2">{iconpic}</div>
            </div>
            <div style={{ display: "flex",flex:"2", alignItems: "center", justifyContent: "space-between", padding:"2px" }}>
              <div> </div>
              <div className="valuecontainer-v2" > {parameterValue}  {parameterUnit}</div>
              <div style={{position:"absolute", bottom:"0", right:"0", fontSize:"0.75rem",cursor:"pointer"}}><FontAwesomeIcon onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} icon={faCircleInfo} color="#9068bd"/></div> 
              {showPopupInfo && (
                <div className="popupInfo" 
                style={{display:"inline-flex",
                flexDirection:"column",position:"absolute",
                backgroundColor:"#e6daf3", color:"black",
                border:"2px solid #7F55AE",padding:"10px",
                zIndex:"1", bottom:"20%", right:"0", 
                borderRadius:"7px",fontFamily:"'Montserrat', sans-serif", 
                whiteSpace:"nowrap"}}>

                  <div style={{display:"inline-flex",fontSize:"0.7rem",fontWeight:"500",fontFamily:"'Montserrat', sans-serif"}}>Status :<div style={{fontFamily:"'Montserrat', sans-serif",marginLeft:"5px",fontWeight:"600",fontSize:"0.7rem"}}> {alarmStatus}</div></div>
                  <div  style={{display:"inline-flex",fontSize:"0.7rem",fontWeight:"500",fontFamily:"'Montserrat', sans-serif"}}>Time :<div style={{fontFamily:"'Montserrat', sans-serif", marginLeft:"5px",fontWeight:"600",fontSize:"0.7rem"}}> {timeGap} ago </div></div>
                </div>
              )}
            </div>
          </div>
          </div>
        </div>
      </div>
    )
  }

export default SideCardCommon


import React from "react";
import OxyFloGraph from "./OxyFloGraph";
import OxyFloHeader from "./OxyFloHeader";
import OxyFloStatus from "./OxyFloStatus";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    padding: "12px 8px 12px 0",
    display: "flex",
    flexDirection: "column",
    flexGrow: "1",
    flexWrap: "wrap",
    height: "70vh",
    maxWidth: "100%",

    ["@media (max-width:1025px)"]: {
      flexDirection: "row",
      height: "auto",
      padding: "18px 0",
    },
  },
}));

function OxyFloTabPanel({ deviceStats }) {
  const classes = useStyles();

  return (
    <>
      <OxyFloHeader />
      <div
        className="flexitems-between flex-wrap-reverse height-md-auto"
        style={{ height: "75vh" }}
      >
        <OxyFloGraph />

        <div className="flex-md-100" style={{ display: "flex", width: "25%" }}>
          <div
            id="OxyFloStatus"
            style={{
              overflow: "hidden",
              display: "flex",
              flexGrow: "1",
              alignItems: "stretch",
            }}
          >
            <div className={classes.cardContainer}>
              {Object.keys(deviceStats).map((status, index) => (
                <OxyFloStatus
                  statusType={status}
                  statusValue={deviceStats[status]}
                  index={index}
                  key={index}
                  statsCount={deviceStats.length}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OxyFloTabPanel;

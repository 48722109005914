import React, { useEffect, useRef, useState } from "react";
import HomeDashboardv2 from "../HomeDashboardv2";
import "./DashboardCommon.css";
import HeartRatev2 from "../VitalCharts/HeartRatev2";
import HeartRatePopup from "../HRv2/HeartRatePopup";
import PopupModal from "../PopupModal";
import BPChartsv2 from "../VitalCharts/BPChartsv2";
import BPChartPopup from "../BPv2/BPChartPopup";
import BPv2 from "../VitalCharts/BPv2";
import BGChartPopup from "../BGv2/BGChartPopup";
import SleepQuick from "../VitalCharts/SleepQuick";
import { SleepPopup } from "../Sleepv2/SleepPopup";
import StepSpo2v2 from "../VitalCharts/StepSpo2v2";
import PillDisv2 from "../VitalCharts/PillDisv2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileContract,
  faFire,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { setVitalList } from "../../../../store/authSlice.js";
import {
  setDashboardData,
  setWebsocketStatus,
} from "../../../../store/dashboardSlice.js";
import constants from "../../../../Common/Constants.js";
import axios from "axios";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import {
  Avatar,
  Box,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { ArrowDropDownCircleOutlined } from "@material-ui/icons";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import resetReduxState from "../../../../Common/Logout.js";
// import LoaderCG from "../CareGiverPage/LoaderCG";
import { Button } from "@material-ui/core";
import UserData from "../OtherComponents/UserData";
import Tempv2 from "../VitalCharts/Tempv2";
import BRv2 from "../VitalCharts/BRv2";
import BRPopup from "../BRv2/BRPopup";
import Weightv2 from "../VitalCharts/Weightv2";
import Caloriev2 from "../VitalCharts/Caloriev2";
import FloatingAddButton from "../Featuresv2/FloatingAddButton";
import PopUpManualEntry from "../OtherComponents/PopUpManualEntry";
import FormBP from "../../../Member Details/Comps/FitbitFolder/FitCharts/FormBP";
import FormBG from "../../../Member Details/Comps/FitbitFolder/FitCharts/FormBG";
import FormSpO2 from "../../../Member Details/Comps/FitbitFolder/FitCharts/FormSpO2";
import FormHR from "../../../Member Details/Comps/FitbitFolder/FitCharts/FormHR";
import FormBR from "../../../Member Details/Comps/FitbitFolder/FitCharts/FormBR";
import FormTemp from "../../../Member Details/Comps/FitbitFolder/FitCharts/FormTemp";
import FormWT from "../../../Member Details/Comps/FitbitFolder/FitCharts/FormWT";
import FormStep from "../../../Member Details/Comps/FitbitFolder/FitCharts/FormStep";
import FormCalorie from "../../../Member Details/Comps/FitbitFolder/FitCharts/FormCalorie";
import FormSleep from "../../../Member Details/Comps/FitbitFolder/FitCharts/FormSleep";
import Ventilatorv2 from "../VitalCharts/Ventilatorv2";
import { SPOPopup } from "../SPOv2/SPOPopup";
import { StepPopup } from "../STPv2/StepPopup";
import { TempPopup } from "../Tempv2.js/TempPopup";
import { CaloriePopup } from "../Caloriev2.js/CaloriePopup";
import TMPPopup from "../TMPv2/TMPPopup";
import WeightPopup from "../Weightv2/WeightPopup";
import BMI from "../VitalCharts/BMI";
import BMIChartPopup from "../BMIv2/BMIChartPopup";
import MedicationReminderModal from "../Reminder";
import MedicationNewTile from "../VitalCharts/MedicationNewTile";
import FormSleepAdv from "../../../Member Details/Comps/FitbitFolder/FitCharts/FormSleepAdv";
import TimeSeriesExample from "../SampleUI/TimeSeriesExample";

const MyHealthComponent = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [popupComponent, setPopupComponent] = useState(null);
  const [medModal, setMedModal] = useState(false);
  const [medName, setMedName] = useState("");
  const [vitalData, setVitalData] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const HeartPage = () => {
    history.push("/heartratepage");
  };
  const StepsPage = () => {
    history.push("/stepspage");
  };
  const SlpPage = () => {
    history.push("/sleeppage");
  };
  const Spo2Page = () => {
    history.push("/spo2page");
  };
  const BrPage = () => {
    history.push("/brpage");
  };
  const BpPage = () => {
    history.push("/bppage");
  };
  const BgPage = () => {
    history.push("/bgpage");
  };
  const MedicationPage = () => {
    history.push(`/medication_page`, { urlStateData: urlStateData });
  };

  const { id, patient_group_id } = useParams();
  const alarms = useRef({});
  const headerGroupName = useSelector((state) => state.auth.headerGroupName);
  const dashboardApiStatus = useSelector((state) => state.dashboard.apiStatus);
  const groupName = useSelector((state) => state.auth.groupName);
  const token = useSelector((state) => state.auth.authToken);
  const deviceTypeList = useSelector((state) => state.home.deviceTypeList);

  const [careData, setCareData] = useState([]);
  const [isCGLoading, setIsCGLoading] = useState(false);
  const [retryCount, setRetryCount] = useState(0);

  const [test, setTest] = useState(1);
  const getLocalStoirageData = () => {
    let d = localStorage.getItem("test");
    if (!test) {
      return false;
    }
    try {
      d = JSON.parse(d);
      return d.token ? d : false;
    } catch (er) {
      return false;
    }
    return !1;
  };

  const careTakerToken = useSelector((state) => {
    let local = getLocalStoirageData();
    if (local) {
      return local.token;
    }
    return state.auth.careTakerToken;
  });

  const userData = useSelector((state) => state.auth.userData);
  const currentUserIndex = useSelector((state) => state.auth.currentUserIndex);

  const careTaker = useSelector((state) => {
    let local = getLocalStoirageData();
    if (local && local.token) {
      return false;
    }
    return state.auth.careTaker;
  });

  const urlStateData = location?.state;

  const careTakerPatientView = !!(careTaker && patient_group_id);

  // User details PopOver Button

  const isMobileViewButton = window.innerWidth <= 530;

  const openPopup = (component) => {
    setShowPopup(true);
    setPopupComponent(component);
  };

  const closePopup = () => {
    setShowPopup(false);
    setPopupComponent(null);
  };

  const [isDetailsPopoverOpen, setDetailsPopoverOpen] = useState(false);

  const popoverRef = useRef(null);

  const togglePopover = () => {
    setDetailsPopoverOpen(!isDetailsPopoverOpen);
  };

  const closePopover = () => {
    setDetailsPopoverOpen(false);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        closePopover();
      }
    };

    const handleNavigate = () => {
      closePopover();
    };

    document.addEventListener("mousedown", handleOutsideClick);
    window.addEventListener("beforeunload", handleNavigate);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
      window.removeEventListener("beforeunload", handleNavigate);
    };
  }, []);

  useEffect(() => {
    // if (dashboardApiStatus == "fetch") {

    const fetchData = async () => {
      let url = `${constants.API_URL}/dashboard_values/${
        careTakerPatientView ? patient_group_id : groupName
      }/${careTakerPatientView ? urlStateData?.careTakerToken : token}/`;

      const result = await axios.get(url);

      dispatch(setDashboardData(result.data.data));

      dispatch(setVitalList(result?.data?.data));
    };

    // {(!careTaker || careTakerPatientView) &&(
    fetchData();
    // )}

    
    const getDelay = (targetTime) => {
      let target = new Date(targetTime);
      let now = new Date();
      target.setSeconds(0);
      return target.getTime() - now.getTime();
    };
    
    const fetchMedData = async () => {
      const response = await fetch(
        `${constants.API_URL}/get_pd_alarms/${groupName}/${token}`
      );
      const data = await response.json();
      data?.alarms?.map((item) => {
        if (!alarms?.current?.hasOwnProperty(item?.id)  && getDelay(item.TS)>0) {
          alarms.current[item.id] = item;
          alarms.current[item.id].timer = setTimeout(() => {
            setMedModal(true)
            setMedName(item?.Med)
          }, getDelay(item?.TS));
        }
      });
    };
    fetchMedData();
    const invtlId = setInterval(fetchData, 61000);
    const intervalId = setInterval(fetchMedData, 5000);
    return () => {
      Object.keys(alarms.current).map((id) => {
        alarms.current[id].timer && clearInterval(alarms.current[id].time);
      });
      clearInterval(invtlId);
      clearInterval(intervalId);
    };

    // }
  }, [dashboardApiStatus, groupName, token, currentUserIndex, userData, test]);

  let data;

  const [errorMessage, setErrorMessage] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = (event) => {
    setAnchorEl(null);
    axios({
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      url: `${constants.API_URL}/logout/caretaker/${careTakerToken}/`,
      data: data,
    }).then(
      (response) => {
        history.push("/");
        setAnchorEl(anchorEl ? null : event.currentTarget);
        resetReduxState(dispatch);
        // closeCreatedSockets();
        setCareData([]);
      },
      (error) => {
        history.push("/");
        setErrorMessage(true);

        resetReduxState(dispatch);
        setCareData([]);
        // closeCreatedSockets();
      }
    );
  };

  const handleToAlarmSettings = () => {
    history.push("/alarm_management");
  };

  let retries = 0;

  return (
    <div>
      <div className="dashboardMainView-v2">
        <div className="left-panel-v2">
          <div className="left-card-main-grid">
            <HeartRatev2
              userID={groupName}
              onClick={() => openPopup(<HeartRatePopup />)}
            />
            {showPopup && (
              <PopupModal onClose={closePopup}>{popupComponent}</PopupModal>
            )}
            <BPChartsv2 onClick={() => openPopup(<BPChartPopup />)} />
            {showPopup && (
              <PopupModal onClose={closePopup}>{popupComponent}</PopupModal>
            )}
            <BPv2 onClick={() => openPopup(<BGChartPopup />)} />
            {showPopup && (
              <PopupModal onClose={closePopup}>{popupComponent}</PopupModal>
            )}
            <SleepQuick onClick={() => openPopup(<SleepPopup />)} />
            {showPopup && (
              <PopupModal onClose={closePopup}>{popupComponent}</PopupModal>
            )}
            <StepSpo2v2
              onSPOPopupClick={() => openPopup(<SPOPopup />)}
              onSTPPopupClick={() => openPopup(<StepPopup />)}
            />

            {showPopup && (
              <PopupModal onClose={closePopup}>{popupComponent}</PopupModal>
            )}

            {/* <PillDisv2 onClick={MedicationPage} /> */}
            <MedicationNewTile onClick={MedicationPage}/>
            
            {/* <TimeSeriesExample/> */}
          </div>
        </div>

        <div className="right-panel-v2">
          <div className="side-container-v2">
            {/* <div
              className="details-css"
              style={{
                margin: "0px 5px 5px 5px",
                padding: "5px 5px 5px 5px",
                fontFamily: "'Montserrat', sans-serif",
                fontSize: "1rem",
                color: "#75757C",
                fontWeight: "500",
              }}
            >
              DETAILS{" "} */}
              {/* <FontAwesomeIcon
                    icon={faPen}
                    style={{ fontSize: "0.7rem", justifyContent: "center" }}
                    color="#7F55AE"
                  /> */}
            {/* </div> */}
            {/* <div
              className="container-right-patient-details-v2"
              style={{ minHeight: "240px" }}
            >
              <UserData profileID={groupName} profileName={headerGroupName} />
            </div> */}
            <div
              className="patient-details-button-v2"
              style={{ width: "100%" }}
            >
              <div className="user-popover-container" ref={popoverRef}>
                <Button className="user-button" onClick={togglePopover}>
                  DETAILS
                </Button>
                {isDetailsPopoverOpen && (
                  <div className="popover-content">
                    <UserData
                      profileID={groupName}
                      profileName={headerGroupName}
                    />
                  </div>
                )}
                {isDetailsPopoverOpen && (
                  <div className="popover-overlay" onClick={closePopover}></div>
                )}
              </div>
            </div>

            <div className="conatainer-right-vitals-v2">
              <Tempv2 onClick={() => openPopup(<TMPPopup />)} />
              {showPopup && (
                <PopupModal onClose={closePopup}>{popupComponent}</PopupModal>
              )}

              <BRv2 onClick={() => openPopup(<BRPopup />)} />
              {showPopup && (
                <PopupModal onClose={closePopup}>{popupComponent}</PopupModal>
              )}
              <Weightv2 onClick={() => openPopup(<WeightPopup />)} />
              {showPopup && (
                <PopupModal onClose={closePopup}>{popupComponent}</PopupModal>
              )}

              <Caloriev2 onClick={() => openPopup(<CaloriePopup />)} />
              {showPopup && (
                <PopupModal onClose={closePopup}>{popupComponent}</PopupModal>
              )}

              <BMI onClick={() => openPopup(<BMIChartPopup />)} />
              {showPopup && (
                <PopupModal onClose={closePopup}>{popupComponent}</PopupModal>
              )}

            <div onClick={() => openPopup(<TimeSeriesExample/>)}>.</div>
            </div>
          </div>
        </div>
      </div>
      <FloatingAddButton
        option1={
          <PopUpManualEntry
            optionName="Blood Pressure"
            FormTitle="Blood Pressure"
            FormType={<FormBP />}
            careTakerPatientView={careTakerPatientView}
          />
        }
        option2={
          <PopUpManualEntry
            optionName="Blood Glucose"
            FormTitle="Blood Glucose"
            FormType={<FormBG careTakerPatientView={careTakerPatientView} />}
          />
        }
        option9={
          <PopUpManualEntry
            optionName="SpO2 "
            FormTitle="SpO2 "
            FormType={<FormSpO2 careTakerPatientView={careTakerPatientView} />}
          />
        }
        option3={
          <PopUpManualEntry
            optionName="Heart Rate"
            FormTitle="Heart Rate"
            FormType={<FormHR careTakerPatientView={careTakerPatientView} />}
          />
        }
        option4={
          <PopUpManualEntry
            optionName="Breath Rate"
            FormTitle="Breath Rate"
            FormType={<FormBR careTakerPatientView={careTakerPatientView} />}
          />
        }
        option5={
          <PopUpManualEntry
            optionName="Temperature"
            FormTitle="Temperature"
            FormType={<FormTemp careTakerPatientView={careTakerPatientView} />}
          />
        }
        option6={
          <PopUpManualEntry
            optionName="Weight"
            FormTitle="Weight"
            FormType={<FormWT careTakerPatientView={careTakerPatientView} />}
          />
        }
        option8={
          <PopUpManualEntry
            optionName="StepCount"
            FormTitle="Step"
            FormType={<FormStep careTakerPatientView={careTakerPatientView} />}
          />
        }
        option7={
          <PopUpManualEntry
            optionName="Calorie"
            FormTitle="Calorie"
            FormType={
              <FormCalorie careTakerPatientView={careTakerPatientView} />
            }
          />
        }
        option10={
          <PopUpManualEntry
            optionName="Sleep"
            FormTitle="Sleep"
            FormType={<FormSleepAdv />}
            careTakerPatientView={careTakerPatientView}
          />
        }
        option11={ 
          // <div onClick={()=> (history.push("medication_page"))}> Medication </div>
          <MenuItem style={{
            fontSize:"0.7rem", 
            fontWeight:"600", 
            fontFamily:"'Montserrat', sans-serif", 
            }}
            onClick={()=> (history.push("medication_page"))} >
              Medication
          </MenuItem>
        }
      />
      {medModal && (
        <MedicationReminderModal
          isOpen={medModal}
          onClose={() => setMedModal(false)}
          medicineName={medName}
        />
      )}
    </div>
  );
};

export default MyHealthComponent;

import React, { useEffect, useState } from "react";
import styles from "./Schedule.module.css";
import { useFormikContext, ErrorMessage } from "formik";

function MedicationForm2({ index, name, medicine }) {
  const namePrefix = `${name}.${index}`;

  const { values, handleChange, errors, handleBlur } = useFormikContext();

  const error = errors?.[name]?.[index];
  // console.log("errorMed", error);
  if(index===1)
    console.log("medicine night : ",medicine?.nightTime)

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    // Add leading zeros for single-digit months and days
    if (month < 10) {
      month = `0${month}`;
    }
    if (day < 10) {
      day = `0${day}`;
    }

    return `${year}-${month}-${day}`;
  };

  return (
    <div className={styles.formMainContainer}>
      <input
        placeholder="Medicine Name"
        value={medicine?.medicineName}
        onChange={handleChange}
        onBlur={handleBlur}
        name={`${namePrefix}.medicineName`}
        type="text"
        className={styles.medicineNameField}
        label="Medicine Name"
      />
      {/* <div className={styles.error}>{error?.medicineName}</div>
       */}

      <ErrorMessage
        name={name + "." + index + "." + "medicineName"}
        render={(msg) => {
          return <span className={styles.error}>{msg}</span>;
        }}
      />

      <div className={styles.conditionDiv}>
        <label className={styles.checkLabel}>
          <input
            type="radio"
            name={`${namePrefix}.category`}
            value="total_days"
            checked={medicine?.category === "total_days"}
            onBlur={handleBlur}
            onChange={handleChange}
            className={styles.check}
          />
          Enter by No of Days
        </label>

        <label className={styles.checkLabel}>
          <input
            type="radio"
            name={`${namePrefix}.category`}
            value="total_medicines"
            checked={medicine?.category === "total_medicines"}
            onBlur={handleBlur}
            onChange={handleChange}
            className={styles.check}
          />
          Enter by No of Medicines
        </label>

        <label className={styles.checkLabel}>
          <input
            type="radio"
            name={`${namePrefix}.category`}
            value="date_range"
            checked={medicine?.category === "date_range"}
            onBlur={handleBlur}
            onChange={handleChange}
            className={styles.check}
          />
          Enter by Date Range
        </label>
      </div>
      <ErrorMessage
        name={name + "." + index + "." + "category"}
        render={(msg) => {
          return <span className={styles.error}>{msg}</span>;
        }}
      />
      {medicine?.category === "total_days" && (
        <div>
          <input
            type="text"
            name={`${namePrefix}.numDays`}
            placeholder="Enter number of days"
            value={medicine?.numDays}
            onBlur={handleBlur}
            onChange={handleChange}
            className={styles.medicineNumberField}
          />
          <ErrorMessage
            name={name + "." + index + "." + "numDays"}
            render={(msg) => {
              return <span className={styles.error}>{msg}</span>;
            }}
          />
        </div>
      )}

      {medicine?.category === "total_medicines" && (
        <div>
          <input
            type="text"
            name={`${namePrefix}.numMed`}
            placeholder="Enter number of medicines"
            value={medicine?.numMed}
            onBlur={handleBlur}
            onChange={handleChange}
            className={styles.medicineNumberField}
          />
          <ErrorMessage
            name={name + "." + index + "." + "numMed"}
            render={(msg) => {
              return <span className={styles.error}>{msg}</span>;
            }}
          />
        </div>
      )}

      {medicine?.category === "date_range" && (
        <div className={styles.rangeDiv}>
          <div className={styles.dateDiv}>
            <label className={styles.rangeLabel}>From</label>
            <input
              type="date"
              name={`${namePrefix}.from_date`}
              value={medicine?.from_date}
              onBlur={handleBlur}
              onChange={handleChange}
              className={styles.rangeField}
              min={getCurrentDate()}
            />
            <ErrorMessage
              name={name + "." + index + "." + "from_date"}
              render={(msg) => {
                return <span className={styles.error}>{msg}</span>;
              }}
            />
          </div>
          <div className={styles.dateDiv}>
            <label className={styles.rangeLabel}>To</label>
            <input
              type="date"
              name={`${namePrefix}.end_date`}
              value={medicine?.end_date}
              onBlur={handleBlur}
              onChange={handleChange}
              className={styles.rangeField}
              min={getCurrentDate()}
            />
            <ErrorMessage
              name={name + "." + index + "." + "end_date"}
              render={(msg) => {
                return <span className={styles.error}>{msg}</span>;
              }}
            />
          </div>
        </div>
      )}

      <div className={styles.checkboxDiv}>
        <label style={{ marginTop: "10px" }} className={styles.checkLabel}>
          <input
            type="checkbox"
            name={`${namePrefix}.morningTime`}
            //value={medicine?.morningTime}
            checked={medicine?.morningTime}
            onChange={handleChange}
            className={styles.check}
          />
          Morning
        </label>

        {!!medicine?.morningTime && (
          <div
            style={{ marginTop: "10px", display: "flex", flexDirection: "row" }}
          >
            <label className={styles.checkLabel}>
              <input
                type="radio"
                name={`${namePrefix}.morning`}
                value="BF"
                checked={medicine?.morning === "BF"}
                onBlur={handleBlur}
                onChange={handleChange}
                className={styles.check}
              />
              Before Food
              <div className={styles.error}>{error?.morning}</div>
            </label>

            <label className={styles.checkLabel}>
              <input
                type="radio"
                name={`${namePrefix}.morning`}
                value="AF"
                checked={medicine?.morning === "AF"}
                onBlur={handleBlur}
                onChange={handleChange}
                className={styles.check}
              />
              After Food
            </label>
            <ErrorMessage
              name={name + "." + index + "." + "morning"}
              render={(msg) => {
                return <span className={styles.error}>{msg}</span>;
              }}
            />
          </div>
        )}
      </div>

      <div className={styles.checkboxDiv}>
        <label style={{ marginTop: "10px" }} className={styles.checkLabel}>
          <input
            type="checkbox"
            name={`${namePrefix}.noonTime`}
            //value={medicine?.noonTime}
            checked={medicine?.noonTime}
            onChange={handleChange}
            className={styles.check}
          />
          Noon
          {/* <div className={styles.error}>{error?.morning}</div> */}
        </label>

        {!!medicine?.noonTime && (
          <div
            style={{ marginTop: "10px", display: "flex", flexDirection: "row" }}
          >
            <label className={styles.checkLabel}>
              <input
                type="radio"
                name={`${namePrefix}.noon`}
                value="BF"
                checked={medicine?.noon === "BF"}
                onBlur={handleBlur}
                onChange={handleChange}
                className={styles.check}
              />
              Before Food
              <div className={styles.error}>{error?.noon}</div>
            </label>

            <label className={styles.checkLabel}>
              <input
                type="radio"
                name={`${namePrefix}.noon`}
                value="AF"
                checked={medicine?.noon === "AF"}
                onBlur={handleBlur}
                onChange={handleChange}
                className={styles.check}
              />
              After Food
              
            </label>
            <ErrorMessage
              name={name + "." + index + "." + "noon"}
              render={(msg) => {
                return <span className={styles.error}>{msg}</span>;
              }}
            />
          </div>
        )}
      </div>

      <div className={styles.checkboxDiv}>
        <label style={{ marginTop: "10px" }} className={styles.checkLabel}>
          <input
            type="checkbox"
            name={`${namePrefix}.nightTime`}
            checked={medicine?.nightTime}
           // value={medicine?.nightTime}
            onChange={handleChange}
            className={styles.check}
          />
          Night
        </label>

        {!!medicine?.nightTime && (
          <div
            style={{ marginTop: "10px", display: "flex", flexDirection: "row" }}
          >
            <label className={styles.checkLabel}>
              <input
                type="radio"
                name={`${namePrefix}.night`}
                value="BF"
                checked={medicine?.night === "BF"}
                onBlur={handleBlur}
                onChange={handleChange}
                className={styles.check}
              />
              Before Food
              <div className={styles.error}>{error?.night}</div>
            </label>

            <label className={styles.checkLabel}>
              <input
                type="radio"
                name={`${namePrefix}.night`}
                value="AF"
                checked={medicine?.night === "AF"}
                onBlur={handleBlur}
                onChange={handleChange}
                className={styles.check}
              />
              After Food
              
            </label>
            <ErrorMessage
              name={name + "." + index + "." + "night"}
              render={(msg) => {
                return <span className={styles.error}>{msg}</span>;
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default MedicationForm2;

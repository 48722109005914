import { createSlice } from "@reduxjs/toolkit";
const dashboardSlice = createSlice({
  name: "settings",
  initialState: {
    
    glucoseDaily: [],
    stepsDaily: { x: [], y: [] },
    breathDaily: [],
    tempDaily:[],
    spo2DailyMin: [],
    spo2DailyMax: [],
    spo2DailyAvg: [],
    bpDailySys: [],
    bpDailyDia: [],
    sleepDaily: [],
    heartRateDaily:[],
    calorieDaily:[],
    weightDaily:[],
    BMI:[],
    medicationDaily:[],
    distanceDaily: { x: [], y: [] },
    apiStatus: "fetch",
    websocketStatus:"fetch",
    analyticsRes:{}
  },

  reducers: {
    setDashboardData(state, action) {
      state.glucoseDaily = action.payload.BG;
      state.bpDailyDia = action.payload.BP;
      state.bpDailySys = action.payload.BP;
      state.sleepDaily = action.payload.sleep;
      state.spo2DailyAvg = action.payload.spo2;
      state.spo2DailyMax = action.payload.spo2;
      state.spo2DailyMin = action.payload.spo2;
      state.breathDaily = action.payload.br;
      state.tempDaily=action.payload.TMP;
      state.stepsDaily = action.payload.STP;
      state.distanceDaily = action.payload.DIS;
      state.heartRateDaily = action.payload.HR;
      state.calorieDaily = action.payload.CAL
      state.weightDaily = action.payload.WT
      state.BMI = action.payload
      state.medicationDaily = action.payload.pd_alarm
      state.apiStatus = "success";
    },
    clearDashboardSlice(state,action){
      state.glucoseDaily= [];
      state.stepsDaily= { x: [], y: [] };
      state.breathDaily = [];
      state.tempDaily=[];
      state.spo2DailyMin= [];
      state.spo2DailyMax =[];
      state.spo2DailyAvg= [];
      state.bpDailySys =  [];
      state.bpDailyDia= [];
      state.sleepDaily= [];
      state.heartRateDaily= [];
      state.calorieDaily = [];
      state.weightDaily = [];
      state.BMI = [];
      state.medicationDaily = [];
      state.distanceDaily= { x: [], y: [] };
      state.apiStatus='fetch';
      state.websocketStatus='fetch'
    },
    setDashboardApiStatus(state,action){
      state.apiStatus=action.payload
    },
    setWebsocketStatus(state,action){
      state.websocketStatus=action.payload
    },
    setAnalyticsRes(state,action){
      state.analyticsRes= action.payload
    }

  },
});

export const {
 setDashboardData,
 setDashboardApiStatus,
 clearDashboardSlice,
 setWebsocketStatus,
 setAnalyticsRes
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
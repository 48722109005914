import React from 'react'
import IconButton from '@mui/material/IconButton';
import { Add, MoreHoriz } from "@material-ui/icons";
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import { Fab } from '@mui/material';
import './FeatureCommon.css'


export default function FloatingAddButton(
    {
     option1,
     option2,
     option3,
     option4,
     option5,
     option6,
     option7,
     option8,
     option9,
     option10,
     option11
    }) 
    {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
  return (
   <React.Fragment>
     <Fab color="#7F55AE" aria-label="add" 
          aria-describedby={id} onClick={handleClick} style={{
                position: "fixed",
                bottom: 20,
                right: 20,
                color:"white", backgroundColor:"#7F55AE"
              }}>
        <Add />     
     </Fab>
     <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div className='cardDropdown-addbtn'>
        <div className='addbtn-heading'> Select a parameter </div>
        <div className='addcard-options'>{option1}</div>
        <div className='addcard-options'>{option2}</div>
        <div className='addcard-options'>{option3}</div>
        <div className='addcard-options'>{option4}</div>
        <div className='addcard-options'>{option5}</div>
        <div className='addcard-options'>{option6}</div>
        <div className='addcard-options'>{option7}</div>
        <div className='addcard-options'>{option8}</div>
        <div className='addcard-options'>{option9}</div>
        <div className='addcard-options'>{option10}</div>
        <div className='addcard-options'>{option11}</div>

        </div>
      </Popover>
   </React.Fragment>
  )
}


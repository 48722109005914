import React from "react";
import styles from './StepPopup.module.css'
import { useLocation, useParams } from 'react-router-dom';
import { useEffect } from "react";
import axios from 'axios';
import {useSelector } from "react-redux"
import constants from "../../../../Common/Constants";
import { format, endOfDay} from 'date-fns'
import {useState} from "react";
import { MoreHoriz } from "@material-ui/icons";
import { Snackbar } from "@mui/material";
import StepContainer from "./StepContainer";
import PopUpManualEntry from "../OtherComponents/PopUpManualEntry";
import FormStep from "../../../Member Details/Comps/FitbitFolder/FitCharts/FormStep";

export const StepPopup  = ({onClose}) => {
    const handleChange = (value) => {
        setTabValue(value);
      };
      const [showPopup, setShowPopup] = useState(false);

  const openPopup = () => {
    if (viewOnly?.viewOnly == true) {
      setShowPopup(false)
    } else {
      setShowPopup(true);
    }
  };

  const closePopup = (event) => {
    event.stopPropagation();
    setShowPopup(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [response, setResponse] = useState(null); 
  const [tabValue, setTabValue] = useState("week");
  const [dayData, setDayData] = useState({ xData: [], yData: [] });
  const [monthData, setMonthData] = useState({ xData: [], yData: [] });
  const [weekData, setWeekData] = useState({ xData: [], yData: [] });
  const [threeMonthData, setThreeMonthData] = useState({
    xData: [],
    yData: [],
  });
  const [yearData, setYearData] = useState({ xData: [], yData: [] });
  const [open, setOpen] = React.useState(false);
  const groupName = useSelector((state) => state.auth.groupName);
  const token = useSelector((state) => state.auth.authToken);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const careTakerToken = useSelector((state) => state.auth.careTakerToken);

  const { id, patient_group_id } = useParams();
  const location = useLocation();
  const urlStateData = location?.state;

  const viewOnly = location?.state;
  const {pat_id} = useParams();

  const dateEnd = format(endOfDay(new Date()), "yyyy-MM-dd");
  const dateHR = format(endOfDay(new Date()), "ddMMyyy");

  const refreshChartData = () => {

    if(tabValue === "day" ){
      setTimeout(() => {
      axios
      .get(`${constants.API_URL}/day_data/STP/${viewOnly?.viewOnly? pat_id : urlStateData?.patientView?patient_group_id:groupName}/${dateHR}/${token}/`)
      .then(res => { 
      
          let x= []
          let y = []
          res.data.day_data?.forEach(data => {
            x.push(data.x_data)
            y.push(data.y_data)
          });
  
          
          setDayData({xData:x,yData:y})
        
        })
      },3000)

    }
    else if(tabValue === "week" ){
      setTimeout(() => {
      axios
      .get(`${constants.API_URL}/week_data/STP/${viewOnly?.viewOnly? pat_id : urlStateData?.patientView?patient_group_id:groupName}/${dateHR}/${token}/`)
      .then(res => { 
      
          let x= []
          let y = []
          res.data.week_data?.forEach(data => {
            x.push(data.x_data)
            y.push(data.y_data)
          });
  
          
          setWeekData({xData:x,yData:y})
        
        })
      },3000)

    }
    else if(tabValue === "month" ){
      setTimeout(() => {
      axios
      .get(`${constants.API_URL}/month_data/STP/${viewOnly?.viewOnly? pat_id : urlStateData?.patientView?patient_group_id:groupName}/${dateHR}/${token}/`)
      .then(res => { 
        
          let x= []
          let y = []
          res.data.month_data?.forEach(data => {
            x.push(data.x_data)
            y.push(data.y_data)
          });
    

          setMonthData({xData:x,yData:y})
      
      })
    },3000)
    }
    else if(tabValue === "threeMonth" ){
      setTimeout(() => {
        axios
      .get(`${constants.API_URL}/three_month_data/STP/${viewOnly?.viewOnly? pat_id : urlStateData?.patientView?patient_group_id:groupName}/${dateHR}/${token}/`)
      .then(res => {
    
        let x= []
        let y = []
        res.data.three_month_data?.forEach(data => {
          x.push(data.x_data)
          y.push(data.y_data)
        });
    

        setThreeMonthData({xData:x,yData:y})
      
      })
    },3000)

    }

  }
  useEffect(() =>{
    if(tabValue === "day" &&!(dayData?.xData?.length)){
      axios
      .get(`${constants.API_URL}/day_data/STP/${viewOnly?.viewOnly? pat_id : urlStateData?.patientView?patient_group_id:groupName}/${dateHR}/${token}/`)
      .then(res => { 
      
          let x= []
          let y = []
          res.data.day_data?.forEach(data => {
            x.push(data.x_data)
            y.push(data.y_data)
          });
  
          
          setDayData({xData:x,yData:y})
        
        })

    }
    else if(tabValue === "week" &&!(weekData?.xData?.length)){
      axios
      .get(`${constants.API_URL}/week_data/STP/${viewOnly?.viewOnly? pat_id : urlStateData?.patientView?patient_group_id:groupName}/${dateHR}/${token}/`)
      .then(res => { 
      
          let x= []
          let y = []
          res.data.week_data?.forEach(data => {
            x.push(data.x_data)
            y.push(data.y_data)
          });
  
          
          setWeekData({xData:x,yData:y})
        
        })

    }
    else if(tabValue === "month" &&!(monthData?.xData?.length)){
      axios
      .get(`${constants.API_URL}/month_data/STP/${viewOnly?.viewOnly? pat_id : urlStateData?.patientView?patient_group_id:groupName}/${dateHR}/${token}/`)
      .then(res => { 
        
          let x= []
          let y = []
          res.data.month_data?.forEach(data => {
            x.push(data.x_data)
            y.push(data.y_data)
          });
    

          setMonthData({xData:x,yData:y})
      
      })
    }
    else if(tabValue === "threeMonth" &&!(threeMonthData?.xData?.length)){
        axios
      .get(`${constants.API_URL}/three_month_data/STP/${viewOnly?.viewOnly? pat_id : urlStateData?.patientView?patient_group_id:groupName}/${dateHR}/${token}/`)
      .then(res => {
    
        let x= []
        let y = []
        res.data.three_month_data?.forEach(data => {
          x.push(data.x_data)
          y.push(data.y_data)
        });
    

        setThreeMonthData({xData:x,yData:y})
      
      })

    }


  },[tabValue])

  const deleteLastEntry = () => {
    axios.get(`${constants.API_URL}/delete_latest_manual_entry/${viewOnly?.viewOnly? pat_id : urlStateData?.patientView?patient_group_id:groupName}/STP/${token}/`).then((res) => {
      if (res.data.message === "success") {
        setResponse('success');
        setOpen(true);
        // Update the dayData state to reflect the changes
        const updatedDayData = { ...dayData };
        updatedDayData.xData.pop(); // Remove the last entry from xData
        updatedDayData.yData.pop(); // Remove the last entry from yData
        setWeekData(updatedDayData);
        // Update the weekData state to reflect the changes
        const updatedWeekData = { ...weekData };
        updatedWeekData.xData.pop(); // Remove the last entry from xData
        updatedWeekData.yData.pop(); // Remove the last entry from yData
        setWeekData(updatedWeekData);

           // Update the weekData state to reflect the changes
           const updatedMonthData = { ...monthData };
           updatedMonthData.xData.pop(); // Remove the last entry from xData
           updatedMonthData.yData.pop(); // Remove the last entry from yData
           setMonthData(updatedMonthData);

             // Update the weekData state to reflect the changes
         const updatedThreeMonthData = { ...threeMonthData };
         updatedThreeMonthData.xData.pop(); // Remove the last entry from xData
         updatedThreeMonthData.yData.pop(); // Remove the last entry from yData
         setThreeMonthData(updatedThreeMonthData);

      } else if (res.data.message === "No data found") {
        setResponse('noData');
        setOpen(true);
      } else {
        setResponse('error');
        setOpen(true);
      }
   
    })
    .catch((error) => {
      setResponse('error');
      setOpen(true);
    
    });
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.heading}>
        <div>Step</div>
        <div style={{ color: "grey", cursor: "pointer" }} >
          <MoreHoriz onClick={openPopup}/>
        </div>
      </div>
      <div className={styles.contentDiv}>
        <div className={styles.tabContainer}>
          <div
            className={`${styles.tab} ${
              tabValue === "day" && styles.activeTab
            }`}
            onClick={() => handleChange("day")}
          >
            DAY
          </div>
          <div
            className={`${styles.tab} ${
              tabValue === "week" && styles.activeTab
            }`}
            onClick={() => handleChange("week")}
          >
            WEEK
          </div>
          <div
            className={`${styles.tab} ${
              tabValue === "month" && styles.activeTab
            }`}
            onClick={() => handleChange("month")}
          >
            MONTH
          </div>
          <div
            className={`${styles.tab} ${
              tabValue === "threeMonth" && styles.activeTab
            }`}
            onClick={() => handleChange("threeMonth")}
          >
            3 MONTHS
          </div>
        </div>
        <div className={styles.tabContent}>
          {tabValue === "day" && (
            <StepContainer
              title="Daily Report"
              xData={dayData?.xData}
              yData={dayData?.yData}
              type="day"
            />
          )}
          {tabValue === "week" && (
            <StepContainer
              title="Weekly Report"
              xData={weekData?.xData}
              yData={weekData?.yData}
              type="week"
            />
          )}
          {tabValue === "month" && (
            <StepContainer
              title="Monthly Report"
              xData={monthData?.xData}
              yData={monthData?.yData}
              type="month"
            />
          )}
          {tabValue === "threeMonth" && (
            <StepContainer
              title="3 Months Report"
              xData={threeMonthData?.xData}
              yData={threeMonthData?.yData}
              type="threeMonth"
            />
          )}
        </div>
      </div>
      {showPopup && (
        <div className={styles.popup}>
          <div className={styles.popupOption1}>
            <PopUpManualEntry
              optionName={"Manual Entry"}
              FormTitle={"Step Count"}
              FormType={<FormStep refreshChartData={refreshChartData} style={{ zIndex: "9999" }} />}
            />
          </div>
          <div className={styles.popupOption} onClick={deleteLastEntry}>
            Delete Last Entry
          </div>
          <div className={styles.popupOption} onClick={(event) => closePopup(event)}>
            Cancel
          </div>
        </div>
      )}
     <Snackbar
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
      message={
        response === "success" ? "Successfully deleted the last entry!" : "No recent data found!"
      }
    />
    </div>
  );
};


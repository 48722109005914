import React from "react";
import { useState } from "react";
import SettingsDashboard from "./SettingsDashboard";
import SettingsDrawer from "./SettingsDrawer";
import SettingsToolBar from "./SettingsToolBar";

function VitalSettings({ closeCreatedSockets }) {
  const [activeTab, setActiveTab] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState(true);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
    document
      .getElementsByClassName("settings-page-container")[0]
      .classList.add("SettingsDrawerOpened");
    document
      .getElementsByClassName("settings-page-container")[0]
      .classList.remove("SettingsDrawerClosed");
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    document
      .getElementsByClassName("settings-page-container")[0]
      .classList.add("SettingsDrawerClosed");
    document
      .getElementsByClassName("settings-page-container")[0]
      .classList.remove("SettingsDrawerOpened");
  };

  const handleTabChange = (event, newActiveTab) => {
    setActiveTab(newActiveTab);
  };

  return (
    <div className="App">
      <div className="settings-page-container">
        <SettingsDrawer
          activeTab={activeTab}
          drawerOpen={drawerOpen}
          handleTabChange={handleTabChange}
          handleDrawerClose={handleDrawerClose}
        />

        <SettingsToolBar
          drawerOpen={drawerOpen}
          handleDrawerOpen={handleDrawerOpen}
          closeCreatedSockets={closeCreatedSockets}
        />

        <SettingsDashboard activeTab={activeTab} drawerOpen={drawerOpen} />
      </div>
    </div>
  );
}

export default VitalSettings;

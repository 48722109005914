import axios from "axios";
import React, { useEffect } from "react";
import constants from "../../../../../Common/Constants";
import { useSelector } from "react-redux";
import "./FNewDash.css";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import fitbitimageBG from './fitbitlogo.png';

export const FitbitLoginPage = () => {

  const history = useHistory();

  const homePage = () => {
    history.push("/home");
  };

  const [count, setCount] = useState(6);

  const groupName = useSelector((state) => state.auth.groupName);

  const token = useSelector((state) => state.auth.authToken);

  let fitbitUrl = document.URL;

  let paramString = fitbitUrl.split("#")[1];

  let queryString = new URLSearchParams(paramString);

  let accessToken = queryString.get("access_token");

  let userId = queryString.get("user_id");

  let fScope = queryString.get("scope");

  let tokenType = queryString.get("token_type");

  let expiry = queryString.get("expires_in");

  let data = {
    group_id: groupName,
    fitbit_user_id: userId,
    access_token: accessToken,
    scope: fScope,
    token_type: tokenType,
    expiry: expiry,
    device_id: sessionStorage.getItem("device_id") || ""
  };

  axios({
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    url: `${constants.API_URL}/authenticate_fitbit/${token}/`,
    data: data,
  });

  useEffect(() => {
    const countdown = setTimeout(() => {
      if (count > 0) {
        setCount(count - 1);
      } else {

        history.push('/home');
      }
    }, 1000);

    return () => {
      clearTimeout(countdown);
    };
  }, [count, history]);

  const [animationVisible, setAnimationVisible] = useState(true);

  const restartAnimation = () => {
    setAnimationVisible(false);
    setTimeout(() => {
      setAnimationVisible(true);
    }, 500);
  };
  useEffect(() => {
    restartAnimation();
  }, [500])

  return (
    <div style={{ height: "100vh" }}>
      <div className="mainContainerFitAuth">
        <div className="authsuccessPopUP">
          <img className="fitbitlogo" src={fitbitimageBG} />
          <div className="textMessageFitAuth">
            <div className="zoom-text">Fitbit authentication successful</div>

            <div className="success-checkmark">
              {animationVisible && (
                <div className="check-icon">
                  <span className="icon-line line-tip"></span>
                  <span className="icon-line line-long"></span>
                  <div className="icon-circle"></div>
                  <div className="icon-fix"></div>
                </div>
              )}
            </div>

            <div style={{ textAlign: 'center' }}>
              <p className="redirectMessageFitAuth">Redirecting to the home page in <div style={{fontWeight:"600", fontSize:"0.8rem"}}>{count}</div></p>
            </div>

          </div>
        </div>
      </div>
    </div>

  );
};
import React,{memo} from 'react'

import ReactEcharts from 'echarts-for-react';
import '../FNewDash.css'


function BGChartPage2({title,xData,yData,handleClick}) {
   
  

  const y1Padding = React.useMemo(()=>{
    if(yData && yData.toString().length===2){
    return [0, 0, -60, -56]
    }
 else
    return [0, 0, -65, -69]
    
 },[yData])

  const chartData=
  React.useMemo(()=>{
    return {
        title: {
          left: 'center',
         
          
        },
        tooltip:{},
     
       
        
        series: [
         
          {
            
            color:"#6D3DA3",
            type: 'gauge',
            guageCategoryGap: "20%",
            startAngle: 360,
            endAngle: 0,
            min: false,
            max: 1,
            splitNumber: 12,
            itemStyle: {
        
              shadowColor: 'blue',
              shadowBlur: 0,
              shadowOffsetX: 0,
              shadowOffsetY: 0
            },
            textStyle: {
              fontFamily: "sans-serif"
            },
            progress: {
              show: true,
              roundCap: true,
              width: 10,
              
            },
            pointer: {
              icon: false,
              length: '0%',
              width: 0,
              offsetCenter: [0, '0%']
            },
            axisLine: {
              roundCap: false,
              show:false,
              lineStyle: {
                width: -10,
              }
            },
            axisTick: {
              splitNumber: false,
              color: 'white',
            
            },
            axisLabel: {
              distance: 30,
              color: 'white',
              fontSize: 0
            },
            title: {
              show: false
            },
            detail: {
              
              lineHeight: 0,
              height: 0,
              borderRadius: 8,
              offsetCenter: [-4, '0%'],
              valueAnimation: true,
              formatter: function (value) {
                return '{value|' + value.toFixed(0) + '}{unit|mg/dL}';
              },
              rich: {
                value: {
                  fontSize: 50,
                  fontWeight: 'bolder',
                  color: '#777',
                  fontFamily:"sans-serif"
                  
                },
                unit: {
                  fontSize: 20,
                  color: '#999',
                  padding: y1Padding,
                //  textAlign:"center",
                    fontFamily: "sans-serif"
                  
                }
              }
            },
            data: [
              {
                value: yData,
                
              }
            ]
          },
        ],
        
      }
    },[xData,yData])


  

  return (
    <div>
    {chartData.series[0].data.length?
  <div className='bpchart1'><div className='nameBP'  style={{fontFamily: "sans-serif"}}>{title}</div>
    
  <div className='BG-value'><ReactEcharts option={chartData} onEvents={{
      'click': handleClick,
    }}/> </div>
 
 
  </div>
   : <p style={{fontSize:"20px", fontWeight:"bold"}}>NO DATA AVAILABLE </p>
   }
  </div>
  )
}

export default memo(BGChartPage2)
import * as React from "react";
import { Button, styled } from "@material-ui/core";

//Common Button Styles

const ColorButton = styled(Button)(({ theme }) => ({
  color: "white",
  backgroundColor: "#40bebb",
  "&:hover": {
    backgroundColor: "#339895",
  },
}));

export default function AquaPrimaryButton(props) {
  return (
    <ColorButton {...props} fullWidth variant="contained">
      {props.buttonlabel}
    </ColorButton>
  );
}

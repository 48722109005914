import React, { useState } from "react";
import { Grid, Paper, Button, Typography } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import constants from "../../../../../Common/Constants";
import { setPassword } from "../../../../../store/authSlice";
import axios from "axios";
import { useParams, useHistory,useLocation } from "react-router-dom";
import {
  setDashboardData,
  setWebsocketStatus,
} from "../../../../../store/dashboardSlice";
import { PopMsg } from "./PopMsg";
import { Alert } from '@material-ui/lab';
import { Snackbar } from '@material-ui/core';



function FormTemp({handleClose, careTakerPatientView=false,refreshChartData }) {

  const dispatch = useDispatch();
  const [showPopup, setShowPopup] = useState(false);
  const careTakerToken=useSelector((state) => state.auth.careTakerToken);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarColor, setSnackbarColor] = useState('');

  const token = useSelector((state) => state.auth.authToken);
  const groupName = useSelector((state) => state.auth.groupName);
  const paperStyle = { padding: "0 5px 50px 5px", width: 270 };
  const btnStyle = { marginTop: 10, fontFamily:"'Montserrat', sans-serif"  };
  const SysDiaValue = /^[0-9]{2,3}/;
  const initialValues = {
    TMP: "",
    // Date: "",
    // Time: "",
    datetime: "",

  };
  const userData = useSelector((state) => state.auth.userData);

  const { id,patient_group_id } = useParams();

    const location = useLocation();
    const urlStateData = location?.state

  const validationSchema = Yup.object().shape({
    TMP: Yup.number().min(5).max(140).required("Required"),
    // Date: Yup.date().required("Required"),
    // Time: Yup.string().required("Required"),
    datetime: Yup.date()
      .max(new Date(), "Date and time must be in the past")
      .required("Please enter a date and time"),
    
  });

  const fetchDataApi = async () => {
    let url = `${constants.API_URL}/dashboard_values/${urlStateData?patient_group_id:groupName}/${token}/`;

    // url += `${token}/`;
    console.log(careTakerPatientView)
    const result = await axios.get(url);

    dispatch(setDashboardData(result.data.data));
    };

  const onSubmit = (values, props) => {
    props.resetForm();

    var dataTMP = values.TMP;
    let TMPCel = (dataTMP - 32) * (5 / 9);

    // const dateValue = values.Date;
    // var temp = new Date(dateValue);
    // temp.setDate(temp.getDate() - 1);

    // const timeValue = values.Time;
    const datetimevalue = values.datetime

    // var timestampData = dateValue + " " + timeValue;
    var epochTime = Date.parse(datetimevalue);

    // var utcTime = epochTime;

    var formData = JSON.stringify({ TMP: parseInt(TMPCel), TS: epochTime });




    axios({
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      url: `${constants.API_URL}/manual_entry/${urlStateData?patient_group_id:groupName}/${token}/`,
      data: formData,
    }).then((res) => {
       if (res.status===200) 
      setShowPopup(true);
      setSnackbarOpen(true);
      setSnackbarColor('success');
      setSnackbarMessage('Manual Entry Successful');
    
      setTimeout(() => {
         // show pop up message
         setShowPopup(false);
         handleClose() ;
         refreshChartData();

       }, 2000);
       clearTimeout();
       

       setTimeout(() => {
         // show pop up message
        fetchDataApi();
     console.log("api refreshing for manual entry")
       }, 3000);
       clearTimeout();
     
    }).catch((error) => {
      console.log(error?.response);
      if(error?.response?.status == 400){
       setShowPopup(true);
       setSnackbarOpen(true);
       setSnackbarColor('error');
       setSnackbarMessage(error?.response?.data?.message);
      }
      else{
       setShowPopup(true);
       setSnackbarOpen(true);
       setSnackbarColor('error');
       setSnackbarMessage("Something went wrong");
      }
   });
  };

  const vertical = "bottom"; 
  const horizontal = "center"; 
 



  return (
    <div>
    {showPopup &&(
      <PopMsg/>
    )}

    <Grid>
      <Paper elevation={0} style={paperStyle}>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {(props) => (
            <Form noValidate>

              <div style={{marginBottom:"10px"}}>
                    <Field as={TextField} name='TMP' label='Temperature' fullWidth
                        error={props.errors.TMP && props.touched.TMP} autoFocus
                        required />
                        <div style={{color:"Red"}}>
                        <ErrorMessage name='TMP' />
                        </div>
              </div>
              
              <div style={{marginBottom:"10px"}}>  
                    <Field type="datetime-local" name="datetime" label="datetime" fullWidth
                        error={props.errors.datetime && props.touched.datetime}
                        // helperText={props.errors.Date && props.touched.Date ? props.errors.Date : null}
                         required />
                        <div style={{color:"Red"}}>
                         <ErrorMessage name='datetime' />
                        </div>
                </div>

              {/* <Field
                type="date"
                name="Date"
                label="date"
                fullWidth
                error={props.errors.Date && props.touched.Date}
                helperText={<ErrorMessage name="date" />}
                required
              />

              <Field
                type="time"
                name="Time"
                label="Time"
                fullWidth
                error={props.errors.Time && props.touched.Time}
                helperText={<ErrorMessage name="time" />}
                required
              /> */}

              <Button
                type="submit"
                style={btnStyle}
                variant="contained"
                color="primary"
              >
                Submit Data
              </Button>
            </Form>
          )}
        </Formik>
      </Paper>
    </Grid>
    <Snackbar
        anchorOrigin={{ vertical, horizontal }}
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={() => setSnackbarOpen(false)}
          message={snackbarMessage}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={snackbarColor} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
  </div>  
  );
}

export default FormTemp;
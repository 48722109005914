import React from 'react'
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import CloseButton from 'react-bootstrap/CloseButton';
import { MenuItem } from '@mui/material';

export default function PopUpManualEntry({FormTitle, FormType,optionName}) {

    const [open, setOpen] = React.useState(false);
  
    const handleClickOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };

  return (
    <div>
    <MenuItem style={{
      fontSize:"0.7rem", 
      fontWeight:"600", 
      fontFamily:"'Montserrat', sans-serif", 
      }}
    onClick={handleClickOpen}>
      {optionName}
    </MenuItem>
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      style={{zIndex:"99999"}}
    >
      <div style={{display:'flex', justifyContent: 'right'}}>
          <CloseButton onClick={handleClose} />
          </div>

      <DialogTitle id="customized-dialog-title" onClose={handleClose} textAlign="center" 
      style={{
        fontSize:"1.2rem", 
        fontWeight:"600", 
        fontFamily:"'Montserrat', sans-serif", 
        }} >
        {FormTitle}
      </DialogTitle>
      <DialogContent dividers>{React.cloneElement(FormType, { handleClose: handleClose })}
      </DialogContent>

    </Dialog>
  </div>
  )
}

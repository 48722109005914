import React from 'react'
// import Popup from 'reactjs-popup';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
// import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import FormBG from './FormBG';
import CloseButton from 'react-bootstrap/CloseButton';
import { MoreHoriz } from "@material-ui/icons";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));


export default function PopupBG({FormTitle, FormType,optionName}) {
  const [open, setOpen] = React.useState(false);
  
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  

  return (
    <div>
      <Button style={{height:15}} color='primary'
      onClick={handleClickOpen}>
        {optionName}
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <div style={{display:'flex', justifyContent: 'right'}}>
            <CloseButton onClick={handleClose} />
            </div>

        <DialogTitle id="customized-dialog-title" onClose={handleClose} textAlign="center" >
          {FormTitle}
        </DialogTitle>
        <DialogContent dividers>{React.cloneElement(FormType, { handleClose: handleClose })}
        </DialogContent>

      </Dialog>
    </div>
  );
}


import React from 'react'
import "./LoaderCG.css";

export default function LoaderCG() {
    return (
        <>
        <div className="loaderscreen" >
          <div className="spinner1 ">
          </div> 
             
        </div>
          
        </>
        
        
      );
    }
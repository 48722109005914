import React, { memo } from "react";
import ReactEcharts from "echarts-for-react";
import CircularProgress from "@mui/material/CircularProgress";
import * as echarts from "echarts";
import styles from './HeartRatePopup.module.css';

function HeartRateLineChart({ title, xData, yData, handleClick }) {
  const chartData = React.useMemo(() => {
    return {
      tooltip: {
        textStyle: {
          fontFamily: "sans-serif",
        },
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
    
      dataZoom: [
        {
          type: 'inside',
          start: 0,
          end: 100,  
          fillerColor: 'red',
        },
        
        {
          start: 10,
          end: 0
        }
      ],

      grid: { // Add grid property to set the margin
        top: "8%", // Adjust the bottom margin as needed
        containLabel: true, // Include axis labels and title inside the grid
      },

      xAxis: {
        type: "category",

        data: xData,
        axisLabel: {
          fontFamily: "sans-serif",
        },
      },
      splitLine: {
        show: true, // Show the grid lines
      },
      yAxis: {
        splitLine: {
          show: false,
        },
        type: "value",
        axisLabel: {
          fontFamily: "sans-serif",
        },
      },

      series: [
        {
          name: "beats/min",
          type: "bar",
          smooth: true,
          areaStyle: {
            opacity: 0.3,
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 1,
                color: "white",
              },
              {
                offset: 0,
                color: "#FA7166",
              },
            ]),
          },

          color: "#FA7166",

          data: yData,
        },
      ],
    };
  }, [xData, yData, title]);

  return (
    <div style={{ height: "100%" }}>
      {chartData?.series?.[0]?.data?.length ? (
        <div className="left-chart">
          <ReactEcharts
            option={chartData}
            onEvents={{
              click: handleClick,
            }}
            style={{ width: "fitContent" }}
          />
        </div>
      ) : (
        <p className={styles.noDataStyles}>
         {/* <CircularProgress /> */}
         No Data
        </p>
      )}
    </div>
  );
}
export default memo(HeartRateLineChart);

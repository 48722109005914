import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './index.css';
import App from './App';
import store from './store/store';
import {persistStore} from 'redux-persist';
import 'bootstrap/dist/css/bootstrap.min.css';

function AppComponent(){
  const [isReady, setIsReady] = useState(false);
  useEffect(() => {
    persistStore(
      store,
      {
        // storage: AsyncStorage,
        whitelist: ['auth']
      },
      () => {
        setTimeout(() => {
          setIsReady(true);
        }, 1000);
      }
    )
  }, [])

  return(
    <React.StrictMode>
      {isReady && <Provider store={store}>
        <App />
      </Provider>}
    </React.StrictMode>
  );
}

ReactDOM.render(
  <AppComponent />,
  document.getElementById('root')
);

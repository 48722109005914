import React from "react";
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer } from "recharts";
import "./LineGraph.css";


const LineGraph = (props) => {
  return (
    <div className="gridDashboard_ChartContainer">
      <div className="gridDashboard_ChartPanel">
        <div className="ChartPanel_LeftLabelDiv">
          <span className="ChartPanel_LeftLabel">{props.graphLabels[0]}</span>
        </div>
        <div className="ChartPanel_ChartDiv">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              data={props.data}
              syncId="anyId"
              margin={{
                top: 5,
                right: 5,
                left: -30,
                bottom: 5,
              }}
            >
              <XAxis hide={true} />
              <YAxis tick={{ fontSize: "0.6rem" }} />

              <Line
                type="monotone"
                fontSize="xx-small"
                isAnimationActive={false}
                dot={false}
                dataKey="uv"
                strokeWidth="2"
                stroke="#ffcf1d"
                fill="#8884d8"
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
      <div className="gridDashboard_ChartPanel">
        <div className="ChartPanel_LeftLabelDiv">
          <span className="ChartPanel_LeftLabel">{props.graphLabels[1]}</span>
        </div>
        <div className="ChartPanel_ChartDiv">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              data={props.data}
              syncId="anyId"
              margin={{
                top: 5,
                right: 5,
                left: -30,
                bottom: 5,
              }}
            >
              {/* <CartesianGrid strokeDasharray="3 3" /> */}
              <XAxis hide={true} />
              <YAxis tick={{ fontSize: "0.6rem" }} />
              <Line
                type="monotone"
                isAnimationActive={false}
                dot={false}
                dataKey="pv"
                strokeWidth="2"
                stroke="#48d342"
                fill="#8884d8"
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
      <div className="gridDashboard_ChartPanel">
        <div className="ChartPanel_LeftLabelDiv">
          <span className="ChartPanel_LeftLabel">{props.graphLabels[2]}</span>
        </div>
        <div className="ChartPanel_ChartDiv">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              data={props.data}
              syncId="anyId"
              margin={{
                top: 5,
                right: 5,
                left: -30,
                bottom: 5,
              }}
            >
              <XAxis hide={true} />
              <YAxis tick={{ fontSize: "0.6rem" }} />
              <Line
                type="monotone"
                isAnimationActive={false}
                dot={false}
                dataKey="amt"
                strokeWidth="2"
                stroke="#40bebb"
                fill="#8884d8"
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};
export default React.memo(LineGraph);

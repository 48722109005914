import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faWeightScale} from '@fortawesome/free-solid-svg-icons';
import SideCardCommon from '../SideCardCommon';
import { useSelector } from 'react-redux';
import FormWT from '../../../Member Details/Comps/FitbitFolder/FitCharts/FormWT';
import TileRemove from '../Featuresv2/TileRemove';
import { useState } from 'react';
import { useEffect } from 'react';

function Weightv2({onClick}) {

  const [isExpired, setIsExpired] = useState(false);

  const weightDaily = useSelector((state) => state.dashboard.weightDaily);

  const WTDaily = weightDaily?.data?.y_data?.WT


  const timeGapIndicationWT = weightDaily?.data?.x_data?.time_gap
  // console.log(timeGapIndicationWT)

  useEffect(() => {
    if (timeGapIndicationWT?.includes("day") || timeGapIndicationWT?.includes("days")) {
      setIsExpired(true);
    }
    else{
      setIsExpired(false);
    }
  }, [timeGapIndicationWT, weightDaily]);

  if (!WTDaily){
    return null;
  }
  
  return (
    <SideCardCommon title="Weight" 
    onClick={onClick}
    tileBgColor={isExpired? "#F9F9F9" : "white"}
    iconpic={<FontAwesomeIcon icon={faWeightScale} color="#7F55AE"/>}
    parameterValue={WTDaily? WTDaily:""}
    parameterUnit="kg"
    alarmStatus={"-"}
    timeGap={weightDaily?.data?.x_data?.time_gap}
    FormTitle={"Weight"}
    FormType={<FormWT/>}
    Option2={<TileRemove option2title={"REMOVE TILE"} parameter={"WT"}/>}
    >
        
    </SideCardCommon>
  )
}

export default Weightv2
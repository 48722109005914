import React, { useState } from 'react';
import styles from './AlarmNew.module.css'
import { InputNumber } from 'antd';
import 'antd/dist/reset.css';
import {ThemeProvider, createTheme, Slider, Backdrop, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import '@material-ui/core';
import { ArrowBackIos, Cancel } from '@mui/icons-material';
import { Button, Dialog } from '@mui/material';
import { useSelector } from 'react-redux';
import constants from '../../../../Common/Constants';
import axios from 'axios';
import { useEffect } from 'react';
import { useHistory, useParams , useLocation} from 'react-router-dom/cjs/react-router-dom';
import { Alert } from '@material-ui/lab';
import { Snackbar } from '@material-ui/core';
import HomeIcon from '@mui/icons-material/Home';
import Person2Icon from '@mui/icons-material/Person2';
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import {
  Popover,
  Radio,
  RadioGroup,
  FormControlLabel,
  Typography,
  Box,
} from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';

const useStyles = makeStyles({
    slider: {
        '& .MuiSlider-track': {
            marginTop:-1,
            height: 4,
            borderRadius: 2 // Set the desired height here
          },
          '& .MuiSlider-thumb': {
            height: 12, // Set the desired height here
            width: 12, // Set the desired width here
            marginTop: -5, // Adjust the vertical position if needed
          },
      color: '#7F55AE', // Set the desired color here
    },

  });
  
  // Inside the render/JSX code


const theme = createTheme({
    components: {
      MuiSlider: {
        styleOverrides: {
          thumb: {
            color: 'red', // Set the color to the desired value
          },
          track: {
            color: 'red', // Set the color to the desired value
          },
          rail: {
            color: 'gray', // Set the color to the desired value
          },
        },
      },
    },
  });

const getRange = (vitalName) => {
  // Define the range for each vital
  const rangeData = {
    SPO: { min: 0, max: 100 },
    HR: { min: 0, max: 250 },
    BR: { min: 0, max: 40 },
    BG: { min: 0, max: 600 },
    BPS: { min: 0, max: 250 },
    BPD: { min: 0, max: 200 },
    TMP: { min: 0, max: 110 },
    CAL: { min: 50, max: 1200},
    STP: { min:100, max: 20000},
    BMI: { min: 10, max: 45},
    SLP : { min: 2, max :24}
  };

  return rangeData[vitalName];
};



const AlarmNewPage = () => {


  const classes = useStyles();
  const history = useHistory();
  const token = useSelector((state) => state.auth.authToken);
  const groupName = useSelector((state) => state.auth.groupName);
  const [loading, setLoading] = useState(true);

  const [isFetchingData, setIsFetchingData] = useState(false);

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const { id,patient_group_id } = useParams();

    const location = useLocation();
    const urlStateData = location?.state

    const [priorityDialogOpen, setPriorityDialogOpen] = useState(null); // State to control the priority dialog

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarColor, setSnackbarColor] = useState('');

  const [anchorEl, setAnchorEl] = useState(null); // Anchor element for popover
  const [selectedVitalIndex, setSelectedVitalIndex] = useState(null); // Index of the selected vital

  const vitalList = useSelector((state) => state.auth.vitalList);
  console.log(vitalList);

  const requiredKeys = ['HR', 'br', 'BP', 'BG', 'spo2', 'TMP', 'CAL', 'STP', 'WT', 'sleep'];

    const newVitalList = Object.keys(vitalList).filter((key) =>
        requiredKeys.includes(key)
    );

    console.log(newVitalList);

  const sendList = {
    vital_list : newVitalList
  }  
  const isActive = ( path )=>{
    return path === location.pathname ;
  }
  const HomePage = () => {
    history.push(PATHS.home)
  }
  
  const PATHS = {
    home : '/home',
    profile:'/myprofile',
    alarm:'/alarm_management'
  }  
  const profileClick = () => {
  history.push(PATHS.profile)
  }
  const handleToAlarmSettings = () => {
  // history.push(PATHS.alarm)
  }
  
  

  const [vitals, setVitals] = useState([]);

  const fetchVitalData = () =>{

  axios({
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    url: `${constants.API_URL}/get_alarms_settings/${patient_group_id != null ? patient_group_id:groupName}/${token}/`,
    data: sendList,
    
  
  }).then(
    (res) => { 
        console.log(res);
        
        const vitalsData = [];
    if (res?.data?.response?.BG) {
      vitalsData.push({ name: 'BG', min: res?.data?.response?.BG?.min, max: res?.data?.response?.BG?.max, priority: res?.data?.response?.BG?.priority });
    }
    if (res?.data?.response?.HR) {
      vitalsData.push({ name: 'HR', min: res?.data?.response?.HR?.min, max: res?.data?.response?.HR?.max, priority: res?.data?.response?.HR?.priority });
    }
    if (res?.data?.response?.BR) {
      vitalsData.push({ name: 'BR', min: res?.data?.response?.BR?.min, max: res?.data?.response?.BR?.max, priority: res?.data?.response?.BR?.priority });
    } 
    if (res?.data?.response?.BPD) {
        vitalsData.push({ name: 'BPD', min: res?.data?.response?.BPD?.min, max: res?.data?.response?.BPD?.max, priority: res?.data?.response?.BPD?.priority });
      }
    if (res?.data?.response?.BPS) {
      vitalsData.push({ name: 'BPS', min: res?.data?.response?.BPS?.min, max: res?.data?.response?.BPS?.max, priority: res?.data?.response?.BPS?.priority });
    }
    if (res?.data?.response?.TMP) {
        vitalsData.push({ name: 'TMP', min: res?.data?.response?.TMP?.min, max: res?.data?.response?.TMP?.max, priority: res?.data?.response?.TMP?.priority });
      }  
    if (res?.data?.response?.SPO) {
        vitalsData.push({ name: 'SPO', min: res?.data?.response?.SPO?.min, max: res?.data?.response?.SPO?.max? res?.data?.response?.SPO?.max : 100 , priority: res?.data?.response?.SPO?.priority });
    }  
    if (res?.data?.response?.CAL) {
      vitalsData.push({ name: 'CAL', min: res?.data?.response?.CAL?.min, max: res?.data?.response?.CAL?.max? res?.data?.response?.CAL?.max : 1200 , priority: res?.data?.response?.CAL?.priority });
    }  
    if (res?.data?.response?.STP) {
      vitalsData.push({ name: 'STP', min: res?.data?.response?.STP?.min, max: res?.data?.response?.STP?.max? res?.data?.response?.STP?.max : 10000 , priority: res?.data?.response?.STP?.priority });
    }  
    if (res?.data?.response?.BMI) {
      vitalsData.push({ name: 'BMI', min: res?.data?.response?.BMI?.min, max: res?.data?.response?.BMI?.max , priority: res?.data?.response?.BMI?.priority });
    }  
    if (res?.data?.response?.SLP) {
      vitalsData.push({ name: 'SLP', min: res?.data?.response?.SLP?.min, max: res?.data?.response?.SLP?.max , priority: res?.data?.response?.SLP?.priority });
    }  


    setVitals(vitalsData)
    setLoading(false);
    });

}
useEffect(() => {
  fetchVitalData(); // Initial data fetch on component mount
}, []);



const handleMinChange = (index, value) => {
    setVitals(prevVitals => {
      const updatedVitals = [...prevVitals];
      updatedVitals[index].min = value;
      return updatedVitals;
    });
  };

  const handleMaxChange = (index, value) => {
    setVitals(prevVitals => {
      const updatedVitals = [...prevVitals];
      updatedVitals[index].max = value;
      return updatedVitals;
    });
  };
  
const handleMinIncrement = (index) => {
    setVitals(prevVitals => {
        console.log("mkm",prevVitals)
      const updatedVitals = [...prevVitals];
      const range = getRange(updatedVitals[index].name);
      const min = updatedVitals[index].min + 1 <= range.max ? updatedVitals[index].min + 1 : range.max;
      updatedVitals[index].min = min <= updatedVitals[index].max ? min : updatedVitals[index].max;
      return updatedVitals;
    });
  };

  const handleMinDecrement = (index) => {
    setVitals(prevVitals => {
      const updatedVitals = [...prevVitals];
      const range = getRange(updatedVitals[index].name);
      const min = updatedVitals[index].min - 1 >= range.min ? updatedVitals[index].min - 1 : range.min;
      updatedVitals[index].min = min <= updatedVitals[index].max ? min : updatedVitals[index].max;
      return updatedVitals;
    });
  };
   
  const handleMaxIncrement = (index) => {
    setVitals(prevVitals => {
      const updatedVitals = [...prevVitals];
      const range = getRange(updatedVitals[index].name);
      const max = updatedVitals[index].max + 1 <= range.max ? updatedVitals[index].max + 1 : range.max;
      updatedVitals[index].max = max >= updatedVitals[index].min ? max : updatedVitals[index].min;
      return updatedVitals;
    });
  };

  const handleMaxDecrement = (index) => {
    setVitals(prevVitals => {
      const updatedVitals = [...prevVitals];
      const range = getRange(updatedVitals[index].name);
      const max = updatedVitals[index].max - 1 >= range.min ? updatedVitals[index].max - 1 : range.min;
      updatedVitals[index].max = max >= updatedVitals[index].min ? max : updatedVitals[index].min;
      return updatedVitals;
    });
  };

  //  // Function to open the priority dialog for a specific vital
  //  const openPriorityDialog = (index) => {
  //   setPriorityDialogOpen(index);
  // };

  // // Function to close the priority dialog for a specific vital
  // const closePriorityDialog = () => {
  //   setPriorityDialogOpen(null);
  // };

  // // Function to handle priority change for a specific vital
  // const handlePriorityChange = (event, index) => {
  //   // Get the selected priority value
  //   const selectedPriority = event.target.value;

  //   // Update the priority value in the vitals state
  //   setVitals((prevVitals) => {
  //     const updatedVitals = [...prevVitals];
  //     updatedVitals[index].priority = selectedPriority;
  //     return updatedVitals;
  //   });

  //   // Close the priority dialog
  //   closePriorityDialog();
  // };

  const openPopover = (event, index) => {
    setAnchorEl(event.currentTarget);
    setSelectedVitalIndex(index);
  };

  // Function to close the popover
  const closePopover = () => {
    setAnchorEl(null);
    setSelectedVitalIndex(null);
  };

  // Function to handle priority change for a specific vital
  const handlePriorityChange = (event) => {
    // Get the selected priority value
    const selectedPriority = event.target.value;

    // Update the priority value in the vitals state
    setVitals((prevVitals) => {
      const updatedVitals = [...prevVitals];
      updatedVitals[selectedVitalIndex].priority = selectedPriority;
      return updatedVitals;
    });

    // Close the popover
    closePopover();
  };

  const handleSaveChanges = () => {

    const updatedData = vitals.reduce((acc, vital) => {
      acc[vital.name] = { min: vital.min, max: vital.max, priority: vital.priority };
      return acc;
    }, {});
    console.log("Updated Data",updatedData)
  
    axios({
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      url: `${constants.API_URL}/store_alarm_range/${patient_group_id != null ?patient_group_id:groupName}/${token}/`,
      data: updatedData,
      
    
    }).then(
      (res) => {
        console.log("storeAlarm",res)
        if (res?.status === 200){
          setTimeout(() => {
          fetchVitalData();
          }, 2000);
          clearTimeout();
          setSnackbarOpen(true);
          setSnackbarColor('success');
          setSnackbarMessage('Alarm Updated');
           
        }
       }).catch((e) =>{
        console.log(e);
        console.error(e);
       })
   
  };

  const handleAutoAdjust = () => {

    axios({
      method: "delete",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      url: `${constants.API_URL}/reset_all_alarms_settings/${patient_group_id != null ? patient_group_id:groupName}/${token}/`,     
    
    }).then(
      (res) => {
        console.log("AutoAdjustAlarm",res)
        if (res?.status === 200){
          setTimeout(() => {
          fetchVitalData();
          handleClose();
          }, 2000);
          clearTimeout();
          setSnackbarOpen(true);
          setSnackbarColor('success');
          setSnackbarMessage('Auto Reset Successful');
        
        }
        else{
          setSnackbarOpen(true);
          setSnackbarColor('error');
          setSnackbarMessage('Error');
        }
       }).catch((e) =>{
        console.log(e);
        console.error(e);
        setSnackbarOpen(true);
          setSnackbarColor('error');
          setSnackbarMessage('Error');
       })
  }

  const handleIndividualReset = (vital) => {
    const updatedData = vitals.reduce((acc, vital) => {
      acc[vital.name] = { min: vital.min, max: vital.max, priority: vital.priority };
      return acc;
    }, {});
  
    setIsFetchingData(true);
    axios({
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      url: `${constants.API_URL}/store_alarm_range/${patient_group_id != null ? patient_group_id:groupName}/${token}/`,
      data: updatedData,
    })
      .then((res) => {
        console.log("storeAlarm", res);
        if (res?.status === 200) {
          return axios({
            method: "delete",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            url: `${constants.API_URL}/reset_alarm_settings/${vital}/${patient_group_id != null ? patient_group_id:groupName}/${token}/`,
          });
        }
      })
      
      .then((res) => {
        console.log("individual reset", res);
        if (res?.status === 200) {
          setTimeout(() => {
            fetchVitalData();
            setIsFetchingData(false);
          }, 2000);
          clearTimeout();
          setSnackbarOpen(true);
          setSnackbarColor("success");
          setSnackbarMessage("Reset Successful");
        }
      })
      .catch((e) => {
        console.log(e);
        console.error(e);
      })
      
  };

  const handleHomePage = () =>{
    {patient_group_id != null ?   history.goBack() : history.push("/home") }
    
  }

  const handleClickOpen = () => {
    setOpen(true);
  }

  console.log("vitals", vitals)
  const vertical = "bottom"; 
  const horizontal = "center"; 

  return (
    <div className={styles.alarmManageMain}>
    <div className={styles.alarmSideBar}>
    <div className='sidebarIcon' onClick={HomePage}>
            <HomeIcon style={{height:"20px"}}/>
            <p style={{fontSize:"small",fontFamily: "Montserrat, sans-serif"}}>Home</p>
            </div>
          
            <div className='sidebarIcon' onClick={profileClick}>
            <Person2Icon style={{height:"20px"}}/>
            <p style={{fontSize:"small",fontFamily: "Montserrat, sans-serif"}}>My Profile</p>
            </div>
            <div className={['sidebarIcon' , isActive(PATHS.alarm)?'active':''].join(" ")} onClick={handleToAlarmSettings}>
            <AccessAlarmsIcon style={{height:"20px"}}/>
            <p style={{fontSize:"small",fontFamily: "Montserrat, sans-serif"}}>Alarm Settings</p>
            </div>
    </div>
    <div className={styles.mainAlarmPage}>
    <div className={styles.alarmHeader}>
     <div className={styles.alarmHeading}><ArrowBackIos style={{cursor:"pointer"}} onClick={handleHomePage}/> Manage Alarms</div>
     <div className={styles.autoAdjustBtn}><Button className={styles.adjustButton} onClick={handleClickOpen}>Auto Adjust</Button></div>
    </div>
    <div className={styles.alarmCardContainer}>
    
      { vitals && vitals.length > 0 ? (
      vitals?.map((vital, index) => (

        
        <div key={vital?.name}>
      <div className={styles.mainContainerAlarm}>
        <div className={styles.VitalName}> 
        <div>
        {vital?.name === 'HR' ? 'Heart Rate' :
         vital?.name === 'BR' ? 'Breath Rate' :
         vital?.name === 'BPS' ? 'BP - Systolic' :
         vital?.name === 'BPD' ? 'BP - Diastolic' :
         vital?.name === 'BG' ? 'Blood Glucose' : 
         vital?.name === 'TMP' ? 'Temperature': 
         vital?.name === 'SPO' ? 'SpO2': 
         vital?.name === 'CAL' ? 'Calorie': 
         vital?.name === 'STP' ? 'Step Count': 
         vital?.name === 'SLP' ? 'Sleep Hours': 
         vital?.name === 'BMI' ? 'BMI': 
         vital?.name}
         </div>
         <div className={styles.resetPriorityBox}>
         <div className={styles.individualReset} onClick={(event) => openPopover(event, index)} >Priority</div>
          <div className={styles.individualReset} onClick={() => handleIndividualReset(vital.name)}>Reset</div>
         </div>
         </div>
          <div className={styles.innerContainer}>
          <div className={styles.minContainer}>
          <div style={{ color: "#8D7A7A", fontFamily: "'Montserrat', sans-serif", fontWeight:"500", marginBottom: "20px" }}>Min</div>
          <div style={{ display: "flex", flexDirection: "row", marginBottom: "10px" }}>
            <button className='' style={{minWidth:"20px", border:"none", borderRadius:"5px"}} onClick={() => handleMinDecrement(index)}>-</button>
            <div style={{minWidth:"30px", maxWidth:"100%", margin:"5px",fontFamily: "'Montserrat', sans-serif", fontWeight:"500"}}>{vital?.min}</div>
            <button style={{minWidth:"20px", border:"none", borderRadius:"5px"}}  onClick={() =>handleMinIncrement(index)}>+</button>
           </div> 
           <div style={{ width:"80%", margin:"10px"}}>
           <ThemeProvider theme={theme}>
            <Slider
              min={getRange(vital?.name)?.min}
              max={getRange(vital?.name)?.max}
              value={vital?.min}
              className={classes.slider}
              onChange={(event, value) => handleMinChange(index, value)}
            />
            </ThemeProvider>
          </div>
          </div>
          <div className={styles.maxContainer}>
          <div style={{ color: "#8D7A7A", fontFamily: "'Montserrat', sans-serif", fontWeight:"500", marginBottom: "20px" }}>Max</div>
          <div style={{ display: "flex", flexDirection: "row", marginBottom: "10px" }}>
            <button style={{minWidth:"20px", border:"none", borderRadius:"5px"}}  onClick={() => handleMaxDecrement(index)}>-</button>
            <div style={{minWidth:"30px", maxWidth:"100%", margin:"5px",fontFamily: "'Montserrat', sans-serif", fontWeight:"500"}}>{vital?.max}</div>
            <button style={{minWidth:"20px", border:"none", borderRadius:"5px"}}  onClick={() => handleMaxIncrement(index)}>+</button>
           </div>
           <div style={{ width:"80%", margin:"10px"}}>
            <Slider
              min={vital?.min}
              max={getRange(vital?.name)?.max}
              value={vital?.max}
              className={classes.slider}
              onChange={(event, value) => handleMaxChange(index, value)}
            />
           </div>
          </div>
        </div>
        <div className={styles.priorityButton}>
                {/* Priority Popover */}
                <Popover
                  open={selectedVitalIndex === index}
                  anchorEl={anchorEl}
                  onClose={closePopover}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <Box p={1.5}>
                    <Typography className={styles.setPrioritybox}>SET PRIORITY</Typography>
                    <RadioGroup
                      value={vital?.priority}
                      onChange={handlePriorityChange}
                    >
                      <FormControlLabel className={styles.radiobuttonstyle} value="High" control={<Radio />} label="High" />
                      <FormControlLabel
                        value="Medium"
                        control={<Radio />}
                        label="Medium"
                      />
                      <FormControlLabel value="Low" control={<Radio />} label="Low" />
                    </RadioGroup>
                  </Box>
                </Popover>
                </div>
        </div>
        </div>
      ))
      ) : (
        <div className={styles.headingNoData}>No Vital Data Available</div>
      )
    }

    </div>
    <div><Button style={{margin:"10px",color:"white", backgroundColor:"#7F55AE", borderRadius:"none"}} onClick={handleSaveChanges}>Save Changes</Button></div>
    </div>

      <Dialog onClose={handleClose} open={open} >
        <div style={{ display: 'flex', justifyContent: 'right' }}>
          <Cancel className={styles.dialogCloseIconStyles} onClick={handleClose} />
        </div>

        <DialogTitle id="customized-dialog-title" onClose={handleClose} textAlign="center" >
          <div className={styles.dialogMainContainer}>
            <div className={styles.dialogTextStyle} >  Auto Adjust all the min and max ranges to default values ? </div>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
              <Button onClick={handleAutoAdjust} className={styles.buttonStyleYes}>YES</Button>
              <Button className={styles.buttonStyleNo} onClick={handleClose}>NO</Button>
            </div>
          </div>
        </DialogTitle>

      </Dialog>
      <Backdrop open={isFetchingData} style={{ zIndex: 1, color: '#fff' }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      
    
    <Snackbar
        anchorOrigin={{ vertical, horizontal }}
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={() => setSnackbarOpen(false)}
          message={snackbarMessage}
          key={vertical + horizontal}
        >
          <Alert severity={snackbarColor} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
    </Snackbar>
    </div>
  );
};

export default AlarmNewPage
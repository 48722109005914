import React, { useState } from "react";
import BPLineChart from "./BPLineChart";
import styles from "./BPChartPopup.module.css";

export const BPContainer = ({ title, xData, yData1, yData2, bpRes, type }) => {
  const [sysDia, setSysDia] = useState({ sys: null, dia: null, date: null });
  const handleClick = (data1, data2) => {
    if (data1 && data2) {
      var [a1, a2] = data1.value;
      var [b1, b2] = data2.value;
      setSysDia({
        title: a1,
        sys: a1,
        dia: a1,
        date: a1,
        x1: b2,
        x2: a2,
      });
    }
  };

  return (
    <div className={styles.containerMain}>
      <div>
        <BPLineChart
          title={title}
          xData={xData}
          yData1={yData1}
          yData2={yData2}
          bpRes={bpRes}
          handleClick={handleClick}
        />
      </div>
    </div>
  );
};

import React from "react";
import BatteryAlertIcon from "@material-ui/icons/BatteryAlert";
import EventNoteIcon from "@material-ui/icons/EventNote";
import NotificationsIcon from "@material-ui/icons/Notifications";
import PauseCircleOutlineIcon from "@material-ui/icons/PauseCircleOutline";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { IconButton, makeStyles, styled } from "@material-ui/core";

const Item = styled("div")(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  backgroundColor: "#4d4e63",
  display: "flex",
  alignItems: "center",
  color: "white",
}));

const useStyles = makeStyles((theme) => ({
  stack: {
    ["@media (max-width:900px)"]: {
      flexWrap: "wrap",
    },
  },
}));

function OxyFloHeader(props) {
  const classes = useStyles();

  return (
    <div className="flexitems-between ">
      <div>
        <h3>OxyFlo</h3>
      </div>
      <div className="flexitems-between flex-wrap" style={{ marginLeft: "0" }}>
        <div direction="row" spacing={1}>
          <Item>
            <IconButton style={{ color: "red" }}>
              <EventNoteIcon />
            </IconButton>
          </Item>

          <Item>
            <p style={{ margin: "0" }}>Alarms</p>
          </Item>

          <Item>
            <IconButton style={{ color: "white" }}>
              <NotificationsIcon />
            </IconButton>
          </Item>
        </div>

        <div style={{ padding: "0 10px" }}>
          <IconButton style={{ color: "white" }}>
            <PauseCircleOutlineIcon style={{ height: "3rem", width: "3rem" }} />
          </IconButton>
        </div>

        <div style={{ padding: "0 15px " }}>
          <div className="flexitems-end flex-column">
            <div>21/07/2021</div>
            <div>10:45:88</div>
          </div>
        </div>

        <div style={{ paddingLeft: "15px" }}>
          <div className="flexitems-baseline">
            <p style={{ margin: "0" }}>Power</p>
            <IconButton style={{ color: "white" }}>
              <FiberManualRecordIcon
                style={{ height: "12px", width: "12px" }}
              />
            </IconButton>
          </div>
          <div className="flexitems-baseline">
            <div style={{ margin: "0" }}>Connection</div>
            <IconButton style={{ color: "green" }}>
              <FiberManualRecordIcon
                style={{ height: "12px", width: "12px" }}
              />
            </IconButton>
          </div>
        </div>

        <div>
          <div>
            <IconButton style={{ color: "red" }}>
              <BatteryAlertIcon style={{ height: "3rem", width: "3rem" }} />
            </IconButton>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OxyFloHeader;

import React from "react";
import EventNoteIcon from "@material-ui/icons/EventNote";
import NotificationsIcon from "@material-ui/icons/Notifications";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import { IconButton, makeStyles, styled } from "@material-ui/core";

const Item = styled("div")(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  backgroundColor: "#4d4e63",
  display: "flex",
  alignItems: "center",
  color: "white",
}));

const useStyles = makeStyles((theme) => ({
  stack: {
    ["@media (max-width:900px)"]: {
      flexWrap: "wrap",
    },
  },
}));

function IndventrHeader(props) {
  const classes = useStyles();

  return (
    <div className="flexitems-between ">
      <div>
        <h3>OxyFlo</h3>
      </div>
      <div className="flexitems-between flex-wrap" style={{ marginLeft: "0" }}>
        <div direction="row" spacing={1}>
          <Item>
            <IconButton style={{ color: "red" }}>
              <EventNoteIcon />
            </IconButton>
          </Item>

          <Item>
            <p style={{ margin: "0" }}>Alarms</p>
          </Item>

          <Item>
            <IconButton style={{ color: "white" }}>
              <NotificationsIcon />
            </IconButton>
          </Item>
        </div>

        <div style={{ padding: "0 10px" }}>
          <div className="flexitems-baseline">
            <p style={{ margin: "0" }}>Cycle No :</p>
            <p>774376347</p>
          </div>
        </div>

        <div style={{ padding: "0 15px " }}>
          <div className="flexitems-end flex-column">
            <div>10:45:88 PM</div>
          </div>
        </div>

        <div>
          <div>
            <IconButton style={{ color: "red" }}>
              <PowerSettingsNewIcon style={{ height: "3rem", width: "3rem" }} />
            </IconButton>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IndventrHeader;

import React from 'react'
import "./CircleCommon.css"
import ExpandMorePopup from './../../../../../Common/ExpandMorePopup'
import OptionPopUp from '../../OtherComponents/OptionPopUp'
import PopUpManualEntry from '../../OtherComponents/PopUpManualEntry'

function CircleCommonCard({cardTitle,cardValue1,cardValue,cardUnit,cardValue3,alarmStatusBorder,cardAlarmStatus, cardTimeGap,circleColor,tileBgColor,cardIcon,fontValueSize, parameter1, parameter3, TitleForm, FormFile,option2, onClick}) {

  return (
    <div className={"circle-Card-Main-container"+(' alarm-status-'+alarmStatusBorder)} style={{display:"flex", flexDirection:"column", backgroundColor:tileBgColor || "white", minWidth:"200px",height:"100%"}}>
       <div style={{display: "flex" , justifyContent:"space-between", alignItems:"center",marginLeft:"10px", marginRight:"5px",marginTop:"10px,", height:"100%"}}>
            <div style={{fontSize:"1.2rem", fontFamily:"'Montserrat', sans-serif", fontWeight:"500"}}>
              {cardTitle}
            </div>
            <div> <OptionPopUp option1={<PopUpManualEntry
          
          optionName="MANUAL ENTRY" 
          FormTitle={TitleForm} 
          FormType={FormFile}/>} 
          option2={option2}/></div>
       </div>
       <div onClick={onClick} style={{cursor:"pointer",display:"flex", flexDirection:"row", alignItems:"center",justifyContent:"center", marginTop:"-20px"}}>
          <div style={{alignItems:"center", justifyContent:"center", paddingRight:"10%"}}>
          <div style={{fontFamily:"'Montserrat', sans-serif", fontSize:"1.2rem", fontWeight:"500"}}>
          {cardValue1} 
          </div>
          <div style={{fontSize:"0.7rem", color:"#75757C"}}>
            {parameter1}
          </div>
          </div>
          <div className='circle-value' style={{ borderColor: circleColor }}>
            <div style={{fontSize:"10px"}}>{cardIcon}</div>
           <div style={{alignItems:"center", fontSize:fontValueSize, fontWeight:"600",fontFamily:"'Montserrat', sans-serif"}}>{cardValue}</div> 
           <div style={{fontSize:"0.7rem",fontFamily:"'Montserrat', sans-serif", color:"#75757C"}}>{cardUnit}</div>
          </div>
        <div
        style={{alignItems:"center", justifyContent:"center",paddingLeft:"10%"}}>
          
          <div style={{fontFamily:"'Montserrat', sans-serif",fontSize:"1.2rem", fontWeight:"500"}}>
          {cardValue3} 
          </div>
          <div  style={{fontSize:"0.7rem", color:"#75757C"}}>
            {parameter3}
          </div>
        </div>

       </div>
       <div style={{display:"flex", justifyContent:"space-between", marginLeft:"7px",marginRight:"7px",marginTop:"-10px", fontSize:"0.75rem", fontFamily:"'Montserrat', sans-serif"}}>
        <div style={{fontFamily:"'Montserrat', sans-serif"}}>
          {cardAlarmStatus}
        </div>
        <div style={{fontFamily:"'Montserrat', sans-serif"}}>
          {cardTimeGap}
        </div>

       </div>
    </div>
  )
}

export default CircleCommonCard
import React, { useEffect, useState } from "react";
import styles from "./MyProfile.module.css";
import Headerv2 from "../../../../../home/V2Components/Headerv2";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { Avatar, styled, makeStyles, Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import constants from "../../../../../../Common/Constants";
import CircularProgress from "@mui/material/CircularProgress";
import { Switch } from "@mui/material";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  setIsDoctor,
  setIsPatient,
  updateHeaderGroupName,
} from "../../../../../../store/authSlice";
import { useLocation } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import Person2Icon from "@mui/icons-material/Person2";
import AccessAlarmsIcon from "@mui/icons-material/AccessAlarms";
// import '../../../../../home/V2Components/ProfileSwitching/DashboardCommon.css'

const useStyles = makeStyles((theme) => ({
  root: {
    alignSelf: "center",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  container: {
    position: "relative",
    display: "inline-block",
  },
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  cameraIcon: {
    position: "absolute",
    bottom: "10%",
    right: "15%",
    transform: "translate(50%, 50%)",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: "50%",
    padding: theme.spacing(1),
    cursor: "pointer",
    display: "flex",
    height: "20px",
    width: "20px",
    alignItems: "center",
    justifyContent: "center",
  },
  mandatoryField: {
    border: "1px solid red",
  },
  errorMsg: {
    color: "red",
    border: "none",
    fontSize: "10px",
  },
}));

export const ProfilePage = () => {
  const [profile, setProfile] = React.useState();
  const history = useHistory();
  const homePage = () => {
    history.push("/home");
  };
  const [selectedImage, setSelectedImage] = useState(null);
  const classes = useStyles();
  const [selectedFile, setSelectedFile] = useState(null);
  const groupName = useSelector((state) => state.auth.groupName);
  const token = useSelector((state) => state.auth.authToken);
  const [doctors, setDoctors] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState("");
  const [isDoctor1, setIsDoctor1] = useState("");
  const [specialization, setSpecialization] = useState("");
  const [hospital, setHospital] = useState("");
  const [name, setName] = useState("");
  const [dob, setDob] = useState("");
  const [contact, setContact] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [email, setEmail] = useState("");
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [cancer, setCancer] = useState("");
  const [heart_disease, setHeart_disease] = useState("");
  const [cholesterol, setCholestrol] = useState("");
  const [diabetes, setDiabetes] = useState("");
  const [hypertension, setHypertension] = useState("");
  const [med, setMed] = useState("");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);
  const [proImage, setProImage] = useState("");
  const [isPatient1, setIsPatient1] = useState("");
  const dispatch = useDispatch();
  const isActive = (path) => {
    return path === location.pathname;
  };
  const HomePage = () => {
    history.push(PATHS.home);
  };
  const location = useLocation();
  const PATHS = {
    home: "/home",
    profile: "/myprofile",
    alarm: "/alarm_management",
  };
  const profileClick = () => {
    history.push(PATHS.profile);
  };
  const handleToAlarmSettings = () => {
    history.push(PATHS.alarm);
  };

console.log("img:",selectedImage?.split(",")[1])

  useEffect(() => {
    // Fetch the profile data from the API
    fetchProfile()
      .then((data) => {
        setProfile(data);
        setName(data?.profile_data?.name);
        setDob(data?.profile_data?.dob);
        setContact(data?.profile_data?.contact);
        setEmail(data?.profile_data?.email);
        // Check if the fetched weight is within the acceptable range
        const fetchedWeight = data?.profile_data?.weight;
        if (fetchedWeight >= 3 && fetchedWeight <= 300) {
          setWeight(fetchedWeight.toString()); // Convert to string to ensure it's a string value
        } else {
          setWeight(""); // Set it to an empty string or another default value
        }
        // Check if the fetched height is within the acceptable range
        const fetchedHeight = data?.profile_data?.height;
        if (fetchedHeight >= 30 && fetchedHeight <= 300) {
          setHeight(fetchedHeight.toString()); // Convert to string to ensure it's a string value
        } else {
          setHeight(""); // Set it to an empty string or another default value
        }
        setMed(data?.profile_data?.medication);
        setSpecialization(data?.profile_data?.specialization);
        setHospital(data?.profile_data?.hospital);
        setCancer(data?.profile_data?.cancer);
        setHeart_disease(data?.profile_data?.heart_disease);
        setHypertension(data?.profile_data?.hypertension);
        setCholestrol(data?.profile_data?.cholesterol);
        setDiabetes(data?.profile_data?.diabetes);
        setIsDoctor1(data?.profile_data?.is_doctor);
        setProImage(data?.profile_data?.image);
        setSelectedDoctor(data?.profile_data?.doctor);
        setIsPatient1(data?.profile_data?.is_patient);
      })
      .catch((error) => {
        console.error("Error fetching profile data:", error);
      });
  }, []);

  const fetchProfile = async () => {
    const response = await fetch(
      `${constants.API_URL}/view_profile/${groupName}/${token}`
    );
    const data = await response.json();
    return data;
  };
  const handleNameChange = (event) => {
    const newName = event.target.value;
    setName(newName);
    dispatch(updateHeaderGroupName(newName)); // Dispatch the action with the updated name
  };

  useEffect(() => {
    // Fetch the list of doctors from the API
    fetchDoctors()
      .then((data) => {
        setDoctors(data);
        setSelectedFile(data?.profile_data?.image);
        if (data?.doctor_list.length > 0) {
          setSelectedDoctor(data?.doctor_list[0].doctor_id);
        }
      })
      .catch((error) => {
        console.error("Error fetching doctors:", error);
      });
  }, []);

  const fetchDoctors = async () => {
    // Make an API request to fetch the list of doctors
    const response = await fetch(`${constants.API_URL}/doctor_list`);
    const data = await response.json();
    return data;
  };

  const handleDoctorChange = (event) => {
    const value = event.target.checked;
    setSelectedDoctor("");
    setIsDoctor1(value);
    if (!value) {
      setSpecialization("");
      setHospital("");
    }
  };
  const handlePatientChange = (event) => {
    const value = event.target.checked;
    setIsPatient1(value);
  };

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    // Add leading zeros for single-digit months and days
    if (month < 10) {
      month = `0${month}`;
    }
    if (day < 10) {
      day = `0${day}`;
    }

    return `${year}-${month}-${day}`;
  };

  const handleSaveChanges = async () => {
    const currentDate = new Date();
    const selectedDOB = new Date(dob);
    // Convert currentDate to "YYYY-MM-DD" format
    const currentDateString = currentDate.toISOString().split("T")[0];

    // Convert selectedDOB to "YYYY-MM-DD" format
    const selectedDOBString = selectedDOB.toISOString().split("T")[0];


    if (selectedDOBString > currentDateString) {
      showSnackbar(
        <div style={{ display: "flex" }}>
          <p style={{ color: "red" }}>
            <ErrorOutlineIcon />
          </p>
          <p style={{ color: "red" }}>DOB cannot be in the future</p>
        </div>
      );
      return;
    }

    // Perform validations
    if (!name || !dob || !height || !weight) {
      showSnackbar(
        <div style={{ display: "flex" }}>
          <p style={{ color: "red" }}>
            <ErrorOutlineIcon />
          </p>
          <p style={{ color: "red" }}>Please fill in all mandatory fields</p>
        </div>
      );
      return;
    }

    if (isDoctor1) {
      if (!specialization || !hospital) {
        showSnackbar(
          <div style={{ display: "flex" }}>
            <p style={{ color: "red" }}>
              <ErrorOutlineIcon />
            </p>
            <p style={{ color: "red" }}>Please fill in all mandatory fields</p>
          </div>
        );
        return;
      }
    }

    if (!isPatient1 && !isDoctor1) {
      showSnackbar(
        <div style={{ display: "flex" }}>
          <p style={{ color: "red" }}>
            <ErrorOutlineIcon />
          </p>
          <p style={{ color: "red" }}>Please select any of the profiles!</p>
        </div>
      );
      return;
    }

    // Add this validation check inside the handleSaveChanges function
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email && !emailRegex.test(email)) {
      showSnackbar(
        <div style={{ display: "flex" }}>
          <p style={{ color: "red" }}>
            <ErrorOutlineIcon />
          </p>
          <p style={{ color: "red", fontSize: "15px" }}>Invalid Email Format</p>
        </div>
      );
      return;
    }
  // Validate height and weight
  const heightValue = parseInt(height);
  const weightValue = parseInt(weight);

  if (isNaN(heightValue) || heightValue < 30 || heightValue > 300) {
    showSnackbar(
      <div style={{ display: "flex" }}>
        <p style={{ color: "red" }}>
          <ErrorOutlineIcon />
        </p>
        <p style={{ color: "red" }}>Height must be between 30 and 300 cm</p>
      </div>
    );
    return;
  }

  if (isNaN(weightValue) || weightValue < 3 || weightValue > 300) {
    showSnackbar(
      <div style={{ display: "flex" }}>
        <p style={{ color: "red" }}>
          <ErrorOutlineIcon />
        </p>
        <p style={{ color: "red" }}>Weight must be between 3 and 300 kg</p>
      </div>
    );
    return;
  }

    const heightValidate = /^[0-9]*$/;
    if (!heightValidate.test(height)) {
      showSnackbar(
        <div style={{ display: "flex" }}>
          <p style={{ color: "red", fontSize: "3px" }}>
            <ErrorOutlineIcon />
          </p>
          <p style={{ color: "red", fontSize: "3px" }}>Invalid Height</p>
        </div>
      );

      return;
    }

    if (height < 30 || height > 300) {
      showSnackbar(
        <div style={{ display: "flex" }}>
          <p style={{ color: "red" }}>
            <ErrorOutlineIcon />
          </p>
          <p style={{ color: "red" }}>Height must be between 30cm and 300cm</p>
        </div>
      );
      return;
    }

    if (weight < 3 || weight > 300) {
      showSnackbar(
        <div style={{ display: "flex" }}>
          <p style={{ color: "red" }}>
            <ErrorOutlineIcon />
          </p>
          <p style={{ color: "red" }}>Weight must be between 3kg and 300 kg</p>
        </div>
      );
      return;
    }

    const weightValidate = /^[0-9]*$/;
    if (!weightValidate.test(weight)) {
      showSnackbar(
        <div style={{ display: "flex" }}>
          <p style={{ color: "red", fontSize: "3px" }}>
            <ErrorOutlineIcon />
          </p>
          <p style={{ color: "red" }}>Invalid Weight</p>
        </div>
      );
      return;
    }

const  payload = {
      name,
      dob,
      contact,
      email,
      height,
      weight,
      hospital,
      specialization,
      cancer,
      heart_disease,
      diabetes,
      hypertension,
      cholesterol,
      doctor: selectedDoctor,
      is_doctor: isDoctor1,
      medication: med,
      is_patient: isPatient1,
      image:selectedImage?.split(",")[1]
    };

    try {
      const response = await fetch(
        `${constants.API_URL}/update_profile/${groupName}/${token}/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      const data = await response.json();
      console.log("response MY PROFILE", data);
      dispatch(setIsDoctor(data?.is_doctor));
      dispatch(setIsPatient(data?.is_patient));

      if (response.ok) {
        // Profile update successful, handle any necessary actions (e.g., show success message)
        showSnackbar(
          <div style={{ display: "flex" }}>
            <TaskAltIcon />
            <p> Data successfully saved</p>
          </div>
        );
          
      } else {
        showSnackbar(
          <div style={{ display: "flex", color: "red" }}>
            <UnpublishedIcon />
            <p> Saving Failed</p>
          </div>
        );
      }
    } catch (error) {}
  };

  const showSnackbar = (message, duration = 3000) => {
    setSnackbarMessage(message);
    setIsSnackbarVisible(true);

    setTimeout(() => {
      setIsSnackbarVisible(false);
    }, duration);
  };

  return (
    <div className={styles.backH} key={profile}>
      <Headerv2 />
      <div className={styles.sideBarProfile}>
        <div className="sidebarIcon" onClick={HomePage}>
          <HomeIcon style={{ height: "20px" }} />
          <p
            style={{ fontSize: "small", fontFamily: "Montserrat, sans-serif" }}
          >
            Home
          </p>
        </div>

        <div
          className={[
            "sidebarIcon",
            isActive(PATHS.profile) ? "active" : "",
          ].join(" ")}
          onClick={profileClick}
        >
          <Person2Icon style={{ height: "20px" }} />
          <p
            style={{ fontSize: "small", fontFamily: "Montserrat, sans-serif" }}
          >
            My Profile
          </p>
        </div>
        <div className="sidebarIcon" onClick={handleToAlarmSettings}>
          <AccessAlarmsIcon style={{ height: "20px" }} />
          <p
            style={{ fontSize: "small", fontFamily: "Montserrat, sans-serif" }}
          >
            Alarm
          </p>
        </div>
      </div>
      {profile ? (
        <div className={styles.containerDashH}>
          <div className={styles.profileHeading}>
            <div
              style={{
                fontFamily: "Montserrat,  san-serif",
                //   fontSize: "15px",
              }}
            >
              PROFILE
            </div>
            <div className={styles.homebtn} onClick={homePage}>
              BACK TO HOMEPAGE <ExitToAppIcon />
            </div>
          </div>

          <div className={styles.contentDiv}>
            <div className={styles.imageDiv}>
              <div className={classes.container}>
                <Avatar
                  src={
                    selectedImage ||
                    `https://carehq-backend.aimed.care${proImage}` ||
                    "https://www.w3schools.com/howto/img_avatar.png"
                  }
                  style={{
                    width: "95px",
                    height: "95px",
                    border: "2px solid #6D3DA3",
                  }}
                />
                <label
                  htmlFor="icon-button-file"
                  className={classes.cameraIcon}
                >
                  <span style={{ fontSize: "15px" }}>
                    <PhotoCamera fontSize="inherit" />
                  </span>
                </label>
                <input
                  accept="image/png, image/jpeg, image/jpg"
                  className={classes.input}
                  id="icon-button-file"
                  type="file"
                  onChange={(event) => {
                    const file = event.target.files[0];
                    setSelectedFile(file);
                   var reader = new FileReader();
                   reader.readAsDataURL(file);
                   reader.onload = function () {
                     setSelectedImage(reader.result)
                   };
                   reader.onerror = function (error) {
                     console.log('Error: ', error);
                   };
                  }}
                />
              </div>
            </div>
            <div className={styles.detailsDiv}>
              {profile && (
                <div>
                  <div className={styles.infoDiv}>
                    <div className={styles.infos}>
                      <p className={styles.labels}>*Name</p>
                      <input
                        className={`${styles.fields} ${
                          !name && classes.mandatoryField
                        }`}
                        type="text"
                        value={name}
                        onChange={handleNameChange}
                        maxLength={30}
                      />
                    </div>
                    <div className={styles.infos}>
                      <p className={styles.labels}>*DOB</p>
                      <input
                        className={`${styles.fields} ${
                          !dob && classes.mandatoryField
                        }`}
                        type="date"
                        value={dob}
                        onChange={(event) => setDob(event.target.value)}
                        max={getCurrentDate()}
                      />
                    </div>
                  </div>
                  <div className={styles.infoDiv}>
                    <div className={styles.infos}>
                      <p className={styles.labels}>Contact No.</p>
                      <PhoneInput
                        id="phoneNumber"
                        country={countryCode}
                        value={contact}
                        onChange={(event) => setContact(event)}
                        inputStyle={{
                          width: "100%",
                          alignItems: "center",
                          fontSize: "small",
                          justifyContent: "center",
                          backgroundColor: "#f5f5f5",
                          border: "none",
                          color: "grey",
                          fontFamily: "Montserrat,  san-serif",
                        }}
                      />
                    </div>
                    <div className={styles.infos}>
                      <p className={styles.labels}>Email</p>
                      <input
                        className={styles.fields}
                        type="email"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                      />
                    </div>
                  </div>
                  <div className={styles.infoDiv}>
                    <div className={styles.infos}>
                      <p className={styles.labels}>*Height(cm)</p>
                      <input
                        className={`${styles.fields} ${
                          !height && classes.mandatoryField
                        }`}
                        type="text"
                        value={height}
                        onChange={(event) => setHeight(event.target.value)}
                      />
                    </div>
                    <div className={styles.infos}>
                      <p className={styles.labels}>*Weight(cm)</p>
                      <input
                        className={`${styles.fields} ${
                          !weight && classes.mandatoryField
                        }`}
                        type="text"
                        value={weight}
                        onChange={(event) => setWeight(event.target.value)}
                      />
                    </div>
                  </div>

                  <div className={styles.infoDivDis}>
                    <div className={styles.infosDisease}>
                      <label className={styles.labels}>Cancer</label>
                      <div className={styles.checkboxContainer}>
                        <div className={styles.checkboxContainer}>
                          <input
                            className={styles.checkbox}
                            type="radio"
                            id="cancer-yes"
                            value="yes"
                            checked={cancer} // Update the checked attribute to use the `cancer` state variable
                            onChange={(event) =>
                              setCancer(event.target.checked)
                            }
                          />
                          <label htmlFor="cancer-yes">Yes</label>
                        </div>
                        <div className={styles.checkboxContainer}>
                          <input
                            className={styles.checkbox}
                            type="radio"
                            id="cancer-no"
                            value="no"
                            checked={!cancer} // Update the checked attribute to use the negation of the `cancer` state variable
                            onChange={(event) =>
                              setCancer(!event.target.checked)
                            }
                          />
                          <label htmlFor="cancer-no">No</label>
                        </div>
                      </div>
                    </div>
                    <div className={styles.infosDisease}>
                      <label className={styles.labels}>Heart_Diseases</label>
                      <div className={styles.checkboxContainer}>
                        <div className={styles.checkboxContainer}>
                          <input
                            className={styles.checkbox}
                            type="radio"
                            id="HeartDiseases-yes"
                            checked={heart_disease} // Update the checked attribute to use the `cancer` state variable
                            onChange={(event) =>
                              setHeart_disease(event.target.checked)
                            }
                          />
                          <label htmlFor="HeartDiseases-yes">Yes</label>
                        </div>
                        <div className={styles.checkboxContainer}>
                          <input
                            className={styles.checkbox}
                            type="radio"
                            id="HeartDiseases-no"
                            checked={!heart_disease} // Update the checked attribute to use the `cancer` state variable
                            onChange={(event) =>
                              setHeart_disease(!event.target.checked)
                            }
                          />
                          <label htmlFor="HeartDiseases-no">No</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.infoDivDis}>
                    <div className={styles.infosDisease}>
                      <label className={styles.labels}>Cholestrol</label>
                      <div className={styles.checkboxContainer}>
                        <div className={styles.checkboxContainer}>
                          <input
                            className={styles.checkbox}
                            type="radio"
                            id="Cholestrol-yes"
                            checked={cholesterol} // Update the checked attribute to use the `cancer` state variable
                            onChange={(event) =>
                              setCholestrol(event.target.checked)
                            }
                          />
                          <label htmlFor="Cholestrol-yes">Yes</label>
                        </div>
                        <div className={styles.checkboxContainer}>
                          <input
                            className={styles.checkbox}
                            type="radio"
                            id="Cholestrol-no"
                            checked={!cholesterol} // Update the checked attribute to use the `cancer` state variable
                            onChange={(event) =>
                              setCholestrol(!event.target.checked)
                            }
                          />
                          <label htmlFor="Cholestrol-no">No</label>
                        </div>
                      </div>
                    </div>
                    <div className={styles.infosDisease}>
                      <label className={styles.labels}>Diabetics</label>
                      <div className={styles.checkboxContainer}>
                        <div className={styles.checkboxContainer}>
                          <input
                            className={styles.checkbox}
                            type="radio"
                            id="Diabetics-yes"
                            checked={diabetes} // Update the checked attribute to use the `cancer` state variable
                            onChange={(event) =>
                              setDiabetes(event.target.checked)
                            }
                          />
                          <label htmlFor="Diabetics-yes">Yes</label>
                        </div>
                        <div className={styles.checkboxContainer}>
                          <input
                            className={styles.checkbox}
                            type="radio"
                            id="Diabetics-no"
                            checked={!diabetes} // Update the checked attribute to use the `cancer` state variable
                            onChange={(event) =>
                              setDiabetes(!event.target.checked)
                            }
                          />
                          <label htmlFor="Diabetics-no">No</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.infoDivDis}>
                    <div className={styles.infosDisease}>
                      <label className={styles.labels}>Hypertension</label>
                      <div className={styles.checkboxContainer}>
                        <div className={styles.checkboxContainer}>
                          <input
                            className={styles.checkbox}
                            type="radio"
                            id="Hypertension-yes"
                            checked={hypertension} // Update the checked attribute to use the `cancer` state variable
                            onChange={(event) =>
                              setHypertension(event.target.checked)
                            }
                          />
                          <label htmlFor="Hypertension-yes">Yes</label>
                        </div>
                        <div className={styles.checkboxContainer}>
                          <input
                            className={styles.checkbox}
                            type="radio"
                            id="Hypertension-no"
                            checked={!hypertension} // Update the checked attribute to use the `cancer` state variable
                            onChange={(event) =>
                              setHypertension(!event.target.checked)
                            }
                          />
                          <label htmlFor="Hypertension-no">No</label>
                        </div>
                      </div>
                    </div>
                    <div className={styles.infosDisease} />
                  </div>

                  <div className={styles.infoDiv}>
                    <div className={styles.infos}>
                      <p className={styles.labels}>Add Medications</p>
                      <input
                        className={styles.fields}
                        type="text"
                        value={med}
                        onChange={(event) => setMed(event.target.value)}
                      />
                    </div>
                    <div className={styles.infos}>
                      <p className={styles.labels}>Select Your Doctor</p>
                      <select
                        className={styles.dropdown}
                        id="doctor"
                        value={selectedDoctor}
                        onChange={(e) => setSelectedDoctor(e.target.value)}
                      >
                        <option value="">Select Your Doctor</option>
                        {doctors?.doctor_list?.map((doctor) => (
                          <option
                            key={doctor?.doctor_id}
                            value={doctor?.doctor_id}
                          >
                            {doctor.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className={styles.infoDiv}>
                    <div className={styles.infosDoc}>
                      <label className={styles.labels}>
                        Are You a Patient?
                      </label>
                      <Switch
                        classes={{ checked: classes.muiSwitch }}
                        color="secondary"
                        checked={isPatient1}
                        onChange={handlePatientChange}
                      />
                    </div>
                  </div>
                  <div className={styles.infoDiv}>
                    <div className={styles.infosDoc}>
                      <label className={styles.labels}>Are You a Doctor?</label>
                      <Switch
                        classes={{ checked: classes.muiSwitch }}
                        color="secondary"
                        checked={isDoctor1}
                        onChange={handleDoctorChange}
                      />
                    </div>
                  </div>
                  {isDoctor1 && (
                    <div className={styles.infoDiv}>
                      <div className={styles.infos}>
                        <label className={styles.labels}>*Specialization</label>
                        <input
                          className={styles.fields}
                          type="text"
                          value={specialization}
                          onChange={(event) =>
                            setSpecialization(event.target.value)
                          }
                        />
                      </div>
                      <div className={styles.infos}>
                        <label className={styles.labels}>*Hospital</label>
                        <input
                          className={styles.fields}
                          type="text"
                          value={hospital}
                          onChange={(event) => setHospital(event.target.value)}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
              <div>
                <button
                  className={styles.saveChangesButton}
                  onClick={handleSaveChanges}
                >
                  Save Changes
                </button>
              </div>
              {isSnackbarVisible && (
                <div className={styles.snackbar}>
                  <span>{snackbarMessage}</span>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <p style={{ height: "50%" }}>
          <CircularProgress />
        </p>
      )}
    </div>
  );
};

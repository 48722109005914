import { createSlice } from "@reduxjs/toolkit";

const homeSlice = createSlice({
  name: "home",
  initialState: {
    bedList: [],
    bedDeviceList: [],
    deviceList: [],
    deviceTypeList: [],
    deviceSettings: [],
    deviceStreamTokens: [],
  },

  reducers: {
    addBedList(state, action) {
      state.bedList = action.payload;
    },
    addBedDeviceList(state, action) {
      state.bedDeviceList = action.payload;
    },
    addDeviceList(state, action) {
      state.deviceList = action.payload;
    },
    addDeviceTypeList(state, action) {
      state.deviceTypeList = action.payload;
    },
    addDeviceSettings(state, action) {
      state.deviceSettings = action.payload;
    },
    addDeviceStreamTokens(state, action) {
      state.deviceStreamTokens = action.payload;
    },
  },
});

export const {
  addBedList,
  addBedDeviceList,
  addDeviceList,
  addDeviceTypeList,
  addDeviceSettings,
  addDeviceStreamTokens,
} = homeSlice.actions;

export default homeSlice.reducer;

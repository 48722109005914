import * as React from "react";
import { Button, styled } from "@material-ui/core";

const ColorButton = styled(Button)(({ theme }) => ({
  color: "white",
  backgroundColor: "green",
  "&:hover": {
    backgroundColor: "green",
  },
}));

export default function GreenPrimaryButton(props) {
  return (
    <ColorButton {...props} fullWidth variant="contained">
      <span className="greenButtonLabel">{props.buttonlabel}</span>
    </ColorButton>
  );
}

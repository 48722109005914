import React, { useState, useEffect } from "react";
import styles from "./HeartRatePopup.module.css";
import { Tabs } from "react-bootstrap";
import { HeartRateContainer } from "./HeartRateContainer";
import Tab from "react-bootstrap/Tab";
import axios from "axios";
import constants from "../../../../Common/Constants";
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import { endOfDay, format } from "date-fns";
import { MoreHoriz } from "@material-ui/icons";
import { Snackbar } from "@mui/material";
import PopUpManualEntry from "../OtherComponents/PopUpManualEntry";
import FormHR from "../../../Member Details/Comps/FitbitFolder/FitCharts/FormHR";

const HeartRatePopup = () => {
  const [tabValue, setTabValue] = useState("week");
  const groupName = useSelector((state) => state.auth.groupName);
  const token = useSelector((state) => state.auth.authToken);
  const careTakerToken = useSelector((state) => state.auth.careTakerToken);
  const currentUserIndex = useSelector((state) => state.auth.currentUserIndex);
  const dateHR = format(endOfDay(new Date()), "ddMMyyy");
  const userData = useSelector((state) => state.auth.userData);
  const [open, setOpen] = React.useState(false);
  const location = useLocation();
  const urlStateData = location?.state;
  const { id, patient_group_id } = useParams();

  const viewOnly = location?.state || {};
  const {pat_id} = useParams();

  const handleChange = (value) => {
    setTabValue(value);
  };
  const [showPopup, setShowPopup] = useState(false);
  
  const openPopup = () => {
    if(viewOnly?.viewOnly == true){
      setShowPopup(false)
    }else{
    setShowPopup(true);
  }
  };

  const closePopup = (event) => {
    event.stopPropagation();
    setShowPopup(false);
  };
  const [dayData, setDayData] = useState ({ xData:[], yData:[]});
  const [monthData, setMonthData] = useState({ xData: [], yData: [] });
  const [weekData, setWeekData] = useState({ xData: [], yData: [] });
  const [threeMonthData, setThreeMonthData] = useState({
    xData: [],
    yData: [],
  });
  const [response, setResponse] = useState(null); 

  const deleteLastEntry = () => {
    axios.get(`${constants.API_URL}/delete_latest_manual_entry/${viewOnly?.viewOnly? pat_id : urlStateData?.patientView? patient_group_id:groupName}/PUL/${token}/`).then((res) => {
      if (res.data.message === "success") {
        setResponse('success');
        setOpen(true);
        refreshChartData();
         // Update the weekData state to reflect the changes
         const updatedWeekData = { ...weekData };
         updatedWeekData.xData.pop(); // Remove the last entry from xData
         updatedWeekData.yData.pop(); // Remove the last entry from yData
         setWeekData(updatedWeekData);
         // Update the monthData state to reflect the changes
         const updatedMonthData = { ...monthData };
         updatedMonthData.xData.pop(); // Remove the last entry from xData
         updatedMonthData.yData.pop(); // Remove the last entry from yData
         setMonthData(updatedMonthData);
         // Update the threeMonthData state to reflect the changes
         const updatedThreeMonthData = { ...threeMonthData };
         updatedThreeMonthData.xData.pop(); // Remove the last entry from xData
         updatedThreeMonthData.yData.pop(); // Remove the last entry from yData
         setThreeMonthData(updatedThreeMonthData);
      } else if (res.data.message === "No data found") {
        setResponse('noData');
        setOpen(true);
      } else {
        setResponse('error');
        setOpen(true);
      }
    
    })
    .catch((error) => {
      setResponse('error');
      setOpen(true);

    });
  };
  
  const handleClose = () => {
    setOpen(false);
  };

  const refreshChartData = () => {

    console.log("Refreshing Chart Data") ;

    setDayData({xData:[], yData:[]});
    setMonthData({xData:[], yData:[]});
    setThreeMonthData({xData:[], yData:[]});
    setWeekData({xData:[], yData:[]});

    setTimeout(() => {
      axios
      .get(`${constants.API_URL}/day_data/HR/${viewOnly?.viewOnly ? pat_id : urlStateData?.patientView ? patient_group_id : groupName}/${dateHR}/${token}/`)
      .then(res => {

        let x = []
        let y = []
        res.data.day_data?.forEach(data => {
          x.push(data.x_data)
          y.push(data.y_data)
        });

        setDayData({ xData: x, yData: y })
      })
    }, 6000)
    

    setTimeout(() => {
    axios
      .get(`${constants.API_URL}/week_data/HR/${viewOnly?.viewOnly ? pat_id : urlStateData?.patientView ? patient_group_id : groupName}/${dateHR}/${token}/`)
      .then(res => {

        let x = []
        let y = []
        res.data.week_data?.forEach(data => {
          x.push(data.x_data)
          y.push(data.y_data)
        });

        setWeekData({ xData: x, yData: y })
      })
    }, 6000)

      setTimeout(() => {
    axios
      .get(`${constants.API_URL}/month_data/HR/${viewOnly?.viewOnly ? pat_id : urlStateData?.patientView ? patient_group_id : groupName}/${dateHR}/${token}/`)
      .then(res => {

        let x = []
        let y = []
        res.data.month_data?.forEach(data => {
          x.push(data.x_data)
          y.push(data.y_data)
        });

        setMonthData({ xData: x, yData: y })
      })
    }, 6000)

      setTimeout(() => {
    axios
      .get(`${constants.API_URL}/three_month_data/HR/${viewOnly?.viewOnly ? pat_id : urlStateData?.patientView ? patient_group_id : groupName}/${dateHR}/${token}/`)
      .then(res => {

        let x = []
        let y = []
        res.data.three_month_data?.forEach(data => {
          x.push(data.x_data)
          y.push(data.y_data)
        });

        setThreeMonthData({ xData: x, yData: y })
      })
    }, 6000);

    };
  

  useEffect(() =>{
    if(tabValue === "day" &&!(dayData?.xData?.length)){
      axios
        .get(`${constants.API_URL}/day_data/HR/${viewOnly?.viewOnly? pat_id : urlStateData?.patientView? patient_group_id:groupName}/${dateHR}/${token}/`)
        .then(res => {
         
          let x= []
          let y = []
          res.data.day_data?.forEach(data => {
            x.push(data.x_data)
            y.push(data.y_data)
          });
       
          
          setDayData({xData:x,yData:y})
        
        })

    }
    else if(tabValue === "week" &&!(weekData?.xData?.length)){
      axios
        .get(`${constants.API_URL}/week_data/HR/${viewOnly?.viewOnly? pat_id : urlStateData?.patientView? patient_group_id:groupName}/${dateHR}/${token}/`)
        .then(res => {
         
          let x= []
          let y = []
          res.data.week_data?.forEach(data => {
            x.push(data.x_data)
            y.push(data.y_data)
          });
       
          
          setWeekData({xData:x,yData:y})
        
        })

    }
    else if(tabValue === "month" &&!(monthData?.xData?.length)){
      axios
      .get(`${constants.API_URL}/month_data/HR/${viewOnly?.viewOnly? pat_id : urlStateData?.patientView? patient_group_id:groupName}/${dateHR}/${token}/`)
      .then(res => {
       
          let x= []
          let y = []
          res.data.month_data?.forEach(data => {
            x.push(data.x_data)
            y.push(data.y_data)
          });
         

          setMonthData({xData:x,yData:y})
      
      })
    }
    else if(tabValue === "threeMonth" &&!(threeMonthData?.xData?.length)){
        axios
      .get(`${constants.API_URL}/three_month_data/HR/${viewOnly?.viewOnly? pat_id : urlStateData?.patientView? patient_group_id:groupName}/${dateHR}/${token}/`)
      .then(res => {
       
        let x= []
        let y = []
        res.data.three_month_data?.forEach(data => {
          x.push(data.x_data)
          y.push(data.y_data)
        });
    

        setThreeMonthData({xData:x,yData:y})
      
      })

    }
  },[tabValue, refreshChartData]);

  
  return (
    <div className={styles.mainContainer}>
      <div className={styles.heading}>
        <div>Heart Rate</div>
        <div style={{ color: "grey", cursor: "pointer" }} >
          <MoreHoriz onClick={viewOnly == true? "" : openPopup}/>
         
        </div>
      </div>
      <div className={styles.contentDiv}>
        <div className={styles.tabContainer}>
          <div
            className={`${styles.tab} ${
              tabValue === "day" && styles.activeTab
            }`}
            onClick={() => handleChange("day")}
          >
            DAY
          </div>
          <div
            className={`${styles.tab} ${
              tabValue === "week" && styles.activeTab
            }`}
            onClick={() => handleChange("week")}
          >
            WEEK
          </div>
          <div
            className={`${styles.tab} ${
              tabValue === "month" && styles.activeTab
            }`}
            onClick={() => handleChange("month")}
          >
            MONTH
          </div>
          <div
            className={`${styles.tab} ${
              tabValue === "threeMonth" && styles.activeTab
            }`}
            onClick={() => handleChange("threeMonth")}
          >
            3 MONTHS
          </div>
        </div>
        <div className={styles.tabContent}>
        {tabValue === "day" && (
            <HeartRateContainer
              title="Daily Report"
              xData={dayData?.xData}
              yData={dayData?.yData}
              type="day"
            />
          )}
          {tabValue === "week" && (
            <HeartRateContainer
              title="Weekly Report"
              xData={weekData?.xData}
              yData={weekData?.yData}
              type="week"
            />
          )}
          {tabValue === "month" && (
            <HeartRateContainer
              title="Monthly Report"
              xData={monthData?.xData}
              yData={monthData?.yData}
              type="month"
            />
          )}
          {tabValue === "threeMonth" && (
            <HeartRateContainer
              title="3 Months Report"
              xData={threeMonthData?.xData}
              yData={threeMonthData?.yData}
              type="threeMonth"
            />
          )}
        </div>
      </div>
      {showPopup && (
        <div className={styles.popup}>
          <div className={styles.popupOption1}>
            <PopUpManualEntry
              optionName={"Manual Entry"}
              FormTitle={"Heart Rate"}
              FormType={<FormHR refreshChartData={refreshChartData} style={{ zIndex: "9999" }} />}
            />
          </div>
          <div className={styles.popupOption} onClick={deleteLastEntry}>
            Delete Last Entry
          </div>
          <div className={styles.popupOption} onClick={(event) => closePopup(event)}>
            Cancel
          </div>
        </div>
      )}
     <Snackbar
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
      message={
        response === "success" ? "Successfully deleted the last entry!" : "No recent data found!"
      }
    />
    </div>
  );
};

export default HeartRatePopup;

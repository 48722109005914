// import React, { useEffect } from 'react';
// import ReactEcharts from "echarts-for-react";

// function TimeSeriesExample() {

//     const data = [
//         { "timestamp": "2023-10-01 08:00:00", "systolic": 120, "diastolic": 80 },
//         {"timestamp": "2023-10-01 08:30:00", "systolic": 190, "diastolic": 80 },
//         { "timestamp": "2023-10-01 12:00:00", "systolic": 130, "diastolic": 85 },
//         { "timestamp": "2023-10-01 16:00:00", "systolic": 125, "diastolic": 82 },
//         // Add more data points...
//       ]

//       useEffect (() => {
//         let url= "${constants.API_URL}/month_data/BG/101/10102023/e2edc9d1-e9e1-4e55-8992-4e9a3bbacedc/" ;
//         axios.get(url).then((res) => {});

//       },[]);
      
//       const getSystolicData = () => {
//         return data.map((item) => ({
//           name: item.timestamp,
//           value: [item.timestamp, item.systolic],
//         }));
//       };
     

//       const getDiastolicData = () => {
//         return data.map((item) => ({
//           name: item.timestamp,
//           value: [item.timestamp, item.diastolic],
//         }));
//       };
    
//       const getOption = () => {
//         return {
//           title: {
//             text: 'Blood Pressure Over Time',
//           },
//           tooltip: {
//             trigger: 'axis',
//             axisPointer: {
//               animation: false,
//             },
//             formatter: function (params) {
//               const systolic = params.find((param) => param.seriesName === 'Systolic');
//               const diastolic = params.find((param) => param.seriesName === 'Diastolic');
//               const date = new Date(systolic.name);
//               return `${date.toLocaleString()}:<br />Systolic: ${systolic.value[1]}<br />Diastolic: ${diastolic.value[1]}`;
//             },
//           },
//           xAxis: {
//             type: 'time',
//           },
//           yAxis: {
//             type: 'value',
//             min: 0,
//             max: 200, // Adjust the maximum based on your data
//             interval: 20,
//           },
//           series:
//           //  [
//             {
//               data: getSystolicData(),
//               type: 'scatter', // Use 'scatter' type to plot individual points
//               name: 'Systolic',
//               symbolSize: 8, // Adjust the size of the plotted points
//             }
//             // {
//             //   data: getDiastolicData(),
//             //   type: 'scatter', // Use 'scatter' type to plot individual points
//             //   name: 'Diastolic',
//             //   symbolSize: 8, // Adjust the size of the plotted points
//             // },
//           // ],
//         };
//       };

//       return (
//         <ReactEcharts
//           option={getOption()}
//           notMerge={true}
//           lazyUpdate={true}
//           style={{ height: '400px', width: '100%' }}
//         />
//       );
//     }    

// export default TimeSeriesExample;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ReactEcharts from "echarts-for-react";
import { useSelector } from 'react-redux';
import * as echarts from 'echarts';
import constants from "../../../../Common/Constants";


function TimeSeriesExample() {

  const [apiData, setApiData] = useState([]); // State to hold the API data
  const token = useSelector((state) => state.auth.authToken);
  const groupName = useSelector((state) => state.auth.groupName);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${constants.API_URL}/three_month_data/BG/${groupName}/10102023/${token}/`
        );
        setApiData(response.data.three_month_data); // Assuming "month_data" is the key for the array in the API response
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); // Call the fetchData function to fetch API data
  }, []);

  const getSystolicData = () => {
    return apiData?.map((item) => ({
      name: `${new Date(item.x_data).toLocaleDateString()} \n ${new Date(item.x_data).toLocaleTimeString()}`,
      value: [item.x_data, item.y_data.BG],
    }));
  };
  const gluces = getSystolicData();
  console.log(gluces);
  
  const getOption = () => {
    return {
      title: {
        text: 'Blood Glucose Over Time',
      },
      tooltip: {
        textStyle: {
          fontFamily: "sans-serif",
        },
        trigger: 'axis',
        axisPointer: {
          // animation: false,
          type:"shadow",
        },
        formatter: function (params) {
          const systolic = params.find((param) => param.seriesName === 'Systolic');
          const date = new Date(systolic.name);
          return `${date.toLocaleString()}:<br />Systolic: ${systolic.value[1]}`;
        },
      },
      dataZoom: [
        {
          type: 'inside',
          start: 0,
          end: 100
        },
        {
          start: 10,
          end: 0
        }
      ],
      grid: { // Add grid property to set the margin
        top: "8%", // Adjust the bottom margin as needed
        containLabel: true, // Include axis labels and title inside the grid
      },
      xAxis: {
        type: 'time',
        axisLabel: {
          fontFamily: "sans-serif",
        },
      },
      yAxis: {
        type: 'value',
        min: 0,
        max: 650, // Adjust the maximum based on your data
        interval: 60,
        axisLabel: {
          fontSize: 10,
          fontFamily: "'Montserrat', sans-serif",
         fontWeight:500
      },
      splitLine: {
          show: false
        },
      },
      series: [
        {
          data: getSystolicData(),
          type: 'line',
          name: 'Systolic',
          smooth: true,
          symbolSize: 0,
          areaStyle: {
            opacity:0.7 ,
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 1,
                color: 'white'
              },
              {
                offset: 0,
                color: '#7F55AE'
              }
            ])
          },
        },
      ],
    };
  };

  return (
    <ReactEcharts
      option={getOption()}
      notMerge={true}
      lazyUpdate={true}
      style={{ height: '400px', width: '100%' }}
    />
  );
}

export default TimeSeriesExample;


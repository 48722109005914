import React,{memo} from 'react'
import ReactEcharts from 'echarts-for-react';
import '../../FitCharts/FNewDash.css'
import axios from 'axios';

function SPO2ChartPage2({title,xData,yData,handleClick}) {

  
    if(!handleClick)
    {

     
    }

  const chartData =React.useMemo(()=>{
    return {
    
            title: {
                text:"SpO2 Average %",
                    left: "center",
                    top: 25,
                    textStyle: {
                      fontFamily: "sans-serif"
                    }
                
                  },
                  grid:{
                    bottom:20
                  },
                  tooltip: {
                    textStyle: {
                      fontFamily: "sans-serif"
                    }
                  },
                 
            
                  series: [
                    {
                      textStyle: {
                        fontFamily: "sans-serif"
                      },
                      color:"#00B5C6",
                      type: "gauge",
                      guageCategoryGap: "20%",
                      startAngle: 360,
                      endAngle: 0,
                      min: false,
                      max: 1,
                      splitNumber: 12,
                      itemStyle: {
                        color: "",
                        shadowColor: "blue",
                        shadowBlur: 0,
                        shadowOffsetX: 0,
                        shadowOffsetY: 0,
                      },
                      progress: {
                        show: true,
                        roundCap: true,
                        width: 10,
                      },
                      pointer: {
                        icon: false,
                        length: "0%",
                        width: 0,
                        offsetCenter: [0, "0%"],
                      },
                      axisLine: {
                        roundCap: false,
                        show:false,
                        lineStyle: {
                          width: -10,
                        }
                      },
                      axisTick: {
                        splitNumber: false,
                        color: "white",
                      },
                      axisLabel: {
                        distance: 30,
                        color: "white",
                        fontSize: 0,
                      },
                      
                      detail: {
                        // backgroundColor: "#fff",
                        lineHeight: 0,
                        height: 0,
                        borderRadius: 8,
                        offsetCenter: [0, "0%"],
                        valueAnimation: true,
                        formatter: function (value) {
                          return "{value|" + value.toFixed(0) + "}{unit|%}";
                        },
                        rich: {
                          value: {
                            fontSize: 50,
                            fontWeight: "bolder",
                            color: "#777",
                            fontFamily: "sans-serif"
                          },
                          unit: {
                            fontSize: 20,
                            color: "#999",
                            padding: [0, 0, -15, 0],
                          },
                        },
                      },
               
                data: [yData]
            }
        ]}
    },[xData,yData,title])

// const [status, setStatus] = React.useState(false)
// const tokenStr = "eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIyMzhKVDMiLCJzdWIiOiJCNEpWS1kiLCJpc3MiOiJGaXRiaXQiLCJ0eXAiOiJhY2Nlc3NfdG9rZW4iLCJzY29wZXMiOiJ3aHIgd251dCB3cHJvIHdzbGUgd3NvYyB3YWN0IHdveHkgd3RlbSB3d2VpIHdzZXQgd2xvYyB3cmVzIiwiZXhwIjoxNjYzNzQ0MDcxLCJpYXQiOjE2NjMxMzkyNzF9.7Pz6O94kCPNXSq_ZenSoqM1yqrsxeXHJiBdh1PaSYHY"​
return ( 
    <div>
    {chartData.series[0].data.length?
    <div><div className='nameBP'  style={{fontFamily: "sans-serif"}}>{title}</div>
  <ReactEcharts option={chartData}  onEvents={{
          'click': handleClick,
        }}/> 
  
  </div>
   : <p style={{fontSize:"20px", fontWeight:"bold"}}>NO DATA AVAILABLE </p>
   }
  </div>
    )
    }

export default memo(SPO2ChartPage2)
import React, { useState } from "react";
import ReactEcharts from "echarts-for-react";
import { useSelector } from "react-redux";
import { useEffect } from "react";




export default function HRPieChart() {
  
  const [data, setData] = useState({
    "Normal-HR": [],
    "Low-Medium": [],
    "High-Medium": [],
    "Low-HR": [],
    "High-HR": [],
  });

  const res = useSelector((state) => state.dashboard.analyticsRes);

  useEffect(() => {
   if(Object.keys(res)?.length){
      
        let medHR = res?.data?.pie_chart_data?.normal_hr;
        let highMedHR = res?.data?.pie_chart_data?.medium_high_hr;
        let lowMedHR = res?.data?.pie_chart_data?.low_medium_hr;
        let lowHR = res?.data?.pie_chart_data?.low_hr;
        let highHR = res?.data?.pie_chart_data?.high_hr;
        
        setData({
          "Normal-HR": medHR,
          "Low-HR": lowHR,
          "High-HR": highHR,
          "Low-Medium": lowMedHR,
          "High-Medium": highMedHR,
        });
   }
        
     
  }, [res]);
  

  const colorsDifiner = (type) => {
    let color;
    switch (type) {
      case "Normal-HR":
        color = "#00B5C6";
        break;
      case "Low-HR":
        color = "#FF407B";
        break;
      case "Low-Medium":
        color = "#FFD700";
        break;
      case 'High-Medium':
        color = '#6D3DA3'
        break;
        case 'High-HR':
          color = '#28B779'
          break;
    }
    return color;
  };
  const derivedData = () => {
    let a = [];
    Object.keys(data).forEach((key) => {
      if (data[key] != 0) {
        a.push({ value: Math.round(data?.[key]), name: key, 
          itemStyle:{color: colorsDifiner(key)} 
        });
      }
    });
    return a;
  };
 

  const option = {
    title: {
      textStyle: {
        fontFamily: "sans-serif",
        fontSize: 17,
      },
      text: "Today's Heart Rate Composition",
    
      left: "center",
    },
    tooltip: {
      trigger: "item",
    },
    legend: {
      orient: 'horizontal',
      left: 'center',
      bottom:5
    },
    
    series: [
      {
       
        type: "pie",
        radius: "65%",
        label: {
          formatter: "{c}%",
          position: "inside",
        },
        data: derivedData(),
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };

  if (!option?.series[0]?.data[0]?.value) {
    return null;
  }
  return (
    <div>
      {" "}
      <ReactEcharts option={option} />
    </div>
  );
}

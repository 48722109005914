import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import authReducer from "./authSlice";
import settingsReducer from "./settingsSlice";
import statsReducer from "./statsSlice";
import homeReducer from "./homeSlice";
import dashboardReducer from "./dashboardSlice";

const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["auth", "stats", "settings", "home","dashboard"],
};

const rootReducer = combineReducers({
  auth: authReducer,
  settings: settingsReducer,
  stats: statsReducer,
  home: homeReducer,
  dashboard: dashboardReducer
});

export default persistReducer(persistConfig, rootReducer);

import React, { useEffect, useState } from 'react'
import ExpandMorePopup from '../../../../Common/ExpandMorePopup'
import './../Commonv2.css'
import * as echarts from 'echarts';
import GlucoseChartDaily from '../../../Member Details/Comps/FitbitFolder/FitCharts/GlucoseChartDaily';
import ReactEcharts from "echarts-for-react";
import { useSelector } from 'react-redux';
import OptionPopUp from '../OtherComponents/OptionPopUp';
import PopupBG from '../../../Member Details/Comps/FitbitFolder/FitCharts/PopBG';
import FormBG from '../../../Member Details/Comps/FitbitFolder/FitCharts/FormBG';
import FormBP from '../../../Member Details/Comps/FitbitFolder/FitCharts/FormBP';
import PopUpManualEntry from '../OtherComponents/PopUpManualEntry';
import MainCommonCard from './MainCommonCard';
import BGIcon from './Icons/GlucoseIcon.svg'
import TileRemove from '../Featuresv2/TileRemove';

function BPv2({onClick}) {

  const [isExpired, setIsExpired] = useState(false);

  const glucoseDaily = useSelector((state) => state.dashboard.glucoseDaily);
  let BGDaily = glucoseDaily?.data?.y_data?.BG?.toFixed(1) ;

  const alarmStatus= glucoseDaily?.data?.y_data?.status_point

  const timeGapIndication = glucoseDaily?.data?.x_data?.time_gap


  useEffect(() => {
    // Check if the time gap contains the words "day" or "days"
    if (timeGapIndication?.includes("day") || timeGapIndication?.includes("days")) {
      setIsExpired(true);
    }
    else{
      setIsExpired(false);
    }
  }, [timeGapIndication, glucoseDaily]);

  let x = [];
  let y = [];
  glucoseDaily?.data?.y_data?.BG_list?.forEach((data) => {
   
    var epochTS = data.x_data;
    var d = new Date(parseInt(epochTS));
   
    let dsnow = `${d.toLocaleTimeString()}`;
  
    x.push(dsnow);
    y.push((data.y_data.BG)?.toFixed(1));
  });

    const chartDataBG = React.useMemo(() => {
        return {
            responsive: true,
        color: ['#80FFA5', '#00DDFF', '#37A2FF', '#FF0087', '#FFBF00'],
        
        tooltip: {
                  color:"#F64D60",
                  trigger: 'axis',
                  color:"red",
                  textStyle: {
                    fontFamily: 'sans-serif' // set tooltip font family to Arial
                  }
                },
        
        grid: {
          left: '5%',
          right: '8%',
          bottom: '0%',
          top:"5%",
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            show:false,
            splitLine: {
                show: false
              },
            data: x
          }
        ],
        yAxis: [
          {
            type: 'value',
            // show:false,
            axisLabel: {
                fontSize: 10,
                fontFamily: "'Montserrat', sans-serif",
               fontWeight:500
            },
            splitLine: {
                show: false
              },
          }
        ],
        series: [
          {
            name: 'BG',
            type: 'line',
            smooth: true,
            lineStyle: {
              width:1.75,
              color: '#7F55AE',
            }, 
            showSymbol: true,
            areaStyle: {
              opacity:0.3 ,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 1,
                  color: 'white'
                },
                {
                  offset: 0,
                  color: '#7F55AE'
                }
              ])
            },
            emphasis: {
              focus: 'series'
            },
            itemStyle: { // set the color of the point
              color: '#7F55AE'
            },
            data: y
          },
        ]
      }
}, [glucoseDaily]);

const BGSingleValueChart = (
  <MainCommonCard
  tileTitle={"Blood Glucose"}
  tileColor={isExpired? "transparent" : "white"}
  alarmStatusBorder={alarmStatus}
  alarmLabel={glucoseDaily?.data?.y_data?.status}
  timeGap={glucoseDaily?.data?.x_data?.time_gap + " ago"}
  onClick={onClick}
  Option1={
    <PopUpManualEntry
      optionName="MANUAL ENTRY"
      FormTitle="Blood Glucose"
      FormType={<FormBG />} />
    }
    Option2={<TileRemove option2title={"REMOVE TILE"} parameter={"BG"}/> }

  renderComponent={
    <div style={{ display: "flex", alignItems: "center", justifyContent: "center",cursor:"pointer" }}>
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
        <div>
          <div style={{ height: "100%", width: "100%", backgroundColor: "rgb(225, 239, 250)", display: "flex", alignItems: "center", justifyContent: "center", padding: "18px", borderRadius: "50%" }}>
            <img src={BGIcon} alt="icon" style={{ height: "40px", width: "40px", color:"#7F55AE" }} />
          </div>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", fontFamily: "'Montserrat', sans-serif" }}>
            <div style={{ fontFamily: "'Montserrat', sans-serif", fontSize: "1.8rem", fontWeight: "600" }}>{BGDaily? BGDaily:""}</div>
            <div style={{ fontFamily: "'Montserrat', sans-serif", fontSize: "0.8rem", fontWeight: "500", marginTop: "-5px" }}>mg/DL</div>
          </div>
        </div> </div>
    </div>
}>

  </MainCommonCard>
)

// const CMGGrpahChart = (
//     <MainCommonCard
//     tileTitle={"Blood Glucose"}
//     alarmStatusBorder={alarmStatus}
//     alarmLabel={glucoseDaily?.data?.y_data?.status}
//     timeGap={glucoseDaily?.data?.x_data?.time_gap + " ago"}
//     renderComponent={
//       <div >
//         <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", fontFamily: "'Montserrat', sans-serif" }}>
//           <div style={{ fontFamily: "'Montserrat', sans-serif", fontSize: "1.8rem", fontWeight: "600" }}>{BGDaily}</div>
//           <div style={{ fontFamily: "'Montserrat', sans-serif", fontSize: "0.8rem", fontWeight: "500", marginTop: "-5px" }}>mg/DL</div>
//         </div>
       
//           <ReactEcharts option={chartDataBG} style={{ height: "120px" }} />{" "}
       
//       </div>
//   }>
//     </MainCommonCard>
// )


if (!BGDaily)
return null

if (!(glucoseDaily?.data?.y_data?.BG_list))
return BGSingleValueChart

  return (
    <div className={"BPv2-main"+(' alarm-status-'+alarmStatus)}  style={{backgroundColor: isExpired? "transparent" :"white", minHeight:"200px",maxWidth:"100%"}}>
     <div style={{display:"flex", flexDirection:"column"}}>
        <div style={{display:"flex",alignItems:"center", justifyContent:"space-between",marginLeft:"10px", marginRight:"2px",marginTop:"10px,", height:"100%"}}>
            <div style={{fontSize:"1.2rem", fontFamily:"'Montserrat', sans-serif", fontWeight:"500"}}>Blood Glucose</div>
            <div> <OptionPopUp option1={<PopUpManualEntry 
          
          optionName="MANUAL ENTRY" 
          FormTitle="Blood Glucose" 
          FormType={<FormBG/>}/>}
          option2={<TileRemove option2title={"REMOVE TILE"} parameter={"BG"}/> } /></div>
        </div>

        <div onClick={onClick}>
        <div style={{ display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center",fontFamily:"'Montserrat', sans-serif"}}>
            <div style={{fontFamily:"'Montserrat', sans-serif",fontSize:"1.8rem",fontWeight:"600" }}>{BGDaily? BGDaily:""}</div>
            <div style={{fontFamily:"'Montserrat', sans-serif",fontSize:"0.8rem",fontWeight:"500", marginTop:"-5px"}}>mg/DL</div>
        </div>

        <div style={{width:"100%", height:"100%"}}>

        <ReactEcharts option={chartDataBG} style={{height:"120px"}}
        //  style={chartData?.series[0]?.data?.length? ({}):({display:'none'})}
         />{" "}

        </div>
        </div>
        <div style={{display:"flex", justifyContent:"space-between",alignItems:"self-end", marginLeft:"7px",marginRight:"7px",marginTop:"0px", fontSize:"0.75rem", fontFamily:"'Montserrat', sans-serif"}}>
        <div style={{fontFamily:"'Montserrat', sans-serif"}}>
          {glucoseDaily?.data?.y_data?.status}
        </div>
        <div style={{fontFamily:"'Montserrat', sans-serif"}}>
          {glucoseDaily?.data?.x_data?.time_gap + " ago"}
        </div>

       </div>
     </div>
    </div>
  )
}

export default BPv2
import React from 'react'
import { useSelector } from 'react-redux';
import ReactEcharts from 'echarts-for-react';
import { useEffect } from 'react';

function RestingHR() {
    const analyticsRes = useSelector((state) => state.dashboard.analyticsRes);
   
  
    let x = [];
    let y = [];
    analyticsRes?.data?.cardio_resting_hr?.forEach(element => {
      x.push(element?.x_data)
     y.push(element?.resting_hr)
    });

    const option = {
        title: {
            text: 'Resting Heart Rate',
            left: 'center',
            textStyle:{
              fontSize: 17,
            }
          },
          legend: {
            orient: 'horizontal',
            right: 5,
            top: 28,
            left: 'center',
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
        xAxis: {
          type: 'category',
          data:x,
        },
        yAxis: {
           splitLine: {
            show: false
          },
          type: 'value'
        },
        series: [
          {
            color:"#FF407B",
            name: 'Resting Heart Rate',
            data: y,
            type: 'line'
          }
        ]
      };
      if (!option?.series[0]?.data[0]) {
        return null;
      }
  return (
    <div>  <ReactEcharts option={option} /> </div>
  )
}

export default RestingHR
import React, { useState } from "react";
import { Fab } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/core/styles";
import AddMedModal from "./AddMedModal";
import { useHistory, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  floatingButton: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 999,
    backgroundColor: "#8E9EFF",
  },
}));

function MedicationFloatingButton({  fetchData }) {
  const classes = useStyles();
  const [modalOpen, setModalOpen] = useState(false);

  
  const location = useLocation();
  const viewablityConstant = location?.state;

  const handleButtonClick = () => {
    if(viewablityConstant?.viewMode == true){
      setModalOpen(false);
    }
    else{
      setModalOpen(true);
    }
    
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };


  return (
    <div>
      <Fab
        className={classes.floatingButton}
        color="primary"
        onClick={handleButtonClick}
      >
        <AddIcon />
      </Fab>
      <AddMedModal
        open={modalOpen}
        onClose={handleCloseModal}
       fetchData={fetchData}
        // setNotification={setNotification}
      />
    </div>
  );
}

export default MedicationFloatingButton;

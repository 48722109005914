import React, { useEffect } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import InputAdornment from "@material-ui/core/InputAdornment";
import PermIdentityOutlinedIcon from "@material-ui/icons/PermIdentityOutlined";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  addAuthToken,
  addDeviceList,
  addGroupName,
  addGroupDevices,
  addHeaderGroupName,
  addPageDevices,
  addPageDeviceFavorite,
  addNoOfPages,
  addPresentPage,
  setCareTaker,
  setUserData,
  setIsDoctor,
  setIsPatient,
  setPatientList,
  setCareTakerToken,
  addCureGptToken,
} from "../../../../../store/authSlice";
import {
  addNoOfStatsPages,
  addStatsPresentPage,
  addStatsPageDevices,
} from "../../../../../store/statsSlice";
import {
  addBedDeviceList,
  addBedList,
  addDeviceSettings,
  addDeviceStreamTokens,
  addDeviceTypeList,
} from "../../../../../store/homeSlice";
import { withStyles } from "@material-ui/core/styles";
// import AquaPrimaryButton from "../../Common/AquaPrimaryButton";
import constants from "../../../../../Common/Constants";
// import {
//   setDeviceNames,
//   setDeviceTypeTabs,
//   setSelectedBed,
//   setSelectedDeviceType,
// } from "../../store/settingsSlice";
import resetReduxState from "../../../../../Common/Logout";
import { Button, Divider } from "@material-ui/core";
import { after } from "lodash";
// import BackgroundImage from "./homebg.jpg";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import "./Loginv2.css";

import {gapi} from 'gapi-script';
import GLogin from "../../../../home/V2Components/SSO/GLogin";
import { Grid, Typography } from "@mui/material";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faMobile, faMobileAndroid, faMobilePhone} from '@fortawesome/free-solid-svg-icons';
// import ErrorModal from "../home/V2Components/SSO/Components/ErrorModal";
import LoginImage from './BannerImg..png'


const client_id = "1042626051183-efk02e24ggt4v8rlu981e4v24i1hpss5.apps.googleusercontent.com"


const WhiteRadioButton = withStyles({
  root: {
    color: "white",
    "&$checked": {
      color: "white",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // backgroundColor:"rgba(255, 255, 255, 0.1)",
    padding:"5px 20px 5px 20px",
    borderRadius:"15px",
    // backdropFilter: "blur(30px)"
    // width:"fit-content"

  },

  form: {
    width: "90%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },

  submit: {
    margin: "12px",
  },
  container: {
    width: "100%",
    height: "100vh",
    // backgroundColor: "white",
    // background: `url(${BackgroundImage})`,
    background: "linear-gradient(to right, #49ACA9, #ffff)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    backgroundSize: "cover",
    // backgroundPosition: "center",
    position: "relative",
  },
  blurBackground: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backdropFilter: "blur(6px)",
  },

  textField: {
    // borderBottom: "1px solid black",
    color: "black !important",
    borderRadius: "0px",
    margin: "12px 0",
  },

  formLabel: {
    marginLeft: "0",
    marginRight: "12px",
  },

  loginbtn: {
    backgroundColor: "#6D3DA3 !important",
    borderRadius: "20px",
    height: "20px",
    color: "white",
    fontSize: "12px",
    alignItems: "center",
  },

  signInbtn: {
    backgroundColor: "#6D3DA3 !important",
    borderRadius: "20px",
    height: "30px",
    width: "100%",
    color: "white",
    fontSize: "12px",
    alignItems: "center",
  },

  version: {
    color: "black",
    clear: "both",
    position: "relative",
  },
}));

export default function Loginv2({
  setConnectFlag,
  setConnectReplayFlag,
  closeCreatedSockets,
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const [groupName, setGroupName] = useState("101");
  const [password, setPassword] = useState("passme123");
  const [path, setPath] = useState("home");
  const [errorMessage, setErrorMessage] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleSignIn = () => {
    history.push("/patient_signup_page");
  };

  const handleForgotPassword = () => {
    history.push("/forgot_password");
  }

  const handleMobileClick =() =>{
    history.push("/mobile_login_page")
  }

  const handleLoginSubmit = (e) => {
    e.preventDefault();
    let data = { id: groupName, password: password };
    axios({
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      url: `${constants.API_URL}/authenticate_user/`,
      data: data,
    }).then(
      (response) => {
        console.log("SignIn Response",response);
        setErrorMessage(false);
        const userData = [];

          if(response?.status === 200){
            userData.push(response.data);
            console.log(userData);
            dispatch(addGroupName(userData?.[0]?.id));
            // setConnectFlag(false);
            // setConnectReplayFlag(false);
            dispatch(setUserData(userData));
            dispatch(addAuthToken(userData?.[0]?.token));
            dispatch(addHeaderGroupName(userData?.[0]?.group_name));
            dispatch(setIsDoctor(userData?.[0]?.is_doctor));
            dispatch(setIsPatient(userData?.[0]?.is_patient));
            dispatch(addCureGptToken(userData?.[0]?.cure_id));
            console.log("userlslkdm",userData?.[0]?.cure_id);
            const patientList = userData?.[0]?.group_list?.map(patient => ({
              group: patient.group,
              group_name: patient.group_name,
            }));
            dispatch(setPatientList(patientList));

            history.push("/" + path);
          }
      },
      (error) => {
        setErrorMessage(true);    
      }
    );
  };

  //Removes all the Redux Stored Values and Websockets related to the CareHQ
  useEffect(() => {
    
    resetReduxState(dispatch);
    closeCreatedSockets();
  }, []);

  
  return (
      <div>
          <div className="containerLogin">
              <div className="innerBoxLogin">
                  <div className="leftLogin">

                   
                          <div className="imageBGLogin">
                            
                          </div>
                       
                  </div>
                  <div className="rightLogin">
                      <div className="headerTitleLogin">LOGIN</div>
                   
            <div className={classes.paper}>
              <form
                className={classes.form}
                noValidate
                onSubmit={handleLoginSubmit}
              >
                <TextField
                  variant="standard"
                  id="standard-start-adornment"
                  placeholder="Group Name"
                  autoComplete="off"
                  fullWidth
                  defaultValue="101"
                  onChange={(e) => setGroupName(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockOutlinedIcon style={{ color: "#545459" }} />
                      </InputAdornment>
                    ),
                    className: classes.textField,
                  }}
                />

                <div style={{float:"left",width:"100%",position:"relative"}}>
                  <TextField
                   
                    variant="standard"
                    required
                    fullWidth
                    name="password"
                    placeholder="Password"
                    type={passwordVisible ? "text" : "password"}
                    id="password"
                    defaultValue="passme123"
                    autoComplete="current-password"
                    onChange={(e) => setPassword(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PermIdentityOutlinedIcon
                            style={{ color: "#545459" }}
                          />
                        </InputAdornment>
                      ),
                      className: classes.textField,
                    }}
                  />
                  <span
                    style={{
                      position: "absolute",
                      top:18,
                     right:0,
                     cursor:"pointer"
                    }}
                  >
                    {" "}
                    <button
                    type="button"

                      style={{  background: "none",
                      color:"#545459",
                        border: "none",
                        cursor: "pointer",
                        outline: "none",
                        marginLeft: "-30px" }}
                      onClick={togglePasswordVisibility}
                    >
                      {passwordVisible ? <VisibilityIcon style={{height:"20px", width:"20px"}}/> : <VisibilityOffIcon style={{height:"20px", width:"20px"}}/>}
                    </button>
                  </span>
                </div>

                <FormControl
                  style={{
                    color: "white",
                    width: "100%",
                    marginBottom: "12px",
                  }}
                  component="fieldset"
                >
                  <RadioGroup
                    onChange={(e) => setPath(e.target.value)}
                    row
                    aria-label="position"
                    name="position"
                    defaultValue="home"
                    className="flexitems-center"
                  >
                  
                  </RadioGroup>
                </FormControl>

                {errorMessage && (
                  <p
                    style={{
                      color: "red",
                      marginTop: "0",
                      textAlign: "center",
                      fontFamily:"'Montserrat', san-serif",
                      fontSize:"10px",
                      fontWeight:"500"
                    }}
                  >
                    Login Failed! Invalid ID or Password 
                  </p>
                )}

                <Button
                  className={classes.signInbtn}
                  color="white"
                  type="submit"
                  buttonlabel="Sign In"
                >
                  SIGN IN
                </Button>
                <p
                  style={{
                    color: "black",
                    marginTop: "10px",
                    textAlign: "center",
                    fontSize:"small",
                    fontFamily:"'Montserrat', sans-serif",
                    cursor:"pointer"
                  }}
                  onClick={handleForgotPassword}
                >
                  Forgot Password ?{" "}
                 
                </p>

                <Grid container alignItems="center" style={{ marginTop: "10px" }}>
  <Grid item xs>
    <Divider style={{ height: "1px", backgroundColor: "black" }} />
  </Grid>
  <Grid item>
    <Typography variant="caption" style={{ margin: "0 10px" }}>OR</Typography>
  </Grid>
  <Grid item xs>
    <Divider style={{ height: "1px", backgroundColor: "black" }} />
  </Grid>
</Grid>
  <div style={{display:"flex", flexDirection:"row", justifyContent:"space-evenly", marginTop:"5px"}}> 
                <div>
                  <GLogin/>
                </div>
                <div>
                  <Button
                    label='Login with Mobile'
                   onClick={handleMobileClick}
                    // disabled={renderProps.disabled}
                    style={{
                      width: "fit-content",
                      height: "fit-content",
                      borderRadius: "20px",
                      background: "#fff",
                      color: "#6D3DA3",
                      fontSize: "11.5px",
                      fontWeight: "500",
                      fontFamily: "'Montserrat', sans-serif",
                      alignItems: "center",
                      textTransform: "uppercase",
                      padding: "8px 10px 5px 10px",
                      border: "transparent",
                      transition: "all 0.2s ease-out",
                      boxShadow: "0px 4px 8px 0px rgba(0,0,0,0.3)",

                    }}
                  >
                    <FontAwesomeIcon icon={faMobilePhone} size="xl" style={{ marginRight: "5px" }} />
                    MOBILE
                  </Button>
                </div>
                </div>
                
              </form>
              
              <div>
                {" "}
                <p
                  style={{
                    color: "black",
                    marginTop: "10px",
                    textAlign: "center",
                    fontSize:"small",
                    fontFamily:"'Montserrat', sans-serif"
                  }}
                >
                  Don't have an account ?{" "}
                  <Button className={classes.loginbtn} onClick={handleSignIn}>
                    Sign Up
                  </Button>
                </p>
              </div>
            </div>
          
        </div>
         {/* <div className={classes.version}>
          <p className="smallText">
            Version: 1.0
            Version: CI-179 &nbsp;&nbsp;&nbsp; Date: 01/12/2021
          </p>
        </div>  */}
      </div>
                  </div>
              </div>
        
  );
}

import React, { useState } from 'react'
import { Grid, Paper, Button, Typography } from '@material-ui/core'
import { TextField } from '@material-ui/core'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { useRef } from 'react'
import {useSelector,useDispatch } from "react-redux"
import constants from '../../../../../Common/Constants'
import { setPassword } from "../../../../../store/authSlice";
import axios from "axios";
import { useParams, useHistory,useLocation } from "react-router-dom";
import {
  setDashboardData,
  setWebsocketStatus,
} from "../../../../../store/dashboardSlice";
import { PopMsg } from "./PopMsg";
import { Alert } from '@material-ui/lab';
import { Snackbar } from '@material-ui/core';
import styles from './Form.module.css';


function FormBP({handleClose, onClose ,careTakerPatientView=false,refreshChartData}) {

  const [showPopup, setShowPopup] = useState(false);
  const careTakerToken=useSelector((state) => state.auth.careTakerToken);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarColor, setSnackbarColor] = useState('');

    const ws=useRef();
    const dispatch = useDispatch();
    const deviceTypeList=useSelector((state)=>state.home.deviceTypeList);   
    const [response, setResponse] = useState([]);
    const [resToken, setResToken]=useState(null);
    const token = useSelector((state) => state.auth.authToken);
    const groupName = useSelector((state) => state.auth.groupName);
    const paperStyle = { padding: '0 5px 50px 5px', width: 270, }
    const btnStyle = { marginTop: 10, fontFamily:"'Montserrat', sans-serif"  }
    const SysDiaValue=/^[0-9]{2,3}/
    const initialValues = {
        BPS: '',
        BPD: '',
        // Date: '',
        // Time:''
        datetime: "",
    }
    const userData = useSelector((state) => state.auth.userData)
    const currentUserIndex= useSelector((state) => state.auth.currentUserIndex)

    
    const location = useLocation();
    const urlStateData = location?.state
  
    const { id,patient_group_id } = useParams();
    
    const validationSchema = Yup.object().shape({
    
      BPS: Yup.number().min(40).max(999).required("Required"),
    
      BPD: Yup.number().min(40).max(999).required("Required"),
    
    //   Date: Yup.date()
    //  .required("Required"),
    //  Time:Yup.string()
    //    .required("Required")
    
    datetime: Yup.date()
    .max(new Date(), "Date and time must not be in the future")
    .required("Please enter a date and time"),
  
    
  })

  const fetchDataApi = async () => {
    let url = `${constants.API_URL}/dashboard_values/${urlStateData?patient_group_id:groupName}/${token}/`;

    // url += `${token}/`;
    console.log(careTakerPatientView)

    const result = await axios.get(url);
    console.log(result)
    dispatch(setDashboardData(result.data.data));
    };

  const onSubmit = (values, props) => {

     
      props.resetForm()
   
      var dataBPS=(values.BPS)
   
      const dataBPD=(values.BPD)
   
      // const dateValue=values.Date 
  
      // const timeValue=values.Time
  
      const datetimevalue = values.datetime
        
      console.log(datetimevalue)

      // var timestampData   =  dateValue +" "+ timeValue  ;
   
      var epochTime = Date.parse(datetimevalue);
    
    //  var utcTime = epochTime
   
      var formData=JSON.stringify({BPS:parseInt(dataBPS), BPD:parseInt(dataBPD), TS:epochTime});
     console.log(formData)
      
    
     
      const pass= "passme123";
     
      axios({
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          url: `${constants.API_URL}/manual_entry/${urlStateData?patient_group_id:groupName}/${token}/`,
          data: formData,
        
        }).then(
          (res) => {
            if (res?.status == 200) {
            console.log(res)
            setShowPopup(true);
            setSnackbarOpen(true);
            setSnackbarColor('success');
            setSnackbarMessage('Manual Entry Successful');
            refreshChartData();
    
            setTimeout(() => {
               // show pop up message
               setShowPopup(false);
               onClose() ;
      
             }, 2000);
             clearTimeout();
             setTimeout(() => {
               // show pop up message
              fetchDataApi();
           console.log("api refreshing for manual entry")
             }, 3000);
             clearTimeout();
            }
          })
          .catch((error) => {
            console.log(error?.response);
            if(error?.response?.status == 400){
             setShowPopup(true);
             setSnackbarOpen(true);
             setSnackbarColor('error');
             setSnackbarMessage(error?.response?.data?.message);
            }
            else if (error?.response?.status == 500){
             setShowPopup(true);
             setSnackbarOpen(true);
             setSnackbarColor('error');
             setSnackbarMessage("Something went wrong");
            }
         });
    
  }
  console.log("BP",careTakerPatientView)
  // console.log(patient_group_id)
  // console.log(urlStateData.patient_token)
  
  const [value, setValue] = React.useState(new Date('2022-11-T21:11:54'));

  const vertical = "bottom"; 
  const horizontal = "center"; 
 
  
  const handleChange = (newValue) => {
    setValue(newValue);
  };
 
  return (
    <div>
      {showPopup && (
        <PopMsg />
      )}

      <Grid>
        <Paper elevation={0} style={paperStyle}>
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {(props) => (
              <Form noValidate>

               <div style={{marginBottom:"10px", fontFamily:"'Montserrat', sans-serif" }}>
                <Field as={TextField} name='BPS' label='Systolic Blood Pressure' fullWidth
                  error={props.errors.BPS && props.touched.BPS}
                  helperText={<ErrorMessage name='BPS' className={styles.errorHelperTextStyles} />} autoFocus required />
               </div>

               <div style={{marginBottom:"10px"}}>
                <Field as={TextField} name='BPD' label='Diastolic Blood Pressure' fullWidth
                  error={props.errors.BPD && props.touched.BPD}
                  helperText={<ErrorMessage name='BPD' />} required />
               </div>

               <div style={{marginBottom:"10px"}}>  
                    <Field type="datetime-local" name="datetime" label="datetime" fullWidth
                        error={props.errors.datetime && props.touched.datetime}
                        // helperText={props.errors.Date && props.touched.Date ? props.errors.Date : null}
                         required />
                        <div style={{color:"Red"}}>
                         <ErrorMessage name='datetime' />
                        </div>
                </div>
                {/* <Field type="date" name="Date" label="date" fullWidth
                  error={props.errors.Date && props.touched.Date}
                  helperText={<ErrorMessage name='date' />} required />

                <Field type="time" name="Time" label="Time" fullWidth
                  error={props.errors.Time && props.touched.Time}
                  helperText={<ErrorMessage name='time' />} required /> */}

                <Button type='submit' style={btnStyle} variant='contained'
                  color='primary'>Submit Data</Button>
              </Form>
            )}
          </Formik>
        </Paper>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={() => setSnackbarOpen(false)}
          message={snackbarMessage}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={snackbarColor} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
    </div>
  )
}

export default FormBP
import React from "react";
import "../FNewDash.css";
import { Col, Row } from "reactstrap";
import { useState } from "react";
import axios from "axios";
import { format, formatRelative, subDays, endOfDay} from 'date-fns'
import BRChartPage1 from "./BRChartPage1";
import BRChartPage2 from "./BRChartPage2";

import { useSelector } from "react-redux";
import constants from "../../../../../../Common/Constants";
import {  useLocation, useParams } from "react-router-dom";


const apiName = {
  threeMonth: "month_data",
  year: "month_data",
};

export default function BRChartContainer({ title, xData, yData, type}) {
     
    const dateHR=format(endOfDay(new Date()), 'ddMMyyy')
   const groupName = useSelector((state) => state.auth.groupName);
     const [clickedChart, setClickedChart] = useState(null);
     const [brValue,setBrValue]=useState({br:null,date:null})
    const [subChartName,setChartName]= useState('')
     const token = useSelector((state) => state.auth.authToken);
     const api_type = apiName[type];
    const [chart_data, setData] = useState({});


    const careTakerToken = useSelector((state) => state.auth.careTakerToken);
  
    const { id,patient_group_id } = useParams();
  
    
    
     // 
  
    const location = useLocation();
    const urlStateData = location?.state 

    
    


   
      const handleClick = (param, echarts) => {
        let location = (param.dataIndex)
            const tempState = {...brValue}
            tempState["date"] = xData[location]
            tempState["br"] = yData[location]
            setBrValue(tempState)
            
        }
      const handle3MonthClick = (param,echarts) => {
      const clickedDate = param?.name
        setClickedChart(clickedDate)
        setChartName(param?.name)
      
  
        axios
        .get(`${constants.API_URL}/${api_type}/br/${urlStateData?patient_group_id:groupName}/fitbit001/${dateHR}/${urlStateData?careTakerToken:token}/`)
        .then(res => { 
           
           
            let x= []
            let y = []
            res?.data?.month_data.forEach((data) => {
              x.push(data.x_data)
              y.push(data.y_data)
              let stateCopy = {...chart_data};
              
              stateCopy[clickedDate] = {xData:x,yData:y}
              
              setData(stateCopy);
            });
      
    })
    }
  
      
    
    return (
      <div className="h-100">
        <Row className="h-100">
          <Col className="first-box h-100">
            <div className="first-card h-100">
              <BRChartPage1
                title={title}
                xData={xData}
                yData={yData}
                handleClick={handleClick}
              
              />
            </div>
          </Col>
          <Col className="second-box">
            {brValue?.br && brValue?.date && 
            <div className="second-card">
              <BRChartPage2 
                title={brValue?.date}
                
                yData={brValue?.br}
              />
            </div>
  }
  
          </Col>
        </Row>
      </div>
    );
}
import React from 'react'

import ReactEcharts from 'echarts-for-react';
import './FNewDash.css'

import {useSelector,useDispatch } from "react-redux"

// import { setStepsDaily,setStepsDailyStatus } from '../../../../../store/dashboardSlice';
import { useMemo } from 'react';

import ExpandMorePopup from "../../../../../Common/ExpandMorePopup";
import FormStep from './FormStep';
import PopupBG from './PopBG';
import TileRemove from '../../../../home/TileRemove';

function StepChartHour({handleClose,onClick,careTakerPatientView=false}) {

  
    const stepsDaily = useSelector((state) => state.dashboard.stepsDaily)


    const distanceDaily = useSelector((state) => state.dashboard.distanceDaily);
    const dispatch = useDispatch();
    

    let disKil = ((distanceDaily?.total)/1000).toFixed(1)
    
  const fitbitStepsData = useMemo(() => {
    return {
      title:[{
          left: '5%',
          text: 'Steps',
          top: 0,
          // subtext:'Distance: '+ disKil + ' km',
          subtextStyle:{
            top:10,
          },
          textStyle: {
            fontWeight: 'normal',
            fontSize: 28,
            }
        },
        {
          subtext: stepsDaily?.time_gap + " ago",
          left: "right",
          bottom: "5%",
          fontSize: 12,
        },
        {
          subtext: "Steps",
          left: "center",
          top: "52%",
     
          fontSize: 15,
        },
      ],
      tooltip: {
        textStyle: {
          fontFamily: "sans-serif",
        },
      },
      series: [
        {
          type: "gauge",
          center: ['50%', '45%'],
          radius:"62%",
          guageCategoryGap: "0%",
          startAngle: 360,
          endAngle: 0,
          min: false,
          max: 1,
          splitNumber: 12,
          itemStyle: {
            color: "#00B5C6",
            shadowColor: "blue",
            shadowBlur: 0,
            shadowOffsetX: 0,
            shadowOffsetY: 0,
          },
          progress: {
            show: true,
            width: 9,
            
          },
          pointer:{
            show:false
          },
          axisLine: {
            show:false,
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show:false,
            length: 15
          },
          axisLabel: {
            show:false,
            distance: 20,
            color: 'red',
            fontSize: 20
          },
          anchor: {
            show: false,
            showAbove: false,
            size: 20,
            itemStyle: {
              borderWidth: 10
            }
          },
          title: {
            show: false
          },
          detail: {
            valueAnimation: true,
            fontSize: 28,
            fontWeight:"normal",
            color:" #434242",
         
            offsetCenter: [0, '5%']
          },
          data: [
            {
              name:'steps',
              value: stepsDaily?.total,
              
            }
          ]
        }
      ]
    
  }},[stepsDaily])

if(!(stepsDaily?.total)){
  return null
}

  return (
    <div>
  
  <div className='dis-daily-chart' >
  <div className="popup-bg" style={{display:'flex', justifyContent: 'right'}}>
    <ExpandMorePopup 
        option1={<PopupBG optionName="Manual Entry" FormTitle="Step Count" FormType={<FormStep handleClose={handleClose} careTakerPatientView={careTakerPatientView}/>} />}
        option2={<TileRemove
          careTakerPatientView={careTakerPatientView}
          parameter="STP" />}  /></div>
  <div onClick={onClick}>
  <ReactEcharts option={fitbitStepsData}/>

  </div>
 
  
  </div>
 
  </div>
  )
}

export default StepChartHour  
import React, { useState } from "react";
import styles from "./ForgotPassword.module.css";
import { useHistory } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import constants from "../../../../../../Common/Constants";

export const ForgotPassword = () => {
  const history = useHistory();

  const [selectedOption, setSelectedOption] = useState("email"); // Default selection is "email"
  const [email, setEmail] = useState("");
  const [contactNumber, setContactNumber] = useState("91");
  const [countryCode, setCountryCode] = useState(""); 
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  
  const handleSignUp = () => {
    history.push("/patient_signup_page");
  };

  const handleSignIn = () => {
    history.push("/");
  };

  const handleVerify = () => {
    // Depending on the selected option, you can access the corresponding value (email or contactNumber) here
    const valueToVerify = selectedOption === "email" ? email : contactNumber;
    history.push("/email_verify");
  };

  const sendVerificationCode = async () => {
    const apiUrl = `${constants.API_URL}/password_reset_otp_generator/`;

    // Prepare the payload based on the selected option
    const payload = {
      detail:
        selectedOption === "email" ? email : `+${contactNumber}`,
    };

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Add any other headers you need here
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        // Handle success, e.g., show a success message or redirect the user
        history.push(`/email_verify?email=${email}&contactNumber=${contactNumber}`);
      } else if (response.status === 400) {
        // Handle errors, e.g., display an error message to the user
        setShowSnackbar(true);
        setSnackbarMessage("Email/Phone number not registered.");
         // Automatically close the snackbar after 5 seconds
         setTimeout(() => {
          setShowSnackbar(false);
        }, 5000); 
      }
    } catch (error) {
      console.error("Error sending verification code:", error);
    }
  };

  

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

 
  return (
    <div className={styles.mainContainerForgot}>
      <div className={styles.mobileViewForgot}>
        <div>
          <span
            style={{
              color: "#6A4396",
              fontWeight: "700",
              fontFamily: "'Montserrat', sans-serif",
              fontSize: "1.6rem",
            }}
          >
            Care
          </span>
          <span
            style={{
              color: "#23AF72",
              fontWeight: "700",
              fontFamily: "'Montserrat', sans-serif",
              fontSize: "1.6rem",
            }}
          >
            HQ
          </span>
        </div>
        <div className={styles.detailForgot}>
          <div className={styles.forgotHeading}>Forgot Password</div>

          <div className={styles.selectionField}>
            <label
              style={{
                fontFamily: "Montserrat, san-serif",
                padding: "0 10px",
                fontSize: "14px",
              }}
            >
              <input
                type="radio"
                name="contactOption"
                value="email"
                checked={selectedOption === "email"}
                onChange={() => handleOptionChange("email")}
                style={{ cursor: "pointer" }}
              />
              Select Email
            </label>
            <label
              style={{
                fontFamily: "Montserrat, san-serif",
                fontSize: "14px",
                marginBottom: "10px",
              }}
            >
              <input
                type="radio"
                name="contactOption"
                value="mobile"
                checked={selectedOption === "mobile"}
                onChange={() => handleOptionChange("mobile")}
                style={{ cursor: "pointer" }}
              />
              Select Mobile
            </label>
          </div>

          {selectedOption === "email" && (
            <input
              placeholder="Enter your email"
              className={styles.emailField}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          )}

          {selectedOption === "mobile" && (
            <PhoneInput
              id="phoneNumber"
              country={countryCode}
              value={contactNumber}
              onChange={(value, country) => {
                setCountryCode(country.dialCode);
                setContactNumber(value);
              }}
              inputStyle={{
                width: "100%",
                fontSize: "small",
                backgroundColor: "#f5f5f5",
                border: "none",
                color: "grey",
                fontFamily: "Montserrat,  san-serif",
                marginTop: "25px",
                borderRadius: "5px",
              }}
            />
          )}

          <div className={styles.verifyBtn} onClick={sendVerificationCode} >
            Send Verification Code
          </div>
          <div
        style={{
          display: showSnackbar ? "block" : "none",
          position: "fixed",
          bottom: "50px",
          left: "50%",
          transform: "translateX(-50%)",
          backgroundColor: "red",
          color: "white",
          padding: "12px",
          borderRadius: "25px",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
          zIndex: 9999,
          fontFamily:"Montserrat, sans-serif",
          fontSize:"small"
        }}
      >
        {snackbarMessage}
       
      </div>
      
          <div className={styles.signUpBtn}>
            Don't have an account ?{" "}
            <span className={styles.btn} onClick={handleSignUp}>
              SIGN UP
            </span>
          </div>
          <div className={styles.backBtn} onClick={handleSignIn}>
            Back to Login
          </div>
        </div>
      </div>
    </div>
  );
};

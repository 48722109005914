import React, { memo } from "react";
import ReactEcharts from "echarts-for-react";
import CircularProgress from "@mui/material/CircularProgress";
import * as echarts from "echarts";
import styles from './BMIChartPopup.module.css';

function BMILineChart({ title, xData, yData, handleClick, bmiRes }) {
  const colorBMI = bmiRes
    ?.map((point) => {
      var epochTS = new Date(parseInt(point.x_data));
      var dsnow = `${epochTS.toLocaleDateString()} \n ${epochTS.toLocaleTimeString()}`;

      return {
        value: [dsnow, (point?.y_data?.BMI).toFixed(1).toString()],

        itemStyle: {
          color: point.y_data.is_manual ? "#808080" : "#6D3DA3",
        },
      };
    })
    .sort((a, b) => {
      // Convert the x-axis values to Date objects for comparison
      const dateA = new Date(`01/01/2021 ${a.value[0]}`);
      const dateB = new Date(`01/01/2021 ${b.value[0]}`);

      // Compare the dates and return the sort order
      if (dateA < dateB) {
        return -1;
      }
      if (dateA > dateB) {
        return 1;
      }
      return 0;
    });

  const chartData = React.useMemo(() => {
    return {
      tooltip: {
        trigger: "axis",
        textStyle: {
          fontFamily: "sans-serif",
        },
        axisPointer: {
          type: "shadow",
        },
      },
     
      dataZoom: [
        {
          type: 'inside',
          start: 60,
          end: 100
        },
        {
          start: 10,
          end: 0
        }
      ],
      grid: { // Add grid property to set the margin
        top: "8%", // Adjust the bottom margin as needed
        containLabel: true, // Include axis labels and title inside the grid
      },

      xAxis: {
        type: "category",
        color: "#AEAEAE",

        axisLabel: {
          fontFamily: "sans-serif",
        },
      },
      yAxis: {
        type: "value",
        color: "#AEAEAE",

        axisLabel: {
          fontFamily: "sans-serif",
        },
      },

      series: [
        {
          name: "BMI",
          type: "line",
          smooth: true,
          barWidth: "50%",
          areaStyle: {
            opacity: 0.3,
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 1,
                color: "white",
              },
              {
                offset: 0,
                color: "#6D3DA3",
              },
            ]),
          },
          color: "#6D3DA3",
          data: colorBMI,
        },
      ],
    };
  }, [xData, yData, title]);

  const _handleClick = (params, data) => {
    let index = params.dataIndex;
    handleClick &&
      "function" === typeof handleClick &&
      handleClick(colorBMI[index]);
  };

  return (
    <div>
      {chartData?.series?.[0]?.data?.length ? (
        <div className="left-chart">
          <ReactEcharts
            option={chartData}
            onEvents={{
              click: handleClick,
            }}
            style={{ width: "fitContent" }}
          />
        </div>
      ) : (
        <p className={styles.noDataStyles}>
         {/* <CircularProgress /> */}
         No Data
        </p>
      )}
    </div>
  );
}
export default memo(BMILineChart);

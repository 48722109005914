import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory,useLocation } from "react-router-dom";
import constants from "../../../Common/Constants.js";
import {
  setDashboardData,
} from "../../../store/dashboardSlice.js";
import { Button, Card, CardActionArea, Paper } from "@material-ui/core";
import FormBP from "../../Member Details/Comps/FitbitFolder/FitCharts/FormBP.js";
import FormBG from "../../Member Details/Comps/FitbitFolder/FitCharts/FormBG.js";
import FormSpO2 from "../../Member Details/Comps/FitbitFolder/FitCharts/FormSpO2.js";
import FormHR from "../../Member Details/Comps/FitbitFolder/FitCharts/FormHR.js";
import FormBR from "../../Member Details/Comps/FitbitFolder/FitCharts/FormBR.js";
import FormTemp from "../../Member Details/Comps/FitbitFolder/FitCharts/FormTemp.js";
import FormWT from "../../Member Details/Comps/FitbitFolder/FitCharts/FormWT.js";
import FormStep from "../../Member Details/Comps/FitbitFolder/FitCharts/FormStep.js";
import FormCalorie from "../../Member Details/Comps/FitbitFolder/FitCharts/FormCalorie.js";
import Headerv2 from './Headerv2.js';
import HeartRatev2 from './VitalCharts/HeartRatev2';
import BPChartsv2 from './VitalCharts/BPChartsv2.js';
import BPv2 from './VitalCharts/BPv2.js';
import SleepQuick from './VitalCharts/SleepQuick.js';
import StepSpo2v2 from './VitalCharts/StepSpo2v2.js';
import PillDisv2 from './VitalCharts/PillDisv2.js';
import UserData from './OtherComponents/UserData.js';
import Tempv2 from './VitalCharts/Tempv2.js';
import BRv2 from './VitalCharts/BRv2.js';
import Weightv2 from './VitalCharts/Weightv2.js';
import Caloriev2 from './VitalCharts/Caloriev2.js';
import FloatingAddButton from './Featuresv2/FloatingAddButton.js';
import PopUpManualEntry from './OtherComponents/PopUpManualEntry.js';
import FormSleep from '../../Member Details/Comps/FitbitFolder/FitCharts/FormSleep.js';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/free-solid-svg-icons'
import { useRef } from "react";
import BMI from "./VitalCharts/BMI.js";

function HomeDashboardPatientView() {

  const HeartPage = () => {
    history.push(`/heartratepage/${patient_group_id}`,careTakerPatientView);
  };
  const StepsPage = () => {
    history.push(`/stepspage/${patient_group_id}`,careTakerPatientView);
  };
  
  const SlpPage = () => {
    history.push(`/sleeppage/${patient_group_id}`,careTakerPatientView);
  };
  const Spo2Page = () => {
    history.push(`/spo2page/${patient_group_id}`,careTakerPatientView);
  };
  const BrPage = () => {
    history.push(`/brpage/${patient_group_id}`,careTakerPatientView);
  };
  const BmiPage = () => {
    history.push(`/bmipage/${patient_group_id}`,careTakerPatientView);
  };
  const BpPage = () => {
    history.push(`/bppage/${patient_group_id}`,careTakerPatientView);
  };
  const BgPage = () => {
    history.push(`/bgpage/${patient_group_id}`,careTakerPatientView);
  };
  const MedicationPage = () =>{
    history.push(`/medication_page/${patient_group_id}`,careTakerPatientView)
  }

  const PatMon = () => {
    history.push("/patientmonitor");
  };
  const Vent = () => {
    history.push("/ventilator");
  };

  const [ test , setTest ] = useState(1);
  const [careData , setCareData] =useState([]);

  const getLocalStoirageData = ()=>{
    let d = localStorage.getItem("test");
    if(!test){
       return false ;
    }
    try{
      d = JSON.parse( d );
      return d.token ? d : false ;
    }catch(er){
      return false ;
    }
    return !1; 
  }

  const count = useSelector((state) => state.auth.renderInterval);
  const groupName = useSelector((state) => state.auth.groupName);
  const token = useSelector((state) => state.auth.authToken);
  const deviceTypeList = useSelector((state) => state.home.deviceTypeList);

  const careTakerToken = useSelector((state) => {
    let local = getLocalStoirageData();
    if( local ){ 
        return local.token ;   
    }
    return state.auth.careTakerToken
  });
  
  const userData = useSelector((state) => state.auth.userData);
  const currentUserIndex = useSelector((state) => state.auth.currentUserIndex);
  
  const careTaker = useSelector((state) => {
    let local = getLocalStoirageData();
    if( local && local.token ){
        return false ;
    }
    return state.auth.careTaker ;
  });

  const { id,patient_group_id } = useParams();
  const location = useLocation();
  const urlStateData = location?.state

  const careTakerPatientView = !!(careTaker && patient_group_id)
  const history = useHistory();
  const dispatch = useDispatch();

  const endReplayTime = useSelector((state) => state.settings.endReplayTime);
  const dashboardApiStatus = useSelector((state) => state.dashboard.apiStatus);

 
  
  useEffect(() => {
    
    // if (dashboardApiStatus == "fetch") {
      const fetchData = async () => {
      let url = `${constants.API_URL}/dashboard_values/${patient_group_id}/${token}/`;
      
      let activeUserDeviceList = userData?.[currentUserIndex]?.["device_list"];

      // url += `${token}/`;
      
      
      const result = await axios.get(url);
      
      dispatch(setDashboardData(result.data.data));
      };
      {(!careTaker || careTakerPatientView) &&(
      fetchData()
      )}
      const intervalId = setInterval(fetchData, 610000);
      return () => clearInterval(intervalId)
    // }
  }, [dashboardApiStatus, groupName, token, currentUserIndex, userData,test ]);

  const CardOnClick = ( token, group_id ) => {
        history.push(`/patient/${group_id}`,{ patient_token: token })
  };

  const [isDetailsPopoverOpen, setDetailsPopoverOpen] = useState(false);

  const popoverRef = useRef(null);

  const togglePopover = () => {
    setDetailsPopoverOpen(!isDetailsPopoverOpen);
  };

  const closePopover = () => {
    setDetailsPopoverOpen(false);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        closePopover();
      }
    };

    const handleNavigate = () => {
      closePopover();
    };

    document.addEventListener('mousedown', handleOutsideClick);
    window.addEventListener('beforeunload', handleNavigate);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
      window.removeEventListener('beforeunload', handleNavigate);
    };
  }, []);

  

  return (
      <>
          {/* {(!careTaker || careTakerPatientView) && ( */}
              <div className='dashMainv2'>
                  <div classname="headerV2">
                      <Headerv2/>
                  </div>
                  <div className='dashboardv2MainContainer-v2'>

                      <div className='left-panel-v2'>
                          <div className='week-month-tab'>  </div>
                          <div className='left-card-main-grid'>
                              <HeartRatev2 onClick={HeartPage} />
                              <BPChartsv2 onClick={BpPage} />
                              <BPv2 onClick={BgPage} />
                              <SleepQuick onClick={SlpPage} />
                              <StepSpo2v2 />
                              {/* <SleepChartv2/> */}
                              {/* <Ventilatorv2/> */}
                              <PillDisv2 onClick={MedicationPage} />
                              {/* <CareCamv2/> */}
                              {/* <PatientMonv2/> */}

                          </div>
                      </div>
                      <div className='right-panel-v2'>
                          <div className='side-container-v2' >
                              <div className='details-css' style={{ margin: "0px 5px 5px 5px", padding: "5px 5px 5px 5px", fontFamily: "'Montserrat', sans-serif", fontSize: "1rem", color: "#75757C", fontWeight: "500" }}>DETAILS <FontAwesomeIcon icon={faPen} style={{ fontSize: "0.7rem", justifyContent: "center" }} color="#7F55AE" /></div>
                              {/* <div className='container-right-patient-details-v2' style={{ minHeight: "240px" }}>
                                  <UserData />
                              </div> */}
                              <div className='patient-details-button-v2' style={{ width: "100%" }}>
                                  <div className="user-popover-container" ref={popoverRef}>
                                      <Button className="user-button" onClick={togglePopover}>
                                          DETAILS
                                      </Button>
                                      {isDetailsPopoverOpen && (
                                          <div className="popover-content">
                                              <UserData />
                                          </div>
                                      )}
                                      {isDetailsPopoverOpen && (
                                          <div className="popover-overlay" onClick={closePopover}></div>
                                      )}
                                  </div>
                              </div>
                              <div className='conatainer-right-vitals-v2'>
                                  <Tempv2 />
                                  <BRv2 onClick={BrPage} />
                                  <Weightv2 />
                                  <Caloriev2 />
                                  <BMI onClick={BmiPage}/>
                              </div>
                          </div>
                      </div>
                  </div>
                  <FloatingAddButton
                      option1={<PopUpManualEntry
                          optionName="Blood Pressure"
                          FormTitle="Blood Pressure"
                          FormType={<FormBP />}
                          careTakerPatientView={careTakerPatientView} />}

                      option2={<PopUpManualEntry
                          optionName="Blood Glucose"
                          FormTitle="Blood Glucose"
                          FormType={<FormBG careTakerPatientView={careTakerPatientView} />}
                      />}

                      option9={<PopUpManualEntry
                          optionName="SpO2 "
                          FormTitle="SpO2 " FormType={<FormSpO2 careTakerPatientView={careTakerPatientView} />}
                      />}

                      option3={<PopUpManualEntry
                          optionName="Heart Rate"
                          FormTitle="Heart Rate" FormType={<FormHR careTakerPatientView={careTakerPatientView} />}
                      />}

                      option4={<PopUpManualEntry
                          optionName="Breath Rate"
                          FormTitle="Breath Rate"
                          FormType={<FormBR careTakerPatientView={careTakerPatientView} />}
                      />}

                      option5={<PopUpManualEntry
                          optionName="Temperature"
                          FormTitle="Temperature"
                          FormType={<FormTemp careTakerPatientView={careTakerPatientView} />}
                      />}

                      option6={<PopUpManualEntry
                          optionName="Weight"
                          FormTitle="Weight"
                          FormType={<FormWT careTakerPatientView={careTakerPatientView} />}
                      />}

                      option8={<PopUpManualEntry
                          optionName="StepCount"
                          FormTitle="Step"
                          FormType={<FormStep careTakerPatientView={careTakerPatientView} />}
                      />}

                      option7={<PopUpManualEntry
                          optionName="Calorie"
                          FormTitle="Calorie"
                          FormType={<FormCalorie careTakerPatientView={careTakerPatientView} />}
                      />}

                      option10={<PopUpManualEntry
                          optionName="Sleep"
                          FormTitle="Sleep"
                          FormType={<FormSleep />} careTakerPatientView={careTakerPatientView} />}

                  />
              </div>
          {/* )} */}
      </>
  );
}

export default React.memo(HomeDashboardPatientView)
import React from "react";
import { useState, useRef, useEffect } from "react";
import styles from './ForgotPassword.module.css'

export const Timer = React.forwardRef((props, ref) => {
  const timerRef = useRef(null);
  const [timer, setTimer] = useState(props.delay || 30);
  const [showResend, setShowResend] = useState(false);

  const startTimer = () => {
    setTimer(props.delay || 30);
    setShowResend(false);

    clearInterval(timerRef.current);
    timerRef.current = setInterval(() => {
      setTimer((prevVal) => {
        let newVal = prevVal - 1;
        if (newVal === 0) {
          clearInterval(timerRef.current);
          setShowResend(true);
        }
        return newVal;
      });
    }, 1000);
  };


  React.useImperativeHandle(ref, () => {
    return {
      start:startTimer,
    };
  });

  useEffect(() => {
    startTimer(); // Start the timer when the component mounts

    // Clear the interval when the component unmounts to prevent memory leaks
    return () => {
      clearInterval(timerRef.current);
    };
  }, []);


  const handleResendClick = () => {
    if (showResend) {
      startTimer(); // Restart the timer when the "Resend" link is clicked
      if (props.onComplete) {
        props.onComplete(); // Trigger the onComplete function if provided
      }
    }
  };

  return (
    <div>
      {!showResend ? (
        <div className={styles.timer}>Didn't receive the code? Resend in {timer} s</div>
      ) : (
        <div className={styles.timer}>
          Didn't receive the code? 
          <span
            style={{
              fontWeight: "bold",
              fontFamily: "Montserrat, san-serif",
              cursor: "pointer",
              color: "#5e2d97"
            }}
            onClick={handleResendClick} // Trigger the onComplete function when the link is clicked
          >
            Resend
          </span>
        </div>
      )}
    </div>
  );
});

import React, { useEffect, useState } from "react";
import styles from "./MyProfile.module.css";
import * as Yup from "yup";
import { Formik, ErrorMessage, Form } from "formik";
import axios from "axios";
import constants from "../../../../../../Common/Constants";
import { useSelector } from "react-redux";
import { Avatar, Button, makeStyles } from "@material-ui/core";
import BreadcrumbsTop from "../BreadcrumbsTop";
import { PopProfileSave } from "../PopProfileSave";
import Header from "../../../../../Header/Header";
import Headerv2 from "../../../../../home/V2Components/Headerv2";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Switch from "@mui/material/Switch";

const useStyles = makeStyles((theme) => ({
  root: {
    alignSelf: "center",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  container: {
    position: "relative",
    display: "inline-block",
  },
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  cameraIcon: {
    position: "absolute",
    bottom: "10%",
    right: "15%",
    transform: "translate(50%, 50%)",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: "50%",
    padding: theme.spacing(1),
    cursor: "pointer",
    display: "flex",
    height: "20px",
    width: "20px",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const VALIDATION_SCHEMA = Yup.object().shape({
  name: Yup.string().required(),
  dob: Yup.string().nullable(),
  height: Yup.number().nullable(),
  weight: Yup.number().nullable(),
  contact: Yup.string().nullable(),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  caretaker: Yup.string().nullable(),
  heart_disease: Yup.string().nullable(),
  cholesterol: Yup.boolean().nullable(),
  diabetes: Yup.boolean().nullable(),
  cancer: Yup.boolean().nullable(),
  hypertension: Yup.boolean().nullable(),
  medication: Yup.string().nullable(),
});

const FIELDS = {
  name: {
    label: "Name",
    type: "text",
  },
  dob: {
    label: "Date of Birth",
    type: "date",
  },
  contact: {
    label: "Contact No.",
    type: "text",
  },
  email: {
    label: "Email",
    type: "email",
  },
  caretaker: {
    label: "Caretaker",
    type: "text",
  },
  height: {
    label: "Height",
    type: "number",
    unit: "cm",
  },
  weight: {
    label: "Weight",
    unit: "kg",
    type: "number",
  },
  heart_disease: {
    label: "Heart Disease",
    type: "radio",
    options: [
      {
        label: "Yes",
        value: "True",
      },
      {
        label: "No",
        value: "False",
      },
    ],
  },
  cholesterol: {
    label: "Cholestrol",
    type: "radio",
    options: [
      {
        label: "Yes",
        value: "True",
      },
      {
        label: "No",
        value: "False",
      },
    ],
  },
  diabetes: {
    label: "Diabetes",
    type: "radio",
    options: [
      {
        label: "Yes",
        value: "True",
      },
      {
        label: "No",
        value: "False",
      },
    ],
  },
  cancer: {
    label: "Cancer",
    type: "radio",
    options: [
      {
        label: "Yes",
        value: "True",
      },
      {
        label: "No",
        value: "False",
      },
    ],
  },
  hypertension: {
    label: "Hypertension",
    type: "radio",
    options: [
      {
        label: "Yes",
        value: "True",
      },
      {
        label: "No",
        value: "False",
      },
    ],
  },
  medication: {
    label: "Add Medications",
    type: "text",
  },
  doctor: {
    label: "doctor",
    type: "text",
  },
  isDoctor: {
    label: "isDoctor",
    type: "radio",
    options: [
      {
        label: "Yes",
        value: "True",
      },
      {
        label: "No",
        value: "False",
      },
    ],
  },
};
function MyProfile() {
  const classes = useStyles();
  const history = useHistory();
  const [showPopup, setShowPopup] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const token = useSelector((state) => state.auth.authToken);
  const homePage = () => {
    history.push("/home");
  };

  function handleEmailChange(event) {
    const { value } = event.target;
    setEmail(value);

    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(value)) {
      setEmailError("Invalid Email Format");
    } else {
      setEmailError("");
    }
  }
  const groupName = useSelector((state) => state.auth.groupName);
  const [profile, setProfile] = React.useState();
  const [userInitials, setUserInitials] = useState("");
  const headerGroupName = useSelector((state) => state.auth.headerGroupName);

  function getInitials(headerName) {
    let initials = headerName
      .split(" ")
      .map((word) => word[0])
      .join("")
      .toUpperCase()
      .slice(0, 2);
    return initials;
  }
  useEffect(() => {
    let initials = getInitials(headerGroupName);
    setUserInitials(initials);
  }, [headerGroupName]);

  const initial_values = React.useMemo(() => {
    var val = {};
    Object.keys(FIELDS).map((key) => {
      val[key] = (profile && profile[key]) || "";
    });
    val = Object.assign({}, val, profile ? profile : {});
    Object.keys(val).forEach((key) => {
      if (typeof val[key] === "boolean") {
        val[key] = val[key] ? "true" : "false";
      }
    });
    return val;
  }, [profile]);

  React.useEffect(() => {
    axios
      .get(`${constants.API_URL}/view_profile/${groupName}/${token}`)
      .then((res) => {
  
        setProfile(res?.data?.profile_data);
      });
  }, [groupName]);
  const onSubmit = (values) => {
    const form = new FormData();
    for (let key in values) {
      if (key === "image") {
        form.append("image", selectedFile);
        continue;
      }
      form.append(key, values[key]);
    }
    // console.log("values: ",values)
    if (selectedImage === null) {
      form.delete("image");
    }
    // props.resetForm();
    // Object.keys(values).forEach((key) => {
    //   if (["true", "false"].indexOf(values[key]) > -1) {
    //     values[key] = values[key] == "true" ? true : false;
    //   }
    // });

    axios({
      method: "post",
      url: `${constants.API_URL}/update_profile/group/${groupName}/${token}`,
      data: form,
    }).then((res) => {
      if (res.status === 200) setShowPopup(true);
      setTimeout(() => {
        // show pop up message
        setShowPopup(false);
      }, 2000);
      clearTimeout();

      setTimeout(() => {
        // show pop up message
        axios
          .get(`${constants.API_URL}/view_profile/${groupName}/${token}`)
          .then((res) => {
            setProfile(res?.data?.profile_data);
          });
      }, 4000);
      clearTimeout();
    });
  };

  return (
    <div className={styles.backH} key={profile}>
      <Headerv2 />
      <div className={styles.containerDashH}>
        {showPopup && <PopProfileSave />}
        <Formik
          initialValues={initial_values}
          validationSchema={VALIDATION_SCHEMA}
          onSubmit={onSubmit}
        >
          {({
            handleChange,
            handleBlur,
            values,
            handleSubmit,
            errors,
            setFieldValue,
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div className={styles.profileHeading}>
                  <div
                    style={{
                      fontFamily: "Montserrat,  san-serif",
                      fontSize: "15px",
                    }}
                  >
                    PROFILE
                  </div>
                  <div className={styles.homebtn} onClick={homePage}>
                    BACK TO HOMEPAGE <ExitToAppIcon />
                  </div>
                </div>
                <div className={styles.contentDiv}>
                  <div className={styles.imageDiv}>
                    <div className={classes.container}>
                      <Avatar
                        src={
                          selectedImage ||
                          "https://www.w3schools.com/howto/img_avatar.png"
                        }
                        style={{ width: "95px", height: "95px" }}
                      />
                      <label
                        htmlFor="icon-button-file"
                        className={classes.cameraIcon}
                      >
                        <span style={{ fontSize: "15px" }}>
                          <PhotoCamera fontSize="inherit" />
                        </span>
                      </label>
                      <input
                        accept="image/*"
                        className={classes.input}
                        id="icon-button-file"
                        type="file"
                        onChange={(event) => {
                          const file = event.target.files[0];
                          setSelectedFile(file);
                          setSelectedImage(URL.createObjectURL(file));
                        }}
                      />
                    </div>
                  </div>

                  <div className={styles.detailsDiv}>
                    <div className="row" style={{ width: "100%" }}>
                      <div className="col" style={{ width: "100%" }}>
                        <div className={styles.labels}>Name</div>{" "}
                        <input
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          id="name"
                          name="name"
                          value={values.name}
                          className={styles.fields}
                        />
                      </div>
                      <div className="col">
                        <div className={styles.labels}> Date of Birth</div>
                        <input
                          type="date"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          id="dob"
                          name="dob"
                          value={values.dob}
                          className={styles.fields}
                        />
                      </div>
                    </div>
                    <div className="row" style={{ width: "100%" }}>
                      <div className="col">
                        <div className={styles.labels}> Contact No.</div>
                        <input
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          id="contact"
                          name="contact"
                          value={values.contact}
                          className={styles.fields}
                        />
                      </div>
                      <div className="col">
                        <div className={styles.labels}> Email</div>
                        <input
                          type="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          id="email"
                          name="email"
                          value={values.email}
                          className={styles.fields}
                        />
                      </div>
                    </div>
                    <div className="row" style={{ width: "100%" }}>
                      <div className="col">
                        <div className={styles.labels}> Caretaker</div>
                        <input
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          id="caretaker"
                          name="caretaker"
                          value={values.caretaker}
                          className={styles.fields}
                        />
                      </div>
                      <div className="col">
                        <div className={styles.labels}> Height(cm)</div>
                        <input
                          type="number"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          id="height"
                          name="height"
                          value={values.height}
                          className={styles.fields}
                        />
                      </div>
                      <div className="col">
                        <div className={styles.labels}> Weight(cm)</div>
                        <input
                          type="number"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          id="weight"
                          name="weight"
                          value={values.weight}
                          className={styles.fields}
                        />
                      </div>
                    </div>
                    <div className="row" style={{ width: "100%" }}>
                      <div className="col-4">
                        <div className={styles.labels}> Heart Diseases</div>{" "}
                        <div className="row">
                          <div className="col-6">
                            <span className={styles.spanCheck}>
                              <input
                                type="radio"
                                onChange={() => {
                                  setFieldValue("heart_disease", "False");
                                }}
                                onBlur={handleBlur}
                                id="heart_disease_false"
                                name="heart_disease"
                                value="False"
                                className={styles.fields}
                                checked={values.heart_disease === "False"}
                              />
                              No
                              
                            </span>
                          </div>
                          <div className="col-6">
                            <span className={styles.spanCheck}>
                            <input
                                type="radio"
                                onChange={() => {
                                  setFieldValue("heart_disease", "True");
                                }}
                                onBlur={handleBlur}
                                id="heart_disease_true"
                                name="heart_disease"
                                value="True"
                                className={styles.fields}
                                checked={values.heart_disease === "True"}
                              />
                              Yes
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="col-4">
                        <div className={styles.labels}> Cholestrol</div>
                        <div className="row">
                          <div className="col-6">
                            <span className={styles.spanCheck}>
                              <input
                                type="radio"
                                onChange={() => {
                                  setFieldValue("cholesterol", "False");
                                }}
                                onBlur={handleBlur}
                                id="cholesterol"
                                name="cholesterol"
                                value={values.cholesterol}
                                className={styles.fields}
                                defaultChecked={values.cholesterol == "False"}
                              />
                              No
                            </span>
                          </div>
                          <div className="col-6">
                            <span className={styles.spanCheck}>
                              <input
                                type="radio"
                                onChange={() => {
                                  setFieldValue("cholesterol", "True");
                                }}
                                onBlur={handleBlur}
                                id="cholesterol"
                                name="cholesterol"
                                value={values.cholesterol}
                                className={styles.fields}
                              />{" "}
                              Yes
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="col-4">
                        <div className={styles.labels}> Diabetics</div>
                        <div className="row">
                          <div className="col-6">
                            <span className={styles.spanCheck}>
                              <input
                                type="radio"
                                onChange={() => {
                                  setFieldValue("diabetes", "False");
                                }}
                                onBlur={handleBlur}
                                id="diabetes"
                                name="diabetes"
                                value={values.diabetes}
                                className={styles.fields}
                                defaultChecked={values.diabetes == "False"}
                              />
                              No
                            </span>
                          </div>
                          <div className="col-6">
                            <span className={styles.spanCheck}>
                              <input
                                type="radio"
                                onChange={() => {
                                  setFieldValue("diabetes", "True");
                                }}
                                onBlur={handleBlur}
                                id="diabetes"
                                name="diabetes"
                                value={values.diabetes}
                                className={styles.fields}
                              />{" "}
                              Yes
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className={styles.labels}> Cancer</div>
                        <div className="row">
                          <div className="col-6">
                            <span className={styles.spanCheck}>
                              <input
                                type="radio"
                                onChange={() => {
                                  setFieldValue("cancer", "False");
                                }}
                                onBlur={handleBlur}
                                id="cancer"
                                name="cancer"
                                value={values.cancer}
                                className={styles.fields}
                                defaultChecked={values.cancer == "False"}
                              />
                              No
                            </span>
                          </div>
                          <div className="col-6">
                            <span className={styles.spanCheck}>
                              <input
                                type="radio"
                                onChange={() => {
                                  setFieldValue("cancer", "True");
                                }}
                                onBlur={handleBlur}
                                id="cancer"
                                name="cancer"
                                value={values.cancer}
                                className={styles.fields}
                              />{" "}
                              Yes
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className={styles.labels}> Hypertension</div>
                        <div className="row">
                          <div className="col-6">
                            <span className={styles.spanCheck}>
                              <input
                                type="radio"
                                onChange={() => {
                                  setFieldValue("hypertension", "False");
                                }}
                                onBlur={handleBlur}
                                id="hypertension"
                                name="hypertension"
                                value={values.hypertension}
                                className={styles.fields}
                                defaultChecked={values.hypertension == "False"}
                              />
                              No
                            </span>
                          </div>
                          <div className="col-6">
                            <span className={styles.spanCheck}>
                              <input
                                type="radio"
                                onChange={() => {
                                  setFieldValue("hypertension", "True");
                                }}
                                onBlur={handleBlur}
                                id="hypertension"
                                name="hypertension"
                                value={values.hypertension}
                                className={styles.fields}
                              />{" "}
                              Yes
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row" style={{ width: "100%" }}>
                      <div className="col">
                        <div className={styles.labels}> Add Medications </div>
                        <input
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          id="medication"
                          name="medication"
                          value={values.medication}
                          className={styles.fields}
                        />
                      </div>
                      <div className="col">
                        <div className={styles.labels}>
                          {" "}
                          Select Your Doctor{" "}
                        </div>
                        <input
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          id="doctor"
                          name="doctor"
                          value={values.doctor}
                          className={styles.fields}
                        />
                      </div>
                    </div>
                    <div className="row" style={{ width: "100%" }}>
                      <div className={styles.labels}>
                        {" "}
                        Are You a Doctor? <Switch {...label} />
                      </div>
                    </div>

                    <div style={{ marginTop: "5%" }}>
                      <Button
                        type="submit"
                        className={styles.btnStyle}
                        variant="contained"
                      >
                        SAVE CHANGES
                      </Button>
                    </div>
                  </div>
                  <div></div>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default MyProfile;

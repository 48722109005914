import React, { memo } from "react";
import { useState, useEffect } from "react";
import ReactEcharts from "echarts-for-react";
import "./FNewDash.css";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import constants from "../../../../../Common/Constants";
import { format, formatRelative, subDays, endOfDay } from "date-fns";
import { textAlign } from "@mui/system";
import GlucoseDefault from "./DefaultCharts/GlucoseDefault";
import { bottomNavigationActionClasses } from "@mui/material";
// import { setGlucoseDaily,setGlucoseDailyStatus } from '../../../../../store/dashboardSlice';
import ExpandMorePopup from "../../../../../Common/ExpandMorePopup";
import PopupBG from "./PopBG";
import { useMemo } from "react";
import FormBG from "./FormBG";
import TileRemove from "../../../../home/TileRemove";
import moment from "moment/moment";
import { data } from "jquery";

function GlucoseChartDaily({handleClose, onClick,careTakerPatientView=false }) {
 
  const dateHR = format(endOfDay(new Date()), "ddMMyyy");
  const [xAxisData, setXData] = useState([]);

  const glucoseDaily = useSelector((state) => state.dashboard.glucoseDaily);
  let gh = glucoseDaily?.data?.y_data?.BG;
  
  const dispatch = useDispatch();

  const y1Padding = React.useMemo(() => {
    if (gh && gh.toString().length === 2) {
      return [0, 0, -60, -57];
    } else return [0, 0, -65, -65];
  }, [gh]);

  let xy = [];
  let x = [];
  let y = [];
  let dsnow
  glucoseDaily?.data?.y_data?.BG_list?.forEach((data) => {
   
    var epochTS = data.x_data;
    var d = new Date(parseInt(epochTS));
   
    dsnow = `${d.toLocaleTimeString()}`;
  
    x.push(dsnow);
    y.push((data.y_data.BG)?.toFixed(1));
    xy.push([ dsnow,(data?.y_data?.BG)?.toFixed(1) ])
  });

  const colorGlucose = glucoseDaily?.data?.y_data?.BG_list?.map((point) => {
    var epochTS = new Date(parseInt(point.x_data));
    var dsnow = `${epochTS.toLocaleTimeString()}`

    return {
    
      value: [dsnow, (point.y_data.BG).toFixed(1)],
      itemStyle: {
        color: point.y_data.is_manual ? "#808080" : "#852B99",
     
      },
     
    }
  })

  const alarmStatus= glucoseDaily?.data?.y_data?.status_point
  
  const chartData = React.useMemo(() => {
    return {
      title: [
        {
          text: "Blood Glucose",
          left: "5%",
          top: 0,
          textStyle: {
            fontWeight: "normal",
            fontSize: 25,
          },
        },

        
        {
          text: (glucoseDaily?.data?.y_data?.BG)?.toFixed(1),
          subtext: "mg/dL",
          subtextStyle: {
            fontSize: 12,
          },
          textAlign: "center",
          left: "82%",
          top: "31%",
          textStyle: {
            fontSize: 28,
            fontWeight: "normal",
          },
        },
        {
          subtext: glucoseDaily?.data?.x_data?.time_gap + " ago",
          left: "right",
          bottom: "5%",
          fontSize: 12,
        },
        {
          subtext: glucoseDaily?.data?.y_data?.status,
          left: "left",
          bottom: "5%",
          fontSize: 12,

         
        },
        
      ],
      grid: {
        top: "20%",
        containLabel: true,
        left: "3%",
        right: "30%",
        bottom: "20%",
      },
      tooltip: {
        trigger: 'axis',
        textStyle: {
          fontFamily: "sans-serif"
        },
        axisPointer: {
          type: 'line',
        }
      },
      xAxis: {
        type: "category",
      },
      yAxis: {
        type: "value",
      },
      series: [
        {
          color:"#852B99",
        data:colorGlucose,
        type: "line",
        // smooth: true,
        },
      ],
    };
  }, [glucoseDaily]);


  const chartData2 = React.useMemo(() => {
    return {
      title: [
        {
          left: "5%",
          text: "Blood Glucose",
          top: 0,
        
          subtextStyle: {
            top: 10,
          },
          textStyle: {
            fontWeight: "normal",
            fontSize: 28,
          },

        
        },
        {
          subtext: glucoseDaily?.data?.y_data?.status,
          left: "left",
          bottom: "5%",
          fontSize: 12,
        },
        {
          subtext: glucoseDaily?.data?.x_data?.time_gap + " ago",
          left: "right",
          bottom: "5%",
          fontSize: 12,
        },
        {
          subtext: "mg/dL",
          left: "center",
          top: "52%",
   
          fontSize: 15,
        },
      ],
      tooltip: {
        textStyle: {
          fontFamily: "sans-serif",
        },
      },
      series: [
        {
          type: "gauge",
          center: ["50%", "50%"],
          radius: "60%",
          guageCategoryGap: "0%",
          startAngle: 360,
          endAngle: 0,
          min: false,
          max: 1,
          splitNumber: 12,
          itemStyle: {
            color: "purple",
            shadowColor: "blue",
            shadowBlur: 0,
            shadowOffsetX: 0,
            shadowOffsetY: 0,
          },
          progress: {
            show: true,
            width: 9,
          },
          pointer: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: false,
            length: 15,
          },
          axisLabel: {
            show: false,
            distance: 20,
            color: "red",
            fontSize: 20,
          },
          anchor: {
            show: false,
            showAbove: false,
            size: 20,
            itemStyle: {
              borderWidth: 10,
            },
          },
          title: {
            show: false,
          },
          detail: {
            valueAnimation: true,
            fontSize: 40,
            color: " #434242",
           
            fontWeight: "normal",
            
            offsetCenter: [0, "5%"],
          },
          data: [
            {
            
              value: (glucoseDaily?.data?.y_data?.BG)?.toFixed(1),
            },
          ],
        },
      ],
    };
  }, [glucoseDaily]);
  

  if(!(chartData2?.series[0]?.data[0]?.value))
 return null

return(
  <div>
  {  
    <div className={"dis-daily-chart"+(' alarm-status-'+alarmStatus)} >
      <div className="popup-bg" style={{display:'flex', justifyContent: 'right'}}>
        <ExpandMorePopup 
        option1={<PopupBG 
          
          optionName="Manual Entry" 
          FormTitle="Blood Glucose" 
          FormType={<FormBG handleClose={handleClose} careTakerPatientView={careTakerPatientView}/>}/>}
        option2={<TileRemove
            careTakerPatientView={careTakerPatientView}
            parameter="BG" />}  
      />
      </div>
      <div className="BG-value" style={{ fontFamily: "sans-serif" }} onClick={onClick} careTakerPatientView={careTakerPatientView}>
        <ReactEcharts option={chartData} style={chartData?.series[0]?.data?.length? ({}):({display:'none'})}/>{" "}
        <ReactEcharts option={chartData2} style={chartData?.series[0]?.data?.length? ({display:'none'}):({})}/>{" "}
      </div>
    </div>
  }
</div>
)

}

export default memo(GlucoseChartDaily);

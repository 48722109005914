import React from "react";
import ReactEcharts from "echarts-for-react";
import "./CaloriePopup.module.css";
import CircularProgress from "@mui/material/CircularProgress";
import styles from './CaloriePopup.module.css';

export const CalorieBarChart =  ({ title, xData, yData, handleClick }) => {
    const chartData = React.useMemo(() => {
      return {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          textStyle: {
            fontFamily: "sans-serif",
          },
        },
        
        dataZoom: [
          {
            type: 'inside',
            start: 0,
            end: 100
          },
          {
            start: 10,
            end: 0
          }
        ],
        grid: { // Add grid property to set the margin
          top: "8%", // Adjust the bottom margin as needed
          containLabel: true, // Include axis labels and title inside the grid
        },
  
        xAxis: {
          type: "category",
  
          data: xData,
          axisLabel: {
            fontFamily: "sans-serif",
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            fontFamily: "sans-serif",
          },
        },
  
        series: [
          {
            name: "cal",
            type: "bar",
            areaStyle: {},
  
            symbol: "none",
  
            color: "#6D3DA3",
  
            data: yData,
          },
        ],
      };
    }, [xData, yData, title]);
  
    return (
      <div style={{ height: "100%" }}>
        {chartData?.series?.[0]?.data?.length ? (
          <div className="left-chart">
            <ReactEcharts
              option={chartData}
              onEvents={{
                click: handleClick,
              }}
              style={{ width: "fitContent" }}
            />
          </div>
        ) : (
          <p className={styles.noDataStyles}>
          {/* <CircularProgress /> */}
          No Data
         </p>
        )}
      </div>
    );
  }
    
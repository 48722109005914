import * as React from "react";
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import SettingsIcon from "@material-ui/icons/Settings";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import constants from "../../Common/Constants";
import "./TopToolBar.css";
import resetReduxState from "../../Common/Logout";
import { PlayCircleOutlineRounded } from "@material-ui/icons";
import { showReplayModal } from "../../store/settingsSlice";
import PeopleIcon from "@material-ui/icons/People";
import DevicesIcon from '@material-ui/icons/Devices';
import FitbitIcon from '@mui/icons-material/Fitbit';
import FormBG from "../Member Details/Comps/FitbitFolder/FitCharts/FormBG";
import FormFitbit from "../Member Details/Comps/FitbitFolder/FitCharts/FormFitbit";


function RightTopbar(props,careTakerPatientView=false) {
  const dispatch = useDispatch();
  const history = useHistory();

  const { id,patient_group_id } = useParams();

  const token = useSelector((state) => state.auth.authToken);
  const headerGroupName = useSelector((state) => state.auth.headerGroupName);
  const careTaker = useSelector((state) => state.auth.careTaker);

  //Find the initials to be displayed in the Header
  const [userInitials, setUserInitials] = useState("");
  function getInitials(headerName) {
    let firstLetters = headerName
      .split(" ")
      .map((word) => word[0])
      .join("");
    let initials = firstLetters.toUpperCase().slice(0, 2);
    return initials;
  }
  
  useEffect(() => {
    let initials = getInitials(headerGroupName);
    setUserInitials(initials);
  }, [headerGroupName]);


  let data;
  const [errorMessage, setErrorMessage] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [popperAnchorEl, setPopperAnchorEl] = React.useState(null);
  const popperOpen = Boolean(popperAnchorEl);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handlePopperClose = () => {
    setPopperAnchorEl(null);
  };

  const [showMenu, setShowMenu] = useState(false);

  const [showPopup, setShowPopup] = useState(false);

  function togglePopup() {
    setShowPopup(!showPopup);
  }

  const handleLogout = (event) => {
    setAnchorEl(null);
    axios({
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      url: `${constants.API_URL}/logout/${token}/`,
      data: data,
    }).then(
      (response) => {
        
        setAnchorEl(anchorEl ? null : event.currentTarget);
        resetReduxState(dispatch);
        props.closeCreatedSockets();
        history.push("/");
      },
      (error) => {
        setErrorMessage(true);
        
        resetReduxState(dispatch);
        props.closeCreatedSockets();
        history.push("/");
      }
    );
  };

  //Navigate Back to Care taker screen
  const handleBackToCareTaker = (event) => {
    setAnchorEl(null);
    setAnchorEl(anchorEl ? null : event.currentTarget);
    history.push("/home");
  };


  //Navigate to My Profile Page
  const handleMyProfile = (event) => {
    setAnchorEl(null);
    setAnchorEl(anchorEl ? null : event.currentTarget);
    history.push("/myprofile");
  };
  //Navigate to Settings Page
  const handleSettings = (event) => {
    setAnchorEl(null);
    
    setAnchorEl(anchorEl ? null : event.currentTarget);
    history.push("/settings");
  };

 

  //Opens Replay Modal
  const openReplayModal = (event) => {
    setAnchorEl(null);
    setAnchorEl(anchorEl ? null : event.currentTarget);
    dispatch(showReplayModal(true));
  };


  const handleRegisterClick = (event) => {
    setPopperAnchorEl(event.currentTarget);
  };

  return (
    <div style={{ cursor: "pointer" }}>
      <Box
        onClick={handleMenuClick}
        sx={{ display: "flex", alignItems: "center", textAlign: "center" }}
      >
        <IconButton className="userAvatar" size="small" sx={{ ml: 2 }}>
          
         
           
            {(careTakerPatientView && careTaker)&& (
           <Avatar/>
            
           )}
          

          {!careTaker && (
          <Avatar>{userInitials}</Avatar>
          )}
          
           
              
            
          
        </IconButton>

      
        
        {careTakerPatientView && careTaker&& (
          <Typography>{patient_group_id}</Typography>
            
           )}
          

          {!careTaker && (
          <Typography>{headerGroupName}</Typography>
          )}
            
           
          
          
        


        <IconButton className="userDropDown" size="small" sx={{ ml: 2 }}>
          <ArrowDropDownIcon style={{ color: "white", marginLeft: 0 }} />
        </IconButton>
      </Box>

      <Menu
        id="LoggedUser"
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        {/* {!props.settingsPage && (
          <MenuItem
            className="normalText minHeight-md-0"
            onClick={(e) => openReplayModal(e)}
            data-toggle="modal"
            data-target="#ReplayModal"
          >
            <ListItemIcon>
              <PlayCircleOutlineRounded
                fontSize="small"
                style={{ color: "white" }}
              />
            </ListItemIcon>
            Replay Device
          </MenuItem>
        )} */}

        <MenuItem className="normalText minHeight-md-0"  onClick={handleMyProfile}>
          <ListItemIcon>
            <AccountCircleIcon fontSize="small" style={{ color: "white" }} />
          </ListItemIcon>
          My Profile
        </MenuItem>
        <Divider />

        <MenuItem
          className="normalText minHeight-md-0"
          onClick={handleRegisterClick}
        >
          <ListItemIcon>
            <DevicesIcon fontSize="small" style={{ color: "white" }} />
          </ListItemIcon>
         Devices
        
        </MenuItem>

        <Divider /> 
        
        {!careTaker &&(
           <MenuItem
           className="normalText minHeight-md-0"
           onClick={(e) => handleLogout(e)}
         >
           <ListItemIcon>
             <ExitToAppIcon fontSize="small" style={{ color: "white" }} />
           </ListItemIcon>
           Logout
         </MenuItem>
         
          
        )}
        
        {careTakerPatientView && careTaker&& (
           <MenuItem
           className="normalText minHeight-md-0"
           onClick={(e) => handleBackToCareTaker(e)}
         >
           <ListItemIcon>
             <ExitToAppIcon fontSize="small" style={{ color: "white" }} />
           </ListItemIcon>
           Back to CareTaker
         </MenuItem>
        )}
       

      </Menu>
      <Menu
        id="LoggedUser"
        anchorEl={popperAnchorEl}
        open={popperOpen}
        onClose={handlePopperClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{vertical: 'top', horizontal: 'center'}}
      >
        <MenuItem className="normalText minHeight-md-0" onClick={togglePopup}>
          <ListItemIcon>
            <FitbitIcon fontSize="small" style={{ color: "white" }} />
          </ListItemIcon>
          Fitbit
        </MenuItem>
        {showPopup ? (
        <div style={{ position: "absolute",
          top: "100%",
          right: "-30%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          padding: "10px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
          borderRadius:"5px"}}>
       <> {<FormFitbit/>}  </>
        </div>
      ) : null}
        <Divider />

       
      </Menu>
    </div>
  );
}

export default RightTopbar;

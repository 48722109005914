import React, { useState } from "react";
// import { Grid, Paper, Button, Typography } from "@material-ui/core";
// import { TextField } from "@material-ui/core";
// import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import constants from "../../../../../Common/Constants";
import { setPassword } from "../../../../../store/authSlice";
import axios from "axios";
import { parse } from "date-fns";
import { Button, Grid, Paper, TextField, Typography } from "@material-ui/core";

import { ErrorMessage, Field, Form, Formik } from "formik";
import { useParams, useHistory,useLocation } from "react-router-dom";
import {
    setDashboardData,
    setWebsocketStatus,
  } from "../../../../../store/dashboardSlice";
  import { PopMsg } from "./PopMsg";
  import { Alert } from '@material-ui/lab';
  import { Snackbar } from '@material-ui/core';



function FormCalorie({handleClose,careTakerPatientView=false,refreshChartData}) {

    const dispatch = useDispatch();
    const [showPopup, setShowPopup] = useState(false);
    const careTakerToken=useSelector((state) => state.auth.careTakerToken);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarColor, setSnackbarColor] = useState('');

    const token = useSelector((state) => state.auth.authToken);
    const groupName = useSelector((state) => state.auth.groupName);
    const calorieDaily = useSelector((state) => state.dashboard.calorieDaily)
    const paperStyle = { padding: '0 5px 50px 5px', width: 270, }
    const btnStyle = { marginTop: 10, fontFamily:"'Montserrat', sans-serif"  }
    const SysDiaValue=/^[0-9]{2,3}/
    const initialValues = {
        CAL: '',
    }

    const userData = useSelector((state) => state.auth.userData)

    const { id,patient_group_id } = useParams();
   
    const location = useLocation();
    const urlStateData = location?.state


    const validationSchema = Yup.object().shape({
        CAL: Yup.number().min(5).max(7000).required("Required"),
    })

    const fetchDataApi = async () => {
        let url = `${constants.API_URL}/dashboard_values/${urlStateData?patient_group_id:groupName}/${token}/`;
    
        // url += `${token}/`;
        
    
        const result = await axios.get(url);
        
        dispatch(setDashboardData(result.data.data));
        };

        const onSubmit = (values, props) => {

            //  alert(JSON.stringify(values), null, 2)
            // Popover( (values))
            props.resetForm()
            var dataCAL=parseInt(values.CAL)
            // var curCal=calorieDaily?.total
            // var addedCal=curCal? dataCAL + curCal : dataCAL

            // const dateValue=values.Date 
            // 
            // const timeValue=values.Time
            // 
      
            // var timestampData   =  dateValue +" "+ timeValue  ;
            var currTimestamp= Date.now()
            
            const roundedTimestamp = Math.floor(currTimestamp / 1000) * 1000;
            

            var formData=JSON.stringify({CAL:parseInt(dataCAL), TS:roundedTimestamp});
             
            

            // let tokenManual = userData[0].token
        
            // const pass= "passme123";
        
            axios({
                method: "post",
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                },
                url: `${constants.API_URL}/manual_entry/${urlStateData?patient_group_id:groupName}/${token}/`,
                data: formData,
                
              
              }).then(
                (res) => {
                    if (res.status===200) 
                    
                    setShowPopup(true);
                    setSnackbarOpen(true);
                    setSnackbarColor('success');
                    setSnackbarMessage('Manual Entry Successful');
    
                    setTimeout(() => {
                       // show pop up message
                       setShowPopup(false);
                       handleClose() ;
                       refreshChartData();
              
                     }, 2000);
                     clearTimeout();
                     
              
                     setTimeout(() => {
                       // show pop up message
                      fetchDataApi();
                   
                     }, 3000);
                     clearTimeout();
                }).catch((error) => {
                  console.log(error?.response);
                  if(error?.response?.status == 400){
                   setShowPopup(true);
                   setSnackbarOpen(true);
                   setSnackbarColor('error');
                   setSnackbarMessage(error?.response?.data?.message);
                  }
                  else{
                   setShowPopup(true);
                   setSnackbarOpen(true);
                   setSnackbarColor('error');
                   setSnackbarMessage("Something went wrong");
                  }
               });
                
        }

        const vertical = "bottom"; 
        const horizontal = "center"; 
       

  return (
    <div>
    {showPopup &&(
      <PopMsg/>
    )}

    <Grid>
    <Paper elevation={0} style={paperStyle}>
        <Grid align='center' style={{fontFamily:"'Montserrat', sans-serif" }}>
            <Typography variant='caption'>Enter incremental calories</Typography>
        </Grid>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {(props) => (
                <Form noValidate>
                    {/* <TextField label='Name' name="name" fullWidth value={props.values.name}
            onChange={props.handleChange} /> */}

                    <Field as={TextField} name='CAL' label='Calories' fullWidth
                        error={props.errors.CAL && props.touched.CAL}
                        helperText={<ErrorMessage name='CAL' />} autoFocus required />

                       <Button type='submit' style={btnStyle} variant='contained'
                           color='primary'>Submit Data</Button>
                </Form>
            )}
        </Formik>
    </Paper>
</Grid>
<Snackbar
        anchorOrigin={{ vertical, horizontal }}
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={() => setSnackbarOpen(false)}
          message={snackbarMessage}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={snackbarColor} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
</div>

  )
}

export default FormCalorie
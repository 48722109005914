import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react'
import MainCommonCard from './MainCommonCard';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import { useSelector } from 'react-redux';
import constants from '../../../../Common/Constants';
import axios from 'axios';
import './PillDisv2.css'


function PillDisv2({onClick}) {

    const groupName = useSelector((state) => state.auth.groupName);
    const token = useSelector((state) => state.auth.authToken);
    const medicationList = useSelector((state) => state.dashboard.medicationDaily)
    const pastMed = medicationList?.past
    const pastMedName = pastMed?.Med[0]?.name? pastMed?.Med[0]?.name : "No Medication"
    const pastMedNum = pastMed?.Med[0]?.num? "x" + pastMed?.Med[0]?.num : "-"
    const pastMedTimeHours = pastMed?.hour? pastMed?.hour : "-"
    const pastMedTimeMinutes = pastMed?.minute? pastMed?.minute : "-" ;
    const remainingPastMed = pastMed?.Med?.length -1

    console.log("Name",medicationList)

    let pastMedTimeFormat = "";
    if (pastMedTimeHours >= 12) {
        pastMedTimeFormat = "PM";
    } else {
        pastMedTimeFormat = "AM";
    }
    
    let pastNumberOfMedicines = "-";
    if (pastMed?.Med?.length >= 2) {
       pastNumberOfMedicines = pastMed?.Med?.length - 1;
    } else {
        pastNumberOfMedicines = "-";
    }

    const nextMed = medicationList?.next
    const nextMedName = nextMed?.Med[0]?.name? nextMed?.Med[0]?.name : "No Medication"
    const nextMedNum = nextMed?.Med[0]?.num? "x" + nextMed?.Med[0]?.num : ""
    const nextMedTimeHours = nextMed?.hour? nextMed?.hour : "-"
    const nextMedTimeMinutes = nextMed?.minute? nextMed?.minute : "-" ;
    const remainingNextMed = nextMed?.Med?.length -1

    let nextMedTimeFormat = "";
    if (nextMedTimeHours >= 12) {
        nextMedTimeFormat = "PM";
    } else if (nextMedTimeHours < 12){
        nextMedTimeFormat = "AM";
    }

    const currentMed = medicationList?.present?.Med
    const currentData = medicationList?.present
    const currentMedTimeHours = currentData?.hour? currentData?.hour : "-" 
    const currentMedTimeMinutes = currentData?.minute? currentData?.minute : "-"

    
    let currentMedTimeFormat = "";
    if (currentMedTimeHours >= 12) {
        currentMedTimeFormat = "PM";
    } else if (currentMedTimeHours < 12){
        currentMedTimeFormat = "AM";
    }

    const MAX_MED_NAMES_TO_DISPLAY = 3;

    // if (!Array.isArray(currentMed) || currentMed.length === 0) {
    //     return null; // Return null or handle the error case appropriately
    //   }
    const truncatedList = currentMed?.slice(0, MAX_MED_NAMES_TO_DISPLAY);
    const remainingCount = currentMed?.length - MAX_MED_NAMES_TO_DISPLAY;



    if (
        medicationList?.hasOwnProperty("past") ||
        medicationList?.hasOwnProperty("present") ||
        medicationList?.hasOwnProperty("next")
      ) {
    return (
    <>
    <MainCommonCard
    onClick={onClick}
    tileTitle={"Medication"}
    renderComponent={
        <div style={{ display: "flex", flexDirection: "column",flex:"1", alignItems: "center", justifyContent: "center", marginTop:"-20px", margin:"-20px 0px 0px 0px", width:"fit-content" }}>
           
            {/* Past schedule tile representation*/}
            <div className='past-div-main'>
                <div className="medicine-name-and-number">   
                <div className="med-style">{pastMedName.length > 6 ? pastMedName.substr(0, 6) + "..." : pastMedName }</div> 
                <div className="med-style">{pastMedNum}</div>
                    {remainingPastMed > 0 && (
                        <div className="med-style" style={{ alignItems: "center", justifyContent: "center", backgroundColor: "#D9D9D9", borderRadius: "5px", fontFamily: "'Montserrat', sans-serif", padding: "0px 2px 0px 2px" }}>
                            +{remainingPastMed}
                        </div>
                    )}
                </div>

                <div className="medicine-time-and-icon">
                    <div className='med-time'>
                        <div style={{ fontSize: "1.1rem", fontFamily: "'Montserrat', sans-serif" }}>{pastMedTimeHours}:{pastMedTimeMinutes}</div>
                        <div style={{ fontSize: "0.8rem", fontFamily: "'Montserrat', sans-serif" }}>{pastMedTimeHours? pastMedTimeFormat : "-"}</div>
                    </div>
                    <div> <FontAwesomeIcon icon={faCircleCheck} style={{ color: "#19c22d", marginLeft: "5px", height: "25px", width: "25px" }} /></div>
                </div>
            </div>

            {/* Current Medication Representation */}
            <div className='current-div-main'>
                <div style={{display: "flex", flexDirection: "column", padding:"2px 0px 2px 0px"}}>
             
        {truncatedList?.map((medicine, index) => (
          <div className="medicine-name-and-number" key={index}>
            <div className="med-style">{medicine.name}</div>
            <div className="med-style">x{medicine.num}</div>
          </div>
        ))}
        {truncatedList == null &&(
            <div className="medicine-name-and-number" >
            <div className="med-style">No Med...</div>
            <div className="med-style">{}</div>
          </div>
        )}
      </div>
      {remainingCount > 0 && (
        <div className="med-style" style={{ alignItems: "center", justifyContent: "center", backgroundColor: "#D9D9D9", borderRadius: "5px", fontFamily: "'Montserrat', sans-serif", padding: "0px 2px 0px 2px" }}>
          +{remainingCount}
        </div>
      )}
                {/* <div className="med-style" style={{ alignItems:"center", justifyContent:"center", backgroundColor: "#D9D9D9", borderRadius: "5px", fontFamily: "'Montserrat', sans-serif", padding:"0px 2px 0px 2px" }}>+2</div> */}

                <div className="medicine-time-and-icon">
                    <div className='med-time'>
                        <div style={{ fontSize: "1.1rem", fontFamily: "'Montserrat', sans-serif" }}>{currentMedTimeHours}:{currentMedTimeMinutes}</div>
                        <div style={{ fontSize: "0.8rem", fontFamily: "'Montserrat', sans-serif" }}>{currentMedTimeFormat}</div>
                    </div>
                    <div> <NotificationsActiveIcon style={{ color: "#75757C", marginLeft: "5px", height: "30px", width: "30px", justifyContent: "right" }} /></div>
                </div>
            </div>
            

            {/* Next Medication Representation */}
            <div className='past-div-main'>
                <div className="medicine-name-and-number">   
                <div className="med-style">{nextMedName.length > 5 ? nextMedName.substr(0, 6) + "..." : nextMedName }</div> 
                <div className="med-style">{nextMedNum}</div>
                    {remainingPastMed > 0 && (
                        <div className="med-style" style={{ alignItems: "center", justifyContent: "center", backgroundColor: "#D9D9D9", borderRadius: "5px", fontFamily: "'Montserrat', sans-serif", padding: "0px 2px 0px 2px" }}>
                            +{remainingPastMed}
                        </div>
                    )}
                </div>

                <div className="medicine-time-and-icon">
                    <div className='med-time'>
                        <div style={{ fontSize: "1.1rem", fontFamily: "'Montserrat', sans-serif" }}>{nextMedTimeHours}:{nextMedTimeMinutes}</div>
                        <div style={{ fontSize: "0.8rem", fontFamily: "'Montserrat', sans-serif" }}>{nextMedTimeFormat}</div>
                    </div>
                    <div><AccessAlarmIcon style={{ color: "#75757C", marginLeft: "5px", height: "25px", width: "25px" }}/></div>
                </div>
            </div>
        </div>
    }
    >

    </MainCommonCard>
    </>
  )
}
else{
    return null;
}
}

export default PillDisv2
import React from 'react'
import '../Commonv2.css'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faLungs} from '@fortawesome/free-solid-svg-icons';
import SideCardCommon from '../SideCardCommon';
import { useSelector } from 'react-redux';
import FormBR from '../../../Member Details/Comps/FitbitFolder/FitCharts/FormBR';
import TileRemove from '../Featuresv2/TileRemove';
import { useState } from 'react';
import { useEffect } from 'react';

function BRv2({onClick}) {

  const [isExpired, setIsExpired] = useState(false);

  const breathDaily = useSelector((state) => state.dashboard.breathDaily);
  const BRDaily = breathDaily?.data?.y_data?.br
  // console.log(breathDaily)

  const alarmStatus= breathDaily?.data?.y_data?.status_point
  const timeGapIndicationBR = breathDaily?.data?.x_data
  // console.log(timeGapIndicationBR)

  useEffect(() => {
    if (timeGapIndicationBR?.includes("day") || timeGapIndicationBR?.includes("days")) {
      setIsExpired(true);
    }
    else{
      setIsExpired(false);
    }
  }, [timeGapIndicationBR, breathDaily]);

  if (!BRDaily){
    return null;
  }

  return (
    <SideCardCommon title="Breath Rate" 
    onClick={onClick}
    tileBgColor={isExpired? "#F9F9F9" : "white"}
    iconpic={<FontAwesomeIcon icon={faLungs} color="#7F55AE"/>}
    parameterValue={BRDaily? BRDaily:""} 
    parameterUnit="RR"
    alarmStatus={breathDaily?.data?.y_data?.status}
    timeGap={breathDaily?.data?.x_data}
    FormTitle="Breath Rate"
    FormType={<FormBR/>}
    alarmStatusBorder={alarmStatus}
    Option2={<TileRemove option2title={"REMOVE TILE"} parameter={"BR"}/>}>    
    </SideCardCommon>
  )
}
export default BRv2;
import React from 'react'
import { MoreHoriz } from '@material-ui/icons'
import styles from "./CareGiverPage.module.css"
import { Avatar } from '@material-ui/core'
import hoversnd from './../CareCamCard/Snd/hover2.wav'

export default function index({children,title="Name",imgUser,handleCardClick}) {

   const playSound = () => {
    const sound = new Audio(hoversnd);
    sound.play();
  }
  return (

    <div className={styles.container1} onClick={handleCardClick}>
    <div className={styles.absoluteContainer}><MoreHoriz/></div>
    <div className={styles.headerTop}>
    <div className={styles.imgUser}>{imgUser}</div> <div className={styles.title}>{title}</div>
    </div>
        {children}
    </div>
  )
}
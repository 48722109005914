import React, { useState, useEffect } from 'react'
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory,useLocation } from "react-router-dom";
import constants from "../../../../../Common/Constants";
import {
  setDashboardData,
} from "../../../../../store/dashboardSlice.js";
import HeartRatev2 from '../../VitalCharts/HeartRatev2'
import HeartRatePopup from '../../HRv2/HeartRatePopup'
import PopupModal from '../../PopupModal'
import BPChartsv2 from '../../VitalCharts/BPChartsv2'
import BPChartPopup from '../../BPv2/BPChartPopup'
import BPv2 from '../../VitalCharts/BPv2'
import BGChartPopup from '../../BGv2/BGChartPopup'
import SleepQuick from '../../VitalCharts/SleepQuick'
import { SleepPopup } from '../../Sleepv2/SleepPopup'
import StepSpo2v2 from '../../VitalCharts/StepSpo2v2'
import PillDisv2 from '../../VitalCharts/PillDisv2'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileContract,
  faFire,
  faPen,
  faCircleArrowLeft
} from "@fortawesome/free-solid-svg-icons";
import UserData from '../../OtherComponents/UserData'
import { Button } from "@material-ui/core";
import Tempv2 from '../../VitalCharts/Tempv2'
import BRv2 from '../../VitalCharts/BRv2'
import BRPopup from '../../BRv2/BRPopup'
import { useRef } from "react";
import Weightv2 from '../../VitalCharts/Weightv2.js';
import Caloriev2 from '../../VitalCharts/Caloriev2.js';
import Headerv2 from '../../Headerv2.js';
import PopUpManualEntry from '../../OtherComponents/PopUpManualEntry.js';
import FormSleep from '../../../../Member Details/Comps/FitbitFolder/FitCharts/FormSleep.js';
import FormCalorie from '../../../../Member Details/Comps/FitbitFolder/FitCharts/FormCalorie.js';
import FormStep from '../../../../Member Details/Comps/FitbitFolder/FitCharts/FormStep.js';
import FormWT from '../../../../Member Details/Comps/FitbitFolder/FitCharts/FormWT.js';
import FormTemp from '../../../../Member Details/Comps/FitbitFolder/FitCharts/FormTemp.js';
import FormBR from '../../../../Member Details/Comps/FitbitFolder/FitCharts/FormBR.js';
import FormHR from '../../../../Member Details/Comps/FitbitFolder/FitCharts/FormHR.js';
import FormSpO2 from '../../../../Member Details/Comps/FitbitFolder/FitCharts/FormSpO2.js';
import FormBG from '../../../../Member Details/Comps/FitbitFolder/FitCharts/FormBG.js';
import FormBP from '../../../../Member Details/Comps/FitbitFolder/FitCharts/FormBP.js';
import FloatingAddButton from '../../Featuresv2/FloatingAddButton.js';
import TMPPopup from '../../TMPv2/TMPPopup.js';
import { SPOPopup } from '../../SPOv2/SPOPopup.js';
import { StepPopup } from '../../STPv2/StepPopup.js';
import WeightPopup from '../../Weightv2/WeightPopup.js';
import { CaloriePopup } from '../../Caloriev2.js/CaloriePopup.js';
import BMI from '../../VitalCharts/BMI';
import BMIChartPopup from '../../BMIv2/BMIChartPopup';
import MedicationNewTile from "../../VitalCharts/MedicationNewTile.js";

function MyFamilyInnerScreen({pat_id}) {

    const [showPopup, setShowPopup] = useState(false);
    const [popupComponent, setPopupComponent] = useState(null);
  
    const openPopup = (component) => {
      setShowPopup(true);
      setPopupComponent(component);
    };
  
    const closePopup = () => {
      setShowPopup(false);
      setPopupComponent(null);
    };
    const count = useSelector((state) => state.auth.renderInterval);
    const groupName = useSelector((state) => state.auth.groupName);
    const token = useSelector((state) => state.auth.authToken);
    const deviceTypeList = useSelector((state) => state.home.deviceTypeList);
    
    const userData = useSelector((state) => state.auth.userData);
    const currentUserIndex = useSelector((state) => state.auth.currentUserIndex);
    
    const history = useHistory();
    const dispatch = useDispatch();
  
    const endReplayTime = useSelector((state) => state.settings.endReplayTime);
    const dashboardApiStatus = useSelector((state) => state.dashboard.apiStatus);
  

    
    const MedicationPage = () => {
      const viewOnlyMode = true;
      history.push(`/medication_page`, {viewMode:viewOnlyMode, patientToken:pat_id});
    };
   
    
    useEffect(() => {
      
        const fetchData = async () => {
        let url = `${constants.API_URL}/dashboard_values/${pat_id}/${token}/`;
        
        let activeUserDeviceList = userData?.[currentUserIndex]?.["device_list"];
        
        const result = await axios.get(url);
        
        dispatch(setDashboardData(result.data.data));
        };

        fetchData()

        const intervalId = setInterval(fetchData, 61000);
        return () => clearInterval(intervalId)
      
    }, [dashboardApiStatus, groupName, token, currentUserIndex, userData ]);
  
    const [isDetailsPopoverOpen, setDetailsPopoverOpen] = useState(false);
  
    const popoverRef = useRef(null);
  
    const togglePopover = () => {
      setDetailsPopoverOpen(!isDetailsPopoverOpen);
    };
  
    const closePopover = () => {
      setDetailsPopoverOpen(false);
    };
  
    useEffect(() => {
      const handleOutsideClick = (event) => {
        if (popoverRef.current && !popoverRef.current.contains(event.target)) {
          closePopover();
        }
      };
  
      const handleNavigate = () => {
        closePopover();
      };
  
      document.addEventListener('mousedown', handleOutsideClick);
      window.addEventListener('beforeunload', handleNavigate);
  
      return () => {
        document.removeEventListener('mousedown', handleOutsideClick);
        window.removeEventListener('beforeunload', handleNavigate);
      };
    }, []);

    const handleBackHome= ()=>{
      history.push(`/home?activeTab=Family%20Members`);
    }

    
  const handleToAlarmSettings = () => {
  }

  return (
    <div style={{backgroundColor:"#F5F5F5"}}>
        <Headerv2/>
        <div style={{backgroundColor:"#F5F5F5"}}>
         <div style={{paddingTop:"10px"}}>
         <div style={{ display:"flex", flexDirection:"row",
            width:"auto", backgroundColor:"white",color:"#7b5c9f",height:" fit-content",
         textAlign:"left",alignItems:"center", padding:"10px 15px 10px 15px", borderRadius:"30px", justifyContent:"space-between", margin:"65px 10px 5px 10px",
         fontFamily:"'Montserrat',san-serif", fontWeight:"600", 
         boxShadow: "0 0px 5px 0 rgba(139, 139, 139, 0.5), 0px 0px 1px 0px rgba(184, 183, 183, 0.19)"}}>
           <div style={{fontFamily:"'Montserrat',san-serif", fontWeight:"500",fontSize:"0.8rem", padding:"5px 10px 5px 10px", backgroundColor:"#7b5c9f", color:"white", borderRadius:"15px"}}>Patient ID : {pat_id}</div>
           <div style={{cursor:"pointer",fontFamily:"'Montserrat',san-serif", fontWeight:"600"}} onClick={handleBackHome}>
            <FontAwesomeIcon icon={faCircleArrowLeft} size='lg'/>
           </div>
         </div>
            <div className="dashboardMainView-v2">
            <div className="left-panel-v2">
              <div className="left-card-main-grid">
                <HeartRatev2 userID={pat_id} onClick={() => openPopup(<HeartRatePopup/>)} />
                {showPopup && (
                  <PopupModal onClose={closePopup}>
                    {popupComponent}
                  </PopupModal>
                )}
                <BPChartsv2 onClick={() => openPopup(<BPChartPopup/>)} />
                {showPopup && (
                  <PopupModal onClose={closePopup}>
                    {popupComponent}
                  </PopupModal>
                )}
                <BPv2 onClick={() => openPopup(<BGChartPopup/>)} />
                {showPopup && (
                  <PopupModal onClose={closePopup}>
                    {popupComponent}
                  </PopupModal>
                )}
                <SleepQuick onClick={() => openPopup(<SleepPopup/>)} />
                {showPopup && (
                  <PopupModal onClose={closePopup}>
                    {popupComponent}
                  </PopupModal>
                )}
                <StepSpo2v2 
                onSPOPopupClick={() => openPopup(<SPOPopup/>)} 
                onSTPPopupClick={() => openPopup(<StepPopup/>)}
                />
                {showPopup && (
                  <PopupModal onClose={closePopup}>
                    {popupComponent}
                  </PopupModal>
                )}
                <MedicationNewTile onClick={MedicationPage}/>

              </div>
            </div>
            <div className="right-panel-v2">
              <div className="side-container-v2">
                <div
                  className="details-css"
                  style={{
                    margin: "0px 5px 5px 5px",
                    padding: "5px 5px 5px 5px",
                    fontFamily: "'Montserrat', sans-serif",
                    fontSize: "1rem",
                    color: "#75757C",
                    fontWeight: "500",
                  }}
                >
                  DETAILS{" "}
                </div>
                <div
                  className="container-right-patient-details-v2"
                  style={{ minHeight: "240px" }}
                >
                  <UserData profileID={pat_id} profileName={pat_id}/>
                </div>
                <div
                  className="patient-details-button-v2"
                  style={{ width: "100%" }}
                >
                  <div className="user-popover-container" ref={popoverRef}>
                    <Button className="user-button" onClick={togglePopover}>
                      DETAILS
                    </Button>
                    {isDetailsPopoverOpen && (
                      <div className="popover-content">
                        <UserData profileID={pat_id} profileName={pat_id} />
                      </div>
                    )}
                    {isDetailsPopoverOpen && (
                      <div
                        className="popover-overlay"
                        onClick={closePopover}
                      ></div>
                    )}
                  </div>
                </div>
                
                <div className="conatainer-right-vitals-v2">
                <Tempv2 onClick={() => openPopup(<TMPPopup/>)} />
                  {showPopup && (
                  <PopupModal onClose={closePopup}>
                    {popupComponent}
                  </PopupModal>
                )}
                  <BRv2 onClick={() => openPopup(<BRPopup/>)} />
                  {showPopup && (
                  <PopupModal onClose={closePopup}>
                    {popupComponent}
                  </PopupModal>
                )}
                  <Weightv2 onClick={() => openPopup(<WeightPopup/>)}/>
                  {showPopup && (
                  <PopupModal onClose={closePopup}>
                    {popupComponent}
                  </PopupModal>
                )}
                  <Caloriev2 onClick={() => openPopup(<CaloriePopup/>)}/>
                  {showPopup && (
                  <PopupModal onClose={closePopup}>
                    {popupComponent}
                  </PopupModal>
                   )}
                   <BMI onClick={() => openPopup(<BMIChartPopup/>)}/>
                   {showPopup && (
                  <PopupModal onClose={closePopup}>
                    {popupComponent}
                  </PopupModal>
                   )}
                </div>
              </div>
            </div>
           </div>
          </div>
          </div>
    </div>
  )
}

export default MyFamilyInnerScreen
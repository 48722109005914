import React from "react";
import "../FNewDash.css";
import { Col, Row } from "reactstrap";
import SPO2Chartpage1 from "./SPO2ChartPage1";
import { useState } from "react";
import SPO2ChartPage2 from "./SPO2ChartPage2";



export default function SPO2ChartContainer({ title, xData, yData }) {
   
   
    const [spo2Value,setSpo2Value]=useState({spo2:null,date:null})
   
   
    const handleClick = (param, echarts) => {
      let location = (param.dataIndex)
          const tempState = {...spo2Value}
          tempState["date"] = xData[location]
          tempState["spo2"] = yData[location]
          setSpo2Value(tempState)
          
      }

  
    return (
      <div className="h-100">
        <Row className="h-100">
          <Col className="first-box h-100 ">
            <div className="first-card h-100">
              <SPO2Chartpage1
                title={title}
                xData={xData}
                yData={yData}
                handleClick={handleClick}
              />
            </div>
          </Col>
          <Col className="second-box">
             {spo2Value?.spo2 && spo2Value?.date &&
            <div className="second-card">
            
              <SPO2ChartPage2 
                title={spo2Value?.date}
               
                yData={spo2Value?.spo2}
              />
            </div>
  }
          </Col>
        </Row>
      </div>
    );
  }
  
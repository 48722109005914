import React, { useState, useEffect } from "react";
import ReactEcharts from "echarts-for-react";
import constants from "../../../../../../Common/Constants";
import { endOfDay, format } from "date-fns";
import { useSelector } from "react-redux";
import axios from "axios";
function HRCardio() {
  const analyticsRes = useSelector((state) => state.dashboard.analyticsRes);
 
  let peak = [];
  let cardio = [];
  let fat = [];
  let xData = [];

  analyticsRes?.data?.cardio_resting_hr?.forEach((item) => {
    peak.push(item?.cardio_zone?.peak);
    cardio.push(item?.cardio_zone?.cardio);
    fat.push(item?.cardio_zone?.fatburn);

    xData.push(item?.x_data);
  });

  const option = {
    title: {
      text: "Minutes in Heart Zones",
      left: "center",
      textStyle: {
        fontSize: 17,
      },
    },
    legend: {
      orient: 'horizontal',
      right: 5,
      top: 23
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },

    xAxis: [
      {
        type: "category",
        data:xData,
      },
    ],
    yAxis: [
      {
        splitLine: {
          show: false
        },
        type: "value",
      },
    ],
    series: [
      {
        color: "#FFD700",
        name: "Fat Burn",
        type: "bar",
        stack: "Ad",
        emphasis: {
          focus: "series",
        },
        data: fat,
      },
      {
        color: "#FF8C00",
        name: "Cardio",
        type: "bar",
        stack: "Ad",
        emphasis: {
          focus: "series",
        },
        data: cardio,
      },
      {
        color: "#28B779",
        name: "Peak",
        type: "bar",
        stack: "Ad",
        emphasis: {
          focus: "series",
        },
        data:peak,
      },
    ],
  };
  
  if (!option?.series[0]?.data?.length) {
    return null;
  }
  return (
    <div>
      <ReactEcharts option={option} />
    </div>
  );
}

export default HRCardio;

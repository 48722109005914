import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import constants from "../../../../../Common/Constants";
import { useSelector } from "react-redux";
import withingIcon from './withings.jpg'

export const WithingsLoginPage = () => {
  const history = useHistory();
  const groupName = useSelector((state) => state.auth.groupName);
  const token = useSelector((state) => state.auth.authToken);

  const [count, setCount] = useState(6);

  useEffect(() => {
    const countdown = setTimeout(() => {
      if (count > 0) {
        setCount(count - 1);
      } else {

        history.push('/home');
      }
    }, 1000);

    return () => {
      clearTimeout(countdown);
    };
  }, [count, history]);

  useEffect(() => {
    // Capture the code from the query parameters
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get("code");
    console.log("code:", code);
if(code){
    axios({
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        url: `${constants.API_URL}/withings_registration/${groupName}/${token}/`,
        data: { code: code },
       
      });
}
   
    console.log("codeapi:",code)

    history.push("/authenticate_withings"); // Redirect to a success page
  }, [history]);

  return (
    <div style={{ height: "100vh" }}>
    <div className="mainContainerWithingsAuth">
      <div className="authsuccessPopUP">
        <img className="withingslogo" src={withingIcon} /> 
        <div className="textMessageFitAuth">
          <div className="zoom-text">Withings Authentication successful</div>

          <div className="success-checkmark">
            {/* {animationVisible && ( */}
              <div className="check-icon">
                <span className="icon-line line-tip"></span>
                <span className="icon-line line-long"></span>
                <div className="icon-circle"></div>
                <div className="icon-fix"></div>
              </div>
            {/* )} */}
          </div>

          <div style={{ textAlign: 'center' }}>
            <p className="redirectMessageFitAuth">Redirecting to the home page in 
            <div style={{fontWeight:"600", fontSize:"0.8rem"}}>{count}</div>
            </p>
          </div>

        </div>
      </div>
    </div>
  </div>
  );
};

import axios from "axios";
import { endOfDay, format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import constants from "../../../../../../Common/Constants";
import ReactEcharts from "echarts-for-react";

import { setAnalyticsRes } from "../../../../../../store/dashboardSlice";
import { useLocation, useParams } from "react-router-dom";

export default function HRAnalytics() {
  const groupName = useSelector((state) => state.auth.groupName);
  const token = useSelector((state) => state.auth.authToken);
  const dateHR = format(endOfDay(new Date()), "ddMMyyy");
  const [data, setData] = useState({ x: [], y: [], z: [], a: [] });
  const dispatch = useDispatch();

  const careTakerToken = useSelector((state) => state.auth.careTakerToken);
  
  const { id,patient_group_id } = useParams();

  
  
   // 

  const location = useLocation();
  const urlStateData = location?.state 

  

  useEffect(() => {
    axios
      .get(
        `${constants.API_URL}/Hr_analytics_data/${urlStateData?patient_group_id:groupName}/${dateHR}/${urlStateData?careTakerToken:token}/`
      )
      .then((res) => {
       
        let xData = [];
        let lowHR = [];
        let highHR = [];
        let avgHR = [];

        dispatch(setAnalyticsRes(res));

        res?.data?.hourly_data?.forEach((item) => {
          xData.push(item?.x_data);
          lowHR.push([item?.x_data,item?.low_hr]);
          highHR.push([item?.x_data,item?.high_hr]);
        });

        res?.data?.daily_avg_hr?.forEach((item) => {
          avgHR.push([item?.x_data,item?.y_data]);
        });

        setData({ x: xData, y: lowHR, z: highHR, a: avgHR });
      });
  }, []);

  const option = {
    title: {
      text: "Hourly Heart Rate",
      left: "center",
      textStyle: {
        fontSize: 17,
      },
    },
    legend: {
      orient: "horizontal",
      right: 5,
      top: 28,
      left: "center",
    },
    tooltip: {
      textStyle: {
        fontFamily: "sans-serif",
      },
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    xAxis: {
      type: "category",
      boundaryGap: false,
      data: data?.x,
    },
    yAxis: {
      splitLine: {
        show: false,
        
      },
      type: "value",
    },
    series: [
      {
        color: "#00B5C6",
       
        name: "Low HR",
        type: "line",
        // stack: "x",
        data: data?.y,

      },
      {
        color: "#6D3DA3",
        // areaStyle: {
        //   offset: 0,
        //   color: "grey",
        // },
        name: "High HR",
        type: "line",
        // stack: "x",
        data: data?.z,
      },
      {
        color: "#28B779",
        
        name: "Average HR",
        type: "line",
        // stack: "x",
        data: data?.a,
      },
      
      
    ],
  };

  if (!option?.series[0]?.data[0]?.length) {
    return null;
  }

  return (
    <div>
      {" "}
      <ReactEcharts option={option} />
    </div>
  );
}

import React from "react";
import moment from "moment";
import { Col, Row } from "reactstrap";
import SleepChartpage1 from "./SleepChartpage1";
import SleepChartPage2 from "./SleepChartPage2";
import { useState } from "react";
import axios from "axios";

import constants from "../../../../../../Common/Constants";
import { useSelector } from "react-redux";
import "../FNewDash.css"
import { useLocation, useParams } from "react-router-dom";

const apiName = {
  week: "sleep",
  month: "week_data",
  threeMonth: "three_month_data",
  year: "month_data",
};

export default function SleepChartContainer({ title, xData, yData }) {
  
 
   const groupName = useSelector((state) => state.auth.groupName);
    const [clickedChart, setClickedChart] = useState(null);
    const [subChartName,setChartName]= useState('')
    const token = useSelector((state) => state.auth.authToken);
   
    const [chart_data, setData] = useState({});

    const careTakerToken = useSelector((state) => state.auth.careTakerToken);
  
    const { id,patient_group_id } = useParams();

    const location = useLocation();
    const urlStateData = location?.state 
    const handleClick = (param, echarts) => {
      const dateMonth = param?.name?.split(" ");
      let month = "01"
      let date = "01"
      if (dateMonth?.length === 1){
        month = moment().month(dateMonth[0]).format("MM");
      }
      else{
        month = moment().month(dateMonth[1]).format("MM");
        date = dateMonth[0]?.length === 1 ? `0${dateMonth[0]}` : dateMonth[0];
      }
      const year =(param?.name.split(" "))[2];
   
      const clickedDate = `${date}${month}${year}`;
      setClickedChart(clickedDate)
      setChartName(param?.name)
    
       if (!chart_data?.[clickedDate]) {
        //api call
       
        axios
          .get(
            `${constants.API_URL}/day_data/sleep/${urlStateData?patient_group_id:groupName}/${clickedDate}/${urlStateData?careTakerToken:token}/`
          )
          .then((res) => {
           
            let data = res?.data?.day_data[0].y_data
           
            let stateCopy = {...chart_data};
           
            stateCopy[clickedDate] = {yData:data}
           
            setData(stateCopy);
           
          });
      }
    };
    return (
      <div className="h-100">
        <Row className="h-100">
          <Col className="first-box h-100">
            <div className="first-card h-100">
              <SleepChartpage1
                title={title}
                xData={xData}
                yData={yData}
                handleClick={handleClick}
              />
            </div>
          </Col>
          <Col className="second-box">
            {clickedChart && 
            <div className="second-card">
              <SleepChartPage2 
                title={subChartName}
                // xData={chart_data?.[clickedChart]?.xData}
                yData={chart_data?.[clickedChart]?.yData}

              />
            
            </div>
            
  }
          </Col>
        </Row>
      </div>
    );
}